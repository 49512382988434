import React from "react";
import { SendMessageSvg } from "src/core/component/assets/svg/SupportSVG";
import MessageImageField from "src/core/component/form/MessageImageField";
import { CloseButtonSvg } from "src/core/component/assets/svg/CommonSVG";


const MessageForm = ({
    formData,
    handleChange,
    onChangeFile,
    handleSubmit,
    isSubmitting,
    isDarkMode,
    maxLength="", 
    imagePreviewUrl,
    handleImageRemove,
    inputRef,
    ...rest

}) => {

    return (
        <form className={"pocket-form" + (isDarkMode && ' dark' || '')} onSubmit={handleSubmit}> 
            {imagePreviewUrl && formData.image && (
                <div className={`img-preview ${isDarkMode && 'dark' || ''}`}>
                    <div className="image position-relative">
                        <img src={imagePreviewUrl} className="img-fluid" />

                        <div className="delete position-absolute" onClick={handleImageRemove}>
                            <CloseButtonSvg />
                        </div>
                    </div>
                </div>  
            )} 

            <div className="row gx-1 gx-sm-2 align-items-center">
                <div className="col-auto field flex-fill">
                    <input type="text" className={"form-control"} placeholder="Type your query here ..." name="reply" id="reply" value={formData['reply'] || ''} maxLength={maxLength} onChange={handleChange} autoComplete="off" required  disabled={isSubmitting}/>
                     {/* Send Image  */}
                    <MessageImageField
                        property={'image'}
                        type={"file"}
                        onChange={onChangeFile}
                        isDarkMode={isDarkMode}
                        inputRef={inputRef}
                    />

                    {isSubmitting && (
                        <span className="spinner-border spinner-border-md text-primary" role="status" aria-hidden="true"></span>
                    )}
                </div>
                <div className="col-auto field">
                    <button type="submit" className="btn pocket-btn support d-none d-md-block text-center" disabled={isSubmitting}>
                        Send
                    </button>
                    <button type="submit" className="d-md-none btn support-mobile text-center" disabled={isSubmitting}>
                        <SendMessageSvg />
                    </button>
                </div>
            </div>
        </form>
    )
}

export default MessageForm;
