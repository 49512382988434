import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { darkTheme, lightTheme } from 'src/theme/redux';

export const ThemeContext = React.createContext(null);

export const useTheme = () => {
  const { isDarkMode, toggleTheme, setLightTheme, setDarkTheme } = React.useContext(ThemeContext);

  return [isDarkMode, toggleTheme, setLightTheme, setDarkTheme];
}

const ThemeContextProvider = (props) => {
  const themeMode = useSelector(state => state.theme.mode);
  const dispatch = useDispatch();
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  React.useEffect(() => {
    setIsDarkMode(themeMode === 'dark');
  }, [themeMode]);

  const darkBg = '#000000';
  const whiteBg = '#FFFFFF';

  React.useEffect(() => {
    if (isDarkMode) {
      document.body.style.backgroundColor = darkBg;
    } else {
      document.body.style.backgroundColor = whiteBg;
    }

    return () => {
      document.body.style.backgroundColor = whiteBg;
    };
  }, [isDarkMode])

  const toggleTheme = () => {

    if (themeMode == 'light') {
      dispatch(darkTheme());
    } else {
      dispatch(lightTheme());
    }

    // setIsDarkMode(!isDarkMode);
  }

  const setLightTheme = () => {
    dispatch(lightTheme());
  }

  const setDarkTheme = () => {
    dispatch(darkTheme());
  }

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, setLightTheme, setDarkTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
