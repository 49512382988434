import React from "react"
import { Link } from "react-router-dom";
import { usePasswordToggle } from "src/core/hook/PasswordToggleHook";


const PasswordField = ({
        property,
        hasLabel=true,
        isSmall=true,
        showToggle=true,
        hasPlaceholder=true, 
        hasInstruction=false,
        hasLink=false,
        hasIcon=false,
        required=false,
        label="",
        classes="", 
        instruction="",
        placeholder="",
        icon="",
        isInvalid=false,
        autoComplete="off",
        error=`*Please enter a valid ${label}`,
        onChange,
        onClick,
        ...res
    }) => {

    onClick = typeof onClick === 'function' ? onClick : function noop() { }

    const [InputType, Icon, toggleVisibility] = usePasswordToggle();
  
  return (
        <>  
            {
                hasLabel &&
                <label className="form-label" htmlFor={property}>{label}</label>
            }
            
            {showToggle && (
                <div className="input-group">
                    <input type={InputType} className={"form-control" + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} placeholder={placeholder} name={property} id={property} onChange={onChange} required={required} autoComplete={autoComplete} />
                    <span className="input-group-text" onClick={toggleVisibility}>{Icon}</span>
                    <div className="invalid-feedback">
                        {error}
                    </div>
                </div>
            )}

            {!showToggle && (
                <>
                    <input type="password" className={"form-control" + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} placeholder={placeholder} name={property} id={property} onChange={onChange} required={required} autoComplete={autoComplete} />
                    <div className="invalid-feedback">
                        {error}
                    </div>
                </>
            )}

            {
                hasInstruction &&
                <div className="form-text">
                    {instruction}

                    {
                        hasLink &&
                        <Link to="#" onClick={onClick} className="form-text-link">
                            click here
                        </Link>
                    }
                </div>
            }
        </>
    )
}

export default PasswordField
