import { loadJSON } from 'src/lib/json-store';
import api from 'src/lib/api';

// Inital state
const initialState = {
  accessToken: loadJSON('auth.accessToken'),
  refreshToken: loadJSON('auth.refreshToken'),
  profile: loadJSON('auth.user'),
  birthProfiles: loadJSON('auth.user.birthProfiles') || [],
  preference: loadJSON('auth.user.preference'),
  notification_preference: loadJSON('auth.user.notification_preference')
};

// Action types
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SETUP_USER_INFOS = "SETUP_USER_INFOS";
const FETCH_PROFILE  = 'FETCH_PROFILE';
const FETCH_BIRTHPROFILES = 'FETCH_BIRTHPROFILES';
const FETCH_SYSTEM_PREFERENCES = 'FETCH_SYSTEM_PREFERENCES';
const FETCH_NOTIFICATION_PREFERENCES = 'FETCH_NOTIFICATION_PREFERENCES';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_BIRTHPROFILE = 'UPDATE_BIRTHPROFILE'
const DELETE_BIRTHPROFILE = 'DELETE_BIRTHPROFILE'
const UPDATE_SYSTEM_PREFERENCES = 'UPDATE_SYSTEM_PREFERENCES';
const UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES'
const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';


// Actions
export const login = (accessToken, refreshToken) => {
  return async (dispatch) => {
    const authHeaders = {
      'Authorization': `Bearer ${accessToken}`
    };

    try {

      const queryUserProfile = api.get('v1/users/me', {
        headers: authHeaders,
      });

      const queryBirthProfile = api.get('v1/birth-profiles', {
        headers: authHeaders,
      });

      Promise.all([queryUserProfile, queryBirthProfile])
      .then(([profile, birthProfiles]) => {
          dispatch({
            type: LOGIN,
            payload: {
              accessToken: accessToken,
              refreshToken: refreshToken,
              profile: profile.data?.result?.data,
              birthProfiles: birthProfiles.data?.result?.data || [],
            }
          });
        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  };
}

export const setupUserInfos = () => {
  return async (dispatch) => {
    try {
      const queryUserProfile = api.get('v1/users/me');
      const queryBirthProfile = api.get('v1/birth-profiles');
      const queryUserSystemPreference = api.get('v1/users/me/preferences/system');
      const queryUserNotificationPreference = api.get('v1/users/me/preferences/notification');

      Promise.all([queryUserProfile, queryBirthProfile, queryUserSystemPreference, queryUserNotificationPreference])
        .then(([userProfile, birthProfiles, systemPreference, notificationPreference]) => {
          dispatch({
            type: SETUP_USER_INFOS,
            payload: {
              profile: userProfile.data?.result?.data,
              birthProfiles: birthProfiles.data?.result?.data || [],
              preference: systemPreference.data?.result?.data,
              notification_preference: notificationPreference.data?.result?.data
            }
          });
        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const fetchProfile = () => {
  return async (dispatch) => {
    try {
      const queryUserProfile = api.get('v1/users/me');

      Promise.resolve(queryUserProfile)
        .then((profile) => {
          dispatch({
            type: FETCH_PROFILE,
            payload: {
              profile: profile.data.result?.data,
            }
          });

        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const fetchBirthProfiles = () => {
  return async (dispatch) => {
    try {
      const queryBirthProfile = api.get('v1/birth-profiles');

      Promise.resolve(queryBirthProfile)
        .then((profiles) => {
          dispatch({
            type: FETCH_BIRTHPROFILES,
            payload: {
              birthProfiles: profiles.data?.result?.data || []
            }
          });

        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const fetchSystemPreferences = () => {
  return async (dispatch) => {
    try {
      const queryUserPreference = api.get('v1/users/me/preferences/system');

      Promise.resolve(queryUserPreference)
        .then((preference) => {
          dispatch({
            type: FETCH_SYSTEM_PREFERENCES,
            payload: {
              preference: preference.data?.result?.data || []
            }
          });

        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const fetchNotificationPreferences = () => {
  return async (dispatch) => {
    try {
      const queryUserPreference = api.get('v1/users/me/preferences/notification');

      Promise.resolve(queryUserPreference)
        .then((preference) => {
          dispatch({
            type: FETCH_NOTIFICATION_PREFERENCES,
            payload: {
              notification_preference: preference.data?.result?.data || []
            }
          });
        });

    } catch (error) {
      //TODO: Handle error
    }
  }
}

export const updateSystemPreference = (preference) => {
  return {
    type: UPDATE_SYSTEM_PREFERENCES,
    payload: {
      preference: preference
    }
  };
}

export const updateNotificationPreference = (preference) => {
  return {
    type: UPDATE_NOTIFICATION_PREFERENCES,
    payload: {
      notification_preference: preference
    }
  };
}

export const updateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    payload: {
      profile: profile
    }
  }
}

export const updateBirthProfile = (profile) => {
  return {
      type: UPDATE_BIRTHPROFILE,
      payload: {
        birthProfile: profile
      }
    };
}

export const deleteBirthProfile = (profile) => {
  return {
      type: DELETE_BIRTHPROFILE,
      payload: {
        birthProfile: profile
      }
    };
}

export const updateAccessToken = (token) => {
  return {
      type: UPDATE_ACCESS_TOKEN,
      payload: {
          accessToken: token
      }
  };
}


// Reducer
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      const payload = action.payload;

      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        profile: payload.profile,
        birthProfiles: payload.birthProfiles
      };
    }

    case SETUP_USER_INFOS: {
      const payload = action.payload;

      return {
        ...state,
        profile: payload.profile,
        birthProfiles: payload.birthProfiles,
        preference: payload.preference,
        notification_preference: payload.notification_preference
      };
    }

    case LOGOUT: {
      return {
        accessToken: null,
        refreshToken: null,
        profile: null,
        birthProfiles: [],
        preference: null,
        notification_preference: null
      };
    }

    case FETCH_PROFILE: {
      const payload = action.payload;
      return {
        ...state,
        profile: payload.profile
      };
    }

    case FETCH_BIRTHPROFILES: {
      const payload = action.payload;
      return {
        ...state,
        birthProfiles: payload.birthProfiles
      };
    }

    case FETCH_SYSTEM_PREFERENCES: {
      const payload = action.payload;
      return {
        ...state,
        preference: payload.preference
      }
    }

    case FETCH_NOTIFICATION_PREFERENCES: {
      const payload = action.payload;
      return {
        ...state,
        notification_preference: payload.notification_preference
      }
    }

    case UPDATE_NOTIFICATION_PREFERENCES: {
      const payload = action.payload;
      return {
        ...state,
        notification_preference: payload.notification_preference
      };
    }

    case UPDATE_SYSTEM_PREFERENCES: {
      const payload = action.payload;
      return {
        ...state,
        preference: payload.preference
      };
    }

    case UPDATE_PROFILE: {
      const payload = action.payload;
      return {
        ...state,
        profile: payload.profile
      };
    }

    case UPDATE_BIRTHPROFILE: {
        const changePrimaryProfile = () => {
            const primaryIndex = state.birthProfiles.findIndex(bp=>bp.is_primary===true)
            if(primaryIndex ===- 1) {
              return [...state.birthProfiles]
            } else {
              const primaryProfile = state.birthProfiles[primaryIndex];
              primaryProfile.is_primary = false;

              return [
                ...state.birthProfiles.slice(0, primaryIndex),
                primaryProfile,
                ...state.birthProfiles.slice(primaryIndex + 1)
              ]
            }
        }

        const birthProfile = action.payload.birthProfile;
        const index = state.birthProfiles.findIndex(bp=>bp.id===birthProfile.id);

        if(index === -1){
          if(birthProfile.is_primary) {
            const updatedProfiles = changePrimaryProfile();
            return {
              ...state,
              birthProfiles: [...updatedProfiles, birthProfile]
            }
          } else {
            return {
              ...state,
              birthProfiles: [...state.birthProfiles, birthProfile]
            }
          }

        } else {
            if(birthProfile.is_primary) {
              const updatedProfiles = changePrimaryProfile();
              return {
                ...state,
                birthProfiles: [...updatedProfiles.slice(0, index), birthProfile, ...updatedProfiles.slice(index + 1)]
              }
            } else {
              return {
                ...state,
                birthProfiles: [...state.birthProfiles.slice(0, index), birthProfile, ...state.birthProfiles.slice(index + 1)]
              }
            }
        }
    }

    case DELETE_BIRTHPROFILE: {
        const payload = action.payload;
        return {
          ...state,
          birthProfiles: state.birthProfiles.filter((profile) => profile.id !== payload.birthProfile.id)
        }
    }

    case UPDATE_ACCESS_TOKEN: {
      const payload = action.payload;
      return {
        ...state,
        accessToken: payload.accessToken
      };
    }

    default:
      return state;
  }
}
