import React, { useState } from "react";

import FAQ from "src/faq/component/faq/FAQ";
import MainLayout from 'src/core/component/MainLayout';


export default function FAQPage() {

  return (
    <MainLayout>
      <FAQ />
    </MainLayout>
  )
}
