import React,{useEffect, useState} from "react";

import CurrencyBanner from "../component/Banner/CurrencyBanner";
import CurrencyBuy from "../component/CurrencyBuy/CurrencyBuy";

function LoadWallet({profile}) {
  const currencies = [
    {
      name: "currency",
      display: "Pesa",
      value: "PP",
    },
    {
      name: "currency",
      display: "USD",
      value: "USD",
    },
    {
      name: "currency",
      display: "INR",
      value: "INR",
    },
    {
      name: "currency",
      display: "POUND",
      value: "GBP",
    },
    {
      name: "currency",
      display: "NPR",
      value: "NPR",
    },
    {
      name: "currency",
      display: "EURO",
      value: "EUR",
    },
    {
      name: "currency",
      display: "AUD",
      value: "AUD",
    }
  ];
  const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);

  useEffect(()=>{
    // console.log('currentCurrency',currentCurrency)

  },[currentCurrency])

  return (
    <>
      <CurrencyBanner 
        currentCurrency={currentCurrency} 
        setCurrentCurrency={setCurrentCurrency} 
        currencies={currencies} />
        
      <CurrencyBuy 
        currentCurrency={currentCurrency} 
        setCurrentCurrency={setCurrentCurrency} 
        currencies={currencies} />
    </>
  )
}

export default LoadWallet;
