import React from "react";
import { Link, useHistory } from "react-router-dom";

import {
  MobilePrevIconSvg,
  MobileNextIconSvg,
} from "src/core/component/assets/svg/CommonSVG";

const MobilePrevNextButton = ({
  hasPrev = true,
  hasNext = true,
  hasLoading = false,
  isLoading = false,
  prevLink = "#",
  nextLink = "#",
  classes = "",
  isDarkMode,
  onClickNext,
  onClickPrev,
  ...rest
}) => {
  // const history = useHistory();

  // onClickPrev = (e) => {
  //     e.preventDefault();
  //     history.goBack();
  // }

  return (
    <div
      className={"position-sticky pe-none" + (" " + classes)}
      style={{ bottom: 96, margin: "0 -15px" }}
    >
      {hasPrev && (
        <Link
          to={prevLink}
          className={
            "mobile-prev-link" +
            ((isDarkMode && " dark") || "") +
            ((isLoading && " disabled") || "")
          }
          onClick={onClickPrev}
        >
          <MobilePrevIconSvg />
        </Link>
      )}
      {hasNext && (
        <Link
          to={nextLink}
          className={
            "mobile-next-link" +
            ((isDarkMode && " dark") || "") +
            ((isLoading && " disabled") || "")
          }
          onClick={onClickNext}
        >
          <MobileNextIconSvg />

          {hasLoading && isLoading && (
            <span
              className="spinner-border spinner-border-sm text-light position-absolute"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </Link>
      )}
    </div>
  );
};

MobilePrevNextButton.defaultProps = {
  onClickNext: () => null,
  onClickPrev: () => null,
};

export default MobilePrevNextButton;
