import React, { useRef } from "react";

import Carousel from "src/core/component/slider/Carousel";
import NextArrow from "src/core/component/slider/arrow-customized/NextArrow";
import PreviousArrow from "src/core/component/slider/arrow-customized/PreviousArrow";
import GeneralPromoCard from "./GeneralPromoCard";

const PromoSliderSm = ({ promosData, isDarkMode, ...rest }) => {
  const promoSlider = useRef(null);

  const settings = {
    infinite: promosData.length > 3,
    autoplay: false,
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
    nextArrow: <NextArrow astro={false} isDarkMode={isDarkMode} />,
    prevArrow: <PreviousArrow astro={false} isDarkMode={isDarkMode} />,
    className: isDarkMode
      ? "pocket-slider pocket-promos__slider-sm dark"
      : "pocket-slider pocket-promos__slider-sm",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: promosData.length >= 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: promosData.length >= 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promosData.length >= 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promosData.length >= 1,
        },
      },
    ],
  };

  return (
    <>
      <Carousel
        sliderRef={promoSlider}
        {...settings}
        items={promosData.map((item, index) => (
          <React.Fragment key={index}>
            <GeneralPromoCard
              item={item}
              isDarkMode={isDarkMode}
            />
          </React.Fragment>
        ))}
      />
    </>
  );
};

export default PromoSliderSm;
