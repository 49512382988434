import React, {useLayoutEffect, useState } from 'react'
import api from "src/lib/api";
import './googleAds.scss'

let videoElement = null;
let adsManager = null;
const GoogleAds = () => {
  let adContainer;
  let googleAdsContainer;
  let adDisplayContainer;
  let adsLoader = null;
  let adsLoaded = false;
  let google_ads_id = null
  const [adsClicked,setAdsClicked] = useState(false)

  useLayoutEffect(()=>{
      videoElement = document.getElementById('video-element');
      adContainer =  document.getElementById('ad-container');
      googleAdsContainer =  document.getElementById('google-ads');
      initializeIMA();

      videoElement.addEventListener('play', function(event) {
        loadAds(event);
      });
      let playButton = document.getElementById('google-ads-button');
      playButton.addEventListener('click', function(event) {
        videoElement.play();
      });
  },[])

  useLayoutEffect(()=>{
    if(adsClicked){
      document.addEventListener("visibilitychange",visibilitychange);
    }
    return () => window.removeEventListener("visibilitychange", visibilitychange);
  },[adsClicked])
  
  
  function visibilitychange(){
    if (document.visibilityState == "visible") {
      setTimeout(window.location.reload(false),100)
    } else {
      adsManager.destroy();
    }
  }

  function initializeIMA() {
    adContainer.addEventListener('click', adContainerClick);

    if (!window?.google?.ima){
      // console.log('Please Disable Ads Block')
      return 0
    }


    adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, videoElement);
    adsLoader = new google.ima.AdsLoader(adDisplayContainer);
    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,onAdsManagerLoaded,false);
  adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,onAdError,false);
  
  
    // Let the AdsLoader know when the video has ended
    videoElement.addEventListener('ended', function() {
      adsLoader.contentComplete();
    });
  
    let adsRequest = new google.ima.AdsRequest();
    // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/tags
  
    adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
    // adsRequest.adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
    // adsRequest.adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/nonlinear_ad_samples&sz=480x70&cust_params=sample_ct%3Dnonlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
    // adsRequest.adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/simid&description_url=https%3A%2F%2Fdevelopers.google.com%2Finteractive-media-ads&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
  
    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
  
    // Pass the request to the adsLoader to request ads
    adsLoader.requestAds(adsRequest);
  }

  function onAdsManagerLoaded(adsManagerLoadedEvent) {
    // Instantiate the AdsManager from the adsLoader response and pass it the video element
    adsManager = adsManagerLoadedEvent.getAdsManager(videoElement);
    adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR,onAdError);
    adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,onContentPauseRequested);
    adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,onContentResumeRequested);
    if(adsManager?.rb){
      google_ads_id = adsManager.rb
    }
  }

  function onAdError(adErrorEvent) {
    // Handle the error logging.
    console.log(adErrorEvent.getError());
    if(adsManager) {adsManager.destroy();}
  }

function loadAds(event) {
  // Prevent this function from running on if there are already ads loaded
  if(adsLoaded) {
    return;
  }

  // Prevent triggering immediate playback when ads are loading
  // event.preventDefault();

  // console.log("loading ads");
  googleAdsContainer.style.zIndex = "10000";
  videoElement.classList.add('video-elements')

    // Initialize the container. Must be done via a user action on mobile devices.
    videoElement.load();
    adDisplayContainer.initialize();
    
    let width = videoElement.clientWidth;
    let height = videoElement.clientHeight;
    try {
      adsManager.init(width, height, google.ima.ViewMode.NORMAL);
      adsManager.start();
      setAdsClicked(true)
    } catch (adError) {
      // Play the video without ads, if an error occurs
      console.log("AdsManager could not be started",adError);
      // videoElement.play();
    }
}
function onContentPauseRequested() {
  videoElement.pause();
}

function onContentResumeRequested() {
  // console.log('------ads ended-------')
  update_ads_reward(google_ads_id);
  setTimeout(window.location.reload(false),100)
  // normal video started
  // videoElement.play(); // play normal video
}

function adContainerClick(event) {
  // console.log("ad container clicked");
  // if(videoElement.paused) {
  //   videoElement.play();
  // } else {
  //   videoElement.pause();
  // }
}

const update_ads_reward = async (google_ads_id) => {
  try {
      const result = await api.post(`v1/reward-points`,{google_ads_id:google_ads_id});

      // let res = result?.data?.result?.data;
      // console.log(res)
  }
  catch (error) {
  }
}

  return (
      <div id='google-ads' className='google-ads'>
        <div id="video-container" className='video-container'>
          <video id="video-element" className='video-element'>
              {/* <source src="https://storage.googleapis.com/interactive-media-ads/media/android.mp4"/> */}
              {/* <source src="https://storage.googleapis.com/interactive-media-ads/media/android.webm"/> */}
          </video>
          <div id="ad-container" className='ad-container'></div>
      </div>
            <button id="google-ads-button"></button>
    </div>
  )
}

export {GoogleAds,videoElement}
