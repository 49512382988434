import React from "react";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import StripeCardPayment from "src/core/component/stripe/StripeCardPayment";


const PaymentSetupModal = ({
    isDarkMode, 
    showModal,
    handleModalClose,
    amount,
    requestClientSecret,
    handlPaymentSuccess,
    processing,
    setProcessing,
    error,
    setError,
    buttonLabel,
    expired=false,
    showProcessingModal=false,
    ...rest
}) => {

    // console.log('Amount', amount);

    return (
        <>
            <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={false} centered={false} classes={`chat-setup__modal-payment ${isDarkMode && 'dark' || ''} ${processing && 'processing' || ''}`} backdrop={expired && "static" || "backdrop"}>
                <ModalBody>
                    <div className={`chat-setup__modal-payment-content text-center ${isDarkMode && 'dark' || ''}`}>
                        <h5 className="heading">Payment method</h5>
                        <StripeCardPayment 
                            amount={amount}
                            clientSecret={requestClientSecret}
                            onSuccess={handlPaymentSuccess}
                            processing={processing}
                            setProcessing={setProcessing}
                            error={error}
                            setError={setError}
                            isDarkMode={isDarkMode}
                            buttonLabel={buttonLabel}
                        />
                        
                    </div>
                    
                    {(showProcessingModal || processing) && (
                        <div className="processing-overlay"></div>
                    )}
                    
                </ModalBody>
            </CommonModal>
        </>
    )
}

export default PaymentSetupModal