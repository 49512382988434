import React from "react";


const CountDownTimer = ({datetime, setConversationExpired, title, isDarkMode, className, ...rest}) => {
    const [countdown, setCountdown] = React.useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        warn: false,
        expire_warn: false,
        expired: false,
    });
    
    React.useEffect(() => {
        setConversationExpired(false);
        
        const counter = setInterval(recalculate, 1000);
        recalculate();
    
        return () => {
          clearInterval(counter);
        }
    }, [datetime]);
    
    const recalculate = () => {
        const finalDate = new Date(datetime).getTime();
        const diff = finalDate - new Date().getTime();

        // console.log('Diff', diff); //total milliseconds
    
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        const minutes = Math.floor(diff % (1000 * 60 * 60) / (1000 * 60));
        const seconds = Math.floor(diff % (1000 * 60) / 1000);

        if(diff <= 0) {
            setConversationExpired(true);
        }
    
        setCountdown({
            days: days, 
            hours: hours, 
            minutes: minutes, 
            seconds: seconds,
            warn: diff > 300000 && diff <= 600000,
            expire_warn: diff <= 300000,
            expired: diff <= 0
        });
    }
    
    const { hours: h, minutes: m, seconds: s } = countdown;
    
    return (
        <div className={`pocket-countdown ${className && className || ''} ${isDarkMode && 'dark' || ''}`}>
            <div className="timer-countdown">
                {countdown.expired && (
                    <span className="timer expired">Expired</span>
                ) || (
                    <>
                        <span>{title}: </span> 
                        <span className={`timer ${countdown.expire_warn && 'expire_warn' || ''} ${countdown.warn && 'warning' || ''}`}>{countdown.days && `${countdown.days}D, ` || ''}{h > 9 && h || `0${h}`} : {m > 9 && m || `0${m}`} : {s > 9 && s || `0${s}`}</span>
                    </>
                )}
            </div>
        </div>
    )
}

export default CountDownTimer;
