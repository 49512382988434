import { loadJSON } from 'src/lib/json-store';
import api from 'src/lib/api';

// Inital state
const initialState = {
  promoNotificationData: loadJSON('promo.promoNotificationData') || [],
  lastChecked: loadJSON('promo.lastChecked') || null
};

// Action types
const SETUP_PROMO_NOTIFICATION_DATA = 'SETUP_PROMO_NOTIFICATION_DATA';
const UPDATE_PROMO_NOTIFICATION_LAST_CHECK = 'UPDATE_PROMO_NOTIFICATION_LAST_CHECK';
const UPDATE_PROMO_NOTIFICATION_DATA = 'UPDATE_PROMO_NOTIFICATION_DATA';
const UPDATE_PROMO_NOTIFICATION_DATA_BULK = 'UPDATE_PROMO_NOTIFICATION_DATA_BULK';
const CLEAR_PROMO_NOTIFICATION_DATA = 'CLEAR_PROMO_NOTIFICATION_DATA';

// Actions
export const setupPromoData = (datetime) => {
    return async (dispatch) => {
        try {
            const promoResult = api.get(`v1/discount-offers?active=true&after=${datetime}&limit=50`);
            Promise.resolve(promoResult)
                .then((promos) => {
                    const promoData = promos.data?.result?.data || [];
                    // console.log('Promo Data', promoData)
                    const data = (promoData || []).map(promo=>({'id': promo.id}));
                    dispatch({
                        type: SETUP_PROMO_NOTIFICATION_DATA,
                        payload: {
                            promoNotificationData: data
                        }
                    });
            });
        } catch (error) {
            console.log('Error fetching promos');
        }
    }
}

export const updatePromoLastCheck = (datetime) => {
    return {
        type: UPDATE_PROMO_NOTIFICATION_LAST_CHECK,
        payload: {
            lastChecked: datetime
        }
    };
}

export const updatePromoData = (data) => {
    return {
        type: UPDATE_PROMO_NOTIFICATION_DATA,
        payload: {
            data: data
        }
    };
}

export const updatePromoDataBulk = (data) => {
    return {
        type: UPDATE_PROMO_NOTIFICATION_DATA_BULK,
        payload: {
            data: data
        }
    };
}

export const clearPromoData = () => {
    return {
        type: CLEAR_PROMO_NOTIFICATION_DATA
    };
}

// Reducer
export default function promoNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case SETUP_PROMO_NOTIFICATION_DATA: {
        const payload = action.payload;
        return {
            ...state,
            promoNotificationData: payload.promoNotificationData
        };
    }

    case UPDATE_PROMO_NOTIFICATION_LAST_CHECK: {
        const payload = action.payload;
        return {
            ...state,
            lastChecked: payload.lastChecked
        };
    }

    case UPDATE_PROMO_NOTIFICATION_DATA: {
        const payload = action.payload;
        const promoIndex = state.promoNotificationData.findIndex(p => p.id == payload.data.id);
        if (promoIndex === -1) {
            return {
                ...state,
                promoNotificationData: [...state.promoNotificationData, payload.data]
            }
        } else {
            return state
        }
    }

    case UPDATE_PROMO_NOTIFICATION_DATA_BULK: {
        const data = action.payload.data;
        const uniqueData = data.filter(d => !state.promoNotificationData.map(p=>p.id).includes(d.id));
        // console.log('Unique Data', uniqueData);
        if (uniqueData && uniqueData.length) {
            return {
                ...state,
                promoNotificationData: [...state.promoNotificationData, ...uniqueData]
            }
        } else {
            return state
        }
    }

    case CLEAR_PROMO_NOTIFICATION_DATA: {
        return {
            ...state,
            promoNotificationData: []
        };
    }

    default:
      return state;
    }
}
