import React, { useEffect,useReducer } from 'react'
import useAuthInfo from "src/auth/hook/auth-info";
import queryString from "query-string";
import api from 'src/lib/api';
import { useSocket } from "src/socket/component/SocketContext";

const INITIAL_STATE = {
    posts:[],
    loading:false,
    error:null,
    currentPage:1,
    limit:10,
    metadata:null,
    category_id:'',
    post_state:{id:0,name:'All Posts',value:'all'},

}

const useYourPosts = ({currentFilter,isAscending,activeCategory}) => {
    const { profile } = useAuthInfo();
  const socket = useSocket();
    const [state,dispatch] = useReducer(yourPostReducer , {...INITIAL_STATE,category_id:activeCategory?.id})

  const fetchYourPosts = async (controller)=>{
    try {
        dispatch({type:'FETCH_YOUR_POSTS_REQUEST'})
        const res = await api.get(`v1/forum-posts-metrics?${queryString.stringify({
            'state':state.post_state?.value == 'all' ? null : state.post_state?.value,
            'page':state.currentPage,
            'limit':state.limit,
            'user_id':profile?.id,
            'only_users_post':profile?.id,
            'filter_by':currentFilter,
            'ascending':isAscending,
            'category_id':state.category_id 
        }, {
          skipEmptyString: true,
          skipNull: true,
        })}`,{signal:controller.signal}
      );
        if(res && res.data){
            dispatch({type:'FETCH_YOUR_POSTS_SUCCESS',payload:res?.data?.result})
        }
        } catch(error){
        dispatch({type:'FETCH_YOUR_POSTS_FAILURE',payload:error})
        }
  }

  useEffect(()=>{
    const controller = new AbortController()
    if(profile){
        fetchYourPosts(controller);
    }

    return () => {
      controller.abort()
    }

  },
  [state.post_state?.value,state.currentPage,state.category_id,state.limit,currentFilter,isAscending]
  )

  useEffect(() => {
    const onForumPostMetrics = ({ data }) => {
        dispatch({'type':'FETCH_YOUR_POST_REPLACE_METRICS',payload:data?.post})
    }
    socket.on("forum_post:metrics",onForumPostMetrics);
    return () => {
      socket.off("forum_post:metrics", onForumPostMetrics);
    };
    
  },[])


  const handleFilterChange = (item)=>{
    dispatch({type:'FILTER_CHANGE',payload:item})
  }

  const handlePageReset = ()=>{
    dispatch({type:'PAGE_RESET'})
  }

  const handleCategoryChange = (category_id) => {
    dispatch({type:'CATEGORY_CHANGE',payload:category_id})
  }

  const handleAddMore = ()=>{
    if (state.metadata && state.metadata?.next && state.metadata.next > 1) {
        dispatch({type:'FETCH_YOUR_POSTS_ADD_MORE'})
    }
  }

  const handlePostReplace = (post)=>{
      dispatch({type:'FETCH_YOUR_POST_REPLACE',payload:post})
  }

  const handlePostAdd = (post)=>{
      dispatch({type:'ADD_POST',payload:post})
  }

  const deleteDraft = (id)=>{
      dispatch({type:'DELETE_DRAFT',payload:id})
  }

  return { state,handleFilterChange,handleAddMore,handlePostReplace,handlePostAdd,deleteDraft,handleCategoryChange,handlePageReset }
}

const yourPostReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_YOUR_POSTS_REQUEST':
            return {
                ...state,
                loading:true,
            }
        case 'FETCH_YOUR_POSTS_ADD_MORE':
            return {
                ...state,
                loading:true,
                currentPage:state.currentPage + 1,

            }
        case 'FETCH_YOUR_POSTS_SUCCESS':
            return {
                ...state,
                loading:false,
                posts: state.currentPage == 1 ? action.payload?.data : [...state.posts, ...action.payload?.data],
                metadata:action.payload?.metadata,
            }
        case 'FETCH_YOUR_POSTS_FAILURE':
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case 'FILTER_CHANGE':
            return {
                ...state,
                post_state:action.payload,
                limit:10,
                currentPage:1,
            }
        case 'PAGE_RESET':
            return {
                ...state,
                currentPage:1,
            }
        case 'CATEGORY_CHANGE':
            return {
                ...state,
                category_id:action.payload,
                limit:10,
                currentPage:1,
            }
        case 'FETCH_YOUR_POST_REPLACE':
            return {
                ...state,
                currentPage:1,
                posts:[...state.posts.map((post)=>post.id == action.payload?.id ? action.payload : post)],
            }
        case 'ADD_POST':
            return {
                ...state,
                posts:[action.payload,...state.posts],
            }
        case 'DELETE_DRAFT':
            return {
                ...state,
                posts:[...state.posts.filter((post)=>post.id != action.payload)],
            }
        case 'FETCH_YOUR_POST_REPLACE_METRICS':
            return {
                ...state,
                posts:[...state.posts.map((post)=>post.id == action.payload?.id ? {...post, metrics:action.payload?.metrics } : post)],
            }
        default:
            return state
    }
}

export default useYourPosts