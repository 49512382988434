import React from "react";
import { useHistory } from "react-router";
import queryString from "query-string";

import useAuthInfo from "src/auth/hook/auth-info";
import { useTheme } from "src/theme/component/ThemeContext";
import BrandLogo from "./BrandLogo";
import MobileNav from "./mobile/MobileNav";
import NavItems from "./NavItems";

export default function Navbar() {
  const history = useHistory();
  const { isAuthenticated, logout, profile } = useAuthInfo();
  const [isDarkMode, toggleTheme] = useTheme();

  const handleLogin = () => {
    history.push("/auth/login");
  };

  //Show Confirm Prompt if logging out from RTP answer page.
  const handleLogout = (e) => {
    e.preventDefault();
    const pathname = history.location.pathname;
    const urlParams = queryString.parse(history.location.search);
    if(pathname === '/realtime-prediction' && urlParams?.stage === 'answer') {
      const confirm = window.confirm('Are you sure you want to leave current page?');
      if(confirm === true) {
        logout();
      }
    } else {
      logout();
    }
  };

  return (
    <nav
      className={
        "navbar navbar-light navbar-expand-sm bg-white navigation" +
        ((isDarkMode && " dark") || "")
      }
    >
      <div className="container-xl align-items-sm-center">
        <BrandLogo isDarkMode={isDarkMode} />

        {/* Mobile Nav Item */}
        <MobileNav
          isAuthenticated={isAuthenticated}
          profile={profile}
          login={handleLogin}
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
        />

        {/* <TogglerButton /> */}

        <div
          className={
            "collapse navbar-collapse justify-content-end navigation__collapse" +
            (isAuthenticated ? " navigation__logged" : "")
          }
          id="pocket-nav"
        >
          <NavItems
            isAuthenticated={isAuthenticated}
            logout={handleLogout}
            login={handleLogin}
            profile={profile}
            isDarkMode={isDarkMode}
            toggleTheme={toggleTheme}
          />
        </div>
      </div>
    </nav>
  );
}
