import React, {useState} from "react"
import ForumReplyItemCard from "src/forumV2/forumDetail/ForumReplyItemCard"
import api from 'src/lib/api';
import { PocketToast } from "src/core/component/toast/PocketToast";


const ForumReplyItem = ({reply, post, isDarkMode, ...rest}) => {
    const [error, setError] = useState(null);
    const [showReplyForm, setShowReplyForm] = useState(false)
    const [replies, setReplies] = useState(reply.replies);
    const [loading, setLoading] = useState(false);
    const [replyData, setReplyData] = useState({
        'parent_reply_id': reply.id,
        'content': '',
    });
    const [replyTextLength, setReplyTextLength] = useState(1000);

    const handleInputChange = (e) => {
        if(e.target.name === 'content' && e.target.value.length === replyTextLength) {
            PocketToast({
                type: "warning", 
                message: `Reply should not be longer than ${replyTextLength} characters`
            })
        }
        setReplyData({ ...replyData, [e.target.name]: e.target.value});
    }

    const handleOnClickReply = () => {
        setShowReplyForm(prevState => !prevState)
    }

    const handleOnclickReport = () => {
        console.log('Report Clicked...')
    }

    const handleReplySubmit = (e) => {
        e.preventDefault();
        createPostReply(replyData);
    }

    const createPostReply = async (data) => {
        setLoading(true);
        try {
            const result = await api.post(`v1/forum-posts/${reply.post_id}/replies`, data)
            setReplies([...replies, result?.data?.result?.data]);
            setReplyData({...replyData, content: ""});
        } catch (error) {
            let errorData = error?.response?.data?.error || error
            setError(errorData?.message || 'Failed to submit a reply!');
        } finally {
            setLoading(false);
        }
    }

    // console.log('Reply Data', replyData);

    return (
        <div className={"forum-reply-item-wrapper" + (isDarkMode && ' dark' || '')}>
            <ForumReplyItemCard
                item={reply}
                post={post}
                replies={replies}
                onClickReply={handleOnClickReply}
                onClickReport={handleOnclickReport}
                isDarkMode={isDarkMode}
                onReplySubmit={handleReplySubmit}
                onChange={handleInputChange}
                showReplyForm={showReplyForm}
                replyData={replyData}
                error={error}
                loading={loading}
                maxLength={replyTextLength}
            />
        </div>
    )
}

export default ForumReplyItem