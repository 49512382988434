import React, {useState, useEffect} from "react";

import ProfileFilter from "src/core/component/birthprofile/ProfileFilter";
import Profiles from "src/core/component/birthprofile/Profiles";
import useSelect from "src/core/hook/useSelect";
import api from 'src/lib/api';
import { PocketToast } from "src/core/component/toast/PocketToast";
import LoadingIcon from "src/core/component/loader/LoadingIcon";


const CustomerBirthProfiles = ({
    user, 
    selectedProfile, 
    setSelectedProfile, 
    setShowKundali,
    isDarkMode, 
    ...rest
}) => {
    const [filter, setFilter] = useState({
        'relationship_id': 'all',
    })
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [selected, handleSelect, setSelectionSpace, isSelected] = useSelect([], [], false);

    useEffect(() => {
        user && fetchUserBirthProfiles()
    }, [user])

    const fetchUserBirthProfiles = async () => {
        setLoading(true);

        try {
            // ?user_id=${user.id}
            const result = await api.get(`/v1/birth-profiles?user_id=${user.id}`);
            setProfiles(result.data?.result?.data || []);
            setLoading(false);
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching birth-profiles."
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchRelationships()
    }, [])

    const fetchRelationships = async () => {
        try {
            const result = await api.get(`v1/relationship`);
            setRelationships(result.data?.result?.data || []);
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching relationships."
            })
        }
    }

    useEffect(() => {
        setSelectionSpace(profiles)
    }, [profiles]);

    useEffect(() => {
        if(selectedProfile && profiles && profiles.length) {
            handleSelect(null, [selectedProfile])
        }
    }, [profiles, selectedProfile])

    const onChangeFilter = (e) => {
        setFilter({ ...filter, 'relationship_id': e.id})
    }

    const handleProceed = () => {
        const selectedProfile = selected && selected.length && selected[0] || null;
        if(selectedProfile) {
            setSelectedProfile(()=>selectedProfile);
            setShowKundali(true);
        }
    }

    // console.log('Profiles', profiles);
    // console.log('relationships', relationships)

    return (
        <div className="pocket-astrologer__chat-birth-profile">
            <div className="row">
                {loading && (
                    <div className="col-12">
                        <LoadingIcon />
                    </div>
                )}

                {!loading && profiles && (
                    <>
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between title-filter">
                                <div className={"title" + (isDarkMode && ' dark' || '')}>
                                    <h4>Select birth profile</h4>
                                </div>
                                <div className={"relationship-filter" + (isDarkMode && ' dark' || '')}>
                                    {relationships && (
                                        <ProfileFilter
                                            isDarkMode={isDarkMode}
                                            filter={filter}
                                            relationships={relationships}
                                            onChangeFilter={onChangeFilter}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <Profiles 
                                isDarkMode={isDarkMode} 
                                filter={filter}
                                profileData={profiles}
                                handleProfileClick={handleSelect}
                                profileIsSelected={isSelected}
                                canDelete={false}
                                canEdit={false}
                                isSelf={false}
                            />
                        </div>

                        {selected && selected.length > 0 && (
                            <div className="col-12 mt-1">
                                <button className="pocket-btn float-end" onClick={handleProceed}>Proceed</button>
                            </div>
                        )}
                    </>
                )}

                {/* {!loading && (!profiles || !profiles.length) && (
                    <SimpleAlert classes="alert-danger">
                        <small className="text-sm">No Birth Profiles exists</small>
                    </SimpleAlert>
                )} */}
                
            </div>
        </div>
    )
}

export default CustomerBirthProfiles;
