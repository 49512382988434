import React, {useState, useEffect} from "react"
import { useHistory } from "react-router";
import queryString from "query-string";


const SearchInput = ({ isDarkMode, url, placeholder, hasFilter=false, ...rest }) => {
    const history = useHistory();

    const [query, setQuery] = useState({
        'query': ''
    });
    
    const [isInvalid, setIsInvalid] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        if(query.query){
            setQuery({'query': query.query})
        }
    }, [history.location])

    const handleInputChange = (e) => {
        setQuery({ ...query, [e.target.name]: e.target.value});
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if(!query.query) {
            setError('Please enter a search term');
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
            
            history.push({
                pathname: url,
                search: queryString.stringify(query, {
                  skipEmptyString: true,
                }),
            });
        }
    }

    // console.log('Query', query)

    return (
        <div className={`pocket-banner__search-form ${isDarkMode && 'dark' || ''} ${hasFilter && 'filter' || ''}`}>
            <form className={`pocket-form mb-0 ${isDarkMode && 'dark' || ''}`} onSubmit={handleSearch}>  
                <div className="row">
                    <div className="col-12 field">
                        <div className="input-group">
                            <input type="text" className={"form-control" + (isInvalid && " is-invalid" || "")} placeholder={placeholder && placeholder || "search topics..."} name="query" id="query" value={query['query'] || ''} onChange={handleInputChange} autoComplete="query" required />
                            <span className="input-group-text pocket-btn" onClick={handleSearch}>Search</span>
                            <div className="invalid-feedback">
                                {error}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SearchInput