import { loadJSON } from 'src/lib/json-store';

// Inital state
const initialState = {
  astrologer: loadJSON('chat.astrologer') || null
};

// Action types
const SELECT_ASTROLOGER = 'SELECT_ASTROLOGER';
const CLEAR_ASTROLOGER = 'CLEAR_ASTROLOGER';

// Actions
export const selectAstrologer = (astrologer) => {
    return {
        type: SELECT_ASTROLOGER,
        payload: {
            astrologer: astrologer
        }
    };
}

export const clearSelectedAstrologer = () => {
    return {
        type: CLEAR_ASTROLOGER
    };
}

// Reducer
export default function customerChatReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ASTROLOGER: {
        const payload = action.payload;

        return {
            ...state,
            astrologer: payload.astrologer
        };
    }

    case CLEAR_ASTROLOGER: {
        return {
            astrologer: null
        };
    }

    default:
      return state;
    }
}
