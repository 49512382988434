import React from 'react'
  
    const findGender = (name = "") => {
    let gender = "";
    switch (name.trim()) {
      case "Son":
      case "Grand Son":
      case "Grandson":
      case "Brother":
      case "Father":
        gender = "male";
        break;
      case "Daughter":
      case "Grand Daughter":
      case "Granddaughter":
      case "Sister":
      case "Mother":
        gender = "female";
        break;
      default:
        gender = "";
    }
    return gender;
  };

export default findGender