import React, { useState } from 'react'
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { PocketToast } from "src/core/component/toast/PocketToast";
import api from 'src/lib/api'

const PromoCodeRedeemModal = ({
  handleModalShow,
  handleModalClose}) => {
    const [redeemCode,setRedeemCode] = useState('')
    const [busy, setBusy] = useState(false);
    const inputRef = React.useRef();

    const handleRedeemCodeChange=(e)=>{
      let value = e.target.value
      setRedeemCode(value)
    }

    const handleRedeemClick = async (e) => {
      e.preventDefault();

      if(busy || !redeemCode) {return;}

      setBusy(true);
      let payload = {'code': redeemCode};

      try {
        const res = await api.post(`v1/apply_redeem_code`, payload);

        const result = (res.data?.result);

        if (result) {
          PocketToast({
            type:'success',
            message: 'Congratulations! Redeem Points has been added to your wallet!'
          });
        }

        setRedeemCode('');
        handleModalClose();
      } catch (error) {
        let errMsg = error.response?.data?.error?.message || error.message;
        PocketToast({type:'error',message: errMsg});

        if (inputRef.current) {
          inputRef.current.select();
          inputRef.current.focus();
        }
      } finally {
        setBusy(false);
      }
    }

  return (
    <div>
      <CommonModal showModal={handleModalShow} classes="forum_promo_code_modal" backdrop="static"
        showCustomClose={true} size={"lg"} handleModalClose = {handleModalClose} >
          <ModalBody>
            <div className='title'>Redeem Points</div>
            <div className='sub_title'>Get faster response with points.</div>
            <div className='modal_input'>
              <input ref={inputRef} type="text" className='w-75' value={redeemCode} onChange={(e)=>handleRedeemCodeChange(e)}
                    placeholder="Enter Coupon Code"/>
              <span onClick={handleRedeemClick}>Redeem</span>
            </div>
            <div className='model_info'>
              <span>Why Redeem Points?</span>
              <ol>
                <li>Get your prediction in 24 hours.</li>
                <li>Use points in any purchase within the site.</li>
              </ol>
            </div>
            </ModalBody>
        </CommonModal>
    </div>
  )
}

export default PromoCodeRedeemModal