import React from "react";


const CenteredDiv = ({classes, text, ...rest}) => {
  
  return (
            <div className={"d-flex justify-content-center align-items-center "  + classes }>
                {text}
            </div>
        )
}

export default CenteredDiv
