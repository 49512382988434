import React from "react";

import QuestionList from "src/core/component/question/QuestionList";
import ViewMoreLink from "src/promos/component/promodetails/ViewMoreLink";


const CategoryQuestions = ({
    questions, 
    promo, 
    handleQuestionSelect,
    profile,
    isDarkMode, 
    handleBrowseMore, 
    handleGetPredictionClick, 
    processing=false,
    isAuthenticated,
    ...rest
}) => {
    
    if(promo && promo.scope==='category' && promo.categories.length && questions){
        questions = questions.filter(item=>item.category_id === promo.categories[0].id)
    }

    return (
        <div className="category-contents__question">
            {questions && questions?.length > 0 && (
                <QuestionList
                    questions={questions && questions.slice(0,4) || []}
                    handleQuestionSelect={handleQuestionSelect}
                    profile={profile}
                    isDarkMode={isDarkMode}
                />
            )}

            {questions && questions?.length > 4 && (
                <ViewMoreLink 
                    title={'view more questions'}
                    url={'/realtime-prediction?stage=question'}
                    onClick={handleBrowseMore}
                    className={`d-flex justify-content-end`}
                    isDarkMode={isDarkMode}
                />
            )}

            {isAuthenticated && (
                <div className="get-prediction d-flex justify-content-end mt-3">
                    <button className={`pocket-btn`} onClick={handleGetPredictionClick}>
                        {processing && 'Processing...' || 'Get Prediction'}
                    </button>
                </div>
            )}
            
        </div>
    )
}

export default CategoryQuestions
