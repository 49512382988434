import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { PlusSvg } from "src/core/component/assets/svg/CommonSVG";
import api from "src/lib/api";
import { useTheme } from "src/theme/component/ThemeContext";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from "src/auth/hook/auth-info";
import * as promoApi from "src/lib/http/promo";
import useSelect from "src/core/hook/useSelect";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import CategoryQuestions from "src/promos/component/promodetails/categorypromo/CategoryQuestions";
import OfferedCategories from "./OfferedCategories";
import OfferedQuestions from "./OfferedQuestions";
import PromoBanner from "./PromoBanner";
import RelationshipContents from "src/promos/component/promodetails/relationshippromo/RelationshipContents";
import ConfirmationModal from "src/core/component/modal/ConfirmationModal";
import { useModal } from "src/core/hook/useModal";
import OfferedRelationship from "./OfferedRelationship";
import { PocketToast } from "src/core/component/toast/PocketToast";
import Auth from "src/core/component/prediction/Auth";
import BirthProfiles from "src/core/component/prediction/BirthProfiles";
// import LoadingCLS from "src/core/component/loader/LoadingCLS";

import placeholderSmall from "src/core/component/loader/assets/placeholder-small.webp";
import placeholderLarge from "src/core/component/loader/assets/placeholder-large.webp";

const PromoDetails = ({ promoId, ...rest }) => {
  const history = useHistory();
  const [isDarkMode] = useTheme();
  const [promo, setPromo] = useState(null);
  const [promoBanner, setPromoBanner] = useState({
    image_url: placeholderSmall,
    medium_image_url: placeholderLarge,
    large_image_url: placeholderLarge,
  })
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, birthProfiles } = useAuthInfo();
  const {
    rtpOrder,
    setOrderInfo,
    isQuestionInCart,
    draftCartLines,
    addToDraftCart,
    removeFromDraftCart,
    updateDraftCartLines,
    paymentDisclaimerShow,
    validateDraftCart,
    syncDrafCart
  } = useRTPInfo();

  const [showModal, handleModalShow, handleModalClose] = useModal(false);
  const [relationships, setRelationships] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [clickedEntity, setClickedEntity] = useState({
    entity: null,
    item: null,
    message: null,
  });

  const [processing, setProcessing] = useState(false);

  const [categoryResponse, setCategoryResponse] = useState(null);
  const [questionResponse, setQuestionResponse] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [relationshipLoading, setRelationshipLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);

  const [filter, setFilter] = useState({
    relationship_id: "all",
    p_relationship_id: "all",
    category_id: []
  });

  const [
    profileSelected,
    handleProfileSelect,
    setProfileSelectionSpace,
    isProfileSelected,
  ] = useSelect([], [], false);
  const [
    categorySelected,
    handleCategorySelect,
    setCategorySelectionSpace,
    isCategorySelected,
  ] = useSelect([], [], true);
  const [
    questionSelected,
    handleQuestionSelect,
    setQuestionSelectionSpace,
    isQuestionSelected,
  ] = useSelect([], [], true);

  useEffect(()=>{
  syncDrafCart()
},[])

  useEffect(() => {
    if (promoId) {
      fetchPromo(promoId);
    }
  }, [promoId]);

  const fetchPromo = async (id) => {
    setLoading(true);
    try {
      const result = await promoApi.getPromoDetails(id);
      setPromo(result?.data?.result?.data);
    } catch (error) {
      console.log('Failed to fetch promo')
    } finally {
      setLoading(false);
    }
  };

  // Handle filter(birth profile filter)
  useEffect(() => {
    if (promo && promo.scope === "relationship" && promo.relationships.length) {
      setFilter({
        ...filter,
        relationship_id: promo.relationships[0]?.id,
        p_relationship_id: promo.relationships[0]?.id
      });
    } else if (promo && promo.scope === "question" && promo.questions.length) {
      setFilter({
        ...filter,
        relationship_id: promo.questions[0]?.relationship_id,
        p_relationship_id: promo.questions[0]?.relationship_id
      });
    } else if(promo && promo.scope === "category" && promo.categories.length) {
      setFilter({
        ...filter,
        category_id: promo.categories[0]?.id
      });
    }

    if (promo) {
      setPromoBanner(promo);
    }
  }, [promo]);

  useEffect(() => {
    fetchRelationships();
  }, []);

  useEffect(() => {
    if(promo && !loading) {
      fetchCategories();
      fetchQuestions();
    }
  }, [filter, promo, loading]);

  const fetchRelationships = async () => {
    setRelationshipLoading(true);
    try {
      const result = await api.get("v1/relationship");
      setRelationships(result?.data?.result?.data || []);
    } catch (error) {
      console.log("Error Fetching Relationships");
    } finally {
      setRelationshipLoading(false);
    }
  };

  const fetchCategories = async () => {
    if(categoryLoading) return;

    setCategoryLoading(true);
    try {
      const query = {
        relationship_id: filter.relationship_id !== "all" ? filter.relationship_id : "",
      };

      const result = await api.get(`v1/question-category?${queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`);
      setCategoryResponse(result)
    } catch (error) {
      setCategoryResponse(error)
      console.log("Error Fetching categories");
    } finally {
      setCategoryLoading(false);
    }
  };

  const fetchQuestions = async () => {
    if(questionLoading) return;

    setQuestionLoading(true);
    try {
      const query = {
        relationship_id: filter.relationship_id !== "all" ? filter.relationship_id : "",
        category_id: filter.category_id
      };

      const result = await api.get(`v1/questions?${queryString.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`);
      setQuestionResponse(result)
    } catch (error) {
      setQuestionResponse(error);
      console.log("Error Fetching questions");
    } finally {
      setQuestionLoading(false);
    }
  };

  useEffect(() => {
    if(!categoryLoading && categoryResponse) {
      const categoryData = categoryResponse.data?.result?.data || [];
      setCategories(categoryData);
    }
  }, [categoryResponse, categoryLoading])

  useEffect(() => {
    if(!questionLoading && questionResponse) {
      const questionData = questionResponse.data?.result?.data || [];
      setQuestions(questionData);
    }
  }, [questionResponse, questionLoading])

  useEffect(() => {
    if (categories.length) {
      setCategorySelectionSpace(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (questions.length) {
      setQuestionSelectionSpace(questions);
    }
  }, [questions]);

  //State manage when profile is selected
  useEffect(() => {
    handleQuestionSelect(null, []);

    if (profileSelected.length) {
      setFilter({
        ...filter,
        relationship_id: profileSelected[0].relationship_id,
      });
    } else {
      if (filter.p_relationship_id === "all" && filter?.relationship_id !== "all") {
        setFilter({
          ...filter,
          relationship_id: "all",
        });
      }
    }
  }, [profileSelected]);

  // Manage Category auto-select based on Promo scope
  useEffect(() => {
    if (promo && categories && categories.length) {
      if (promo.scope === "question" && promo.questions.length) {
        let category_id = promo.questions[0]?.category_id;
        handleCategorySelect(
          null,
          (category_id &&
            categories.filter((item) => item.id === category_id)) ||
            []
        );
      } else if (promo.scope === "category" && promo.categories.length) {
        let category = promo.categories[0];
        handleCategorySelect(null, (category && [category]) || []);
      } else if (promo.scope === "relationship" && promo.relationships.length) {
        let relationshipQuestions =(questions || [] ).filter((q) => q.relationship_id === promo.relationships[0]?.id);
        let questionCategoryIds = (relationshipQuestions || []).map((q) => q.category_id);
        let finalCategories = (questionCategoryIds && categories.filter((c) => questionCategoryIds.includes(c.id))) || [];
        handleCategorySelect(
          null,
          (finalCategories.length && [finalCategories[0]]) || []
        );
      }
    }
  }, [categories, questions, promo]);

  const hasProfile = () => {
    return profileSelected && profileSelected.length > 0;
  };

  const handleQuestionClick = (e, question) => {
    if(questionLoading) return;

    if (!hasProfile()) {
      PocketToast({
        type: "error",
        message: "Please select a Birth Profile"
      });
      return;
    }

    handleQuestionSelect(e, question);
    const selectedProfileId = profileSelected[0].id;
    const line = { question_id: question.id, profile_id: selectedProfileId }
    if (isQuestionInCart(question.id, selectedProfileId)) {
      removeFromDraftCart(line)
    } else {
      addToDraftCart(line)
    }
  };

  const hasQuestion = () => {
    if(promo.scope === "question" && promo.questions.length) {
      return true
    } else {
      return draftCartLines && draftCartLines.length > 0;
    }
  };

  const handleGetPredictionClick = (e) => {
    e.preventDefault();
    if (!hasProfile()) {
      PocketToast({
        type: "error",
        message: "You must select a Birth Profile to proceed",
      });
      return;
    }

    if (!hasQuestion()) {
      PocketToast({
        type: "error",
        message: "You must select a Question to proceed",
      });
      return;
    }

    prepareAndCreateCart();
  };

  const prepareAndCreateCart = () => {
    let cartData = JSON.parse(JSON.stringify(draftCartLines)) || [];
    // if the promo type is question then add the question to cart(expected behavior)
    if (promo && promo.scope === "question" && promo.questions.length && profileSelected.length) {
      const questionCartData = {
        profile_id: profileSelected[0].id,
        question_id: promo.questions[0].id
      }
      addToDraftCart(questionCartData)
      cartData.push(questionCartData)
    }
    // console.log('Cart Data Initial', cartData)

    let uniqueCartLines = cartData.filter(
      (line1, index, lineArray) => lineArray.findIndex((line2) => line2.profile_id === line1.profile_id && line2.question_id === line1.question_id) === index
    );
    // console.log('Unique Cart Lines Final', uniqueCartLines);

    updateCart(uniqueCartLines);
  };

  const updateCart = async (lineItems) => {
    if(!lineItems.length) {
      PocketToast({
        type: "warning",
        message: "You must select at least one question to proceed"
      });
      return;
    }

    let validatedDraftCart = [...lineItems];
    
    try {
        const publishedQuestions = await api.post("/v1/questions/validate-published", {
          question_ids: lineItems.map(l=>l.question_id),
        });
        const questions = publishedQuestions?.data?.result?.data || [];
        const published_question_ids = questions.map(q=>q.id);
        validatedDraftCart = lineItems.filter(l=>published_question_ids.includes(l.question_id));
    } catch (error) {
      // console.log('Validate error')
    }

    if (validatedDraftCart.length > rtpOrder?.order_lines?.length) {
      paymentDisclaimerShow();
    }

    setProcessing(true);

    try {
      const res = await api.post('v2/question-orders/cart', {
        'line_items': validatedDraftCart
      });
      const _order = res.data?.result?.data || null;
      setOrderInfo(_order);
      setProcessing(false);

      const cartItemsCount = (_order?.order_lines || []).length;

      if(!cartItemsCount) {
        PocketToast({
          type: "warning",
          message: "You must select at least one question to proceed"
        });
      } else {
        //Note: Not really needed but to be on safe side
        const cartLines = (_order?.order_lines || []).map(l=>({question_id: l.question_id, profile_id: l.profile_id}))
        updateDraftCartLines(cartLines);
        
        fbq('track', 'AddToCart'); //NOTE: Facebook Meta Pixel tracking event
        history.push('/instant-prediction/payment');
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message || error?.message;
      PocketToast({
        type: "error",
        message: errorMsg,
        autoClose: 5000,
      });
    }
  }

  // when clicked on any entity browse more button.
  const handleEntityBrowseMore = (e) => {
    e.preventDefault();
    history.push("/instant-prediction");
  }

  //Click on category item in Browse other categories section
  const handleClickOfferedCategory = (e, item) => {
    setClickedEntity({
      entity: "category",
      item: item,
      message:
        "You will be redirected to Instant Prediction page for more detail view",
    });
    handleModalShow();
  };

  //Click on relationship item in Browse other relationships section
  const handleClickOfferedRelationship = (e, item) => {
    setClickedEntity({
      entity: "relationship",
      item: item,
      message:
        "You will be redirected to Instant Prediction page for more detail view",
    });
    handleModalShow();
  };

  // Redirect to the respective page on RTP when clicked proceed button on confirmation popup.
  const handleProceedConfirm = () => {
    if (!clickedEntity) {
      return;
    }

    if (clickedEntity.entity === "category") {
      handleModalClose();
      history.push({
        pathname: "/instant-prediction",
        state: {
          category_id: clickedEntity?.item?.id
        }
      });
    } else if (clickedEntity.entity === "relationship") {
      handleModalClose();
      history.push({
        pathname: "/instant-prediction",
        state: {
          relationship_id: clickedEntity?.item?.id
        }
      });
    } else {
      handleModalClose();
    }
  };

  const filteredProfiles = (birthProfiles || []).filter(p=>p.relationship_id === filter.relationship_id);

  // console.log('Promo Item', promo);
  // console.log('Filter', filter);
  // console.log('Cart Lines', draftCartLines)

  return (
    <section
      className={`pocket-promo__details ${(isDarkMode && "dark") || ""}`}
    >
        <div
          className={`pocket-promo__details ${(isDarkMode && "dark") || ""}`}
        >
          <PromoBanner promo={promoBanner} isDarkMode={isDarkMode} />

          {
            !loading && promo && (
              <div
                className={`pocket-promo__details-contents rtp-layoutTwo ${
                  (isDarkMode && "dark") || ""
                }`}
              >
                <div className="container-xl">
                  {!isAuthenticated && (
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-11 col-xl-10 mb-2 mt-0">
                          <Auth authRoute={`/instant-prediction/auth`} state={{from: history.location.pathname}} hasTitle={false} hasFilter={false} />
                        </div>
                      </div>
                  )}

                  {isAuthenticated && (
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-11 col-xl-10">
                        {promo && promo?.scope !== 'category' && filteredProfiles.length > 0 && (
                          <div className="d-flex justify-content-between">
                            <div className="me-auto">
                                <h5 className="rtp-filter__heading mb-0">Select birth profile</h5>
                            </div>
                            <div className="promo-create-profile mt-0 mb-0 ms-auto">
                              <Link
                              className="pocket-btn"
                                to={{
                                  pathname: "/instant-prediction/birth-profile",
                                  state: {
                                    relationship_id: filter.relationship_id
                                  }
                                }}
                              >
                                 <PlusSvg /> &nbsp;Add Birth Profile
                              </Link>
                            </div>
                          </div>
                        )}

                        <BirthProfiles
                            filter={filter}
                            setFilter={setFilter}
                            setProfileSelectionSpace={setProfileSelectionSpace}
                            handleProfileSelect={handleProfileSelect}
                            isProfileSelected={isProfileSelected}
                            resetSelectionOnFilter={true}
                            createEditURL={`/instant-prediction/birth-profile`}
                            hasFilter={promo?.scope === "category"}
                            hasFilterTitle={true}
                            filterTitle={"Select birth profile"}
                        />

                        {promo.scope === "question" && (
                          <div className="get-prediction d-flex justify-content-end mt-3">
                            <button
                              className={`pocket-btn get-prediction`}
                              onClick={handleGetPredictionClick}
                            >
                              {(processing && "Processing...") || "Get Prediction"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {promo.scope === "category" && (
                    <div
                      className={`category-contents mt-3 ${
                        (isDarkMode && "dark") || ""
                      }`}
                    >
                      <div className="row justify-content-center">
                        <div className="col-12 col-lg-11 col-xl-10">
                          {!questionResponse && <LoadingIcon />}

                          {questionResponse && questions && (
                            <CategoryQuestions
                              isDarkMode={isDarkMode}
                              promo={promo}
                              questions={questions || []}
                              handleQuestionSelect={handleQuestionClick}
                              handleBrowseMore={handleEntityBrowseMore}
                              handleGetPredictionClick={handleGetPredictionClick}
                              processing={processing}
                              profile={profileSelected && profileSelected[0]}
                              isAuthenticated={isAuthenticated}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {promo.scope === "relationship" && (
                    <div
                      className={`relationship-contents mt-3 ${
                        (isDarkMode && "dark") || ""
                      }`}
                    >
                      {(!questionResponse || !categoryResponse) && <LoadingIcon />}

                      {questionResponse && categoryResponse && (
                        <RelationshipContents
                          categories={categories || []}
                          questions={questions || []}
                          promo={promo}
                          categorySelected={categorySelected}
                          handleQuestionSelect={handleQuestionClick}
                          profile={profileSelected && profileSelected[0]}
                          handleCategorySelect={handleCategorySelect}
                          isCategorySelected={isCategorySelected}
                          handleBrowseMore={handleEntityBrowseMore}
                          handleGetPredictionClick={handleGetPredictionClick}
                          processing={processing}
                          isDarkMode={isDarkMode}
                          isAuthenticated={isAuthenticated}
                        />
                      )}
                    </div>
                  )}

                  <div
                    className={`related-contents ${promo.scope} ${
                      (isDarkMode && "dark") || ""
                    }`}
                  >
                    <div className="row justify-content-center">
                      {promo.scope === "relationship" && (
                        <div className="col-12 col-lg-11 col-xl-10">
                          <OfferedRelationship
                            isDarkMode={isDarkMode}
                            promo={promo}
                            relationships={relationships || []}
                            handleRelationshipSelect={
                              handleClickOfferedRelationship
                            }
                            handleBrowseMore={handleEntityBrowseMore}
                          />
                        </div>
                      )}

                      {(promo.scope === "question" ||
                        promo.scope === "category") && (
                        <>
                          <div className="col-12 col-lg-11 col-xl-10">
                            {!questionResponse && <LoadingIcon />}

                            {questionResponse && (
                              <OfferedQuestions
                                isDarkMode={isDarkMode}
                                promo={promo}
                                questions={questions || []}
                                handleQuestionSelect={handleQuestionClick}
                                profile={profileSelected && profileSelected[0]}
                                handleBrowseMore={handleEntityBrowseMore}
                              />
                            )}
                          </div>

                          <div className="col-12 col-lg-11 col-xl-10">
                            <OfferedCategories
                              isDarkMode={isDarkMode}
                              promo={promo}
                              categories={categories || []}
                              handleCategorySelect={handleClickOfferedCategory}
                              handleBrowseMore={handleEntityBrowseMore}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* Confimation Modal */}
                <ConfirmationModal
                  showModal={showModal}
                  handleModalClose={handleModalClose}
                  handleProceedClick={handleProceedConfirm}
                  text={clickedEntity?.message}
                  isDarkMode={isDarkMode}
                />
              </div>
            )
          }
        </div>
    </section>
  );
};

export default PromoDetails;
