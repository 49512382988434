import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { FreeMode, Navigation,Mousewheel } from "swiper";
import { CustomPrevArrow, CustomNextArrow } from "src/shop/component/shop/ProductSlider";
import api from "src/lib/api";
import Category from "./Category";
import SwiperCarousel from "src/core/component/slider/SwiperCarousel";
import All from "../assets/all-category@2x.png";
import "../scss/Categories.scss";

const Categories = ({
  rtpFilterIds,
  categorySelected,
  handleCategorySelect,
  setCategorySelectionSpace,
  isCategorySelected,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryResponse, setCategoryResponse] = useState(null);

  const settings = {
    slidesPerView: "auto",
    spaceBetween: 10,
    mouseWheel :true,
    modules: [Navigation, FreeMode,Mousewheel],
    navigation: {
      prevEl: ".instant-pred-category-navigation-prev",
      nextEl: ".instant-pred-category-navigation-next",
    },
  };

  useEffect(() => {
    fetchCategoryList().then();
  }, [rtpFilterIds.p_relationship_id]);

  const fetchCategoryList = async () => {
    try {
      const query = {
        relationship_id:
          rtpFilterIds.relationship_id !== "all"
            ? rtpFilterIds.p_relationship_id
            : "",
      };
      const response = await api.get(
        `/v1/question-category?${queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      setCategoryResponse(response);
    } catch (error) {
      console.log("RTP Category Error", error);
      setCategoryResponse(error);
    }
  };

  useEffect(() => {
    setCategoryList(categoryResponse?.data?.result?.data || []);
  }, [categoryResponse]);

  // useEffect(() => {
  //   if ((categoryList || []).length) {
  //     setCategorySelectionSpace(categoryList);
  //   }
  // }, [categoryList]);

  // console.log("categoryList", categoryList);

  return (
    <div className="rtp-category">
      <h5 className="rtp-headingTwo">
        Choose a category for related questions
      </h5>
      <div>
      <div className="d-flex">
        <Category
          category={{
            icon_url: All,
            name: "All",
          }}
          className={`rtp-category__item h-auto ${
            rtpFilterIds.category_id.length < 1 ? "selected" : ""
          }`}
          handleCategorySelect={() => handleCategorySelect(null, [])}
        />

        {categoryResponse && categoryList.length > 0 && (
          <SwiperCarousel
            {...settings}
            items={categoryList.map((item) => {
              return (
                <Category
                  key={item.id}
                  category={item}
                  className={`rtp-category__item ${
                    isCategorySelected(item) ? "selected" : ""
                  }`}
                  handleCategorySelect={handleCategorySelect}
                />
              );
            })}
          />
        )}
      </div>
        <CustomPrevArrow classNames="instant-pred-category-navigation-prev" />
         <CustomNextArrow classNames="instant-pred-category-navigation-next"/>
         </div>
    </div>
  );
};

export default Categories;
