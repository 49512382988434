import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, Modal } from "react-bootstrap";
import { CheckedIconSvg, NotificationSvg } from "../assets/svg/CommonSVG";
import api from 'src/lib/api';


function QuestionNotifyModal({ show, setShow, question, profileId, source="", classes = ""}) {
  const history = useHistory();
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [error, setError] = useState();
  
  const handleModalClose = () => {
    setShow(false);
    if(source && source==="external") {
      history.push('/instant-prediction')
    }
  }

  const handleQuestionSubscription = async () => {
    if(!question || !profileId) return;

    try {
        const response = await api.post("v1/question-subscription", {
            "question_id": question.id,
            "birth_profile_id": profileId
        });
        setHasSubscribed(true);
      } catch (error) {
        const errorMsg = error?.response?.data?.error?.message || error?.message ||  'Unknown error'
        setError(errorMsg)
      }
  };

  return (
    <Modal show={show} onHide={handleModalClose} className={classes ? classes : ""}>
      <Modal.Body>
        <div className="question-notify-modal">
          <div className={`question-notify-modal__image ${hasSubscribed ? " checked-image" : ""}`}>
            {(hasSubscribed && <CheckedIconSvg />) || <NotificationSvg />}
          </div>
          <div className="question-notify-modal__description">
            {(hasSubscribed && (
              <>
                <h5>Your Notificaion is set.</h5>
                <p>You will be notified with an email notification.</p>
              </>
            )) || (
              <>
                <h5>
                  The question will release on <span>{new Date(question.schedule_date).format("MMM dd, yyyy")}</span>
                </h5>
                <p>Do you want to get notified when the question is out?</p>
              </>
            )}
          </div>
          {error && (
            <small className="d-block text-danger mb-1">{error}</small>
          )}
          <div className="question-notify-modal__buttons">
            {(hasSubscribed && (
              <button className="pocket-btn" onClick={handleModalClose}>
                Go Back
              </button>
            )) || (
              <>
                <button
                  className="pocket-btn"
                  onClick={() => handleQuestionSubscription()}
                >
                  Yes, Notify Me!
                </button>
                <button className="btn btn-link" onClick={handleModalClose}>
                  No, Thanks
                </button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default QuestionNotifyModal;
