import React from "react";

import FormField from "src/core/component/form/FormField";


const AccountUpdateForm = ({
    handleSubmit,
    handleChange,
    formData,
    isDarkMode, 
    ...rest 
}) => {

    let fields = [
        {
            name: 'screen_name',
            placeholder: 'username',
            label: 'Username',
            type: 'text',
            required: true,
            hasInstruction:true,
            instruction:'* alphabets and numbers only.'
        },  {
            name: 'name',
            placeholder: 'Full Name',
            label: 'Full Name',
            type: 'text',
            required: true
        },
        {
            name: 'login',
            placeholder: 'Email',
            label: 'Email',
            type: 'email',
            required: true,
            disabled: true
        }
    ]

    return ( 
        <div className={"accounts__form" + (isDarkMode && ' dark' || '')}>
            <form onSubmit={handleSubmit} className={"pocket-form mb-0" + (isDarkMode && ' dark' || '')} autoComplete="off">
                <div className={`row gx-2 gx-lg-4 gx-xl-5`}>
                    {
                        fields && fields.map((item, index) =>{
                            return (
                                <div className="col-12 col-sm-6 field" key={index}>
                                    {(item.type === "text" || item.type === "email") && (
                                        <FormField 
                                            property={item.name}
                                            type={item.type}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={handleChange}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                            autoComplete={"off"}
                                            hasInstruction={item.hasInstruction || false}
                                            instruction={item.instruction || ""}
                                            disabled={item.disabled || false}
                                        />
                                    )}
                                    
                                </div>
                            )
                        })
                    }
                    <div className="col-12">
                        <button className="pocket-btn d-none" type="submit">Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AccountUpdateForm
