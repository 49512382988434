import React from "react";


const ConnectWithSocial = ({classes, onClick, text, name, icon, disabled, ...rest}) => {
    onClick = typeof onClick === 'function' ? onClick : function noop() { }
  
  return (
            <div className={"social " + classes + (disabled && ' disabled' || '')} onClick={onClick}>
                {icon}
                {text}
            </div>
        )
}

export default ConnectWithSocial
