import React from 'react'
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import LogoLight from "src/core/component/LogoLight"
import { LinkSvg } from '../assets/svg/CommonSVG'

const FollowModal = ({showFollowModal,handleFollowModalShow,handleFollowModalClose}) => {

  return (
    <div>
    {showFollowModal && (
      <CommonModal showModal={handleFollowModalShow} classes="instant_pred_follow_modal"
        showCustomClose={true} size={"lg"} handleModalClose = {handleFollowModalClose} >
          <ModalBody>
          {/* <LogoLight width="50px" /> */}
              {/* <div className="content">
                  <div>FOR <span>FREE</span> PREDICTION</div>
                  <ul>
                    <li><span>Follow</span> and <span>Share</span> any two of our social media page.</li>
                    <li>Message us the <span>Screenshot</span> of both follow and share on our <span>Instagram</span> and <span>Facebook</span></li>
                    <li>Get your unique <span>Coupon Code</span> for a <span>FREE Prediction</span></li>

                    <li>
                        <span className='d-block'>Get Your:</span>
                        <span className='d-block li_second'>Love Reading, Marriage Reading,</span>
                        <span className='d-block li_last'>Career Reading  and many more...</span>
                    </li>
                  </ul>
              </div> */}
              {/* <div className="links">
                <div>Links</div>
                <div><a target="_blank" href="https://www.tiktok.com/@pocketpandit">tiktok.com/@pocketpandit</a></div>
                <div><a target="_blank" href="https://www.facebook.com/pocketpandit.astrology">facebook.com/pocketpandit.astrology</a></div>
                <div><a target="_blank" href="https://www.instagram.com/pocketpandit/">instagram.com/pocketpandit</a></div>
              </div> */}
            </ModalBody>
        </CommonModal>
      )}
    </div>
  )
}

export default FollowModal