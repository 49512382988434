import React from "react";
import { Link } from "react-router-dom";

import api from 'src/lib/api';
import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import ChatUserList from "src/connect-with-astrologer/component/chat/ChatUserList";
import ChatConversationList from "src/connect-with-astrologer/component/chat/ChatConversationList";
import ChatMessageList from "src/connect-with-astrologer/component/chat/ChatMessageList";
import ConversationDetail from "src/connect-with-astrologer/component/chat/ConversationDetail";

import 'src/connect-with-astrologer/component/chat/chat-message.css';
import { useHistory } from "react-router-dom";
import ScrollView from "src/core/component/ScrollView";

const ConnectHome = () => {
  const history = useHistory();
  const [conversations, setConversations] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState('conversation-tab');
  const [activeConversation, setActiveConversation] = React.useState(null);
  const [isDarkMode] = useTheme();

  const handleUserSelect = async (user) => {
    try {
      const res = await api.post('v1/conversations', {
        'user_id': user.id,
      });

      const conversation = res.data.result?.data;

      setConversations([
        conversation,
        ...conversations,
      ]);

      history.push({
        pathname: window.location.pathname,
        search: '?c=' + conversation['name'],
      });

      setActiveTab('conversation-tab');
    } catch (error) {
      error = (error.response?.data?.error || error);
      console.log('Error creating new conversation', error);
    }
  }

  const selectTab = (e) => {
    e.preventDefault();
    setActiveTab(
      e.currentTarget.href.split('#').pop()
    );
  }

  const reloadConversation = async () => {
    try {
      const res = await api.get('v1/conversations');
      const conversationList = (res.data.result?.data || []);

      setConversations(conversationList);
    } catch (error) {
      console.log('Error fetching conversations', error);
    }
  }

  React.useEffect(() => {
    reloadConversation();
  }, []);

  return (
    <MainLayout>
      <section className={`container-xl pocket-connect__astrologer ${isDarkMode && 'dark' || ''}`}>
        <div className="row g-0">
          <ScrollView className="col-3 py-4" style={{ height: 'calc(100vh - 80px)' }}>

            <ul className="nav nav-pills nav-fill" role="tablist">
              <li className="nav-item">
                <a href="#conversation-tab" onClick={selectTab} className={"nav-link small" + (activeTab === 'conversation-tab' && ' active' || '')} data-toggle="pill" role="tab"> <strong>CONVERSATION</strong> </a>
              </li>

              <li className="nav-item">
                <a href="#astrologers-tab" onClick={selectTab} className={"nav-link small" + (activeTab === 'astrologers-tab' && ' active' || '')} data-toggle="pill" role="tab"> <strong>ASTROLOGERS</strong> </a>
              </li>
            </ul>

            <hr />

            <div className="tab-content">

              <div className={"tab-pane fade" + (activeTab === 'conversation-tab' && " show active" || '')}>
                <ChatConversationList items={conversations} reload={reloadConversation} onSelectItem={setActiveConversation}>

                  <h5 className="text-muted text-center small">
                    You do not have any conversation. Start chatting with our <a href="#astrologers-tab" onClick={selectTab}>astrologers</a> from around the globe.
                  </h5>

                </ChatConversationList>
              </div>

              <div className={"tab-pane fade" + (activeTab === 'astrologers-tab' && " show active" || '')}>
                  <h5 className="text-muted text-center small">START A NEW CONVERSATION WITH AN ASTROLOGER</h5>
                  <ChatUserList onSelectItem={handleUserSelect} />
              </div>

            </div>

          </ScrollView>
          <div className="col-5 border border-2 border-top-0 border-bottom-0" style={{ height: 'calc(100vh - 80px)'}}>
            {
              activeConversation
              && (
                <ChatMessageList conversation={activeConversation} />
              )
              || (
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <div className="text-center">
                    <h5 className="text-muted text-center">
                      Once you start a new conversation with an astrologer you'll see it listed here.
                    </h5>
                    <br />
                    <a href="#astrologers-tab" className="text-small" onClick={selectTab}><strong>CHAT WITH ASTROLOGER</strong></a>
                  </div>
                </div>
              )
            }
          </div>
          <div className="col-4">

            <div className="mx-2">
              {
                activeConversation && <ConversationDetail conversation={activeConversation} />
              }
            </div>

          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default ConnectHome;
