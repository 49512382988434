import React from 'react'
import { Container } from 'react-bootstrap'
import WalletReport from '../component/WalletDashboard/WalletReport'
import WalletTransactions from '../component/WalletDashboard/WalletTransactions'

const WalletDashboard = ({profile}) => {
  return (
    <section className="gbt-pocket-peso">
      <Container fluid="xl">
          <div className="gbt-pocket-peso-dashboard ">
            <WalletReport profile={profile} />
            <WalletTransactions/>
          </div>
        </Container>
    </section>
  )
}

export default WalletDashboard