import React from "react";
import { Link } from "react-router-dom";
import { ClockSvg } from "src/core/component/assets/svg/AstrologySVG";

import SimpleAlert from "src/core/component/SimpleAlert";
import { isObjectEmpty } from "src/util/extra";

const BirthProfileAlert = ({ onClick=()=>{}, relationship={}, className="" }) => {
  function getAlertText() {
    if (!isObjectEmpty(relationship)) {
      if (relationship?.is_self) {
        return "birth time?"
      } else {
        return relationship?.name + "'s birth time "
      }
    } else {
      return "birth time "
    }
  }

  return (
    <SimpleAlert classes={`birth-profile__alert ${className}`}>
    <ClockSvg/>
      <span className="mb-0 ms-3 alert-text">
        {`If you don't know your ${getAlertText()}`}
      </span>
      <Link to="#" onClick={onClick} className="popup-link">
        <b> click here</b>
      </Link>
    </SimpleAlert>
  );
};

export default BirthProfileAlert;
