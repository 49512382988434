import React, { useState } from "react";
import { PasswordHideSvg, PasswordRevealSvg } from "../component/assets/svg/CommonSVG";


export const usePasswordToggle = () => {
  const [visible, setVisibility] = useState(false);

    const toggleVisibility = () => {
      // console.log('Toggle Visibility');
      setVisibility(!visible);
    }

    const Icon = visible ? <PasswordRevealSvg /> : <PasswordHideSvg />

    const InputType = visible ? "text" : "password";

    return [InputType, Icon, toggleVisibility];
};