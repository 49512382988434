import React from "react"
import { Link } from "react-router-dom";


const CheckboxField = ({
        id,
        property,
        classes="",
        onChange,
        value,
        children,
        isInvalid=false,
        error="*You need to accept this field",
        ...res
    }) => {

  return (
        <>
            <div className={"form-check " + classes}>
                <input className={"form-check-input small" + (isInvalid && " is-invalid" || "")} type="checkbox" name={property} id={property} checked={value} onChange={onChange} />
                {
                    children
                }
                <div className="invalid-feedback">
                    {error}
                </div>
            </div>
        </>
    )
}

export default CheckboxField
