import React, { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";

import { useTheme } from "src/theme/component/ThemeContext";

import api from "src/lib/api";
import { validateEmail, validatePhone } from "src/util/extra";
import FormField from "src/core/component/form/FormField";
import TextArea from "src/core/component/form/TextArea";
import Map from "./Map";

import {
  EmailSvgDark,
  EmailSvgLight,
  LocationSvgDark,
  LocationSvgLight,
  PhoneSvgDark,
  PhoneSvgLight,
} from "src/core/component/assets/svg/CommonSVG";

const fields = [
  {
    id: 1,
    name: "name",
    placeholder: "Enter your Full Name",
    label: "Your Name",
    type: "text",
    hasIcon: false,
    required: true,
  },
  {
    id: 2,
    name: "email",
    placeholder: "Enter email address",
    label: "Email Address",
    type: "email",
    hasIcon: false,
    required: true,
  },
  {
    id: 3,
    name: "phone",
    placeholder: "Enter phone number",
    label: "Phone Number",
    type: "text",
    hasIcon: false,
    required: true,
  },
  {
    id: 4,
    name: "message",
    placeholder: "What do you want to ask us?",
    label: "Your Message",
    type: "textarea",
    hasIcon: false,
    required: true,
  },
];

const Contact = () => {
  const [isDarkMode] = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [status, setStatus] = useState({
    success: "",
    error: "",
  });
  const [formError, setFormError] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const formDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
    setFormError({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setFormError((prevState) => ({
        ...prevState,
        email: "Email address not valid.",
      }));
    }

    if (!validatePhone(formData.phone)) {
      setFormError((prevState) => ({
        ...prevState,
        phone: "Phone number not valid.",
      }));
    }

    if (!validateEmail(formData.email) || !validatePhone(formData.phone))
      return;

    try {
      await api.post("/v1/contact_us", {
        ...formData,
      });
      setStatus({
        ...status,
        success: "Your message was successfully sent",
      });
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      setStatus({
        ...status,
        error: "We could not process your request, please try again",
      });
    } finally {
      setTimeout(() => {
        setStatus({
          success: "",
          error: "",
        });
      }, 3000);
      setFormError({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };

  return (
    <section className="gbt-contact">
      <Container fluid="lg">
        <div className="gbt-contact__heading text-center">
          <h2 className="mb-3">Get in touch</h2>
          <p className={`${isDarkMode?' dark':' '}`}>
           We would love to hear from you. If you'd like to get in touch with us, please fill the form below. And we will get back to you.
          </p>
        </div>

        <div className="gbt-contact__address">
          <ul className="list-unstyled mb-0 row row-cols-1 row-cols-sm-3 g-4 justify-content-center">
            <li className="col">
              {isDarkMode ? <PhoneSvgDark /> : <PhoneSvgLight />}
              <a className="telephone" href="tel:+13028280931">
                +1 (302) 828-0931
              </a>
            </li>
            <li className="col">
              {isDarkMode ? <EmailSvgDark /> : <EmailSvgLight />}
              <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a>
            </li>
            <li className="col">
              {isDarkMode ? <LocationSvgDark /> : <LocationSvgLight />}
              <span>
                PocketPandit, INC 600 N Broad Street, Suite 5 #3519 Middletown,
                DE 19709
              </span>
            </li>
          </ul>
        </div>

        <div className="gbt-contact__form">
          <Form
            className={isDarkMode ? "dark" : ""}
            onSubmit={handleFormSubmit}
          >
            <Row className="mb-3 g-lg-5">
              {((status.success || status.error) && (
                <div className="col-12 gbt-contact__status">
                  <div
                    className={
                      status.success
                        ? " gbt-contact__status_success"
                        : " gbt-contact__status_error"
                    }
                  >
                    {status.success || status.error}
                  </div>
                </div>
              )) ||
                ""}

              <div className="col-12 col-sm-6">
                {(fields.length || null) &&
                  fields.map((field) => {
                    return (
                      (field.type === "text" || field.type === "email") && (
                        <div className="field" key={field.id}>
                          <FormField
                            classes="is-invalid"
                            property={field.name}
                            label={field.label}
                            hasIcon={field.hasIcon}
                            type={field.type}
                            placeholder={field.placeholder}
                            required={field.required}
                            value={formData[field.name]}
                            isInvalid={formError[field.name]}
                            onChange={formDataChangeHandler}
                          />
                        </div>
                      )
                    );
                  })}
              </div>
              <div className="col-12 col-sm-6">
                {(fields.length || null) &&
                  fields.map((field) => {
                    return (
                      field.type === "textarea" && (
                        <div className="field textarea" key={field.id}>
                          <TextArea
                            property={field.name}
                            label={field.label}
                            hasIcon={field.hasIcon}
                            type={field.type}
                            onChange={formDataChangeHandler}
                            placeholder={field.placeholder}
                            required={field.required}
                            rows={6}
                            value={formData[field.name]}
                          />
                        </div>
                      )
                    );
                  })}
              </div>

              <div className="col-12 mt-2 text-end">
                <button className="pocket-btn" type="submit">
                  Submit
                </button>
              </div>
            </Row>
          </Form>
        </div>

        <Map />
      </Container>
    </section>
  );
};

export default Contact;
