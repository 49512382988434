import React from "react";
import {Link} from "react-router-dom";
import { Container } from "react-bootstrap";

import defaultImg from 'src/image/default.jpg';
import Logo from "src/wallet/images/Logo";
import CurrencySvg from "src/wallet/images/Collection";
import "./Header.scss";

function Header({profile}) {
  return (
    <header className="header-two">
      <Container>
        <div className="header-two__inner">
          <div className="header-two__logo">
            <Logo />
          </div>

          <div className="header-two__options">
            <Link to="/wallet/transactions" className="btn btn-promo">Transactions</Link>

            <div className="header-two__user">
              <figure className="header-two__avatar">
                <img src={defaultImg} alt="" />
              </figure>
            </div>

            <div className="header-two__currency">
              <Link to="/wallet">
                <CurrencySvg />
                <span className="header-two__currency-text">{parseFloat(profile?.wallet_balance?.dollar*profile?.wallet_balance?.exchange_rate).toFixed(2)}</span>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
