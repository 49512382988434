import React from "react";
import PricingSummary from "src/profilesetting/component/orders/orderdetail/PricingSummary";
import ChatOrderInvoices from "./ChatOrderInvoices";
import ConversationSummary from "./ConversationSummary";

const ChatOrderSummary = ({conversation, invoices, isDarkMode, ...rest }) => {

    return ( 
        <div className={"order-summary chat" + (isDarkMode && ' dark' || '')}>
            <h5 className="title">
                Order Summary
            </h5>
            
            <ConversationSummary
                conversation={conversation}
                isDarkMode={isDarkMode}
            />

            <div className="separator my-3"></div>

            <ChatOrderInvoices
                invoices={invoices}
                isDarkMode={isDarkMode}
            />

            <div className="separator my-3"></div>

            <PricingSummary
                title={""}
                order={conversation}
                predictionOrder={false}
                isDarkMode={isDarkMode}
                tax=''
            />
            
        </div>
    )
}

export default ChatOrderSummary
