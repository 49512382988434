import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'

import FormField from 'src/core/component/form/FormField';
import { StripeSvg } from "src/core/component/assets/svg/CommonSVG";
import { PocketToast } from "src/core/component/toast/PocketToast";


const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`pocket-btn d-block w-100 ${error ? "pocket-btn--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ className, children }) => (
  <div className={"element-error-msg " + (className && className || "")} role="alert">
    * {children && typeof children === 'string' && (
      <>
        {children}
      </>
    )}
  </div>
);

export default function StripeCardPayment({
  amount,
  onSuccess,
  processing,
  setProcessing,
  error,
  setError,
  clientSecret: requestClientSecret,
  buttonLabel = "",
  disabled = false,
  recurring = false,
  isDarkMode = false
}) {
  const [cardNumberError, setCardNumberError] = useState(null)
  const [cardExpiryError, setCardExpiryError] = useState(null)
  const [cardCvcError, setCardCvcError] = useState(null)
  const [cardNumberComplete, setCardNumberComplete] = useState(null)
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false)
  const [cardCvcComplete, setCardCvcComplete] = useState(false)
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: ""
  });

  const stripe = useStripe();
  const elements = useElements();

  const CARD_NUMBER_OPTIONS = {
    iconStyle: "solid",
    showIcon: true,
    style: {
      base: {
        iconColor: "#00579F",
        color: isDarkMode ? "#CCCCCC" : "#0A1D40",
        fontWeight: 400,
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#929292"
        }
      },
      invalid: {
        iconColor: "#C72F1E",
        color: "#C72F1E"
      }
    }
  }

  const CARD_EXPIRY_CVC_OPTIONS = {
    style: {
      base: {
        iconColor: "#00579F",
        color: isDarkMode ? "#CCCCCC" : "#0A1D40",
        fontWeight: 400,
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#929292"
        }
      },
      invalid: {
        iconColor: "#C72F1E",
        color: "#C72F1E"
      }
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!stripe || !elements) { return; }

    if (cardNumberError || cardExpiryError || cardCvcError) {
      setError('Invalid card informations!')
      return;
    }

    if (!cardNumberComplete || !cardExpiryComplete || !cardCvcComplete) {
      setError('Please fill the card informations correctly!')
      return;
    }

    if (cardNumberComplete && cardExpiryComplete && cardCvcComplete) {
      setProcessing(true);
    }

    const res = await requestClientSecret(billingDetails);

    if (res['error']) {
      setError(res.error);
      setProcessing(false);
      PocketToast({
        type: "error",
        message: res?.error && res.error || "Sorry! can't process your payment."
      })
      return;
    }

    let data = res['data']['result']['data'];

    const result = await stripe.confirmCardPayment(data.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          'name': billingDetails['name'],
          'email': billingDetails['email'] || undefined
        },
      },
      setup_future_usage: recurring ? 'off_session' : 'on_session',
    });

    if (result.error) {
      const errorMsg = result.error?.message || 'Sorry! can\'t process your payment.';

      setError(errorMsg);
      setProcessing(false);
      PocketToast({
        type: "error",
        message: errorMsg
      });
      return;
    }

    const paymentIntent = result.paymentIntent;

    data = {
      ...data,
      'amount': (paymentIntent.amount || 0) / 100,
      'currency': paymentIntent.currency,
    };

    onSuccess(data);
  }

  return (
    <form className={"payment" + (isDarkMode && ' dark' || '')} onSubmit={handleFormSubmit}>
      {/* <div className="field">
          <FormField
            property={"email"}
            type={"email"}
            hasLabel={false}
            placeholder={"Email"}
            value={billingDetails.email}
            required={true}
            autoComplete={"off"}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
        </div> */}

      <div className="field">
        <CardNumberElement
          options={CARD_NUMBER_OPTIONS}
          onChange={(e) => {
            setCardNumberError(e.error);
            setCardNumberComplete(e.complete);
          }}
        />
        {cardNumberError && <ErrorMessage className={"mt-1"}>{cardNumberError.message}</ErrorMessage>}
      </div>

      <div className="field">
        <div className="row g-2">
          <div className="col-6">
            <CardExpiryElement
              options={CARD_EXPIRY_CVC_OPTIONS}
              onChange={(e) => {
                setCardExpiryError(e.error);
                setCardExpiryComplete(e.complete);
              }}
            />
            {cardExpiryError && <ErrorMessage className={"mt-1"}>{cardExpiryError.message}</ErrorMessage>}
          </div>
          <div className="col-6">
            <CardCvcElement
              options={CARD_EXPIRY_CVC_OPTIONS}
              onChange={(e) => {
                setCardCvcError(e.error);
                setCardCvcComplete(e.complete);
              }}
            />
            {cardCvcError && <ErrorMessage className={"mt-1"}>{cardCvcError.message}</ErrorMessage>}
          </div>
        </div>
      </div>

      {/* <div className="field">
          <FormField
            property={"name"}
            type={"text"}
            hasLabel={false}
            placeholder={"Name"}
            value={billingDetails.name}
            required={true}
            autoComplete={"off"}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
        </div> */}

      {error && <ErrorMessage className={"mb-2"}>{error || `Sorry! can't process your payment.`}</ErrorMessage>}

      <div className="payment-submit d-flex justify-content-mdlg-end mb-2">
        <SubmitButton processing={processing} error={error} disabled={!stripe || !amount || disabled}>
          {buttonLabel && buttonLabel || 'View Your Prediction'}
        </SubmitButton>
      </div>
      {/* <div className="powered-by d-flex justify-content-mdlg-end">
        <StripeSvg />
      </div> */}

    </form>
  )
}

StripeCardPayment.propTypes = {
  amount: PropTypes.number.isRequired,
  clientSecret: PropTypes.func.isRequired,
  setError: PropTypes.func,
  setProcessing: PropTypes.func,
  onSuccess: PropTypes.func,
  recurring: PropTypes.bool,
}

StripeCardPayment.defaultProps = {
  onSuccess: function noop() { },
  error: null,
  processing: false
}