import React from "react";
import { Container } from "react-bootstrap";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import MainLayout from "src/core/component/MainLayout";

import "./RTPHome.scss";
import SetupBirthProfile from "./components/birthprofilesetup/SetupBirthProfile";
import RegisterLogin from "./components/registerlogin/RegisterLogin";
import CartSetup from "./components/CartSetup";
import Payment from "./components/payment/Payment";
import QuestionAnswerContent from "./components/questionanswer/QuestionAnswerContent";

const RTPHomeV2 = () => {
  const match = useRouteMatch();

  return (
    <MainLayout className="rtp-layoutTwo">
      <div className="rtp-layoutTwo-inner">
        <Container>
          <Switch>
            <Route exact path={match.path}>
              <CartSetup />
            </Route>

            <Route exact path={match.path + "/birth-profile"}>
              <SetupBirthProfile />
            </Route>

            <Route exact path={match.path + "/auth"}>
              <RegisterLogin />
            </Route>

            <Route exact path={match.path + "/payment"}>
              <Payment />
            </Route>

            <Route exact path={match.path + "/answer"}>
              <QuestionAnswerContent />
            </Route>

            <Route path={match.path + "/*"}>
              <Redirect to={{ pathname: "/instant-prediction" }} />
            </Route>
          </Switch>
        </Container>
      </div>
    </MainLayout>
  );
};

export default RTPHomeV2;
