// this is north style_1
export const kundaliLines1 = (w, h) => {
  return [
    {move: [w/4, h/4], line1: [w/2, 0],line2:[w/4*3, h/4],line3:[w/2, h/2]},
    {move: [0, 0], line1: [w/2, 0],line2:[w/4,h/4]},
    {move: [0, h/2], line1: [0, 0],line2:[w/4, h/4]},
    {move: [0, h/2], line1: [w/4, h/4],line2:[w/2, h/2],line3:[w/4, h/4*3]},
    {move: [0, h], line1: [0, h/2],line2:[w/4, h/4*3]},
    {move: [w/2, h], line1: [0, h],line2:[w/4, h/4*3]},
    {move: [w/4, h/4*3], line1: [w/2, h/2],line2:[w/4*3, h/4*3],line3:[w/2, h]},
    {move: [w, h], line1: [w/2, h],line2:[w/2/2*3, h/4*3]},
    {move: [w, h/2], line1: [w, h],line2:[w/2/2*3, h/4*3]},
    {move: [w/2, h/2], line1: [w/4*3, h/4],line2:[w, h/2],line3:[w/4*3, h/4*3]},
    {move: [w, 0], line1: [w, h/2],line2:[w/4*3, h/4]},
    {move: [w/2, 0], line1: [w, 0],line2:[w/4*3, h/4]},
  ];
}

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // color:'yellow',
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  fontWeight: 700,
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  // color:'red',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  // color:'yellow',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(8 * scale, 10),
  fontWeight: 600,  
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 66 * scale,
      width: 96 * scale,
      top: 40 * scale,  
      left: 152 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 25 * scale,
      left: 194 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 120 * scale,
      left: 192 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 18 * scale,    
      left: 55 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 5 * scale,
      left: 100 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 52 * scale,
      left: 92 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 95 * scale,
      width: 55 * scale,
      top: 52 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 38 * scale,
      left: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 63 * scale,
      left: 78 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 82 * scale,
      width: 78 * scale,
      top: 104 * scale, 
      left: 63 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 88 * scale,
      left: 95 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 132 * scale,
      left: 178 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 88 * scale,
      width: 55 * scale,
      top: 190 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 92 * scale,
      left: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 64 * scale,
      left: 78 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 44 * scale,
      width: 102 * scale,
      top: 243 * scale,
      left: 52 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 40 * scale,
      left: 95 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 52 * scale,
      left: 94 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 64 * scale,
      width: 98 * scale,
      top: 183 * scale,
      left: 151 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 100 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 120 * scale,
      left: 192 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 44 * scale, 
      width: 120 * scale,
      top: 242 * scale,
      right: 44 * scale,  
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 40 * scale,
      right: 97 * scale, 
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 54 * scale,
      right: 94 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 90 * scale,
      width: 55 * scale,
      top: 188 * scale,
      right: 10 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 97 * scale,
      right: 32 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 63 * scale,
      right: 81 * scale
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 82 * scale,
      width: 78 * scale,
      top: 104 * scale,
      right: 63 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 90 * scale,
      right: 95 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 132 * scale,
      right: 178 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 95 * scale,
      width: 55 * scale,
      top: 49 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 36 * scale,
      right: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 63 * scale,
      right: 80 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 18 * scale,    
      right: 55 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 5 * scale,
      right: 100 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 50 * scale,
      right: 96 * scale,
    }
  }
];
