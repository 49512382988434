import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import BirthProfileForm from "src/core/component/birthprofile/BirthProfileForm";
import H4Heading from "src/core/component/grid/H4Heading";
import DismissableAlert from "src/core/component/DismissableAlert";
import { useAlert } from "src/core/hook/Alert";
import Profiles from "./Profiles";
import Filter from "./Filter";
import api from "src/lib/api";
import { pick } from "src/util/extra";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from "src/auth/hook/auth-info";
import useSelect from "src/core/hook/useSelect";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { dateAndTimeToDateTime } from "src/util/date_and_time_to_datetime";
import { useModal } from "src/core/hook/useModal";
import useFreeAstrology from "src/freeastrology/hook/useFreeAstrology";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import PrashnaKundaliModal from "src/core/component/prashnakundali/PrashnaKundaliModal";
import SwiperCarousel from "src/core/component/slider/SwiperCarousel";
import CommonBirthProfile from "src/core/component/prediction/BirthProfile";
import DeleteModal from "src/core/component/modal/DeleteModal";
import findGender from "src/core/component/findGender";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { PlusSvg } from "src/core/component/assets/svg/CommonSVG";

const BirthProfile = ({ path, isDarkMode, ...rest }) => {
  const location = useLocation();
  const { rtpDatetime } = useRTPInfo();
  const { birthProfiles, updateBirthProfile, deleteBirthProfile } = useAuthInfo();
  const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
  const [profileId, setProfileId] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showModeSwitching, setShowModeSwitching] = useState(false);
  const [filter, setFilter] = useState({
    relationship_id: "all",
  });
  const [form, setForm] = useState({
    name: "",
    relationship_id: "",
    is_primary: false,
    address: "",
    dob: "",
    latitude: 0.0,
    longitude: 0.0,
    timezone: "",
    country_code: "",
    gender: "",
    date: new Date().format("yyyy-MM-dd"),
    time: new Date().format("HH:mm:ss"),
    profile_type: 'regular_kundali_profile'
  });

  const { selectProfile } = useFreeAstrology();
  const [relationships, setRelationships] = useState([]);
  const [showModal, handleModalShow, handleModalClose] = useModal(false);
  const [showPrasnaKundaliModal, handlePrasnaKundaliModalShow, handlePrasnaKundaliModalClose] = useModal(false);
  const [isPransaKundaliAgreed,setIsPransaKundaliAgreed] = useState(false)
  const [selected, handleSelect, setSelectionSpace, isSelected] = useSelect(
    [],
    [],
    false
  );
  const [birthProfileLoading, setBirthProfileLoading] = useState(false);
  const [nameTextLength, setNameTextLength] = useState(50);
  const [showDeleteModal, handleDeleteModalShow, handleDeleteModalClose] = useModal(false);
  const [profileDeleteId, setProfileDeleteId] = useState(null);
  const [isProfileDeleting, setIsProfileDeleting] = useState(false);

  useEffect(() => {
    fetchRelationships().then();
  }, []);

  useEffect(()=>{
    autoSelectGender(form.relationship_id)
  },[form.relationship_id,relationships])

  const autoSelectGender = (rel_id)=>{
    let relationship = relationships.filter((rel) => rel.id == rel_id)[0]
    if(relationship?.name){
        if(!( relationship?.name == "Self" || relationship?.name == "Friend")){
            setForm(prev=>({...prev,gender:findGender(relationship?.name)}))
        }
    }
  }

  const fetchRelationships = async () => {
    try {
      const result = await api.get(`v1/relationship`);
      const relationshipData = result?.data?.result?.data || [];
      setRelationships(relationshipData);
    } catch (error) {
      console.log("Error on fetching relationships.");
    }
  };

  useEffect(() => {
    setSelectionSpace(birthProfiles);
  }, [birthProfiles]);

  useEffect(() => {
    let selectedProfile = selected[0];
    setForm({
      name: selectedProfile?.name || "",
      relationship_id: selectedProfile?.relationship_id || "",
      is_primary: selectedProfile?.is_primary || false,
      address: selectedProfile?.address || "",
      dob: selectedProfile?.dob || "",
      latitude: selectedProfile?.latitude || 0.0,
      longitude: selectedProfile?.longitude || 0.0,
      timezone: selectedProfile?.timezone || "",
      country_code: selectedProfile?.country_code || "",
      gender: selectedProfile?.gender || "",
      date:
        (selectedProfile?.dob &&
          new Date(selectedProfile.dob).format("yyyy-MM-dd")) ||
        new Date().format("yyyy-MM-dd"),
      time:
        (selectedProfile?.dob &&
          new Date(selectedProfile.dob).format("HH:mm:ss")) ||
        new Date().format("HH:mm:ss"),
        profile_type: selectedProfile?.profile_type || 'regular_kundali_profile'
    });
    selectedProfile && window.scrollTo(0, 0);
    setProfileId(selectedProfile?.id || null);
  }, [selected]);

  // Handle the create/edit state along with redirected from other pages too.
  useEffect(() => {
    if (birthProfiles?.length && relationships?.length && !profileId) {
      if (location.state && location.state?.profile_id) {
        let profile_id = location.state.profile_id;
        let profile = birthProfiles.filter((item) => item.id === +profile_id);
        handleSelect(null, profile);
      } else if (location.state && location.state?.mode === "create") {
        handleSelect(null, []);
      } else {
        const primaryProfile =
          (birthProfiles?.length > 0 &&
            birthProfiles.filter((profile) => profile.is_primary)) ||
          [];
        handleSelect(null, primaryProfile);
      }
    }
  }, [location.state, birthProfiles, relationships]);

  const onLocationChange = (data) => {
    setForm((currentValue) => ({
      ...currentValue,
      address: data["query"] || "",
      latitude: data["place"]["latitude"] || "",
      longitude: data["place"]["longitude"] || "",
      timezone: data["timezone"]["name"] || "",
      country_code: data["country_code"] || "",
    }));
  };

  const handleProfileFormChange = useCallback((e) => {
    if (e.target.name === "name" && e.target.value.length === nameTextLength) {
      PocketToast({
        type: "warning",
        message: `Name should not be longer than ${nameTextLength} characters`,
      });
    }

    const target = e.target;
    let value;
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
    }

    setForm({ ...form, [e.target.name]: value });
  });

  const handleSelectionChange = (e) => {
    setForm({ ...form, [e.name]: e?.id || e?.value });
  };

  const onChangeFilter = (e) => {
    setFilter({ ...filter, relationship_id: e.id });
  };

  const handlePrashnaKundali = (e) => {
    e.preventDefault();
    handleModalShow();
  };
  const setupPrashnaKundali = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      profile_type: "prashna_kundali_profile",
      date:
        (rtpDatetime && new Date(rtpDatetime).format("yyyy-MM-dd")) ||
        new Date().format("yyyy-MM-dd"),
      time:
        (rtpDatetime && new Date(rtpDatetime).format("HH:mm:ss")) ||
        new Date().format("HH:mm:ss"),
    });
    handleModalClose();
  };

  const handleProfileFormSubmit = useCallback((e) => {
    e.preventDefault();
    const dataToSubmit = pick(
      form,
      "name",
      "relationship_id",
      "address",
      "is_primary",
      "gender",
      "latitude",
      "longitude",
      "timezone"
    );
    const dob = dateAndTimeToDateTime(form["date"], form["time"]);
    if (dob) {
      dataToSubmit["dob"] = dob;
    }

    if (Object.values(dataToSubmit).some((val) => val === null || val === "")) {
      PocketToast({
        type: "error",
        message: "All fields are required to be filled",
      });
      return;
    }

    dataToSubmit["country_code"] = form["country_code"];
    dataToSubmit['profile_type'] = form['profile_type'];

    if (!profileId) {
      createProfile(dataToSubmit).catch((e) => {
        let errorData = e?.response?.data?.error || e;
        PocketToast({
          type: "error",
          message: errorData?.message || "Failed to create a birth profile",
        });
      });

      return;
    }

    if (profileId) {
      editProfile(dataToSubmit).catch((e) => {
        let errorData = e?.response?.data?.error || e;
        PocketToast({
          type: "error",
          message: errorData?.message || "Failed to update a birth profile",
        });
      });
      return;
    }
  });

  const createProfile = async (payload) => {
    if(payload.profile_type == "prashna_kundali_profile" && isPransaKundaliAgreed==false){
            handlePrasnaKundaliModalShow()
            return;
        }
    const result = await api.post("v1/birth-profiles", payload);
    const profile = result.data.result?.data || null;
    handleSelect(null, [profile]);
    updateBirthProfile(profile);
    if (profile?.is_primary) {
      selectProfile(profile);
    }
    PocketToast({
      type: "success",
      message: "Birth profile created successfully",
    });
  };

  const editProfile = async (payload) => {
    const result = await api.put(`v1/birth-profiles/${profileId}`, payload);
    const profile = result.data.result?.data || null;

    //If primary select for Free Astrology active profile
    if (profile?.is_primary) {
      selectProfile(profile);
    }
    updateBirthProfile(profile);

    PocketToast({
      type: "success",
      message: "Birth profile updated successfully",
    });
  };

  const handleClickCreateButton = (e) => {
    e.preventDefault();
    // resetting selection will goes into the create mode.
    handleSelect(null, []);
  };

  const handleDeleteProfile = async (e, id, canProceed=false) => {
    e.preventDefault();
    if (canProceed) {
      setIsProfileDeleting(true);

      try {
        const response = await api.delete(`/v1/birth-profiles/${id}`);
        if (response.status === 200) {
          const deletedProfile = response?.data?.result?.data;
          setIsProfileDeleting(false);
          handleDeleteModalClose();
          deleteBirthProfile(deletedProfile);
          PocketToast({
            type: "success",
            message: "Birth profile deleted successfully",
          });
        }
      } catch (error) {
        // console.log("profile delete error");
        handleDeleteModalClose();
        setIsProfileDeleting(false);
        PocketToast({
          type: "error",
          message: "Birth profile cannot be deleted",
        });
      }
    } else {
      handleDeleteModalShow();
      setProfileDeleteId(id);
    }
  };

  let alertVariant = error ? "alert-error" : success ? "alert-success" : "";

  // let relationship = selected.length > 0 && selected[0].relationship;

  const settings = {
    freeMode: true,
    mouseWheel:true,
    slidesPerView: 1.1,
    spaceBetween: 10,
    breakpoints: {
      576: {
        slidesPerView: 1.1,
      },
      800: {
        slidesPerView: 1.6,
      },
      890: {
        slidesPerView: 1.9,
      },
      992: {
        slidesPerView: 2.1,
      },
      1200: {
        slidesPerView: 2.3,
      },  
      1400: {
        slidesPerView: 2.6,
      },
    },
  };

  let profilesToRender = birthProfiles;
  if (filter && filter.relationship_id !== "all") {
    profilesToRender = birthProfiles.filter((profile) => profile.relationship_id === filter.relationship_id);
  }
  const relationship = (relationships || []).find((relationship) => relationship.id === form.relationship_id);

  // console.log('Relationships', relationships);
  // console.log('Filter', filter)
  // console.log('Selected Profile', selected)
  // console.log('Form Data', form);
  // console.log('Profile Id', profileId);

  const handlePrashnaKundaliAgreed = (e)=>{
    setIsPransaKundaliAgreed(true)
    handlePrasnaKundaliModalClose()
    handleProfileFormSubmit(e)
  }

  return (
    <>
      <div className={"birth-profile" + ((isDarkMode && " dark") || "")}>
      {showAlert && (
        <DismissableAlert
          text={error || success}
          handleAlertClose={handleAlertClose}
          classes={`dismissable-alert ${alertVariant}`}
        ></DismissableAlert>
      )}

      <div className="d-flex justify-content-between">
        <H4Heading
          classes={"pocket-page-heading" + ((isDarkMode && " dark") || "")}
          heading={`${(profileId && "Update") || "Create"} Birth Profile`}
        />

        {profileId && (
          <Link
            to="#"
            onClick={handleClickCreateButton}
            className="pocket-btn d-inline-flex align-items-center h-100"
            style={{ padding: "13px 11px", fontSize: "11px" }}
          >
            <PlusSvg height="14" style={{ marginRight: "5px" }} />
            Create Profile
          </Link>
        )}
      </div>

      <div className="row">
        <div className="col-12">
          {showModeSwitching && <LoadingIcon />}

          {!showModeSwitching && (
            <BirthProfileForm
              isDarkMode={isDarkMode}
              handleChange={handleProfileFormChange}
              handleSubmit={handleProfileFormSubmit}
              form={form}
              onLocationChange={onLocationChange}
              onChangeSelection={handleSelectionChange}
              setForm={setForm}
              relationships={relationships}
              profileId={profileId}
              gapClass={""}
              showInstruction={false}
              nameTextLength={nameTextLength}
              relationship={relationship}
              handlePrashnaKundali={handlePrashnaKundali}
            />
          )}

          {/*Prashna Kundali Modal */}
          {showModal && (
            <PrashnaKundaliModal
            isDarkMode={isDarkMode}
              showModal={showModal}
              handleModalClose={handleModalClose}
              heading="Looks like you don't know your birth profile"
              description={`We will use the practice of ‘Prasna Chart’ to provide a prediction to your question. Please enter your current location below and we will use that along with the date and time you asked us for prediction to your question(s).`}
              onLocationChange={onLocationChange}
              setupPrashnaKundali={setupPrashnaKundali}
              formData={form}
            />
          )}
          {showPrasnaKundaliModal && (
        <CommonModal showModal={handlePrasnaKundaliModalShow} classes="rtp__birth__profile__prasnakundali" 
        showCustomClose={true} size={"lg"} handleModalClose = {handlePrasnaKundaliModalClose}>
          <ModalBody classes={"rtp__birth__profile__prasnakundali"}>
            <div className="">
                <div className="rtp__birth__profile__prasnakundali__particular__profile">You have created a Prashna Kundali (Question Chart) profile</div>
                  <div className={"rtp_card" }>
                    <div className="rtp_card_text">
                     <span> You can only select and buy one question using a Prashna Kundali (Question Chart) profile.</span> 
                      <span>If you know your Birth Detail please enter it or proceed with <b>Prashna Kindali</b></span>
                    </div>
                    <div className="rtp_card_buttons">
                      <div className="cancel" onClick={handlePrasnaKundaliModalClose}>Cancel</div>
                      <div className="proceed" onClick={(e)=>{handlePrashnaKundaliAgreed(e)}}>Proceed</div>
                    </div>
                  </div>
              </div>
            
            </ModalBody>
    </CommonModal>
      )}
        </div>
      </div>

      {/* Birth Profile Slider and Filter... */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between title-filter">
            <div className={"title" + ((isDarkMode && " dark") || "")}>
              <h4>Saved birth profile</h4>
            </div>
            <div
              className={
                "relationship-filter" + ((isDarkMode && " dark") || "")
              }
            >
              {relationships && (
                <Filter
                  isDarkMode={isDarkMode}
                  filter={filter}
                  relationships={relationships}
                  onChangeFilter={onChangeFilter}
                />
              )}
            </div>
          </div>
        </div>
        <div className={`col-12 rtp-layoutTwo m-0${isDarkMode ? " dark" : ""}`}>
          {birthProfileLoading && <LoadingIcon />}

          {!birthProfileLoading && profilesToRender.length > 0 && (
            <SwiperCarousel
              {...settings}
              items={profilesToRender.map((profile) => (
                <CommonBirthProfile
                  key={profile.id}
                  className={!isDarkMode ? "bg-white" : ""}
                  profile={profile}
                  handleProfileSelect={handleSelect}
                  isProfileSelected={isSelected}
                  showCartcount={false}
                  canDelete={true}
                  handleProfileDelete={handleDeleteProfile}
                />
              ))}
            />
          ) || <p className="text-danger">Sorry, you don't have birth profiles for selected relationship.</p>}

          {/*
            {!birthProfileLoading && birthProfiles && (
              <Profiles
                isDarkMode={isDarkMode}
                filter={filter}
                profiles={birthProfiles}
                handleProfileClick={handleSelect}
                profileIsSelected={isSelected}
                onClickEdit={handleSelect}
                canDelete={true}
                handleDelete={handleDeleteProfile}
              />
            )}
          */}
        </div>
      </div>
    </div>
      {
        showDeleteModal && (
          <DeleteModal
            show={true}
            className={`delete-modal ${isDarkMode?' dark':''}`}
            handleClose={() => {
              setProfileDeleteId(null);
              handleDeleteModalClose();
            }}
            handleCloseClass="btn-primary"
            handleProceed={(e) => handleDeleteProfile(e, profileDeleteId, true)}
            handleProceedText={isProfileDeleting ? "Deleting..." : "Delete Profile"}
            handleProceedClass="btn-danger"
          >
            <h5>Delete Profile</h5>
            <p>Are you sure you want to delete this profile?</p>
          </DeleteModal>
        )
      }
    </>
  );
};

export default BirthProfile;
