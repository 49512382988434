import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useTheme } from "src/theme/component/ThemeContext";
import MainLayout from 'src/core/component/MainLayout';
import BannerInput from "src/core/component/banner/BannerInput";
import CheckboxField from "src/core/component/form/CheckboxField";

import MultiRangeSlider from "../shop/MultiRangeSlider";
import StarRating from "../shop/StarRating";
import CategoryList from "../shop/CategoryList";
import ProductSlider, {CustomNextArrow, CustomPrevArrow} from "../shop/ProductSlider";

const ShopHome = () => {
  const [isDarkMode] = useTheme();

  return (
    <MainLayout>
      <BannerInput isDarkMode={isDarkMode} title="Search Products" hasSearch={true} />

      <section className="gbt-shop">
        <Container fluid="xl">
          <Row className="g-3">
            <Col sm={12} lg={3}>
              <div className="gbt-shop__filter">
                <h4>Filter</h4>

                <MultiRangeSlider
                  min={50}
                  max={100}
                  onChange={() => {}}
                />

                <StarRating style={{marginTop: "50px"}} />

                <CategoryList style={{marginTop: "50px"}} />

                <div className="gbt-shop__filter-price__by" style={{marginTop: "40px"}}>
                  <CheckboxField property={"filter-by"} id="filter-by1">
                    <label htmlFor="filter-by1" className="form-check-label">By Zodiac Sign</label>
                  </CheckboxField>
                  <CheckboxField property={"filter-by"} id="filter-by2">
                    <label htmlFor="filter-by2" className="form-check-label">By Planet</label>
                  </CheckboxField>
                </div>
              </div>
            </Col>

            <Col sm={12} lg={9}>
              <div className="gbt-shop__products">
                <div className="gbt-shop__products-slider">
                  <h5>Recommended for you</h5>

                  <ProductSlider
                    settings={
                      {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                        prevArrow: <CustomPrevArrow />,
                        nextArrow: <CustomNextArrow />
                      }
                    }
                    data={[
                      {
                        id: 1,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 2,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/2.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 3,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/3.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        oldPrice: "12.32",
                        finalPrice: "10.00",
                      },
                      {
                        id: 4,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      }
                    ]}
                  />
                </div>

                <div className="gbt-shop__products-slider">
                  <h5>Deals of the day</h5>

                  <ProductSlider
                    settings={
                      {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                        prevArrow: <CustomPrevArrow />,
                        nextArrow: <CustomNextArrow />
                      }
                    }
                    data={[
                      {
                        id: 1,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 2,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/2.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 3,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/3.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        oldPrice: "12.32",
                        finalPrice: "10.00",
                      },
                      {
                        id: 4,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      }
                    ]}
                  />
                </div>

                <div className="gbt-shop__products-slider">
                  <h5>Top Selling Gems</h5>

                  <ProductSlider
                    settings={
                      {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                        prevArrow: <CustomPrevArrow />,
                        nextArrow: <CustomNextArrow />
                      }
                    }
                    data={[
                      {
                        id: 1,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 2,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/2.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      },
                      {
                        id: 3,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/3.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        oldPrice: "12.32",
                        finalPrice: "10.00",
                      },
                      {
                        id: 4,
                        imageUrl: "https://github.com/iamsumankarki/images/raw/main/1.jpg",
                        title: "Item Name",
                        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.",
                        finalPrice: "10.00",
                      }
                    ]}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  )
}

export default ShopHome;
