import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import ForumFilterItem from './ForumFilterItem'

const ForumFilter = ({isAscending,setIsAscending,currentFilter,setCurrentFilter})=>{

  const handleAscendingChange=()=>{
    setIsAscending(prev=>!prev)
  }

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="1">
        <Accordion.Header>
            <div className='d-flex flex-column'>
                <span className='title'>Filter by</span>
                <span className='value'>{currentFilter || 'Select'}</span>
            </div>
        </Accordion.Header>
        <Accordion.Body>
         <div className='ascending'>
         Ascending
         <Form.Check 
            type="switch"
            value={isAscending}
            checked={isAscending}
            onChange={handleAscendingChange}
        /></div>
          <ForumFilterItem currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default ForumFilter;