import React from "react"
import { Link,useHistory } from "react-router-dom";

import FormCheckboxSwitch from "src/core/component/form/FormChekboxSwitch";
import { EditSvg } from "src/core/component/assets/svg/CommonSVG";


const AdjustSettingsOverlay = ({ handleSubmit, onChange, isDarkMode, setShowOverlay, ...rest }) => {
    const history = useHistory();

    const handleLinkClick = (e) => {
        e.preventDefault();
        history.push('/cookies')
        setShowOverlay(false);
    }

    return (
        <section className={"cookie-overlay" + (isDarkMode && ' dark' || '' )}>
            <div className={"cookie-overlay__content" + (isDarkMode && ' dark' || '' )}>
                <div className="container-xl">
                    <div className={"settings" + (isDarkMode && ' dark' || '' ) }>
                        <h4 className="heading">
                            Our Website Uses Cookies
                        </h4>
                        <div className="description">
                            We use cookies and similar technologies to enhance your experience with PocketPandit. Some are necessary to help PocketPandit work as expected. Therefore, they can't be turned off; however, there are some that are optional which we use to access your page visits, your IP addresses, your devices, and your online interactions with us. We use optional cookies and similar technologies to continually improve the services that we provide. This may include tailoring our services to provide you with personalized content or personalized ads. 
                            <br /><br />
                            You can manage your choices now and learn more about how we use cookies and similar technologies below.
                        </div>
                        
                        {/* Form */}
                        <form className={"settings-form" + (isDarkMode && ' dark' || '' )}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="essential-cookies">
                                        <h4 className="title" onClick={handleLinkClick}>
                                            <span>4</span> 
                                            <Link to="#">
                                                Essential Cookies <EditSvg />
                                            </Link>
                                        </h4>
                                        <div className="description">
                                            We use essential cookies and similar technologies to make PocketPandit function. For instance, essential cookies and similar technologies allow you securely sign in and browse PocketPandit. These essential cookies and similar technologies help us keep your user account safe and prevent fraud.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">    
                                    <div className="functional-cookies">
                                        <h4 className="title" onClick={handleLinkClick}>
                                            <span>4</span> 
                                            <Link to="#">
                                                Functional Cookies <EditSvg />
                                            </Link>
                                        </h4>
                                        <div className="description">
                                            We use functional cookies and similar technologies to provide you with an experience that we tailor especially for you. For instance, functional cookies and similar technologies provide us the provision to remember a variety of preferences you might set on PocketPandit.
                                        </div>
                                        <div className={"switch d-flex align-items-center" + (isDarkMode && ' dark' || '')}>
                                            {/* <span style={{marginRight: '5px'}}>Off</span> <FormCheckboxSwitch hasLabel={false} value={false} disabled={true} /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="analytical-cookies">
                                        <h4 className="title" onClick={handleLinkClick}>
                                            <span>4</span> 
                                            <Link to="#">
                                                Analytical Cookies <EditSvg />
                                            </Link>
                                        </h4>
                                        <div className="description">
                                            Google Analytics(GA) is used by PocketPandit to gather data on how you use it. Your age, gender, interests, and demographic data are collected by Google Analytics, as well as their frequency of visits, the pages they browse, and the websites they visited before ours. We do not combine the information collected using Google Analytics with Personal Data.
                                            We do not combine the information collected using Google Analytics with Personal Data.
                                            You can opt out of Google Analytics via this link: 
                                            <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">Opt out of GA</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="advertising-cookies">
                                        <h4 className="title" onClick={handleLinkClick}>
                                            <span>4</span> 
                                            <Link to="#">
                                                Advertising Cookies <EditSvg />
                                            
                                            </Link>
                                        </h4>
                                        <div className="description">
                                            We use advertising cookies and similar technologies to deliver ads that we personalize for you. For instance, advertising cookies and similar technologies allow us to display personalized ads to you based on what we think are your interests. We work with 3rd-party providers Google and Facebook to show you ads in PocketPandit. Please note that these 3rd-party providers may use your information according to their privacy policies. You can control how these 3rd-party provider use your information via these links: 
                                            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Ad Settings</a> &nbsp;&nbsp;
                                            <a href="https://www.facebook.com/help/568137493302217" target="_blank">Facebook Ad Settings</a>
                                        </div>
                                        
                                        <div className={"switch d-flex align-items-center" + (isDarkMode && ' dark' || '')}>
                                            {/* <span style={{marginRight: '5px'}}>Off</span> <FormCheckboxSwitch hasLabel={false} value={false} disabled={true} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn-submit d-flex ms-sm-auto" onClick={(e)=>handleSubmit(e)}>Save Settings</button>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdjustSettingsOverlay
