import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

import LogoutSvg from "src/image/login-signup/logout.svg";
import {MenuDropdownSvg, SettingsGearSvg } from "src/core/component/assets/svg/CommonSVG";
import defaultImg from "src/image/default.jpg";

const NavDropdown = ({ logout, isDarkMode, profile, classes, ...rest }) => {
  return (
    <>
      <Dropdown
        className={"navigation__dropdown" + ((isDarkMode && " dark") || "")}
        drop="down"
        align={"end"}
        autoClose={true}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          className={
            "navigation__dropdown__toggler" + ((isDarkMode && " dark") || "")
          }
        >
          {profile.avatar_url ? (
            <img
              src={profile.avatar_url}
              className="img-fluid navigation__profile-img"
              alt=""
              referrerPolicy="no-referrer"
            />
          ) : (
            <img
              src={defaultImg}
              className="img-fluid navigation__profile-img"
              alt=""
              referrerPolicy="no-referrer"
            />
          )}
          {/* <MenuDropdownSvg /> */}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={
            "navigation__dropdown__menu" +
            ((isDarkMode && " dark") || "") +
            ((classes && " " + classes) || "")
          }
        >
          <Dropdown.Item
            className={
              "navigation__dropdown__link_name" +
              ((isDarkMode && "_dark") || "")
            }
          >
            <div>{profile.display_name}</div>
          </Dropdown.Item>

          {(profile?.astrologer && (
            <Dropdown.Item
              as="div"
              className={
                "navigation__dropdown__link_setting" +
                ((isDarkMode && "_dark") || "")
              }
            >
              <Link to="/settings">
                <SettingsGearSvg />
                &nbsp;<span>Settings</span>
              </Link>
            </Dropdown.Item>
          )) || (
            <Dropdown.Item
              as="div"
              className={
                "navigation__dropdown__link_setting" +
                ((isDarkMode && "_dark") || "")
              }
            >
              <Link to="/settings">
                <SettingsGearSvg />
                &nbsp;<span>Settings</span>
              </Link>
            </Dropdown.Item>
          )}
          <Dropdown.Item
            href="/"
            onClick={logout}
            className={
              "navigation__dropdown__link_logout" +
              ((isDarkMode && "_dark") || "")
            }
          >
            <img src={LogoutSvg} />
            &nbsp;<span>Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavDropdown;
