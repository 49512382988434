import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import H4Heading from "src/core/component/grid/H4Heading";
import PocketSelection from "src/core/component/selection/PocketSelection";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import api from "src/lib/api";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { prepareSelectionData } from "src/util/extra";


const ChartPreference = ({
    path,
    isDarkMode,
    ...rest 
}) => {
    const history = useHistory();
    const {preference, updateSystemPreference } = useAuthInfo();
    const [preferences, setPreferences] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preferenceMetadata, setPreferenceMetadata] = useState(null);
    const [formData, setFormData] = useState({
        'ayanamsha': '',
        'kundali_variant': '',
        'modern_planet': '',
        'rahu_ketu_position': '',
        'sunrise_sunset': ''
    });
    const [prefFields, setPrefFields] = useState([]);

    useEffect(() => {
        fetchUserSystemPreferences();
    }, [])

    const fetchUserSystemPreferences = async () => {
        setLoading(true)
        try {
            const result = await api.get(`v1/users/me/preferences/system`);
            const systemPref = result?.data?.result?.data;
            const systemPrefMeta = result?.data?.result?.metadata;
            setPreferences(systemPref);
            setPreferenceMetadata(systemPrefMeta);
            const selectionOptions = prepareSelectionData(systemPrefMeta?.selection_option || []);
            setPrefFields(selectionOptions);
            setLoading(false);
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching chart preferences"
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        setFormData({
            ...formData,
            'ayanamsha': preferences?.ayanamsha || '',
            'kundali_variant': preferences?.kundali_variant || '',
            'modern_planet': preferences?.modern_planet || '',
            'rahu_ketu_position': preferences?.rahu_ketu_position || '',
            'sunrise_sunset': preferences?.sunrise_sunset || ''
        })
    }, [preferences])

    const handleSelectionChange = (e) => {
        setFormData({...formData, [e.type]: e.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await api.put('v1/users/me/preferences', formData);
            const prefData = res.data?.result?.data;
            if(prefData){
                updateSystemPreference(prefData);
                PocketToast({
                    type: "success", 
                    message: "Chart preferences are updated successfully"
                })
            }

        } catch (error) {
            let errorData = error?.response?.data?.error || error
            PocketToast({
                type: "error", 
                message: errorData?.message || 'Error on updating chart preferences'
            })
        }
    }

    const handleBackClik = (e) => {
        e.preventDefault();
        history.push(`${path}/preference`)
    }

    // console.log('Formdataa', formData);
    // console.log('Fields', prefFields);

    return ( 
        <div className={"preferences" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Update Chart Preferences`} 
            />

            <div className="row">
                <div className="col-12">
                    <div className={"preferences__form notification-settings" + (isDarkMode && ' dark' || '')}>
                        {loading && (
                            <LoadingIcon />
                        )}

                        {!loading && (
                            <form onSubmit={handleSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')}>
                                <div className={`row gx-2 gx-md-2 gx-lg-4 gx-xl-5`}>

                                    {prefFields.map((item, index) => {
                                        return (
                                                <div className="col-12 col-md-6 field" key={item.name}>
                                                    <PocketSelection 
                                                        options= {[...item.options]}
                                                        currentValue={ item.options.filter(opt=>opt.value===formData[item.name])[0] || ''}
                                                        onChange={ handleSelectionChange }
                                                        classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                                        hasLabel={true}
                                                        label={item.label}
                                                        required={true}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center mt-2 mt-md-3">
                                            <button type="button" className={`btn prev-link ${isDarkMode && 'dark' || ''}`} onClick={handleBackClik}>Back</button>
                                            <button className={"pocket-btn"} type="submit">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartPreference
