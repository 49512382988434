import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavLink from "src/core/component/nav/NavLink";
import Badge from "src/core/component/nav/Badge";
import {
  WishListSvg,
  NotificationSvg,
  CartSvg,
  PromoSvg,
  HomeSvg,
  PesoSvg
} from "src/core/component/assets/svg/CommonSVG";
import NavDropdown from "./NavDropdown";
import LinkItem from "src/core/component/LinkItem";
import ThemeToggler from "src/core/component/ThemeToggler";
import useQuery from "src/core/hook/useQuery";
import usePromo from "src/promos/hook/usePromo";
import NotificationDropdown from "./NotificationDropdown";
import useRTPInfo from "src/realtimeprediction/hook/useRTPInfo";

const NavItems = ({
  isAuthenticated,
  login,
  logout,
  profile,
  handleLinkClick,
  page,
  isDarkMode,
  toggleTheme,
  ...rest
}) => {
  const location = useLocation();
  let queryParams = useQuery();
  const { rtpOrderLineCount } = useRTPInfo();
  const { promoNotificationData } = usePromo();
  const [isNotificationActive, setIsNotificationActive] = useState(false);

  let prefix = location.pathname.split("/")[1];
  let rtpStage = queryParams.get("stage");

  let classes = isDarkMode
    ? "nav-item navigation__item dark"
    : "nav-item navigation__item";

  let svgLinks = [
    {
      url: "/",
      page: "home",
      svg: <HomeSvg />,
    },
    {
      url: "/promos",
      page: "promos",
      badge: (promoNotificationData && promoNotificationData.length) || 0,
      svg: <PromoSvg />,
      isActive: prefix === "promo" ? true : false,
    },
    // {
    //     url: "/shop",
    //     page: 'cart',
    //     // badge: 50,
    //     svg: <CartSvg />
    // },
    {
      url: "/instant-prediction/payment",
      page: "wishlist",
      badge: rtpOrderLineCount,
      svg: <WishListSvg />,
      isActive: rtpStage === "payment" ? true : false,
    },
  ];

  // console.log('Navs', svgLinks);

  const handleNotificationToggle = (show) => {
    setIsNotificationActive(show);
  };

  return (
    <ul className="navbar-nav navigation__list">
      {svgLinks.map((item, index) => {
        return (
          <NavLink
            key={index}
            currentPage={page}
            page={item.page}
            onClick={handleLinkClick}
            url={item.url}
            classes={classes}
            isActive={item.isActive}
          >
            {item.svg}

            {(item.badge && item.badge > 0 && <Badge value={item.badge} />) || (
              <></>
            )}
          </NavLink>
        );
      })}

      <li
        className={
          "nav-item navigation__item d-sm-flex align-items-center justify-content-md-end" +
          ((isNotificationActive && " active") || "")
        }
      >
        <NotificationDropdown
          logout={logout}
          profile={profile}
          isDarkMode={isDarkMode}
          onToggle={handleNotificationToggle}
        />
      </li>

      {isAuthenticated ? (
        <>
        <li className="nav-item navigation__item logged-dropdown d-sm-flex align-items-center justify-content-md-end">
          <NavDropdown
            classes="auth__dropdown"
            logout={logout}
            profile={profile}
            isDarkMode={isDarkMode}
          />
        </li>
        {
          profile?.admin == true?
        <li>
        <Link to="/wallet">
            <PesoSvg/><span className="ps-1 text-success text-decoration-underline pointer-event">
                {parseFloat(profile?.wallet_balance?.dollar*profile?.wallet_balance?.exchange_rate).toFixed(2) || 0 }</span>
        </Link>
        </li>:null
        }
        </>
      ) : (
        <li className="nav-item navigation__item d-flex align-items-center justify-content-md-end navigation__login">
          <LinkItem
            name={"Login"}
            url={"#"}
            onClick={login}
            classes={
              "nav-link d-flex align-items-center justify-content-center text-primary login-btn"
            }
          />

          <LinkItem
            name={"Get Started"}
            url={"/auth/signup"}
            classes={"btn btn-primary navigation__get-started"}
          />
        </li>
      )}

      <li className="nav-item navigation__item theme-switch d-none d-md-block">
        <ThemeToggler
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
          classes={
            "nav-link d-sm-flex align-items-center justify-content-center theme-toggler"
          }
        />
      </li>
    </ul>
  );
};

export default NavItems;
