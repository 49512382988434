import React, {useState} from "react";
import { Link } from "react-router-dom";
import { DownloadSvg } from "src/core/component/assets/svg/ProfileSettingSVG";
import fileDownloader from "src/util/file-downloader";


const DownloadInvoice = ({order, isDarkMode, ...rest }) => {
    const [loading, setLoading] = useState(false);

    const handleInvoiceDownload = async (e) => {
        if(!order?.invoice_id) return;

        e.preventDefault();
        setLoading(true);
        fileDownloader(`/v1/invoices/${order.invoice_id}/download`, `${order.name.split('/').join('-')}-PocketPandit.pdf`);

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    // console.log('Loading', loading);

    return (
        <div className={"pocket-invoice__download my-3" + (isDarkMode && ' dark' || '')}>
          <Link className="pocket-btn d-flex align-items-center position-relative" to="#" onClick={handleInvoiceDownload} style={loading && ({pointerEvents: 'none', opacity: 0.7}) || {}}>
              <DownloadSvg />
              <span className="ms-2">Download Invoice</span>

                {loading && (
                    <span className="spinner-border spinner-border-sm position-absolute" role="status" aria-hidden="true"></span>
                )}
            </Link>
        </div>
    )
}

export default DownloadInvoice
