import React, { useState, useEffect } from "react";

import api from "src/lib/api";
import useAuthInfo from "src/auth/hook/auth-info";
import H4Heading from "src/core/component/grid/H4Heading";
import { PocketToast } from "src/core/component/toast/PocketToast";
import AccountUpdateForm from "./AccountUpdateForm";
import PasswordChangeForm from "src/auth/component/forms/PasswordChangeForm";


const Accounts = ({ path, isAuthenticated, isDarkMode, ...rest }) => {
  const { profile, updateProfile } = useAuthInfo();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [defaultSpecializations, setDefaultSpecializations] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    bio: "",
    specialization: [],
  });

  useEffect(() => {
    if (profile) {
      setFormData((currentState) => ({
        ...currentState,
        name: profile.name,
        address: profile.address,
        bio: profile.bio,
        specialization: (profile.specialization || []).map((s) => s.id),
      }));

      setDefaultSpecializations([...profile.specialization]);
    }
  }, [profile]);

  useEffect(() => {
    fetchSpecializations();
  }, []);

  const fetchSpecializations = async () => {
    try {
      const result = await api.get(`/v1/specializations`);
      const data = result.data?.result?.data || [];
      setSpecializations(data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUserProfile(formData).catch((e) => {
      console.log("error", e);
      PocketToast({
        type: "error",
        message: "Failed to update a profile!",
      });
    });
  };

  const updateUserProfile = async (payload) => {
    const result = await api.put(`v1/users/me`, payload);
    const profileData = result.data?.result?.data;
  
    if(profileData) {
        updateProfile(profileData);

        PocketToast({
          type: "success",
          message: "Your Account has been updated successfully",
        });
    }
  };

  const handleChangePasswordClick = (e) => {
    setShowPasswordChangeForm((prevState) => !prevState);
  };

  const handleSelectionChange = (skills) => {
    setFormData((currentState) => ({
      ...currentState,
      specialization: (skills || []).map((s) => s.id),
    }));
    setDefaultSpecializations(skills || []);
  };

  const onLocationChange = (data) => {
    setFormData((currentValue) => ({
      ...currentValue,
      address: data["query"] || "",
    }));
  };
  // console.log('Specializations', specializations);
  // console.log('Form Data', formData);

  return (
    <div className={"astrologer-dashboard" + ((isDarkMode && " dark") || "")}>
      <H4Heading
        classes={"pocket-page-heading" + ((isDarkMode && " dark") || "")}
        heading={`${
          (!showPasswordChangeForm && "Update Profile Information") ||
          "Change Password"
        }`}
      />

      {profile && (
        <div className="astrologer-profile-update">
          <div className="row">
            {!showPasswordChangeForm && (
              <div className="col-12">
                <AccountUpdateForm
                  formData={formData}
                  setFormData={setFormData}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  handleSelectionChange={handleSelectionChange}
                  isDarkMode={isDarkMode}
                  specializations={specializations}
                  defaultSpecializations={defaultSpecializations}
                  onLocationChange={onLocationChange}
                  profile={profile}
                />
              </div>
            )}

            <div className="col-12">
              <div
                className={"change-password" + ((isDarkMode && " dark") || "")}
              >
                {!showPasswordChangeForm && (
                  <>
                    <h4 className="title">Change Password</h4>
                    <p className="text">
                      Do you want to change your password?{" "}
                      <span
                        className="link"
                        onClick={handleChangePasswordClick}
                      >
                        click here
                      </span>
                    </p>
                  </>
                )}

                {showPasswordChangeForm && (
                  <PasswordChangeForm
                    isDarkMode={isDarkMode}
                    showSubmit={true}
                  />
                )}
              </div>
            </div>

            {!showPasswordChangeForm && (
              <div className="col-12">
                <div className="d-flex justify-content-sm-end align-items-center">
                  <button className="pocket-btn" onClick={handleSubmit}>
                    Save Changes
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Accounts;
