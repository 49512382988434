import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import H4Heading from "src/core/component/grid/H4Heading";
import PocketSelection from "src/core/component/selection/PocketSelection";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import api from "src/lib/api";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";


const NotificationPreference = ({
    path,
    isDarkMode,
    ...rest 
}) => {
    const history = useHistory();
    const { fetchNotificationPreferences, notification_preference, updateNotificationPreference } = useAuthInfo();
    const [preferences, setPreferences] = useState(null);
    const [notificationOption, setNotificationOption] = useState([]);
    const [dashatransitNotificationOption, setDashatransitNotificationOption] = useState([]);
    const [dashatransitOption, setDashatransitOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preferenceMetadata, setPreferenceMetadata] = useState(null);
    const [formData, setFormData] = useState({
        'all_notification': '',
        'dasha_transit_notification': '',
        'dasha_transit_notification_option': ''
    });

    useEffect(() => {
        fetchNotifyPreferences();
    }, [])

    const fetchNotifyPreferences = async () => {
        setLoading(true)
        try {
            const result = await api.get(`v1/users/me/preferences/notification`);
            const notifPreference = result?.data?.result?.data;
            const notifMetadata = result?.data?.result?.metadata;
            setPreferences(notifPreference);
            setPreferenceMetadata(notifMetadata);
            setNotificationOption(notifMetadata?.notification_option && notifMetadata.notification_option.map(o=>({type: 'all_notification', ...o})) || []);
            setDashatransitNotificationOption(notifMetadata?.notification_option && notifMetadata.notification_option.map(o=>({type: 'dasha_transit_notification', ...o})) || [])
            setDashatransitOption(notifMetadata?.selection_option?.dasha_transit_notification_option && notifMetadata?.selection_option?.dasha_transit_notification_option.map(o=>({type: 'dasha_transit_notification_option', ...o})) || []);
            setLoading(false);
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching notification preferences"
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        setFormData({
            ...formData,
            'all_notification': preferences?.all_notification || '',
            'dasha_transit_notification': preferences?.dasha_transit_notification || '',
            'dasha_transit_notification_option': preferences?.dasha_transit_notification_option || ''
        })
    }, [preferences])

    const handleSelectionChange = (e) => {
        setFormData({...formData, [e.type]: e.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await api.put('v1/users/me/preferences', formData);
            const notifPref = res.data?.result?.data;
            if(notifPref) {
                updateNotificationPreference(notifPref);

                PocketToast({
                    type: "success", 
                    message: "Notification preferences are updated successfully"
                })
            }
        } catch (error) {
            let errorData = error?.response?.data?.error || error
            PocketToast({
                type: "error", 
                message: errorData?.message || 'Error on updating notification preferences'
            })
        }
    }

    const handleBackClik = (e) => {
        e.preventDefault();
        history.push(`${path}/preference`)
    }

    return (
        <div className={"preferences" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Update Notification Preferences`} 
            />

            <div className="row">
                <div className="col-12">
                    <div className={"preferences__form notification-settings" + (isDarkMode && ' dark' || '')}>
                        {loading && (
                            <LoadingIcon />
                        )}

                        {!loading && (
                            <form onSubmit={handleSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')}>
                                <div className={`row gx-2 gx-md-2 gx-lg-4 gx-xl-5`}>
                                    <div className="col-12 col-md-6 field">
                                        <PocketSelection 
                                            options= {[...notificationOption]}
                                            currentValue={ notificationOption.filter(opt=>opt.value===formData['all_notification'])[0] || ''}
                                            onChange={ handleSelectionChange }
                                            classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                            hasLabel={true}
                                            label={'All Notification'}
                                            required={true}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 field">
                                        <PocketSelection 
                                            options= {[...dashatransitNotificationOption]}
                                            currentValue={ dashatransitNotificationOption.filter(opt=>opt.value===formData['dasha_transit_notification'])[0] || ''}
                                            onChange={ handleSelectionChange }
                                            classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                            hasLabel={true}
                                            label={'Dasha Transit Notification'}
                                            required={true}
                                        />
                                    </div>

                                    {formData['dasha_transit_notification'] !== 'off' && (
                                        <div className="col-12 col-md-6 field">
                                            <PocketSelection 
                                                options= {[...dashatransitOption]}
                                                currentValue={ dashatransitOption.filter(opt=>opt.value===formData['dasha_transit_notification_option'])[0] || ''}
                                                onChange={ handleSelectionChange }
                                                classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                                hasLabel={true}
                                                label={'Dasha Transit Notification Options'}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center mt-2 mt-md-3">
                                            <button type="button" className={`btn prev-link ${isDarkMode && 'dark' || ''}`} onClick={handleBackClik}>Back</button>
                                            <button className="pocket-btn" type="submit">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default NotificationPreference
