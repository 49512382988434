import api from 'src/lib/api';
import { prepareFormData } from "src/util/extra";

export default async function uploadFile(file, key_prefix='') {

    const data = prepareFormData({
      'file': file,
      'key_prefix': key_prefix,
    });

    const res = await api.post('v1/uploads', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      }
    });

    const result = res.data?.result;

    return result?.data?.s3_object_url;
}
