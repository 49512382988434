import React from "react";
import { Link } from "react-router-dom";

import Sidebar from "src/profilesetting/component/sidebar/Sidebar";
import useAuthInfo from "src/auth/hook/auth-info";
import CircleImage from "src/core/component/birthprofile/CircleImage";
import ThemeSwitcher from "./ThemeSwicher";


const SettingHomeMobile = ({path, isDarkMode, ...rest }) => {
    const {profile, logout} = useAuthInfo();

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    }

    return (
        <div className={"settings__mobile-home" + (isDarkMode && ' dark' || '')}>
            <ThemeSwitcher />

            <div className="user-avatar d-flex justify-content-center">
                <CircleImage
                    url={profile?.avatar_url}
                    showIconOverlay={false}
                    isDarkMode={isDarkMode}
                    onClick={()=>null}
                />
                <p className={`name ${isDarkMode && 'dark' || ''}`}>{profile?.name}</p>
            </div>

            <div className={`settings__mobile-menu ${isDarkMode && 'dark' || ''}`}>
                <Sidebar path={path} isDarkMode={isDarkMode} />
            </div>

            <div className="mobile-logout">
                <Link to="#" onClick={handleLogout} className="btn pocket-btn d-block logout">Sign Out</Link>
            </div>
        </div>
    )
}

export default SettingHomeMobile
