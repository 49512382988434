import { loadJSON } from 'src/lib/json-store';

// Inital state
const initialState = {
  birthProfile: loadJSON('free-astro.birthProfile') || null,
  datetime: loadJSON('free-astro.datetime') || null
};

// Action types
const SELECT_PROFILE = 'SELECT_PROFILE';
const SET_DATETIME = 'SET_DATETIME';
const CLEAR_ASTROINFO = 'CLEAR_ASTROINFO';

// Actions
export const selectProfile = (profile) => {
    return {
        type: SELECT_PROFILE,
        payload: {
            birthProfile: profile
        }
    };
}

export const setEnterDateTime = () => {
    return {
        type: SET_DATETIME,
        payload: {
            datetime: new Date().format('yyyy-MM-ddTHH:mm:ss')
        }
    };
}

export const clearAstroInfo = () => {
    return {
        type: CLEAR_ASTROINFO
    };
}

// Reducer
export default function freeAstroReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROFILE: {
        const payload = action.payload;

        return {
            ...state,
            birthProfile: payload.birthProfile
        };
    }

    case SET_DATETIME: {
        const payload = action.payload;

        return {
            ...state,
            datetime: payload.datetime
        };
    }

    case CLEAR_ASTROINFO: {
        return {
            birthProfile: null,
            datetime: null
        };
    }

    default:
      return state;
    }
}
