import React from "react";
import { Link } from "react-router-dom";

import Card from "src/core/component/card/Card";

const dimension = process.env.IMAGE_STATIC_DIMENSIONS;

const FeaturedPromoCard = ({ item }) => {
  return (
    <Link to={`promo/${item.id}`} key={item.id}>
      <Card classes={"promo-card"}>
        <picture>
          <source media="(max-width: 576px)" srcSet={item.image_url} width={dimension.sm.w} height={dimension.sm.h} />
          <source media="(max-width: 992px)" srcSet={item.medium_image_url} width={dimension.md.w} height={dimension.md.h} />
          <img src={item.large_image_url} alt="" width={dimension.lg.w} height={dimension.lg.h} />
        </picture>
      </Card>
    </Link>
  );
};

export default FeaturedPromoCard;
