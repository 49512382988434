import React, {useState, useEffect} from "react";
import { useParams } from "react-router";

import H4Heading from "src/core/component/grid/H4Heading";
import defaultImg from 'src/image/random.jpg';
import OrderSummary from "src/profilesetting/component/orders/orderdetail/OrderSummary";
import OrderState from "./OrderState";


const OrderTrack = ({path, isDarkMode, ...rest }) => {
    const params = useParams();
    const [orderId, setOrderId] = useState(null)
    const [order, setOrder] = useState(null);
    const [orderList, setOrderList] = useState(
        [
            {
                id: 1,
                date: '2021-01-01T11:14:18',
                state: 'delivered',
                price: '11.00',
                type: 'question',
                order_lines: [
                    {
                        id: 1,
                        question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry ?',
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    },
                    {
                        id: 2,
                        question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry ?',
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    },
                    {
                        id: 3,
                        question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry ?',
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    }
                ] 
            },
            {
                id: 2,
                date: '2021-01-01T11:14:18',
                state: 'delivered',
                price: '11.00',
                type: 'product',
                order_lines: [
                    {
                        id: 4,
                        product: {
                            id: 1,
                            name: 'product 1',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 2,
                    },
                    {
                        id: 5,
                        product: {
                            id: 2,
                            name: 'product 2',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 1
                    },
                    {
                        id: 6,
                        product: {
                            id: 3,
                            name: 'product 3',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 3
                    },
                    {
                        id: 7,
                        product: {
                            id: 4,
                            name: 'product 4',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 1
                    },
                    {
                        id: 8,
                        product: {
                            id: 5,
                            name: 'product 5',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 1
                    }
                ] 
            },
            {
                id: 3,
                date: '2021-01-01T11:14:18',
                state: 'delivered',
                price: '11.00',
                type: 'call',
                order_lines: [
                    {
                        id: 10,
                        user: {
                            id: 1,
                            name: 'Dipendra Karki',
                            position: 'Position',
                            avatar: defaultImg,
                            rating: 3
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    }
                ] 
            },
            {
                id: 4,
                date: '2021-01-01T11:14:18',
                state: '',
                price: '11.00',
                type: 'question',
                order_lines: [
                    {
                        id: 11,
                        question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry ?',
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    },
                    {
                        id: 12,
                        question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry ?',
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    }
                ] 
            },
            {
                id: 5,
                date: '2021-01-01T11:14:18',
                state: 'on_progress',
                price: '11.00',
                type: 'product',
                order_lines: [
                    {
                        id: 13,
                        product: {
                            id: 5,
                            name: 'product 5',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 1
                    },
                    {
                        id: 14,
                        product: {
                            id: 6,
                            name: 'product 6',
                            quantity: 1,
                            image: defaultImg
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18',
                        quantity: 3
                    }
                ] 
            },
            {
                id: 3,
                date: '2021-01-01T11:14:18',
                state: 'delivered',
                price: '11.00',
                type: 'call',
                order_lines: [
                    {
                        id: 15,
                        user: {
                            id: 1,
                            name: 'Dipendra Karki',
                            position: 'Position',
                            avatar: defaultImg,
                            rating: 5
                        },
                        price: '11.00',
                        date: '2021-01-01T11:14:18'
                    }
                ] 
            }
        ]
    )

    useEffect(() => {
        if(params['OrderId']){
            setOrderId(params.OrderId)
        }
    }, [params])

    useEffect(() => {
        const orderItem = orderList.filter(item=>item.id === +orderId)
        if(orderItem.length){
            setOrder(orderItem[0]);
        }
    }, [orderId])

    // console.log('Order Id', orderId)
    // console.log('Order Item', order)

    return ( 
        <div className={"order-track" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Track Order`} 
            />

            <div className="row">
                <div className="col-12">
                    {order && (
                        <>
                            <OrderState
                                order={order}
                                isDarkMode={isDarkMode}
                            />

                            <OrderSummary 
                                order={order}
                                isTracking={true}
                                isDarkMode={isDarkMode}
                            />
                        </>
                    )}
                </div>
            </div>

        </div>
    )
}

export default OrderTrack
