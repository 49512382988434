import React from "react";

import MomentCalendarString from "src/core/component/moment-date-time/MomentCalendarString";

const ChatOrderInvoices = ({invoices, isDarkMode, ...rest }) => {

    return (
        <div className={"conversation-invoices" + (isDarkMode && ' dark' || '')}>
            <table className={"table table-borderless" + (isDarkMode && ' dark' || '')}>
                <tbody>
                    {(invoices || []).sort((i1, i2) => i1.id - i2.id).map((item, i) => (
                        <tr key={i} className="d-flex align-items-center justify-content-between">
                            <td className="align-middle date">
                                {item?.created_at && (
                                    <MomentCalendarString
                                        datetime={item.created_at}
                                    />
                                )}
                            </td>

                            <td className="align-middle quantity">
                                {item?.items.map(it=>it.quantity).join(' ')}hr
                            </td>

                            <td className="align-middle amount">
                                ${item?.amount_total}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ChatOrderInvoices
