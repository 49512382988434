import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import useAuthInfo from "src/auth/hook/auth-info";
import TabManager from "./TabManager";
import { useTheme } from "src/theme/component/ThemeContext";
import "src/instant-prediction/scss/registerlogin/RegisterLogin.scss";

const RegisterLogin = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthInfo();
  const [isDarkMode] = useTheme();

  const [currentTab, setCurrentTab] = useState(
    location.state?.tab || "register"
  );

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: location.state.from || "/instant-prediction",
          search: location.state.search || "",
        }}
      />
    );
  }

  return (
    <TabManager tab={currentTab} setTab={setCurrentTab} location={location} isDarkMode={isDarkMode} />
  );
};

export default RegisterLogin;
