export function pick(object, ...p) {
  return p.reduce((o, e) => {
    return (o[e] = object[e]), o;
  }, {});
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function validatePhone(phone) {
  const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return re.test(phone);
}

export function pluralize(num, singular, plural) {
  return num > 1 ? plural : singular;
}

export function nl2br(str, isXhtml) {
  //  discuss at: https://locutus.io/php/nl2br/
  // original by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Philip Peterson
  // improved by: Onno Marsman (https://twitter.com/onnomarsman)
  // improved by: Atli Þór
  // improved by: Brett Zamir (https://brett-zamir.me)
  // improved by: Maximusya
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Reynier de la Rosa (https://scriptinside.blogspot.com.es/)
  //    input by: Brett Zamir (https://brett-zamir.me)
  //   example 1: nl2br('Kevin\nvan\nZonneveld')
  //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
  //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false)
  //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
  //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true)
  //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
  //   example 4: nl2br(null)
  //   returns 4: ''
  // Some latest browsers when str is null return and unexpected null value
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  // Adjust comment to avoid issue on locutus.io display
  const breakTag =
    isXhtml || typeof isXhtml === "undefined" ? "<br " + "/>" : "<br>";
  return (str + "").replace(/(\r\n|\n\r|\r|\n)/g, breakTag + "$1");
}

export function truncate(str, length, ellipsis = "...") {
  return str.length <= length + ellipsis.length
    ? str
    : str.substr(0, length) + ellipsis;
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function getFormattedDateTime(dt, format) {
  return new Date(dt).format(format);
}

export function formDataToJson(payload) {
  return JSON.stringify(Object.fromEntries(payload.entries()));
}

export function mapArrToIds(arr) {
  return Array.isArray(arr) ? arr.map((item) => item.id) : arr;
}

export const prepareFormData = (obj) => {
  // console.log('Data to submit', obj);

  const formData = new FormData();
  const keys = Object.keys(obj);
  for (let key of keys) {
    let val = obj[key];
    if (Array.isArray(val)) {
      if (typeof val[0] === "object" && val[0] !== null) {
        val = mapArrToIds(val);
      }
      for (let v of val) {
        if (v) formData.append(key, v.id || v);
      }
    } else if (val instanceof File) {
      formData.append(key, val);
    } else {
      if (val != null) formData.set(key, val);
    }
  }

  return formData;
};

export const isObjectEmpty = (obj) => {
  if (!obj) return true;

  const keys = Object.keys(obj);

  if (keys.length === 0) return true;

  const values = Object.values(obj);

  return !values.some((val) => val != null);
};

export const clearForm = (formData) => {
  let setAll = (obj, val) => Object.keys(obj).forEach((k) => (obj[k] = val));
  setAll(formData, "");
};

export function abbreviateNumber(num, fixed) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early

  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power

  return e;
}

export function transformSelectionString(str) {
  if (!str) {
    return null;
  }
  let splitStr = str.split("_");
  let transformedArray = splitStr.map((item) => {
    return capitalize(item);
  });
  return transformedArray.join(" ");
}

// Make selection options from Selection Items
export function prepareSelectionData(selectionItems) {
  let selectionKeys = (selectionItems && Object.keys(selectionItems)) || [];

  let selectionFields = [];
  if (selectionKeys.length) {
    selectionKeys.forEach((item) => {
      let data = {
        name: item,
        type: "select",
        label: transformSelectionString(item),
        options: [...selectionItems[item].map((v) => ({ type: item, ...v }))],
      };
      selectionFields.push(data);
    });
  }

  return selectionFields;
}

export function uniqueArrayOfObjects(arrayOfObjects) {
  let uniqueArray = arrayOfObjects.filter(
    (I1, i, a) => a.findIndex((I2) => I2.id === I1.id) === i
  );
  return uniqueArray;
}

export function parseGender(gender) {
  if (!gender) {
    return null;
  }

  let genderStr = gender.split(".");
  return genderStr.length > 1 && genderStr[1];
}

export function compare(a, b) {
  if (typeof a === "string") {
    a = a.toLowerCase();
  }
  if (typeof b === "string") {
    b = b.toLowerCase();
  }
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }
  return 0;
}

export default function copyToClipboard(text) {
  if (!navigator.clipboard) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  } else {
    navigator.clipboard.writeText(text);
  }
}
