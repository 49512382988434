import React, {useState} from "react";
import { Link } from "react-router-dom";
import { EmailSvg } from "src/core/component/assets/svg/CommonSVG";
import OverlayLoading from "src/core/component/loader/OverlayLoading";


const Emailing = ({line,kundalisName,currentChartType, emailHandler,emailProcessing,setEmailProcessing }) => {

    const kundaliImageNames  = kundalisName.map((k,i)=>`realtime_${currentChartType}_${line.id}_${k.kundali_name}`)

    const onEmailClick = () => {
        setEmailProcessing(true);
        
        setTimeout(() => {

            if(!emailProcessing){
                emailHandler(line,kundaliImageNames).then(() => {
                    setEmailProcessing(false); 
                });
            }
            
        }, 0);
    }

    return (
        <div className="send-answer d-flex justify-content-end">
            <div className="send-button d-flex align-items-center" onClick={onEmailClick}>
                Email this to me 
                <EmailSvg />
            </div>
        </div>
    )
}

export default Emailing