import React from "react"
import { Link } from "react-router-dom";
import { useTheme } from "src/theme/component/ThemeContext";
import Banner from "src/core/component/Banner";
import CenteredDiv from "src/core/component/CenteredDiv";
import CenteredContainer from "src/core/component/grid/CenteredContainer";


const Privacy = () => {

    const [isDarkMode, toggleTheme] = useTheme();


    return (
        <section className={"pocket-gdpr" + (isDarkMode && ' dark' || '' )}>
            <Banner classes={isDarkMode && 'dark' ||  ''}>
                <CenteredDiv classes={'title'} text={'Privacy Information'} />
            </Banner>

            <div className={"pocket-gdpr__contents"  + (isDarkMode && ' dark' || '' )}>
                <CenteredContainer colClasses={'col-sm-12 col-xl-10 col-xxl-9'}>
                    <div className="information mb-0 mb-md-4">
                        <div className="text">
                            <p className="mb-4">Last update: July 13, 2022; 5:00 AM | Effective Date: July 13, 2022 </p>
                            <p>
                                POCKET PANDIT INC (“We”, “Our”, “Us”) respects your privacy and is committed to protect 
                                the same while using “Our” Services and outline our obligations to YOU in respect of how we 
                                manage YOUR Personal Information. This Policy applies to all websites, mobile applications, 
                                and other digital platforms owned and operated by POCKET PANDIT INC (collectively 
                                POCKET PANDIT) and linked to the SERVICE referred in this policy. This policy does not 
                                apply to information we collect from YOU in other ways, including to information you send to 
                                us by email. 
                            </p>
                            <p>
                                This Policy explains how we store, process, use, disclose and share the information we 
                                collect through any of our Platforms. If you are an EU resident, POCKET PANDIT is the data 
                                controller and we confirm that YOUR data is protected under the General Data Protection 
                                Regulation 2016 (the "GDPR").  POCKET PANDIT is represented by the term “WE”, “OUR” 
                                or “US” appropriately at this policy and the data subject is represented by the term “YOU” or 
                                “YOURS” or “USER” at this policy.
                            </p>
                            <p>
                                PLEASE NOTE THAT WE ENSURE THE PROTECTION OF PERSONAL INFORMATION 
                                OF OUR USERS/CUSTOMERS. WE USE YOUR INFORMATION TO PROVIDE BETTER 
                                AND IMPROVED SERVICES ONLY. BY USING OR ACCESSING ANY SERVICES 
                                THROUGH WEB PLATFORM OR MOBILE APPLICATION WE DESIGNED, DEVELOPED 
                                AND MADE AVAILABLE IS SUBJECT TO THIS PRIVACY POLICY. IT IS DEEMED YOU 
                                GAVE YOUR FREE CONSENT THIS POLICY YOUR USE AND ACCESS TO THESE 
                                SERVICES ARE. 
                            </p>
                            <p>
                                This privacy Policy is an integral part of the <Link to="/tos">TERMS OF USE</Link> and published in accordance 
                                with the <i>Delaware Online Privacy and Protection Act, 2016 (DOPPA)</i> which requires 
                                publishing of the privacy policy for collection, use, storage and transfer of sensitive personal 
                                information or data.
                            </p>

                            <h5 className="sub-heading">
                                PERSONAL INFORMATION DEFINED 
                            </h5>
                            <p>
                                Personal Information is information that identifies an individual or user of our service/site. 
                                Personal Information includes, but not limited to, names, locations, addresses, email 
                                addresses, phone numbers and date of birth etc. This Personal Information is obtained in 
                                many ways including by chatbox, email, via our website www. pocketpandit.com, from media 
                                and publications, from other publicly available sources, from cookies and from third parties. 
                                We can not guarantee the content of third-party websites or their policies. 
                            </p>

                            <h5 className="sub-heading">
                                INFORMATION WE COLLECT
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    We may collect a variety of information directly from you and/or others when you use or 
                                    access to POCKET PANDIT Platform as follows:
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        (a) To access our services we may ask you to register to our site, www.pocketpandit.com 
                                        and ask you to provide personal data, including but not limited to your name, gender, 
                                        email address, date of birth, phone number, mailing addresses, payment address etc.
                                    </li>  

                                    <li>
                                        (b) You may be asked to provide other information such as gender, time of birth, time zone, and birth location which is used to calculate your accurate information.
                                    </li>
                                    <li>
                                        (c) When you visit or access our services, our site may collect your information through 
                                        automated means that may include your device ID, device type, machine ID, 
                                        advertising ID, geolocation information, phone number details,  computer and 
                                        connection information, browser type, device/ browser information, Internet Protocol 
                                        ("IP") address (a number that is automatically assigned to your computer when you 
                                        use the Internet. 
                                    </li>
                                    <li>
                                        (d) The Website may collect additional information that may be required to be disclosed 
                                        and may receive additional information via email or other methods, including 
                                        contracts, with regard to specific services accessed through the Website or any 
                                        products purchased through the Website; however, such information may only be 
                                        used to address the User's particular need or concern.  
                                    </li>

                                    <li>
                                        (e) Information you supplied to the social networking site you connected to receive the 
                                        POCKET PANDIT platform.  
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                PURPOSE OF COLLECTION
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    We process your data for the delivery of service you request, to improve our service to you 
                                    and provide updates of the services and changes in the policies. Primarily, we collect your 
                                    information for the following;
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        (a) To enable your participation in activities on our site and help to confirm your identity verification.
                                    </li>  

                                    <li>
                                        (b) To inform or notify you of changes made to our Platforms 
                                    </li>
                                    <li>
                                        (c) React to any questions or concerns you may have about our site or services;  
                                    </li>
                                    <li>
                                        (d) To notify you regarding customer service 
                                    </li>

                                    <li>
                                        (e) Uphold our Platform's policies and terms of service   
                                    </li>
                                    <li>
                                        (f)  To recommend features, products and services as per your preferences   
                                    </li>
                                    <li>
                                        (g) To prevent and detect fraud    
                                    </li>
                                    <li>
                                        (h) To comply with any legal obligations    
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                DISCLOSURE OF PERSONAL INFORMATION
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    <b>As</b> a matter of policy, Pocket Pandit inc. does not disclose personally-identifying information 
                                    of its users to third parties unless you have given us their prior express consent. However, 
                                    we may share the information we collect to the following third parties: 
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        i) <b>Our Associates:</b> To prevent fraudulent activity against us and our customers, 
                                        we may disclose information collected about you to our associates. 
                                    </li>  

                                    <li>
                                        ii) <b>Third-Party Service Providers: We do not share information about you 
                                        with third parties for their own direct marketing purposes. However,</b> we 
                                        disclose information collected about you to our third-party service providers 
                                        who perform services on our behalf.  We shall contractually require each of 
                                        these third parties to use your personally identifiable information exclusively to 
                                        carry out the services we have requested and to abide by this privacy policy 
                                        when we send any of your information to them.These third parties offer 
                                        services in the following categories among others:

                                        <ol style={{listStyleType: 'none'}}>
                                            <li>
                                                1) processing payments on our behalf; 
                                            </li>
                                            <li>
                                                2) sending marketing/advertising communications on our behalf;
                                            </li>
                                            <li>
                                                3) helping us to create or maintain our databases  
                                            </li>
                                            <li>
                                                4) helping us to research or analyse the people who use our Platforms;  
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        iii) Third-Party Operators of Integrated Technologies: Any time third-party ads, 
                                        offerings, or other technologies are integrated in our sites, the third parties 
                                        who are delivering or running such ads, offerings, or other technologies will be 
                                        directly collecting information about you (and disclosing it to them).  Any such 
                                        information may be used and further disclosed subject only to any applicable 
                                        privacy policies of such third parties.
                                    </li>
                                    <li>
                                        iv) General Public: Any information you publish as a user on our sites, such as 
                                            online reviews, comments, forums, or blog postings, may be shared with the 
                                            public via our sites. This could contain specifics about you (by username or 
                                            otherwise).   
                                    </li>

                                    <li>
                                        v) Law Enforcement and Legal process: When required by law to do so, such as 
                                        in connection with illegal activity involving the sites, or in response to a court 
                                        order, or legal process, or that may help a law enforcement investigation, we 
                                        may disclose information about you to the government or to law enforcement 
                                        agencies.    
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                LINK TO THIRD PARTY PLATFORM 
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    <b>As</b> a matter of policy, Pocket Pandit inc. does not disclose personally-identifying information 
                                    of its users to third parties unless you have given us their prior express consent. However, 
                                    we may share the information we collect to the following third parties: 
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        a) POCKET PANDIT Platform may contain links to other websites that are not 
                                        covered by this Privacy Policy and where information practices may be 
                                        different from ours.   
                                    </li>  

                                    <li>
                                        b) The provision of a link to any other website or location is for your convenience 
                                            and does not signify our endorsement of such other website or location or its 
                                            contents.
                                    </li>
                                    <li>
                                        c) When you click on such a link, you will leave our Platform and go to another website.
                                    </li>
                                    <li>
                                        d) During this process, another entity may collect information from you. We have 
                                            no control over, do not review, and are not responsible for the privacy 
                                            practices or the content of such other websites.    
                                    </li>

                                    <li>
                                        e) Please be aware that the terms of this Privacy Policy do not apply to these 
                                        outside websites or content.     
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-title">RETENTION OF YOUR INFORMATION </h5>
                            <div className="lists">
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        a) We allow the possibility to view, edit, transfer, and delete your data. We keep your 
                                        information until either your account is terminated or until it is no longer required to 
                                        provide our services or our products, whichever happens first.   
                                    </li>  

                                    <li>
                                        b) However, we may retain and use your personal data as long as necessary to comply 
                                            with laws and regulations, and legal obligations, resolve disputes and enforce our 
                                            agreements and rights.
                                    </li>
                                    <li>
                                        c) In some circumstances, we store data for a set amount of time rather than giving 
                                            users the option to remove it. We establish retention periods for each category of 
                                            data in accordance with the purposes behind its collection.
                                    </li>
                                    <li>
                                        d) Sometimes to comply with legal obligations and resolving disputes we are obliged to 
                                        retain certain information, for specific purposes, for an extended period of time. We 
                                        may keep some data for longer periods of time for the following reasons:  

                                        <ol style={{listStyleType: 'none'}}>
                                            <li>
                                                1) security, preventing fraud and abuse 
                                            </li>
                                            <li>
                                                2) maintaining financial records
                                            </li>
                                            <li>
                                                3) obeying the law or other regulations    
                                            </li>
                                            <li>
                                                4) helping us to research or analyse the people who use our Platforms;  
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                HOW DO WE PROTECT YOUR INFORMATION?
                            </h5>
                            <p>
                                Pocket Pandit Inc., shall take all the necessary measures to protect your personal 
                                information from unauthorised access, modification, or disclosure. We are implementing all 
                                the technical measures including encryption of certain information that protects against 
                                unauthorised access to your information. However, no method of transmission over the 
                                Internet or  physical storage is 100% secure. Despite our reasonable attempt to offer secure 
                                transmission of information provided to us through the services, the security of any 
                                information you provide to us cannot be thus guaranteed by us and we hereby disclaim any 
                                such assurance.
                            </p>

                            <h5 className="sub-heading">
                                ACCESS TO YOUR INFORMATION AND UPDATE
                            </h5>
                            <p>
                                On the website’s “Your Profile” page you may access your information including your name, 
                                date of birth, time of birth, address, payment options, profile details, and purchase history. 
                                YOU can correct, update or make choices about the information collected about you that we 
                                are stored. You may also be able to see the question that you chose previously.
                            </p>

                            <h5 className="sub-heading">
                                CHILDREN’S PRIVACY 
                            </h5>
                            <p>
                                Pocket pandit inc. makes it clear that you must be of legal age to utilise our services. Anyone 
                                under the age of 18 is not eligible to use our service. We do not intentionally collect 
                                personally identifying information from children under the age of eighteen. Please contact us 
                                if you are a parent or guardian and you are aware that your child has provided us with 
                                Personal Data. If we learn that we have obtained Personal Data from children without a 
                                parental agreement, we will take steps to erase the data from our servers.
                            </p>

                            <h5 className="sub-heading">
                                DISPUTE
                            </h5>
                            <p>
                                Any disagreements over privacy or the provisions of this Privacy Policy shall be governed by 
                                Delaware law. If any section or any part of this Privacy Policy is found to be illegal, void, or 
                                unenforceable under applicable law or by order of a court of competent jurisdiction, it will be 
                                deemed deleted, without affecting the remainder of the terms and will be continuing in full 
                                force.
                            </p>

                            <h5 className="sub-title">GDPR Compliance</h5>
                            <div className="lists">
                                <ol style={{listStyleType: 'none'}}>
                                    <li>
                                        a) POCKET PANDIT is a Delaware, USA based company and all the legal issues are 
                                        subject to Delaware, USA jurisdiction. By using our service from outside Delaware, 
                                        USA, you acknowledge that your personal data may be accessed by us or 
                                        transferred to us in the United States jurisdiction. We may share this to our affiliates, 
                                        partners, and service providers who are located in multiple jurisdictions.   
                                    </li>  

                                    <li>
                                        b) If you are based in the EU member countries, we will process your personal data for 
                                            a purpose described in this Privacy Policy when 
                                        
                                        <ol style={{listStyleType: 'none'}}>
                                            <li>
                                                i) you have provided your consent (which can be withdrawn at any time), 
                                            </li>
                                            <li>
                                                ii) the processing is necessary for the performance of a contract we                        
                                                    are about to enter into or have entered into with you,
                                            </li>
                                            <li>
                                                iii) we are required by law,     
                                            </li>
                                            <li>
                                                iv) processing is required to protect your vital interests or those of                         
                                                another person, or   
                                            </li>
                                            <li>
                                                v) the processing is necessary for the purposes of our legitimate                            
                                                    commercial interests (except where such interests are overridden by                                 
                                                    your rights and interests).   
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        c) YOU will have all the remedy for the breach, if any, under the General Data Protection Regulation of 2016 (GDPR)
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                USE OF COOKIES 
                            </h5>
                            <p>
                                Cookies allow us to better understand your preferences and interests so that we can 
                                personalize your current and future visits to our site. When You visit our site, we are likely to 
                                keep some of this information on their computers in the form of cookies so that You do not 
                                have to re-enter it each time they visit. They can, however, configure their browser to notify 
                                them when a cookie is received, allowing them to choose whether or not to accept it. For 
                                more information about cookies and their use, please read our  cookies policy <Link to="/cookies">here</Link> 
                            </p>

                            <h5 className="sub-heading">
                                REVISION OF THE POLICY 
                            </h5>
                            <p>
                                From time to time, we may make changes/amend our Privacy Policy. Any changes will be 
                                communicated to you by posting the revised Privacy Policy on this page. Prior to the change 
                                taking effect, we will notify you through email and/or a conspicuous notice on our Service. 
                                Please examine this Privacy Policy on a regular basis for any updates. When changes to this 
                                Privacy Policy are posted on this page, they become effective.  
                            </p>

                            <h5 className="sub-heading">
                                DISCLAIMER 
                            </h5>
                            <p>
                                We are not responsible for any communication between the user and the third-party website. 
                                the user is advised to read the privacy policy and other policies of the third party on their 
                                websites. 
                            </p>

                            <h5 className="sub-heading">
                                GOVERNING LAW  
                            </h5>
                            <p>
                                POCKET PANDIT is a Delaware, USA Company and applies Delaware jurisdiction for all 
                                legal issues and disputes. This privacy policy is designed and adopted under the Delaware 
                                Online Privacy and Protection Act (Code § 1205C) which defines the Posting of the privacy 
                                policy by operators of commercial online sites and services.  
                            </p>
                            
                            <h5 className="sub-heading">
                                CONTACTING US  
                            </h5>
                            <p>
                                For any queries regarding the policy, you can contact us at follows:   
                            </p>
                            <div className="contact-address mb-3">
                                M/S PocketPandit, INC<br/>
                                600 N Broad Street, Suite 5 # 3519<br/>
                                Middletown, DE 19709<br/> 
                                Email: <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a>  
                            </div>

                            <h5 className="sub-heading">
                                YOUR CONSENT 
                            </h5>
                            <p>
                                By using our site, you consent to our privacy policy. 
                            </p>
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        </section>
    )
}

export default Privacy
