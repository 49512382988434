import { loadJSON } from "src/lib/json-store";
import api from 'src/lib/api';

// Inital state
const initialState = {
  birthProfile: loadJSON("rtp.birthProfile") || null,
  categoryInfo: loadJSON("rtp.categoryInfo") || null,
  orderInfo: loadJSON("rtp.orderInfo") || null,
  datetime: loadJSON("rtp.datetime") || null,
  paymentDisclaimer: loadJSON("rtp.paymentDisclaimer") || "true",
  draftCartLines: loadJSON("rtp.draftCartLines") || []
};

// Action types
const FETCH_RTP_ORDER = "FETCH_RTP_ORDER";
const SELECT_PROFILE = "SELECT_PROFILE";
const SET_CATEGORYINFO = "SET_CATEGORYINFO";
const SET_ORDERINFO = "SET_ORDERINFO";
const SET_DATETIME = "SET_DATETIME";
const CLEAR_RTPINFO = "CLEAR_RTPINFO";
const PAYMENT_DISCLAIMER_ACCEPT = "PAYMENT_DISCLAIMER_ACCEPT";
const PAYMENT_DISCLAIMER_SHOW = "PAYMENT_DISCLAIMER_SHOW";
const UPDATE_DRAFT_CART_LINES = "UPDATE_DRAFT_CART_LINES";
const ADD_TO_DRAFT_CART = "ADD_TO_DRAFT_CART";
const REMOVE_FROM_DRAFT_CART = "REMOVE_FROM_DRAFT_CART";
const VALIDATE_DRAFT_CART_LINES = "VALIDATE_DRAFT_CART_LINES";

// Actions
export const fetchRTPOrder = () => {
  return async (dispatch) => {
      try {
        const queryQuestionCart = api.get("/v2/question-orders/cart");
        Promise.resolve(queryQuestionCart)
          .then((cart) => {
            dispatch({
                type: FETCH_RTP_ORDER,
                payload: {
                  orderInfo: cart.data?.result?.data || null
                }
            });
        });
    } catch (error) {
        console.log('Error question fetching cart');
    }
  }
}

export const selectProfile = (profile) => {
  return {
    type: SELECT_PROFILE,
    payload: {
      birthProfile: profile,
    },
  };
};

export const setCategoryInfo = (categoryInfo) => {
  return {
    type: SET_CATEGORYINFO,
    payload: {
      categoryInfo: categoryInfo,
    },
  };
};

export const setOrderInfo = (orderInfo) => {
  return {
    type: SET_ORDERINFO,
    payload: {
      orderInfo: orderInfo,
    },
  };
};

export const setEnterDateTime = () => {
  return {
    type: SET_DATETIME,
    payload: {
      datetime: new Date().format("yyyy-MM-ddTHH:mm:ss"),
    },
  };
};

export const clearRTPInfo = () => {
  return {
    type: CLEAR_RTPINFO,
  };
};

export const paymentDisclaimerAccept = () => {
  return {
    type: PAYMENT_DISCLAIMER_ACCEPT,
  };
};

export const paymentDisclaimerShow = () => {
  return {
    type: PAYMENT_DISCLAIMER_SHOW,
  };
};

export const updateDraftCartLines = (lines) => {
  return {
    type: UPDATE_DRAFT_CART_LINES,
    payload: {
      draftCartLines: lines
    },
  }
}

export const addToDraftCart = (line) => {
  return {
    type: ADD_TO_DRAFT_CART,
    payload: {
      line: line
    },
  }
}

export const removeFromDraftCart = (line) => {
  return {
    type: REMOVE_FROM_DRAFT_CART,
    payload: {
      line: line
    },
  }
}

export const validateDraftCartLines = (lines) => {
  const question_ids = lines.map(l=>l.question_id)
  return async (dispatch) => {
    try {
        const publishedQuestions = api.post("/v1/questions/validate-published", {
          question_ids: question_ids,
        });
        Promise.resolve(publishedQuestions)
          .then((questionData) => {
            const questions = questionData?.data?.result?.data || [];
            const published_question_ids = questions.map(q=>q.id);
            dispatch({
                type: VALIDATE_DRAFT_CART_LINES,
                payload: {
                  published_question_ids: published_question_ids
                }
            });
        });
    } catch (error) {
      console.log('Error validating questions');
    }
  }
}

// Reducer
export default function rtpReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RTP_ORDER: {
      const payload = action.payload;
      // return {
      //   ...state,
      //   orderInfo: payload.orderInfo,
      // };

      const cartLines = (payload.orderInfo?.order_lines || []).map(l=>({question_id: l.question_id, profile_id: l.profile_id}))
      if(state.draftCartLines.length < 1 && cartLines.length) {
        return {
          ...state,
          orderInfo: payload.orderInfo,
          draftCartLines: cartLines
        }
      } else {
        return {
          ...state,
          orderInfo: payload.orderInfo,
        };
      }
    }

    case SELECT_PROFILE: {
      const payload = action.payload;

      return {
        ...state,
        birthProfile: payload.birthProfile,
      };
    }

    case SET_CATEGORYINFO: {
      const payload = action.payload;

      return {
        ...state,
        categoryInfo: payload.categoryInfo,
      };
    }

    case SET_ORDERINFO: {
      const payload = action.payload;

      return {
        ...state,
        orderInfo: payload.orderInfo,
      };
    }

    case CLEAR_RTPINFO: {
      return {
        birthProfile: null,
        categoryInfo: null,
        orderInfo: null,
        datetime: null,
        paymentDisclaimer: "true",
        draftCartLines: []
      };
    }

    case SET_DATETIME: {
      const payload = action.payload;

      return {
        ...state,
        datetime: payload.datetime,
      };
    }

    case PAYMENT_DISCLAIMER_ACCEPT: {
      return {
        ...state,
        paymentDisclaimer: "false",
      };
    }

    case PAYMENT_DISCLAIMER_SHOW: {
      return {
        ...state,
        paymentDisclaimer: "true",
      };
    }

    case UPDATE_DRAFT_CART_LINES: {
      const payload = action.payload;
      return {
        ...state,
        draftCartLines: payload.draftCartLines 
      }
    }

    case ADD_TO_DRAFT_CART: {
      const payload = action.payload
      return {
        ...state,
        draftCartLines: [...state.draftCartLines, payload.line]
      }
    }

    case REMOVE_FROM_DRAFT_CART: {
      const payload = action.payload
      return {
        ...state,
        draftCartLines: [...state.draftCartLines.filter(l=>!(l.profile_id === payload.line.profile_id && l.question_id === payload.line.question_id))]
      }
    }

    case VALIDATE_DRAFT_CART_LINES: {
      const question_ids = action.payload.published_question_ids || [];
      const validatedLines = state.draftCartLines.filter(l=>question_ids.includes(l.question_id));
      return {
        ...state,
        draftCartLines: validatedLines
      }
    }

    default:
      return state;
  }
}
