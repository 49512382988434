import React from "react";

import pocketloading from 'src/image/pocket-loading.gif'

const LoadingIcon = ({children}) => {
  
  return (
        <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="data-loading-icon">
                <img src={pocketloading} className="img-fluid" alt="Loading Spinner" />
            </div>
            {children && (
                <div className="data-loading-text text-center">
                    {children}
                </div>
            )}
        </div>
    )
}

export default LoadingIcon
