import React from "react"
import { MessageImageSvg } from "src/core/component/assets/svg/CommonSVG"


const MessageImageField = ({
        property,
        onChange,
        inputRef,
        type="file"
}) => {

    return (
        <div className={`pocket-image-input`}>
            <input ref={inputRef} type={type} id={property} name={property} className="pocket-image" onChange={onChange} accept="image/png, image/jpeg" />
            <label htmlFor={property}>
                <MessageImageSvg />
            </label>
        </div>
    )
}

export default MessageImageField
