import React from "react";
import Moment from 'react-moment';


const MomentRelativeTime = ({datetime, avoidSuffix=false, ...rest}) => {
    //For all possible format see=>https://www.npmjs.com/package/react-moment
    // ... ago
    
    return (
        <>
            {avoidSuffix && (
                <Moment fromNow ago>
                { datetime }
                </Moment>
            ) || (
                <Moment fromNow>
                    { datetime }
                </Moment>
            )}
            
        </>
    )
}

export default MomentRelativeTime
