import React from 'react'
import { Link } from 'react-router-dom';

import LogoLight from 'src/core/component/LogoLight'
import LogoDark from 'src/core/component/LogoDark';


const BrandLogo = ({isDarkMode, ...rest}) => {

    return (
            <Link to="/" className="navbar-brand navigation__logo">
                { isDarkMode ?
                    <LogoDark />
                    :
                    <LogoLight />

                }
            </Link>

        )
}

export default BrandLogo
