import React from "react";

export default function CurrencySvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#FFD80B"
        d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z"
      ></path>
      <path
        fill="#0061E7"
        d="M15.21 5.416h-3.856a1.363 1.363 0 00-1.377 1.349v1.69A.552.552 0 019.42 9H7.47a1.362 1.362 0 00-1.377 1.349v6.31c0 .52.519.94 1.16.94.64 0 1.16-.42 1.16-.94v-1.191h2.911a1.363 1.363 0 001.378-1.347v-1.865a.376.376 0 01.38-.372h2.127a1.363 1.363 0 001.378-1.347V6.765a1.365 1.365 0 00-1.377-1.349zm-4.84 7.77c-.26.255-.61.396-.974.395a1.363 1.363 0 01-1.378-1.347 1.322 1.322 0 01.404-.958 1.4 1.4 0 011.948 0 1.326 1.326 0 010 1.904v.007zm4.325-4.498a1.362 1.362 0 01-1.376 1.346h-.075a1.36 1.36 0 01-1.376-1.346v-.075a1.363 1.363 0 011.377-1.347h.08A1.363 1.363 0 0114.7 8.613l-.005.075z"
      ></path>
      <path
        fill="#FFD80B"
        d="M13.283 10.368a1.718 1.718 0 100-3.435 1.718 1.718 0 000 3.435z"
      ></path>
    </svg>
  );
}
