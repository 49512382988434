import React from "react";
import { useTheme } from "src/theme/component/ThemeContext";

const FormCheckboxSwitch = ({
    hasLabel=false, 
    label="",
    labelTwo="",
    classes="", 
    value=false,
    property,
    checked=false,
    onChange,
    disabled=false,
    ...rest
}) => {
  const [isDarkMode] = useTheme();

  const dynamicProperties = {
    style: {
      color: isDarkMode
        ? value
          ? "#D49369"
          : "#CCCCCC"
        : value
        ? "#D49369"
        : "#0A1D40",
      cursor: "pointer",
    },
    styleTwo: {
      color: isDarkMode
        ? value
          ? "#CCCCCC"
          : "#D49369"
        : value
        ? "#0A1D40"
        : "#D49369",
      cursor: "pointer",
    },
  };

  return (
    <>
      {/* {
                hasLabel &&
                <label className="form-check-label" htmlFor={property}>{label}</label>
            }

            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" name={property} id={property} defaultChecked={value} value={value} onChange={onChange} />
            </div> */}

            <div className="form-check form-switch">
                {
                    labelTwo && (
                        <label className="form-check-label me-2" htmlFor={value ? property : ""} style={dynamicProperties.styleTwo}>{labelTwo}</label>
                    ) || ""
                }
                <input className="form-check-input" type="checkbox" name={property} id={property} checked={value} onChange={onChange} disabled={disabled} />
                <label className="form-check-label ms-2" htmlFor={labelTwo && value ? "" : property} style={dynamicProperties.style}>{label}</label>
            </div>
        </>
    )
}

export default FormCheckboxSwitch;
