import React from "react";
import { Link } from 'react-router-dom';


const DialogContent = (props) => {
  
  return (
            <div className={"pocket-dialog__content " + props.classes}>
                {props.children}
            </div>
        )
}

export default DialogContent
