import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTheme } from "src/theme/component/ThemeContext";
import ForumReplyList from "src/forumV2/forumDetail/ForumReplyList";
import ForumItemCard from "src/forum/component/ForumItemCard";
import useAuthInfo from "src/auth/hook/auth-info";
import api from "src/lib/api";
import * as forumApi from "src/lib/http/forum";
import SimpleAlert from "src/core/component/SimpleAlert";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useSocket } from "src/socket/component/SocketContext";
import { HomeSvgRound } from "src/core/component/assets/svg/CommonSVG";
import ForumPromo from "src/forumV2/forumPromo/ForumPromo"
import ForumPostYourCard from "../forumPost/forumPostYour/ForumPostYourCard"
import "./forumPostDetails.scss" 
import NotAuthenticatedAlert from "./NotAuthenticatedAlert"
import ForumReplyForm from "src/forumV2/forumDetail/ForumReplyForm"

const ForumDetailsV2 = () => {
  const params = useParams();
  const history = useHistory();
  const socket = useSocket();
  const [isDarkMode] = useTheme();
  const { isAuthenticated, profile } = useAuthInfo();
  const [showReplyForm, setShowReplyForm] = useState(true);
  const [postId, setPostId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [post, setPost] = useState(null);
  const [replies, setReplies] = useState([]);
  const [replyData, setReplyData] = useState({ content: "" });
  const [replySubmitting, setReplySubmitting] = useState(false);
  const [replyTextLength, setReplyTextLength] = useState(1000);

  useEffect(() => {
    if (params["postId"]) {
      setPostId(params.postId);
    }
  }, [params]);

  useEffect(() => {
    if (postId) {
      socket.emit("forum_post:seen", JSON.stringify({ post_id: postId }));
      fetchPost(postId);
      fetchReplies(postId);
    }
  }, [postId]);

  useEffect(() => {
    const onForumPostMetrics = ({ data }) => {
      setPost(prev=>({...prev, metrics:data?.post?.metrics }))
      // TODO is_like change garnu parxa is self le like gareko ho vane.
    }

    socket.on("forum_post:metrics",onForumPostMetrics);

    return () => {
      socket.off("forum_post:metrics", onForumPostMetrics);
    };
    
  },[])

  useEffect(()=>{
    return () => {
      if (history.action == 'POP') {
        history.replace({
          pathname: "/forum",
          state: {post_id: params.postId,}
        });
    }
  }
  },[])

  const fetchPost = async (id) => {
    setLoading(true);
    try {
      const result = await api.get(
        `v1/forum-posts/${id}/metrics?${queryString.stringify({'user_id':profile?.id}, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      setPost(result?.data?.result?.data);
    } catch (error) {
      setError("Failed to fetch a post!");
    } finally {
      setLoading(false);
    }
  };

  const fetchReplies = async (id) => {
    const params = queryString.stringify({ limit: 50 });
    try {
      const result = await forumApi.getPostReplies(id, params);
      setReplies(result?.data?.result?.data);
    } catch (error) {
      setError("Failed to fetch a post replies!");
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.length === replyTextLength) {
      PocketToast({
        type: "warning",
        message: `Reply should not be longer than ${replyTextLength} characters`,
      });
    }
    setReplyData({ ...replyData, [e.target.name]: e.target.value });
  };

  const handleOnClickReply = () => {
    if (replySubmitting) {
      return;
    }

    setShowReplyForm((prevState) => !prevState);
  };

  const handleReplySubmit = (e) => {
    e.preventDefault();
    handleCreatePostReply(replyData);
  };

  const handleCreatePostReply = async (data) => {
    // TODO sanitize html
    setReplySubmitting(true);
    try {
      const result = await api.post(`v1/forum-posts/${postId}/replies`, data);
      setReplies([...replies, result?.data?.result?.data]);
      setReplyData({ content: "" });
      setPost({
        ...post,
        replies_count: (post?.replies_count || 0) + 1,
      });
    } catch (error) {
      let errorData = error?.response?.data?.error || error
      setError(errorData?.message || "Failed to submit a reply!");
    } finally {
      setReplySubmitting(false);
    }
  };

  // console.log('Post', post);
  // console.log('Replies', replies);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          {error && (
            <SimpleAlert classes="alert-danger mb-3">
              <p className="mb-0">{error}</p>
            </SimpleAlert>
          )}
          {!post && loading && <LoadingIcon />}
        </div>

          <div className="row justify-content-center">
            {post && (
            <>
              <div className="col-12 col-lg-9">
                <div className="forum_post_questions">
                  {post &&
                    <div className="question_body">
                      <ForumPostYourCard item={post} setPosts={setPost} isYourPost = {false} replyClick={handleOnClickReply}/>
                    </div>
                    
                  }

                  {showReplyForm && (
                    <div className="forum-post-reply">
                      {!isAuthenticated && (
                        <NotAuthenticatedAlert
                          isDarkMode={isDarkMode}
                          title={"Login or Sign up to add your reply."}
                        />
                      )}

                      {isAuthenticated && (
                        <ForumReplyForm
                          onChange={handleInputChange}
                          handleSubmit={handleReplySubmit}
                          value={replyData["content"]}
                          loading={replySubmitting}
                          isDarkMode={isDarkMode}
                          rows={"6"}
                          maxLength={replyTextLength}
                        />
                      )}
                    </div>
                  )}

                  {replies.length > 0 && (
                    <div className={"forum-post-replies" + ((isDarkMode && " dark") || "")} >
                      <ForumReplyList
                        postReplies={replies}
                        post={post}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-3">
            <ForumPromo/>
              </div>
            </>
            )}
          </div>
      </div>

      <div className="forum-reply-back">
        <Link to="/forum">
          <HomeSvgRound />
        </Link>
      </div>
    </>
  );
};

export default ForumDetailsV2;
