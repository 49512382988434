import React from "react";
import Modal from "react-bootstrap/Modal";

import LoadingIcon from "src/core/component/loader/LoadingIcon";
import ModalBody from "src/core/component/modal/ModalBody";
import {
  CloseButtonSvg,
  ProcessingSuccessSvg,
} from "src/core/component/assets/svg/CommonSVG";

const PaymentProcessingModal = ({
  showModal,
  handleModalClose,
  success,
  processing,
  showCloseIcon,
  isDarkMode,
  ...rest
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      className={"payment-processing__modal" + ((isDarkMode && " dark") || "")}
      size="lg"
      backdrop="static"
    >
      {showCloseIcon && (
        <div className="modal-custom-close" onClick={handleModalClose}>
          <CloseButtonSvg />
        </div>
      )}

      <ModalBody>
        <div className={"payment-status" + ((isDarkMode && " dark") || "")}>
          <div className="text-center">
            {processing && (
              <>
                <LoadingIcon />
                <h5 className="text processing">Processing...</h5>
              </>
            )}

            {success && (
              <div className="success">
                <ProcessingSuccessSvg />
                <h5 className="text done">Payment Successful</h5>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentProcessingModal;
