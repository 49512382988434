import React, {useState} from "react";
import { useHistory } from "react-router";

import FormField from "src/core/component/form/FormField";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { EmailSvg } from "src/core/component/assets/svg/CommonSVG";
import api from 'src/lib/api';
import { useAlert } from "src/core/hook/Alert";
import { isObjectEmpty } from "src/util/extra";
import DismissableAlert from "src/core/component/DismissableAlert";


export default function ForgotPasswordPage({isDarkMode}) {
    const history = useHistory();
    const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
    const [showModal, setShowModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
       'login': ''
    });

    let fields = [
        {
            name: 'login',
            placeholder: 'Enter email address',
            hasLabel: false,
            type: 'email',
            required: true,
        }
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(processing) return;
        if(isObjectEmpty(formData)) {
            setError('Email required!')
            setShowAlert(true);
            return;
        }
        
        setProcessing(true);
        requestPasswordReset(formData).catch(e=> {
            setError(e?.response?.data?.error?.message || e?.message);
            setShowAlert(true)
            setProcessing(false)
        })
    }

    const requestPasswordReset = async (payload) => {
        const result = await api.post('v1/request-password-reset', payload)
        setProcessing(false)
        if(result?.status === 200) {
            setShowModal(true)
        }
    }

    const handleModalClose = () => {
        setShowModal(false);

        setFormData({
            ...formData,
            'login': '',
        });
        history.push('/auth/reset-password');
    }

    return (
        <div className={"pocket-auth__forgot-password" + (isDarkMode && ' dark' || '')}>
            <div className="row justify-content-center">
                <div className="col-sm-9 col-md-9 col-lg-9 col-xl-10">
                    <div className={"card" + (isDarkMode && ' dark' || '')}>
                        {showAlert && (
                            <DismissableAlert text={error} handleAlertClose={handleAlertClose} classes={'dismissable-alert alert-error'}>
                            </DismissableAlert>
                        )}

                        <h4 className="heading">Forgot Password?</h4>
                        <p className="description">
                            Enter email or phone number associated with your account
                        </p>
                        <form onSubmit={handleFormSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')}>
                            <div className="row gx-2 gx-smsm-3">
                                    {
                                        fields && fields.map((item, index) =>{
                                            return (
                                                <div className="col-12 col-lg-10 col-xl-8 field" key={index}>
                                                    {(item.type === "text" || item.type === "email") && (
                                                        <FormField 
                                                            property={item.name}
                                                            type={item.type}
                                                            hasLabel={false}
                                                            placeholder={item.placeholder}
                                                            onChange={handleChange}
                                                            value={formData[item.name]}
                                                            required={item.required}
                                                            autoComplete={item.name}
                                                        />
                                                    )}
                                                    
                                                </div>
                                            )
                                        })
                                    }
                            </div>

                            <div className="submit-login-register">
                                <button className="pocket-btn" type="submit">
                                    {processing ? 'Processing...' : 'Reset Password'}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {/* Email/Code sent modal */}
            <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={true} centered={false} classes={'pocket-auth__modal'  + (isDarkMode && ' dark' || '')} backdrop="static">
                <ModalBody>
                    <div className="card">
                        <div className="icon mail-sent mx-auto">
                            <EmailSvg />
                        </div>
                        <p className="card-text">We have sent you a reset code on your email address</p>
                    </div>
                </ModalBody>
            </CommonModal>
        </div>
    )
}
