import React from "react";

import MainLayout from "src/core/component/MainLayout";
import Career from "../career/Career";

const CareerPage = () => {
  return (
    <MainLayout>
      <Career />
    </MainLayout>
  )
}

export default CareerPage;
