// this is north style_1
export const kundaliLines1 = (w, h) => {
  return [
    //inside
    {move: [w/2, h/9], line1: [w-w*3/10, h*3/10],line2:[w/2, h/2],line3:[w*3/10, h*3/10]},
    {move: [w/10, h/9], line1: [w/2, h/9],line2:[w*3/10, h*3/10],line3:[]},
    {move: [w/10, h/2], line1: [w*3/10, h*3/10],line2:[w/10, h/9],line3:[]},
    {move: [w/2, h/2], line1: [w*3/10, h*3/10],line2:[w/10, h/2],line3:[w*3/10, h-h*3/10]},
    {move: [w*3/10, h-h*3/10], line1: [w/10, h-h/9],line2:[w/10, h/2],line3:[]},
    {move: [w/2, h-h/9], line1: [w/10, h-h/9],line2:[w*3/10, h-h*3/10],line3:[]},
    {move: [w/2, h-h/9], line1: [w-w*3/10, h-h*3/10],line2:[w/2, h/2],line3:[w*3/10, h-h*3/10]},
    {move: [w-w*3/10, h-h*3/10], line1: [w-w/10, h-h/9],line2:[w/2, h-h/9],line3:[]},
    {move: [w-w/10, h/2], line1: [w-w/10, h-h/9],line2:[w-w*3/10, h-h*3/10],line3:[]},
    {move: [w/2, h/2], line1: [w-w*3/10, h*3/10],line2:[w-w/10, h/2],line3:[w-w*3/10, h-h*3/10]},
    {move: [w-w/10, h/9], line1: [w-w/10, h/2],line2:[w-w*3/10, h*3/10],line3:[]},
    {move: [w/2, h/9], line1: [w-w/10, h/9],line2:[w-w*3/10, h*3/10],line3:[]},
    // level 1
    {move: [w/3, 0], line1: [w/3*2, 0],line2:[w/3*2, h/9],line3:[w/3, h/9]},
    {move: [0, 0], line1: [w/3, 0],line2:[w/3, h/9],line3:[w/10, h/9]},
    {move: [0,0], line1: [w/10, h/9],line2:[w/10, h/3],line3:[0, h/3]},
    {move: [0, h/3*2], line1: [0, h/3],line2:[w/10, h/3],line3:[w/10, h/3*2]},
    {move: [w/10, h-h/9], line1: [0, h],line2:[0, h/3*2],line3:[w/10, h/3*2]},
    {move: [w/3,h-h/9], line1: [w/3,h],line2:[0, h],line3:[w/10, h-h/9]},
    {move: [w/3*2, h-h/9], line1: [w/3*2, h],line2:[w/3,h],line3:[w/3,h-h/9]},
    {move: [w-w/10, h-h/9], line1: [w, h],line2:[w/3*2, h],line3:[w/3*2, h-h/9]},
    {move: [w-w/10, h/3*2], line1: [w, h/3*2],line2:[w, h],line3:[w-w/10, h-h/9]},
    {move: [w-w/10, h/3], line1: [w, h/3],line2:[w, h/3*2],line3:[w-w/10, h/3*2]},
    {move: [w, 0], line1: [w, h/3],line2:[w-w/10, h/3],line3:[w-w/10, h/9]},
    {move: [w/3*2, 0], line1: [w, 0],line2:[w-w/10, h/9],line3:[w/3*2, h/9]},
  ];
}

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // color:'yellow',
  // fontFamily: "Montserrat",
};
export const transitTextStyle = {
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // color:'yellow',
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(5 * scale, 6),
  fontWeight: 700,
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  // color:'red',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(6 * scale, 10),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  // color:'yellow',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  fontWeight: 600,  
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 66 * scale,
      width: 96 * scale,
      top: 60 * scale,  
      left: 152 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 45 * scale,
      left: 194 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 126 * scale,
      left: 193 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 43 * scale,    
      left: 70 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 33 * scale,
      left: 115 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 70 * scale,
      left: 114 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 95 * scale,
      width: 55 * scale,
      top: 54 * scale,
      left: 30 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 42 * scale,
      left: 43 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 78 * scale,
      left: 103 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 82 * scale,
      width: 78 * scale,
      top: 110 * scale, 
      left: 83 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 94 * scale,
      left: 115 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 134 * scale,
      left: 182 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 88 * scale,
      width: 55 * scale,
      top: 166 * scale,
      left: 31 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 119 * scale,
      left: 44 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 79 * scale,
      left: 103 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 44 * scale,
      width: 102 * scale,
      top: 220 * scale,
      left: 70 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 62 * scale,
      left: 115 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 71 * scale,
      left: 114 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 64 * scale,
      width: 98 * scale,
      top: 165 * scale,
      left: 151 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 118 * scale,
      left: 193 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 126 * scale,
      left: 193 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 44 * scale, 
      width: 120 * scale,
      top: 220 * scale,
      right: 60 * scale,  
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 62 * scale,
      right: 117 * scale, 
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 71 * scale,
      right: 115 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 90 * scale,
      width: 55 * scale,
      top: 166 * scale,
      right: 31 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 119 * scale,
      right: 44 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 79 * scale,
      right: 103 * scale
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 82 * scale,
      width: 78 * scale,
      top: 110 * scale,
      right: 83 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 96 * scale,
      right: 115 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 134 * scale,
      right: 185 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 95 * scale,
      width: 55 * scale,
      top: 55 * scale,
      right: 32 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 43 * scale,
      right: 44 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 79 * scale,
      right: 103 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 43 * scale,    
      right: 67 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 33 * scale,
      right: 115 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 71 * scale,
      right: 115 * scale,
    }
  }]
  export const gharaStyles2 = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 135 * scale,
    }
  },  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 46 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 37 * scale,
    }
  },  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 25 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 22 * scale,
      left: 2 * scale,
    }
  },  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      left: 2 * scale,
    }
  },  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 203 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 190 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      left: 2 * scale,
    }
  },  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 47 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 37 * scale,
    }
  },  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 135 * scale,
    }
  },  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 265 * scale,
    }
  },  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 200 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 189 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      right: 2 * scale,
    }
  },  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      right:2 * scale,
    }
  },  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 21 * scale,
      right: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 20 * scale,
      right: 2 * scale,
    }
  },  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 265 * scale,
    }
  },
];
