import React from 'react'
import { Link } from 'react-router-dom';

import api from 'src/lib/api'

export default function ChatUserList({ onSelectItem }) {
  onSelectItem = typeof onSelectItem === 'function' ? onSelectItem : () => { }

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {

    api
      .get('v1/users', {
        params: { 'exclude_me': 1 }
      })
      .then(res => {
        return (res.data.result?.data || []);
      })
      .then(setItems)
      .catch((error) => console.log('Error fetching user list', error));

  }, []);


  const handleItemClick = (item, e) => {
    e.preventDefault();

    onSelectItem(item);
  }


  return (<div className="list-group list-group-flush">
    {items.map(item => (
      <Link key={item.id} to="#" className="list-group-item list-group-item-action" onClick={handleItemClick.bind(null, item)}>
        <div className="d-flex py-2">
          <div className="flex-shrink-0 d-flex align-items-center">
            <img className="img-responsive rounded-circle" width="40" src={item.avatar_url} />
          </div>
          <div className="flex-grow-1 ms-3">
            <div className="clearfix">
              <p className="float-end small-text text-success my-0">
                <i className="fa fa-fw fa-dollar"></i>
                {Number(item.chat_rate_per_hour || 0).toFixed(2)} / Hour
              </p>

              <div className="float-start">
                <h6 className="my-0 small-text">
                  <strong>{item.name}</strong>
                </h6>

                <span className="small-text">
                  {item.login}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    ))}
  </div>)
}
