import React, {useEffect, useState} from "react";
import { Col, Row, Form } from "react-bootstrap";

import PocketSelection from "src/core/component/selection/PocketSelection";
import useExchangeRate from "src/wallet/hook/useExchangeRate"

import CurrencySvg from "src/wallet/images/Collection";
import "./CurrencyConvert.scss";

function CurrencyConvert({currentCurrency,setCurrentCurrency,currencies}) {

  let currencyList = currencies.filter(cur=>cur.value!='PP')
  let selectedCurrency = currentCurrency.value =='PP'?currencyList[0]:currentCurrency;

  const [currencyAmount,setCurrencyAmount] = useState(1);
  const [convertedAmount,setConvertedAmount] = useState(null);
  const {exchangeRate, loading, error } = useExchangeRate()

  function handleCurrencyChange(item) {
    setCurrentCurrency(item);
  }

  const handleAmountChange = (e)=>{
    let value = e.target.value
    if(!isNaN(value)){
      setCurrencyAmount(value)
    }
  }

  useEffect(()=>{
    if(!loading && error==null && exchangeRate){
      let excg_rate_usd = 0
      let excg_rate = 0
      excg_rate_usd = exchangeRate.find(rate=>rate.exchange_currency == 'PP').exchange_rate
      if(selectedCurrency.value == 'USD'){
      setConvertedAmount(excg_rate_usd*currencyAmount)
      }else{
        excg_rate = exchangeRate.find(rate=>rate?.exchange_currency == selectedCurrency?.value).exchange_rate
        let exchanged_amount = Math.ceil(currencyAmount/excg_rate*excg_rate_usd)
        setConvertedAmount(exchanged_amount)
      }
    }
  },[selectedCurrency,currencyAmount,loading])


  return (
    <div className="currency-converter">
      <Row xs={2} className="gx-1 gx-sm-4 gx-lg-5">
        <Col>
          <Form.Label>Paying Currency</Form.Label>
          <div className="d-inline-flex d-lg-flex currency-converter__form">
            <PocketSelection
              options={[...currencyList]}
              currentValue={selectedCurrency}
              onChange={handleCurrencyChange}
            />
            <Form.Control type="text" value={currencyAmount} onChange={handleAmountChange} />
          </div>
        </Col>
        <Col>
          <div className="currency-converter__converted">
            <p>Received Pocket Pesa</p>
            <div className="d-flex mt-auto position-relative">
              <div className="currency-converter__amount">
                <CurrencySvg />
                <span>{convertedAmount}+{Math.ceil(convertedAmount*15/100)}</span>
              </div>
              <p className="d-none d-lg-inline-flex currency-converter__bonus">
                Get 15% bonus pocket pesa
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CurrencyConvert;
