import React from "react";

import { Link } from "react-router-dom";
import { truncate } from "src/util/extra";
import CircleImage from "src/core/component/birthprofile/CircleImage";
import PocketRatings from "src/core/component/rating/PocketRatings";
import useAuthInfo from 'src/auth/hook/auth-info';


const ConversationItem = ({ 
    conversation,
    isDarkMode,
    showRate=false,
    ...rest 
}) => {

    const { profile } = useAuthInfo();
    const astrologerData = conversation && conversation.users.filter(u=>u.id!==profile.id)[0] || null

    const spacializations = astrologerData && astrologerData?.specialization && astrologerData.specialization.map(sp=>sp.name).join(" ") || '';

    // console.log('Astrologer', astrologerData)

    return ( 
        <div className="order-lines__item conversation">
            <div className={`card astrologer ${isDarkMode && 'dark' || ''}`}>
                <div className="card-body astrologer">
                    <div className="card-text">
                        <div className={`astrologer-profile ${isDarkMode && 'dark' || ''}`}>
                            <div className="d-flex">
                                <div className="image">
                                    <CircleImage
                                        url={astrologerData?.avatar_url}
                                        showIconOverlay={false}
                                        isDarkMode={isDarkMode}
                                        showIsOnline={true}
                                        isOnline={astrologerData?.isOnline || true}
                                    />
                                </div>
                                <div className={`infos ${isDarkMode && 'dark' || ''}`}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Link to={`/astrologer-profile/${astrologerData.id}`} className="name">
                                            {astrologerData?.name}
                                        </Link>
                                        {showRate && (
                                            <p className="chat-rate mb-0">
                                                {`$${astrologerData.chat_rate_per_hour || 0}/hr`}
                                            </p>
                                        )}
                                    </div>
                                    {spacializations && (
                                        <p className="specialization">{truncate(spacializations, 35)}</p>
                                    )}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <PocketRatings
                                            ratings={+astrologerData.rating}
                                            isDarkMode={isDarkMode}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConversationItem
