import React from "react"

const CardIcon = (props) => {

  return (   
        <div className={props.classes}>
            {props.children}
        </div> 
    )
}

export default CardIcon
