import React from "react";
import { useHistory } from "react-router-dom";

import { ChangeProfileIcon } from "src/core/component/assets/svg/CommonSVG";
import MomentCalendarString from "src/core/component/moment-date-time/MomentCalendarString";


const MessageItem = ({item, isReply, isDarkMode, ...rest}) => {
    const history = useHistory();

    const handleImageClick = (item) => {
        if(!item || !item.user.astrologer){
            return;
        }
        
        history.push(`/astrologer-profile/${item.user.id}`)
    }


    return (
        <div className={`pocket-astrologer__chat-conversations-item ${isDarkMode && 'dark' || ''}`}>
            <div className={`pocket-astrologer__chat-conversations-item-message ${!isReply && 'self' || ''}`}>
                <div className={`message-wrapper`}>
                    <div className="image">
                        { item?.user?.avatar_url && <img src={item.user.avatar_url} className="img" onClick={handleImageClick.bind(null, item)} style={{cursor:'pointer'}} /> || <ChangeProfileIcon />}
                    </div>

                    <div className={`message-content ${!isReply && 'self' || ''} ${item.error && 'text-danger' || ''}`}>
                        <p className="message">
                            {item.content}
                        </p>
                        {item.error && (
                            <span className="text-danger">{item.error}</span>
                        )}
                    </div>
                        
                </div>
            </div>
        </div>
    )
}

export default MessageItem;
