import React from "react";

import Kundali from "src/core/component/kundali/Kundali";
import { ProfileIconSvg, ClockSvg, LocationSvg } from "src/core/component/assets/svg/AstrologySVG";


const KundaliSliderItem = ({
    data, 
    variant,
    title,
    config, 
    profile,
    scale,
    isDarkMode, 
    ...rest
}) => {

    return (
        <div className="pocket-kundali__slider-item">  
            {variant === 'north' && (
                <>
                    <div className="kundali-title">
                        <h3>{title}</h3>
                    </div>
                    <Kundali
                        variant={variant}
                        data={data}
                        xScale={scale}
                        className={`Rtp Kundali ${isDarkMode && 'dark' || ''}`}
                        color={config['name'] == 'rasi' ? '#873A23' : '#03a012'}
                        sthaanaStyle={{ color: '#afafaf', fontWeight: 'normal', fontFamily: 'Montserrat' }}
                        grahaStyle={{ color: '#505050', fontFamily: 'Montserrat', fontWeight: '600' }}
                    >
                    </Kundali>
                </>
            )}

            {(variant !== 'north') && (
                <>
                    <div className={`kundali-title ${isDarkMode && 'dark' || ''}`}>
                        <h3>{title}</h3>
                    </div>
                    <Kundali
                        variant={variant}
                        data={data}
                        xScale={scale}
                        className={`Rtp Kundali ${isDarkMode && 'dark' || ''}`}
                        color={config['name'] == 'rasi' ? '#873A23' : '#03a012'}
                        sthaanaStyle={{ color: '#afafaf', fontWeight: 'normal', fontFamily: 'Montserrat' }}
                        grahaStyle={{ color: '#505050', fontFamily: 'Montserrat', fontWeight: '600' }}
                    >
                        {profile && (
                            <div className={`text-center chart-profile ${variant} ${isDarkMode && 'dark' || ''}`}>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-center justify-content-center name">
                                        <ProfileIconSvg />
                                        {profile?.name}
                                    </li>

                                    <li className="text-center">
                                        <div className="d-flex align-items-center justify-content-center location">
                                            <LocationSvg />
                                            {profile?.latitude}, {profile?.longitude}
                                        </div>
                                        <span className="address">
                                            {profile?.address}
                                        </span>
                                    </li>
                                    <li className="text-center">
                                        <div className="d-flex align-items-center justify-content-center timezone">
                                            <ClockSvg />
                                            {profile?.timezone}
                                        </div>
                                        <span className="dob">
                                            {new Date(profile?.dob).format('MMM dd, yyyy hh:mm tt')}
                                        </span>
                                        
                                    </li>
                                </ul>
                            </div>
                        )}

                    </Kundali>
                </>
            )}
        </div>
    )
}

export default KundaliSliderItem