import React from "react";

import { ChangeProfileIcon } from "src/core/component/assets/svg/CommonSVG";
import MomentRelativeTime from "src/core/component/moment-date-time/MomentRelativeTime";
import MomentCalendarString from "src/core/component/moment-date-time/MomentCalendarString";
import { useModal } from "src/core/hook/useModal";
import ImagePopup from "src/core/component/support/ImagePopup";


const ReplyItem = ({item, userInfo, isDarkMode, ...rest}) => {
    const isSelf = item && (item?.user?.id === userInfo?.id) || false;
    const [showModal, handleModalShow, handleModalClose] = useModal(false);

    // console.log('Is Self', isSelf);

    return (
        <div className={`pocket-customer-support__support-reply-item ${isDarkMode && 'dark' || ''}`}>
            <div className={`pocket-customer-support__support-reply-item-message ${isSelf && 'self' || ''}`}>
                <div className="message-wrapper">
                    <div className="image">
                        { item?.user?.avatar_url && <img src={item.user.avatar_url} className="img" /> || <ChangeProfileIcon />}
                    </div>

                    <div className={`reply-content ${isSelf && 'self' || ''}`}>
                        {item?.image_url && (
                            // <a href={item.image_url} target="_blank">
                                <div className="reply-image" onClick={handleModalShow} style={{cursor: 'pointer'}}>
                                    <img src={item.image_url} className="img-fluid" />
                                </div>
                            // </a>
                        )}
                        <p className="reply">
                            {item.reply}
                        </p>


                        <span className="date-replied">
                            {item?.created_at && (
                                <>
                                    <MomentRelativeTime
                                        datetime={item.created_at}
                                    />

                                    {/* <MomentCalendarString
                                        datetime={item.created_at}
                                    /> */}
                                </>
                            )}
                        </span>

                        {!isSelf && (
                            <p className="replied-by">
                                {item.user?.name}
                            </p>
                        )}
                    </div>

                </div>
            </div>

            {/* Image Modal */}
            {showModal && (
                <ImagePopup
                    showModal={showModal}
                    handleModalClose={handleModalClose}
                    isDarkMode={isDarkMode}
                    imageSrc={item?.image_url}
                    caption={item.reply}
                />
            )}
        </div>
    )
}

export default ReplyItem;
