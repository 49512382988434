import React, { useState, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";

import HomePage from "src/home/component/page/HomePage";
import Auth from "src/auth/component/page/Auth";
import useAuthInfo from "src/auth/hook/auth-info";

import AboutUsPage from "src/about-us/component/page/AboutUsPage";
import ContactPage from "src/contact/component/page/ContactPage";
import CareerPage from "src/career/component/page/CareerPage";
import NotFoundPage from "src/core/component/page/NotFoundPage";
import CookiePage from "src/gdpr/component/page/CookiePage";
import TosPage from "src/gdpr/component/page/TosPage";
import PrivacyPage from "src/gdpr/component/page/PrivacyPage";
// import RTPHome from "src/realtimeprediction/component/page/RTPHome";
import RTPHome from "src/instant-prediction/RTPHome";
// import FreeAstrologyHome from "src/freeastrology/component/page/FreeAstrologyHome";
// import FreeAstrologyHomeV2 from "src/freeastrology/component/page/FreeAstrologyHomeV2";
import MainLayout from "src/core/component/MainLayout";
import useDefaultProfile from "src/core/hook/useDefaultProfile";
import ForumHome from "src/forum/component/page/ForumHome";
import ForumHomeV2 from "src/forumV2/ForumHome";
import SettingsHome from "src/profilesetting/component/page/SettingsHome";
import FAQPage from "src/faq/component/page/FAQPage";
import PromosHome from "src/promos/component/page/PromosHome";
import ShopHome from "src/shop/component/page/ShopHome";
import ConnectHome from "src/connect-with-astrologer/component/page/ConnectHomeOld";
import CustomerSupportHome from "src/customer-support/component/page/CustomerSupportHome";
import ConnectAstrologerHome from "src/connect-with-astrologer/component/page/ConnectAstrologerHome";
import AstrologerChatHome from "src/connect-with-astrologer/component/page/AstrologerChatHome";
import AstrologerHome from "src/astrologer/component/page/AstrologerHome";
import AstrologerProfile from "src/profile/component/astrologer/AstrologerProfile";
import NotificationMobile from "src/notification/page/NotificationMobile";
import ActionLinkPage from "src/action-link/component/page/ActionLinkPage";
import EmailVerificationPage from "src/auth/component/page/EmailVerificationPage";
import Wallet from "src/wallet/Wallet";

import Login from "src/backend/pages/Login/Login";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import PaymentPolicyPage from "src/gdpr/component/page/PaymentPolicyPage";
import useFreeAstrology from "src/freeastrology/hook/useFreeAstrology";
import useNotification from "src/notification/hook/useNotification";
import usePromo from "src/promos/hook/usePromo";
import { useSocket } from "src/socket";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import getAppSettings from "src/lib/services/app-setting"

// import AdminDashboard  from "src/backend/App";
const AdminDashboard = React.lazy(() => import('src/backend/App'));
const FreeAstrologyHomeV2 = React.lazy(() => import('src/freeastrology/component/page/FreeAstrologyHomeV2'));

const withBirthProfile = (Component) => {
  const { primaryBirthProfile } = useAuthInfo();
  const { defaultProfile } = useDefaultProfile();

  if (!(primaryBirthProfile || defaultProfile)) {
    return (props) => <Redirect to="/" {...props} />;
  }

  return (props) => <Component {...props} />;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuthInfo();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: {
                from: props.location.pathname,
                search: props.location.search,
              },
            }}
          />
        )
      }
    />
  );
};

const AstroRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, profile } = useAuthInfo();

  return (
    <Route
      {...rest}
      render={(props) =>
        (isAuthenticated && profile.astrologer && <Component {...props} />) || (
          <Redirect to="/" />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, profile } = useAuthInfo();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && profile.admin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/admin-login",
              state: {
                from: props.location.pathname,
                search: props.location.search,
              },
            }}
          />
        )
      }
    />
  );
};

const RouteChangeTracker = withRouter(({ history }) => {
  const { isAuthenticated, profile } = useAuthInfo();

  const trackPageView = (locaiton) => {
    ReactGA.send({
      hitType: "pageview",
      page: locaiton.pathname,
    });
  }

  const emailVerificationRedirect = (location) => {

    if (isAuthenticated && !profile?.is_email_verified) {
      if (location.pathname !== '/verify-email') {
        window.location.href = '/verify-email';
      }
    }
  }

  trackPageView(window.location);
  emailVerificationRedirect(window.location);

  React.useEffect(() => {
    const listenForPageView = history.listen((location, action) => {
      trackPageView(location);
    });

    const listenForEmailVerificationRedirect = history.listen((location) => {
      emailVerificationRedirect(location);
    });

    return () => {
      listenForEmailVerificationRedirect();
      listenForPageView();
    }
  }, []);

  return null;
});

export default function AppRouter() {
  const { isAuthenticated, setupUserInfos, profile,updateProfile } = useAuthInfo();
  const socket = useSocket();
  const [appSettings, setAppSettings] = useState([])
  const { setOrderInfo, fetchRTPOrder, draftCartLines, validateDraftCart } = useRTPInfo();
  const { setupAstroBirthProfile, selectProfile } = useFreeAstrology();
  const {
    setupUserNotification,
    markUserNotificationRead,
    updateUserNotification,
  } = useNotification();
  const {
    setupPromoData,
    updatePromoLastCheck,
    updatePromoData,
    updatePromoDataBulk,
    promoLastCheck,
  } = usePromo();

  const { defaultProfile } = useDefaultProfile();

  React.useEffect(() => {
    if (isAuthenticated) {
      // fetch userInfos
      setupUserInfos();

      // fetch RTP order
      fetchRTPOrder();

      // fetch push notifications
      setupUserNotification();

      // setup initial birthprofile for Free Astrology
      setupAstroBirthProfile();

      // Validate Draft cart lines:
      if (draftCartLines && draftCartLines.length) {
        validateDraftCart(draftCartLines);
      }
    } else {
      setOrderInfo(null);
      selectProfile(defaultProfile);
    }
  }, [isAuthenticated]);

  // Socket Events
  React.useEffect(() => {
    const onDiscoundOfferReceive = ({ data }) => {
      updatePromoData(data);
    };

    const onDiscoundOfferReceiveBullk = ({ data }) => {
      updatePromoDataBulk(data);
    };

    const onNewNotification = ({ data }) => {
      if (data.notification?.recipient_id != profile?.id) {
        return;
      }
      updateUserNotification(data.notification);
    };

    const onNotificationRead = ({ data }) => {
      if (data.notification?.recipient_id != profile?.id) {
        return;
      }
      markUserNotificationRead(data.notification);
    };

    const onRewaredCreate = ({ data }) => {
      if (data.reward?.user_id != profile?.id) {return;}
      updateProfile({...profile,wallet_balance: {...profile.wallet_balance,dollar: data.wallet_balance}});
    }

    const onWalletTransaction = ({ data }) => {
      if (data.wallet_transaction?.user_id != profile?.id) {return;}
        updateProfile({...profile,wallet_balance: {...profile.wallet_balance,dollar: data.wallet_balance}});
    }

    // Promo events
    socket.on("discount_offer:new", onDiscoundOfferReceive);
    socket.on("discount_offer:starts", onDiscoundOfferReceiveBullk);
    // Notification event
    socket.on("notification:create", onNewNotification);
    socket.on("notification:read", onNotificationRead);
    // Reward event
    socket.on("reward:create",onRewaredCreate);
    // Wallet Transaction event
    socket.on("wallet:transaction",onWalletTransaction)

    return () => {
      socket.off("discount_offer:new", onDiscoundOfferReceive);
      socket.off("discount_offer:starts", onDiscoundOfferReceiveBullk);
      socket.off("notification:create", onNewNotification);
      socket.off("notification:read", onNotificationRead);
      socket.off("reward:create", onRewaredCreate);
      socket.off("wallet:transaction", onWalletTransaction);
    };
  }, [isAuthenticated]);

  React.useEffect(() => {
    fetchAppSettings()
    if (promoLastCheck) {
      setupPromoData(promoLastCheck);
    } else {
      const datetime = new Date().format("yyyy-MM-ddTHH:mm:ss");
      updatePromoLastCheck(datetime);
    }
  }, []);

  const fetchAppSettings = async () => {
    try {
      let res = await getAppSettings();
      res = res.data.result.data;
      res = res.map(r => r.code);
      setAppSettings(res);

    } catch (error) { }
  }


  return (
    <BrowserRouter>
      
      <RouteChangeTracker />
      
      <Suspense fallback={<div><LoadingIcon /></div>}>
        <Switch>
          <AdminRoute path="/admin" exact={false} component={AdminDashboard} />
          <Route path="/admin-login" exact component={Login} />

          <Route path="/auth" component={Auth} />
          <Route path="/verify-email" component={EmailVerificationPage} />

          {/* <Route path="/auth/login" exact component={LoginPage} /> */}
          {/* <Route path="/auth/signup" exact component={SignupPage} /> */}

          <AuthRoute path="/action-link" exact={false} component={ActionLinkPage} />
          <Route path="/" exact component={HomePage} />
          <Route path="/about" exact component={AboutUsPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/careers" exact component={CareerPage} />
          <Route path="/cookies" exact component={CookiePage} />
          <Route path="/tos" exact component={TosPage} />
          <Route path="/faq" exact component={FAQPage} />
          <Route path="/privacy-policy" exact component={PrivacyPage} />
          {/*<Route path="/realtime-prediction" exact component={RTPHome} />*/}
          <Route path="/instant-prediction" component={RTPHome} />
          {/* <Route path="/forum" component={ForumHome} /> */}
          <Route path="/forum" component={ForumHomeV2} />

          <Route
            path="/free-astrology"
            exact
            component={withBirthProfile(FreeAstrologyHomeV2)}
          />
          {/* <Route path="/v2/free-astrology" exact component={withBirthProfile(FreeAstrologyHomeV2)} /> */}

          <AuthRoute path="/support" component={CustomerSupportHome} />
          <AuthRoute path="/settings" component={SettingsHome} />
          <AuthRoute path="/wallet" component={appSettings.includes(3) ? Wallet:LoadingIcon} />

          <Route path="/promos" exact component={PromosHome} />
          <Route path="/promo/:promoId" exact component={PromosHome} />

          <Route path="/shop" exact component={appSettings.includes(2) ? ShopHome : LoadingIcon} />

          <Route path="/chat/demo" exact component={ConnectHome} />

          <Route path="/connect-astrologer" component={appSettings.includes(1) ? ConnectAstrologerHome:LoadingIcon} />

          {/* Astrologer Route */}
          <AstroRoute path="/conversations" component={AstrologerChatHome} />
          <AstroRoute path="/astrologer" component={AstrologerHome} />
          <Route
            path="/astrologer-profile/:astroId"
            exact
            component={AstrologerProfile}
          />

          <Route path="/payment-policy" exact component={PaymentPolicyPage} />
          <Route path="/notifications" exact component={NotificationMobile} />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
