import React from "react";


const QuestionItem = ({item, isDarkMode, ...rest }) => {

    return ( 
        <div className={"order-lines__item question" + (isDarkMode && ' dark' || '')}>
            {item?.name || item?.question?.name || ''}
        </div>
    )
}

export default QuestionItem
