import React from 'react'
import PerfectScrollbar from 'perfect-scrollbar';
import MutationObserverPolyfill from 'mutation-observer';
import debounce from 'src/util/debounce';

import './ScrollView.css';

if (window.MutationObserver === undefined) {
  window.MutationObserver = MutationObserverPolyfill;
}

export default function ScrollView({ innerRef = null, style = {}, children, ...props }) {
  const scrollbarRef = innerRef || React.useRef();

  React.useEffect(() => {
    let scrollbar;
    let observer;

    if (!scrollbarRef.current) {
      return;
    }

    observer = new window.MutationObserver(debounce(() => {
      if (!scrollbar) { return; }

      observer.disconnect();
      scrollbar.update();

      setTimeout(() => {
        observeScrollbar(observer);
      }, 100);
    }, 100));

    observeScrollbar(observer);

    scrollbar = new PerfectScrollbar(scrollbarRef.current, {
      wheelSpeed: 1.5,
      wheelPropagation: true,
    });

    scrollbar.update();

    return () => {
      if (observer) {
        observer.disconnect()
        observer = undefined;
      }

      if (scrollbar) {
        scrollbar.destroy();
        scrollbar = undefined;
      }
    }

  }, []);

  const observeScrollbar = (observer) => {
    if (!observer || !scrollbarRef.current) { return; }

    observer.observe(scrollbarRef.current, {
      attributes: true,
      subtree: true,
      childList: true,
      characterData: true,
    });
  }

  return (
    <div style={{ 'position': 'relative', 'overflow': 'hidden', ...style }} {...props} ref={scrollbarRef} >
      {children}
    </div>
  )
}
