import React from "react";

import useCustomerChat from "src/connect-with-astrologer/hook/useCustomerChat";
import AstrologerCard from "src/core/component/astrologer/AstrologerCard";
import CustomerChatContainer from "./CustomerChatContainer";


const CustomerChat = ({match, isDarkMode, ...rest}) => {
    const {chatAstrologer} = useCustomerChat();

    return (
        <div className={`row gx-2 justify-content-center pocket-astrologer__chat customer ${isDarkMode && 'dark' || ''}`}>
            {chatAstrologer && (
                <>
                    <div className="col-md-4 col-lg-3 d-none d-md-block">
                        <div className={`astrologer-sidebar ${isDarkMode && 'dark' || ''}`}>
                            <h5 className={`heading ${isDarkMode && 'dark' || ''}`}>Talking with</h5>
                            <div className={`astrologer-info ${isDarkMode && 'dark' || ''}`}>
                                <AstrologerCard
                                    astrologer={chatAstrologer}
                                    isDarkMode={isDarkMode}
                                    className={"astrologer"}
                                />
                                
                                {chatAstrologer?.bio && (
                                    <div className={`astrologer-bio ${isDarkMode && 'dark' || ''}`}>
                                        <h5 className={`heading ${isDarkMode && 'dark' || ''}`}>About the astrologer</h5>
                                        <div className="bio">
                                            {chatAstrologer.bio}
                                            {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore cum esse quia eos maiores cupiditate dignissimos corrupti! Ducimus quaerat facilis ab eaque commodi veniam beatae unde, nostrum tempore iste placeat. */}
                                        </div>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9">
                        <div className={`chat-playground ${isDarkMode && 'dark' || ''}`}>
                            <CustomerChatContainer
                                astrologer={chatAstrologer}
                                isDarkMode={isDarkMode}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default CustomerChat;
