import React from 'react';

export default function useDependentState(fn, states) {
  const [state, setState] = React.useState(fn.apply(null, states));

  React.useEffect(() => {
    setState(fn.apply(null, states));
  }, [...states]);

  return [state, setState];
}
