import axios from "axios";
import { createBrowserHistory } from 'history';

import api from "./api";
import { loadJSON } from "./json-store";
import { logout, updateAccessToken, setupUserInfos } from "src/auth/redux";
import { clearRTPInfo, fetchRTPOrder } from "src/instant-prediction/redux";
import { clearUserNotification, setupUserNotification } from "src/notification/redux";
import { clearAstroInfo } from "src/freeastrology/redux";

const setupInterceptors = (store) => {

  const { dispatch } = store;
  const history = createBrowserHistory({forceRefresh:true});

  // Request interceptor for API calls
  api.interceptors.request.use(
    async config => {
      const token = loadJSON('auth.accessToken');

      if (!token) { return config; }

      config.headers = {
        'Authorization': `Bearer ${token}`,
      };

      return config;
    },

    error => {
      Promise.reject(error)
    }
  );

  // Response interceptor for refreshing token.
  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const refreshToken = loadJSON('auth.refreshToken');

      if (originalConfig.url !== "auth/login" && err.response) {
        // console.log('Refreshing....', originalConfig);

        if ((err.response.status === 401 || err.response.status === 422) && !originalConfig._retry) {
          if(!refreshToken) {
            return Promise.reject(err);
          }

          originalConfig._retry = true;
          try {
            axios.defaults.baseURL = process.env.API_ENDPOINT;
            axios.defaults.headers.common['Content-Type'] = 'application/json';
            const response = await axios.post("v1/auth/refresh", {
              "refresh_token": refreshToken
            });

            // console.log('Response', response);
            const newAccessToken = response?.data?.result?.data?.access_token;
            // console.log('New Access Token', newAccessToken);
            dispatch(updateAccessToken(newAccessToken));
            dispatch(setupUserInfos());
            dispatch(fetchRTPOrder());
            dispatch(setupUserNotification());

            return api(originalConfig);

          } catch (_error) {
            if((_error?.response?.status === 401 || _error?.response?.status === 422) && _error?.config?.url === 'v1/auth/refresh') {
              // console.log('Error to Refresh Token... loggin out.......', _error?.response, _error?.config);
              dispatch(logout());
              dispatch(clearRTPInfo());
              dispatch(clearUserNotification());
              dispatch(clearAstroInfo());

              history.push({
                pathname: '/auth/login',
              })
            }

            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );

};

export default setupInterceptors;
