import React from "react"
import { Switch, useHistory, Route, useRouteMatch } from 'react-router-dom';
import api from 'src/lib/api';
import ActionLinkVimshottariPage from './ActionLinkVimshottariPage';
import ActionLinkBeneficMaleficPage from './ActionLinkBeneficMaleficPage';
import ActionLinkInstantPredictionPage from "./ActionLinkInstantPredictionPage";

export default function ActionLinkPage() {
  const history = useHistory();
  const [decoded, setDecoded] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [error, setError] = React.useState(null);
  const {path} = useRouteMatch()

  React.useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const tokenCode = searchParams.get('token');
    setToken(tokenCode);

    decodeActionLinkToken(tokenCode);
  }, [history]);

  React.useEffect(() => {

    if(!decoded){return;}

    handleAction(decoded.action, decoded.payload);
  }, [decoded]);

  const decodeActionLinkToken = async (token) => {
    try {
      const res = await api.get('v1/action-link', {
        params: {token: token},
      });

      const result = res.data.result;
      setDecoded(result.data);
    } catch (error) {
      const errorMessage = error.response?.data?.error?.message || error.message;

      setError(errorMessage);
    }
  }

  const handleAction = (action, payload) => {
    history.push({
      pathname: '/action-link/' + action,
      state: {
        action: action,
        payload: payload,
      }
    });
  }

  return (
    <>
      <div>
        {
          error && (<div className="alert alert-error text-white"> { error } </div>)
        }
      </div>

      <Switch>
        <Route path={path + "/free-astrology:vimshottari-dasha"} exact component={ActionLinkVimshottariPage} />
        <Route path={path + "/free-astrology:benefic-malefic"} exact component={ActionLinkBeneficMaleficPage} />
        <Route 
          path={path + "/instant-prediction:question"} 
          exact
          render={props => (
            <ActionLinkInstantPredictionPage 
                {...props}
                token={token}
            />
          )}
        />

      </Switch>
    </>
  )
}
