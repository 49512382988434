import React from "react"
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import { useTheme } from "src/theme/component/ThemeContext";
import { PocketToast } from "src/core/component/toast/PocketToast";

import api from 'src/lib/api'

const ApplyCoupon = ({
        name,
        id,
        setIsFreePayment,
        modelName,
        isSmall=true,
        isInvalid=false,
    }) => {
    const [isDarkMode] = useTheme();
    const [couponCode, setCouponCode] = React.useState('');
    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState('');
    const inputRef = React.useRef();

    const { fetchRTPOrder } = useRTPInfo();


    const handleChange = (e) => {
      setCouponCode(e.target.value);
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');

      if(busy || !couponCode) {
        return;
      }

      setBusy(true);

      let payload = {
        'code': couponCode,
      };

      if (modelName == 'QuestionOrder') {
        payload['question_order'] = name;
      }

      try {
        const res = await api.post(`/v1/question_order/${id}/apply_promo`, payload);

        const result = (res.data?.result);

        if (result) {
          fetchRTPOrder();
          PocketToast({
            type:'success',
            message: 'Congratulations! Coupon code has been successfully applied to your order!'
          });
          if(result?.data?.is_free && (result?.data?.order_lines || []).length > 0){
            setIsFreePayment(true)
          }
        }

        setCouponCode('');
      } catch (error) {
        let errMsg = error.response?.data?.error?.message || error.message;
        // setError(errMsg);
        PocketToast({type:'error',message: errMsg});

        if (inputRef.current) {
          inputRef.current.select();
          inputRef.current.focus();
        }
      } finally {
        setBusy(false);
      }
    }

    return (
        <form className={"apply-coupon w-100 " + (isDarkMode?'dark':'')}>
            <div className="field">
                <div className="input-group">
                    <input ref={inputRef} type="text" value={couponCode} className={"form-control" + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} placeholder="Enter coupon code" name="coupon" onChange={handleChange} />

                    <button type="submit" className="input-group-text pocket-btn px-3" onClick={handleSubmit} disabled={busy || !couponCode}>{ busy ? 'Applying...': 'Apply'}</button>

                    <p className="invalid-feedback d-block">{error}</p>
                </div>
            </div>
        </form>
    )
}

export default ApplyCoupon
