import React, { useState,useEffect } from 'react'
import api from 'src/lib/api';

const useExchangeRate = () => {
  const [exchangeRate,setExchangeRate] = useState([]);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchExchangeRate = async (controller)=>{
    try {
      const res = await api.get('v1/exchange_rate',
      {signal:controller.signal});
      if(res && res.data){
        setExchangeRate(res?.data?.result?.data);
        setError()
      }
    } catch(error){
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    const controller = new AbortController()
    fetchExchangeRate(controller);

    return () => {
      controller.abort()
    }

  },[])


  return { exchangeRate, loading, error }
}

export default useExchangeRate