import React from "react";

import MainLayout from "src/core/component/MainLayout";
import Promos from "src/promos/component/promos/Promos";
import PromoDetails from "src/promos/component/promodetails/PromoDetais";

const PromosHome = ({ match, ...rest }) => {
  return (
    <MainLayout className="promo-details-layout">
      {match.params.promoId ? (
        <PromoDetails promoId={match.params.promoId} />
      ) : (
        <Promos />
      )}
    </MainLayout>
  );
};

export default PromosHome;
