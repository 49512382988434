import React from "react";
import { Link } from "react-router-dom";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import TimeSetupForm from "./TimeSetupForm";


const BuyTimeModal = ({
    hours, 
    setHours,
    invalidHours=false,
    isDarkMode, 
    showModal,
    handleModalClose,
    handlePayAndContine,
    handleEndChat,
    title,
    conversation,
    expired=false,
    ...rest
}) => {

    handlePayAndContine = typeof handlePayAndContine === 'function' ? handlePayAndContine : function noop() { }
    handleEndChat = typeof handleEndChat === 'function' ? handleEndChat : function noop() { }

    const handleInputChange = (e) => {
        setHours(+e.target.value);
    }

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={false} centered={false} classes={'chat-setup__modal'  + (isDarkMode && ' dark' || '')} backdrop={expired && 'static' || 'backdrop'}>
            <ModalBody> 
                <div className={`chat-setup__modal-content text-center ${isDarkMode && 'dark' || ''}`}>
                    <h5 className="heading">{title}</h5>
                    
                    {/* TimeSetup */}
                    <TimeSetupForm 
                        hours={hours}
                        setHours={setHours}
                        invalidHours={invalidHours}
                        isDarkMode={isDarkMode}
                        handleInputChange={handleInputChange}
                    />

                    {/* Buttons */}
                    {expired && (
                        <Link to="#" onClick={handleEndChat} className="pocket-btn end-chat d-inline-block">
                            End Chat
                        </Link>
                    )}

                    <button onClick={handlePayAndContine} className="pocket-btn">
                        Pay and Continue
                    </button>

                </div>
            </ModalBody>
        </CommonModal>
    )
}

export default BuyTimeModal