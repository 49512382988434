import React, { forwardRef } from "react";


const DatePickerInput = forwardRef(
  ({ 
        value, 
        onChange, 
        onClick, 
        property,
        error, 
        hasLabel=false, 
        label, 
        required=false, 
        isInvalid=false, 
        isSmall=true, 
        placeholder="",
        disabled=false,
        autoComplete="off"
    }, ref) => {

    onClick = typeof onClick === 'function' ? onClick : function noop() { }

    return (
        <>
            {
                hasLabel &&
                <label className="form-label" htmlFor={property}>{label}</label>
            }
            <input type="text" className={"form-control" + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} 
                placeholder={placeholder} 
                name={property} 
                id={property} 
                value={value} 
                onChange={onChange} 
                required={required} 
                autoComplete={autoComplete} 
                disabled={disabled} 
                ref={ref} 
                onClick={onClick}
            />
            <div className="invalid-feedback">
                {error}
            </div>
        </>
    );
  }
);

export default DatePickerInput
