import React, {useState} from "react";
import { Redirect, useLocation } from "react-router";

import TabManager from "./TabManager";
import useAuthInfo from 'src/auth/hook/auth-info';


const RegisterLogin = ({isDarkMode, ...rest}) => {
    const location = useLocation()
    const { isAuthenticated } = useAuthInfo();
    const [currentTab, setCurrentTab] = useState('register');

    if (isAuthenticated) {
        return <Redirect 
            to={{
                pathname: (location.state?.from && location.state.from) || "/forum",
                search: (location.state?.search && location.state.search) || ""
            }} 
        />
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-9">
                <TabManager tab={currentTab} setTab={setCurrentTab} isDarkMode={isDarkMode} />
            </div>
        </div>
    )
}

export default RegisterLogin