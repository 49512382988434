import React, {useState, useEffect, useRef} from "react";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";


const ProfileImageUploadModal = ({
    showModal,
    handleModalClose,
    imgUploading,
    isDarkMode,
    onSave = () => {},
}) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [image, setImage] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length) {
          let reader = new FileReader();
          let file = e.target.files[0];
          reader.onloadend = () => {
            setImageUrl(reader.result);
          };
          reader.readAsDataURL(file);
          setImage(file);
        } else {
          setImageUrl(null);
          setImage(null);
        }
    };

    return (
        <CommonModal showModal={showModal} showCustomClose={true} size={"md"} centered={false} handleModalClose={handleModalClose} classes={'image-upload__modal' + (isDarkMode && ' dark' || '')} backdrop="static">
            <ModalBody>
                <div className="mb-3 image-upload__modal-input">
                    <label htmlFor="formFile" className="form-label">Upload Image</label>
                    <input type="file" className="form-control" id="formFile" onChange={ handleFileChange } accept="image/png, image/jpeg" />
                </div>

                {imageUrl && (
                    <div className="img-preview image-upload__modal-preview">
                        <img src={imageUrl} className="img-fluid" />
                    </div>
                )}

                {image && (
                    <button className="pocket-btn image-upload__modal-save" onClick={onSave.bind(null, image)}>
                        {imgUploading ? 'Uploading...' : 'Upload'}
                    </button>
                )}
            </ModalBody>
        </CommonModal>
    )
}

export default ProfileImageUploadModal
