import React, { useEffect, useState } from "react";
import useRTPInfo from "src/realtimeprediction/hook/useRTPInfo";
import api from "src/lib/api";
import useAuthInfo from "src/auth/hook/auth-info";
import { Link } from "react-router-dom";
import { useTheme } from "src/theme/component/ThemeContext";
import { PlusSvg,CouponSVg } from "src/core/component/assets/svg/CommonSVG";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import QuestionItem from "src/core/component/question/QuestionItem";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import CommonBirthProfile from "src/core/component/prediction/BirthProfile";
import { useModal } from "src/core/hook/useModal";
import "../scss/Questions.scss";
import FollowModal from "src/core/component/modal/FollowModal"


const Questions = ({
  questionSelected,
  rtpFilterIds,
  handleQuestionSelect,
  handleQuestionClickWithProfile = ()=>{},
  handleProfileSelect,
  handleRelationshipSelect,
  isQuestionSelected,
  createEditURL = "",
  match,
  profile,
  questionResponse
}) => {
  const {questionsInChartByPKProfile,removeFromDraftCart,isQuestionInCart } = useRTPInfo();
  const [showPrasnaKundaliModal, handlePrasnaKundaliModalShow, handlePrasnaKundaliModalClose] = useModal(false);
  const [showFollowModal, handleFollowModalShow, handleFollowModalClose] = useModal(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const { isAuthenticated, birthProfiles } = useAuthInfo();
  const [profileSelectModal,setProfileSelectModal] = useState(false)
  const [selectedQuestion,setSelectedQuestion] = useState({})
  const [relationshipList, setRelationshipList] = useState([]);
  const [isDarkMode] = useTheme();
  let questionList = questionResponse || [];

  useEffect(() => {
    fetchRelationshipList().then();
  }, [rtpFilterIds]);

  const fetchRelationshipList = async () => {
    try {
      const response = await api.get("/v1/relationship");
      setRelationshipList(response?.data.result.data);
    } catch (error) {
      console.log("RTP Filter Error", error);
    }
  };

  const isProfilePrashnaKundali = profile?.profile_type == "prashna_kundali_profile"
  
  if(rtpFilterIds?.relationship_id !='all'){
    questionList = questionList.filter(ql=>ql.relationship_id == rtpFilterIds.relationship_id)
  }

  if(rtpFilterIds?.category_id.length>0){
    questionList = questionList.filter(q=>rtpFilterIds?.category_id.includes(q.category_id))
  }

  if(profile?.profile_type == "prashna_kundali_profile" && profile?.question_id){
    questionList = questionList.filter(q=>q.id==profile?.question_id)
  }

  const handlePrasnaKUndaliProceed = ()=>{
    handlePrasnaKundaliModalClose();
    handleQuestionClickWithProfile(selectedQuestion?.id,profile?.id)
  }

  
  const handleQuestionClick = (e, question) => {
    if(profile?.profile_type == "prashna_kundali_profile"){
      if(profile?.question_id == null){
        const pransaKundaliQuestions = questionsInChartByPKProfile(profile.id)
        if(pransaKundaliQuestions.length>0){
          pransaKundaliQuestions.forEach(line => {
            removeFromDraftCart(line)
          });
        }
      }else{
        setSelectedQuestion(question)
        if (!isQuestionInCart(question.id, profile.id)) {
          handlePrasnaKundaliModalShow(true)
          return;
        }
      }
    }

    if (questionLoading) return;

    if (!profile) {
      setSelectedQuestion(question)
      setProfileSelectModal(true)
    }

    handleQuestionSelect(e, question);
  };

  const profileRelation = (isForModal = false) => {
    if(relationshipList.length>0){
      let rel_name = relationshipList?.filter(rl=>rl.id == selectedQuestion?.relationship_id)[0]?.name;
      if(isForModal){
        return rel_name=='Self'?'yourself':"your "+rel_name
      }
      return rel_name=='Self'?'':rel_name+"'s"
    }
  }

const handleModalProfileSelect = (e,profile)=>{
  setProfileSelectModal(false)
  // handleRelationshipSelect(e,profile.relationship)
  handleProfileSelect(e,profile)
  if(profile?.question_id){
    if (profile.question_id == selectedQuestion.id){
      handlePrasnaKundaliModalShow(true)
    }
      return;
  }
  const pransaKundaliQuestions = questionsInChartByPKProfile(profile.id)
  if(pransaKundaliQuestions.length>0){
    pransaKundaliQuestions.forEach(line => {
      if (line.question_id != selectedQuestion.id && profile?.profile_type == "prashna_kundali_profile"){
        removeFromDraftCart(line)
      }
    });
  }
    handleQuestionClickWithProfile(selectedQuestion?.id,profile?.id)
}

  return (
    <div className="rtp-questions">
    <div className="rtp-questions-heading">
      <h5 className="rtp-headingTwo mb-0">{isProfilePrashnaKundali?'Select a question':'Select questions'}</h5>
      <button onClick={handleFollowModalShow}><CouponSVg/> GET FREE COUPON CODE</button>
    </div>

      {questionResponse && questionList.length > 0 ? questionList.map((question) => (
          <QuestionItem
            key={question.id}
            question={question}
            handleQuestionSelect={handleQuestionClick}
            isSelectable={!questionLoading}
            profile={profile}
          />
        )):
        <div>
        <LoadingIcon>
          <div className="rtp-questions-loading">
          Loading Questions
          </div>
        </LoadingIcon>
        </div>
        }
        {
          profileSelectModal &&
        <CommonModal showModal={profileSelectModal} classes="instant_pred_profile_modal" backdrop="static"
                showCustomClose={true} size={"md"} handleModalClose = {()=>setProfileSelectModal(false)}>
          <ModalBody classes={"instant_pred_profile_modal_body" + ((isDarkMode && " dark") || "")}>
        
          {birthProfiles.filter(bp=>bp.relationship_id == selectedQuestion.relationship_id).length>0 ?(
            <div className={`col-12 m-0 rtp-layoutTwo ${isDarkMode?'dark':''}`} >
            <div className={`profile-container ${isDarkMode?'dark':''}`}>
            <div className="particular_profile">Select your {profileRelation()} profile to proceed</div>
            <div className="swiper_profile">
                    <Link to={{ pathname: createEditURL,
                         state: {relationship_id: selectedQuestion.relationship_id,
                                  question_id:selectedQuestion.id},}}
                      className="pocket-btn">
                        <PlusSvg /> Create Profile
                    </Link>
            </div>
            <div className="instant_pred_profile_slider">
                {birthProfiles.filter(bp=>bp.relationship_id == selectedQuestion.relationship_id).map((_profile) => (
                  <CommonBirthProfile
                    key={_profile.id}
                    profile={_profile}
                    handleProfileSelect={handleModalProfileSelect}
                    // className={isQuestionInCart(selectedQuestion.id, _profile.id)?' selectedddd ':''}
                    className={"m-1"}
                    isProfileSelected={()=>{}}
                    showCartcount={true}
                    editURL = {createEditURL}
                    canDelete={false}
                    handleProfileDelete={()=>{}}
                  />
                ))}
                </div>
            
              </div>
              </div>
            ): (
              isAuthenticated?
              <div className="">
                <div className={"rtp_card" + ((isDarkMode && " dark") || "")}>
                  <div className="rtp_card_text">
                    <h4>Please <span style={{color: "#58B368"}}>
                          create</span> a birth profile for {profileRelation(true)} </h4>
                  </div>
                  <div className="rtp_card_buttons">
                    <Link
                      to={{
                        pathname: createEditURL,
                        state: {
                          relationship_id: selectedQuestion.relationship_id,
                          question_id:selectedQuestion.id
                        },
                      }}
                      className="pocket-btn"
                    >
                      <PlusSvg />
                      Add Birth Profile
                    </Link>
                  </div>
                </div>
                </div>:
                <div className="rtp-layoutTwo m-0">
                <div style={isDarkMode?{backgroundColor:'#212121'}:{}} className="rtp-auth__card py-3 px-1">
                    <div className="rtp-auth__text">
                      <h4>Please Login / Signup to view your prediction!</h4>
                    </div>
                    <div className="rtp-auth__buttons">
                      <Link
                        to={{
                          pathname: `${match.path}/auth`,state: {tab: "register",},
                        }}
                        className="pocket-btn">Sign Up
                      </Link>
                      <Link
                        to={{
                          pathname: `${match.path}/auth`,state: {tab: "login",},
                        }}
                        className="pocket-btn btn-two">Login
                      </Link>
                    </div>
                  </div>
                </div>
        )}
  
        </ModalBody>
      </CommonModal>
        }

    {showPrasnaKundaliModal && (
      <CommonModal showModal={handlePrasnaKundaliModalShow} classes="rtp__birth__profile__prasnakundali" 
        showCustomClose={false} size={"lg"} >
          <ModalBody classes={"rtp__birth__profile__prasnakundali"}>
            <div className="">
                <div className="rtp__birth__profile__prasnakundali__particular__profile">Prashna Kundali Selected</div>
                  <div className={"rtp_card" }>
                    <div className="rtp_card_text">
                     <span> The selected question was already ordered with this Prashna Kundali profile. Please check your email for the purchased answer or, proceed further to order it again.</span>
                    </div>
                    <div className="rtp_card_buttons">
                      <div className="cancel" onClick={()=>handlePrasnaKundaliModalClose()}>Cancel</div>
                      <div className="proceed" onClick={(e)=>handlePrasnaKUndaliProceed(e)}>Proceed</div>
                    </div>
                  </div>
              </div>
            
            </ModalBody>
        </CommonModal>
      )}

      <FollowModal showFollowModal={showFollowModal} handleFollowModalShow={handleFollowModalShow} handleFollowModalClose={handleFollowModalClose}/>
    </div>
  );
};

export default Questions;
