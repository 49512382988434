import React, {useState} from "react"
import Modal from 'react-bootstrap/Modal';

import { CloseButtonSvg } from "src/core/component/assets/svg/CommonSVG";


const CommonModal = ({
    showModal, 
    handleModalClose, 
    children, 
    showCustomClose=true, 
    size="", 
    centered=true,
    backdrop="backdrop",
    classes="", 
    ...rest}) => {
  
  return (
        <Modal show={showModal} onHide={handleModalClose} className={classes} size={size} backdrop={backdrop} centered={centered}>
            {
                showCustomClose &&
                <div className="modal-custom-close" onClick={handleModalClose}>
                    <CloseButtonSvg />
                </div>
            }

            {children}
        </Modal>
    )
}

export default CommonModal
