import React, { Children } from 'react';
import PropTypes from 'prop-types';

import * as north from './north-styles';
import * as south from './south-styles';
import * as east from './east-styles';

const WIDTH = 400;
const HEIGHT = 280;

const drawKundaliLines = (ctx, lines, scale = 1, color = 'black', lineWidth = 1) => {
  lines.map(kLine => {
    ctx.moveTo(...kLine.move.map(i => i * scale));

    kLine.curve
      ? ctx.bezierCurveTo(...kLine.curve.map(i => i * scale))
      : ctx.lineTo(...kLine.line.map(i => i * scale));
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  });
};

export const Ghar = ({ variant, sthaana, sthaanaStyle, grahas, grahaStyle, code, codeStyle }) => {
  const variantStyle = switchVariant(variant);

  return (
    <>
      <div style={grahaStyle} className={`graha-info ${code}`}>
        <div style={variantStyle.textStyle}>
          {grahas.map((d, i) => (
            <span key={i} style={variantStyle.letterStyle} className="letter"> {d} </span>
          ))}
        </div>
      </div>
      <div style={sthaanaStyle} className={`sthaan-zodiac ${code}`}>
        <div style={variantStyle.zodiacStyle} className={`zodiac-icon ${code}`}>
          {sthaana}
        </div>
      </div>
      <div style={codeStyle} className={`planet-code ${code}`}>
        <div style={variantStyle.zodiacStyle}>
          <span style={variantStyle.letterStyle} className="letter">{code}</span>
        </div>
      </div>
    </>
  );
};

Ghar.propTypes = {
  variant: PropTypes.string.isRequired,
  // sthaana: PropTypes.string.isRequired,
  sthaana: PropTypes.node.isRequired,
  // grahas: PropTypes.arrayOf(PropTypes.string).isRequired,
  grahas: PropTypes.node.isRequired,
  code: PropTypes.node,
  grahaStyle: PropTypes.object.isRequired,
  sthaanaStyle: PropTypes.object.isRequired,
  codeStyle: PropTypes.object
}

const switchVariant = (variant) => {
  return { 'north': north, 'south': south, 'east': east }[variant];
}

const Kundali = React.memo(({
  className = 'Kundali',
  data,
  variant = 'north',
  xScale = WIDTH,
  sthaanaStyle = {},
  grahaStyle = {},
  color = 'black',
  lineWidth = 1,
  children,
}) => {
  const canvasRef = React.useRef();
  const scale = xScale / WIDTH || 1;
  const variantStyle = switchVariant(variant);

  React.useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.beginPath();
    ctx.moveTo(0, 0);

    drawKundaliLines(
      ctx, variantStyle.kundaliLines(WIDTH.toFixed(0), HEIGHT.toFixed(0)), scale, color, lineWidth
    );
  }, [variant, xScale, sthaanaStyle, grahaStyle, color, lineWidth]);

  const styles = variantStyle.gharaStyles(scale);

  return (
    <div className={`${className} ${variant}`} style={{
      ...variantStyle.kundaliStyle(WIDTH + 1, HEIGHT + 1, scale),
      margin: 0
    }}>
      <canvas ref={canvasRef} height={(HEIGHT * scale + 0.8).toFixed(0)} width={(WIDTH * scale + 0.5).toFixed(0)}></canvas>

      {(variant == 'south' || variant == 'east' || null) && (
        <div style={{
          width: 200 * scale,
          height: 140 * scale,
          top: 70 * scale,
          left: 100 * scale,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div className="d-flex" style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            {children}
          </div>

          {/* <span className="small text-muted" style={{ width: '100%', textAlign: 'center' }}> <strong>PocketPandit</strong></span> */}
        </div>
      )}

      {
        data.map(([label, planets, code], index) => (
          <Ghar
            key={index}
            variant={variant}
            sthaana={label}
            sthaanaStyle={Object.assign({}, styles[index].sthaana, sthaanaStyle)}
            code={code}
            codeStyle={Object.assign({}, styles[index].code)}
            grahas={planets}
            grahaStyle={Object.assign({}, styles[index].graha, grahaStyle)}
          />
        ))
      }
    </div>
  )
});

Kundali.propTypes = {
  className: PropTypes.string,
  xScale: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  defaultVariant: PropTypes.string
};

export default Kundali;
