import React from "react";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import AlignEnd from "src/core/component/AlignEnd";
import SearchLocation from "src/core/component/form/SearchLocation";


const PrashnaKundaliModal = ({ 
    isDarkMode, 
    showModal, 
    handleModalClose,
    heading,
    description="",
    onLocationChange,
    formData,
    setupPrashnaKundali,
    ...rest
}) => {
   
    return (
        <CommonModal showModal={showModal} showCustomClose={true} handleModalClose={handleModalClose} classes={'profile-setup__modal' + (isDarkMode && ' dark' || '')} backdrop="static">
            <ModalBody>
                <h4 className="heading">
                    {heading}
                </h4>
                <div className="description">
                    {description && description || 'We will use the practice of ‘Prasna Chart’ to provide a prediction based on your profile. Please enter your current location below and we will use that along with the date and time you asked us for prediction.'}
                </div>
                <form className={"pocket-form" + (isDarkMode && ' dark' || '')}>
                    <SearchLocation 
                        property={'address'}
                        hasLabel={true}
                        isSmall={false}
                        label={'Please enter your current location'}
                        placeholder={'Enter Location'}
                        onChange={onLocationChange}
                        value={formData['address'] || ""}
                    />

                    <AlignEnd>
                        <button className="location-submit" onClick={setupPrashnaKundali}>
                            Submit
                        </button>
                    </AlignEnd>
                    
                </form>
            </ModalBody>
        </CommonModal>
    )
}

export default PrashnaKundaliModal