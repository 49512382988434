import React from "react";

const Badge = ({value}) => {
  
  return (
    <>  
      {value && (
          <span className="badge">
            {value}
        </span>
      ) || (<></>)}
    </>
  )
}

export default Badge
