import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useModal } from "src/core/hook/useModal";
import api from "src/lib/api";
import FeaturesGrid from "src/home/component/features/FeaturesGrid";
import Promos from "src/home/component/promos/Promos";
import MainLayout from "src/core/component/MainLayout";
import FAQAccordion from "src/faq/component/faq/FAQAccordion";
import H4Heading from "src/core/component/grid/H4Heading";
import FollowModal from "src/core/component/modal/FollowModal"

const HomePage = () => {
  const [faqList, setFaqList] = useState([]);
  const [showFollowModal, handleFollowModalShow, handleFollowModalClose] = useModal(true);

  useEffect(() => {
    fetchFaqList();
  }, []);

  const fetchFaqList = async () => {
    try {
      const response = await api.get("/v1/faq?is_featured=true");
      // console.log('Response Faq', response);
      const data = response?.data?.result?.data.slice(0, 5);
      setFaqList(data);
    } catch (error) {
      console.log("error", error?.response || error?.message);
    } finally {
    }
  };

  return (
    <MainLayout className="homepage">
      <Promos />

      <FeaturesGrid />

      <section className="faq-list">
        <Container>
          <H4Heading heading="FAQs" classes="rtp-heading" />
          <FAQAccordion faqData={faqList} />
          <div className="text-end">
            <Link to="/faq">view all FAQs</Link>
          </div>
        </Container>
      </section>
      
      <FollowModal showFollowModal={showFollowModal} handleFollowModalShow={handleFollowModalShow} handleFollowModalClose={handleFollowModalClose}/>
    </MainLayout>
  );
};

export default HomePage;
