import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import useAuthInfo from "src/auth/hook/auth-info";
import FilterCategory from "src/forumV2/forumFilter/FilterCategory";
import ForumPromo from "./forumPromo/ForumPromo"
import ForumPost from "./forumPost/ForumPost"
import { useSocket } from "src/socket/component/SocketContext";

const ForumList = ({ 
  categories, 
  categoryLoading, 
  isDarkMode,
  setPosts,
  posts,
  isAscending,
  setIsAscending,
  currentFilter,
  setCurrentFilter,
  filter,
  setFilter,
  forumTabs,
  forumTab,
  setForumTab,
  postsLoading,
  metaData,
  limit,
  selectedCategory,
  setSelectedCategory
}) => {
  const { profile,isAuthenticated } = useAuthInfo();

  const history = useHistory();
  const socket = useSocket();
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    setFilter(prev=>({...prev,page:1,user_id:profile?.id || null}))
  },[isAuthenticated])

  useEffect(() => {
    setFilter(prev=>({...prev,page:1,category_id:selectedCategory?.id}))
  }, [selectedCategory,isAscending,currentFilter]);

  useEffect(() => {
    const onForumPostMetrics = ({ data }) => {
      setPosts(prev=>prev.map(post=>post.id===data?.post?.id?{...post, metrics:data?.post?.metrics }:post))

      // TODO is_like change garnu parxa is self le like gareko ho vane.
    }

    socket.on("forum_post:metrics",onForumPostMetrics);

    return () => {
      socket.off("forum_post:metrics", onForumPostMetrics);
    };
    
  },[])

  useEffect(()=>{
    const searchedQuery = queryString.parse(history.location.search);

    if(searchedQuery?.['category']){
      setSelectedCategory(categories?.find(c=>c.id == searchedQuery.category))
    }
  },[categories])

  useEffect(() => {
    const searchedQuery = queryString.parse(history.location.search);
    if (searchedQuery.query) {
      setSearchQuery({ query: searchedQuery.query });
      setFilter({
        ...filter,
        query: searchedQuery.query,
        page: 1,
      });
      setPosts([]);
    }
  }, [history.location]);

  useEffect(() => {
    const state = history?.location?.state;
    if(state && state?.post_id){
      document.getElementById(state?.post_id)?.scrollIntoView()
      history.push({state:null})
    }
  }, [history.location.state]);

  useEffect(() => {
    const state = history.location.state;
    if (state?.category) {
      setSelectedCategory(state.category);
    }
  }, [history.location.state]);

  useEffect(() => {
    setFilter({ ...filter, limit: limit });
  }, [limit]);

  const handleLoadMore = () => {
    if (metaData && metaData?.next && metaData.next > 1) {
      setFilter({ ...filter, page: metaData.next });
    }
  };

  const handleSelectCategory =(item)=>{
    if (item.id == selectedCategory?.id){
      setSelectedCategory(null);
      let searchedQuery = queryString.parse(history.location.search);
      searchedQuery = delete searchedQuery.category;
      searchedQuery =  queryString.stringify(searchedQuery, {skipEmptyString: true, skipNull: true})
      history.push({search:searchedQuery})
      return
    }
    setSelectedCategory(item);
    let searchedQuery = queryString.parse(history.location.search);
    searchedQuery = {...searchedQuery,'category':item.id}
    searchedQuery =  queryString.stringify(searchedQuery, {skipEmptyString: true, skipNull: true})
    history.push({search:searchedQuery})
  }

  // console.log('Filter', filter)
  // console.log('Post to Render', postsToRender);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-3">
        <FilterCategory
            categories={categories}
            categoryLoading={categoryLoading}
            activeCategory={selectedCategory}
            setActiveCategory={handleSelectCategory}
            isDarkMode={isDarkMode}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
          />
        </div>
        <div className="col-12 col-lg-6">
            <ForumPost 
              categories={categories}
              setPosts={setPosts}
              posts={posts} 
              postsLoading={postsLoading}
              handleLoadMore = {handleLoadMore}
              metaData = {metaData}
              currentFilter = {currentFilter}
              isAscending = {isAscending}
              activeCategory={selectedCategory}
              forumTabs = {forumTabs}
              forumTab = {forumTab}
              setForumTab = {setForumTab}
              />
        </div>
        <div className="col-12 col-lg-3">
            <ForumPromo/>
        </div>
      </div>
    </>
  );
};

export default ForumList;
