import React, {useState} from "react"
import { Link } from 'react-router-dom';
import CookieSimple from "./CookieSimple";
import CookieHeaderOverlay from "./CookieHeaderOverlay";
import CookieSticky from "./CookieSticky";


const CookieDialog = ({ accepted, handleAccept, variant, isDarkMode, ...rest }) => {

  return (
       <>
          {
            variant === 'footer' &&
            <CookieSimple accepted={accepted} handleAccept={handleAccept} isDarkMode={isDarkMode} classes={'footer' + (isDarkMode && ' dark' || '')} />
          }

          {
            variant === 'header' &&
            <CookieSimple accepted={accepted} handleAccept={handleAccept} isDarkMode={isDarkMode} classes={'header' + (isDarkMode && ' dark' || '')} />
          }

          {
            variant === 'overlay' &&
            <CookieHeaderOverlay handleAccept={handleAccept} isDarkMode={isDarkMode} classes={'overlay' + (isDarkMode && ' dark' || '')} />
          }

         {
           variant === 'sticky' &&
           <CookieSticky handleAccept={handleAccept} isDarkMod={isDarkMode} classes={'cookie-sticky' + (isDarkMode && ' dark' || '')} />
         }

       </>
    )
}

export default CookieDialog

