import { useDispatch, useSelector } from "react-redux";
import {
  fetchRTPOrder as rtpOrderFetchAction,
  selectProfile as profileSelectionAction,
  setCategoryInfo as setCategoryInfoAction,
  setOrderInfo as setOrderInfoAction,
  setEnterDateTime as setEnterDateTimeAction,
  clearRTPInfo as RTPInfoClearAction,
  paymentDisclaimerAccept as paymentDisclaimerAcceptAction,
  paymentDisclaimerShow as paymentDisclaimerShowAction,
  updateDraftCartLines as draftCartLinesUpdateAction,
  addToDraftCart as addToDraftCartAction,
  removeFromDraftCart as removeFromDraftCartAction,
  validateDraftCartLines as draftCartLinesValidateAction
} from "src/instant-prediction/redux";
import api from 'src/lib/api';

export default function useRTPInfo() {
  const dispatch = useDispatch();
  const rtpInfo = useSelector((state) => state.rtp);
  const authInfo = useSelector((state) => state.auth);

  const _rtpSelectedBirthProfile = () => {
    if (authInfo.accessToken && authInfo.profile && rtpInfo.birthProfile) {
      return rtpInfo.birthProfile;
    }
    return null;
  };

  const _rtpSelectedCategories = () => {
    if (
      authInfo.accessToken &&
      authInfo.profile &&
      rtpInfo.birthProfile &&
      rtpInfo.categoryInfo
    ) {
      return rtpInfo.categoryInfo.categories;
    }
    return [];
  };

  const _rtpOrder = () => {
    if (authInfo.accessToken && authInfo.profile && rtpInfo.orderInfo) {
      return rtpInfo.orderInfo;
    }
    return null;
  };

  const _rtpDraftCartLines = () => {
    return rtpInfo?.draftCartLines || [];
  }

  const _rtpDatetime = () => {
    return rtpInfo.datetime;
  };

  const fetchRTPOrder = () => {
    dispatch(rtpOrderFetchAction())
  }

  const selectProfile = (profile) => {
    dispatch(profileSelectionAction(profile));
  };

  const setCategoryInfo = (categoryInfo) => {
    dispatch(setCategoryInfoAction(categoryInfo));
  };

  const setOrderInfo = (orderInfo) => {
    dispatch(setOrderInfoAction(orderInfo));
  };

  const setEnterDateTime = () => {
    dispatch(setEnterDateTimeAction());
  };

  const clearRTPInfo = () => {
    dispatch(RTPInfoClearAction());
  };

  const paymentDisclaimerAccept = () => {
    dispatch(paymentDisclaimerAcceptAction());
  };

  const paymentDisclaimerShow = () => {
    dispatch(paymentDisclaimerShowAction());
  };

  const updateDraftCartLines = (lines) => {
    dispatch(draftCartLinesUpdateAction(lines))
  }

  const addToDraftCart = (line) => {
    const _draftLines = _rtpDraftCartLines();
    if(!!_draftLines.find((item) => item.question_id === line.question_id && item.profile_id === line.profile_id)) {
      console.log('Already Exists')
    } else {
      dispatch(addToDraftCartAction(line))
    }
  }

  const syncDrafCart = ()=>{
    const _order = _rtpOrder();
    const cartLines = (_order?.order_lines || []).map(l=>({question_id: l.question_id, profile_id: l.profile_id}))
    updateDraftCartLines(cartLines)
  }

  const removeFromDraftCart = (line) => {
    dispatch(removeFromDraftCartAction(line))
  }

  const validateDraftCartLines = (lines) => {
    dispatch(draftCartLinesValidateAction(lines))
  }

  const profileCartCount = (profileId) => {
    const _draftLines = _rtpDraftCartLines();
    return (_draftLines || []).filter(l=>l.profile_id === profileId).length;
  }

  const isQuestionInCart = (questionId, profileId) => {
    const _draftLines = _rtpDraftCartLines();
    return !!_draftLines.find(
      (item) => item.question_id === questionId && item.profile_id === profileId
    );
  };

  const checkInCart = (questionId, profileId = null) => {
    const _order = _rtpOrder();

    let found;

    if (!profileId) {
      found = (_order?.order_lines || []).find(
        (l) => l.question_id == questionId
      );
    } else {
      found = (_order?.order_lines || []).find(
        (l) => l.question_id == questionId && l.profile_id == profileId
      );
    }

    return !!found;
  };

  const validateDraftCart = async (lines) => {
    const question_ids = lines.map(l=>l.question_id)
    const _draftLines = _rtpDraftCartLines();

    try {
        const publishedQuestions = await api.post("/v1/questions/validate-published", {
          question_ids: question_ids,
        });
        const questions = publishedQuestions?.data?.result?.data || [];
        const published_question_ids = questions.map(q=>q.id);
        const validatedLines = _draftLines.filter(l=>published_question_ids.includes(l.question_id));
        dispatch(draftCartLinesUpdateAction(validatedLines));
        return validatedLines;
    } catch (error) {
      // console.log('Validate error')
    }
  }

  return {
    birthProfile: rtpInfo.birthProfile,
    paymentDisclaimer: JSON.parse(rtpInfo.paymentDisclaimer),

    get rtpBirthProfile() {
      return _rtpSelectedBirthProfile();
    },
    get rtpCategories() {
      return _rtpSelectedCategories();
    },
    get rtpOrder() {
      return _rtpOrder();
    },
    get rtpOrderLineCount() {
      const o = _rtpOrder();
      return (o?.order_lines || []).length;
    },

    get draftCartLines() {
      return _rtpDraftCartLines();
    },

    get rtpDatetime() {
      return _rtpDatetime();
    },

    fetchRTPOrder,
    selectProfile,
    setCategoryInfo,
    setOrderInfo,
    setEnterDateTime,
    clearRTPInfo,
    checkInCart,
    paymentDisclaimerAccept,
    paymentDisclaimerShow,
    updateDraftCartLines,
    addToDraftCart,
    removeFromDraftCart,
    validateDraftCartLines,
    validateDraftCart,
    profileCartCount,
    isQuestionInCart,
    syncDrafCart
  };
}
