import React, {useState} from "react"
import { Link } from 'react-router-dom';

import AdjustSettingsOverlay from "./AdjustSettingsOverlay";
import Dialog from "src/core/component/Dialog";
import DialogContent from "src/core/component/DialogContent";
import Button from "src/core/component/Button";


const CookieHeaderOverlay = ({ accepted, handleAccept, isDarkMode, classes, ...rest }) => {

    const [showOverlay, setShowOverlay] = useState(false);

    const hadleShowOverlay = () => {
        setShowOverlay(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAccept();
        setShowOverlay(false);
    }

    const handleChange = (e) => {
        console.log('Changed...');
    }

  return (
        <>
            <Dialog accepted={accepted} handleAccept={handleAccept} classes={classes}>
                <DialogContent classes={'overlay' + (isDarkMode && ' dark' || '')}>
                    <h4 className="heading">
                        Cookies on PocketPandit
                    </h4>

                    <div className="info-text">
                        We use cookies and similar technologies to enhance your experience with PocketPandit. Some are necessary to help PocketPandit work as expected. Therefore, they can't be turned off; however, there are some that are optional which we use to access your page visits, your IP addresses, your devices, and your online interactions with us. We use optional cookies and similar technologies to continually improve the services that we provide. This may include tailoring our services to provide you with personalized content or personalized ads. 
                        <br /> <br />
                        Are you willing to accept cookies and similar technologies? To manage your choices now and learn more about how we use cookies and similar technologies, click on Adjust Settings below.
                    </div>

                    <div className="d-flex align-items-center justify-content-start cookie-dialog__buttons">
                        <Link to="#" className="adjust-settings info-link" onClick={hadleShowOverlay}>
                            Adjust Settings
                        </Link>

                        <Button name={'Accept and Continue'} classes={'cookie-accept-btn'} onClick={handleAccept} />
                    </div>

                </DialogContent>
            </Dialog>

            {
                showOverlay ?
                <AdjustSettingsOverlay handleSubmit={handleSubmit} onChange={handleChange} isDarkMode={isDarkMode} setShowOverlay={setShowOverlay} />
                :
                <></>
            }
        </>
    )
}

export default CookieHeaderOverlay
