import React, {useState, useCallback} from "react";
import { Link } from "react-router-dom";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import FormCheckboxSwitch from "src/core/component/form/FormChekboxSwitch";
import PocketRatings from "src/core/component/rating/PocketRatings";


const FilterModal = ({
    isDarkMode, 
    showModal,
    handleModalClose,
    filter,
    setFilter,
    order,
    setOrder,
    ...rest
}) => {

    const handleApplyFilter = () => {
        handleModalClose();
    }

    const [isOnline, setIsOnline] = useState(filter?.status && filter.status === 'online' || false);

    const handleSetRatings = (val) => {
        setFilter({
            ...filter,
            'rating': val
        })
    }

    const handleIsOnlineChange = (e) => {
        let value = e.target.checked;
        setFilter({
            ...filter,
            'status': value === true ? 'online' : ''
        })
    }

    const handleOrderClick = (name) => {
        setOrder(name)
    }

    // console.log('Filter', filter)

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"sm"} showCustomClose={false} centered={false} classes={'filter__modal astrologer'  + (isDarkMode && ' dark' || '')}>
            <ModalBody>
                <h4 className={"heading" + (isDarkMode && ' dark' || '')} >
                    Filter
                </h4>
                
                <div className={"row filter__modal-content g-2"  + (isDarkMode && ' dark' || '')}>
                    <div className={`rating ${isDarkMode && 'dark' || ''}`}>
                        <PocketRatings
                            ratings={filter.rating}
                            setRatings={handleSetRatings}
                            title={'Rating'}
                            isDarkMode={isDarkMode}
                            readOnly={false}
                        />
                    </div>
                    
                    <hr />
                    
                    <div className={`status ${isDarkMode && 'dark' || ''}`}>
                        <h5 className="heading">
                            Status
                        </h5>
                        <div className={"filter-switch d-flex align-items-center justify-content-start" + (isDarkMode && ' dark' || '')}>
                            <FormCheckboxSwitch hasLabel={true} label={'Online'} property={'status'} value={isOnline} onChange={handleIsOnlineChange} />
                        </div>
                    </div>

                    <div className={`ordering ${isDarkMode && 'dark' || ''}`}>
                        <h5>Number of chats</h5>
                        <div className={`d-flex justify-content-center align-items-center w-100 ${isDarkMode && ' dark' || ''}`}>
                            <div className={`text-center item high w-50 ${isDarkMode && 'dark' || ''} ${order === 'high_to_low' && 'active' || ''}`} onClick={handleOrderClick.bind(null, 'high_to_low')}>
                                High to low
                            </div>
                            <div className={`text-center item low w-50 ${isDarkMode && 'dark' || ''} ${order === 'low_to_high' && 'active' || ''}`} onClick={handleOrderClick.bind(null, 'low_to_high')}>
                                Low to high
                            </div>
                        </div>
                    </div>

                </div>
                
                {/* <div className="filter__modal-footer">
                    <div className="d-flex justify-content-end align-items-center">
                        <Link to="#" onClick={handleApplyFilter} className="pocket-btn">
                            Apply Filter
                        </Link>
                    </div>
                </div> */}
                
            </ModalBody>
        </CommonModal>
    )
}

export default FilterModal