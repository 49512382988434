import React from 'react'

const WalletCheckoutSummary = ({order,intentData,isDarkMode,handleDelete}) => {
  return (
    <div className="col-mdlg-6 order-1 order-mdlg-2 wallet-checkout">
    <div className={"order-summary" + ((isDarkMode && " dark") || "")}>
      <h5 className="title d-none d-sm-flex">
        Order Summary
        {(order?.name && (
          <span className="order-summary-id">{order?.name.replace('WTO','PP')}</span>
        )) ||
          ""}
      </h5>
    </div>
    <div>
      <div className=' d-flex justify-content-between wallet-checkout-currency'>
        <span className='label'>Total {intentData?.currentCurrency}</span>
        <span className='value'>{intentData?.intentAmount || order?.amount}</span>
      </div> 
      <div className=' d-flex justify-content-between wallet-checkout-tax'>
        <span className='label'>Tax And Fees</span>
        <span className='value'> 0</span>
      </div>
      <div className=' d-flex justify-content-between wallet-checkout-total'>
        <span className='label'>Total USD:</span>
        <span className='value'>{intentData?.intentAmountInUsd || order?.amount}</span>
      </div>
    </div>
    </div>
  )
}

export default WalletCheckoutSummary