import React from "react";
import PricingSummary from "./PricingSummary";

import QuestionItem from "./QuestionItem";

const QuestionOrderSummary = ({ order, isDarkMode, ...rest }) => {
  const handleQuestionRemove = (item) => {
    console.log("Removing...", item?.id);
  };

  return (
    <div
      className={"order-summary__question" + ((isDarkMode && " dark") || "")}
    >
      {/* <p className="heading">Real-Time Predictions</p> */}
      <div className="selected-questions mb-3 mb-sm-2">
        {/* <p className="heading">Selected Questions:</p> */}
        <div className={"questions-list" + ((isDarkMode && " dark") || "")}>
          {order?.order_lines &&
            order.order_lines.map((line, index) => {
              return (
                <QuestionItem
                  key={line.id}
                  line={line}
                  handleRemoval={handleQuestionRemove}
                  isDarkMode={isDarkMode}
                />
              );
            })}
        </div>
        <p className="separator mt-2"></p>

        <PricingSummary
          title={"Question"}
          order={order}
          isDarkMode={isDarkMode}
        />

        {/* <div
          className={
            "d-flex justify-content-end re-order-btn" +
            ((isDarkMode && " dark") || "")
          }
        >
          <button className="pocket-btn" disabled>
            Reorder
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default QuestionOrderSummary;
