import React, { useRef } from "react";

import Slider from "react-slick";

const Carousel = ({
  items = [],
  infinite = false,
  autoplay = true,
  dots = false,
  arrows = false,
  speed = 500,
  autoplaySpeed = 7000,
  slidesToShow = 1,
  slidesToScroll = 1,
  className = "",
  responsive = [],
  sliderRef = useRef(null),
  ...rest
}) => {
  const settings = {
    infinite: infinite,
    autoplay: autoplay,
    dots: dots,
    arrows: arrows,
    speed: speed,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    className: className,
    responsive: responsive,
    ...rest,
  };

  return (
    <Slider {...settings} ref={(slider) => (sliderRef.current = slider)}>
      {items}
    </Slider>
  );
};

export default Carousel;
