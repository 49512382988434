import React, { useState, useEffect, useRef } from "react";
import {useHistory} from "react-router-dom";

import AnswerItem from "./AnswerItem";
import Emailing from "./Emailing";
import useAuthInfo from "src/auth/hook/auth-info";
import { chartData } from "src/core/component/kundaliV2/prepare-chart-data";
import KundaliSlider from "src/core/component/kundaliV2/KundaliSlider";
import * as jyotishApi from "src/lib/http/jyotish";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import LogoDark from "src/core/component/LogoDark";
import LogoLight from "src/core/component/LogoLight";

import api from "src/lib/api";
import handleAnswerActionLink from "src/lib/http/handle-answer-action-link";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import QuestionNotifyModal from "src/core/component/modal/QuestionNotifyModal";

const Answer = ({
  line,
  order,
  parentWidth,
  metaData,
  newKundaliDatas,
  emailHandler,
  emailProcessing,
  setEmailProcessing,
  isDarkMode,
  ...rest
}) => {
  const [beneficMaleficData, setBeneficMaleficData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchBeneficMalefic = async () => {
    try {
      const res = await jyotishApi.queryFunctionalBenificMalefic();
      setBeneficMaleficData(res.data.result.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBeneficMalefic();
  }, []);

  const { preference } = useAuthInfo();
  const [config, setConfig] = useState({
    modern_planet: preference?.modern_planet || "exclude",
    rahu_ketu_position: preference?.rahu_ketu_position || "mean_position",
    kundali_variant: "north",
    // 'kundali_variant': preference?.kundali_variant || 'north',
    name: "rasi", //chalit
  });

  const [currentChartType, setCurrentChartType] = useState(
    preference?.kundali_variant
  );

  const answerRef = useRef();
  const history = useHistory();
  const {rtpOrder, draftCartLines, updateDraftCartLines, setOrderInfo, paymentDisclaimerShow} = useRTPInfo();
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState()
  const [profileToUse, setProfileToUse] = useState()
  const [kundaliTitle, setKundaliTitle] = useState(() => "Next South-Indian Chart");

  let profileMetaData = line && newKundaliDatas && newKundaliDatas?.kundali_datas || [];
  profileMetaData.sort((a,b) => {
    return a?.kundali_name?.substring(1)- b?.kundali_name?.substring(1)
  })
  const [currentKundali, setCurrentKundali] = useState(
    profileMetaData[0]?.kundali_name || 'D0'
  );

  let newActiveHouses = line && profileMetaData.map((p) => p.active_houses);
  let newFirstHouses = line && profileMetaData.map((p) => p.kundali_info || []);

  const kundaliTypes =
    profileMetaData &&
    profileMetaData.length > 0 &&
    profileMetaData.map(
      (kundali) =>
        chartData(
          kundali?.kundali_info,
          Object.assign({}, config, {
            ascendant: kundali?.ascendant,
            degrees: true,
            nakshatra: true,
            benefic_malefic: true,
            benefic_malefic_data: beneficMaleficData || [],
          })
        ) || []
    ) || [];

  let kundaliData = kundaliTypes;

  const sortData = (data) => {
    let d = data.slice();
    d.sort((a, b) => a[2] - b[2]) || [];
    return d;
  };

  let sliderData;

  if (preference.kundali_variant == "north") {
    sliderData = kundaliData.map((d, i) => [
      { data: d || [], variant: "north", title: "North-Indian Chart" },
      {
        data: sortData(d) || [],
        variant: "south",
        title: "South-Indian Chart",
      },
      { data: sortData(d) || [], variant: "east", title: "East-Indian Chart" },
    ]);
  } else if (preference.kundali_variant == "south") {
    sliderData = kundaliData.map((d, i) => [
      {
        data: sortData(d) || [],
        variant: "south",
        title: "South-Indian Chart",
      },
      { data: sortData(d) || [], variant: "east", title: "East-Indian Chart" },
      { data: d || [], variant: "north", title: "North-Indian Chart" },
    ]);
  } else if (preference.kundali_variant == "east") {
    sliderData = kundaliData.map((d, i) => [
      {
        data: (kundaliData && sortData(d)) || [],
        variant: "east",
        title: "East-Indian Chart",
      },
      {
        data: (kundaliData && d) || [],
        variant: "north",
        title: "North-Indian Chart",
      },
      {
        data: (kundaliData && sortData(d)) || [],
        variant: "south",
        title: "South-Indian Chart",
      },
    ]);
  }

  const handleKundaliChange = (k) => {
    setCurrentKundali(k);
  };

  const sliderRef = sliderData.map((d, i) => useRef());

  const handleOnSlideChange = (index) => {
    for (let slide of sliderRef) {
      slide.current.slickGoTo(index);
    }
    if (preference?.kundali_variant == "north") {
      if (index == 0) {
        setCurrentChartType("north");
      } else if (index == 1) {
        setCurrentChartType("south");
      } else if (index == 2) {
        setCurrentChartType("east");
      }
    } else if (preference?.kundali_variant == "south") {
      if (index == 0) {
        setCurrentChartType("south");
      } else if (index == 1) {
        setCurrentChartType("east");
      } else if (index == 2) {
        setCurrentChartType("north");
      }
    } else if (preference?.kundali_variant == "east") {
      if (index == 0) {
        setCurrentChartType("east");
      } else if (index == 1) {
        setCurrentChartType("north");
      } else if (index == 2) {
        setCurrentChartType("south");
      }
    }

    if (index === 0) {
      setKundaliTitle("Next South-Indian Chart");
    } else if (index === 1) {
      setKundaliTitle("Next East-Indian Chart");
    } else {
      setKundaliTitle("");
    }
  };

    const createCart = async (question, profileId) => {
        let cartItems = [...draftCartLines, {question_id: question.id, profile_id: profileId}];
        cartItems = cartItems.filter((line1, index, lineArray) => lineArray.findIndex((line2) => line2.profile_id === line1.profile_id && line2.question_id === line1.question_id) === index);

        if (cartItems.length > rtpOrder?.order_lines?.length) {
            paymentDisclaimerShow();
        }

        try {
            const response = await api.post("v2/question-orders/cart", {
                line_items: cartItems,
            });
            const _order = response.data?.result?.data || null;
            setOrderInfo(_order);

            const cartItemsCount = (_order?.order_lines || []).length;
            if (!cartItemsCount) {
                console.log('Order is empty')
            } else {
                const cartLines = (_order?.order_lines || []).map((item) => ({
                  question_id: item.question_id,
                  profile_id: item.profile_id,
                }));
                updateDraftCartLines(cartLines);
                
                fbq('track', 'AddToCart'); //NOTE: Facebook Meta Pixel tracking event
                history.push("/instant-prediction/payment");
            }
        } catch (error) {
            console.log('Can not add question to cart')
        }
    }

    const manageQuestionSubscription = (question, profileId) => {
        if(!question) return;
        setQuestion(question);
        setProfileToUse(profileId)

        if(question.published && profileId) {
            createCart(question, profileId).then();
        } else {
            setShowModal(true);
        }
    }

    const onLinkClick = async (e) => {
      e.preventDefault();
      const url = new URL(e.target.href);

      if (url.pathname.startsWith("/action-link")) {
        const token = url.searchParams.get("token");

        await handleAnswerActionLink(token, manageQuestionSubscription);
      } else {
        window.open(url, "_blank");
      }
    };

    useEffect(() => {
      if (!answerRef.current) return;

      const anchors = answerRef.current.querySelectorAll("a");
      anchors.forEach((a) => {
        a.addEventListener("click", onLinkClick);
      });

      return () => {
        anchors.forEach((a) => {
          a.removeEventListener("click", onLinkClick);
        });
      };
    }, [line]);

    useEffect(()=>{
      if(newKundaliDatas?.kundali_datas){
        setCurrentKundali(newKundaliDatas?.kundali_datas[0]?.kundali_name)
      }
    },[newKundaliDatas])

  // console.log('Profile MetaData', profileMetaData);
  // console.log('Chart Data', kundaliData)
  // console.log('Data To Pass', sliderData);
  // console.log('Line Profile', line?.profile)
  // console.log("Line", line);

  return (
    <>
      <div className={"rtp-question-answer" + ((isDarkMode && " dark") || "")}>
        <Emailing
          line={line}
          kundalisName={profileMetaData}
          currentChartType={currentChartType}
          emailHandler={emailHandler}
          emailProcessing={emailProcessing}
          setEmailProcessing={setEmailProcessing}
        />

        <div className={"answer-container" + ((isDarkMode && " dark") || "")}>
          <div className={"answer" + ((isDarkMode && " dark") || "")}>
            <div className={"main" + ((isDarkMode && " dark") || "")}>
              {/* Kundali Chart */}
              {kundaliData && kundaliData.length > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="kundali-chart"
                    style={{
                      maxWidth: `${
                        (parentWidth && parentWidth > 600 && "560px") ||
                        `${parentWidth - 30}px` ||
                        "560px"
                      }`,
                    }}
                  >
                    {line?.profile && (
                      <div>
                        <p
                          className={`profile-title d-flex align-items-center ${
                            (isDarkMode && "dark") || ""
                          }`}
                        >
                          {line.profile?.relationship?.name && (
                            <span
                              style={{
                                color: `${line.profile?.relationship?.color}`,
                              }}
                            >
                              {`${line.profile.relationship?.name} Profile`}
                            </span>
                          )}
                          <span className="name">({line.profile?.name})</span>
                        </p>
                        <div className="d-flex gap-3 pb-3">
                          {profileMetaData.map((d, i) => (
                            <div
                              key={i}
                              style={
                                currentKundali == d.kundali_name
                                  ? {
                                      backgroundColor: "#D49369",
                                      color: "white",
                                      padding: "6px 28px",
                                      borderRadius: "30px",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#DAE3ED",
                                      color: "black",
                                      padding: "6px 28px",
                                      borderRadius: "30px",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }
                              }
                              onClick={() => handleKundaliChange(d.kundali_name)}
                            >
                              {d.kundali_name} Chart
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="kundali-slider-wrapper">
                      {loading ? (
                        <LoadingIcon />
                      ) : (
                        <div style={{ position: "relative", overflow: "hidden" }}>
                          {sliderData &&
                            sliderData.map((slideData, i) => (
                              <div
                                key={i}
                                // style={currentKundali == profileMetaData[i].kundali_name?{opacity:1,width:'100%'}:{opacity:1,width:'100%'}}
                                style={
                                  currentKundali ==
                                  profileMetaData[i].kundali_name
                                    ? {
                                        opacity: 1,
                                        width: "100%",
                                      }
                                    : {
                                        zIndex: "-1",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        opacity: 0,
                                      }
                                }
                              >
                                <KundaliSlider
                                  kundali_data={slideData}
                                  activeHouses={newActiveHouses[i]}
                                  firstHouses={newFirstHouses[i]}
                                  kundaliName={profileMetaData[i].kundali_name}
                                  kundaliTitle={kundaliTitle}
                                  sliderRef={sliderRef[i]}
                                  handleOnSlideChange={handleOnSlideChange}
                                  line={line}
                                  config={config}
                                  profile={line?.profile}
                                  isDarkMode={isDarkMode}
                                  scale={
                                    (parentWidth && parentWidth > 600 && 550) ||
                                    parentWidth - 40 ||
                                    550
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="answer-text-content">
                {line?.standard_prediction && (
                  <AnswerItem
                    innerRef={answerRef}
                    title={line.name}
                    description={line.standard_prediction}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-5">
          {(isDarkMode && <LogoDark width="100" />) || <LogoLight width="100" />}
        </div>
      </div>
      {
        showModal && (
          <QuestionNotifyModal show={showModal} setShow={setShowModal} question={question} profileId={profileToUse} classes="question-notify-modal" />
        )
      }
    </>
  );
};

export default Answer;
