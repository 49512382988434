import React from "react"

import MainLayout from 'src/core/component/MainLayout';
import PaymentPolicy from "src/gdpr/component/paymentpolicy/PaymentPolicy";


export default function PaymentPolicyPage() {

  return (
    <MainLayout>
      <PaymentPolicy />
    </MainLayout>
  )
}
