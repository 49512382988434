import React from 'react'
import io from 'socket.io-client'
import useAuthInfo from 'src/auth/hook/auth-info';
import { loadJSON } from 'src/lib/json-store';


function getSocket() {
  const query = {};
  const accessToken = loadJSON('auth.accessToken');

  if (accessToken) {
    query['jwt'] = accessToken;
  }

  return io.connect(process.env.SOCKET_SERVER, {
    transports: ['websocket', 'polling'],
    query: query,
  });
}


const SocketContext = React.createContext(null);

export const useSocket = () => {
  const socket = React.useContext(SocketContext);

  return socket;
}

const SocketContextProvider = (props) => {
  const {accessToken} = useAuthInfo();
  const firstRun = React.useRef(true);
  const socket = getSocket();

  React.useEffect(() => {
    const handleSocketConnection = () => {
      console.info('socketio connection established!');
    };
    socket.on('connect', handleSocketConnection);
    return () => {
      socket.off('connect', handleSocketConnection);
    }

  }, []);


  React.useEffect(() => {

    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    if (!socket?.socket) { return; }

    if (accessToken) {
      socket.socket.options.query = 'jwt=' + accessToken;
    } else {
      socket.socket.options.query = '';
    }

    socket.socket.connect();

  }, [accessToken]);


  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketContextProvider;
