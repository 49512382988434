import React from "react";

import Footer from "src/core/component/footer/Footer";
import Navbar from "src/core/component/navbar/Navbar";
import Cookie from "src/gdpr/component/cookie/Cookie";
import { useTheme } from "src/theme/component/ThemeContext";

const MainLayout = (props) => {
  const [isDarkMode] = useTheme();

  const className = props.className;

  return (
    <>
      {/* Cookie Dialog Above Header */}
      {/* <Cookie variant={'header'} /> */}
      {/*<Cookie variant={"overlay"} />*/}
      <Cookie variant="sticky" />

      <Navbar />

      {/* <section className={"pocket-features" + (isDarkMode && ' dark' || '' )}></section> */}
      <main
        className={`pocket-main flex-grow-1 ${(isDarkMode && "dark") || ""} ${
          (className && className) || ""
        }`}
        style={props.style}
      >
        {props.children}
      </main>

      {/* Cookie Dialog above Footer */}
      {/* <Cookie variant={'footer'} /> */}

      <Footer />
    </>
  );
};

export default MainLayout;
