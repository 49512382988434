import React from "react";

import { NextArrowSvg, CarouselNextIcon } from "src/core/component/assets/svg/CommonSVG";


const NextArrow = ({className="", style, onClick, isDarkMode=false, astro=false, title="", ...rest}) => {

  return (
        <div className={`${className}${isDarkMode && ' dark' || ''}${!astro && ' common' || ''}`} onClick={onClick}>
          {
            title && <strong>{title}</strong>
          }
          {
                astro ? <CarouselNextIcon /> : <NextArrowSvg />
            }
        </div>
    )
}

export default NextArrow;
