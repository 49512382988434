import React from 'react'
import ForumAds from './ForumAds'

const ForumPromo = () => {

  const astroQuestion = [
    {"id":1,"name":"Want to know about your love life?","formatted_price":'$2.99'},
    {"id":2,"name":"Want to know about your love life?","formatted_price":'$3.99'},
    {"id":3,"name":"Want to know about your love life?","formatted_price":'$4.99'},
  ]

  return (
    <div className='forum_promo'>
        <ForumAds/>
        <div className='forum_promo_astro_question'>
          <p>New Astrology Questions</p>
          <div className='astro_questions'>
            {
              astroQuestion?.map(question=>
                <div key={question.id} className="astro_question">
                  <div className='name'>{question.name}</div>
                  <div className='price'>{question.formatted_price}</div>
                </div>
              )
            }
            <div className='view_all'>View All Questions</div>
          </div>
        </div>
    </div>
  )
}

export default ForumPromo