import React from "react";

const AlignEnd = ({ children, classes = "", ...rest }) => {
  return (
    <div className={"d-flex align-items-center justify-content-end " + classes}>
      {children}
    </div>
  );
};

export default AlignEnd;
