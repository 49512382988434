import React, { Fragment } from "react";

import AvatarImageUpload from "./AvatarImageUpload";
import CoverImageUpload from "./CoverImageUpload";


const ImageUploadContainer = ({
    formData,
    setFormData,
    profile,
    isDarkMode,
}) => {

    setFormData = typeof setFormData === "function" ? setFormData : function noop() {};

    return (
        <Fragment>
            <div className="pocket-profile-setup__profile-images">
                <CoverImageUpload 
                    formData={formData} 
                    setFormData={setFormData} 
                    profile={profile}  
                />

                <AvatarImageUpload
                    formData={formData}
                    setFormData={setFormData}
                    profile={profile}
                />
            </div>
        </Fragment>
    );
};

export default ImageUploadContainer;
