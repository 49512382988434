import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApplyCoupon from "src/core/component/form/ApplyCoupon";
import api from "src/lib/api";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import StripeCardPayment from "src/core/component/stripe/StripeCardPayment";
import PaymentProcessingModal from "./PaymentProcessingModal";
import ContentHeader from "./ContentHeader";
import { FUNDING, PayPalButtons } from '@paypal/react-paypal-js'
import { PocketToast } from "src/core/component/toast/PocketToast";
import useAuthInfo from "src/auth/hook/auth-info";

const PaymentForm = ({
  isDarkMode,
  order,
  isLoading,
}) => {
  let history = useHistory();
  const { clearRTPInfo } = useRTPInfo();
  const [showModal, setShowModal] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [answerOrder, setAnswerOrder] = useState(null);
  const [showApplyCouponForm, setShowApplyCouponForm] = useState(false);
  const {profile } = useAuthInfo();
  const [isFreePayment,setIsFreePayment] = useState(false)

  useEffect(()=>{
      setIsFreePayment(order?.is_free && (order?.order_lines || []).length > 0)
  },[order])

  useEffect(() => {
    if (processing) {
      setShowModal(true);
    }
  }, [processing]);

  useEffect(() => {
    if (error && showModal) {
      setShowModal(false);
    }
  }, [error]);

  const handleModalClose = () => {
    setShowModal(false);
    history.push({
      pathname: "/realtime-prediction",
      search: "?stage=answer",
      state: {
        answerState: answerOrder,
      },
    });
  };

  const confirmOrder  = async () => {
    try {
      const result = await api.post(`v1/question_order/${order?.id}/confirm`, {
        'payment_gateway': '',
      });
      return result;
    } catch (error) {
      error = error?.response?.data?.error || error;
      return {
        error: error?.message || `Sorry, can't process your payment`,
      };
    }
  }

  const confirmWalletOrder  = async () => {
    try {
      const result = await api.post(`v1/question_order/${order?.id}/confirm`, {
        'payment_gateway': 'wallet',
      });
      return result;
    } catch (error) {
      error = error?.response?.data?.error || error;
      return {
        error: error?.message || `Sorry, can't process your payment`,
      };
    }
  }
  
  const requestClientSecret = async () => {
    try {
      const result = await api.post(`v1/question_order/${order?.id}/confirm`, {
        'payment_gateway': 'stripe',
      });
      return result;
    } catch (error) {
      error = error?.response?.data?.error || error;
      return {
        error: error?.message || `Sorry, can't process your payment`,
      };
    }
  };

  const createPayPalOrder = async () => {
    try {
      const res = await api.post(`v1/question_order/${order?.id}/confirm`, {
        'payment_gateway': 'paypal',
      });

      const data = res.data?.result?.data;
      return data['payment_intent_id'];
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message || error.message;

      throw new Error(errorMsg);
    }
  };

  const handlePayPalPaymentApprove = async (paypalData) => {
    setSuccess(false);
    setError(false);

    try {
      const res =  await api.post(`v1/paypal-orders/${paypalData.orderID}/capture`);
      const data = res.data?.result?.data;
      setProcessing(true);
      setSuccess(true);
      handlePaymentSuccess( data );
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message || error.message;

      PocketToast({
        'type': 'error',
        'message': errorMsg,
      });
      setError(true);
    } finally {
      setProcessing(false);
    }
  }

  const handlePaymentSuccess = (data) => {
    if(data['amount']) {

      fbq('track', 'Purchase', {
        'value': (data['amount']),
        'currency': data['currency'],
      }); //NOTE: Facebook Meta Pixel tracking event
    }

    displayAnswerPage({
      'order_id': order?.id,
      'invoice_id': data['invoice_id'],
      'payment_reference': data['payment_intent_id'],
      'payment_gateway': data['payment_gateway'],
    });
  }

  const displayAnswerPage = (orderState) => {
    setAnswerOrder(orderState);
    setProcessing(false);
    setSuccess(true);
    setShowCloseIcon(false);

    //Clear RTP info from redux state.
    clearRTPInfo();

    setTimeout(() => {
      setShowModal(false);

      history.push({
        pathname: "/instant-prediction/answer",
        state: {
          answerState: orderState,
        },
      });
    }, 2000);
  }

  const getFreePrediction = async () => {
    try {
      setProcessing(true);
      const res = await confirmOrder();

      if (res['error']) {
        throw new Error(res['error']['message'])
      }

      let data = res['data']['result']['data'];

      data['amount'] = 0.0;
      data['currency'] = false;

      handlePaymentSuccess(data)
    } catch (error) {
      const errMsg = error.response?.data?.error?.message || error.message;
      setError(errMsg)
    } finally {
      setProcessing(false);
    }
  }

  const getPredictionFromWallet = async () => {
    try {
      setProcessing(true);
      const res = await confirmWalletOrder();

      if (res['error']) {
        throw new Error(res['error']['message'])
      }

      let data = res['data']['result']['data'];
      data['amount'] = order?.amount;
      data['currency'] = 'USD';

      handlePaymentSuccess(data)
    } catch (error) {
      const errMsg = error.response?.data?.error?.message || error.message;
      setError(errMsg)
    } finally {
      setProcessing(false);
    }
  }

  const paypal_style={
    color: isDarkMode?'black': 'silver',
    size: 'small',
    // shape:  'pill',
    label:  'pay',
    height: 40,
    tagline: 'false'
}

// const isFreePayment = order?.is_free && (order?.order_lines || []).length > 0;

  return (
    <>
      <div
        className="col-mdlg-6 order-2 order-mdlg-1 position-sticky"
        style={{ top: "10px", alignSelf: "flex-start" }}
      >
        {
              <>
                <ContentHeader isDarkMode={isDarkMode} />

                {!isFreePayment==true &&(
                  <>
                <StripeCardPayment
                  amount={order?.amount || 0}
                  clientSecret={requestClientSecret}
                  onSuccess={handlePaymentSuccess}
                  processing={processing}
                  setProcessing={setProcessing}
                  error={error}
                  setError={setError}
                  isDarkMode={isDarkMode}
                  disabled={isLoading || !order}
                />
                {/* <div className="separator-bottom"></div> */}
                 {/* d-mdlg-none */}
                 <div className="d-flex justify-content-between ">
                <div className="coupon d-flex align-items-center my-1">
                  { showApplyCouponForm && (
                    <ApplyCoupon
                      name={order?.name}
                      id={order?.id}
                      setIsFreePayment={setIsFreePayment}
                      modelName='QuestionOrder'
                      classes={(isDarkMode && "dark") || ""}
                    />
                  ) || (
                    <a href="#" onClick={() => setShowApplyCouponForm(!showApplyCouponForm)} title="You can apply multiple coupon codes one-by-one.">Use Promo Code</a>
                  )}
                </div>
                <div>
                {
                    (profile.admin && profile?.wallet_balance?.dollar>=order?.amount && order?.amount!=0) ?
                    <button className="btn btn-primary" onClick={getPredictionFromWallet} >Pay with coin {order.amount*profile?.wallet_balance?.exchange_rate}</button>:null
                  }
                </div>
                </div>
                </>
                )}
                {/* <div className="pt-3">
                <PayPalButtons
                  style= {paypal_style}
                  forceReRender={[paypal_style]}
                  fundingSource={FUNDING.PAYPAL}
                  createOrder={createPayPalOrder}
                  onApprove={handlePayPalPaymentApprove}
                  disabled= {isFreePayment==true? true:(isLoading || !order)}
                />
                </div> */}
                {isFreePayment==true && (
                <div className="pt-2 pocket-pandit-free-prediction">
                <p>You have appiled a coupon code, your total is <b>$0.00 USD</b></p>
                <button className="pocket-btn d-block w-100 mt-0" type="submit" onClick={getFreePrediction}>View Your Free Prediction</button>
                </div>
                )
                }
              </>
        }
      </div>

      {/* processing state modal */}
      <PaymentProcessingModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        success={success}
        processing={processing}
        showCloseIcon={showCloseIcon}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default PaymentForm;
