import React from "react";

import MainLayout from 'src/core/component/MainLayout';
import Notifications from "src/core/component/notification/Notifications";


const NotificationMobile = () => {

    return (
        <MainLayout>
            <Notifications />
        </MainLayout>
    )
}

export default NotificationMobile;
