import React, { useState, useEffect } from "react";

import useDependentState from "src/core/hook/useDependentState";
import ForumReplyItem from "src/forumV2/forumDetail/ForumReplyItem";
import { useTheme } from "src/theme/component/ThemeContext";

const ForumReplyList = ({ postReplies, post, ...rest }) => {
  const [isDarkMode] = useTheme();
  const [replies, setReplies] = useDependentState(
    (_data) => _data,
    [postReplies]
  );

  return (
    <div className="row">
      {replies && replies.length > 0 && replies.map((item, index) => {
          return (
            <div className="col-12 mt-3" key={index}>
              <ForumReplyItem
                reply={item}
                post={post}
                isDarkMode={isDarkMode}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ForumReplyList;
