import React from 'react'

import useAuthInfo from 'src/auth/hook/auth-info';
import ScrollView from 'src/core/component/ScrollView';
import MessageItem from './MessageItem';


export default function ChatMessageList({messages, astrologer, conversation,  isDarkMode, ...rest }) {
    const authInfo = useAuthInfo();
    const scrollableRef = React.useRef();

    // React.useEffect(() => scrollDown(), [messages]);

    // const scrollDown = () => {
    //     if (!scrollableRef.current) { return; }
    //     scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    // }

    return (
        <>
            {/* <ScrollView className="w-100 h-100" innerRef={scrollableRef}> */}
                <div className={`pocket-astrologer__chat-conversations-list ${isDarkMode && 'dark' || ''}`}>
                    {messages.map((item, i) => {
                        const isReply = item.user_id != authInfo?.profile?.id;
                        const prevItem = messages[i - 1];

                        const timeGrouped = !prevItem || new Date(prevItem.created_at).format('yyyy-MM-dd:HH') != new Date(item.created_at).format('yyyy-MM-dd:HH')

                        let timeGroupHeader;

                        if (new Date(item.created_at).format('yyyy') != new Date().format('yyyy')) {
                            timeGroupHeader = new Date(item.created_at).format('MMM dd yyyy, h tt');
                        }
                        else if (new Date(item.created_at).format('yyyy-MM-dd') != new Date().format('yyyy-MM-dd')) {
                            timeGroupHeader = new Date(item.created_at).format('MMM dd, h tt');
                        } else {
                            timeGroupHeader = new Date(item.created_at).format('h tt');
                        }

                        return (
                            <React.Fragment key={item.id}>
                              {!item?.error && (
                                <>
                                    {timeGrouped && (
                                          <div className={i === 0 ? `timing-group first ${isDarkMode && 'dark' || ''}` : `timing-group ${isDarkMode && 'dark' || ''}`}>
                                              <h6 className="mx-auto">
                                                  <span className="timing">
                                                      {timeGroupHeader}
                                                  </span>
                                              </h6>
                                          </div>
                                      )}

                                      <MessageItem
                                          item={item}
                                          isReply={isReply}
                                          isDarkMode={isDarkMode}
                                      />

                                </>
                              )}
                            </React.Fragment>
                        )
                    })}

                </div>
            {/* </ScrollView> */}
        </>
    )
}
