import React from "react";
import { Link } from 'react-router-dom';

import Card from "src/core/component/card/Card";
import CardBody from "src/core/component/card/CardBody";


const FeatureItem = ({classes, title, url, icon, onClick, ...rest}) => {

  return (
        <div className={"col " + classes}>
            <Link to={url} onClick={onClick}>
                <Card classes={'text-center'}>
                    <div className="card-img d-flex justify-content-center align-items-center">
                        {icon}
                    </div>

                    <CardBody
                        isfeatures={true}
                        hasLink={false}
                        description={title}
                        onClick={onClick}
                    />

                </Card>
            </Link>
        </div>
    )
}

export default FeatureItem;
