import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import api from "src/lib/api";
import { pick, validateEmail } from "src/util/extra";
import { CalendarSvg } from "src/core/component/assets/svg/CommonSVG";
import CheckboxField from "src/core/component/form/CheckboxField";
import PasswordField from "src/core/component/form/PasswordField";
import FormField from "src/core/component/form/FormField";
import SearchLocation from "src/core/component/form/SearchLocation";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { dateAndTimeToDateTime } from "src/util/date_and_time_to_datetime";
import { useModal } from "src/core/hook/useModal";
import PocketDatePicker from "src/core/component/form/PocketDatePicker";
import Gender from "src/core/component/form/Gender";
import useAuthInfo from "src/auth/hook/auth-info";
import PocketSelection from "src/core/component/selection/PocketSelection";
import BirthProfileAlert from "src/instant-prediction/components/birthprofilesetup/BirthProfileAlert";
import PrashnaKundaliModal from "src/core/component/prashnakundali/PrashnaKundaliModal";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import findGender from "src/core/component/findGender";
import SocialRegisterLogin from "src/auth/component/social/SocialRegisterLogin";

const initialState = {
  login_method: "password",
  screen_name:'',
  name: "",
  address: "",
  dob: "",
  latitude: "",
  longitude: "",
  timezone: "",
  country_code: "",
  login: "",
  password: "",
  date: new Date().format("yyyy-MM-dd"),
  time: new Date().format("HH:mm:ss"),
  agree_tos: false,
  gender: "",
  relationship_id: "",
  profile_name: "",
  profile_type: "regular_kundali_profile",
};

export default function SignupForm({ isDarkMode, onClickLogin }) {
  onClickLogin = typeof onClickLogin === 'function' ? onClickLogin : function noop() { }

  const { login: loginUser } = useAuthInfo();
  const [formData, setFormData] = React.useState(initialState);
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [tosError, setTosError] = React.useState(null);
  const [fieldError, setFieldError] = React.useState(null);
  const [relationshipList, setRelationshipList] = React.useState([]);
  const [showPrasnaModal, handlePrasnaModalShow, handlePrasnaModalClose] = useModal(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setResponse(null);
    setError(null);
    setTosError(null);
    setFieldError(null);

    if (!validateEmail(formData["login"])) {
      setFieldError({ login: "Email is not valid" });
      return;
    }

    if(formData['screen_name'].length<5){
      setFieldError({ screen_name: "Username must be 5 letters." });
      return
    }
    
    if(formData['screen_name'].length>49){
      setFieldError({ screen_name: "Username is longer than 50." });
      return
    }

    if (!formData["gender"]) {
      setFieldError({ gender: "Please select a gender" });
      return;
    }
    if (formData["name"].trim().length <4) {
      setFieldError({ name: "Please enter your full name" });
      return;
    }
    if (formData["profile_name"].trim().length <4) {
      setFieldError({ profile_name: "Please enter your profile name" });
      return;
    }

    if (!formData["agree_tos"]) {
      setTosError({
        agree_tos: "You must agree to our Terms of Services and Privacy Policy",
      });

      return;
    }

    const dob = dateAndTimeToDateTime(formData["date"], formData["time"]);
    if (dob) {
      formData["dob"] = dob;
    }

    const payload = pick(
      formData,
      "screen_name",
      "name",
      "address",
      "dob",
      "latitude",
      "longitude",
      "timezone",
      "country_code",
      "login",
      "password",
      "gender",
      "relationship_id",
      "profile_name",
    );
    // console.log("🚀 ~ payload:", payload)

    try {
      const res = await api.post("/v1/auth/signup", payload);
      const data = res.data?.result?.data;
      if (data) {
        PocketToast({
          type: "success",
          message:
            "Congratulations, your account has been created successfully",
        });

        fbq('track', 'CompleteRegistration');//NOTE: Facebook Meta Pixel tracking event

        loginUser(data["access_token"], data["refresh_token"]);
      } else {
        throw res;
      }
    } catch (error) {
      setResponse({
        error: error?.response?.data?.error || {
          message: error?.message || "Unknown Error.",
        },
      });
    }
  };

  const onChangeInputValue = (e) => {
    let value;

    if (e.target["type"] == "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    if (e.target.name == "name" || e.target.name == 'profile_name'){
      value = value.replace(/  +/g, ' ');
    }
    if (e.target.name == "screen_name"){
      value = value.replace(/ /g, '');
    }
    if (e.target.name == "login"){
      value = value.trim().replace(/ +/g, '')
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSelectionChange = (e) => {
    setFormData({ ...formData, [e.name]: e?.id || e?.value });
  };

  const onLocationChange = (data) => {
    setFormData((currentValue) => ({
      ...currentValue,
      address: data["query"],
      latitude: data["place"]["latitude"],
      longitude: data["place"]["longitude"],
      timezone: data["timezone"]["name"],
      country_code: data["country_code"] || "",
    }));
  };

  React.useEffect(() => {
    fetchRelationshipList().then();
  }, []);

  useEffect(() => {
    autoSelectGender(formData.relationship_id)
  }, [formData.relationship_id, relationshipList])

  const autoSelectGender = (rel_id) => {
    let relationship = relationshipList.filter((rel) => rel.id == rel_id)[0]
    if (relationship?.name) {
      if (!(relationship?.name == "Self" || relationship?.name == "Friend")) {
        setFormData(prev => ({ ...prev, gender: findGender(relationship?.name) }))
      }
    }
  }

  const fetchRelationshipList = async () => {
    try {
      const response = await api.get("/v1/relationship");
      setRelationshipList(response?.data.result.data);
    } catch (error) {
      console.log("Cannot fetch relationship");
    }
  };

  const handleRelationshipChange = (relationship) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        relationship_id: relationship.id,
      }
    })
  };

  // console.log('Form Data', formData)

  function getRelationshipTitle() {
    if (relationshipList.length && formData["relationship_id"]) {
      const relationship = relationshipList.filter((relationship) => formData["relationship_id"] === relationship.id);
      if (relationship.length && relationship[0].is_self) {
        return "Your Profile Name"
      } else {
        return `Your ${relationship[0].name}'s Name`
      }
    } else {
      return "Your Profile Name"
    }
  }

  let relationship = relationshipList.length > 0 && relationshipList.filter((relationship) => formData["relationship_id"] === relationship.id)[0];

  let fields = [
    {
      name: "screen_name",
      placeholder: "Enter username",
      label: "Username",
      type: "text",
      hasIcon: false,
      icon: "",
      required: true,
    },
    {
      name: "name",
      placeholder: "Enter your name",
      label: "Name",
      type: "text",
      hasIcon: false,
      icon: "",
      required: true,
    },
    {
      name: "login",
      placeholder: "Enter your email",
      label: "Email",
      type: "email",
      hasIcon: false,
      icon: "",
      required: true,
    },
    {
      name: "password",
      placeholder: "Enter your password",
      label: "Password",
      type: "password",
      hasIcon: false,
      icon: "",
      required: true,
    },
  ];
  let fields2 = [
    {
      name: "profile_name",
      placeholder: getRelationshipTitle(),
      label: getRelationshipTitle(),
      type: "text",
      hasIcon: false,
      icon: "",
      required: true,
    },
    {
      name: "date",
      type: "date",
      placeholder: "Enter Birth Date",
      label: "Birth Date (YYYY-MM-DD)",
      required: true,
    },
    {
      name: "address",
      placeholder: "Enter Birth Location",
      label: "Birth Location",
      type: "location-input",
      hasIcon: false,
      icon: "",
      required: true,
    },
    {
      name: "time",
      type: "time",
      placeholder: "Enter Birth Time",
      label: "Birth Time (HH:MM:SS AM/PM)",
      step: 1,
      required: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      required: true,
    },
  ]

  const handlePrashnaKundali = (e) => {
    e.preventDefault();
    handlePrasnaModalShow();
  };
  const setupPrashnaKundali = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      profile_type: "prashna_kundali_profile",
    });
    handlePrasnaModalClose();
  };

  /*{
    name: 'relationship_id',
    label: 'Relationship',
    type: 'select',
    required: true,
    options: relationshipList
},*/

  return (
    <>
      <div
        className={
          "pocket-auth__signup--wrapper" + ((isDarkMode && " dark") || "")
        }
      >
        <form
          method="POST"
          autoComplete="off"
          onSubmit={handleFormSubmit}
          className={"pocket-form" + ((isDarkMode && " dark") || "")}
        >
          <h5 className="auth-title mb-2">Enter your registration details</h5>

          {(response?.error || error) && (
            <div className="alert alert-danger w-100 text-center my-2">
              <strong> {response?.error["message"] || error} </strong>
            </div>
          )}

          <div className="row gx-2 gx-smsm-3">
            {fields &&
              fields.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 field" key={index}>
                    {(item.type === "text" ||
                      item.type === "email" ||
                      item.type === "time") && (
                        <FormField
                          property={item.name}
                          type={item.type}
                          hasLabel={true}
                          hasIcon={item.hasIcon}
                          label={item.label}
                          placeholder={item.placeholder}
                          icon={item.icon}
                          onChange={onChangeInputValue}
                          value={formData[item.name] || ""}
                          required={item.required}
                          autoComplete={"off"}
                          isInvalid={
                            fieldError &&
                            Object.keys(fieldError).includes(item.name)
                          }
                          error={
                            fieldError &&
                            Object.keys(fieldError).includes(item.name) &&
                            fieldError[item.name]
                          }
                        />
                      )}

                    {item.type === "date" && (
                      <PocketDatePicker
                        formData={formData}
                        setFormData={setFormData}
                        property={item.name}
                        required={true}
                        error={"Please enter a valid date!"}
                        hasLabel={true}
                        label={item.label}
                        placeholder={item.placeholder}
                        disabled={false}
                        isDarkMode={isDarkMode}
                      />
                    )}

                    {item.type === "password" && (
                      <PasswordField
                        property={item.name}
                        hasLabel={true}
                        hasIcon={item.hasIcon}
                        label={item.label}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        onChange={onChangeInputValue}
                        value={formData[item.name] || ""}
                        required={item.required}
                      />
                    )}

                    {item.type == "location-input" && (
                      <SearchLocation
                        property={item.name}
                        hasLabel={true}
                        hasIcon={item.hasIcon}
                        label={item.label}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        onChange={onLocationChange}
                        value={formData[item.name] || ""}
                        required={item.required}
                      />
                    )}

                    {item.type === "select" && item.name === "gender" && (
                      <>
                        <Gender
                          value={formData[item.name]}
                          onChange={handleSelectionChange}
                          classes={
                            "pocket-select" + ((isDarkMode && " dark") || "")
                          }
                          hasLabel={true}
                          label={item.label}
                          placeholder={"Select Gender"}
                        />
                        {fieldError?.gender && (
                          <div className="invalid-feedback d-block">
                            {fieldError.gender}
                          </div>
                        )}
                      </>
                    )}

                    {item.type === "select" && item.name === "relationship_id" && (
                      <PocketSelection
                        options={[...item.options]}
                        currentValue={item.options.filter(opt => opt.id === formData[item.name])[0] || ''}
                        onChange={handleRelationshipChange}
                        classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                        hasLabel={true}
                        label={item.label}
                        placeholder={'Relationship'}
                      />
                    )}
                  </div>
                );
              })}
          </div>

          <hr style={{ margin: "24px 0", backgroundColor: isDarkMode ? "#929292" : "#C9CFD7" }} />

          <div className="text-center birth-profile__alert__container__border">
            <BirthProfileAlert onClick={handlePrashnaKundali} relationship={relationship} />
          </div>

          <div className="row gx-2 gx-smsm-3">
            <div className="col-12 col-sm-7">
              <div className="d-flex flex-wrap align-items-center mb-3 gap-3">
                <h5 className="auth-title mb-0">Enter birth detail for your</h5>
                <PocketSelection
                  options={[...relationshipList]}
                  currentValue={relationshipList.filter(opt => opt.id === formData["relationship_id"])[0] || relationshipList[0] || ""}
                  onChange={handleRelationshipChange}
                  classes={"pocket-select flex-fill" + (isDarkMode && ' dark' || '')}
                  hasLabel={false}
                  placeholder={'Relationship'}
                />
              </div>
            </div>

            <div className="col-12" />

            {fields2 &&
              fields2.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 field" key={index}>
                    {(item.type === "text" ||
                      item.type === "email" ||
                      item.type === "time") && (
                        <>

                          <FormField
                            property={item.name}
                            type={item.type}
                            hasLabel={true}
                            hasIcon={item.hasIcon}
                            label={item.label}
                            placeholder={item.placeholder}
                            icon={item.icon}
                            onChange={onChangeInputValue}
                            value={formData[item.name] || ""}
                            required={item.required}
                            autoComplete={"off"}
                            isInvalid={
                              fieldError &&
                              Object.keys(fieldError).includes(item.name)
                            }
                            error={
                              fieldError &&
                              Object.keys(fieldError).includes(item.name) &&
                              fieldError[item.name]
                            }
                          />
                          {/*
                            item.type === "time" && <BirthProfileAlert className="no-idea-alert bg-transparent m-0 p-0" onClick={handlePrashnaKundali} relationship={relationship} />
                          */}
                        </>
                      )}

                    {item.type === "date" && (
                      <PocketDatePicker
                        formData={formData}
                        setFormData={setFormData}
                        property={item.name}
                        required={true}
                        error={"Please enter a valid date!"}
                        hasLabel={true}
                        label={item.label}
                        placeholder={item.placeholder}
                        disabled={false}
                        isDarkMode={isDarkMode}
                      />
                    )}

                    {item.type === "password" && (
                      <PasswordField
                        property={item.name}
                        hasLabel={true}
                        hasIcon={item.hasIcon}
                        label={item.label}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        onChange={onChangeInputValue}
                        value={formData[item.name] || ""}
                        required={item.required}
                      />
                    )}

                    {item.type == "location-input" && (
                      <SearchLocation
                        property={item.name}
                        hasLabel={true}
                        hasIcon={item.hasIcon}
                        label={item.label}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        onChange={onLocationChange}
                        value={formData[item.name] || ""}
                        required={item.required}
                      />
                    )}

                    {item.type === "select" && item.name === "gender" && (
                      <>
                        <Gender
                          value={formData[item.name]}
                          onChange={handleSelectionChange}
                          classes={
                            "pocket-select" + ((isDarkMode && " dark") || "")
                          }
                          hasLabel={true}
                          label={item.label}
                          placeholder={"Select Gender"}
                          disabled={
                            !(
                              relationship?.name == "Self" ||
                              relationship?.name == undefined ||
                              relationship?.name == "Friend"
                            )
                          }
                        />
                        {fieldError?.gender && (
                          <div className="invalid-feedback d-block">
                            {fieldError.gender}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>

          <CheckboxField
            property={"agree_tos"}
            onChange={onChangeInputValue}
            classes={"register-login mb-3"}
            isInvalid={(tosError && tosError?.agree_tos) || false}
            error={(tosError?.agree_tos && tosError?.agree_tos) || false}
          >
            <label className="form-check-label" htmlFor="agree_tos">
              I agree with the
              <Link to="/tos">Terms of Services</Link> and&nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
            </label>
          </CheckboxField>

          <div className="submit-login-register">
            <button className="pocket-btn" type="submit">
              Sign Up
            </button>
          </div>
        </form>

        <div className="account-exists">
          Already have an account? <Link to="/auth/login" onClick={onClickLogin}>Login</Link>
        </div>

        <div className="social-login mb-sm-5">
          <SocialRegisterLogin
            title={"Or signup with your social media accounts"}
            type={"Signup"}
          />
        </div>
      </div>
      {/*Prashna Kundali Modal */}
      {showPrasnaModal && (
        <PrashnaKundaliModal
          showModal={showPrasnaModal}
          handleModalClose={handlePrasnaModalClose}
          heading="Looks like you don't know your birth profile"
          description={`We will use the practice of ‘Prasna Chart’ to provide a prediction to your question. Please enter your current location below and we will use that along with the date and time you asked us for prediction to your question(s).`}
          onLocationChange={onLocationChange}
          setupPrashnaKundali={setupPrashnaKundali}
          formData={formData}
        />
      )}
    </>
  );
}
