import React from "react";

import { pluralize } from "src/util/extra";
import AmountItem from "./AmountItem";
import DownloadInvoice from "./DownloadInvoice";


const PricingSummary = ({
    order,
    predictionOrder=true,
    isDarkMode
    }) => {

    return (
        <div className={"order-summary__pricing" + (isDarkMode && ' dark' || '')}>
            {predictionOrder && (
                <AmountItem title={`${order.order_lines.length} ${pluralize(order.order_lines.length, 'Question', 'Questions')} `} value={order.amount_formatted} />
            )}

            <AmountItem title={'Total:'} value={order.amount_formatted} classes={'all-total'} />

            <div className="d-flex">
                <div className="ms-auto">
                    <DownloadInvoice
                        order={order}
                        isDarkMode={isDarkMode}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingSummary
