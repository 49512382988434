import React from "react";
import { Link, useHistory } from "react-router-dom";

import Card from "src/core/component/card/Card";
import ProductLines from "src/core/component/order/ProductLines";
import QuestionLines from "src/core/component/order/QuestionLines";
import copyToClipboard, { transformSelectionString } from "src/util/extra";
import MomentCalendarString from "src/core/component/moment-date-time/MomentCalendarString";
import ConversationItem from "src/core/component/order/ConversationItem";
import { CopyIconSvg } from "src/core/component/assets/svg/CommonSVG";
import { PocketToast } from "src/core/component/toast/PocketToast";


const OrderItem = ({order, path, isDarkMode, ...rest }) => {
    const history = useHistory();

    const handleOrderClick = (e) =>  {
        e.preventDefault();
        if((!order || order.type !== 'chat')){
            return ;
        }
        history.push(`${path}/orders/chat/${order.id}`)
    }

    const handleCopy = (order) => {
        copyToClipboard(order.name);
        PocketToast({
            'type': 'success',
            'message': 'Copied'
        })
    }

    return (
        <Card classes={`${order.type} ${isDarkMode && 'orders__item dark' || 'orders__item'}`}>
            <div className="card-body" onClick={handleOrderClick.bind(null)}>
                <div className="card-title">
                    {order.type === 'product' && order?.state && (
                        <p className={`state ${order.state}`}>
                            {order.state && (
                                <>{ transformSelectionString(order.state)}</>
                            )}
                        </p>
                    )}

                    {order && order.type === 'question' && (
                        <div className="rtp-id d-flex align-items-center">
                            <span className="name">#{order.name}</span> <span className="copy" onClick={handleCopy.bind(null, order)}><CopyIconSvg /></span>
                        </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="date">
                            {order?.created_at && (
                                <MomentCalendarString
                                    datetime={order.created_at}
                                />
                            )}
                            {/* {order?.created_at && new Date(order.created_at).format('MMM dd, yyyy hh:mm tt')} */}
                        </h3>
                        <h3 className="price">{order.amount_formatted}</h3>
                    </div>
                </div>

                <div className="card-text">
                    <div className={`order-contents`}>
                        {order.type === 'question' && (
                            <QuestionLines
                                lines={order.order_lines}
                                order={order}
                                isDarkMode={isDarkMode}
                            />
                        )}

                        {order.type === 'product' && (
                            <ProductLines
                                lines={order.order_lines}
                                order={order}
                                path={path}
                                isDarkMode={isDarkMode}
                            />
                        )}

                        {order.type === 'chat' && (
                            <ConversationItem
                                conversation={order}
                                isDarkMode={isDarkMode}
                            />
                        )}
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        {order.state === 'on_progress' && order.type === 'product' && (
                            <Link to={`/settings/order/${order.id}/track`} className="pocket-btn track-order">Track Order</Link>
                        )}

                        {order.type !== 'chat' && (
                            <Link to={`/settings/order/${order.id}`} className="view-order">View Order</Link>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default OrderItem
