import React, { useState, useEffect } from "react";

import api from "src/lib/api";
import PasswordField from "src/core/component/form/PasswordField";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { pick } from "src/util/extra";
import useAuthInfo from "src/auth/hook/auth-info";

const PasswordChangeForm = ({
  formData,
  onChange,
  handleSubmit,
  isDarkMode,
  showSubmit = false,
  ...rest
}) => {
  const { logout } = useAuthInfo();
  const [form, setForm] = useState({
    current_password: "",
    password: "",
  });

  const handlePasswordChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    let dataToSubmit = pick(form, "current_password", "password");

    if (form.current_password === form.password) {
      PocketToast({
        type: "error",
        message: "New password must be different from current password",
      });
      return;
    }

    changeUserPassword(dataToSubmit).catch((e) => {
      // console.log('Error', e);
      PocketToast({
        type: "error",
        message: "Failed to change password",
      });
    });
  };

  const changeUserPassword = async (payload) => {
    const result = await api.put(`v1/users/me/change-password`, payload);
    // console.log('Result', result);
    PocketToast({
      type: "success",
      message: "Your password has been changed, Please login again",
    });
    logout();
  };

  // console.log('Form Data', form)

  let fields = [
    {
      name: "current_password",
      placeholder: "Current Password",
      label: "Current Password",
      type: "password",
      required: true,
    },
    {
      name: "password",
      placeholder: "New Password",
      label: "New Password",
      type: "password",
      required: true,
    },
  ];

  (formData = formData || form),
    (onChange =
      typeof onChange === "function" ? onChange : handlePasswordChange);
  handleSubmit =
    typeof handleSubmit === "function" ? handleSubmit : handlePasswordSubmit;

  return (
    <form
      onSubmit={handleSubmit}
      className={"pocket-form" + ((isDarkMode && " dark") || "")}
    >
      <div className="row gx-2 gx-lg-3">
        {fields &&
          fields.map((item, index) => {
            return (
              <div className="col-12 col-sm-6 field" key={index}>
                <PasswordField
                  property={item.name}
                  showToggle={true}
                  hasLabel={true}
                  label={item.label}
                  placeholder={item.placeholder}
                  onChange={onChange}
                  value={formData[item.name]}
                  required={item.required}
                />
              </div>
            );
          })}
      </div>

      <div
        className={`submit-login-register ${
          (showSubmit && "d-flex justify-content-sm-end") || ""
        }`}
      >
        <button
          className={`pocket-btn ${(!showSubmit && "d-none") || ""}`}
          type="submit"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

export default PasswordChangeForm;
