import api from 'src/lib/api';

export async function getPromos(params) {
  if(params){
    return api.get(`v1/discount-offers?${params}`);
  }else {
    return api.get(`v1/discount-offers`);
  }
}

export async function getPromoDetails(id) {
  return api.get(`v1/discount-offers/${id}`);
}