import React from "react";

import { useTheme } from "src/theme/component/ThemeContext";
import { DarkModeSvg, LightModeSvg } from 'src/core/component/assets/svg/CommonSVG';


const ThemeSwitcher = () => {
    const [isDarkMode, toggleTheme,  setLightTheme, setDarkTheme] = useTheme();

    return (
        <div className={"settings__mobile-theme-switcher" + (isDarkMode && ' dark' || '')}>
            <p className={`theme-title ${isDarkMode && 'dark' || ''}`}>Light/Dark Mode</p>
            {/* <div className="d-flex justify-content-center align-items-center"> */}
                <div className={`d-flex justify-content-center align-items-center switcher-wrapper w-100 ${isDarkMode && ' dark' || ''}`}>
                    <div className={`light-mode switch-item text-center w-50 ${isDarkMode && 'dark' || ''} ${!isDarkMode && 'active' || ''}`} onClick={setLightTheme}>
                        <LightModeSvg />
                    </div>
                    <div className={`dark-mode switch-item text-center w-50 ${isDarkMode && 'dark' || ''} ${isDarkMode && 'active' || ''}`} onClick={setDarkTheme}>
                        <DarkModeSvg />
                    </div>
                </div>
            {/* </div> */}
        </div>  
    )
}

export default ThemeSwitcher
