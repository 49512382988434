import React from "react";

import { Link, useLocation } from "react-router-dom";


const NavLink = (props) => {
  const location = useLocation();
  let classes = props.classes;

  const isActiveLink = props.isActive || props.url == location.pathname;

  return (
      <li className={ isActiveLink ? classes + ' active' : classes }>
        <Link to={props.url} className="nav-link d-flex align-items-center justify-content-center">
          { props.children }
        </Link>
      </li>
  )
}

export default NavLink
