import React from "react";


  const Button = ({
      name, 
      classes, 
      onClick, 
      item=null,
      disabled=false,
      selectOnClick=false,
      ...rest}) => {

    const handleClick = (e) => {

      if(selectOnClick && item) {
        onClick(e,item)
      }else {
        onClick(e)
      }
    }
    
    return ( 
        <button className={classes} onClick={handleClick} disabled={disabled}>
            {name}
        </button>
    )
  }

  export default Button
