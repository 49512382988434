import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import FormCheckboxSwitch from "src/core/component/form/FormChekboxSwitch";


const FilterModal = ({
    isDarkMode, 
    showModal,
    handleModalClose,
    filter,
    setFilter,
    ...rest
}) => {

    const [filterType, setFilterType] = useState(null);
    const [filterOnProgress, setFilterOnProgress] = useState(filter?.on_progress || false);

    const handleItemClick = (item) => {
        if(filterType && filterType.value === item.value) {
            setFilterType(null)
        }else{
            setFilterType(item);
        }
    }

    const handleApplyFilter = () => {
        handleModalClose();
        setFilter({...filter, ...{
            'type': filterType?.value || '',
            'on_progress': filterOnProgress
        }})
    }

    const filterTypes = [
        {
            name: 'Physical Products',
            value: 'product'
        },
        {
            name: 'Questions',
            value: 'question'
        },
        {
            name: 'Chat',
            value: 'chat'
        }
    ]

    const handleFilterOnProgressToggle = (e) => {
        let value = e.target.checked;
        setFilterOnProgress(value);
    }

    // console.log('Filter Type', filterType);
    // console.log('Filter on Progress', filterOnProgress);
    // console.log('Filter', filter)

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"lg"} showCustomClose={false} centered={false} classes={'filter__modal'  + (isDarkMode && ' dark' || '')}>
            <ModalBody>
                <h4 className={"heading" + (isDarkMode && ' dark' || '')} >
                    Filter Orders:
                </h4>

                <div className={"row filter__modal-content g-2 g-lg-3"  + (isDarkMode && ' dark' || '')}>
                    {
                        filterTypes && filterTypes.map((item, index) => {
                
                        return (
                            <React.Fragment key={index}>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className={"filter-item" + (filterType && filterType.value === item.value && ' selected' || '') + (isDarkMode && ' dark' || '')} onClick={(e) => {handleItemClick(item)}}>
                                        {item.name}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                        })
                    }

                    {filterType && filterType.value === 'product' && (
                        <div className="col-12">
                            <form>
                                <div className={"filter-switch d-flex align-items-center justify-content-start" + (isDarkMode && ' dark' || '')}>
                                    <FormCheckboxSwitch hasLabel={true} label={'On Progress'} property={'on_progress'} value={filterOnProgress} onChange={handleFilterOnProgressToggle} />
                                </div>
                            </form>
                        </div>
                    )}

                </div>
                
                <div className="filter__modal-footer">
                    <div className="d-flex justify-content-end align-items-center">
                        <Link to="#" onClick={handleApplyFilter} className="pocket-btn">
                            Apply
                        </Link>
                    </div>
                </div>
                
            </ModalBody>
        </CommonModal>
    )
}

export default FilterModal