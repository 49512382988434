import React from "react";

import {CardSvg} from "src/core/component/assets/svg/CommonSVG";
import Card from "src/core/component/card/Card";
import CardBody from "src/core/component/card/CardBody";
import CardIcon from "src/core/component/card/CardIcon";
import {ChatSvg, DashboardCardBgBottomLeftSvg} from "src/core/component/assets/svg/connectastrologer/ConnectAstrologer"
import cardBg from "src/image/card-bg-bottom-left.svg";

const DashbordCard = ({
    isDarkMode,
    data,
    ...rest
}) => {


    return (
        <div className={`card dashboard ${isDarkMode && 'dark' || ''}`}>
            <img src={data?.cardBg} className="card-img" alt="" />
            <div className={`card-img-overlay ${isDarkMode && 'dark' || ''}`}>
                <div className="card-content d-flex flex-column align-items-start">
                    <div className="earning d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 earn-type">{data?.earn_type}</p>
                        <span className="earn-value">{data?.earn_value}</span>
                    </div>
                    <div className="total-chats d-flex align-items-center mt-auto">
                        <span><ChatSvg /></span>
                        <span className="value">{data?.chat_count}</span>
                        <span className="title">Chats</span>
                    </div>
                </div>
            </div>
        </div>

        // <div className={`card dashboard ${isDarkMode && 'dark' || ''}`}>
        //     <div className="card-bg">
        //         <DashboardCardBgBottomLeftSvg />
        //     </div>
        //     <div className="card-body">
        //         <div className="card-content d-flex flex-column align-items-start">
        //             <div className="earning d-flex align-items-center justify-content-between w-100">
        //                 <p className="mb-0 earn-type">Monthly Earning</p>
        //                 <span className="earn-value">$500</span>
        //             </div>
        //             <div className="total-chats d-flex align-items-center mt-auto">
        //                 <span><ChatSvg /></span>
        //                 <span className="value">100</span>
        //                 <span className="title">Chats</span>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default DashbordCard;
