import React from "react";
import { ChangeProfileIcon, Like2Svg, LikeGraphSvg, Reply2SVG } from "src/core/component/assets/svg/CommonSVG";
import MomentRelativeTime from "src/core/component/moment-date-time/MomentRelativeTime";
import { useHistory } from "react-router-dom";
import ShareDropdown from "src/core/component/dropdown/ShareDropdown"
import api from 'src/lib/api';
import useAuthInfo from "src/auth/hook/auth-info";
// import HelmetMetaData from "src/core/component/HelmetMetaData";

const ForumPostCard = ({item,setPosts}) => {
  const history = useHistory();
  const { isAuthenticated } = useAuthInfo();

  const handlePostClick = (e) => {
    e.preventDefault();
    history.push(`/forum/${item.id}`);
  };

  const handlePostLikeClick = async (id,target_like_action) => {
    const payload = {'type':"likes",'like':target_like_action }
    if(isAuthenticated){ 
      const result = await api.put(`v1/forum-posts/${id}/metrics`, payload);
      let updatedPost = result.data?.result?.data || null;
      updatedPost = {...updatedPost,is_like:payload.like}
      setPosts(prev=>prev.map(post=>post.id===id?updatedPost:post))
    }
  };

  return (
      <>
       {/* <HelmetMetaData 
          title={item?.title}
          description={ item?.name}
          image="https://pocketpandit.com/favicon.png">
    </HelmetMetaData> */}
            <div className="image">
              {(item?.created_by?.avatar_url && (
                <img src={item.created_by.avatar_url} />
              )) || <ChangeProfileIcon />}
            </div>
            <div className="question_content">
                <div className="question_content_header">
                    <span className="question_created_by">{item?.created_by?.screen_name || item?.created_by?.name}</span>
                    <span style={{backgroundColor:'#99756d'}} className="question_category">{item?.category?.name}</span>
                </div>
                <div className="question_content_body">
                    <span className="question_title">{item?.title}</span>
                    <span className="question_time">  {item?.created_at && (
                        <MomentRelativeTime datetime={item.created_at} />
                        )}</span>
                </div>
                <div className="question_content_stats">
                    <div className="like" onClick={()=>(handlePostLikeClick(item.id,!item.is_like))}>
                      <Like2Svg fill={item.is_like == true?"#1D5DC2":"#585858"}/> {item?.metrics?.data?.likes || 0}
                    </div>
                    <div className="reply"  onClick={handlePostClick}><Reply2SVG/> Reply</div>
                    <div className="views"><LikeGraphSvg/> {item?.views_count}</div>
                    <ShareDropdown post={item}/>
                    </div>
            </div>
        </>

  );
};

export default ForumPostCard;
