import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { Loader } from '@googlemaps/js-api-loader';

const scriptLoader = new Loader({
  'apiKey': process.env.GOOGLE_API_KEY,
  'libraries': [
    'places',
  ],
});

import { fetchTimezone } from 'src/util/google-apis';

let autoComplete;

// const loadScript = (url, callback) => {
//   let script = document.createElement("script");
//   script.type = "text/javascript";
//   script.id = "google-maps-js-api";

//   if (script.readyState) {
//     script.onreadystatechange = function () {
//       if (script.readyState === "loaded" || script.readyState === "complete") {
//         script.onreadystatechange = null;
//         callback();
//       }
//     };
//   } else {
//     script.onload = () => callback();
//   }

//   if (document.querySelector('#' + script.id) === null) {
//     script.src = url;
//     document.getElementsByTagName("head")[0].appendChild(script);
//   } else {
//     callback();
//   }
// };

function handleScriptLoad(updateQuery, autoCompleteRef) {
  if (!window.google) throw new Error('[SearchLocationInput]: Google script not loaded');
  if (!window.google.maps) throw new Error('[SearchLocationInput]: Google maps script not loaded');
  if (!window.google.maps.places) throw new Error('[SearchLocationInput]: Google maps places script not loaded');

  google.maps.event.addDomListener(autoCompleteRef.current, 'keydown', function disableFormSubmitOnEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  });

  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {}
  );
  // @see: fields reference (https://developers.google.com/maps/documentation/places/web-service/place-data-fields)
  autoComplete.setFields(["formatted_address","address_component", "geometry", "name", "utc_offset_minutes", "place_id"]);
  autoComplete.addListener("place_changed", () => {

    const place = autoComplete.getPlace();

    if (!place?.geometry?.location) { return; }

    const query = autoCompleteRef.current.value; // place.name; //place.formatted_address;
    const location = place.geometry.location;
    const addressComponents = place.address_components;
    const country_code = addressComponents.filter(addr=>addr.short_name.length==2)
    const countryLetter = country_code[country_code.length-1]?.short_name


    fetchTimezone(location.lat(), location.lng())
      .then(timezoneInfo => {
        updateQuery({
          'query': query,
          'place': {
            'latitude': location.lat(),
            'longitude': location.lng()
          },
          country_code:countryLetter,
          'timezone': timezoneInfo,
        });
      });

  });
}

function SearchLocationInput({ value, onChange, ...props }) {
  const [query, setQuery] = useState(value || '');
  const autoCompleteRef = useRef(null);
  // const SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`;

  const handleChange = (data) => {
    setQuery(data['query']);
    onChange(data);
  }

  React.useEffect(() => {

    setQuery(value);

  }, [value]);

  useEffect(() => {
    // loadScript(SCRIPT_URL,
    //   () => handleScriptLoad(handleChange, autoCompleteRef)
    // );

    //TODO: use script cache
    if (!window.google || !window?.google?.maps) {
      scriptLoader.load().then(
        () => handleScriptLoad(handleChange, autoCompleteRef)
      );
    } else {
      handleScriptLoad(handleChange, autoCompleteRef);
    }


    // return () => {
    //   document.head.removeChild(document.querySelector('#google-maps-js-api'));
    // }
  }, []);

  return (
    <input
      ref={autoCompleteRef}
      onChange={event => setQuery(event.target.value)}
      onFocus={(event) => event.target.select()}
      placeholder="Enter a City"
      value={query}
      {...props}
    />
  );
}

SearchLocationInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SearchLocationInput;
