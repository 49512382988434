import React,{useState} from "react";
import Dialog from "src/core/component/Dialog";
import {Link} from "react-router-dom";
import AdjustSettingsOverlay from "./AdjustSettingsOverlay";

const CookieSticky = ({
  accepted,
  handleAccept,
  isDarkMode,
  classes,
  ...rest
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAccept();
  };
  const hadleShowOverlay = () => {
    setShowOverlay(true);
}
const handleChange = (e) => {
  console.log('Changed...');
}

  return (
    <>
    <Dialog accepted={accepted} handleAccept={handleAccept} classes={classes}>
      <div className="cookie-sticky__content">
        <p>
          We use cookies and similar technologies to enhance your experience
          with PocketPandit. Manage your cookies &nbsp;
          <span role="button" className="text-success text-decoration-underline" onClick={hadleShowOverlay}>here</span>
        </p>
        <div className="cookie-sticky__buttons">
          <button className="btn btn-link" onClick={handleSubmit}>
            No Thanks
          </button>
          <button className="btn btn-promo" onClick={handleSubmit}>
            It's Alright
          </button>
        </div>
      </div>
    </Dialog>
     {
      showOverlay ?
      <AdjustSettingsOverlay handleSubmit={handleSubmit} onChange={handleChange} isDarkMode={isDarkMode} setShowOverlay={setShowOverlay} />
      :
      <></>
  }
  </>
  );
};

export default CookieSticky;
