import React from 'react';
import ReactDOM from 'react-dom';

import firebase from 'src/lib/firebase';

import * as serviceWorker from './serviceWorker';
import 'src/util/format-date';
import 'src/favicon/android-chrome-96x96.png';
import 'src/favicon/apple-touch-icon.png';
import 'src/favicon/favicon-16x16.png';
import 'src/favicon/favicon-32x32.png';
import 'src/favicon/favicon.ico';
import 'src/favicon/favicon.png';
import 'src/favicon/mstile-150x150.png';
import 'src/favicon/site.webmanifest';
import 'src/favicon/safari-pinned-tab.svg';

import App from 'src/core/component/App';

window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  'size': 'invisible',
  'callback': (response) => {
    // reCAPTCHA solved, allow signInWithPhoneNumber.
  },
});

ReactDOM.render(
  <App />,
  document.getElementById('app')
);

//# React strict mode not working with react material ui @material-ui library
// @see: https://stackoverflow.com/a/61561445/2071612
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('app')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
