import React from "react"

import Banner from "src/core/component/Banner"
import SearchInput from "./SearchInput";
import { FilterSvg } from "src/core/component/assets/svg/CommonSVG";


const BannerInput = ({
    isDarkMode,
    url,
    title,
    placeholder,
    hasSearch=false,
    hasFilter=false,
    handleFilterClick,
    ...rest
}) => {

    handleFilterClick = typeof handleFilterClick === 'function' ? handleFilterClick : function noop() { }
    const bannerclass = isDarkMode ? 'pocket-banner__search dark': 'pocket-banner__search'

    return (
        <Banner classes={bannerclass}>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11 col-md-10 col-lg-9">
                    <div className="text-center pocket-banner__search-title">
                        {title && (
                            <h4 className="mb-3">{title}</h4>
                        )}
                    </div>

                    {hasSearch && (
                        <div className={`d-flex align-items-center`}>
                            <SearchInput
                                isDarkMode={isDarkMode}
                                url={url}
                                placeholder={placeholder}
                                hasFilter={hasFilter}
                            />

                            {hasFilter && (
                                <div className={`filter-icon d-flex align-items-center ${isDarkMode && 'dark' || ''}`} onClick={handleFilterClick}>
                                    <FilterSvg />Filter
                                </div>
                            )}
                        </div>
                    )}

                </div>
            </div>
        </Banner>
    )
}

export default BannerInput
