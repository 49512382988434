import React, { useState } from "react";


export const useAlert = (defaultValue) => {
    const [showAlert, setShowAlert] = useState(defaultValue);

    const handleAlertClose = () => {
        setShowAlert(false);
    }

    return [showAlert, setShowAlert, handleAlertClose];
};