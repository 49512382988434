import React from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import { FirstSvg, PrevSvg, NextSvg, LastSvg } from "src/core/component/assets/svg/pagination/PaginationSvg";


const MAX_RESULTS_IN_PAGE = 50;

const Pagination = ({
  count,
  isDarkMode,
  pageSize = MAX_RESULTS_IN_PAGE,
  currentPage,
  setCurrentPage
}) => {

  currentPage = Number(currentPage);
  const lastPage = parseInt(Math.ceil(count / pageSize));
  const pages = Array.from({ length: lastPage }, (_, i) => i + 1);
  const activeClasses = `active`;
  const itemClasses = `page-link`;
  const history = useHistory();

  const handlePageChange = (newPage) => {
    const query = queryString.parse(history.location.search);
    query.page = newPage;
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(query),
    });

    setCurrentPage(newPage);
  };


  return (
    pages.length > 1 && (
      <nav className={`d-flex w-100 my-1 pocket-pagination__nav ${isDarkMode && 'dark' || ''}`} aria-label="Page navigation">
        <ul className={`mx-auto text-center d-flex list-unstyled mb-0 pocket-pagination__list ${isDarkMode && 'dark' || ''}`}>
          {currentPage !== 1 && (
            <li className={`pocket-pagination__list-item ${isDarkMode && 'dark' || ''}`}>
              <a
                className={itemClasses}
                href="#"
                aria-label="First"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(1);
                }}
              >
                <FirstSvg />
              </a>
            </li>
          )}

          {currentPage > 2 && (
            <li className={`pocket-pagination__list-item ${isDarkMode && 'dark' || ''}`}>
              <a
                className={itemClasses}
                href="#"
                aria-label="Previous"
                onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(currentPage - 1);
                }}
              >
                <PrevSvg />
              </a>
            </li>
          )}

          {pages.length > 0 &&
            pages.map((page, index) => {
              if (Math.abs(page - currentPage) < 2) {
                return (
                  <li key={index} className={`pocket-pagination__list-item ${isDarkMode && 'dark' || ''}`}>
                    <a
                      className={`${itemClasses} ${currentPage === page ? activeClasses : ""}`}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange(page);
                      }}
                    >
                      {page}
                    </a>
                  </li>
                );
              }
            })}

          {currentPage < lastPage - 1 && (
            <li className={`pocket-pagination__list-item ${isDarkMode && 'dark' || ''}`}>
              <a
                className={itemClasses}
                href="#"
                aria-label="Next"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(currentPage + 1);
                }}
              >
                <NextSvg />
              </a>
            </li>
          )}

          {currentPage !== lastPage && (
            <li className={`pocket-pagination__list-item ${isDarkMode && 'dark' || ''}`}>
              <a
                className={itemClasses}
                href="#"
                aria-label="Last"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(lastPage);
                }}
              >
                <LastSvg />
              </a>
            </li>
          )}
        </ul>
      </nav>
    )
  );
};

export default Pagination;

Pagination.defaultProps = {
  setCurrentPage: () => null
};
