import React from "react";
import { LockIconSvg } from "src/core/component/assets/svg/CommonSVG";
import { StripeSvg } from "src/core/component/assets/svg/CommonSVG";
import {CreditCardSvg} from "src/core/component/assets/svg/AppStoreSVG"

const ContentHeader = ({ isDarkMode, ...rest }) => {
  return (
    <>
    <div
      className={
        "rtp-payment-header d-flex justify-content-between align-items-center d-none d-sm-flex" +
        ((isDarkMode && " dark") || "")
      }
    >
      <p className="card-info-title">Credit card information</p>
      <p className="secure-service d-flex align-items-center">
        <LockIconSvg />
        Secure Service
      </p>
    </div>
    <div className={"rtp-payment-debit-or-credit d-flex justify-content-between"+((isDarkMode && " dark") || "")}>
      <div className="d-flex align-items-center ">      
    <div className="credit-or-debit d-flex justify-content-mdlg-end">
        <CreditCardSvg />
      </div>
      <span>Credit or debit card</span> 
      </div>
      <div className="powered-by d-flex justify-content-mdlg-end">
        <StripeSvg />
      </div>
    </div>
    </>
  );
};

export default ContentHeader;