import React from "react";
import { Link } from "react-router-dom";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import Amounts from "./Amounts";
import CartEmpty from "./CartEmpty";
import SelectedQuestions from "./SelectedQuestions";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { videoElement } from "src/core/component/googleAds/GoogleAds";

const OrderSummary = ({
  isDarkMode,
  order,
  orderLines,
  handleLineDelete,
  tax,
  isProcessing,
  deletingLineId,
}) => {
  const { rtpOrder } = useRTPInfo();
  const {profile } = useAuthInfo();

  const handleAdsPlay = ()=>{
    if (!window?.google?.ima){
      PocketToast({type: "error",message: 'Please Disable Ads Block'})
      return 0
    }
    setTimeout(()=>{videoElement.play()},800)
  }

  return (
    <>
      <div className="col-mdlg-6 order-1 order-mdlg-2">
        <div className={"order-summary" + ((isDarkMode && " dark") || "")}>
          <h5 className="title d-none d-sm-flex">
            Order Summary
            {(rtpOrder?.name && (
              <span className="order-summary-id">{rtpOrder?.name}</span>
            )) ||
              ""}
          </h5>

          {/* <p className="heading d-none d-sm-block">Real-Time Predictions</p> */}

          {!orderLines ||
            (orderLines.length <= 0 && (
              <>
                <CartEmpty />
                <p className="separator mt-2 d-none d-sm-block"></p>
              </>
            ))}

          {orderLines && orderLines.length > 0 && (
            <>
              <SelectedQuestions
                orderLines={orderLines}
                order={order}
                handleRemoval={handleLineDelete}
                isDarkMode={isDarkMode}
                isProcessing={isProcessing}
                deletingLineId={deletingLineId}
              />

              <Amounts
                orderLines={orderLines}
                order={order}
                tax={tax}
                isDarkMode={isDarkMode}
              />
            </>
          )}
        </div>
        {
          profile.admin && 
          <button className="btn btn-primary" onClick={handleAdsPlay}>Get Reward Points</button>
        }
      </div>

      <div className="col-12 order-3 d-none d-mdlg-block">
        <div className="coupon d-flex align-items-center justify-content-between my-3">
          <Link
            to="/instant-prediction"
            className={
              "prev-link" +
              ((isDarkMode && " dark") || "") +
              ((isProcessing && " disabled") || "")
            }
          >
            Back
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
