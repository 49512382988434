import React, { useState, useEffect } from "react";
import { Redirect  } from "react-router-dom";

import useAuthInfo from "src/auth/hook/auth-info";
import api from 'src/lib/api';
import { PocketToast } from "src/core/component/toast/PocketToast";
import { ChatSvg } from "src/core/component/assets/svg/connectastrologer/ConnectAstrologer"
import cardMonthlyBg from "src/image/card-bg-top-right.svg";
import cardYearlyBg from "src/image/card-bg-bottom-left.svg";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import DashbordCard from "src/astrologer/component/DashboardCard";


const Dashboard = ({path, isAuthenticated, isDarkMode, ...rest }) => {
    if (!isAuthenticated) {
        return <Redirect to="/auth/login" />
    }

    const { profile } = useAuthInfo();
    const [loading, setLoading] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [astrologer, setAstrologer] = useState(null);
    const [totalChats, setTotalChats] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [groupedInvoices, setGroupedInvoices] = useState({});
    const [groupedConversations, setGroupedConversations] = useState({});
    const [monthlyData, setMonthlyData] = useState({
        earn_type: 'Monthly Earning',
        earn_value: 0,
        chat_count: 0,
        cardBg: cardMonthlyBg
    });
    const [yearlyData, setYearlyData] = useState({
        earn_type: 'Yearly Earning',
        earn_value: 0,
        chat_count: 0,
        cardBg: cardYearlyBg
    });

    useEffect(() => {
        fetchDashboardData();
    }, [])

    const fetchDashboardData = async () => {
        setLoading(true)
        try {
            const result = await api.get(`/v1/astrologers/dashboard`);
            console.log('Result', result);
            const astrologerData = result.data?.result?.data?.dashboard?.astrologer || null;
            const conversationData = result.data?.result?.data?.dashboard?.conversations || [];
            const invoiceData = result.data?.result?.data?.dashboard?.invoices || [];
            setAstrologer(astrologerData);
            setConversations(conversationData);
            setInvoices(invoiceData);
            setLoading(false);
        }
        catch (error) {
            PocketToast({
                type: "error",
                message: "Error on fetching dashboard data."
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(conversations.length) {
            let totalIncome = conversations && conversations.reduce((acc, next)=> acc += next.amount, 0);
            let totalChats = conversations.length;
            setTotalChats(totalChats);
            setTotalIncome(totalIncome);
        }
    }, [conversations])

    useEffect(() => {
        if(conversations.length){
            let totalYears = [];
            let totalMonths = [];
            conversations.forEach((conv) => {
                let year = new Date(conv.created_at).format('yyyy');
                let yearMonth = new Date(conv.created_at).format('yyyy-MM');

                if(!totalYears.includes(year)) {
                    totalYears.push(year)
                }

                if(!totalMonths.includes(yearMonth)){
                    totalMonths.push(yearMonth)
                }
            })

            let yearlyIncome = Math.ceil(totalIncome/totalYears.length);
            let monthlyIncome = Math.ceil(totalIncome/totalMonths.length);
            let yearlyChatCount = Math.ceil(totalChats/totalYears.length);
            let monthlyChatCount = Math.ceil(totalChats/totalMonths.length);

            setMonthlyData(curr=>({
                ...curr,
                'chat_count': monthlyChatCount,
                'earn_value': monthlyIncome
            }));
            setYearlyData(curr=>({
                ...curr,
                'chat_count': yearlyChatCount,
                'earn_value': yearlyIncome
            }));

        }
    }, [conversations, totalChats, totalIncome])

    useEffect(() => {
        if(conversations.length > 0) {
            const grouped = {};
            conversations.forEach((conv) => {
                let year = new Date(conv.created_at).format('yyyy');
                let month = new Date(conv.created_at).format('M');
                let day = new Date(conv.created_at).format('d');
                grouped[year] = grouped[year] || [{}];
                grouped[year][0][month] = grouped[year][0][month] || [{}];
                grouped[year][0][month][0][day] = grouped[year][0][month][0][day] || [];
                grouped[year][0][month][0][day].push(conv);
            })
            setGroupedConversations(grouped)
        }
    }, [conversations])


    // useEffect(() => {
    //     if(groupedConversations){
    //         let totalYears = (Object.keys(groupedConversations) || []).length;
    //         console.log(totalIncome, totalChats, totalYears);

    //     }
    // }, [groupedConversations])

    // console.log('Grouped Conversations', groupedConversations);
    // console.log('Astrologer', astrologer);
    // console.log('Conversations', conversations);
    // console.log('Invoices', invoices);
    // console.log('Total income', totalIncomes);

    return (
        <div className={"astrologer-dashboard dashboard" + (isDarkMode && ' dark' || '')}>
            <div className="astrologer-dashboard__contents">
                {loading && (
                    <div className="row">
                        <div className="col">
                            <LoadingIcon />
                        </div>
                    </div>
                )}

                {!loading && (
                    <>
                        <div className="row gx-md-2 gx-lg-3 gx-xl-4">
                            {monthlyData && (
                                <div className="col-md-6 dashboard-card">
                                    <DashbordCard isDarkMode={isDarkMode} data={monthlyData} />
                                </div>
                            )}

                            {yearlyData && (
                                <div className="col-md-6 dashboard-card">
                                    <DashbordCard isDarkMode={isDarkMode} data={yearlyData} />
                                </div>
                            )}

                        </div>

                        <div className={`astrologer-dashboard__contents-summary ${isDarkMode && 'dark' || ''}`}>
                            <div className="row gx-2 gx-sm-1 gx-md-2 gx-lg-3">
                                {/* <div className="col-sm-9 offset-sm-3">
                                    <div className="title">Your Chat Stats</div>
                                </div> */}
                                <div className="col-12">
                                    <div className="title">Your Chat Stats</div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="chat-counts text-center">
                                        <div className="d-flex justify-content-center align-items-center title">
                                            <ChatSvg />
                                            <span className="text">Total Text Chat</span>
                                        </div>
                                        <div className="value">
                                            {totalChats}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="total-earnings">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="earnings">
                                                <div className="title">Total Earning</div>
                                                <div className="value">{totalIncome}</div>
                                            </div>
                                            <div className="withdraw d-none">
                                                <button className="pocket-btn withdraw" disabled>
                                                    Withdraw
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Dashboard
