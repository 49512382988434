import React from "react";

import FeaturedPromoSlider from "src/core/component/promos/FeaturedPromoSlider";

const PromosSlider = ({ promosData, isDarkMode, ...rest }) => {
  return (
    <div className={"container" + ((isDarkMode && " dark") || "")}>
      <div className={"pocket-promos__content" + ((isDarkMode && " dark") || "")}>
        <FeaturedPromoSlider
          promosData={promosData} isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default PromosSlider;
