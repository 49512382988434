import React from "react";
import { Link } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";


const TabManager = ({tab, setTab, isDarkMode, ...rest}) => {

    const handleTabClicked = (clickedTab) => {
        setTab(clickedTab);
    }

    return (
        <div className={"register-login__tabs" + (isDarkMode && ' dark' || '')}>
            <div className={"d-flex justify-content-start align-items-center tab-menu" + (isDarkMode && ' dark' || '')}>
                <div className={"tab-item" + (tab === 'register' && ' active' || '') + (isDarkMode && ' dark' || '')} onClick={()=>{handleTabClicked('register')}}>Signup</div>
                <div className={"tab-item" + (tab === 'login' && ' active' || '') + (isDarkMode && ' dark' || '')} onClick={()=>{handleTabClicked('login')}}>Login</div>
            </div>

            {
                tab === 'register' && 
                <Register 
                    setTab={setTab} 
                    isDarkMode={isDarkMode} 
                />
            }

            {
                tab === 'login' && 
                <Login 
                    setTab={setTab} 
                    isDarkMode={isDarkMode} 
                />
            }

            <div className="back-btn my-3">
                <Link to="/forum" className={"prev-link" + (isDarkMode && ' dark' || '')}>
                    Back
                </Link>
            </div>

        </div>
        
    )
}

export default TabManager