import React from "react";
import { DoneSvg } from "src/core/component/assets/svg/SupportSVG";


const SupportStatus = ({support, isDarkMode, ...rest}) => {

    return (
        <div className={`pocket-customer-support__support-item-status ${isDarkMode && 'dark' || ''}`}>
            <div className="container-xl">
                <div className={`support-status ${support.state} ${(support.state === 'running' || support.state === 'done') && 'd-flex align-items-center justify-content-between' || ''}`}>
                    <div className={`info ${support.state}`}>
                        <p>Ticket ID: <span className="support-token">{`#${support?.support_token}`}</span></p>
                        <span className="created-at">{new Date(support.created_at).format('MM/dd/yyyy')}</span>
                    </div>
                    {(support.state === 'running' ||  support.state === 'done') && (
                        <div className="state d-flex align-items-center">
                            {support.state === 'running' && (
                                <span className="running">On Progress</span>
                            )}

                            {support.state === 'done' && (
                                <span className="done"><DoneSvg />Done</span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SupportStatus;
