import React from 'react'
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import api from 'src/lib/api'
import { truncate } from 'src/util/extra';
import useAuthInfo from 'src/auth/hook/auth-info';
import useDependentState from 'src/core/hook/useDependentState';
import { useSocket } from 'src/socket/component/SocketContext';

export default function ChatConversationList(props) {

  let { items: defaultItems, reload, onSelectItem } = props;

  const socket = useSocket();
  const location = useLocation();
  const history = useHistory();
  const [activeItem, setActiveItem] = React.useState(null);
  const authInfo = useAuthInfo();
  const [items, setItems] = useDependentState(_s => _s, [defaultItems]);

  onSelectItem = typeof onSelectItem === 'function' ? onSelectItem : () => { }
  reload = typeof reload === 'function' ? reload : () => { }

  React.useEffect(() => {

    const onMessageReceive = ({ data: message }) => {
      setItems(state => {

        const index = state.findIndex(s => s.id == message.conversation_id);
        const conversation = state[index];

        if (!conversation) { return state; }

        conversation.last_message = message;

        return [
          ...state.slice(0, index),
          conversation,
          ...state.slice(index + 1),
        ];
      });
    }

    const onConversationCreate = ({ data: conversation }) => {
      setItems(state => ([
        conversation,
        ...state.filter(s => s.id != conversation.id),
      ]));
    }

    const onConversationRemove = ({ data: conversation_id }) => {

      setItems(state => ([
        ...state.filter(s => s.id != conversation_id),
      ]));
    }

    socket.on('chat_message:create', onMessageReceive);
    socket.on('chat_conversation:create', onConversationCreate);
    socket.on('chat_conversation:remove', onConversationRemove);

    return () => {
      socket.off('chat_message:create', onMessageReceive);
      socket.off('chat_conversation:create', onConversationCreate);
      socket.off('chat_conversation:remove', onConversationRemove);
    }

  }, []);

  React.useEffect(() => {

    const onConversationRenew = async ({ data }) => {

      try {
        const res = await api.get('v1/conversations/' + data.conversation_id);
        const conversationList = (res.data.result?.data || []);

        if (activeItem?.id === data.conversation_id) {
          setActiveItem(conversationList);
        }

        setItems(state => {

          const index = state.findIndex(s => s.id == data.conversation_id);
          const conversation = state[index];

          if (!conversation) { return [conversationList, ...state]; }

          return [
            ...state.slice(0, index),
            conversationList,
            ...state.slice(index + 1),
          ];
        });
      } catch (error) {
        console.log('Error fetching conversation', error);
      }
    }

    socket.on('chat_conversation:renew', onConversationRenew);

    return () => {
      socket.off('chat_conversation:renew', onConversationRenew);
    }

  }, [activeItem]);

  React.useEffect(() => onSelectItem(activeItem), [activeItem]);

  React.useEffect(() => {

    const search = new URLSearchParams(location.search);
    const conversation = search.get('c');
    const found = items.find(i => i['name'] == conversation);

    if (items.length && !found) {
      const first = items[0];
      history.push({
        search: '?c=' + first.name,
      });
    }

    setActiveItem(found);

  }, [location, items]);

  const handleRemoveItem = async (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {

      if (activeItem.id == item.id) {

        const itemIndex = items.findIndex(i => i.id == item.id);
        const nextItem = items[itemIndex + 1] || items[itemIndex - 1];

        if (nextItem) {
          history.push({
            'search': '?c=' + nextItem['name'],
          });
        }
      }

      setItems([
        ...items.filter(i => i.id != item.id)
      ]);
      await api.delete('v1/conversations/' + item.id);
      reload();
    } catch (error) {
      console.log('Error removing conversation', error);
    }
  }


  // if (items.length && !activeItem) {
  //   const firstItem = items[0];
  //   return (
  //     <Redirect to={{
  //       pathname: location.pathname,
  //       search: '?c=' + firstItem['name']
  //     }} />
  //   )
  // }onSelectItem = typeof onSelectItem === 'function' ? onSelectItem : () => { }

  if (!items.length) { return props.children || null; }

  // console.log('items', items)

  return (
    <div className="list-group list-group-flush">
      {items.map((item) => {
        const [otherUser] = item.users.filter(u => u.id != authInfo?.profile?.id);
        const isReply = item.last_message?.user?.id != authInfo?.profile?.id;

        return (
          <Link key={item.name} className={"list-group-item list-group-item-action py-2 px-2" + (item.id == activeItem?.id && ' active' || '')} to={{ search: `?c=${item.name}` }}>

            <div className="d-flex">
              <div className="flex-shrink-0 d-flex align-items-center">
                <img className="img-responsive rounded-circle" width="40" src={otherUser?.avatar_url} />
              </div>
              <div className="flex-grow-1 ms-3">

                <div className="clearfix">
                  <ul className="list-inline my-0 float-end">
                    <li className="list-inline-item small-text">
                      <i className="fa fa-fw fa-calendar-o"></i>
                      {new Date(item.last_message?.created_at || item.created_at).format('MMM dd')}
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-sm btn-outline-danger" onClick={handleRemoveItem.bind(null, item)}>
                        <i className="fa fa-fw fa-trash-o"></i>
                        <strong><big>&times;</big></strong>
                      </button>
                    </li>
                  </ul>

                  <div className="float-start">
                    <h6 className="my-0 small-text">
                      <strong>{otherUser?.name}</strong>
                    </h6>
                    <span className='small-text'>{otherUser?.login}</span>
                  </div>
                </div>

                <ul className="list-inline mt-2 mb-0 small-text float-end">
                  {(item.last_message?.content && (<>

                    <li className="list-inline-item">
                      <i className="fa fa-fw fa-clock-o"></i>
                      <span> {new Date(item.last_message?.created_at).format('hh:mm tt')} </span>
                    </li>

                    <li className="list-inline-item"> <strong>&bull;</strong> </li>

                    <li className="list-inline-item">
                      {isReply
                        && (<i className="fa fa-fw fa-reply"></i>)
                        || (<i className="fa fa-fw fa-envelope-o"></i>)
                      }
                      <span> {truncate(item.last_message?.content, 20)} </span>
                    </li>

                  </>))}
                </ul>
              </div>
            </div>

          </Link>
        )
      })
      }
    </div >
  )
}
