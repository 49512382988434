import React from "react";
import { Link } from "react-router-dom";

import MainLayout from "src/core/component/MainLayout";
// import { HomeSvg } from "src/core/component/assets/svg/CommonSVG";
// import notFoundCover from "src/image/404_cover.gif";
import notFoundBannerImg from "src/image/404_banner.png";

export default function NotFoundPage() {
  return (
    <MainLayout className={"not-found"}>
      <section
        className="pocket-404"
        style={{
          backgroundImage: `url(${notFoundBannerImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="content">
                <h3 className="title">
                  You are out of bound<span>!</span>
                </h3>
                <div className="text">
                  The page you are trying to access is not available
                </div>
                <Link to="/" className={`d-inline-block pocket-btn`}>
                  Go Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
