import React from "react";

import MainLayout from "src/core/component/MainLayout";
import AboutUs from "../about-us/AboutUs";

const AboutUsPage = () => {
  return (
    <MainLayout>
      <AboutUs />
    </MainLayout>
  )
}

export default AboutUsPage;
