import React from "react";
import { Modal } from "react-bootstrap";

function DeleteModal({
  show = false,
  buttonParentClass = "",
  handleClose = () => {},
  handleCloseText = "No, Thanks",
  handleCloseClass = "",
  handleProceed = () => {},
  handleProceedText = "Delete Profile",
  handleProceedClass = "",
  className = "",
  children,
}) {
  return (
    <Modal
      show={show}
      className={className}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer className={buttonParentClass}>
        <button className={`btn ${handleProceedClass}`} onClick={handleProceed}>
          {handleProceedText}
        </button>
        <button className={`btn ${handleCloseClass}`} onClick={handleClose}>
          {handleCloseText}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
