import React from "react";
import { Link } from "react-router-dom";

const LinkItem = ({name, url,classes, children, ...rest}) => {
  
  return (
        <Link to={url} className={classes} {...rest}>
            {
              name 
            }
            
            {
              children
            }
        </Link>
  )
}

export default LinkItem
