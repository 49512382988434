import React from "react";

import CircleImage from "src/core/component/birthprofile/CircleImage";
import PocketRatings from "src/core/component/rating/PocketRatings";


const RatingCard = ({
    isDarkMode,
    item,
    ...rest
}) => {

    return (
        <div className={`card rating ${isDarkMode && 'dark' || ''}`}>
            <div className="card-body">
                <div className="card-text">
                    <div className={`rating-info d-flex align-items-center justify-content-between ${isDarkMode && 'dark' || ''}`}>
                        <div className="d-flex align-items-center rater">
                            <div className="rater-image">
                                <CircleImage
                                    url={item.created_by.avatar_url}
                                    showIconOverlay={false}
                                    isDarkMode={isDarkMode}
                                    showIsOnline={false}
                                />
                            </div>
                            <div className="rater-name">{item.created_by.name}</div>
                        </div>

                        {item.rating && (
                            <div className={`ratings ${isDarkMode && 'dark' || ''}`}>
                                <PocketRatings
                                    ratings={+item.rating}
                                    isDarkMode={isDarkMode}
                                    readOnly={true}
                                />
                            </div>
                        )}

                    </div>

                    <div className={`review ${isDarkMode && 'dark' || ''}`}>
                        {item.review}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatingCard
