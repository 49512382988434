import React from "react";

import {AngleDownSvg} from "src/core/component/assets/svg/CommonSVG";
import CheckboxField from "../../../core/component/form/CheckboxField";

const fields = [
  {
    id: 1,
    name: "category",
    label: "Yantra",
    type: "checkbox",
  },
  {
    id: 2,
    name: "category",
    label: "Rudraksha",
    type: "checkbox",
  },
  {
    id: 3,
    name: "category",
    label: "Mala",
    type: "checkbox",
  },
  {
    id: 4,
    name: "category",
    label: "Jadi",
    type: "checkbox",
  },
  {
    id: 5,
    name: "category",
    label: "FengShui",
    type: "checkbox",
  },
  {
    id: 6,
    name: "category",
    label: "Premium",
    type: "checkbox",
  },
];

const CategoryList = ({className = "", style = {}}) => {
  return (
    <div className={"gbt-shop__filter-price__category " + className} style={style}>
      <h5>
        Categories

        <button className="category-toggle">
          <AngleDownSvg />
        </button>
      </h5>

      {
        (fields.length || null) && fields.map((field) => {
          return (
            <CheckboxField key={field.id} property={field.name} id={"inline-checkbox-" + field.id}>
              <label htmlFor={"inline-checkbox-" + field.id} className="form-check-label">{field.label}</label>
            </CheckboxField>
          )
        })
      }
    </div>
  )
}

export default CategoryList;
