import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollView from "src/core/component/ScrollView";

import * as north from './north-styles';
import * as north2 from './north-styles-2';
import * as south from './south-styles';
import * as south2 from './south-styles-2';
import * as east from './east-styles';
import * as east2 from './east-styles-2';
import jsonToFormData from 'src/util/json-to-form-data'

const WIDTH = 400;
const HEIGHT = 280;

const switchVariant = (variant,transitGhar) => {
  return { 'north': transitGhar? north2: north,
           'south': transitGhar? south2: south, 
           'east' : transitGhar? east2 : east  }[variant];
}

//  ------------------------------draw kundali ------------------------------
const drawKundaliLines = (ctx,kundali_path2D, color = 'black', lineWidth = 1,clickedGhar,firstHouse,variant,activeHouses,from) => { 
  let newKundali_path2D = kundali_path2D;
  if(variant !='north'){
    if(newKundali_path2D.length<=12){
      let cloneKundali_path2D = kundali_path2D.slice();
      let slicedPath = cloneKundali_path2D.splice(0,firstHouse)
      newKundali_path2D = [...cloneKundali_path2D,...slicedPath]
    }else{
      let cloneKundali_path2D1 = kundali_path2D.slice();
      let cloneKundali_path2D2 = kundali_path2D.slice();
      let after12 = cloneKundali_path2D1.splice(0,12)
      let first12 = cloneKundali_path2D2.splice(12,24);
      let slicedPath12 = after12.splice(0,firstHouse)
      let slicedPath24 = first12.splice(0,firstHouse)
      newKundali_path2D = [...after12,...slicedPath12,...first12,...slicedPath24]
    }
  }
  newKundali_path2D.map((path, index) => {
  ctx.strokeStyle = color;
  if(index==clickedGhar){
    ctx.fillStyle = '#d4936960';
    ctx.fill(path);
  }
  if(from == 'real-prediction'){
    activeHouses.map(h=>{
      if(index == h.code-1){
        ctx.fillStyle = '#d4936960';
        ctx.fill(path);
      }
    })
  }
  ctx.lineWidth = lineWidth;
  ctx.stroke(path);
})
};

export const Ghar = ({ variant,transitGhar,onClickKundaliDetailView, sthaana, sthaanaStyle, grahas, grahaStyle, code, codeStyle }) => {
  const variantStyle = switchVariant(variant,transitGhar);

  return (
    <>
      <div style={grahaStyle} className={`graha-info ${code}`}>
        <div style={variantStyle.textStyle}>
          {grahas.map((d, i) => (
            <span key={i} style={variantStyle.letterStyle} onClick={()=>onClickKundaliDetailView(d)} className="letter"> {d} </span>
          ))}
        </div>
      </div>
      <div style={sthaanaStyle} className={`sthaan-zodiac ${code}`}>
        <div style={variantStyle.zodiacStyle} className={`zodiac-icon ${code}`}  onClick={()=>onClickKundaliDetailView('Rasi no: '+sthaana)}>
          {sthaana}
        </div>
      </div>
      <div style={codeStyle} className={`planet-code ${code}`}>
        <div style={variantStyle.zodiacStyle}  onClick={()=>onClickKundaliDetailView('code '+code)}>
          <span style={variantStyle.letterStyle} className="letter">{code}</span>
        </div>
      </div>
    </>
  );
};
export const TrasitGhar = ({ variant,transitGhar,onClickKundaliDetailView, sthaana, sthaanaStyle, grahas, grahaStyle, code, codeStyle }) => {
  const variantStyle = switchVariant(variant,transitGhar);

  return (
    <>
      <div style={grahaStyle} className={`graha-info ${code}`}>
        <div style={variantStyle.transitTextStyle}>
        {grahas.map((d, i) =>   (
            <span key={i} style={variantStyle.letterStyle} onClick={()=>onClickKundaliDetailView(d)} className="letter"> {d} </span>
            ))}
        </div>
      </div>
      {/* <div style={sthaanaStyle} className={`sthaan-zodiac ${code}`}>
        <div style={variantStyle.zodiacStyle} className={`zodiac-icon ${code}`}  onClick={()=>onClickKundaliDetailView('Rasi no: '+sthaana)}>
          {sthaana}
        </div>
      </div> */}
      <div style={codeStyle} className={`planet-code ${code}`}>
        <div style={variantStyle.zodiacStyle}  onClick={()=>onClickKundaliDetailView('code '+code)}>
          <span style={variantStyle.letterStyle} className="letter">{code}</span>
        </div>
      </div>
    </>
  );
};

Ghar.propTypes = {
  variant: PropTypes.string.isRequired,
  // sthaana: PropTypes.string.isRequired,
  sthaana: PropTypes.node.isRequired,
  // grahas: PropTypes.arrayOf(PropTypes.string).isRequired,
  grahas: PropTypes.node.isRequired,
  code: PropTypes.node,
  grahaStyle: PropTypes.object.isRequired,
  sthaanaStyle: PropTypes.object.isRequired,
  codeStyle: PropTypes.object
}

const Kundali = React.memo(({
  className = 'Kundali',
  data,
  transitData,
  response=[],
  variant = 'north',
  transitGhar,
  xScale = WIDTH,
  sthaanaStyle = {},
  grahaStyle = {},
  color = 'black',
  lineWidth = 1,
  children,
  isDarkMode,
  from='free-astrology',
  activeHouses = [],
  firstHouses = []
}) => {

  const firstHouse = response.length>0 ? response[0]?.zodiac?.code : (firstHouses.length>0 && from == 'real-prediction')?firstHouses[0]?.zodiac?.code : 0

  const [clickedGhar,setClickGhar] = useState(-1)
  let newTransitData = transitData

  function findTransitDataStartPosition(dataPos) {
    let transitSlicePos = 13;
    transitData.map((tData,index)=>{if(tData[2] == dataPos){transitSlicePos= index}})
    return transitSlicePos
  }

  function reorderTransitData(){
    if(variant === 'north'){
      let brithDataStartsWith = data[0][2]
      let brithTransitDataStartsWithPosition = findTransitDataStartPosition(brithDataStartsWith)
      let cloneTransitData = transitData.slice();
      let sliceTransitData = cloneTransitData.splice(0,brithTransitDataStartsWithPosition)
      newTransitData = [...cloneTransitData,...sliceTransitData]
    }
  }

reorderTransitData();

  React.useEffect(() => {
    // may not work, there is bug when clicked from south or east to north.
    reorderTransitData();
  },[variant])


  const [isChartDetailView, setIsChartDetailView] = React.useState(false);
  const [isChartViewScroll, setIsChartViewScroll] = React.useState(77);
  const [currentHouseClicked, setCurrentHouseClicked] = React.useState('');
  const [isResize, setIsResize] = React.useState(false);
  const canvasRef = React.useRef();
  const scale = xScale / WIDTH || 1;
  const variantStyle = switchVariant(variant,transitGhar);
  let ctx = canvasRef?.current?.getContext('2d');
  let kundali_path2D = []

 const KundaliResize = () => {setIsResize(!isResize);}

  React.useEffect(() => {
    window.addEventListener("resize", KundaliResize);
    return () => {
      window.removeEventListener("resize", KundaliResize);
    };
  }, []);

  window.addEventListener("resize", () => {reDrawKundaliOnVariantChange()})

  window.addEventListener("scroll", () => {
    if (window.pageYOffset >=0 && window.pageYOffset < 77) {
        setIsChartViewScroll(77 - window.pageYOffset);
    }else {
      setIsChartViewScroll(0);}
  })

  const reDrawKundaliOnVariantChange = () => {
    kundali_path2D.map((path, index) => {kundali_path2D[index] = new Path2D();})
  }

  const calculateUnder12 = (num)=>{
    if(num>=12){
      num+=12
    }
  let calculateNumer = num - firstHouse;
  if(calculateNumer<0){
    calculateNumer+=12
  }else if(calculateNumer>=24){
    calculateNumer-=12
  }
    return calculateNumer
}
  const handleClickEvent = (e) => {
    kundali_path2D.map((path, index) => {
      if(index === 0 && ctx.isPointInPath(kundali_path2D[index], e.offsetX, e.offsetY)) {
        if(variant == 'north'){
          onClickKundaliDetailView('House '+(index+1));
          // setClickGhar(index)
        }else{
          onClickKundaliDetailView('House '+(calculateUnder12(index)+1));
          // setClickGhar(calculateUnder12(index))
        }
      }
      else if(ctx.isPointInPath(kundali_path2D[index], e.offsetX, e.offsetY)){
        if(variant == 'north'){
        onClickKundaliDetailView('House '+(((index)%12)+1));
        // setClickGhar(index)
        }else{
          onClickKundaliDetailView('House '+(calculateUnder12(((index)%12))+1));
          // setClickGhar(calculateUnder12(index))
        }
    }
})
}

  const onClickKundaliGhar = () => {
    //click sometime works and dont, if resize and zoom
    // canvasRef.current && canvasRef.current.removeEventListener('click', handleClickEvent);
    canvasRef.current && canvasRef.current.addEventListener('click', handleClickEvent);
  }

    const onClickKundaliDetailView = (ctx) => {
      setCurrentHouseClicked(ctx)
      if(from == 'free-astrology'){
        setIsChartDetailView(true)
      }
    }

  React.useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.beginPath();
    ctx.moveTo(0, 0);

    kundali_path2D = []

    kundali_path2D = [...Array(variantStyle.kundaliLines1(WIDTH.toFixed(0), HEIGHT.toFixed(0)).length)].map((_, i) => new Path2D())
    reDrawKundaliOnVariantChange();

    variantStyle.kundaliLines1(WIDTH.toFixed(0), HEIGHT.toFixed(0)).map((line, index) => {
        kundali_path2D[index].moveTo(line.move[0]*scale, line.move[1]*scale);
        kundali_path2D[index].lineTo(line.line1[0]*scale, line.line1[1]*scale);
        kundali_path2D[index].lineTo(line.line2[0]*scale, line.line2[1]*scale);
        line.hasOwnProperty('line3') && kundali_path2D[index].lineTo(line.line3[0]*scale, line.line3[1]*scale);
        kundali_path2D[index].lineTo(line.move[0]*scale, line.move[1]*scale);
  })
    ///draw kundali
    drawKundaliLines(ctx,kundali_path2D, color, lineWidth,clickedGhar,firstHouse,variant,activeHouses,from);
    from == 'free-astrology' && onClickKundaliGhar();
    return () => {
      // canvasRef.current && canvasRef.current.removeEventListener('click', handleClickEvent);
    }
  }, [variant, xScale, sthaanaStyle, grahaStyle, color, lineWidth,isResize,clickedGhar]);

  const styles = variantStyle.gharaStyles(scale);
  const styles2 = transitGhar && variantStyle.gharaStyles2(scale);

  const onChartDetailViewClose = () => {
    setIsChartDetailView(false)
    // setCurrentHouseClicked(0)
  }

  return (
    <>
    { isChartDetailView  && false && (
    <div className='chart-detail-view' style={{top:isChartViewScroll}}>
        <div className={`chart-detail-view-content ${isDarkMode && 'bg-dark text-white' || ''}`}>
            <ScrollView className="vh-lg-100 pe-lg-3">
                <div className="chart-detail-view-content-title">{currentHouseClicked}</div>
                <span className='chart-detail-view-content-desc'>The first house is also known as Ascendant in Vedic Astrology. It is the house of behavior, as well as represents your likes and dislikes. The first house explains the personality, personal traits, and physical structure of an individual. The first house replicates the person that he is going to become, and how we evolve with time.  Not only this, but it also projects the overall destiny and the life span of an individual.</span>
                <span className='chart-detail-view-content-desc'>The first house reveals how you portray yourself in front of others and what others think of you. The meaning of the first house in Astrology determines many characteristics that we demonstrate through our identity on many different occasions.</span>
                <div className="chart-detail-view-content-title">Significance of the House</div>
                <span className='chart-detail-view-content-desc'>The first house is also known as Ascendant in Vedic Astrology. It is the house of behavior, as well as represents your likes and dislikes. The first house explains the personality, personal traits, and physical structure of an individual. The first house replicates the person that he is going to become, and how we evolve with time.  Not only this, but it also projects the overall destiny and the life span of an individual.</span>
                <span className='chart-detail-view-content-desc'>The first house reveals how you portray yourself in front of others and what others think of you. The meaning of the first house in Astrology determines many characteristics that we demonstrate through our identity on many different occasions.</span>
              </ScrollView>
        </div>
        <div className='chart-detail-view-close' onClick={()=>onChartDetailViewClose()}></div>
    </div>
    )}   
    <div className={`${className} ${variant}`} style={{
      ...variantStyle.kundaliStyle(WIDTH + 1, HEIGHT + 1, scale),
      margin: 0
    }}>
      <canvas ref={canvasRef} height={(HEIGHT * scale + 0.8).toFixed(0)} width={(WIDTH * scale + 0.5).toFixed(0)}></canvas>

      {(variant == 'south' || variant == 'east' || null) && (
        <div style={{
          top:  variant == 'south'?115*scale:118 * scale,
          left:  variant == 'south'?from == 'real-prediction'?(150*scale):(158*scale):from == 'real-prediction'?(155 * scale):(165 * scale),
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div className="d-flex" style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            {children}
          </div>

          {/* <span className="small text-muted" style={{ width: '100%', textAlign: 'center' }}> <strong>PocketPandit</strong></span> */}
        </div>
      )}

      {
         data.map(([label, planets, code], index) => (
          <Ghar
            key={index}
            variant={variant}
            transitGhar = {transitGhar}
            onClickKundaliDetailView = {onClickKundaliDetailView}
            sthaana={label}
            sthaanaStyle={Object.assign({}, styles[index].sthaana, sthaanaStyle)}
            code={code}
            codeStyle={Object.assign({}, styles[index].code)}
            grahas={planets}
            grahaStyle={Object.assign({}, styles[index].graha, grahaStyle)}
          />
        ))
      }    
      
      {
        transitGhar && newTransitData.map(([label, planets, code], index) => (
          <TrasitGhar
            key={index}
            variant={variant}
            transitGhar = {transitGhar}
            onClickKundaliDetailView = {onClickKundaliDetailView}
            sthaana={label}
            sthaanaStyle={Object.assign({}, styles2[index].sthaana, sthaanaStyle)}
            code={code}
            codeStyle={Object.assign({}, styles2[index].code)}
            grahas={planets}
            grahaStyle={Object.assign({}, styles2[index].graha, grahaStyle)}
          />
        ))
      }
    </div>
    </>
  )
});

Kundali.propTypes = {
  className: PropTypes.string,
  xScale: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  defaultVariant: PropTypes.string
};

export default Kundali;
