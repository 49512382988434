import React, { useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch,useLocation } from "react-router-dom";
import queryString from "query-string";
import api from "src/lib/api";
import useSelect from "src/core/hook/useSelect";
import useAuthInfo from "src/auth/hook/auth-info";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import { PocketToast } from "src/core/component/toast/PocketToast";
import Auth from "src/core/component/prediction/Auth";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import BirthProfiles from "src/core/component/prediction/BirthProfiles";
import Categories from "./Categories";
import Questions from "./Questions";
import PrevNextPage from "./PrevNextPage";

const CartSetup = () => {
  const { isAuthenticated,birthProfiles } = useAuthInfo();
  const birthProfileSwiperRef = useRef()
  const match = useRouteMatch();
  const location = useLocation();

  const {
    rtpOrder,
    setOrderInfo,
    isQuestionInCart,
    draftCartLines,
    addToDraftCart,
    removeFromDraftCart,
    paymentDisclaimerShow,
    updateDraftCartLines,
    validateDraftCart,
    syncDrafCart
  } = useRTPInfo();
  const history = useHistory();
  const [loading,setLoading] = useState(false)
  const [questionResponse, setQuestionResponse] = useState(null);
  const [rtpFilterIds, setRtpFilterIds] = useState({
    relationship_id: "all",
    p_relationship_id: "all", // doesn't conflict with relationship dropdown filter
    category_id: [],
  });
  const [
    relationshipSelected,
    handleRelationshipSelect,
    setRelationshipSelectionSpace,
    isRelationshipSelected,
  ] = useSelect([], []);

  useEffect(()=>{
    syncDrafCart()
    fetchQuestionList()
  },[])

  const [
    profileSelected,
    handleProfileSelect,
    setProfileSelectionSpace,
    isProfileSelected,
  ] = useSelect([], []);
  const [
    categorySelected,
    handleCategorySelect,
    setCategorySelectionSpace,
    isCategorySelected,
  ] = useSelect([], [], true);
  const [questionSelected, handleQuestionSelect, setQuestionSelectionSpace] =
    useSelect([], [], true);

  useEffect(() => {
    if (relationshipSelected.length === 0) {
      setRtpFilterIds((prevRtpFilterIds) => {
        return {
          ...prevRtpFilterIds,
          relationship_id: "all",
          p_relationship_id: "all",
        };
      });
    }
  }, [relationshipSelected]);
  useEffect(() => {
    handleQuestionSelect(null, []);

    if (profileSelected.length) {
      setRtpFilterIds({
        ...rtpFilterIds,
        relationship_id: profileSelected[0].relationship_id,
      });
    } else {
      if (rtpFilterIds.p_relationship_id === "all") {
        setRtpFilterIds({
          ...rtpFilterIds,
          relationship_id: "all",
        });
      }
    }
  }, [profileSelected]);
  useEffect(() => {
    setRtpFilterIds({
      ...rtpFilterIds,
      category_id: (categorySelected || []).map((category) => category.id),
    });
  }, [categorySelected]);

useEffect(() => {
  if (location.state && location.state?.question_id && location.state?.profile) {
    handleProfileSelect({},location.state?.profile)
    handleQuestionClickWithProfile(location.state?.question_id,location.state?.profile.id)
    history.replace({ state: {} })
  }
}, [location.state]);

useEffect(()=>{
  if(profileSelected.length>0){
    if(rtpFilterIds.relationship_id == 'all'){
      const filtered_regular_profile = birthProfiles.filter(profile=>profile.profile_type == "regular_kundali_profile")
      const filtered_prashna_profile = birthProfiles.filter(profile=>profile.profile_type == "prashna_kundali_profile")
      const filtered_birth_profile = [...filtered_regular_profile,...filtered_prashna_profile]
      let birthProfileIndex = filtered_birth_profile.map(bp=>bp.id).indexOf(profileSelected[0]?.id)
      birthProfileSwiperRef.current.swiper.slideTo(birthProfileIndex);
  }
  }
},[profileSelected])


const fetchQuestionList = async () => {
    try {
      const query = {
        relationship_id:
          rtpFilterIds.relationship_id !== "all"
            ? rtpFilterIds.relationship_id
            : "",
        category_id: rtpFilterIds.category_id,
      };
      const response = await api.get(
        `/v1/questions?${queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      setQuestionResponse(response);
    } catch (error) {
      console.log("RTP question error", error);
    }
  };

  const handleQuestionClick = (e, question) => {
    handleQuestionSelect(e, question);

    if (!profileSelected?.length) return;
    const selectedProfileId = profileSelected[0].id;
    const line = {
      question_id: question.id,
      profile_id: selectedProfileId,
    };
    if (isQuestionInCart(question.id, selectedProfileId)) {
      removeFromDraftCart(line);
    } else {
      addToDraftCart(line);
    }
  };

  const handleQuestionClickWithProfile = (question_id,profile_id)=>{
    const line = {
      question_id: question_id,
      profile_id: profile_id,
    };
    if (isQuestionInCart(question_id, profile_id)) {
      // removeFromDraftCart(line);
    } else {
      addToDraftCart(line);
    }
    // if(rtpFilterIds.relationship_id == 'all'){
    //   const filtered_regular_profile = birthProfiles.filter(profile=>profile.profile_type == "regular_kundali_profile")
    //   const filtered_prashna_profile = birthProfiles.filter(profile=>profile.profile_type == "prashna_kundali_profile")
    //   const filtered_birth_profile = [...filtered_regular_profile,...filtered_prashna_profile]
    //   let birthProfileIndex = filtered_birth_profile.map(bp=>bp.id).indexOf(profile_id) || 0
    //   birthProfileSwiperRef.current.swiper.slideTo(birthProfileIndex);
    // }
  }

  const prepareAndCreateCart = async () => {
    setLoading(true)
    const validatedDraftCart = await validateDraftCart(draftCartLines);
    // console.log('Instant Prediction Validated Draft Cart', validatedDraftCart);

    if (validatedDraftCart.length > rtpOrder?.order_lines?.length) {
      paymentDisclaimerShow();
    }

    try {
      const response = await api.post("v2/question-orders/cart", {
        line_items: validatedDraftCart,
      });
      const _order = response.data?.result?.data || null;
      setOrderInfo(_order);

      const cartItemsCount = (_order?.order_lines || []).length;

      if (!cartItemsCount) {
        PocketToast({
          type: "warning",
          message: "You must select at least one question to proceed",
        });
      } else {
        const cartLines = (_order?.order_lines || []).map((item) => ({
          question_id: item.question_id,
          profile_id: item.profile_id,
        }));
        updateDraftCartLines(cartLines);

        fbq('track', 'AddToCart'); //NOTE: Facebook Meta Pixel tracking event

        history.push("/instant-prediction/payment");
      }
    } catch (error) {
      PocketToast({
        type: "error",
        message: error.response?.data?.error?.message || error.message,
        autoClose: 5000,
      });
    }finally{
      setLoading(false)
    }
  };

  // console.log('Cart Data', cartData);
  // console.log("Draft cart lines", draftCartLines);

  return (
    <div className="position-relative">  
    {loading && <div className="fixed_modal"><LoadingIcon/></div>}
      <h2 className="rtp-heading">Get Your Instant Prediction Now</h2>

      {(isAuthenticated && (
        <BirthProfiles
          filter={rtpFilterIds}
          setFilter={setRtpFilterIds}
          setProfileSelectionSpace={setProfileSelectionSpace}
          setRelationshipSelectionSpace={setRelationshipSelectionSpace}
          handleProfileSelect={handleProfileSelect}
          handleRelationshipSelect={handleRelationshipSelect}
          isProfileSelected={isProfileSelected}
          isRelationshipSelected={isRelationshipSelected}
          resetSelectionOnFilter={true}
          createEditURL={`${match.path}/birth-profile`}
          hasFilterTitle={true}
          filterTitle={"Who do you want a prediction for?"}
          birthProfileSwiperRef = {birthProfileSwiperRef}
        />
      )) || (
        <Auth
          authRoute={`${match.path}/auth`}
          hasTitle={true}
          title={"Who do you want a prediction for?"}
        />
      )}

      <Categories
        rtpFilterIds={rtpFilterIds}
        setRtpFilterIds={setRtpFilterIds}
        categorySelected={categorySelected}
        handleCategorySelect={handleCategorySelect}
        setCategorySelectionSpace={setCategorySelectionSpace}
        isCategorySelected={isCategorySelected}
      />

      <Questions
        rtpFilterIds={rtpFilterIds}
        setRtpFilterIds={setRtpFilterIds}
        questionSelected={questionSelected}
        handleQuestionSelect={handleQuestionClick}
        handleQuestionClickWithProfile = {handleQuestionClickWithProfile}
        handleProfileSelect = {handleProfileSelect}
        handleRelationshipSelect = {handleRelationshipSelect}
        createEditURL={`${match.path}/birth-profile`}
        match={match}
        setQuestionSelectionSpace={setQuestionSelectionSpace}
        isQuestionSelected={isQuestionInCart}
        profile={(profileSelected.length && profileSelected[0]) || null}
        questionResponse = {questionResponse?.data?.result?.data}
      />

      <PrevNextPage
        onClick={prepareAndCreateCart}
        hasPrev={true}
        hasNext={true}
        isEnabled={draftCartLines.length > 0}
      />
    </div>
  );
};

export default CartSetup;
