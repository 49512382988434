import React, { useState,useEffect,useRef,useCallback } from 'react'
import useAuthInfo from "src/auth/hook/auth-info";
import { Button, Spinner } from 'react-bootstrap';
import { BoldSvg, Clock2Svg,Plus2Svg,RedeemCode,PesoSvg } from 'src/core/component/assets/svg/CommonSVG'
import defaultImg from 'src/image/default.jpg';
import ForumDropdown from 'src/core/component/dropdown/ForumDropdown'
import { useModal } from "src/core/hook/useModal";
import PromoCodeRedeemModal from './PromoCodeRedeemModal'
import ForumBirthProfile from './ForumBirthProfile'
import useQuestionBank from 'src/forumV2/forumPost/forumPostAsk/getQuestionBank';
import { PocketToast } from 'src/core/component/toast/PocketToast'
import debounce from "src/util/debounce";
import api from 'src/lib/api'
import moment from 'moment';


const ForumAskQuestion = ({
  isAskQuestion,
  setIsAskQuestion,
  forumQuestion,
  setForumQuestion,
  selectedBirthProfile,
  setSelectedBirthProfile,
  filterItem,
  setFilterItem,
  selectedQuestionBank,
  setSelectedQuestionBank,
  draftId,
  setDraftId,
  categories,
  setPosts,
  handleForumClickHandle,
  handlePostReplace,
  handlePostAdd
}) => {
  const { isAuthenticated,profile } = useAuthInfo();
  const [isAddBirthProfile,setIsAddBirthProfile] = useState(false)
  const questionBankRef = useRef(null)
  const [showPromoModal, handlePromoModalShow, handlePromoModalClose] = useModal(false);
  const {questionBank,questionInputChange} = useQuestionBank()
  // const [filteredQuestionBank,setFilterQuestionBank] = useState([])
  const [processing,setProcessing] = useState(false)

  useEffect(()=>{
    questionBankRef?.current?.classList.add('question_forum_input_blur')
  },[isAskQuestion,isAddBirthProfile])

  useEffect(()=>{
    if(!isAuthenticated){setIsAskQuestion(false)}
  },[isAuthenticated])

  useEffect(() => {
    if(categories){setFilterItem(categories[0])}
  }, [categories])

  // useEffect(()=>{
  //   if(forumQuestion == ''){
  //     setFilterQuestionBank(questionBank.questions)
  //   }
  //   else if(forumQuestion.split(' ').length > 1){
  //     let isAllMatch = questionBank.questions.filter((qb)=>{
  //       return qb.name.toLowerCase().split(' ').every(tag=>{
  //         return forumQuestion.toLowerCase().trim().replaceAll('?','').split(' ').includes(tag)
  //       })
  //     })
  //     if(isAllMatch.length > 0){
  //       setFilterQuestionBank(isAllMatch)
  //     }else{
  //       setFilterQuestionBank(questionBank.questions?.filter((qb)=>
  //       forumQuestion.toLowerCase().trim().split(' ').every((value)=>{
  //         return [...qb.tags.toLowerCase().split(','),...qb.name.toLowerCase().split(' ')].some(tag=>{
  //           return tag.includes(value)
  //         })
  //       })
  //       ))
  //     }
  //   }
  //   else{
  //     setFilterQuestionBank(questionBank.questions?.filter(
  //       qb=>qb.tags.toLowerCase().
  //       includes(forumQuestion.toLowerCase())))
  //   }
  // },[forumQuestion,questionBank.loading])

  const handleDebounce = useCallback(
    debounce((func, ...args) => func(...args), 680),
    []
);

  const handleFilterChange = (item)=>{
    setFilterItem(item)
  }
  const handleAskQuestionClick = ()=>{
    if(!isAuthenticated){
      // TODO instant-pred ko jasto login ra signup dekhaune
      PocketToast({ type: "error", message: "You must be login to ask a question.",});
      return;
    }
    setIsAskQuestion(true)
  }

  const handleQuestionInputChange =(e)=>{
    let value = e.target.value;
    if(value.length > 1000){
      PocketToast({ type: "error", message: "Question is too long.",});
      return;
    }
    setForumQuestion(value)
    value = value.replaceAll('?','')
    handleDebounce(questionInputChange, value);
  }

  const handleQuestionInputFoucs = ()=>{
    questionBankRef?.current?.classList.add('question_forum_input_focus')
    questionBankRef?.current?.classList.remove('question_forum_input_blur')
  }

  const handleQuestionInputClick = (e)=>{
    handleQuestionInputFoucs();
    handleQuestionInputChange(e);
  }

  const handleQuestionInputBlur = (timeout=false)=>{
    if(timeout){
      setTimeout(() => {
        questionBankRef?.current?.classList.add('question_forum_input_blur')
        questionBankRef?.current?.classList.remove('question_forum_input_focus')
      }, 400);
    }else{
        questionBankRef?.current?.classList.remove('question_forum_input_focus')
        questionBankRef?.current?.classList.add('question_forum_input_blur')
      }
  }

  const handleQuestionBankClick=(question) => {
    setForumQuestion(question.name)
    setFilterItem(question?.category)
    setSelectedQuestionBank(question?.id)
    handleQuestionInputBlur()
  }

  const handleRedeemPromoCode = ()=>{handlePromoModalShow()}
  const handleAddBirthProfile=()=>{setIsAddBirthProfile(true)}
  const handleBack=()=>{setIsAddBirthProfile(false)}

  const handleForumPostClick = (is_quick=false) => {
    if (!filterItem.id) {
      PocketToast({ type: "error", message: "You must select a category.",});
      return;
    }
    if (!selectedBirthProfile?.id) {
      PocketToast({ type: "error", message: "You must select a profile.",});
      return;
    }
    
    handleFormSubmit({
      title:forumQuestion,
      category_id:filterItem.id,
      profile_id:selectedBirthProfile.id,
      question_bank_id:selectedQuestionBank,
      state : 'posted',
      is_quick : is_quick
    })
  }

  const handleDraftClick = ()=>{
    if (!forumQuestion) {
      PocketToast({ type: "error", message: "You must enter a question.",});
      return;
    }
    
    handleFormSubmit({
      title:forumQuestion,
      category_id:filterItem.id,
      profile_id:selectedBirthProfile?.id || null,
      question_bank_id:selectedQuestionBank,
      state : 'draft'
    })
  }

  const handleFormSubmit = (payload) => {
    if (!payload.title || payload.title.trim().length<1) {
      PocketToast({ type: "error", message: "You must enter a question.",});
      return;
    }
      setProcessing(true);

      if(draftId == -1){
        createPost(payload).catch((e) => {
            let errorData = e?.response?.data?.error || e
            setProcessing(false);
            PocketToast({type: "error",message: errorData?.message || "Sorry, can not submit a question, try again",});
          });
        }else{
          updatePost(payload).catch((e) => {
              let errorData = e?.response?.data?.error || e
              setProcessing(false);
              PocketToast({type: "error",message: errorData?.message || "Sorry, can not update a question, try again",});
          });
      }
  };

  const updatePost = async (payload) => {
    payload = {
      ...payload,
      created_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),

    }
    try {
      const result = await api.put(`/v1/forum-posts/${draftId}`,payload);
      const updatedPost = result.data?.result?.data || null;
      // update your post

      setSelectedBirthProfile(null);
      setForumQuestion('');
      handleAskQuestionClick();
      setIsAskQuestion(false);
      setDraftId(-1);
      handlePostReplace(updatedPost);
      setTimeout(() => {
        setProcessing(false);
      },500);
      PocketToast({type: "success",message: "Question posted successfully.",});
    } catch (error) {
      let errorData = error?.response?.data?.error || error
      PocketToast({type: "error",message: errorData?.message || "Sorry, can not post a question",});
    }
  };

  const createPost = async (payload) => {
    const result = await api.post("v1/forum-posts", payload);
    const createdPost = result.data?.result?.data || null;
    setPosts(prev=>[createdPost,...prev])
    if(payload.state =='posted'){
      PocketToast({type: "success",message: "Question created successfully.",});
    }else if(payload.state =='draft'){
      PocketToast({type: "success",message: "Question saved in draft successfully.",});
    }
    setSelectedBirthProfile(null);
    setForumQuestion('');
    handleAskQuestionClick();
    setIsAskQuestion(false);
    handlePostAdd(createdPost);
    setTimeout(() => {
      setProcessing(false);
    },500);
    // handleForumClickHandle('your_post')
  };

  return (
    <div className='forum_post_ask_question'>
    {
      isAskQuestion == false ?
      (<>
        <img src={profile?.avatar_url || defaultImg}/>
        <div className='ask_content'>
          <Button className='w-100' onClick={handleAskQuestionClick}>Ask Your Question</Button>
          <p><Clock2Svg/> Default reply within 7 days</p>
          <p><BoldSvg/> Faster reply with coupon code. <a href='#'>Learn how</a></p>
        </div>
      </>
      ):isAddBirthProfile == false?(
        <div className='question_forum_form'>
          <div className='question_forum_birth_category'>
            <div className={`question_forum_birth_profile ${selectedBirthProfile!=null?' active':''}`}>
              <img src={profile?.avatar_url || defaultImg}/>
              {selectedBirthProfile==null?
              (<span onClick={handleAddBirthProfile} className='question_birth_profile'>
                  Add Birth profile <Plus2Svg/>
              </span>):
              (<div className='info_profile' onClick={handleAddBirthProfile}>
                <div className='info_profile_name'>{selectedBirthProfile?.name}</div>
                <div className='info_profile_desc'>
                  <span>{new Date(selectedBirthProfile?.dob).format('yyyy-MM-dd')}</span>
                  <span>{new Date(selectedBirthProfile?.dob).format('hh:mm tt')}</span>
                  <span>{selectedBirthProfile?.address.split(' ').slice(-2)}</span>
                </div>
              </div>)
              }
            </div>
            <ForumDropdown items={categories}
                  selectedItem={filterItem}
                  itemClick={handleFilterChange}
                  title='Category'
                  />
          </div>
          <div className='question_forum_input'>
            <input type='text' name="forum_question" 
                    value={forumQuestion} placeholder="Type your question"
                    onBlur={()=>handleQuestionInputBlur(true)}
                    onFocus={handleQuestionInputFoucs} 
                    onClick={handleQuestionInputClick}
                    onChange={(e)=>handleQuestionInputChange(e)} />
            <div className='question_forum_question_bank' ref={questionBankRef}>
              <div className='suggested_question_title'>
                <span className='left'>Suggested for you</span>
                <span className='right'>{questionBank.length}+</span>
              </div>
              {questionBank.questions && questionBank.questions.map((fqb,idx)=>
                  <div className='suggested_question' key={idx} onClick={()=>handleQuestionBankClick(fqb)}>
                    {fqb.name}?
                  </div>
                )}
            </div>
          </div>
          <div className='question_forum_promocode'>
               <span className='message'>Want faster response?</span>
               <span className='action' onClick={handleRedeemPromoCode}><RedeemCode/> Redeem Points</span>
          </div>
          <div className='question_forum_footer'>
                <span className='save_draft' onClick={handleDraftClick}>
                  {draftId == -1 &&
                    <span>Save Draft </span>
                  }
                </span>
                <span className='forum_footer_action'>
                  <span className='forum_footer_action_quick' onClick={()=>handleForumPostClick(true)} >Quick Answer <PesoSvg size='16'/> <span className='point'>30</span></span>
                  <span className='forum_footer_action_post' onClick={()=>handleForumPostClick(false)}>
                    Post
                    <span className='position-absolute'>{processing==true &&  <Spinner animation="border" variant="secondary" />}</span>
                  </span>
                </span>
          </div>
        </div>
      ):(
       <ForumBirthProfile 
            handleBack={handleBack}
            selectedBirthProfile = {selectedBirthProfile}
            setSelectedBirthProfile = {setSelectedBirthProfile}
            />
      )
    }

    {
      showPromoModal && (
        <PromoCodeRedeemModal
          handleModalShow={handlePromoModalShow}
          handleModalClose={handlePromoModalClose}
        />
      )
    }
    </div>
  )
}

export default ForumAskQuestion