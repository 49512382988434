import React from "react"
import AstrologerSignupForm from "src/auth/component/AstrologerSignupForm";


export default function AstrologerSignupPage({isDarkMode}) {

    return (
        <div className={"pocket-auth__signup" + (isDarkMode && ' dark' || '')}>
            <div className="row">
                <div className="col-12">
                    <h4 className={"pocket-auth__signup--heading" + (isDarkMode && ' dark' || '')}>Sign Up with <span>PocketPandit</span></h4>

                    <AstrologerSignupForm
                        isDarkMode={isDarkMode}
                    />
                </div>
            </div>
        </div>
    )
}
