import React from "react";

import SupportReplies from "./SupportReplies";
import SupportStatus from "./SupportStatus";

const SupportItem = ({ support, isDarkMode, userInfo, ...rest }) => {
  return (
    <div
      className={`pocket-customer-support__support-item ${
        (isDarkMode && "dark") || ""
      }`}
    >
      <SupportStatus support={support} isDarkMode={isDarkMode} />

      <div className="container-xl">
        {support?.support_replies && (
          <SupportReplies
            replies={support?.support_replies || []}
            userInfo={userInfo}
            isDarkMode={isDarkMode}
          />
        )}
      </div>
    </div>
  );
};

export default SupportItem;
