import React, { useState } from "react";


export const useModal = (defaultValue) => {
    const [showModal, setShowModal] = useState(defaultValue);

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleModalShow = () => {
        setShowModal(true);
    }

    return [showModal, handleModalShow, handleModalClose];
};