import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import AverageRatings from "src/core/component/astrologer/AverageRatings";
import api from 'src/lib/api';
import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import RatingSlider from 'src/core/component/rating/RatingSlider';
import PocketPills from "src/core/component/PocketPills";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import { PocketToast } from "src/core/component/toast/PocketToast";
import StartChat from "src/core/component/chat/StartChat";


const AstrologerProfile = ({match, ...rest}) => {
    const history = useHistory();
    const [isDarkMode] = useTheme();
    const [astrologer, setAstrologer] = useState(null);
    const [loading, setLoading] = useState(null);
    const [ratings, setRatings] = useState([]);
    const [chatCount, setChatCount] = useState(0);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        if(match.params?.astroId){
            fetchAstrologerDetails(match.params.astroId);
        }
    }, [match.params])

    const fetchAstrologerDetails = async (id) => {
        setLoading(true)

        try {
            const result = await api.get(`/v1/astrologers/${id}`);
            const astrologerInfo = result.data?.result?.data || null
            const count = result.data?.result?.metadata?.chat_count 
            if(astrologerInfo && Object.keys(astrologerInfo).length > 0){
                setAstrologer(astrologerInfo)
            }
            setChatCount(count);
            setMetaData(result?.data?.result?.metadata || null);
            setLoading(false);
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching astrologer details."
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(astrologer) {
            const ratingData = astrologer?.ratings || []
            setRatings(ratingData);
        }
    }, [astrologer])

    const totalRating = ratings && ratings.map(r=>r.rating).reduce((acc,curr) => acc + curr, 0) || 0;
    const average = ratings && totalRating && Math.ceil(totalRating / ratings.length) || 0;

    const handleChatClick = (e) => {
        e.preventDefault();
        history.push('/connect-astrologer')
    }

    // console.log('Astrologer', astrologer);

    return (
        <MainLayout>
            <section className={`pocket-profile__astrologer ${isDarkMode && 'dark' || ''}`}>
                <div className="container-xl">
                    {loading && (
                        <div className="row">
                            <div className="col-12">
                                <LoadingIcon />
                            </div>
                        </div>
                    )}

                    {!loading && astrologer && (
                        <>
                            <div className={`pocket-profile__astrologer-images ${isDarkMode && 'dark' || ''}`}>
                                <div className={`pocket-profile__astrologer-cover ${isDarkMode && 'dark' || ''} ${astrologer?.cover_image_url && 'has-cover' || ''}`}>
                                    {astrologer?.cover_image_url && (
                                        <img src={astrologer.cover_image_url} className="img-fluid" alt="" />
                                    )}
                                </div>
                                <div className={`pocket-profile__astrologer-info d-flex align-items-center ${isDarkMode && 'dark' || ''}`}>
                                    <div className={`pocket-profile__astrologer-avatar ${isDarkMode && 'dark' || ''} ${astrologer?.avatar_url && 'has-avatar' || ''}`}>
                                        {astrologer?.avatar_url && (
                                            <img src={astrologer.avatar_url} className="img-fluid" alt="" />
                                        )}
                                    </div> 
                                    <div className={`pocket-profile__astrologer-intro ${isDarkMode && 'dark' || ''}`}>
                                        <div className="name">
                                            {astrologer?.name}
                                        </div>
                                        <div className="contact">
                                            Contact Now: 
                                            <StartChat
                                                isDarkMode={isDarkMode}
                                                astrologer={astrologer}
                                                metaData={metaData}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className={`pocket-profile__astrologer-details ${isDarkMode && 'dark' || ''}`}>
                                <div className="row gx-3 gx-lg-3 gx-xl-4">
                                    {astrologer?.bio && (
                                        <div className="col-md-6">
                                            <div className="title">About Me:</div>
                                            <div className="bio">
                                                {astrologer?.bio}
                                            </div>
                                        </div>
                                    )}
                                    
                                    <div className="col-md-6">
                                        {astrologer?.address && (
                                            <div className={`address ${isDarkMode && 'dark' || ''}`}>
                                                <div className="title">Location:</div>
                                                <div className="location">{astrologer?.address}</div>
                                            </div>
                                        )}

                                        {astrologer?.specialization.length > 0 && (
                                            <div className={`specialization ${isDarkMode && 'dark' || ''}`}>
                                                <div className="title">
                                                    Spcialization:
                                                </div>
                                                <div className="specialization-items">
                                                    {astrologer?.specialization.length > 0 && (
                                                        <PocketPills
                                                            items={astrologer.specialization}
                                                            isDarkMode={isDarkMode}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>

                                <div className="separator"></div>

                                <div className={`astrologer-ratings-reviews mb-0 mb-sm-5 ${isDarkMode && 'dark' || ''}`}>
                                    {(average || chatCount) && (
                                        <div className={`d-flex align-items-center avg-ratings ${isDarkMode && 'dark' || ''}`}>
                                            <AverageRatings
                                                isDarkMode={isDarkMode}
                                                average={average}
                                                chatCount={chatCount}
                                            />
                                        </div>
                                    ) || <></>}

                                    {astrologer.ratings && astrologer.ratings.length > 0 && (
                                        <div className={`reviews ${isDarkMode && 'dark' || ''}`}>
                                            <div className="title">Reviews:</div>
                                            <div className={`reivew-cards-slider ${isDarkMode && 'dark' || ''}`}>
                                                <RatingSlider
                                                    ratings={astrologer.ratings}
                                                    isDarkMode={isDarkMode}
                                                    showNav={true}
                                                />
                                            </div>
                                        </div>
                                    ) || (<></>)}
                                    
                                </div>
                                
                            </div>
                        </>
                    )}

                    {!loading && !astrologer && (
                        <div className="row">
                            <div className="col-12">
                                <p className="text-danger py-5 text-center">Astrologer Not Found</p>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </MainLayout>
    )
}

export default AstrologerProfile;
