import React, {useState, useEffect} from "react";

import { SendMessageSvg } from "src/core/component/assets/svg/SupportSVG";
import useAuthInfo from 'src/auth/hook/auth-info';
import api from 'src/lib/api';
import { PocketToast } from "src/core/component/toast/PocketToast";


const ChatForm = ({
    isDarkMode,
    conversation,
    onSent,
    onError,
    expired=false,
    ...rest
}) => {

    const authInfo = useAuthInfo();
    const [formData, setFormData] = useState({
        'conversation_id': conversation.id,
        'content': '',
    });

    onSent = typeof onSent === 'function' ? onSent : function noop() { }
    onError = typeof onError === 'function' ? onError : function noop() { }

    useEffect(() => {
        setFormData({ ...formData, 'conversation_id': conversation.id });
    }, [conversation]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const tempLocalID = Math.ceil(Math.random() * 1e20);

        try {
            const payload = Object.assign({}, formData, {
                'local_id': tempLocalID.toString(),
            });
            setFormData({
                ...formData,
                'content': '',
            });

            // onSent({
            //     content: payload.content,
            //     conversation_id: payload.conversation_id,
            //     user_id: authInfo?.profile?.id,
            //     user: authInfo?.profile,
            //     created_at: new Date().toISOString(),
            //     updated_at: new Date().toISOString(),
            //     id: tempLocalID,
            // });

            const res = await api.post('v1/messages', payload, {
                headers: {
                'Content-Type': 'application/json'
                }
            });

            onSent(res?.data?.result?.data, tempLocalID);
        } catch (error) {
            error = error.response?.data?.error || error;
            onError(error.message, tempLocalID);
            PocketToast({
                type: "error",
                message: `${error?.message && error.message || 'Error on sending message.'}`
            })
        }
    }

    const conversationExpired = conversation.expired || expired;

    return (
        <form className={"pocket-form chat mb-0" + (isDarkMode && ' dark' || '')} onSubmit={handleSubmit}>
            <div className="row gx-1 gx-sm-2 align-items-center">
                <div className="col-auto flex-fill">
                    <input type="text" className={"form-control"} placeholder="Type a message" name="content" id="content" value={formData['content'] || ''} onChange={handleChange} required autoFocus disabled={conversationExpired} />
                </div>
                <div className="col-auto">
                    <button type="submit" className="btn pocket-btn chat d-none d-md-block" disabled={conversationExpired}>Send</button>
                    <button type="submit" className="d-md-none btn chat-mobile" disabled={conversationExpired}>
                        <SendMessageSvg />
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ChatForm;
