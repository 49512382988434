import React, { useState, useEffect } from "react";

import H4Heading from "src/core/component/grid/H4Heading";
import useAuthInfo from "src/auth/hook/auth-info";
import AccountUpdateForm from "./AccountUpdateForm";
import CircleImage from "src/core/component/birthprofile/CircleImage";
import PasswordChangeForm from "src/auth/component/forms/PasswordChangeForm";
import { pick } from "src/util/extra";
import api from "src/lib/api";
import DismissableAlert from "src/core/component/DismissableAlert";
import { useAlert } from "src/core/hook/Alert";
import ProfileImageUploadModal from "./ProfileImageUploadModal";
import { PocketToast } from "src/core/component/toast/PocketToast";
import uploadFile from 'src/lib/http/upload-file';

const Account = ({ path, isDarkMode, ...rest }) => {
  const { profile, updateProfile } = useAuthInfo();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);
  const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
  const [showModal, setShowModal] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    login: "",
    specialization: [],
  });

  useEffect(() => {
    if (profile) {
      setFormData((currentState) => ({
        ...currentState,
        screen_name:profile.screen_name,
        name: profile.name,
        login: profile.email,
        specialization: (profile.specialization || []).map((s) => s.id),
      }));
    }
  }, [profile]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSaveProfile = (e) => {
    // update profile
    handleSubmit(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(formData['screen_name'].length<5){
      PocketToast({type: "error",message: 'Username must be 5 letters.'});
      return
    }else if(formData['screen_name'].length>49){
      PocketToast({type: "error",message: 'Username is longer than 50.'});
      return
    }
    let dataToSubmit = pick(formData, "name",'screen_name', "specialization");

    updateUserProfile(dataToSubmit).catch((e) => {
      let errorData = e?.response?.data?.error || e
      PocketToast({
        type: "error",
        message: errorData?.message || "Failed to update a profile",
      });
    });
  };

  const updateUserProfile = async (payload) => {
    const result = await api.put(`v1/users/me`, payload);
    const profileData = result.data?.result?.data;
    if(profileData) {
      updateProfile(profileData);

      PocketToast({
        type: "success",
        message: "Your account updated successfully",
      });
    }
  };

  const handleChangePasswordClick = (e) => {
    setShowPasswordChangeForm((prevState) => !prevState);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const hanldeImageUpload = async (image) => {
    try {
      setImgUploading(true);
      const imageUrl = await uploadFile(image, 'uploads/profile-picture');

      const result = await api.put('v1/users/me', {'avatar_url': imageUrl});
      setImgUploading(false);
      setShowModal(false);

      const profileData = result.data?.result?.data;
      if(profileData) {
        updateProfile(profileData);

        PocketToast({
          type: "success",
          message: "Image uploaded successfully",
        });
      }
    } catch (error) {
      const errMsg = error.response?.data?.error?.message || error?.message;
      PocketToast({
        type: "error",
        message: errMsg || "Failed to upload image",
      });
    }
  };

  let alertVariant = error ? "alert-error" : success ? "alert-success" : "";

  return (
    <div className={"accounts" + ((isDarkMode && " dark") || "")}>
      {showAlert && (
        <DismissableAlert
          text={error || success}
          handleAlertClose={handleAlertClose}
          classes={`dismissable-alert ${alertVariant}`}
        ></DismissableAlert>
      )}

      <H4Heading
        classes={"pocket-page-heading" + ((isDarkMode && " dark") || "")}
        heading={`${
          (!showPasswordChangeForm && "Update Account Information") ||
          "Change Password"
        }`}
      />

      {profile && (
        <div className="row">
          {!showPasswordChangeForm && (
            <div className="col-12">
              <div className="d-flex align-items-center mb-4">
                <div
                  className={"profile-image" + ((isDarkMode && " dark") || "")}
                >
                  <CircleImage
                    url={profile?.avatar_url}
                    showIconOverlay={true}
                    isDarkMode={isDarkMode}
                    onClick={handleModalShow}
                  />
                </div>
                <div
                  className={"profile-name" + ((isDarkMode && " dark") || "")}
                >
                  {profile?.name}
                </div>
              </div>

              <AccountUpdateForm
                formData={formData}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                isDarkMode={isDarkMode}
              />
            </div>
          )}

          <div className="col-12">
            <div
              className={"change-password" + ((isDarkMode && " dark") || "")}
            >
              {!showPasswordChangeForm && (
                <>
                  <h4 className="title">Change Password</h4>
                  <p className="text">
                    Do you want to change your password?{" "}
                    <span className="link" onClick={handleChangePasswordClick}>
                      click here
                    </span>
                  </p>
                </>
              )}

              {showPasswordChangeForm && (
                <PasswordChangeForm isDarkMode={isDarkMode} showSubmit={true} />
              )}
            </div>
          </div>

          {!showPasswordChangeForm && (
            <div className="col-12">
              <div className="d-flex justify-content-sm-end align-items-center">
                <button className="pocket-btn" onClick={handleSaveProfile}>
                  Save Changes
                </button>
              </div>
            </div>
          )}

          {/* <Notifications
                        isDarkMode={isDarkMode}
                    /> */}

          {/* Image Upload Modal */}
          <ProfileImageUploadModal
            showModal={showModal}
            handleModalClose={handleModalClose}
            onSave={hanldeImageUpload}
            imgUploading={imgUploading}
            isDarkMode={isDarkMode}
          />
        </div>
      )}
    </div>
  );
};

export default Account;
