import React from "react";

const ProfileFilterItem = (props) => {
  function handleProfileFilterItemClick (e) {
    props.handleClick(e, props.item);
  }

  return (
    <div
      className={props.classes}
      role="button"
      onClick={handleProfileFilterItemClick}
    >
      <img
        src={props.item.icon_url}
        alt={props.item.name.slice(0, 1)}
        style={{ color: props.item.color }}
      />
      <span>{props.item.name}</span>
    </div>
  );
};

export default ProfileFilterItem;
