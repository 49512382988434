import React, { useState, useEffect } from "react";
import queryString from "query-string";

import api from "src/lib/api";
import { useTheme } from "src/theme/component/ThemeContext";
import PromosSlider from "./PromosSlider";

import placeholderSmall from "src/core/component/loader/assets/placeholder-small.webp";
import placeholderLarge from "src/core/component/loader/assets/placeholder-large.webp";

const Promos = () => {
  const [isDarkMode, toggleTheme] = useTheme();
  const [callApi, setCallAPi] = useState(true);
  const [promos, setPromos] = useState([
    {
      id: 0,
      image_url: placeholderSmall,
      medium_image_url: placeholderLarge,
      large_image_url: placeholderLarge,
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    active: true,
    is_featured: true,
    discount_type: "scope",
  });

  useEffect(() => {
    fetchFeaturedPromos();
  }, []);

  const fetchFeaturedPromos = async () => {
    setLoading(true);

    try {
      const result = await api.get(
        `/v1/discount-offers?${queryString.stringify(filter, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      const promoData = result?.data?.result?.data || [];
      setPromos(promoData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(`Can't fetch promos`);
    }
  };

  // console.log("Promos Home...", promos);

  return (
    <>
      {promos && promos.length > 0 && (
        <section className={"pocket-promos" + ((isDarkMode && " dark") || "")}>
          <PromosSlider promosData={promos} isDarkMode={isDarkMode} />
        </section>
      )}
    </>
  );
};

export default Promos;
