import React, {useState, useCallback} from "react";
import { Link, useHistory } from "react-router-dom";

import api from 'src/lib/api';
import { isObjectEmpty } from "src/util/extra";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import CircleImage from "src/core/component/birthprofile/CircleImage";
import PocketRatings from "src/core/component/rating/PocketRatings";
import { PocketToast } from "src/core/component/toast/PocketToast";
import useCustomerChat from "src/connect-with-astrologer/hook/useCustomerChat";


const AddReviewModal = ({
    astrologer,
    isDarkMode, 
    showModal,
    handleModalClose,
    handleResumeChat,
    conversation,
    expired=false,
    ...rest
}) => {
    const history = useHistory();
    const {clearSelectedAstrologer} = useCustomerChat();
    
    const [data, setData] = useState({
        'rating': 0,
        'review': ""
    })

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value});
    }

    const handleReviewSubmit = (e) => {
        e.preventDefault();
        if(data.rating <= 0){
            PocketToast({
                type: "error", 
                message: "Rating needs to be added."
            })
            return;
        }

        addReviewToAstrologer();
    }

    const addReviewToAstrologer = async () => {
        try {
            const res = await api.post(`v1/astrologers/${astrologer.id}/rating`, {
                rating: data.rating,
                review: data.review
            });

            const reviewData = res.data.result.data;
            PocketToast({
                type: "success", 
                message: "Your review was registered."
            })

            // Remove selected astrologer from Redux store.
            clearSelectedAstrologer();

            history.push('/connect-astrologer');

        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Sorry! can't add a review at the moment."
            })
        }
    }

    const handleReviewLater = (e) => {
        e.preventDefault();
        PocketToast({
            type: "error", 
            message: "Your conversation has ended."
        })
        // Remove selected astrologer from Redux store.
        clearSelectedAstrologer();
        history.push('/connect-astrologer');
    }

    const handleSetRatings = (val) => {
        setData({
            ...data,
            'rating': val
        })
    }

    // console.log('Ratings', data.rating);
    // console.log('Data', data);

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={false} centered={true} classes={'chat-setup__modal-review'  + (isDarkMode && ' dark' || '')} backdrop="static">
            <ModalBody>
                <div className={`chat-setup__modal-content text-center ${isDarkMode && 'dark' || ''}`}>
                    <div className={`rating-card ${isDarkMode && 'dark' || ''}`}>
                        <div className={`image ${isDarkMode && 'dark' || ''}`}>
                            <CircleImage
                                url={astrologer?.avatar_url}
                                showIconOverlay={false}
                                isDarkMode={isDarkMode}
                                showIsOnline={true}
                                isOnline={astrologer?.isOnline || true}
                            />
                        </div>
                        <span className="text-center name">{astrologer?.name}</span>
                        {/* <div className={`continue-chat d-flex align-items-center justify-content-center ${isDarkMode && 'dark' || ''}`}>
                            <p className="mb-0">Continue to chat?</p>
                            <div className="pocket-btn" onClick={handleResumeChat}>
                                Resume Chat
                            </div>
                        </div> */}
                        <hr />
                        <div className="rating">
                            <PocketRatings
                                ratings={data.rating}
                                setRatings={handleSetRatings}
                                title={'How was your conversation?'}
                                isDarkMode={isDarkMode}
                                readOnly={false}
                            />
                        </div>
                        <hr />
                        <div className={`review text-start ${isDarkMode && 'dark' || ''}`}>
                            <div className="title">
                                Was the astrologer helpful?
                            </div>
                            <form className={`pocket-form w-100 ${isDarkMode && 'dark' || ''}`} onSubmit={handleReviewSubmit}>
                                <div className="row">
                                    <div className="col-12 field">
                                        <label className="form-label" htmlFor="review">Share your expereince</label>
                                        <textarea 
                                            className="form-control none"
                                            name="review"
                                            id="review" 
                                            value={data.review}
                                            onChange={handleChange} 
                                            rows={3}
                                            maxLength="100"
                                            autoComplete="off"
                                        >

                                        </textarea>
                                        <small>100 characters</small>
                                    </div>
                                    <div className="col-12 field submit-review">
                                        <button className="pocket-btn w-100">
                                            Add your review
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="do-later text-center">
                                <Link to="" onClick={handleReviewLater}>Do it later</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </CommonModal>
    )
}

export default AddReviewModal