import React from "react";

import MomentRelativeTime from "src/core/component/moment-date-time/MomentRelativeTime";
import {
    FreeAstrologySvgIcon,
    RealTimePredictionSvgIcon,
    ShopSvgIcon,
    ForumSvgIcon,
    CustomerSupportSvgIcon,
    GeneralNotificationIcon,
    NotificationDeleteIcon,
} from "src/core/component/assets/svg/CommonSVG";


const NotificationItem = ({ item, isDarkMode, handleDelete, handleClick, ...rest }) => {
    handleDelete = typeof handleDelete === 'function' ? handleDelete : function noop() { };
    handleClick = typeof handleClick === 'function' ? handleClick : function noop() { };

    const NotifIcon = () => {
        if(item?.data_payload?.origin) {
            if(item.data_payload.origin === 'free-astrology') {
                return <FreeAstrologySvgIcon />
            } else if (item.data_payload.origin === 'realtime-prediction') {
                return <RealTimePredictionSvgIcon />
            } else if (item.data_payload.origin === 'forum') {
                return <ForumSvgIcon />
            } else if (item.data_payload.origin === 'shop') {
                return <ShopSvgIcon />
            } else if (item.data_payload.origin === 'support') {
                return <CustomerSupportSvgIcon />
            } else {
                return <GeneralNotificationIcon />
            }
        } else {
            return <GeneralNotificationIcon />
        }
    }

    return (
        <div className={`pocket-user__notifications-item position-relative ${item?.data_payload?.origin && item.data_payload.origin || 'general'} ${item?.read && 'seen' || ''} ${isDarkMode && 'dark' || ''}`}>
            <div className="content-wrapper" onClick={handleClick.bind(null, item)}>
                <div className="d-flex align-items-center">
                    <div className="icon">
                        {NotifIcon()}
                    </div>
                    <div className={`contents w-100 ${isDarkMode && 'dark' || ''}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="notification-body">
                                <div className={`title ${item?.read && 'seen' || ''}`}>
                                    {item?.title}
                                </div>
                                <div className="body">
                                    <div dangerouslySetInnerHTML={{__html: item?.body}} />
                                </div>
                            </div>
                            <div className={`action-timestamp flex-shrink-0 ${isDarkMode && 'dark' || ''}`}>
                                <div className="timestamp">
                                    <MomentRelativeTime
                                        datetime={item.created_at}
                                        avoidSuffix={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="delete position-absolute" style={{top: '-2px', right: "5px", zIndex: 2}}>
                <div className="delete" onClick={handleDelete.bind(null, item)}>
                    <NotificationDeleteIcon />
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
