import React from "react";

import CategorySlider from "src/core/component/category/CategorySlider";
import ViewMoreLink from "./ViewMoreLink";


const OfferedCategories = ({
    categories, 
    promo, 
    handleCategorySelect, 
    isCategorySelected, 
    handleBrowseMore, 
    isDarkMode, 
    ...rest
}) => {

    handleCategorySelect = typeof handleCategorySelect === 'function' ? handleCategorySelect : function noop(){ }
    isCategorySelected = typeof isCategorySelected === 'function' ? isCategorySelected : function noop(){ }

    if(promo && promo.scope==='question' && promo.questions.length && categories.length){
        categories = categories.filter(item=>item.id!==promo.questions[0]?.category_id)
    } else if(promo && promo.scope==='category' && promo.categories.length && categories.length) {
        categories = categories.filter(item=>item.id!==promo.categories[0].id)
    }

    return (
        <div className="related-contents__category">
            {categories && categories.length > 0 && (
                <>
                    <h3 className="related-title">Browse Our Other Categories:</h3>
                    <div className="">
                        <CategorySlider
                            categories={categories}
                            handleCategorySelect={handleCategorySelect}
                            isCategorySelected={isCategorySelected}
                            isDarkMode={isDarkMode}
                        />
                    </div>

                    <ViewMoreLink 
                        title={'View all categories'}
                        onClick={handleBrowseMore}
                        className={`d-flex justify-content-end`}
                        isDarkMode={isDarkMode}
                    />
                </>
            )}
        </div>
    )
}

export default OfferedCategories
