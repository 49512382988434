import { useDispatch, useSelector } from "react-redux";

import  {
    setupUserNotification as userNotificationSetupAction,
    updateUserNotification as userNotificationUpdateAction,
    markUserNotificationRead as userNotificationReadAction,
    deleteUserNotification as userNotificationDeleteAction,
    clearUserNotification as userNotificationClearAction
} from 'src/notification/redux';


export default function useNotification() {
    const dispatch = useDispatch();
    const notificationData = useSelector(state => state.notification);

    const _getNotifications = () => {
        const notifications = notificationData.userNotifications;
        return notifications;
    }

    const setupUserNotification = () => {
        dispatch(userNotificationSetupAction());
    }

    const updateUserNotification = (notification) => {
        dispatch(userNotificationUpdateAction(notification));
    }

    const markUserNotificationRead = (notification) => {
        dispatch(userNotificationReadAction(notification));
    }

    const deleteUserNotification = (id) => {
        dispatch(userNotificationDeleteAction(id));
    }

    const clearUserNotification = () => {
        dispatch(userNotificationClearAction());
    }

    return {
        get userNotifications() {
            return _getNotifications()
        },

        setupUserNotification,
        updateUserNotification,
        markUserNotificationRead,
        deleteUserNotification,
        clearUserNotification
    };
}
