import React,{useState,useEffect, useCallback, useRef} from 'react'
import { useHistory,useLocation } from 'react-router-dom'
import queryString from "query-string";
import ForumPostTab from './ForumPostTab'
import ForumAskQuestion from './forumPostAsk/ForumAskQuestion'
import ForumPostCard from './ForumPostCard'
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import ForumYourPost from './forumPostYour/ForumYourPost'
import useAuthInfo from "src/auth/hook/auth-info";
import useYourPosts from 'src/forumV2/forumPost/forumPostYour/getYourPosts'

const ForumPost = ({
  categories,
  setPosts,
  posts,
  postsLoading,
  handleLoadMore,
  metaData,
  currentFilter,
  isAscending,
  activeCategory,
  forumTabs,
  forumTab,
  setForumTab
}) => {
  const {
    state,
    handleFilterChange,
    handleAddMore,
    handlePostReplace,
    handlePostAdd,
    deleteDraft,
    handleCategoryChange,
    handlePageReset
    } = useYourPosts({
    'currentFilter':currentFilter,
    'isAscending':isAscending,
    'activeCategory':activeCategory
  });
  const { isAuthenticated } = useAuthInfo();
  const [isAskQuestion,setIsAskQuestion] = useState(false)
  const [forumQuestion,setForumQuestion] = useState('')
  const [selectedBirthProfile,setSelectedBirthProfile] = useState(null)
  const [filterItem,setFilterItem] = useState(null)
  const [selectedQuestionBank,setSelectedQuestionBank] = useState(null)
  const [draftId,setDraftId] = useState(-1)
    const history = useHistory()
    const location = useLocation()
    const observer = useRef(null)

    const handlePostDraft = (post) => {
      window.scrollTo({top: 0,left:0, behavior: "instant"});
      setIsAskQuestion(true)
      setForumQuestion(post?.title || '')
      setSelectedBirthProfile(post?.profile || null)
      setFilterItem(post?.category || null)
      setSelectedQuestionBank(post?.question_bank_id || null)
      setDraftId(post?.id || -1)
    }

    const handleForumClickHandle = (tab) => {
        setForumTab(tab)
        let searchedQuery = queryString.parse(history.location.search);
        searchedQuery = {...searchedQuery,'tab':tab}
        searchedQuery =  queryString.stringify(searchedQuery, {skipEmptyString: true, skipNull: true})
        history.push({search:searchedQuery})
    }

    useEffect(() => {
    const parsed = queryString.parse(location.search)
    if(forumTabs.map(tab=>tab.value).includes(parsed['tab'])){
        setForumTab(parsed['tab'])
    }
    }, [location])

    const lastPost = useCallback((node)=>{
      if(!node) return;
      if (postsLoading) return;
      if(observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries)=>{
        if(entries[0].isIntersecting){
          handleLoadMore()
        }
      });
      observer.current.observe(node);
    })

  return (
    <div className='forum_post'>
    {
      isAuthenticated && 
        <ForumPostTab tabs={forumTabs} currentTab={forumTab} handleForumClickHandle={handleForumClickHandle} />
    }
        
        <ForumAskQuestion 
            isAskQuestion={isAskQuestion}
            setIsAskQuestion = {setIsAskQuestion}
            forumQuestion = {forumQuestion}
            setForumQuestion = {setForumQuestion}
            selectedBirthProfile = {selectedBirthProfile}
            setSelectedBirthProfile = {setSelectedBirthProfile}
            filterItem = {filterItem}
            setFilterItem = {setFilterItem}
            selectedQuestionBank = {selectedQuestionBank}
            setSelectedQuestionBank = {setSelectedQuestionBank}
            draftId = {draftId}
            setDraftId = {setDraftId}
            categories={categories} 
            setPosts={setPosts} 
            handleForumClickHandle={handleForumClickHandle} 
            handlePostReplace={handlePostReplace}
            handlePostAdd={handlePostAdd}
    
        />
        {
          forumTab === 'explore' && 
            <div className='forum_post_questions'>
              {posts && posts.length > 0 && posts.map((item, index) => 
                <div key={index} id={item.id} className={"question_body"} 
                ref={index === posts.length -1 ? lastPost : null}
                >
                  <ForumPostCard item={item} setPosts={setPosts}/>
                </div>
              )}
              {/* {metaData &&
                metaData?.next &&
                metaData.next > 1 &&
                (<div className="col-12">
                    <div className="load-more">
                      <button
                        className="pocket-btn d-block forum mx-auto mt-3"
                        onClick={handleLoadMore}
                      >
                        View More
                      </button>
                    </div>
                  </div>
                )} */}
              {postsLoading && (<div className="col-12"><LoadingIcon /></div>)}
        </div>
        }
        {
          forumTab === 'your_post' && 
          <ForumYourPost 
            forumstate = {state}
            handleFilterChange = {handleFilterChange}
            handleAddMore = {handleAddMore}
            handlePostReplace = {handlePostReplace}
            deleteDraft = {deleteDraft}
            handleCategoryChange = {handleCategoryChange}
            handlePageReset = {handlePageReset}
            postDraft = {handlePostDraft}
            activeCategory={activeCategory}
            currentFilter = {currentFilter}
            isAscending = {isAscending}
          />
        }
    </div>
  )
}

export default ForumPost