import React from "react";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";


const ConfirmationModal = ({
    showModal,
    handleModalClose,
    handleProceedClick,
    text,
    isDarkMode, 
    ...rest
}) => {

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={true} centered={false} classes={'pocket-modal__confirm'  + (isDarkMode && ' dark' || '')}>
            <ModalBody>
                <div className={`confirmation-info ${isDarkMode && 'dark' || ''}`}>
                    <div className="text">
                        {text}
                    </div>
                    <button className="btn pocket-btn float-end" onClick={handleProceedClick}>Proceed</button>
                </div>
            </ModalBody>
        </CommonModal>
    )
}

export default ConfirmationModal