import React from "react";

import { CardSvgOld } from "src/core/component/assets/svg/CommonSVG";
import Card from "src/core/component/card/Card";
import CardBody from "src/core/component/card/CardBody";
import CardIcon from "src/core/component/card/CardIcon";

const FeatureItem = ({
  classes,
  title,
  description,
  url,
  pricing,
  buttonText,
  icon,
  onClick,
  isDarkMode,
  ...rest
}) => {
  return (
    <div className={"col " + classes}>
      <Card classes={"text-center"}>
        <CardSvgOld />

        <CardBody
          isfeatures={true}
          hasLink={true}
          title={title}
          description={description}
          url={url}
          pricing={pricing}
          linkText={buttonText}
          onClick={onClick}
        />

        <CardIcon classes={"card-icon"}>{icon}</CardIcon>
      </Card>
    </div>
  );
};

export default FeatureItem;
