import React from "react";
import { Link } from "react-router-dom";

import { truncate } from "src/util/extra";
import {CallSvg, VideoCallSvg, ChatSvg} from "src/core/component/assets/svg/connectastrologer/ConnectAstrologer"
import CircleImage from "src/core/component/birthprofile/CircleImage";
import { EditSvg } from "src/core/component/assets/svg/CommonSVG";
import PocketRatings from "src/core/component/rating/PocketRatings";
import StartChat from "src/core/component/chat/StartChat";


const AstrologerCard = ({
    astrologer, 
    metaData,
    className, 
    isDarkMode,
    handleCall,
    handleVideoCall,
    handleChat,
    ...rest
}) => {

    handleCall = typeof handleCall === 'function' ? handleCall : function noop() { }
    handleVideoCall = typeof handleVideoCall === 'function' ? handleVideoCall : function noop() { }
    handleChat = typeof handleChat === 'function' ? handleChat : function noop() { }

    className = isDarkMode ? `${className} dark` : className;

    const spacializations = astrologer?.specialization && astrologer.specialization.map(sp=>sp.name).join(" ") || '';

    return (
        <div className={'card ' + className}>
            <div className="card-body">
                <div className="card-text">
                    <div className={`astrologer-profile ${isDarkMode && 'dark' || ''}`}>
                        <div className="d-flex">
                            <div className="image">
                                <CircleImage
                                    url={astrologer?.avatar_url}
                                    showIconOverlay={false}
                                    isDarkMode={isDarkMode}
                                    showIsOnline={true}
                                    isOnline={astrologer?.isOnline || true}
                                />
                            </div>
                            <div className={`infos ${isDarkMode && 'dark' || ''}`}>
                                <div className="d-flex justify-content-between align-items-center">
                                    {/* <h4 className="name">{astrologer?.name}</h4> */}
                                    <Link to={`/astrologer-profile/${astrologer.id}`} className="name">
                                        {astrologer?.name}
                                    </Link>
                                    {astrologer?.chat_rate_per_hour > 0 && (
                                        <p className="chat-rate mb-0">
                                            {`$${astrologer.chat_rate_per_hour}/hr`}
                                        </p>
                                    )}
                                    
                                </div>
                                {spacializations && (
                                    <p className="specialization">{truncate(spacializations, 35)}</p>
                                )}
                                <div className="d-flex justify-content-between align-items-center">
                                    <PocketRatings
                                        ratings={+astrologer.rating}
                                        isDarkMode={isDarkMode}
                                        readOnly={true}
                                    />
                                    
                                    <span className="view-profile d-flex align-items-start ms-auto">
                                        <Link to={`/astrologer-profile/${astrologer.id}`} className="link">
                                            View Profile
                                            <EditSvg />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`astrologer-contact ${isDarkMode && 'dark' || ''}`}>
                        <div className="contact-items">
                            <div className="d-flex align-items-center">
                                <span className="text">Contact</span>
                                {/* <span className="call" onClick={handleCall}>
                                    <CallSvg />
                                </span>
                                <span className="video-call" onClick={handleVideoCall}>
                                    <VideoCallSvg />
                                </span> */}

                                {/* Chat */}
                                <StartChat
                                    isDarkMode={isDarkMode}
                                    astrologer={astrologer}
                                    metaData={metaData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AstrologerCard
