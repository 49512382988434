import { loadJSON } from 'src/lib/json-store';
import api from 'src/lib/api';

// Inital state
const initialState = {
  userNotifications: loadJSON('notification.data') || []
};

// Action types
const SETUP_USER_NOTIFICATION = 'SETUP_USER_NOTIFICATION';
const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
const CLEAR_USER_NOTIFICATION = 'CLEAR_USER_NOTIFICATION';
const MARK_USER_NOTIFICATION_READ = 'MARK_USER_NOTIFICATION_READ';
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

// Actions
export const setupUserNotification = () => {
    return async (dispatch) => {
        try {
            const notificationQuery = api.get('v1/notifications?unread=true&limit=100');
            Promise.resolve(notificationQuery)
              .then((notificationRespone) => {
                const notifications = notificationRespone.data?.result?.data || [];
                dispatch({
                    type: SETUP_USER_NOTIFICATION,
                    payload: {
                        userNotifications: notifications
                    }
                });
            });
        } catch (error) {
            console.log('Error fetching notifications');
        }
    }
}

export const updateUserNotification = (notification) => {
    return {
        type: UPDATE_USER_NOTIFICATION,
        payload: {
            notification: notification
        }
    };
}

export const markUserNotificationRead = (notification) => {
    return {
        type: MARK_USER_NOTIFICATION_READ,
        payload: {
            notification: notification
        }
    }
}

export const deleteUserNotification = (id) => {
    return {
        type: DELETE_NOTIFICATION,
        payload: {
            id: id
        }
    }
}

export const clearUserNotification = () => {
    return {
        type: CLEAR_USER_NOTIFICATION
    };
}

// Reducer
export default function userNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case SETUP_USER_NOTIFICATION: {
        const payload = action.payload;
        return {
            ...state,
            userNotifications: payload.userNotifications
        };
    }

    case UPDATE_USER_NOTIFICATION: {
        const payload = action.payload;
        const notifIndex = state.userNotifications.findIndex(n => n.id == payload.notification.id);
        if (notifIndex === -1) {
            return {
                ...state,
                userNotifications: [payload.notification, ...state.userNotifications]
            }
        } else {
            return state
        }
    }

    case MARK_USER_NOTIFICATION_READ: {
        const payload = action.payload;
        const index = state.userNotifications.findIndex(n=>n.id==payload.notification.id)
        if(index === -1){
            return state
        } else {
            const notif = state.userNotifications[index];
            notif.read = true;
            const notifications = [
                ...state.userNotifications.slice(0, index),
                notif,
                ...state.userNotifications.slice(index + 1),
            ];
            
            return {
                ...state,
                userNotifications: notifications
            }
        }
    }

    case DELETE_NOTIFICATION: {
        const payload = action.payload;
        return {
            ...state,
            userNotifications: state.userNotifications.filter(n=>n.id!==payload.id)
        }
    }

    case CLEAR_USER_NOTIFICATION: {
        return {
            userNotifications: []
        };
    }

    default:
      return state;
    }
}
