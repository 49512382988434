import React, { useCallback, useEffect, useState } from "react";

export default function useSelect(
  initialSelection,
  initialSelectionSpace,
  isMultiSelect = false
) {
  const [selected, setSelected] = useState(initialSelection);
  const [selectionSpace, setSelectionSpace] = useState(initialSelectionSpace);

  const itemIsSelected = useCallback((item) =>
    selected.find((s) => s.id === item.id)
  ,[ selected ]);

  useEffect(() => {
    if(!isMultiSelect && (selected || []).length > 1) {
      setSelected([ selected[ selected.length - 1]]);
    }
  }, [ selected ]);

  useEffect(() => {
    const newSelected = [];
    selected.forEach((item) => {
      let newItem = selectionSpace.find((it) => it.id === item.id);
      newSelected.push(newItem || item);
    });
    setSelected(newSelected);
  }, [selectionSpace]);

  const handleSelectionChange = useCallback((e, item) => {
    if (Array.isArray(item)) {
      if (item.length || item.length ^ selected.length) {
        setSelected(item);
      }
    } else if (e && e.target?.type && e.target.type === "checkbox") {
      let newSelectedItems = [];
      if (itemIsSelected(item)) {
        newSelectedItems = selected.filter(
          (selected) => selected["id"] != item["id"]
        );
      } else {
        newSelectedItems = [...selected, item];
      }
      setSelected(newSelectedItems);
    } else {
      let newSelectedItems = [];

      if (e.shiftKey) {
        const lastSelected = selected[selected.length - 1];
        const lastSelectedIndex =
          (lastSelected &&
            (selectionSpace || []).findIndex(
              (r) => r["id"] === lastSelected["id"]
            )) ||
          -1;
        const targetIndex = (selectionSpace || []).findIndex(
          (r) => r["id"] === item["id"]
        );

        newSelectedItems = [...selected];

        for (let ei in selectionSpace || []) {
          const entity = selectionSpace[ei];
          let shouldSelect = false;
          let shouldBreak = false;

          if (targetIndex > lastSelectedIndex) {
            if (ei >= lastSelectedIndex && ei <= targetIndex) {
              shouldSelect = true;
            }
            shouldBreak = ei >= targetIndex;
          } else {
            if (ei <= lastSelectedIndex && ei >= targetIndex) {
              shouldSelect = true;
            }
            shouldBreak = ei >= lastSelectedIndex;
          }

          if (shouldSelect && !itemIsSelected(entity)) {
            newSelectedItems = [...newSelectedItems, entity];
          }

          if (shouldBreak) {
            break;
          }
        }

        document.getSelection().removeAllRanges();
      } else if (e.ctrlKey || e.metaKey) {
        if (itemIsSelected(item)) {
          newSelectedItems = selected.filter(
            (selected) => selected["id"] != item["id"]
          );
        } else {
          newSelectedItems = [...selected, item];
        }
      } else {
        if (isMultiSelect) {
          if (itemIsSelected(item)) {
            newSelectedItems = selected.filter((t) => t.id !== item.id);
          } else {
            newSelectedItems = [...selected, item];
          }
        } else {
          if (!itemIsSelected(item)) {
            newSelectedItems = [item];
          }
        }
      }

      setSelected(newSelectedItems);
    }
  });

  //   console.log('Space', selectionSpace)
  return [selected, handleSelectionChange, setSelectionSpace, itemIsSelected];
}

useSelect.defaultProps = {
  initialSelection: [],
};
