export const kundaliLines1 = (w, h) => ([
  {move: [w/3, 0], line1: [w/3*2, 0],line2:[w/3*2, h/3],line3:[w/3, h/3]},
  {move: [0, 0], line1: [w/3, 0],line2:[w/3, h/3]},
  {move: [0, 0], line1: [0, h/3],line2:[w/3, h/3]},
  {move: [0, h/3], line1: [0, h/3*2],line2:[w/3, h/3*2],line3:[w/3, h/3]},
  {move: [0, h/3*2], line1: [0, h],line2:[w/3, h/3*2]},
  {move: [0, h], line1: [w/3, h],line2:[w/3, h/3*2]},
  {move: [w/3, h], line1: [w/3*2, h],line2:[w/3*2, h/3*2],line3:[w/3, h/3*2]},
  {move: [w/3*2, h], line1: [w, h],line2:[w/3*2, h/3*2]},
  {move: [w/3*2, h/3*2], line1: [w, h],line2:[w, h/3*2]},
  {move: [w, h/3*2], line1: [w, h/3],line2:[w/3*2, h/3],line3:[w/3*2, h/3*2]},
  {move: [w/3*2, h/3], line1: [w,0],line2:[w,h/3]},
  {move: [w/3*2, h/3], line1: [w,0],line2:[w/3*2,0]},
]);

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 60 * scale,
      width: 134 * scale,
      top: 20 * scale,  
      left: 133 * scale,
    },
    sthaana: {  
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top:  4 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      left: 195 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 13 * scale,
      left: 80 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 102 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 72 * scale,
      left: 120 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 59 * scale,
      width: 52 * scale,
      top: 34 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 21 * scale,
      left: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      left: 105 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 74 * scale,
      width: 110 * scale,
      top: 113 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 97 * scale,  
      left: 50 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 133 * scale,
      left: 119 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 203 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 79 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 79 * scale,
      left: 103 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 61 * scale,
      width: 49 * scale,
      top: 220 * scale,
      left: 84 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 61 * scale,
      left: 107 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 70 * scale,
      left: 118 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 58 * scale,
      width: 133 * scale,
      top: 220 * scale,
      left: 134 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 62 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 78 * scale,
      left: 195 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 61 * scale,
      width: 51 * scale,
      top: 220 * scale,
      right: 83 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 61 * scale,
      right: 103 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 72 * scale,
      right: 119 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 57 * scale,
      width: 52 * scale,
      top: 200 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 80 * scale,
      right: 21 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 79 * scale,
      right: 103 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 74 * scale,
      width: 110 * scale,
      top: 113 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 97 * scale,  
      right: 50 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 133 * scale,
      right: 119 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 60 * scale,
      width: 52 * scale,
      top: 36 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 23 * scale,
      right: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      right: 105 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 16 * scale,
      right: 80 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      right: 102 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 72 * scale,
      right: 120 * scale,
    }
  },
];
