import React, { useState, useEffect } from "react";
import { Redirect, useLocation, Prompt, useHistory } from "react-router-dom";
import html2canvas from "html2canvas";

import api from "src/lib/api";
import H4Heading from "src/core/component/grid/H4Heading";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import SimpleAlert from "src/core/component/SimpleAlert";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { images } from "src/image";
import OverlayLoading from "src/core/component/loader/OverlayLoading";
import InstructEntitySelection from "../InstructEntitySelection";
import Categories from "./Categories";
import { useTheme } from "src/theme/component/ThemeContext";

const QuestionAnswerContent = () => {
  const location = useLocation();
  const [isDarkMode] = useTheme();
  const history = useHistory();
  const { isAuthenticated } = useAuthInfo();
  const [categories, setCategories] = useState([]);
  const [answerCategories, setAnswerCategories] = useState([]);
  const [requestPaylod, setRequestPayload] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);
  const [answerData, setAnswerData] = useState(null);
  const [answerMetaData, setAnswerMetaData] = useState(null);
  const [orderLines, setOrderLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [blockNaviation, setBlockNavigation] = useState(false);
  const [emailProcessing, setEmailProcessing] = useState(false);

  const [kundaliImages, setKundaliImages] = useState();

  useEffect(() => {
    fetchCategories().then();
  }, []);

  const fetchCategories = async () => {
    try {
      const result = await api.get(`v1/question-category`);
      const categoryData = result.data?.result?.data || [];
      setCategories(categoryData);
    } catch (error) {
      console.log("Failed to fetch categories");
    }
  };

  useEffect(() => {
    if (location.state && location.state?.answerState) {
      setRequestPayload(location.state.answerState);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      requestPaylod &&
      requestPaylod?.order_id &&
      requestPaylod?.invoice_id &&
      requestPaylod?.payment_reference
    ) {
      fetchOrderAnswers().then();
    }
  }, [requestPaylod]);

  // blocking navigation to other page only when user is authenticated and currently has an answer data.
  useEffect(() => {
    requestPaylod && setBlockNavigation(true);
  }, [requestPaylod]);

  const fetchOrderAnswers = async () => {
    setLoading(true);
    const payload = {
      'invoice_id': requestPaylod.invoice_id,
      'payment_reference': requestPaylod.payment_reference,
      'payment_gateway': requestPaylod.payment_gateway,
    };

    try {
      const result = await api.post(
        `v1/question_order/ask/${requestPaylod.order_id}`,
        payload
      );
      // const result = await api.post(`v1/question_order/ask/${requestPaylod.order_id}`,{});
      setAnswerResponse(result);
      setLoading(false);
    } catch (error) {
      setError(error?.message || "Error on fetching answers.");
      PocketToast({
        type: "error",
        message: "Error on fetching answers",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (answerResponse) {
      const answer = answerResponse?.data?.result?.data || null;
      const metaData = answerResponse?.data?.result?.metadata || null;
      setAnswerMetaData(metaData);
      setAnswerData(answer);
    }
  }, [answerResponse]);

  useEffect(() => {
    if (
      answerData &&
      answerData?.order_lines &&
      answerData.order_lines?.length > 0
    ) {
      setOrderLines(answerData.order_lines);
    }
  }, [answerData]);

  useEffect(() => {
    if (orderLines && categories && orderLines.length) {
      let questionCategories = orderLines.map(
        (line) => line.question.category_id
      );
      let categIds =
        (questionCategories && [...new Set(questionCategories)]) || [];
      let answerCategs =
        categories && categories.filter((c) => categIds.includes(c.id));
      setAnswerCategories(answerCategs);
    }
  }, [orderLines, categories]);

  const emailHandler = async (line, kundaliImageNames) => {
    setEmailProcessing(true);
    // console.log("🚀 kundaliImageNames", kundaliImageNames)

    const downloadImage = async (chartName) => {
      const domElement = document.getElementById(chartName);
      const chartCanvas = await html2canvas(domElement, {
        onclone: (document) => {},
      });
      return chartCanvas.toDataURL("image/png");
    };

    const kundaliImages = await Promise.all(
      kundaliImageNames.map((name) => downloadImage(name))
    );

    const payload = {
      'invoice_id': requestPaylod.invoice_id,
      'payment_reference': requestPaylod.payment_reference,
      'payment_gateway': requestPaylod.payment_gateway,
      'order_id': requestPaylod.order_id,
      'charts': kundaliImages || [],
    };
    // console.log("🚀 payload", payload)

    try {
      const result = await api.post(
        `v1/question_order/email/${line.id}`,
        payload
      );
      setEmailProcessing(false);
      PocketToast({
        type: "success",
        message: "Email sent successfully",
      });
    } catch (error) {
      PocketToast({
        type: "error",
        message: "Error on sending email",
      });
      setEmailProcessing(false);
    }
  };

  // console.log('Answer Data', answerData);
  // console.log('Answer Meta Data', answerMetaData);
  // console.log('Answer lines', orderLines);
  // console.log('Categories Selected', answerCategories);
  // console.log('all categs', categories);
  // console.log('Block', blockNaviation);
  // console.log('Request Data', requestPaylod);
  // console.log('Response',answerResponse);
  // console.log('Processing email....', emailProcessing);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/instant-prediction/auth",
          state: {
            from: history.location.pathname,
            search: history.location.search,
          },
        }}
      />
    );
  }

  return requestPaylod ? (
    <>
      {/* show email processing loading as an overlay */}
      {emailProcessing && <OverlayLoading isDarkMode={isDarkMode} />}

      <H4Heading
        classes="rtp-heading d-none d-sm-block"
        heading={`${
          (orderLines &&
            orderLines.length > 1 &&
            `Predictions for your questions`) ||
          `Prediction for your question`
        }`}
      />

      {/* Navigation block prompt */}
      <Prompt
        when={blockNaviation}
        message={`Are you sure you want to leave current page?`}
      />

      <div className="row">
        <div className="col-12">
          {loading && (
            <LoadingIcon>
              <p className="mb-0">Just a second ...</p>
              <p className="mb-0">We are preparing your prediction!</p>
            </LoadingIcon>
          )}

          {!loading && (
            <>
              {error && (
                <div className="col-12">
                  <SimpleAlert classes="alert-danger mb-4">
                    <p className="mb-0">{error}</p>
                  </SimpleAlert>
                </div>
              )}

              {answerData &&
                orderLines.length > 0 &&
                answerCategories.length > 0 && (
                  <>
                    {/*<Disclaimer
                      description={
                        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
                      }
                      isDarkMode={isDarkMode}
                    />*/}

                    <Categories
                      order={answerData}
                      orderLines={orderLines}
                      metaData={(answerMetaData && answerMetaData) || []}
                      categories={answerCategories}
                      emailHandler={emailHandler}
                      emailProcessing={emailProcessing}
                      setEmailProcessing={setEmailProcessing}
                      setKundaliImages={setKundaliImages}
                    />
                  </>
                )}
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="row justify-content-center">
      <div className="col-md-11">
        <InstructEntitySelection
          message={`You haven't bought any questions. You need to buy some questions to get prediction`}
          path={"/instant-prediction"}
          entityToGo={"get started"}
          icon={images["selected-answer.png"].default}
        />
      </div>
    </div>
  );
};

export default QuestionAnswerContent;
