import React from 'react'
import { useHistory } from 'react-router-dom';

export default function ActionLinkBeneficMaleficPage() {
  const history = useHistory();

  React.useEffect(() => {
    history.push({
      pathname: '/free-astrology',
      search: new URLSearchParams({'page': 'benefic-malefic'}).toString(),
      state: history.location.state,
    });

  }, []);

  return null;
}
