import React from "react";
import { Link } from "react-router-dom";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import TimeSetupForm from "./TimeSetupForm";


const StartChatSetupModal = ({
    hours, 
    setHours,
    isDarkMode, 
    showModal,
    handleModalClose,
    handlePayAndContine,
    handleFreeTrial,
    invalidHours,
    metaData,
    ...rest
}) => {

    handlePayAndContine = typeof handlePayAndContine === 'function' ? handlePayAndContine : function noop() { }
    handleFreeTrial = typeof handleFreeTrial === 'function' ? handleFreeTrial : function noop() { }

    const handleInputChange = (e) => {
        setHours(+e.target.value);
    }

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={false} centered={false} classes={'chat-setup__modal'  + (isDarkMode && ' dark' || '')}>
            <ModalBody>
                <div className={`chat-setup__modal-content text-center ${isDarkMode && 'dark' || ''}`}>
                    <h5 className="heading">Start your conversation.</h5>
                    
                    {/* TimeSetup */}
                    <TimeSetupForm 
                        hours={hours}
                        setHours={setHours}
                        isDarkMode={isDarkMode}
                        handleInputChange={handleInputChange}
                        invalidHours={invalidHours}
                    />

                    {/* Buttons */}
                    <button onClick={handlePayAndContine} className="pocket-btn">
                        Pay and Continue
                    </button>
                    <div className={`${metaData && metaData?.chat_message && 'd-none' || ''}`}>
                        <Link to="#" onClick={handleFreeTrial} className="start-trial d-inline-block mt-2">
                            Start 5 min free trial
                        </Link>
                    </div>

                </div>
            </ModalBody>
        </CommonModal>
    )
}

export default StartChatSetupModal