import React from "react";

import ConversationList from "./ConversationList";


const AstrologerHomeMobile = ({match, isDarkMode, ...rest}) => {
    
    return (
        <div className={`row gx-1 gx-lg-2 justify-content-center pocket-astrologer__chat astrologer ${isDarkMode && 'dark' || ''}`}>
            <div className="col-12">
                <div className={`astrologer-sidebar ${isDarkMode && 'dark' || ''}`}>
                    <ConversationList
                        isDarkMode={isDarkMode}
                    />
                </div>
            </div>
        </div>
    )
}

export default AstrologerHomeMobile;
