import React from "react";

import MainLayout from "src/core/component/MainLayout";
import { useTheme } from "src/theme/component/ThemeContext";
import Support from "src/customer-support/component/support/Support";

const CustomerSupportHome = ({ match, ...rest }) => {
  const [isDarkMode] = useTheme();

  return (
    <MainLayout className="customer-support-main">
      <section
        className={`pocket-customer-support ${(isDarkMode && "dark") || ""}`}
      >
        <Support isDarkMode={isDarkMode} />
      </section>
    </MainLayout>
  );
};

export default CustomerSupportHome;
