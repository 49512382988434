import React from "react";
import { Link } from "react-router-dom";

import { getFormattedDateTime } from "src/util/extra";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import { DeleteSvg, EditSvg } from "src/core/component/assets/svg/CommonSVG";
import "src/instant-prediction/scss/BirthProfile.scss";
import { Button } from "react-bootstrap";

const BirthProfile = ({
  profile,
  handleProfileSelect,
  isProfileSelected,
  editURL = "",
  onEdit = '',
  showCartcount = true,
  canDelete = false,
  className = "",
  handleProfileDelete=() => {}
}) => {
  // onEdit = typeof onEdit === "function" ? onEdit : function noop() {};
  
  const { draftCartLines } = useRTPInfo();

  const handleProfileClick = (e) => {
    handleProfileSelect(e, profile);
  };

  const draftQuestionsLength = draftCartLines.filter(
    (l) => l.profile_id === profile.id
  ).length;

  return (
    <>
      <div
        className={`rtp-birthProfile ${
          isProfileSelected(profile) ? " selected" : ""
        }${className ? " " + className : ""}`}
        role="button"
        onClick={handleProfileClick}
      >
        <figure className="rtp-birthProfile__image position-relative">
          <img
            src={profile?.relationship?.icon_url}
            alt={profile?.relationship?.name
              .split(" ")
              .map((item) => item.slice(0, 1))
              .join("")}
            style={{ color: profile?.relationship?.color }}
          />
          {profile?.profile_type == 'prashna_kundali_profile' &&
          <p className="rtp-birthProfile__image__badge">Question Birth Chart</p>
        }
        </figure>
        <div className="rtp-birthProfile__info">
          <h5 className="rtp-birthProfile__name">{profile?.name}</h5>
          <p className="rtp-birthProfile__time">
            <span>{getFormattedDateTime(profile?.dob, "MM/dd/yyyy")}</span>
            <span>{getFormattedDateTime(profile?.dob, "hh:mm tt")}</span>
          </p>
          <p className="rtp-birthProfile__address" title={profile?.address}>
            {profile?.address}
          </p>
          {
            onEdit!=''?(
              <div onClick={(e)=>onEdit(e,profile)} className="edit-profile">
          Edit Profile
            <EditSvg />
          </div>
            ):(
              <Link
            to={{
              pathname: editURL,
              state: {
                relationship_id: profile?.relationship_id,
                profile_id: profile?.id,
                mode: "edit",
              },
            }}
            className="edit-profile"
          >
            Edit Profile
            <EditSvg />
          </Link>
            )
          }
         
         
        </div>
        {showCartcount && draftQuestionsLength > 0 && (
          <span className="rtp-birthProfile__count" onClick={()=>{}}>
          {draftQuestionsLength}
          {draftQuestionsLength > 1 ? " Qn's" : " Qn"}
          </span>
          )}
        </div>
      {canDelete && (
        <Button
          className="p-0"
          variant="link"
          style={{ position: "absolute", top: "5px", right: "5px" }}
          onClick={(e) => handleProfileDelete(e, profile?.id)}
        >
          <DeleteSvg fill="#C72F1E" height="12" />
        </Button>
      )}
    </>
  );
};

export default BirthProfile;
