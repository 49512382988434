import React from 'react'

import HandleQuestionActionLink from 'src/instant-prediction/components/HandleQuestionActionLink';


export default function ActionLinkInstantPredictionPage({token, ...rest}) {
  
  return (
    <HandleQuestionActionLink
      token={token}
      source={"external"}
    />
  )
}
