import React from 'react'
import { Provider as StoreProvider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// Swiper Slider styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import 'src/scss/main.scss';

import AppRouter from './AppRouter';

import store from 'src/store';
import setupInterceptors from 'src/lib/setupInterceptors';

import SocketContextProvider from 'src/socket/component/SocketContext';
import ThemeContextProvider from 'src/theme/component/ThemeContext';
import {GoogleAds} from './googleAds/GoogleAds';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css2?family=Montserrat"
    }
  ]
};

// axios interceptors which dipatches auth state change action
setupInterceptors(store);

const GA_TRACKING_ID = process.env.GOOGLE_ANALYTIC_TRACKING_ID;
if (GA_TRACKING_ID) {
  ReactGA.initialize(GA_TRACKING_ID);
}

const PAYPAL_OPTIONS = {
  'client-id': process.env.PAYPAL_CLIENT_ID,
};

export default function App() {
  return (
    <StoreProvider store={store}>
      <SocketContextProvider>
        <ThemeContextProvider>
          <PayPalScriptProvider options={PAYPAL_OPTIONS}>
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
              {/* Toas messages */}
              <ToastContainer />
              <GoogleAds/>
              <AppRouter />
            </Elements>
          </PayPalScriptProvider>
        </ThemeContextProvider>
      </SocketContextProvider>
    </StoreProvider>
  )
}
