import React, {useState} from "react"

import { useTheme } from "src/theme/component/ThemeContext";
import CookieDialog from "./CookieDialog";
import useCookie from "src/gdpr/component/cookie/hook/useCookie";


const Cookie = ({ variant='overlay'}) => {

    const [isDarkMode, toggleTheme] = useTheme();

    const { cookieAccepted, acceptCookie } = useCookie();

    const handleAccept = () => {
        acceptCookie();
    }

  return cookieAccepted ? (<></>) : (

        <CookieDialog accepted={cookieAccepted} handleAccept={handleAccept} variant={variant} isDarkMode={isDarkMode} />
    )
}

export default Cookie

