import React from "react";

import ListLinkItem from "src/core/component/ListLinkItem";

const FooterNavLinks = ({ currentPage, isDarkMode, ...rest }) => {
  let navItems = [
    {
      url: "/cookies",
      page: "cookie",
      name: "Cookies",
    },
    {
      url: "/tos",
      page: "terms-of-use",
      name: "Terms of Use",
    },
    {
      url: "/privacy-policy",
      page: "privacy-policy",
      name: "Privacy Policy",
    },
    {
      url: "/payment-policy",
      page: "payment-policy",
      name: "Payment & Refund Policy"
    },

    {
      url: "/careers",
      page: "careers",
      name: "Careers",
    },
    {
      url: "/contact",
      page: "contact",
      name: "Contact",
    },
    {
      url: "/about",
      page: "about",
      name: "About",
    },
  ];

  return (
    <ul
      /* className={
        "list-unstyled mb-0 d-flex align-items-center justify-content-end pocket-footer__nav-links" +
        ((isDarkMode && " dark") || "")
      } */
      className={
        "list-unstyled mb-0 pocket-footer__nav-links" +
        ((isDarkMode && " dark") || "")
      }
    >
      {navItems.map((item, index) => {
        return (
          <ListLinkItem
            key={index}
            currentPage={currentPage}
            page={item.page}
            url={item.url}
            name={item.name}
          ></ListLinkItem>
        );
      })}
    </ul>
  );
};

export default FooterNavLinks;
