import React from "react";

const CenteredContainer = (props) => {
  return (
    <div className={"container-xl " + (props.classes || "")}>
      <div className="row justify-content-center">
        <div className={props.colClasses}>{props.children}</div>
      </div>
    </div>
  );
};

export default CenteredContainer;
