import React, {Fragment, useState} from "react";
import Slider from "react-slick";
import Button from "src/core/component/Button";
import {NextArrowSvg, PrevArrowSvg} from "src/core/component/assets/svg/CommonSVG";
import {NextSvg, PrevSvg} from "src/core/component/assets/svg/pagination/PaginationSvg";
import ProductSliderItem from "./ProductSliderItem";

const CustomPrevArrow = ({onClick,classNames="custom-arrow custom-prev-arrow"}) => {
  return (
    <button className={classNames} onClick={onClick}>
      <PrevArrowSvg/>
    </button>
  )
}
const CustomNextArrow = ({onClick,classNames="custom-arrow custom-next-arrow"}) => {
  return (
    <button className={classNames} onClick={onClick}>
      <NextArrowSvg/>
    </button>
  )
}
const ProductSlider = ({settings = {}, data = []}) => {
  return (
    <Fragment>
      <Slider {...settings}>
        {
          (data.length || null) && data.map((item) => {
            return (
              <ProductSliderItem
                key={item.id}
                item={item}
              />
            )
          })
        }
      </Slider>
    </Fragment>
  )
}

export {CustomPrevArrow, CustomNextArrow};
export default ProductSlider;
