import React, {useState, useEffect, useRef} from "react";

import {useTheme} from "src/theme/component/ThemeContext";
import Questions from "./Questions";
import useWidth from "src/core/hook/useWidth";


const Categories = ({
    order,
    orderLines,
    metaData,
    categories,
    emailHandler,
    emailProcessing,
    setEmailProcessing,
    setKundaliImages,
    ...rest
}) => {
    const [isDarkMode] = useTheme();
    const containerRef = useRef(null);
    const [parentWidth] = useWidth(containerRef);
    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        if(categories?.length > 0) {
            setActiveCategory(categories[0])
        }
    }, [categories])

    const handleClick  = (item) => {
        setActiveCategory(item);
    }

    // console.log('Active category', activeCategory);

    return (
        <>
            <div className="row" ref={containerRef}>
                <div className="col-12">
                    <div className={"category-pills d-flex align-items-center justify-content-start mt-3" + (isDarkMode && ' dark' || '')}>
                        {
                            categories && categories.map((item, index) => {
                                return (
                                    <div className={"category-item rtp-answer " + (activeCategory && activeCategory['id'] === item['id'] && 'active' || '')} key={index} onClick={() => {handleClick(item)}} style={activeCategory && activeCategory['id'] === item['id'] && {border: `1px solid ${activeCategory.color}`} || {}}>
                                        {item.icon_url && (
                                            // <div className="icon d-flex align-items-center justify-content-center" style={{backgroundColor: `${item.color && item.color || ''}`}}>
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <img src={item.icon_url} className="img-fluid" alt="" />
                                            </div>
                                        )}
                                        <span className="title">{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="col-12">
                    <Questions
                        order={order}
                        orderLines={orderLines}
                        metaData={metaData}
                        parentWidth={parentWidth}
                        category={activeCategory}
                        emailHandler={emailHandler}
                        emailProcessing = {emailProcessing}
                        setEmailProcessing = {setEmailProcessing}
                        setKundaliImages = {setKundaliImages}
                        isDarkMode={isDarkMode}
                    />
                </div>
            </div>
        </>
    )
}

export default Categories
