import api from 'src/lib/api';

export async function getPosts(params) {
    return api.get(`v1/forum-posts?${params}`);
}

export async function getPostDetails(id) {
  return api.get(`v1/forum-posts/${id}`);
}

export async function getPostReplies(id, params) {
  if(params){
      return api.get(`v1/forum-posts/${id}/replies?${params}`);
  }else {
      return api.get(`v1/forum-posts/${id}/replies`);
  }
}