import React from "react";

import { PrevArrowSvg, CarouselPrevIcon } from "src/core/component/assets/svg/CommonSVG";


const PreviousArrow = ({className, style, onClick, isDarkMode=false, astro=false, title="", ...rest}) => {

  return (
        <div className={`${className}${isDarkMode && ' dark' || ''}${!astro && ' common' || ''}`} onClick={onClick}>
            {
              title && <strong>{title}</strong>
            }
            {
                astro ? <CarouselPrevIcon /> : <PrevArrowSvg />
            }
        </div>
    )
}

export default PreviousArrow;
