import React from "react";
import {Autoplay, Pagination, Navigation} from "swiper";

import Carousel from "src/core/component/slider/Carousel";
import SwiperCarousel from "src/core/component/slider/SwiperCarousel";
import FeaturedPromoCard from "./FeaturedPromoCard";

const FeaturedPromoSlider = ({ promosData, isDarkMode, ...rest }) => {
  const settings = {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    freeMode: false,
    slidesPerView: 1,
    spaceBetween: 10,
    modules: [Autoplay, Pagination, Navigation],
    pagination: {
      clickable: true,
    },
  };

  /*
  const [isDragging, setIsDragging] = useState(false);
  const settings = {
    infinite: promosData.length > 1,
    autoplay: true,
    dots: true,
    arrows: false,
    speed: 400,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: isDarkMode
      ? "pocket-promos__slider dark"
      : "pocket-promos__slider",
    responsive: [],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };
  */

  // console.log(promosData);

  return (
    <div
      className={
        "pocket-promos__featured slide-content" +
        ((isDarkMode && " dark") || "")
      }
    >
      <SwiperCarousel
        {...settings}
        items={promosData.map((item) => (
          <FeaturedPromoCard
            key={item.id}
            item={item}
            isDarkMode={isDarkMode}
          />
        ))}
      />
    </div>
  );
};

export default FeaturedPromoSlider;
