import React, { useEffect, useMemo,useRef } from "react";
import { Link } from "react-router-dom";
import { FreeMode, Navigation,Mousewheel } from "swiper";
import useAuthInfo from "src/auth/hook/auth-info";
import BirthProfile from "./BirthProfile";
import { PlusIcon } from "src/backend/images/Svgs";
import { PlusSvg } from "src/core/component/assets/svg/CommonSVG";
import "src/instant-prediction/scss/BirthProfiles.scss";
import SwiperCarousel from "src/core/component/slider/SwiperCarousel";
import { CustomPrevArrow, CustomNextArrow } from "src/shop/component/shop/ProductSlider";
import BirthProfileFilter from "./BirthProfileFilter";

const BirthProfiles = ({
  filter,
  setFilter,
  setProfileSelectionSpace,
  handleProfileSelect,
  handleRelationshipSelect,
  isProfileSelected,
  isRelationshipSelected,
  resetSelectionOnFilter = true,
  showCartcount = true,
  createEditURL = "",
  hasFilter = true,
  hasFilterTitle = true,
  filterTitle = "",
  isFromFreeAstrology = false,
  birthProfileSwiperRef = useRef(null)
}) => {
  const { birthProfiles } = useAuthInfo();

  const filteredBirthProfiles = useMemo(() => {
    const filtered_regular_profile = birthProfiles.filter(profile=>profile.profile_type == "regular_kundali_profile")
    const filtered_prashna_profile = birthProfiles.filter(profile=>profile.profile_type == "prashna_kundali_profile")
    const filtered_birth_profile = [...filtered_regular_profile,...filtered_prashna_profile]

    if (filtered_birth_profile.length && filter.relationship_id !== "all") {
      if (filter?.p_relationship_id === "all") {
        return filtered_birth_profile;
      } else {
        return filtered_birth_profile.filter(
          (profile) => profile.relationship_id === filter.relationship_id
        );
      }
    } else {
      return filtered_birth_profile;
    }
  }, [birthProfiles, filter]);

  useEffect(() => {
    if ((birthProfiles || []).length) {
      setProfileSelectionSpace(birthProfiles);
    }
  }, [birthProfiles]);

  const settings = {
    freeMode: true,
    slidesPerView: 1.1,
    modules: [Navigation, FreeMode,Mousewheel],
    spaceBetween: 10,
    mouseWheel :{forceToAxis:true},
    swiperRef : birthProfileSwiperRef,
    navigation: {
      prevEl: ".instant-pred-navigation-prev",
      nextEl: ".instant-pred-navigation-next",
    },
    breakpoints: {
      576: {
        slidesPerView: 1.6,
      },
      768: {
        slidesPerView: 2.2,
      },
      992: {
        slidesPerView: 2.6,
      },
    },
  };

  // console.log("birth profiles", birthProfilesFiltered);
  // console.log("birth profiles", birthProfiles);

  return (
    <div className="rtp-birthProfiles">
      {hasFilter && (
        <BirthProfileFilter
          filter={filter}
          setFilter={setFilter}
          handleProfileSelect={handleProfileSelect}
          resetSelection={resetSelectionOnFilter}
          handleRelationshipSelect={handleRelationshipSelect}
          isRelationshipSelected={isRelationshipSelected}
          hasTitle={hasFilterTitle}
          title={filterTitle}
          createURL={filteredBirthProfiles.length>0?createEditURL:''}
          isFromFreeAstrology = {isFromFreeAstrology}
        />
      )}

      {(filteredBirthProfiles.length > 0 && (
        <>
        <SwiperCarousel
          {...settings}
          items={filteredBirthProfiles.map((profile) => (
            <BirthProfile
              key={profile.id}
              profile={profile}
              handleProfileSelect={handleProfileSelect}
              isProfileSelected={isProfileSelected}
              editURL={createEditURL}
              showCartcount = {isFromFreeAstrology?false:showCartcount}
            />
          ))}
        />
        <span className="d-flex gap-2 pt-2 justify-content-end">
            <CustomPrevArrow classNames="instant-pred-navigation-prev" />
            <CustomNextArrow classNames="instant-pred-navigation-next"/>
        </span>
        </>
      )) || (
        <div className="rtp-auth__card">
          <div className="rtp-auth__text">
            <h4>Please <span style={{color: "#58B368"}}>create</span> a birth profile!</h4>
            {/* <p>We need birth profiles to provide you with predictions</p> */}
          </div>
          <div className="rtp-auth__buttons">
            <Link
              to={{
                pathname: createEditURL,
                state: {
                  relationship_id: filter.p_relationship_id,
                },
              }}
              className="pocket-btn"
            >
              <PlusSvg />
              Add Birth Profile
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default BirthProfiles;
