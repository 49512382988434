import React, {useState} from "react";

import ConversationList from "./ConversationList";
import AstrologerChatContainer from "./AstrologerChatContainer";


const AstrologerChat = ({isDarkMode, match, ...rest}) => {
    const conversation = null;
    const [activeConversation, setActiveConversation] = useState(null);

    return (
        <div className={`row gx-1 gx-lg-2 justify-content-center pocket-astrologer__chat astrologer ${isDarkMode && 'dark' || ''}`}>
            <div className="col-sm-4 col-lg-3 d-none d-sm-block">
                <div className={`astrologer-sidebar ${isDarkMode && 'dark' || ''}`}>
                    <ConversationList
                        isDarkMode={isDarkMode}
                        setActiveItem={setActiveConversation}
                        match={match}
                    />
                </div>
            </div>
            <div className="col-sm-8 col-lg-9">
                <div className={`chat-playground ${isDarkMode && 'dark' || ''}`}>
                    <AstrologerChatContainer
                        isDarkMode={isDarkMode}
                        match={match}
                    />
                </div>
            </div>
        </div>
    )
}

export default AstrologerChat;
