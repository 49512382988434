import React from "react";

function ExclamationSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="44"
      fill="none"
      viewBox="0 0 50 44"
    >
      <path
        fill="#F5C037"
        d="M20.67 2.5c1.924-3.333 6.736-3.333 8.66 0l19.486 33.75c1.924 3.333-.481 7.5-4.33 7.5H5.514c-3.849 0-6.254-4.167-4.33-7.5L20.67 2.5z"
      ></path>
      <path
        fill="#fff"
        d="M26.307 11h-3.221v16.107h3.221V11zM22.5 33.696c0 1.172 1.025 2.197 2.196 2.197 1.172 0 2.197-1.025 2.197-2.197 0-1.171-1.025-2.196-2.197-2.196-1.171 0-2.196 1.025-2.196 2.196z"
      ></path>
    </svg>
  );
}

export default ExclamationSVG;
