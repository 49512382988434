import { useDispatch, useSelector } from "react-redux";

import { 
    acceptCookie as cookieAcceptAction
} from "src/gdpr/component/cookie/redux/redux";


export default function useCookie() {
    const dispatch = useDispatch();
    const cookie = useSelector(state => state.cookie)

    const _cookieAccepted = () => {
        return cookie.accepted
    }

    const acceptCookie = () => {
        dispatch(cookieAcceptAction())
    }

    return {
        get cookieAccepted() {
            return _cookieAccepted()
        },
        acceptCookie
    };
}