import React from "react";


const CategorySliderItem = ({
    item, 
    handleCategorySelect,
    isSelected=false,
    isDarkMode, 
    ...rest 
}) => {

    let style = item && isSelected ? `1.5px solid ${item.color}` : '';

    return ( 
        <div className={"category__slider-item" + (isDarkMode && ' dark' || '')} onClick={(e) => {handleCategorySelect(e, item)}} style={{'border': style}}>
            <div className="d-flex justify-content-center align-items-center">
                {item.icon_url && (
                    // <div className="icon d-flex align-items-center justify-content-center" style={{backgroundColor: `${item.color && item.color || ''}`}}>
                    <div className="icon d-flex align-items-center justify-content-center">
                        <img src={item.icon_url} className="img-fluid" alt="" />
                    </div>
                )}

                <span className="name">
                    {item?.name || ''}
                </span>
            </div>
            
        </div>
    )
}

export default CategorySliderItem
