import React from 'react';
import { toast } from 'react-toastify';


export function PocketToast({type, message, ...options}) {

    const settings = {
        position: options?.position || toast.POSITION.TOP_CENTER,
        autoClose: options?.autoClose || 1500,
        pauseOnHover: options?.pauseOnHover || true,
        closeOnClick: options?.closeOnClick || true,
        className: 'Pocket-toast',
        bodyClassName: 'Pocket-toast__body',
        progressClassName: 'Pocket-toast__progress',
        theme: options?.theme || 'colored',
        toastId: message,
    }

    // console.log('Settings', settings)

    switch(type) {
        case 'error':
            toast.error(message, settings);
            return ;
        case 'warning':
            toast.warn(message, settings);
            return;
        case 'success':
            toast.success(message, settings);
            return;
        case 'info':
            toast.info(message, settings);
            return;
        default:
            toast(message, settings);
    }
}