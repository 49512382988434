import React from "react";

import MainLayout from "src/core/component/MainLayout";
import Contact from "../contact/Contact";

const ContactPage = () => {
  return (
    <MainLayout>
      <Contact />
    </MainLayout>
  )
}

export default ContactPage;
