import React from 'react'

import useAuthInfo from 'src/auth/hook/auth-info';
import api from 'src/lib/api';

export default function ChatMessageForm({ conversation, onSent, onError }) {
  const authInfo = useAuthInfo();
  const [formData, setFormData] = React.useState({
    'conversation_id': conversation.id,
    'content': '',
  });

  onSent = typeof onSent === 'function' ? onSent : function noop() { }

  React.useEffect(() => {
    setFormData({ ...formData, 'conversation_id': conversation.id });
  }, [conversation]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    const tempLocalID = Math.ceil(Math.random() * 1e20);

    try {
      const payload = Object.assign({}, formData, {
        'local_id': tempLocalID.toString(),
      });
      setFormData({
        ...formData,
        'content': '',
      });

      onSent({
        content: payload.content,
        conversation_id: payload.conversation_id,
        user_id: authInfo?.profile?.id,
        user: authInfo?.profile,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        id: tempLocalID,
      });

      const res = await api.post('v1/messages', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      onSent(res['result']['data'], tempLocalID);
    } catch (error) {
      error = error.response?.data?.error || error;
      console.log('Error sending message', error.message);
      onError(error.message, tempLocalID);
    }
  }


  return (<form className="form w-100 bg-light mb-0 py-3 px-2" onSubmit={handleMessageSubmit} style={{
    // 'position': 'absolute',
    // 'bottom': 0,
    // 'left': 0,
  }}>
    <div className="input-group input-group-sm">
      <input className="form-control rounded" name="content" value={formData.content} type="text" onChange={handleInputChange} placeholder="Enter your message..." autoFocus></input>

      <span className="ms-2 input-group-btn">
        <button type="submit" className="btn btn-primary btn-sm px-3">Send</button>
      </span>
    </div>
  </form>)
}
