import React from "react";
import { useHistory } from "react-router";

import ProductItem from "./ProductItem";


const ProductLines = ({lines, order, path, isDarkMode, ...rest }) => {
    const history = useHistory();

    const handleMoreClick = () => {
        history.push(`${path}/order/${order.id}`)
    }

    return ( 
        <div className={"order-lines" + (isDarkMode && ' dark' || '')}>
            <div className="d-flex align-items-start justify-content-start">
                {lines && lines.slice(0,4).map((line, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ProductItem
                                    item={line}
                                    isDarkMode={isDarkMode}
                                />
                            </React.Fragment>
                        )
                    })
                }

                {lines.length > 4 && (
                    <span className="total" onClick={handleMoreClick}>+{lines.length - 4}</span>
                )}
            </div>
        </div>
    )
}

export default ProductLines
