import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";

import useAuthInfo from "src/auth/hook/auth-info";
import SimpleAlert from "src/core/component/SimpleAlert";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import api from "src/lib/api";
import { clearForm } from "src/util/extra";
import SupportItem from "./SupportItem";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useSocket } from "src/socket/component/SocketContext";
import MessageForm from "src/core/component/support/MessageForm";
import uploadFile from 'src/lib/http/upload-file';


const SupportContainer = ({ isDarkMode, ...rest }) => {
  const socket = useSocket();
  const { isAuthenticated, profile } = useAuthInfo();
  const replyCotainerRef = useRef(null);
  const [supports, setSupports] = useState([]);
  const [error, setError] = useState(null);
  const [supportResponse, setSupportResponse] = useState(null);
  const [paginationData, setPaginationData] = useState({
    has_next: false,
    next: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    reply: "",
    image: "",
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [replyTextLength, setReplyTextLength] = useState(512);
  const [limit, setLimit] = useState(2);
  const inputRef = useRef();

  useEffect(() => {
    fetchUserSupportTickets();
  }, []);

  const fetchUserSupportTickets = async () => {
    if (loading) return;

    const query = {
      page: paginationData?.next || "",
      limit: limit,
    };

    setLoading(true);
    try {
      const result = await api.get(
        `v1/support?${queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      setSupportResponse(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let errorData = error?.response?.data?.error || error
      PocketToast({
        type: "error",
        message: `${
          (errorData?.message && errorData.message) || "Failed to fetch support tickets"
        }`,
      });
    }
  };

  useEffect(() => {
    if (!loading && supportResponse) {
      const supportData = supportResponse?.data?.result?.data || [];
      const metaData = supportResponse?.data?.result?.metadata || null;
      setSupports((prevSupports) => [...prevSupports, ...supportData]);
      setPaginationData(() => metaData);

      const errorMsg = supportResponse?.error?.message || null;
      setError(errorMsg);
    }
  }, [supportResponse, loading]);

  useEffect(() => {
    if (error) {
      PocketToast({
        type: "error",
        message: `${(error && error) || "Something went wrong"}`,
      });
    }
  }, [error]);

  // Update last read reply
  useEffect(() => {
    if (supports.length) {
      const first = supports[0];
      const last_reply =
        (first?.support_replies &&
          first.support_replies.length &&
          first.support_replies.slice(-1)) ||
        [];
      if (last_reply.length) {
        socket.emit(
          "customer_support_reply:update_user_last_read_reply",
          JSON.stringify({ data: last_reply[0] })
        );
      }
    }
  }, [supports]);

  useEffect(() => {
    const onSupportTicketCreate = ({ data: support }) => {
      setSupports((state) => [
        support,
        ...state.filter((s) => s.id != support.id),
      ]);
    };

    const onSupportTicketStateChange = ({ data: support }) => {
      setSupports((state) => {
        const index = state.findIndex((s) => s.id == support.id);
        const supportTicket = state[index];
        if (!supportTicket) {
          return state;
        }

        supportTicket.state = support.state;
        return [
          ...state.slice(0, index),
          supportTicket,
          ...state.slice(index + 1),
        ];
      });
    };

    const onSupportTicketDelete = ({ data: support_id }) => {
      setSupports((state) => [...state.filter((s) => s.id != support_id)]);
    };

    const onSupportNewReply = ({ data: reply }) => {
      onTicketNewReply(reply);
    };

    socket.on("customer_support:create", onSupportTicketCreate);
    socket.on("customer_support:state_change", onSupportTicketStateChange);
    socket.on("customer_support:delete", onSupportTicketDelete);
    socket.on("customer_support_reply:create", onSupportNewReply);

    return () => {
      socket.off("customer_support:create", onSupportTicketCreate);
      socket.off("customer_support:state_change", onSupportTicketStateChange);
      socket.off("customer_support:delete", onSupportTicketDelete);
      socket.off("customer_support_reply:create", onSupportNewReply);
    };
  }, []);

  // useEffect(() => {
  //     scrollToBottom();
  // }, [supports])

  const onTicketNewReply = (reply) => {
    setSupports((state) => {
      const ticketIndex = state.findIndex((s) => s.id == reply.support_id);
      const supportTicket = state[ticketIndex];
      if (!supportTicket) {
        return state;
      }

      const replyIndex = (supportTicket?.support_replies || []).findIndex(
        (r) => r.id === reply.id
      );

      let replies = [];
      if (replyIndex === -1) {
        replies = [...(supportTicket?.support_replies || []), reply];
      } else {
        replies = [
          ...(supportTicket?.support_replies || []).slice(0, replyIndex),
          reply,
          ...(supportTicket?.support_replies || []).slice(replyIndex + 1),
        ];
      }

      supportTicket.support_replies = [...replies];

      return [
        ...state.slice(0, ticketIndex),
        supportTicket,
        ...state.slice(ticketIndex + 1),
      ];
    });

    // scrollToBottom();
  };

  const scrollToBottom = () => {
    replyCotainerRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleMessageChange = (e) => {
    if (e.target.value.length === replyTextLength) {
      PocketToast({
        type: "warning",
        message: `Message should not be longer than ${replyTextLength} characters`,
      });
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const cleanupImageData = () => {
    inputRef.current.value = "";
    setFormData({ ...formData, image: "" });
    setImagePreviewUrl(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      let reader = new FileReader();
      let file = e.target.files[0];
      const { name: fileName, size } = file;
      const fileSize = (size / 1000).toFixed(2);

      if (fileSize > 512) {
        PocketToast({type: "error",message: `File too Big, file size should not be a greater than 512KB`,});
        inputRef.current.value = "";
        
        // resize image

        // reader.readAsDataURL(file);
        // reader.onload = (event)=>{
        //   let image_url = event.target.result
        //   let image = document.createElement('img')
        //   image.src = image_url
          
        //   image.onload = async(e) => {            
        //     let canvas = document.createElement('canvas')
        //     let WIDTH = 1000
        //     let ratio = WIDTH / e.target.width
        //     canvas.width = WIDTH
        //     canvas.height = e.target.height * ratio
            
        //     const context = canvas.getContext('2d')
        //     context.drawImage(image,0,0,canvas.width,canvas.height)
        //     let new_image_url = context.canvas.toDataURL("image/jpeg",90)

        //     const blob = await fetch(new_image_url).then(it => it.blob())
        //     let new_file = new File([blob], file.name, {type:"image/jpeg", lastModified:new Date()});
        //     setFormData({ ...formData, image: new_file });
        //     setImagePreviewUrl(reader.result);
        //   }
        // }
        return;
      }
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        
        // scroll to bottom when file is selected for uploading
        scrollToBottom()
      };
      reader.readAsDataURL(file);
      setFormData({ ...formData, image: file });
    } else {
      cleanupImageData();
    }
  };

  const handleImageRemove = () => {
    if(isSubmitting) return;

    cleanupImageData();
  };

  const handleReplySubmit = (e) => {
    e.preventDefault();
    submitReply();
  };

  const submitReply = async () => {
    setError(null);
    setIsSubmitting(true);

    try {
      let payload = Object.assign({}, {reply: formData.reply, image_url: ""})

      if(formData["image"] && formData['image'] instanceof File) {
        const imageUrl = await uploadFile(formData['image'], 'uploads/support');
        payload = Object.assign(payload, {image_url: imageUrl})
      }
      
      const response = await api.post("v2/support/reply", payload);
      onTicketNewReply(response.data?.result?.data);
      clearForm(formData);
      inputRef.current.value = "";
      setImagePreviewUrl((prev)=>null);
    } catch (error) {
      let errorData = error?.response?.data?.error || error
      PocketToast({
        type: "error",
        message: `${
          (errorData?.message && errorData.message) || "Failed to submit a reply"
        }`,
      });
    } finally {
      setIsSubmitting(false);
      // scrollToBottom();
    }
  };

  // console.log('Form Data', formData);
  // console.log('PReview', imagePreviewUrl)
  // console.log('Input ref', inputRef.current);
  // console.log('Supports', supports)
  // console.log('Error', error)
  // console.log('User Info', profile);
  // console.log('Pagination Data', paginationData);

  return (
    <div
      className={`pocket-customer-support__container ${
        (isDarkMode && "dark") || ""
      }`}
    >
      {error && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SimpleAlert classes="alert-danger my-3">
                <p className="mb-0">{error}</p>
              </SimpleAlert>
            </div>
          </div>
        </div>
      )}

      <div
        className="pocket-customer-support__data"
        id="customerSupportTickets"
      >
        {/* Generally placed at bottom of the parent div to scroll into it but because column-inverse feature it should be placed at top */}
        <div ref={replyCotainerRef} />

        {!supportResponse && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <LoadingIcon />
              </div>
            </div>
          </div>
        )}

        {supportResponse &&
          !supportResponse["error"] &&
          supports.length > 0 &&
          paginationData && (
            <InfiniteScroll
              dataLength={supports.length}
              next={fetchUserSupportTickets}
              hasMore={
                paginationData?.has_next || paginationData?.next || false
              }
              scrollableTarget="customerSupportTickets"
              scrollThreshold={"5px"}
              loader={<LoadingIcon />}
              // style is must for the scroll top feature
              style={{ display: "flex",height:'60vh', flexDirection: "column-reverse" }}
              inverse={true}
              endMessage={<></>
                // <p className="tickets-exhusted" style={{ textAlign: "center" }}>
                //   <b>No more Tickets!</b>
                // </p>
              }
            >
              {supports.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <SupportItem
                      support={item}
                      userInfo={profile}
                      isDarkMode={isDarkMode}
                    />
                  </React.Fragment>
                );
              })}
            </InfiniteScroll>
          )}
      </div>

      {/* Message form */}
      <div className="pocket-customer-support__form">
        <div className="container-xl">
          <MessageForm
            formData={formData}
            handleChange={handleMessageChange}
            onChangeFile={handleFileChange}
            handleSubmit={handleReplySubmit}
            isDarkMode={isDarkMode}
            isSubmitting={isSubmitting}
            maxLength={replyTextLength}
            imagePreviewUrl={imagePreviewUrl}
            handleImageRemove={handleImageRemove}
            inputRef={inputRef}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportContainer;
