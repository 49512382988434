import React from "react";

import pocketloading from 'src/image/pocket-loading.gif'

const OverlayLoading = ({isDarkMode, ...rest}) => {
  
  return (
        <div className="overlay-loading">
            <div className="overlay-loading__container">
                <div className="data-loading-icon">
                    <img src={pocketloading} className="img-fluid" alt="Loading..." />
                </div>
            </div>  
        </div>
    )
}

export default OverlayLoading
