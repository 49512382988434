import React from "react";

import useAuthInfo from "src/auth/hook/auth-info";

import {
  NextArrowSvg,
  PrevArrowSvg,
} from "src/core/component/assets/svg/CommonSVG";
import "../scss/PrevNextPage.scss";
import { Link } from "react-router-dom";

const PrevNextPage = ({
  onClick = () => {},
  isEnabled = false,
  hasPrev = true,
  hasNext = true,
}) => {
  const { isAuthenticated } = useAuthInfo();

  return (
    <div className="rtp-prevNextPage">
      {hasPrev && (
        <Link className="rtp-prevPage" to="/">
          <PrevArrowSvg />
        </Link>
      )}
      {hasNext && (
        <span
          role="button"
          className={
            "rtp-nextPage" + (!isAuthenticated || !isEnabled ? " disabled" : "")
          }
          onClick={onClick}
        >
        <span className="next_text">Add to cart</span> <NextArrowSvg />
        </span>
      )}
    </div>
  );
};

export default PrevNextPage;
