import React from "react";

import usePromo from "src/promos/hook/usePromo";
import { ReloadSvg } from "src/core/component/assets/svg/CommonSVG";
import { useTheme } from "src/theme/component/ThemeContext";


const NewPromoAlert = ({refresh, ...rest}) => {
    const [isDarkMode] = useTheme();
    const {setupPromoData, updatePromoLastCheck} = usePromo();

    const handleRefresh = (e) => {
        e.preventDefault();
        const datetime = new Date().format('yyyy-MM-ddTHH:mm:ss');
        updatePromoLastCheck(datetime);
        setupPromoData(datetime);
        refresh();
    }

    return (
        <>
        <div className={`new-item__alert ${isDarkMode && 'dark' || ''}`}>
            <button className="pocket-btn new-alert d-flex align-items-center" onClick={handleRefresh}>
                <ReloadSvg />
                <span className="title">New promos</span>
            </button>
        </div>
        </>
    )
}

export default NewPromoAlert;
