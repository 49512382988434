import React from "react";

import ScrollView from "src/core/component/ScrollView";

const PocketPills = ({
    items,
    isDarkMode,
...rest}) => {

    
    return (
        <div className="row">
            <div className="col-12">
                
                <div className={`pocket-pills ${isDarkMode && 'dark' || ''}`}>
                    <ScrollView className="w-100 d-flex align-items-center justify-content-start">
                        {
                            items && items.map((item, index) => {
                                return (
                                    <div className={`pocket-pills__item ${isDarkMode && 'dark' || ''}`} key={item.id}>
                                        <span className="name">{item?.name || item?.title || ''}</span>
                                    </div>
                                )
                            })
                        }
                    </ScrollView>
                        
                </div>
            </div>
        </div>
    )
}

export default PocketPills
