import React, { useState, useEffect } from "react";
import debounce from 'src/util/debounce';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: null,
      height:  null,
    });

    useEffect(() => {
        const handleResize = debounce(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 100);

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

export default useWindowSize