import React from "react"

export const PrevSvg = () => {
    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M-2.18557e-07 5L4.912 0.183059C5.1609 -0.0610201 5.56443 -0.0610201 5.81333 0.183058C6.06222 0.427135 6.06222 0.822864 5.81333 1.06694L1.80265 5L5.81333 8.93306C6.06222 9.17714 6.06222 9.57286 5.81333 9.81694C5.56443 10.061 5.1609 10.061 4.912 9.81694L-2.18557e-07 5Z" fill="#1D5DC2"/>
        </svg>
    )
}

export const NextSvg = () => {
    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 5L1.088 9.81694C0.839104 10.061 0.435566 10.061 0.186672 9.81694C-0.0622229 9.57287 -0.0622229 9.17714 0.186672 8.93306L4.19735 5L0.186672 1.06694C-0.0622225 0.822864 -0.0622225 0.427136 0.186672 0.183058C0.435566 -0.0610197 0.839104 -0.0610197 1.088 0.183058L6 5Z" fill="#1D5DC2"/>
        </svg>
    )
}

export const FirstSvg = () => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1C1 0.723858 0.776142 0.5 0.5 0.5C0.223858 0.5 0 0.723858 0 1V11C0 11.2761 0.223858 11.5 0.5 11.5C0.776142 11.5 1 11.2761 1 11V6.49032L5.412 10.8169C5.6609 11.061 6.06443 11.061 6.31333 10.8169C6.56222 10.5729 6.56222 10.1771 6.31333 9.93306L2.30265 6L6.31333 2.06694C6.56222 1.82286 6.56222 1.42713 6.31333 1.18306C6.06443 0.938979 5.6609 0.938979 5.412 1.18306L1 5.50968V1Z" fill="#1D5DC2"/>
        </svg>
    )
}

export const LastSvg = () => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11C6 11.2761 6.22386 11.5 6.5 11.5C6.77614 11.5 7 11.2761 7 11L7 1C7 0.723858 6.77614 0.5 6.5 0.5C6.22386 0.5 6 0.723858 6 1L6 5.50968L1.588 1.18306C1.33911 0.93898 0.935567 0.93898 0.686673 1.18306C0.437778 1.42713 0.437778 1.82286 0.686673 2.06694L4.69735 6L0.686672 9.93306C0.437778 10.1771 0.437778 10.5729 0.686672 10.8169C0.935566 11.061 1.3391 11.061 1.588 10.8169L6 6.49032L6 11Z" fill="#1D5DC2"/>
        </svg>
    )
}