import React from "react";
import MarkdownParse from "src/core/component/MarkdownParse";


const AnswerItem = ({title, description, innerRef, ...rest}) => {
    // console.log('Answer', description);

    return (
       <div className="answer-item" ref={innerRef}>
           <h5 className="title">
               {title}
           </h5>
           <div className="description">
               {description && (
                    <MarkdownParse
                        markdownText={description}
                    />
               )}

               {/* {description && description.split('\n').map((d, i) => <p key={i}>{d}</p>)} */}
               {/* {description} */}
           </div>
       </div>
    )
}

export default AnswerItem
