import React from "react";
import { Link } from 'react-router-dom';


const ProfileCardBody = ({
    isFromChart=false,
    title, 
    data, 
    fields, 
    titleIcon="",
    classes="", 
    ...rest}) => {
        
    return (
        <div className={"card-body " + classes}>
            {title && (
                <div className="card-title">
                    {titleIcon && (
                        <span className="title-icon">{titleIcon}</span>
                    )}
                    {title}
                </div>
            )}

            {
                data && fields.length > 0 &&
                <div className="card-text" style={isFromChart?{fontSize:'16px'}:{}}>
                    {
                        fields.map((field, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {!field.discardOnCardBody && field.showIcon && field.getIcon && (
                                        <div className={"d-flex align-items-center"}>
                                            <span className="card-icon">{field.getIcon(data)}</span> <span className="title">{field.getValue(data)}</span>
                                        </div>
                                    )}

                                    {!field.discardOnCardBody && !field.showIcon && !field.isMultiColumn && (
                                        <div className={"d-flex" + (field.isColumnDisplay && ' flex-column' || ' align-items-center')}>
                                            <span className="title">{field.displayText}</span>
                                            {
                                                field.getValue ? (
                                                    <span className="value">{field.getValue(data)}</span>
                                                ) : (
                                                    <span className="value">{data[field.name]?.toString()}</span>
                                                )
                                            }
                                        </div>
                                    )}

                                    {!field.discardOnCardBody && field.isMultiColumn && (
                                        <>
                                            {field.getValue(data)}
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default ProfileCardBody
