import React, { useState } from "react";

import api from "src/lib/api";
import firebase from "src/lib/firebase";
import { TwitterCircleSvg, LinkedinCircleSvg, GoogleCircleSvg, FacebookCircleSvg } from "src/core/component/assets/svg/CommonSVG";
import ConnectWithSocial from "src/core/component/ConnectWithSocial";
import { PocketToast } from "src/core/component/toast/PocketToast";
import useAuthInfo from "src/auth/hook/auth-info";
import { useTheme } from "src/theme/component/ThemeContext";

const SocialRegisterLogin = ({
    title,
    type,
    onError = function noop() { },
    onSuccess = function noop() { },
}) => {
    const { login: loginUser } = useAuthInfo();
    const [isDarkMode] = useTheme();

    // const [isInstagramInApp,setIsInstagramInApp] = useState(()=>{
    //     return (navigator?.userAgent?.indexOf("Instagram") > -1);
    // })

    const loginWithFirebaseUser = async (user) => {
        const idToken = await user.getIdToken();
        const res = await api.post("/v1/auth/id-token/signin", {
            id_token: idToken,
        });

        const data = res.data.result.data;

        PocketToast({
            type: "success",
            message: "Login successful.",
        });

        loginUser(data["access_token"], data["refresh_token"]);
        onSuccess({
            user: user,
            data: data
        });
    };

    const loginWithProvider = (provider) => {
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((authInfo) => {
                return loginWithFirebaseUser(authInfo.user);
            })
            .catch((error) => {
                let userError = error.response?.data?.error || {
                    message: error.message,
                };
                PocketToast({
                    type: "error",
                    message: userError?.message || 'Unknown error',
                });
                onError(userError);
            });
    };

    const loginWithFacebook = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope("public_profile");
        provider.addScope("email");
        loginWithProvider(provider);
    };

    const loginWithGoogle = () => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope("profile");
            provider.addScope("email");
            loginWithProvider(provider);
    };

    let socialRegisterLoginOptions = [
        {
            name: 'facebook',
            title: `${type} with Facebook`,
            icon: <FacebookCircleSvg />,
            clickHandler: loginWithFacebook,
        },
        // {
        //     name: 'google',
        //     title: `${type} with Google`,
        //     icon: <GoogleCircleSvg />,
        //     clickHandler: loginWithGoogle,
        // },


        // {
        //     name: 'linkedin',
        //     title: `${type} with Linkedin`,
        //     icon: <LinkedinCircleSvg />,
        //     clickHandler: null,
        //     disabled: true,
        // },
        // {
        //     name: 'twitter',
        //     title: `${type} with Twitter`,
        //     icon: <TwitterCircleSvg />,
        //     clickHandler: null,
        //     disabled: true,
        // }
    ];

    const isInstagramInApp = (navigator?.userAgent?.indexOf("Instagram") > -1)

    if(isInstagramInApp == false){
        socialRegisterLoginOptions.push({
            name: 'google',
            title: `${type} with Google`,
            icon: <GoogleCircleSvg />,
            clickHandler: loginWithGoogle,
        })
    }

    return (
        <div className={"social-register-login" + (isDarkMode && ' dark' || '')}>
            <p className="title">{title}</p>
            <div className="row">
                <div className="col-md-10">
                    <div className="row g-2 g-sm-3">
                        {
                            socialRegisterLoginOptions && socialRegisterLoginOptions.map((item, index) => {
                                return (
                                    <div className="col-6" key={index}>
                                        <ConnectWithSocial
                                            classes={item.name}
                                            onClick={item.clickHandler}
                                            icon={item.icon}
                                            text={item.title}
                                            disabled={item.disabled}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SocialRegisterLogin
