import React from "react"

export const ReplySVG = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V6C1 6.26522 1.10536 6.51957 1.29289 6.70711C1.48043 6.89464 1.73478 7 2 7H2.5C2.63261 7 2.75979 7.05268 2.85355 7.14645C2.94732 7.24021 3 7.36739 3 7.5V8.566L5.223 7.084C5.30503 7.0293 5.4014 7.00008 5.5 7H8C8.26522 7 8.51957 6.89464 8.70711 6.70711C8.89464 6.51957 9 6.26522 9 6V2C9 1.73478 8.89464 1.48043 8.70711 1.29289C8.51957 1.10536 8.26522 1 8 1H2ZM0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H8C8.53043 0 9.03914 0.210714 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2V6C10 6.53043 9.78929 7.03914 9.41421 7.41421C9.03914 7.78929 8.53043 8 8 8H5.651L2.777 9.916C2.70171 9.96611 2.61423 9.99485 2.52389 9.99917C2.43355 10.0035 2.34373 9.98323 2.264 9.94054C2.18427 9.89785 2.1176 9.83434 2.07111 9.75676C2.02462 9.67918 2.00005 9.59044 2 9.5V8C1.46957 8 0.960859 7.78929 0.585786 7.41421C0.210714 7.03914 0 6.53043 0 6V2Z" fill="#0A1D40"/>
        </svg>
    )
}

export const ViewSVG = () => {
    return (
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.45385 1.384C3.40304 0.646552 4.61546 0 5.9697 0C7.32395 0 8.53637 0.646552 9.48555 1.384C10.4411 2.12638 11.1882 3.00603 11.6233 3.57406C12.044 4.12431 12.0446 4.87571 11.6234 5.42586C11.1882 5.99389 10.4411 6.87362 9.48555 7.616C8.53637 8.35345 7.32395 9 5.9697 9C4.61546 9 3.40304 8.35345 2.45385 7.616C1.49908 6.8742 0.752401 5.99535 0.317121 5.42727C0.111564 5.1619 0 4.83572 0 4.5C0 4.16427 0.111571 3.83808 0.31714 3.5727C0.752423 3.00463 1.49909 2.12579 2.45385 1.384Z" fill="#0A1D40"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.96973 3.375C5.34841 3.375 4.84473 3.87868 4.84473 4.5C4.84473 5.12132 5.34841 5.625 5.96973 5.625C6.59105 5.625 7.09473 5.12132 7.09473 4.5C7.09473 3.87868 6.59105 3.375 5.96973 3.375ZM3.71973 4.5C3.71973 3.25736 4.72709 2.25 5.96973 2.25C7.21237 2.25 8.21973 3.25736 8.21973 4.5C8.21973 5.74264 7.21237 6.75 5.96973 6.75C4.72709 6.75 3.71973 5.74264 3.71973 4.5Z" fill="#F5F5F5"/>
        </svg>
    )
}

export const EditPencilSvg = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.44 12.67H0.56C0.25025 12.67 0 12.9203 0 13.23V13.86C0 13.937 0.063 14 0.14 14H13.86C13.937 14 14 13.937 14 13.86V13.23C14 12.9203 13.7498 12.67 13.44 12.67ZM2.54975 11.2C2.58475 11.2 2.61975 11.1965 2.65475 11.1912L5.59825 10.675C5.63325 10.668 5.6665 10.6523 5.691 10.626L13.1093 3.20775C13.1255 3.19156 13.1383 3.17233 13.1471 3.15116C13.1559 3.12999 13.1604 3.10729 13.1604 3.08437C13.1604 3.06146 13.1559 3.03876 13.1471 3.01759C13.1383 2.99642 13.1255 2.97719 13.1093 2.961L10.2008 0.05075C10.1675 0.0175 10.1237 0 10.0765 0C10.0293 0 9.9855 0.0175 9.95225 0.05075L2.534 7.469C2.50775 7.49525 2.492 7.52675 2.485 7.56175L1.96875 10.5052C1.95173 10.599 1.95781 10.6955 1.98647 10.7864C2.01514 10.8772 2.06552 10.9597 2.13325 11.0267C2.24875 11.1388 2.394 11.2 2.54975 11.2Z" fill="#1D5DC2"/>
        </svg>
    )
}