import React from "react";

import LoginForm from "src/auth/component/LoginForm";

export default function LoginPage({isDarkMode}) {

  return (
    <div className={"pocket-auth__login" + ((isDarkMode && " dark") || "")}>
      <div className="row">
        <div className="col-12">
          <h4
            className={
              "pocket-auth__login--heading" + ((isDarkMode && " dark") || "")
            }
          >
            Login to <span>PocketPandit</span>
          </h4>

          <LoginForm
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
}
