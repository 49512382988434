import React from "react";
import { useLocation } from "react-router-dom";

import NavLink from "src/core/component/nav/NavLink";
import Badge from "src/core/component/nav/Badge";
import useQuery from "src/core/hook/useQuery";
import {
  WishListSvg,
  NotificationSvg,
  CartSvg,
  PromoSvg,
  HomeSvg,
} from "src/core/component/assets/svg/CommonSVG";
import usePromo from "src/promos/hook/usePromo";
import useNotification from "src/notification/hook/useNotification";
import useRTPInfo from "src/realtimeprediction/hook/useRTPInfo";
import useDetectKeyboardOpen from "src/core/hook/useDetectKeyboardOpen";

const MobileFooter = ({ page, handleClicked, isDarkMode, ...rest }) => {
  const location = useLocation();
  let queryParams = useQuery();
  const { rtpOrderLineCount } = useRTPInfo();
  const { promoNotificationData } = usePromo();
  const { userNotifications } = useNotification();
  const isKeyboardOpen = useDetectKeyboardOpen();

  let prefix = location.pathname.split("/")[1];
  let rtpStage = queryParams.get("stage");

  let classes = "nav-item";

  let svgLinks = [
    {
      url: "/",
      page: "home",
      svg: <HomeSvg />,
    },
    {
      url: "/promos",
      page: "promos",
      badge: (promoNotificationData && promoNotificationData.length) || 0,
      svg: <PromoSvg />,
      isActive: prefix === "promo" ? true : false,
    },
    {
      url: "/instant-prediction/payment",
      page: "wishlist",
      badge: rtpOrderLineCount,
      svg: <WishListSvg />,
      isActive: rtpStage === "payment" ? true : false,
    },
    {
      url: "/notifications",
      page: "notification",
      badge: (userNotifications || []).filter((n) => !n.read).length || 0,
      svg: <NotificationSvg />,
    },
    // {
    //     url: "/shop",
    //     page: 'cart',
    //     svg: <CartSvg />
    // }
  ];

  return (
    <ul
      className={
        "list-unstyled mb-0 d-flex w-100 justify-content-between align-items-center d-sm-none pocket-footer__mobile" +
        ((isDarkMode && " dark") || "") +
        ((isKeyboardOpen && " keypad-open") || "")
      }
    >
      {svgLinks.map((item, index) => {
        return (
          <NavLink
            key={index}
            currentPage={page}
            page={item.page}
            onClick={handleClicked}
            url={item.url}
            classes={classes}
            isActive={item.isActive}
          >
            {item.svg}

            {(item.badge && item.badge > 0 && <Badge value={item.badge} />) || (
              <></>
            )}
          </NavLink>
        );
      })}
    </ul>
  );
};

export default MobileFooter;
