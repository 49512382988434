import React from "react";

import "./Coin.scss";
import CurrencySvg from "src/wallet/images/Collection";

function Coin(props) {
  function handleClick(e, item) {
    props.handleCoinSelect(e, item);
  }

  return (
    <div
      className={`pesa-coin ${props.isCoinSelected ? "selected" : ""}`}
      onClick={(e) => handleClick(e, props.data)}
    >
      {props.currentCurrency.value=='PP'?<CurrencySvg />:null}
      <span>{props.data[props.currentCurrency.value]}</span>

      {props.data.hasExtra ? (
        <span className="pesa-coin__extra">+{props.data.extra} pesa extra</span>
      ) : (
        ""
      )}
    </div>
  );
}

export default Coin;
