import React from "react";
import Alert from 'react-bootstrap/Alert';


const SimpleAlert = ({children, classes="", ...rest}) => {
  
  return ( 
        <Alert className={"mb-0 " + classes}>
            {children}
        </Alert>
  )
}

export default SimpleAlert
