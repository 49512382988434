import React from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import CurrencyConvert from "../CurrencyConvert/CurrencyConvert";
import CurrencySvg from "../../images/Collection";
import "./CurrencyBanner.scss";

function CurrencyBanner({...rest}) {
  return (
    <section className="banner-two">
      <Container>
        <div className="banner-two__inner">
          <h1 className="banner-two__title">
            <CurrencySvg />
            Load Wallet
          </h1>

          <div className="banner-two__form">
            <CurrencyConvert {...rest} />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default CurrencyBanner;
