import React from 'react'
import { Dropdown } from 'react-bootstrap';
import {Email2Svg, FacebookSvg, InstagramSvg, Link2Svg, ShareSvg, TwitterRectSvg } from "src/core/component/assets/svg/CommonSVG";
import {FacebookShareButton,TwitterShareButton,EmailShareButton, FacebookIcon,} from "react-share";

const ShareDropdown = ({post}) => {
  const shareUrl = `${window.location.origin}/forum/${post.id}`

  const handleCopyLink = ()=>{
    navigator.clipboard.writeText(shareUrl)
  }

  const handleEmailShare = (e)=>{
    e.preventDefault()
    window.open(`mailto:?subject=${post.title}&body=${shareUrl}`)
  }

  return (
    <Dropdown className="share" drop="down" align={"start"} autoClose={true}>
      <Dropdown.Toggle className="share_toggle">
        <ShareSvg/>
      </Dropdown.Toggle>
      <Dropdown.Menu className="share_links">
        <Dropdown.Item onClick={handleCopyLink}><Link2Svg/> Copy Link</Dropdown.Item>
        <Dropdown.Item>
          <FacebookShareButton
              url='pocketpandit.com'
              // quote={"test"}
              // hashtag={"#hashtag"}
            >
               <FacebookSvg fill="#585858"/> Facebook
            </FacebookShareButton>
          
        </Dropdown.Item>
        {/* <Dropdown.Item><InstagramSvg fill="#585858"/> Instagram</Dropdown.Item> */}
        <Dropdown.Item>
          <TwitterShareButton
            url={shareUrl}
            title={post.title}
          >
            <TwitterRectSvg/> Twitter
          </TwitterShareButton>
        </Dropdown.Item>
        <Dropdown.Item onClick={handleEmailShare}><Email2Svg/> Email</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ShareDropdown