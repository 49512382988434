import React, { useState, useRef } from "react"
import { Col, Form, Row, Button } from "react-bootstrap"
import { Redirect, useLocation } from "react-router-dom";

import api from "src/lib/api"
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import banner from 'src/image/login-signup/auth-banner.jpg'
import LogoDark from "src/image/navigation_icons/final-logo-dark.png"

import "./Login.scss"


const Login = () => {
  const location = useLocation();
  const { isAuthenticated, profile, login: loginUser, logout } = useAuthInfo();
  const [error, setError] = useState({
    login: "",
    password: "",
    message: "",
  })
  const warningRef = useRef(false);

  const [formData, setFormData] = useState({
    'login_method': 'password',
    'login': '',
    'password': '', 
  });

  const handleLogin = async (credentials) => {
    const { login, password } = credentials;

    try {

      if (credentials.login_method === 'otp') {
        throw new Error('Goto OTP Login');
      }

      const res = await api.post('/v1/auth/token', {
          'login': login, password: password,
      });

      if(res.data?.error) {
          throw res.data.error;
      }

      const data = res.data?.result?.data;
      if(data?.user?.admin) {
        if(isAuthenticated) {
          logout();
        }
        
        loginUser(data['access_token'], data['refresh_token']);
        PocketToast({
          type: "success", 
          message: "Successfully logged in."
        });

        return <Redirect to="/admin" />
      }
    } catch (error) {
      let errorData = error.response?.data?.error || error
      setFormData({ ...credentials, 'login': login, 'password': '' });
      setError(() => ({
        login: "",
        password: "",
        message: errorData?.message || 'Unknown error',
      }));
    }
  }

  const handleValueChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    setError({
      login: "",
      password: "",
      message: "",
    })

    // basic validations to check if everything is alright
    if (!(formData['login'] || formData['password'])) {
      setError(() => ({
        login: "This field may not be blank.",
        password: "This field may not be blank.",
        message: "",
      }))

      return
    }
    if (!formData['login']) {
      setError(() => ({
        login: "This field may not be blank.",
        password: "",
        message: "",
      }))

      return;
    }
    if (!formData['password']) {
      setError(() => ({
        login: "",
        password: "This field may not be blank.",
        message: "",
      }))

      return;
    }

    // if everything works, then only run below code
    handleLogin(formData);
  }

  if (isAuthenticated) {
    if(profile.admin) {
      return <Redirect 
        to={{
            pathname: (location.state?.from && location.state.from) || "/admin",
            search: (location.state?.search && location.state.search) || ""
        }}
      />
    } else {
      if(!warningRef.current) {
        PocketToast({
          type: "error", 
          message: "You must login with Admin Credentials."
        })
      }
      warningRef.current = true;
    }
  }

  return (
    <div className="admin-login">
      <Row>
        <Col xs="12" sm="6" lg="5" xl="4">
          <div
            className="admin-login__logo"
            style={
              {
                backgroundImage: "url(" + banner + ")",
              }
            }
          >
            <figure>
              <img src={LogoDark} alt="Pocket Pandit" />
            </figure>
          </div>
        </Col>
        <Col xs="12" sm="6" lg="7" xl="8">
          <div className="admin-login__form">
            <Form onSubmit={handleLoginSubmit} autoComplete={"off"}>
              <h2>Login</h2>
              <Row className="gx-lg-3">
                <Col xs="12" lg="6">
                  <Form.Group className="mb-3" controlId="admin-email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="login" value={formData['login']} onChange={handleValueChange} placeholder="Enter your email" className={(error.login || error.message) && "is-invalid" || ""} />
                    {
                      (error.login || error.message) && <div className="invalid-feedback">{error.login || error.message}</div>
                    }
                  </Form.Group>
                </Col>
                <Col xs="12" lg="6">
                  <Form.Group className="mb-3" controlId="admin-password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" value={formData['password']} onChange={handleValueChange} placeholder="Enter your password" className={(error.password || error.message) && "is-invalid" || ""} />
                    {
                      error.password && <div className="invalid-feedback">{error.password}</div>
                    }
                  </Form.Group>
                </Col>
                <Col xs="12">
                  {/* <Form.Group className="mb-4" controlId="admin-remember-me">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group> */}

                  <Button variant="primary" type="submit" className="btn-admin">
                    Login
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login