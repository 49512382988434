import React, { useEffect, useState } from 'react'
import api from 'src/lib/api';
import H4Heading from "src/core/component/grid/H4Heading";
import { useTheme } from "src/theme/component/ThemeContext";
import { Link, Redirect, useHistory } from "react-router-dom";
import MobilePrevNextButton from "src/core/component/MobilePrevNextButton";
import WalletCheckoutForm from 'src/wallet/component/WalletCheckout/WalletCheckoutForm'
import WalletCheckoutSummary from 'src/wallet/component/WalletCheckout/WalletCheckoutSummary'
import "./wallet_checkout.scss"

const WalletCheckout = () => {
    const [loading,setLoading] = useState(false)
    const history = useHistory()
    const [isDarkMode] = useTheme();
    const [isProcessing, setIsProcessing] = useState(false);
    const [intentData,setIntentData] = useState({})
    const [order,setOrder] = useState()

    useEffect(()=>{
        if(history.location){
            setLoading(true)
            setIntentData(history?.location?.state)
            walletTopupOrder(history?.location?.state)
            // clear this state after payment

        }
        if(!loading){
            getWalletTopupOrder();
        }
    },[history.location])


    const getWalletTopupOrder = async () => {
        try {
          const result = await api.get(`v1/wallet_topup_order`);
          if(result?.data){
            console.log(result.data.result?.data)
              setOrder(result.data.result?.data)
          }

        } catch (error) {
          error = error?.response?.data?.error || error;
          return {
            error: error?.message || `Cannot Create Wallet Topup Order`,
          };
        }finally{setLoading(false)}
      };

    const walletTopupOrder = async (state) => {
        try {
          const result = await api.post(`v1/wallet_topup_order`, {
            'currency': state?.currentCurrency,
            'intentAmount': state?.intentAmount,
            'intentAmountInUsd': state?.intentAmountInUsd,
          });
          if(result?.data){
              setOrder(result.data.result?.data)
          }

        } catch (error) {
          error = error?.response?.data?.error || error;
          return {
            error: error?.message || `Cannot Create Wallet Topup Order`,
          };
        }finally{setLoading(false)}
      };

      const handleDelete = (id)=>{

      }

  return (
     <div className='wallet-layout'>
     <div className='wallet-layout-inner'>
     <div className='container'>
      <div className="payment-page">
        <H4Heading classes="wallet-payment-heading mb-4" heading="Payment Method" />

        <div className={"rtp-layout" + (isDarkMode ? " dark" : "")}>
          <div className="rtp-payment-content">
            <div className="row gx-2 gx-lg-3 gx-xxl-4">
              <WalletCheckoutForm
                order={order}
                isDarkMode={isDarkMode}
                isLoading={isProcessing}
              />

              <WalletCheckoutSummary
                order={order}
                intentData={intentData}
                isDarkMode={isDarkMode}
                handleDelete={handleDelete}
              />
           
            </div>
          </div>
        </div>

        <div className="back-btn mt-2 d-none d-sm-block">
          <Link to="/wallet" className={"prev-link" + ((isProcessing && " disabled") || "")} >Back</Link>
        </div>
        {/* <MobilePrevNextButton
          classes="d-sm-none"
          hasPrev={true}
          hasNext={false}
          prevLink="/wallet"
          nextLink={"#"}
          hasLoading={true}
          isLoading={isProcessing}
        /> */}
        </div>
        </div>
        </div>
        </div>

  )
}

export default WalletCheckout