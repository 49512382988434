import api from "src/lib/api";


export default async function fileDownloader(endpoint, fileName, params = {}) {
  try {
        const response = await api.request({
            method: "GET",
            url: endpoint,
            params: params,
            responseType: "blob",
        });
        // console.log('Response', response);

        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement("a");
        a.target = '_blank';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
    } catch (error) {
        console.log('Can not download pdf.')
    }
}
