import React from "react";
import Accordion from 'react-bootstrap/Accordion';


const AccordionCommon = ({children, classes="", defaultActiveKey='', ...rest}) => {
    return (
            <Accordion className={classes} defaultActiveKey={defaultActiveKey}>
                {children}

            </Accordion>
        )
}

export default AccordionCommon