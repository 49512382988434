import React from "react";

const dimension = process.env.IMAGE_STATIC_DIMENSIONS;

const PromoBanner = ({ promo, isDarkMode, className = "", ...rest }) => {
  return (
    <div className="container">
      <div className="pocket-promo__details-banner">
        <picture>
          <source media="(max-width: 576px)" srcSet={promo.image_url} width={dimension.sm.w} height={dimension.sm.h} />
          <source media="(max-width: 992px)" srcSet={promo.medium_image_url} width={dimension.md.w} height={dimension.md.h} />
          <img src={promo.large_image_url} alt="" width={dimension.lg.w} height={dimension.lg.h} />
        </picture>
      </div>
    </div>
  );
};

export default PromoBanner;
