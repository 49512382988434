import React, {useState, useEffect} from "react";
import { useHistory } from "react-router";

import api from 'src/lib/api';
import useAuthInfo from 'src/auth/hook/auth-info';
import { useModal } from "src/core/hook/useModal";
import StartChatSetupModal from "./StartChatSetupModal";
import PaymentSetupModal from "./PaymentSetupModal";
import useCustomerChat from "src/connect-with-astrologer/hook/useCustomerChat";
import { PocketToast } from "src/core/component/toast/PocketToast";
import PaymentProcessingPopup from "src/core/component/payment/PaymentProcessingPoup";
import { ChatSvg } from "src/core/component/assets/svg/connectastrologer/ConnectAstrologer";


const StartChat = ({isDarkMode, astrologer, metaData, ...rest}) => {
    const history = useHistory();
    const {selectAstrologer} = useCustomerChat();
    const [showStartChatModal, handleStartChatModalShow, handleStartChatModalClose] = useModal(false);
    const [showPaymentModal, handlePaymentModalShow, handlePaymentModalClose] = useModal(false);
    const [showProcessingModal, handleProcessingModalShow, handleProcessingModalClose] = useModal(false);
    const { isAuthenticated } = useAuthInfo();
    const [selectedAstrologer, setSelectedAstrologer] = useState(null);
    const [hours, setHours] = useState(1);
    const [invalidHours, setInvalidHours] = useState(false);
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(null);

    const getActiveConversation = async (id) => {
        try {
            const result = await api.get(`v1/conversations/active?user_id=${id}`)
            return result.data?.result?.data || null;
        }
        catch (error) {
            return null;
        }
    }

    const handleChat = async (astro) => {
        if(!isAuthenticated) {
            return;
        }

        // check if there is any active conversation and redirect to chat page if exists:
        const activeConversation = await getActiveConversation(astro.id);
        if(activeConversation) {
            //Save astrologer to Redux store as a current conversation astrologer
            selectAstrologer(astro);

            history.push({
                pathname: `/connect-astrologer/chat`,
                search: `?c=${activeConversation['name']}`,
                state: {
                    conversationData: activeConversation
                }
            });
        } else {

            // if no active conversation open chat start setup modal
            setSelectedAstrologer(astro);
            handleStartChatModalShow();
        }
    }

    const startFreeTrialChat = async (astro) => {
        try {
            const res = await api.post('v1/conversations', {
              'user_id': astro.id,
              'minute': 5
            });
            const newConversation = res.data.result?.data;
            if(newConversation) {
                history.push({
                    pathname: `/connect-astrologer/chat`,
                    search: `?c=${newConversation['name']}`,
                    state: {
                        conversationData: newConversation
                    }
                });
            }

        } catch (error) {
            PocketToast({
                type: "error", 
                message: `${error?.response && error.response.data.error.message || 'Error starting free trial.'}`
            })
        }
    }

    const createConversation = async (astro) => {
        try {
            const res = await api.post('v1/conversations/start', {
              'user_id': astro.id,
              'hours': hours
            });
            const newConversation = res.data.result?.data?.conversation;
            if(newConversation) {
                history.push({
                    pathname: `/connect-astrologer/chat`,
                    search: `?c=${newConversation['name']}`,
                    state: {
                        conversationData: newConversation
                    }
                });
            }

        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Error creating new conversation."
            })
        }
    }
    
    const handleFreeTrial = (e) => {
        e.preventDefault();
        handleStartChatModalClose();
        if(!selectedAstrologer) {
            return;
        }
        selectAstrologer(selectedAstrologer);
        startFreeTrialChat(selectedAstrologer)
    }

    const handlePayAndContine = (e) => {
        e.preventDefault();
        if(hours <= 0 || parseInt(hours) <= 0) {
            setInvalidHours(true)
            console.log(`Invalid time! should be greater than 0`)
            return;
        }

        handleStartChatModalClose();
        if(!selectedAstrologer) {
            return;
        }

        selectAstrologer(selectedAstrologer);

        if(selectedAstrologer && (!selectedAstrologer.chat_rate_per_hour || selectedAstrologer.chat_rate_per_hour <= 0)){
            // create conversation and redirect to chat page
            createConversation(selectedAstrologer);
        }

        if(selectedAstrologer && selectedAstrologer.chat_rate_per_hour && selectedAstrologer.chat_rate_per_hour >= 0){
            handlePaymentModalShow();
        }
    }

    useEffect(() => {
        if(paymentProcessing) {
            handleProcessingModalShow();
        }
    }, [paymentProcessing])

    useEffect(() => {
        if(paymentError) {
            handlePaymentModalClose();
            handleProcessingModalClose();
        }
    }, [paymentError])

    const requestClientSecret = async () => {
        try {
            const result = await api.post('v1/conversations/start', {
                'user_id': selectedAstrologer.id,
                'hours': hours
              });
            return result;
        }
        catch (error) {
            return {
                error: `Sorry, can't process your request`
            }
        }
    }
    
    const handlPaymentSuccess = (data) => {
        setPaymentProcessing(false);
        setPaymentSuccess(true);
        setTimeout(() => {
            handleProcessingModalClose();
            handlePaymentModalClose();
            setPaymentSuccess(false);
            const conversationData = data?.conversation;
            
            if(conversationData) {
                history.push({
                    pathname: `/connect-astrologer/chat`,
                    search: `?c=${conversationData['name']}`,
                    state: {
                        conversationData: conversationData
                    }
                });
            }
        }, 2000);
    }

    // console.log('Meta data', metaData);
    // console.log('Astrologer', astrologer);
    // console.log('Dark Mode', isDarkMode);
    // console.log('Astrologer Selected', selectedAstrologer);
    // console.log('Hours', hours);

    return (
        <>
            <span className="chat" onClick={handleChat.bind(null, astrologer)}>
                <ChatSvg />
            </span>

            {isAuthenticated && astrologer && (
                <>
                    {/* Start Chat modal */}
                    {showStartChatModal && (
                        <StartChatSetupModal
                            hours={hours}
                            setHours={setHours}
                            isDarkMode={isDarkMode}
                            showModal={showStartChatModal}
                            handleModalClose={handleStartChatModalClose}
                            handleFreeTrial={handleFreeTrial}
                            handlePayAndContine={handlePayAndContine}
                            invalidHours={invalidHours}
                            metaData={metaData}
                        />
                    )}
                    

                    {/* Payment Modal */}
                    {showPaymentModal && selectedAstrologer && (
                        <PaymentSetupModal
                            isDarkMode={isDarkMode}
                            showModal={showPaymentModal}
                            handleModalClose={handlePaymentModalClose}
                            astrologer={selectedAstrologer}
                            hours={hours}
                            amount={selectedAstrologer && selectedAstrologer?.chat_rate_per_hour * hours || 0}
                            requestClientSecret={requestClientSecret}
                            handlPaymentSuccess={handlPaymentSuccess}
                            processing={paymentProcessing}
                            setProcessing={setPaymentProcessing}
                            error={paymentError}
                            setError={setPaymentError}
                            buttonLabel={'Confirm Payment'}
                            showProcessingModal={showProcessingModal}
                        />
                    )}

                    {/* Payment Processing  */}
                    {showProcessingModal && (
                        <PaymentProcessingPopup 
                            showModal={showProcessingModal}
                            handleModalClose={handlePaymentModalClose}
                            success={paymentSuccess}
                            processing={paymentProcessing}
                            showCloseIcon={false}
                            isDarkMode={isDarkMode} 
                            size={"md"}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default StartChat;
