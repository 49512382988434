export const kundaliLines1 = (w, h) => ([
  {move: [w/4, 0], line1: [w/2, 0],line2:[w/2, h/4],line3:[w/4, h/4]},
  {move: [w/2, 0], line1: [w/4*3, 0],line2:[w/4*3, h/4],line3:[w/2, h/4]},
  {move: [w/4*3, 0], line1: [w, 0],line2:[w, h/4],line3:[w/4*3, h/4]},
  {move: [w/4*3, h/4], line1: [w, h/4],line2:[w, h/2],line3:[w/4*3, h/2]},
  {move: [w/4*3, h/2], line1: [w, h/2],line2:[w, h/4*3],line3:[w/4*3, h/4*3]},
  {move: [w/4*3, h/4*3], line1: [w, h/4*3],line2:[w, h],line3:[w/4*3, h]},
  {move: [w/4*3, h], line1: [w/2, h],line2:[w/2, h/4*3],line3:[w/4*3, h/4*3]},
  {move: [w/2, h/4*3], line1: [w/4, h/4*3],line2:[w/4, h],line3:[w/2, h]},
  {move: [0, h], line1: [w/4, h],line2:[w/4, h/4*3],line3:[0, h/4*3]},
  {move: [0, h/4*3], line1: [0, h/2],line2:[w/4, h/2],line3:[w/4, h/4*3]},
  {move: [0, h/2], line1: [w/4, h/2],line2:[w/4, h/4],line3:[0, h/4]},
  {move: [0, 0], line1: [w/4, 0],line2:[w/4, h/4],line3:[0, h/4]},
]);

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(8 * scale, 10),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 48 * scale,
      width: 100 * scale, 
      top: 21 * scale,
      left: 100 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 6 * scale,
      left: 145 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 0 * scale,
      left: 100 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 20 * scale,
      left: 200 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 6 * scale,
      left: 245 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 0 * scale,
      left: 200 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 21 * scale,
      left: 300 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 6 * scale,
      left: 345 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 0 * scale,
      left: 300 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 100 * scale,
      top: 91 * scale,
      left: 300 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 76 * scale,
      left: 345 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 70 * scale,
      left: 300 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 161 * scale,
      left: 300 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 146 * scale,
      left: 345 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 140 * scale,
      left: 300 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 100 * scale,
      top: 231 * scale,
      left: 300 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 216 * scale,
      left: 345 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 210 * scale,
      left: 300 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 100 * scale,
      top: 231 * scale,
      left: 200 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 216 * scale,
      left: 245 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 210 * scale,
      left: 200 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 100 * scale,
      top: 231 * scale,
      left: 100 * scale, 
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 216 * scale,
      left: 145 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 210 * scale,
      left: 100 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 231 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 216 * scale,
      left: 45 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 210 * scale,
      left: 0 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 161 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 146 * scale,
      left: 45 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 140 * scale,
      left: 0 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 91 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 76 * scale,
      left: 45 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 70 * scale,
      left: 0 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 100 * scale,
      top: 21 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 6 * scale,
      left: 45 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 0 * scale,
      left: 0 * scale,
    }
  }
];
