import React, { useState,useEffect } from 'react'
import api from 'src/lib/api';

const useWalletTransactions = () => {
  const [walletTransactions,setWalletTransactions] = useState([]);
  const [exchangeRate,setExchangeRate] = useState(0);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchWalletTransactions = async (controller)=>{
    try {
      const res = await api.get('v1/wallet_transactions',
      {signal:controller.signal});
      if(res && res.data){
        setWalletTransactions(res?.data?.result?.data);
        setExchangeRate(res?.data?.result?.metadata?.exchange_rate || 0)
        setError()
      }
    } catch(error){
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    const controller = new AbortController()
    fetchWalletTransactions(controller);

    return () => {
      controller.abort()
    }

  },[])


  return { walletTransactions,exchangeRate, loading, error }
}

export default useWalletTransactions