import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PocketSelection from "src/core/component/selection/PocketSelection";
import api from "src/lib/api";
import ProfileFilter from "src/core/component/birthprofile/ProfileFilter";
import { PlusSvg } from "src/core/component/assets/svg/CommonSVG";
import "src/instant-prediction/scss/Filter.scss";
import { useTheme } from "src/theme/component/ThemeContext";

const BirthProfileFilter = ({
  filter,
  setFilter,
  handleProfileSelect,
  handleRelationshipSelect,
  isRelationshipSelected,
  createURL = "",
  resetSelection = true,
  hasTitle = true,
  title = "",
  isFromFreeAstrology
}) => {
  const [isDarkMode] = useTheme();
  const [relationshipList, setRelationshipList] = useState([]);
  const [relationshipFilter, setRelationshipFilter] = useState({
    relationship_id: "all",
  });

  useEffect(() => {
    fetchRelationshipList().then();
  }, []);

  const fetchRelationshipList = async () => {
    try {
      const response = await api.get("/v1/relationship");
      setRelationshipList(response?.data.result.data);
    } catch (error) {
      console.log("RTP Filter Error", error);
    }
  };

  const handleRelationshipChange = (e, relationship) => {
    handleRelationshipSelect(e, relationship);

    setRelationshipFilter((prevRelationshipFilter) => {
      return {
        ...prevRelationshipFilter,
        relationship_id: relationship.id,
      };
    });

    setFilter({
      ...filter,
      relationship_id: relationship.id,
      p_relationship_id: relationship.id,
    });

    if (resetSelection) {
      handleProfileSelect(null, []);
    }
  };

  const currentFilter = relationshipFilter?.relationship_id &&
    relationshipList.filter((opt) => opt.id === relationshipFilter["relationship_id"])[0] || "";
  // console.log("relationshipFilter", relationshipFilter);
  // console.log("relationshipList", relationshipList);
  
const birthProfile__add__style = {
    display: "flex",
    flexDirection:"row-reverse",
    flexWrap:'wrap',
    justifyContent: "space-between",
    alignItems: "end"
}

  return (
    <div className="rtp-birthProfile__filter">
      {hasTitle && title && <h5 className="rtp-filter__heading">{title}</h5>}

      <div className={`rtp-birthProfile__add`} style={birthProfile__add__style}>
        {(createURL && !isFromFreeAstrology) ? <Link
          className="pocket-btn"
          to={{
            pathname: createURL,
            state: {
              relationship_id: filter.p_relationship_id,
            },
          }}
        >
          <PlusSvg />
          {isFromFreeAstrology?"Create":"Add Birth"} Profile
        </Link>:<div></div>
        }
        {!isFromFreeAstrology?
        (<div style={{width:"150px"}}>
          <PocketSelection
          options={[{ id: "all", name: "All"},...relationshipList]}
          currentValue={currentFilter}
          onChange={(item,e)=>{handleRelationshipChange(e,item)}}
          hasLabel={false}
          label={''}
          isSearchable={false}
          required={false}
          placeholder={'All'}
          classes={`pocket-select ${isDarkMode? ' dark':''}`}
        />
      </div>):
        (<div className="rtp-filter__select">
          <ProfileFilter
            rtpFilterIds={filter}
            filter={relationshipFilter}
            relationships={relationshipList}
            handleRelationshipSelect={handleRelationshipSelect}
            isRelationshipSelected={isRelationshipSelected}
            onChangeFilter={handleRelationshipChange}
          />
        </div>)
      }
      </div>
    </div>
  );
};

export default BirthProfileFilter;
