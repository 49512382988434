import { useEffect, useState } from "react";
import useAuthInfo from "src/auth/hook/auth-info";
import api from 'src/lib/api';


const useFetch = (url, doFetch) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {isAuthenticated, accessToken} = useAuthInfo()

  const authHeaders = isAuthenticated ? {'Authorization': `Bearer ${accessToken}`} : {}

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
      
        try {
            const result = await api.get(url, {headers: authHeaders})
            setData(result);
        } catch (error) {
            setData({
                'error': {
                'message': error.message
                }
            });
        } finally {
            setLoading(false);
        }
    }

    if (url) fetchData();

    }, [doFetch]);

    return { data, loading };
};

export default useFetch;