import React, { Fragment, useEffect, useState } from "react";
import Cropper from "react-cropper";
import { Form } from "react-bootstrap";
import "cropperjs/dist/cropper.css";

import api from 'src/lib/api';
import { useTheme } from "src/theme/component/ThemeContext";
import {
  TickMarkSvg,
  ReverseCameraSvg
} from "src/core/component/assets/svg/CommonSVG";
import { ProfileImgSvg } from "src/core/component/assets/svg/ProfileSettingSVG";
import { PocketToast } from "src/core/component/toast/PocketToast";
import useAuthInfo from "src/auth/hook/auth-info";
import dataURItoBlob from "src/util/data-uri-to-blob";


const CoverImageUpload = ({ formData, setFormData, profile }) => {
  setFormData = typeof setFormData === "function" ? setFormData : function noop() {};
  const {updateProfile} = useAuthInfo();
  const [isDarkMode] = useTheme()
  const [isDragged, setIsDragged] = useState(false);
  const [coverImage, setCoverImage] = useState('');
  const [coverCropData, setCoverCropData] = useState("");
  const [coverCropper, setCoverCropper] = useState();
  const [tempCover, setTempCover] = useState(null);

  // profile && profile?.cover_image_url ||
  
  useEffect(() => {
    profile && setTempCover(profile.cover_image_url);
  }, [profile])

  useEffect(() => {
    if (!coverCropData) return;
    handleCoverSubmit();
  }, [coverCropData]);

  const handleCoverSubmit = async () => {
    try {
        const [coverImage, coverImageName] = dataURItoBlob(coverCropData, "cover_image");
        const formData = new FormData();
        formData.append("cover_image", coverImage, coverImageName);

        const result = await api.put(`v1/users/me/change-profile-cover`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        const profileData = result.data?.result?.data;
        if(profileData) {
            updateProfile(profileData);

            PocketToast({
              type: "success", 
              message: "Cover Image uploaded successfully."
            })
        }
    }
    catch (error) {
        PocketToast({
            type: "error", 
            message: "Cover Image upload failed."
        })  
    }
  }

  const onChangeCoverHandle = (e) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCoverImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCoverCropData = () => {
    if (typeof coverCropper !== "undefined") {
      setCoverCropData(
        coverCropper.getCroppedCanvas().toDataURL(coverImage.slice(5, 15))
      );
      setCoverImage("");
    }
  };


  return (
    <div
      className={
        "pocket-profile-setup__profile-cover" + (isDarkMode && ' dark' || '') +
        (profile?.cover_image_url || coverImage || coverCropData
          ? " has-cover-img"
          : "") +
        (isDragged ? " drag-active" : "")
      }
    >
      {coverImage ? (
        <Fragment>
          <Cropper
            cropBoxResizable={false}
            dragMode="move"
            style={{ height: "100%", width: "100%" }}
            initialAspectRatio={3}
            src={coverImage}
            onInitialized={(instance) => {
              setCoverCropper(instance);
            }}
            toggleDragModeOnDblclick={false}
            viewMode={3}
            zoomTo={0}
          />
          <div className={`pocket-cropper-btns ${isDarkMode && 'dark' || ''}`}>
            <span
              className="pocket-cropper__tick"
              onClick={() => getCoverCropData()}
            >
              <TickMarkSvg />
            </span>
            <span
              className="pocket-cropper__delete"
              onClick={() => setCoverImage("")}
            >
              <ReverseCameraSvg />
            </span>
          </div>
        </Fragment>
      ) : !coverCropData &&
        !tempCover ? (
        <Form.Group controlId="profile_cover">
          <Form.Label>
            <div className={`img-icon ${isDarkMode && 'dark' || ''}`}>
              <ProfileImgSvg />
            </div>
            <p className={`${isDarkMode && 'dark' || ''}`}>
              Drag and drop an image, or <span>Browse</span>
            </p>
          </Form.Label>

          <Form.Control
            type="file"
            onChange={onChangeCoverHandle}
            accept="image/png, image/jpeg"
            onDragOver={() => setIsDragged(true)}
            onDragLeave={() => setIsDragged(false)}
            onDrop={() => setIsDragged(false)}
          />
        </Form.Group>
      ) : (
        <Fragment>
          <img
            className="img-cover img-fluid"
            src={tempCover || coverCropData}
            alt=""
          />

          <div className={`pocket-cropper-btns ${isDarkMode && 'dark' || ''}`}>
            <span
              className="pocket-cropper__delete"
              onClick={() => {
                setTempCover("");
                setCoverCropData("");
              }}
            >
              <ReverseCameraSvg />
            </span>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CoverImageUpload;
