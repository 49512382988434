import React, { useState, useEffect } from "react";
import queryString from "query-string";

import { useTheme } from "src/theme/component/ThemeContext";
import GeneralPromos from "src/promos/component/general/GeneralPromos";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from "src/auth/hook/auth-info";
import usePromo from "src/promos/hook/usePromo";
import NewPromoAlert from "./NewPromoAlert";
import api from "src/lib/api";
import FeaturedPromoSlider from "src/core/component/promos/FeaturedPromoSlider";

const Promos = () => {
  const [isDarkMode] = useTheme();
  const { isAuthenticated } = useAuthInfo();
  const { setupPromoData, updatePromoLastCheck, clearPromoData, promoNotificationData } = usePromo();
  const [callApi, setCallAPi] = useState(true);
  const [promos, setPromos] = useState([]);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNewPromoAlert, setShowNewPromoAlert] = useState(false);
  const [filter, setFilter] = useState({
    active: true,
    limit: 30,
  });

  // clear promo notification and update last checked date
  useEffect(() => {
    const datetime = new Date().format("yyyy-MM-ddTHH:mm:ss");
    updatePromoLastCheck(datetime);
    clearPromoData();
  }, []);

  useEffect(() => {
    fetchPromos();
  }, []);

  const fetchPromos = async () => {
    setLoading(true);
    try {
      const promoResult = await api.get(
        `v1/discount-offers?${queryString.stringify(filter, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      setResponse(promoResult);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error on fetching promos");
    }
  };

  useEffect(() => {
    if (promoNotificationData && promoNotificationData.length > 0) {
      setShowNewPromoAlert(true);
    } else {
      setShowNewPromoAlert(false);
    }
  }, [promoNotificationData]);

  useEffect(() => {
    const promoData = response?.data?.result?.data || [];
    setPromos(promoData);
  }, [response]);

  const refreshPromos = () => {
    fetchPromos();
  };

  // console.log("Promos", promos);

  return (
    <section
      className={`pocket-promos__page mb-sm-3 ${(isDarkMode && "dark") || ""}`}
    >
      <div className="container-xl">
        {!response && (
          <div className="row">
            <div className="col-12">
              <LoadingIcon />
            </div>
          </div>
        )}

        {response && promos && promos.length > 0 && (
          <>
            <div className="row">
              <div className="col-12">
                <div className="featured-promos__container position-relative">
                  {showNewPromoAlert && (
                    <NewPromoAlert refresh={refreshPromos} />
                  )}

                  <FeaturedPromoSlider
                    promosData={
                      (promos && promos.filter((item) => item.is_featured)) ||
                      []
                    }
                    isDarkMode={isDarkMode}
                  />
                </div>
              </div>
            </div>

            <div className="all-promos-wrapper">
              {promos &&
                promos.filter(
                  (item) => item.scope === "category" && !item.is_featured
                ).length > 0 && (
                  <div
                    className={`promos-container ${
                      (isDarkMode && "dark") || ""
                    }`}
                  >
                    <GeneralPromos
                      promos={
                        promos &&
                        promos.filter(
                          (item) =>
                            item.scope === "category" && !item.is_featured
                        )
                      }
                      title={"Categories"}
                      isDarkMode={isDarkMode}
                    />
                  </div>
                )}

              {promos &&
                promos.filter(
                  (item) => item.scope === "relationship" && !item.is_featured
                ).length > 0 && (
                  <div
                    className={`promos-container ${
                      (isDarkMode && "dark") || ""
                    }`}
                  >
                    <GeneralPromos
                      promos={
                        promos &&
                        promos.filter(
                          (item) =>
                            item.scope === "relationship" && !item.is_featured
                        )
                      }
                      title={"Relationships"}
                      isDarkMode={isDarkMode}
                    />
                  </div>
                )}

              {promos &&
                promos.filter(
                  (item) => item.scope === "question" && !item.is_featured
                ).length > 0 && (
                  <div
                    className={`promos-container last ${
                      (isDarkMode && "dark") || ""
                    }`}
                  >
                    <GeneralPromos
                      promos={
                        promos &&
                        promos.filter(
                          (item) =>
                            item.scope === "question" && !item.is_featured
                        )
                      }
                      title={"Questions"}
                      isDarkMode={isDarkMode}
                    />
                  </div>
                )}
            </div>
          </>
        )}

        {/* {response && (!promos || promos.length < 1) && (
          <div className="row">
            <div className="col-12">
              <p className="text-danger text-center py-5">
                Sorry! there are no promos available right now.
              </p>
            </div>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Promos;
