import api from 'src/lib/api';

export async function getOrders(params) {
  if(params){
    return api.get(`v1/question_order?${params}`);
  }else {
    return api.get(`v1/question_order`);
  }
}

export async function getOrderDetails(id) {
  return api.get(`v1/question_order/${id}`);
}