import React,{useState} from "react";
import DatePicker from "react-datepicker";
import DatePickerInput from "./DatePickerInput";


const PocketDatePicker = ({ 
        formData,
        setFormData,
        property,
        required=false, 
        hasLabel=false, 
        label, 
        placeholder="",
        isInvalid=false, 
        error="Please enter a valid date!", 
        disabled=false,
        autoComplete="off",
        isDarkMode=false,
        isTemporary=false,
        isMulti = false,
}) => {
    const [isDateWarning,setIsDateWarning] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const today = new Date()
    setFormData = typeof setFormData === 'function' ? setFormData : function noop() { }

    const handleDateWarningClick =()=>{
        setIsDateWarning(false)
    }

    const handleDateChange = (date) => {
        if(isMulti){
            const [start, end] = date;
            setFormData({...formData,startDate:new Date(start).format('yyyy-MM-dd'),endDate:new Date(end).format('yyyy-MM-dd')})
            setStartDate(start);
            setEndDate(end);
            return ;
        }
        var today = new Date();
        if(today<date && !isTemporary){
           setIsDateWarning(true)
        }
        setFormData({...formData, [property]: new Date(date).format('yyyy-MM-dd')})
    };

    return (
        <>
            <DatePicker
                value={isMulti?formData?.startDate +" - "+ formData?.endDate:formData[property] || ""}
                selected={formData[property] && new Date(formData[property]) || new Date()}
                dateFormatCalendar={"y-MM-dd"}
                format={"y-MM-dd"}
                onChangeRaw={(e) => e.preventDefault()}
                // maxDate={isTemporary?new Date("12-12-2099"):today}
                maxDate={isTemporary?new Date("2099-12-12"):today}
                showMonthDropdown
                showYearDropdown
                showPopperArrow={false}
                openCalendarOnFocus={true}
                numberOfMonths={1}
                dropdownMode="select"
                onChange={handleDateChange}
                onYearChange={isMulti ?()=>{}: handleDateChange}
                onMonthChange={isMulti ?()=>{}: handleDateChange}
                showLeadingZeros={true}
                required={true}
                name={property}
                calendarClassName={`pocket-date-picker ${isDarkMode && 'dark' || ''}`}
                startDate = {startDate}
                endDate = {endDate}
                selectsRange = {isMulti}
                monthsShown={isMulti?2:1}
                
                customInput={<DatePickerInput
                    name={property}
                    value={formData[property] || ""}
                    property={property}
                    required={required}
                    placeholder={placeholder}
                    hasLabel={hasLabel}
                    label={label}
                    isInvalid={isInvalid}
                    error={error} 
                    autoComplete={autoComplete}
                    disabled={disabled}
                />}
            />
            { isDateWarning && (
            <div className={"date_picker_dialog"+ (isDarkMode && ' dark' || '' )}>
            <div className={"date_picker_dialog_container" + (isDarkMode && ' dark' || '' )}>
                <div className={"date_picker_dialog_container_title"+ (isDarkMode && ' dark' || '' )}>Date of birth is of future.</div>
                <div className={"date_picker_dialog_container_message"+ (isDarkMode && ' dark' || '' )}>Please verify your date of birth before we create you the profile.</div>
                <div className="date_picker_dialog_container_button">
                    <div className={"date_picker_dialog_container_button_edit"+ (isDarkMode && ' dark' || '' )} onClick={()=>handleDateWarningClick()}>Continue</div>
                </div>
            </div>
            </div>
            )}
        </>
    );
}

export default PocketDatePicker
