import React from "react";

const H4Heading = ({ heading, classes, ...rest }) => {
  return (
    <>
      <h4 className={"heading " + classes}>{heading}</h4>
    </>
  );
};

export default H4Heading;
