import React from "react";

const AmountItem = ({ title, value, classes = "", monetary=true}) => {
  // const formatted = Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67

  return (
    <div
      className={
        "amount-item d-flex align-items-center justify-content-between " +
        classes
      }
    >
      <p className="subject">{title}</p>
      <p className="price-total">{ value }</p>
    </div>
  );
};

export default AmountItem;
