import React from "react";

import {
  ForumSvg,
  CustomerSupportSvg,
  ShopSvg,
  ConnectAstrologerSvg,
  RealTimeSvg,
  FreeAstrologySvg,
} from "src/core/component/assets/svg/CommonSVG";
import FeatureItem from "./FeatureItem";

const MobileFeaturesGrid = ({ handleRTPClick,appSettings,astro_profile }) => {
  let featuresData = [
    {
      title: "Free Astrology",
      url: "/free-astrology",
      icon: <FreeAstrologySvg />,
      class: "free-astrology",
    },
    {
      title: "Instant Prediction",
      url: "/instant-prediction",
      icon: <RealTimeSvg />,
      class: "realtime-prediction",
      onClick: handleRTPClick,
    },
    astro_profile?.astrologer === true && {
      'title': 'Astrologer',
      'url': '/astrologer',
      'icon': <ConnectAstrologerSvg />,
      'class': 'connect-an-astrologer'
  },
     astro_profile?.astrologer === false && {
        'code':1,
        'title': 'Connect with Astrologer',
        'url': '/connect-astrologer',
        'icon': <ConnectAstrologerSvg />,
        'class': 'connect-astrologer'
    },
    {
        'code':2,
        'title': 'Shop',
        'url': '/shop',
        'buttonText': 'Shop Now',
        'pricing': 'From $11.1',
        'icon': <ShopSvg />,
        'class': 'shop'
    },
    {
      title: "Customer Support",
      url: "/support",
      icon: <CustomerSupportSvg />,
      class: "customer-support",
    },
    {
      title: "Discussion Forum",
      url: "/forum",
      icon: <ForumSvg />,
      class: "forum",
    },
  ];
  featuresData = featuresData.map(data=>data?.code?appSettings.includes(data.code) && data:data)
  featuresData = featuresData.filter(data=>data!=false)
  
  return (
    <>
      {/* <div className="row row-cols-2 row-cols-xsmd-3 gx-2 gy-3"> */}
      <div className="row row-cols-2 gy-3 justify-content-center">
        {featuresData.map((item, index) => {
          return (
            <FeatureItem
              key={index}
              title={item.title}
              url={item.url}
              classes={item.class}
              icon={item.icon}
              onClick={item.onClick}
            ></FeatureItem>
          );
        })}
      </div>
    </>
  );
};

export default MobileFeaturesGrid;
