import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getAppSettings from "src/lib/services/app-setting"
import useAuthInfo from "src/auth/hook/auth-info";
import useRTPInfo from "src/realtimeprediction/hook/useRTPInfo";
import { useTheme } from "src/theme/component/ThemeContext";
import FeaturesCard from "./FeaturesCard";
import Features from "./mobile/Features";

const FeaturesGrid = () => {
  const history = useHistory();
  const [isDarkMode] = useTheme();
  const { setEnterDateTime } = useRTPInfo();
  const [appSettings,setAppSettings] = useState([])
  const { isAuthenticated, profile } = useAuthInfo();

  useEffect(()=>{
    fetchAppSettings()
  },[])

  const handleRTPClick = (e) => {
    // console.log('Prediction Clicked....')
    e.preventDefault();
    setEnterDateTime();
    history.push("/instant-prediction");
  };

    const fetchAppSettings = async () => {
    try {
        let res = await getAppSettings();
        res = res?.data?.result?.data || []
        res = res.map(r=>r.code)
        setAppSettings(res)

    } catch (error) {}
}

  return (
    <section className={"pocket-features" + ((isDarkMode && " dark") || "")}>
      {/* mobile */}
      <Features handleRTPClick={handleRTPClick} isDarkMode={isDarkMode} appSettings={appSettings} astro_profile={profile}/>

      {/* Desktop, Tablet View */}
      <div
        className={
          "container d-none d-sm-block pocket-features__container" +
          ((isDarkMode && " dark") || "")
        }
      >
        <FeaturesCard handleRTPClick={handleRTPClick} isDarkMode={isDarkMode} appSettings={appSettings} astro_profile={profile}/>
      </div>
    </section>
  );
};

export default FeaturesGrid;
