import React from "react";

import { SimpleCloseIconSvg } from "src/core/component/assets/svg/CommonSVG";

const QuestionItem = ({ line, isDarkMode, handleRemoval, ...rest }) => {
  console.log('line',line)
  handleRemoval =
    typeof handleRemoval === "function" ? handleRemoval : function noop() {};

  return (
    <div
      className={
        "question-item d-flex align-items-center justify-content-between" +
        ((isDarkMode && " dark") || "")
      }
    >
      <div className="question">
      {line?.name || line?.question?.name}
      <p><span>{line?.profile?.relationship?.name}{line?.profile?.relationship?.name !='Self' && "'s"} Profile</span> ({line?.profile?.name})</p>
      </div>

      <div className="pricing">
      <div >{[0,0.0].includes(line.discounted_price)?'FREE TRIAL':line.discounted_price_formatted }</div>
      {
        line?.price !=line?.discounted_price ?
          <div className="real_price">{line.price_formatted}</div>:<>&nbsp;</>
      }
      </div>

      {/* <div
        className="remove-icon"
        onClick={() => {
          handleRemoval(line);
        }}
      >
        <SimpleCloseIconSvg />
      </div> */}
    </div>
  );
};

export default QuestionItem;
