export function fetchTimezone(lat, lon) {
  const URL = `https://maps.googleapis.com/maps/api/timezone/json`;

  return fetch(URL + '?' + new URLSearchParams({
    'key': process.env.GOOGLE_TIMEZONE_API_KEY,
    'location': `${lat},${lon}`,
    'timestamp': Math.round((new Date().getTime()) / 1000).toString(),
  }))
    .then(res => res.json())
    .then(data => ({
      'dstOffst': data['dstOffset'],
      'offset': data['rawOffset'],
      'name': data['timeZoneId'],
      'label': data['timeZoneName'],
    }));
}
