import React from "react";
import { Link } from "react-router-dom";

const InstructEntitySelection = ({
  entityToGo,
  entityToAct,
  path,
  isDarkMode,
  message = "",
  search = "",
  icon = "",
  ...rest
}) => {
  return (
    <div className={"rtp-user-intruction" + ((isDarkMode && " dark") || "")}>
      <div className={"text-center contents" + ((isDarkMode && " dark") || "")}>
        {icon && (
          <div className="icon">
            <img src={icon} className="img-fluid" alt="" />
          </div>
        )}

        <div className="instruction">
          {!message && (
            <p className="mb-0">
              You need to select &nbsp;
              <Link
                to={{
                  pathname: `${path}`,
                  search: `${search}`,
                }}
                className="link-to"
              >
                {entityToGo}
              </Link>
              &nbsp; before you proceed to the {entityToAct}.
            </p>
          )}

          {message && (
            <p className="mb-0">
              {message}&nbsp;
              <Link
                to={{
                  pathname: `${path}`,
                  search: `${search}`,
                }}
                className="link-to"
              >
                {entityToGo}
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstructEntitySelection;
