import React, { useEffect } from "react";
import { useHistory, useLocation, Route, Switch, useRouteMatch  } from "react-router-dom";

import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import Sidebar from "src/profilesetting/component/sidebar/Sidebar";
import BirthProfile from "src/profilesetting/component/birthprofile/BirthProfile";
import Preference from "src/profilesetting/component/preference/Preference";
import Orders from "src/profilesetting/component/orders/Orders";
import Account from "src/profilesetting/component/accounts/Account";
import OrderDetail from "src/profilesetting/component/orders/orderdetail/OrderDetail";
import OrderTrack from "src/profilesetting/component/orders/ordertrack/OrderTrack";
import SettingHomeMobile from "src/profilesetting/component/mobile/SettingHomeMobile";
import useWindowSize from "src/core/hook/useWindowSize";
import ChatOrderDetails from "src/profilesetting/component/orders/orderdetail/chat_order/ChatOrderDetails";
import ChartPreference from "src/profilesetting/component/preference/ChartPreference";
import NotificationPreference from "src/profilesetting/component/preference/NotificationPreference";


const SettingsHome = () => {

    const history = useHistory();
    const location = useLocation()
    const match = useRouteMatch();
    const windowSize = useWindowSize();

    const [isDarkMode] = useTheme();

    useEffect(() => {
        if(location.pathname === match.path){
            if(windowSize && windowSize?.width > 575) {
                history.push(`${match.path}/birth-profile`);
            }
        }
    }, [location, windowSize])

    // console.log('Window Size', windowSize);

    return (
        <MainLayout>
            <section className={"profile-settings" + (isDarkMode && ' dark' || '')}>
                <div className="container-xl">
                    <div className="row gx-2">
                        <div className="col-sm-4 col-md-3 d-none d-sm-block">
                            <Sidebar path={match.path} isDarkMode={isDarkMode} />
                        </div>
                        <div className="col-sm-8 col-md-9">
                            <div className={"profile-settings__contents" + (isDarkMode && ' dark' || '')}>
                                <Switch>
                                    <Route  
                                        exact
                                        path={`${match.path}`}  
                                        render={props => (
                                            <SettingHomeMobile 
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/birth-profile`}  
                                        render={props => (
                                            <BirthProfile 
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/preference`}  
                                        render={props => (
                                            <Preference
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/preference/chart`}  
                                        render={props => (
                                            <ChartPreference
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/preference/notification`}  
                                        render={props => (
                                            <NotificationPreference
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/accounts`}  
                                        render={props => (
                                            <Account
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route 
                                        exact 
                                        path={`${match.path}/orders`}  
                                        render={props => (
                                            <Orders
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route 
                                        exact
                                        path={`${match.path}/orders/chat/:OrderId`}
                                        render={props => (
                                            <ChatOrderDetails
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route 
                                        exact 
                                        path={`${match.path}/order/:OrderId`}  
                                        render={props => (
                                            <OrderDetail
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                    <Route 
                                        exact 
                                        path={`${match.path}/order/:OrderId/track`}  
                                        render={props => (
                                            <OrderTrack
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                            />
                                        )}
                                    />

                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}

export default SettingsHome;
