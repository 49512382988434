import React from "react"

import PasswordField from "src/core/component/form/PasswordField";


const NewPasswordForm = ({
    formData, 
    onChangePasswordInput,
    handleResetFormSubmit,
    processing,
    isDarkMode,
    ...rest
}) =>  {
 
    let fields = [
        {
            name: 'password',
            placeholder: 'Enter new password',
            label: 'Enter New Password',
            type: 'password',
            required: true,
        },
        {
            name: 'confirm_password',
            placeholder: 'confirm password',
            label: 'Confirm Password',
            type: 'password',
            required: true,
        }
    ];

    return (
        <>
            <p className="description secondary">
                Your new password must be different than your previously used password
            </p>
            <form onSubmit={handleResetFormSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')}>
                <div className="row gx-2 gx-smsm-3">
                        {
                            fields && fields.map((item, index) =>{
                                return (
                                    <div className="col-12 col-sm-6 field" key={index}>
                                        <PasswordField 
                                            property={item.name}
                                            showToggle={false}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={onChangePasswordInput}
                                            value={formData[item.name]}
                                            required={item.required}
                                        />
                                        
                                    </div>
                                )
                            })
                        }
                </div>

                <div className="submit-login-register">
                    <button className="pocket-btn" type="submit">
                        {processing ? 'Processing...' : 'Reset'}
                    </button>
                </div>

            </form>
        </>
                        
    )
}

export default NewPasswordForm
