import React, {useState} from "react";

import { StarSvg } from "src/core/component/assets/svg/CommonSVG";


const PocketRatings = ({title, ratings, setRatings, isDarkMode, readOnly=false, ...rest}) => {
    const [hover, setHover] = useState(0);
    setRatings = typeof setRatings === 'function' ? setRatings : function noop() { }


    return (
        <div className={`pocket-ratings ${isDarkMode && 'dark' || ''}`}>
            {title && (
                <h6 className={`pocket-ratings__title ${isDarkMode && 'dark' || ''}`}>
                    {title}
                </h6>
            )}
            
            <div className={`pocket-ratings__add ${isDarkMode && 'dark' || ''}`}>
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <span
                            type="button"
                            key={index}
                            className={index <= (hover || ratings || 0) ? "rating rated" : "rating"}
                            onClick={() => !readOnly ? setRatings(index) : null}
                            onMouseEnter={() => !readOnly ? setHover(index) : null}
                            onMouseLeave={() => !readOnly ?  setHover(ratings || 0) : null}
                        >
                            <StarSvg />
                        </span>
                    );
                })}
            </div>
        </div>
    )
}

export default PocketRatings
