import React from "react";
import { useLocation } from "react-router-dom";

import * as astologerSvg from 'src/core/component/assets/svg/connectastrologer/ConnectAstrologer';
import NavLink from 'src/core/component/nav/NavLink';
import { AngleDownSvg } from "src/core/component/assets/svg/CommonSVG";


const Sidebar = ({ path, isDarkMode, ...rest}) => {

    const location = useLocation();


    // # TODO change to side bar of admin
  
    let navItems = [
        {
          url: `${path}`,
          page: 'Dashboard',
          icon: <astologerSvg.DashboardSvg />,
          isLink: true
        },
        {
            url: `${path}/profile`,
            page: 'Edit Profile',
            icon: <astologerSvg.EditSvg />,
            isLink: true
        },
        {
            url: `${path}/reviews`,
            page: 'Reviews',
            icon: <astologerSvg.ReviewSvg />,
            isLink: true, 
        },
        {
            url: `/conversations`,
            page: 'Chat',
            icon: <astologerSvg.DashboardChatSvg />,
            isLink: true,
            classes: 'chat'
        }
    ];

    // console.log('NavItems', navItems)

    let classes = isDarkMode ? "profile-settings__sidebar-menu-item dark" : "profile-settings__sidebar-menu-item";

    return (
        <div className={"profile-settings__sidebar" + (isDarkMode && ' dark' || '')}>
            <ul className={"profile-settings__sidebar-menu list-unstyled mb-0" + (isDarkMode && ' dark' || '')}>
                {navItems.map((item, index) => {
                    return (
                            <React.Fragment key={index}>
                                {item.isLink && (
                                    <NavLink
                                        key={index}
                                        url={item.url}
                                        classes={`${classes} ${item.classes || ''}`}
                                        isActive={item.isActive}
                                    >
                                        {item.icon}
        
                                        {
                                            item.page
                                        }

                                        <span className="icon-right d-sm-none ms-auto">
                                            <AngleDownSvg />
                                        </span>    
                                    </NavLink>
                                )}
                            </React.Fragment>
                        );
                    })}
            </ul>
        </div>
    )
}

export default Sidebar;
