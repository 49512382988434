import React from "react";
// import html2canvas from 'html2canvas';
import Kundali from "src/core/component/kundaliV2/Kundali";
import { ProfileIconSvg, ClockSvg, LocationSvg } from "src/core/component/assets/svg/AstrologySVG";


const KundaliSliderItem = ({
    data,
    activeHouses,
    firstHouses,
    kundaliName,
    line,
    variant,
    title,
    config, 
    profile,
    scale,
    isDarkMode,
    ...rest
}) => {

    // console.log('data',data);
    // console.log('kundaliName',kundaliName);
    // console.log('line.id',line.id);
    // variant && line.id && kundaliName

    // const downloadImage = (chartName)=>{
    //     const domElement = document.getElementById(chartName);
    //     html2canvas(domElement, {
    //         onclone: document => {
    //             // document.getElementById("chart-button").style.visibility = "hidden";
    //         }
    //     }).then(canvas => {
    //         const imgData = canvas.toDataURL("image/png");
    //         const link = document.createElement('a');
    //         link.href = imgData;
    //         // console.log("🚀 imgData", imgData,typeof imgData)
    //         link.download = `${chartName}.png`
    //         link.click()
    //     });
    // }

    return (
        <div className="pocket-kundali__slider-item">  
            {variant === 'north' && (
                <div id={`realtime_${variant}_${line.id}_${kundaliName}`}>
                    <div className="kundali-title">
                        {/* <h3 id ="chart-button" onClick={()=>downloadImage(`realtime_${variant}_${line.id}_${kundaliName}`)}>{title} ({kundaliName})</h3> */}
                        <h3 id ="chart-button">{title} ({kundaliName})</h3>
                    </div>
                    <Kundali
                        variant={variant}
                        data={data}
                        transitData={data}
                        xScale={scale}
                        className={`Rtp Kundali ${isDarkMode && 'dark' || ''}`}
                        color={config['name'] == 'rasi' ? '#873A23' : '#03a012'}
                        sthaanaStyle={{ color: '#afafaf', fontWeight: 'normal' }}
                        grahaStyle={{ color: '#505050', fontWeight: '600' }}
                        from='real-prediction'
                        activeHouses = {activeHouses}
                        firstHouses = {firstHouses}
                    >
                    </Kundali>
                </div>
            )}

            {(variant !== 'north') && (
                <div id={`realtime_${variant}_${line.id}_${kundaliName}`}>
                    <div className={`kundali-title ${isDarkMode && 'dark' || ''}`}>
                    <h3 id ="chart-button" onClick={()=>downloadImage(`realtime_${variant}_${line.id}_${kundaliName}`)}>{title} ({kundaliName})</h3>
                    </div>
                    <Kundali
                        variant={variant}
                        data={data}
                         transitData={data}
                        xScale={scale}
                        className={`Rtp Kundali ${isDarkMode && 'dark' || ''}`}
                        color={config['name'] == 'rasi' ? '#873A23' : '#03a012'}
                        sthaanaStyle={{ color: '#afafaf', fontWeight: 'normal' }}
                        grahaStyle={{ color: '#505050', fontWeight: '600' }}
                        from='real-prediction'
                        activeHouses = {activeHouses}
                        firstHouses = {firstHouses}
                    >
                        {profile && (
                            <div className={`text-center chart-profile ${variant} ${isDarkMode && 'dark' || ''}`}>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-center justify-content-center name">
                                        <ProfileIconSvg />
                                        {profile?.name}
                                    </li>

                                    <li className="text-center">
                                        {/* <div className="d-flex align-items-center justify-content-center location">
                                            <LocationSvg />
                                            {profile?.latitude}, {profile?.longitude}
                                        </div> */}
                                        <span className="address">
                                        {profile?.address.split(' ').slice(-2).join(' ')}
                                        </span>
                                    </li>
                                    <li className="text-center">
                                        <div className="d-flex align-items-center justify-content-center timezone">
                                            {/* {profile?.timezone} */}
                                        </div>
                                        <span className="dob"><ClockSvg />
                                            {new Date(profile?.dob).format('MMM dd, yyyy , hh:mm tt')}
                                        </span>
                                        
                                    </li>
                                </ul>
                            </div>
                        )}

                    </Kundali>
                </div>
            )}
        </div>
    )
}

export default KundaliSliderItem