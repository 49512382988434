import React,{useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import ContentHeader from "src/instant-prediction/components/payment/ContentHeader"
import StripeCardPayment from "src/core/component/stripe/StripeCardPayment";
import PaymentProcessingModal from "src/instant-prediction/components/payment/PaymentProcessingModal"
import api from "src/lib/api";

const WalletCheckoutForm = ({order, isDarkMode,isLoading}) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (processing) {
      setShowModal(true);
    }
  }, [processing]);

  useEffect(() => {
    if (error && showModal) {
      setShowModal(false);
    }
  }, [error]);

  const handleModalClose = () => {
    setShowModal(false);
    history.push({
      pathname: "/wallet/transactions",
    });
  };

    const requestClientSecret = async () => {
        try {
          const result = await api.post(`v1/wallet_load/confirm`, {
            // question ,  order ko id kina chahiyo
            'payment_gateway': 'stripe',
          });
          return result;
        } catch (error) {
          error = error?.response?.data?.error || error;
          return {
            error: error?.message || `Sorry, can't process your payment`,
          };
        }
      };
      const handlePaymentSuccess = (data) => {
      //fbq
    
     // refresh the coin data if not updated check it
      setProcessing(false);
      setSuccess(true);
      setShowCloseIcon(false);
  
      setTimeout(() => {
        setShowModal(false);
  
        history.push({
          pathname: "/wallet/transactions",
          state:null
        });
      }, 1000);
      // state null vayana
      }

  return (
    <>
    <div
      className="col-mdlg-6 order-2 order-mdlg-1 position-sticky" style={{ top: "10px", alignSelf: "flex-start" }}>
        <ContentHeader isDarkMode={isDarkMode} />

        <StripeCardPayment
        amount={order?.amount || 0}
        clientSecret={requestClientSecret}
        onSuccess={handlePaymentSuccess}
        processing={processing}
        setProcessing={setProcessing}
        error={error}
        setError={setError}
        isDarkMode={isDarkMode}
        disabled={isLoading || !order}
        buttonLabel = "Buy Pocket Pesa"
        />
     
    </div>

    {/* processing state modal */}
    <PaymentProcessingModal
      showModal={showModal}
      handleModalClose={handleModalClose}
      success={success}
      processing={processing}
      showCloseIcon={showCloseIcon}
      isDarkMode={isDarkMode}
    />
  </>
  )
}

export default WalletCheckoutForm