import React, { useState } from "react"
import { Link, Redirect } from 'react-router-dom';
import LogoDark from "src/core/component/LogoDark";
import LogoLight from "src/core/component/LogoLight";
import AuthBanner from "src/auth/component/banner/AuthBanner";
import { PocketToast } from "src/core/component/toast/PocketToast";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { SuccessMessageSvg } from "src/core/component/assets/svg/CommonSVG";
import api from 'src/lib/api';
import { useAlert } from "src/core/hook/Alert";
import DismissableAlert from "src/core/component/DismissableAlert";
import OtpInputForm from "src/auth/component/forms/OtpInputForm";
import { useTheme } from "src/theme/component/ThemeContext";
import useAuthInfo from "src/auth/hook/auth-info";


export default function EmailVerificationPage() {
  const { isAuthenticated, updateProfile, profile, logout } = useAuthInfo();
  const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
  const [showModal, setShowModal] = useState(false);
  const [optVerified, setOTPVerified] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState(null)
  const [isDarkMode] = useTheme();


  const verifyEmail = async (payload) => {
    const res = await api.post('/v1/auth/verify-email', payload)
    setProcessing(false);
    if (res?.status === 200) {
      setShowModal(true);
    }
  }

  const resendVerificationEmail = async () => {
    const res = await api.post('/v1/auth/resend-email-verification', {})
    setProcessing(false);

    return res;
  }

  const handleOtpSubmit = (e) => {
    e.preventDefault();

    if (processing) return;

    if (!otp) {
      setError('OTP required!');
      setShowAlert(true);
      return;
    }

    if (otp.length > 6) {
      setError('OTP cannot be greater than 6 digits!');
      setShowAlert(true);
      return;
    }
    const payload = { "otp_code": otp };
    setProcessing(true);

    verifyEmail(payload).catch(e => {
      setError(e?.response?.data?.error?.message || e?.message)
      setShowAlert(true)
    })
      .finally(() => {
        setProcessing(false)
      });
  }

  const handleModalClose = async () => {
    setShowModal(false);
    const reqProfile = await api.get('v1/users/me');

    if (reqProfile.status == 200) {
      updateProfile(reqProfile?.data?.result?.data);
      setOTPVerified(true);
    }

  }

  const resendVerificationCode = async () => {
    return resendVerificationEmail().then(() => {
      PocketToast({
        type: 'success',
        message: 'Verification code sent',
      });
    }).catch((error) => {
      const errMsg = error.response?.data?.error?.message || error.message;

      PocketToast({
        type: 'error',
        message: errMsg,
      });
    });
  }

  if (!isAuthenticated || optVerified || profile?.is_email_verified) {
    if (location.state?.from || location.state?.search) {
      return <Redirect
        to={{
          pathname: (location.state?.from && location.state.from) || "/",
          search: (location.state?.search && location.state.search) || ""
        }}
      />
    } else {
      return <Redirect to="/" />;
    }
  }

  return (

    <main className={"pocket-main auth-main" + ((isDarkMode && " dark") || "")}>
      <section className={"pocket-auth" + ((isDarkMode && " dark") || "")}>
        <div className="auth-container">
          <div className="pocket-auth__logo-sm d-md-none">
            <Link to="/">{isDarkMode ? <LogoDark /> : <LogoLight />}</Link>
          </div>
          <div className="row gx-md-2 gx-lg-4 gx-xl-5">
            <div className="col-md-3 col-lg-4 d-none d-md-block">
              <AuthBanner isDarkMode={isDarkMode} />
            </div>
            <div className="col-sm-12 col-md-9 col-lg-8 col-xl-7">

              <div className={"pocket-auth__new-password" + (isDarkMode && ' dark' || '')}>
                <div className="row justify-content-center">
                  <div className="col-sm-9 col-md-9 col-lg-9 col-xl-10">
                    <div className={"card new-password" + (isDarkMode && ' dark' || '')}>

                      {showAlert && (
                        <DismissableAlert text={error} handleAlertClose={handleAlertClose} classes={'dismissable-alert alert-error'}>
                        </DismissableAlert>
                      )}

                      <h4 className="heading secondary">Verify your email</h4>
                      <OtpInputForm
                        processing={processing}
                        setOtp={setOtp}
                        handleOtpSubmit={handleOtpSubmit}
                        onClickResend={resendVerificationCode}
                        isDarkMode={isDarkMode}
                      />

                      {isAuthenticated && (<Link to="/" className="mt-3 text-secondary" onClick={(e) => {
                        e.preventDefault();
                        logout();
                      }}>Logout</Link>)}
                    </div>
                  </div>
                </div>

                {/* Email/Code sent modal */}
                <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={true} centered={false} classes={'pocket-auth__modal' + (isDarkMode && ' dark' || '')} backdrop="static">
                  <ModalBody>
                    <div className="card">
                      <div className="icon change-successful mx-auto">
                        <SuccessMessageSvg />
                      </div>
                      <p className="card-text">Your email has been successfully verified</p>
                    </div>
                  </ModalBody>
                </CommonModal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

  )
}
