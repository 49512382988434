import React, {useState, useEffect} from "react";
import { useHistory } from "react-router";
import queryString from "query-string";

import api from 'src/lib/api';
import { isObjectEmpty } from "src/util/extra";
import SimpleAlert from "src/core/component/SimpleAlert";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from 'src/auth/hook/auth-info';
import NotAuthenticatedAlert from "src/core/component/NotAuthenticatedAlert";
import AstrologerCard from "src/core/component/astrologer/AstrologerCard";
import Pagination from "src/core/component/Pagination";
import { PocketToast } from "src/core/component/toast/PocketToast";


const AstrologerList = ({ match, isDarkMode, astroFilter, order, ...rest}) => {
    const history = useHistory()
    const [currentPage, setCurrentPage] = useState();
    const { isAuthenticated } = useAuthInfo();
    const [searchQuery, setSearchQuery] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        'query': '',
    })
    const [astrologers, setAstrologers] = useState([])
    const [astrologersToRender, setAstrologersToRender] = useState([]);
    const [metadata, setMetaData] = useState(null);


    useEffect(() => {
        isAuthenticated && fetchAstrologers();
    }, [])

    const fetchAstrologers = async () => {
        setLoading(true)

        try {
            // const result = await api.get(`v1/users?astrologer=true&exclude_me=true`);
            const result = await api.get(`v1/astrologers?exclude_me=true`)
            setAstrologers(result?.data?.result?.data || []);
            setMetaData(result?.data?.result?.metadata || null);
            setLoading(false);
        }
        catch (error) {
            setError(error?.message || 'Error on fetching astrologers.');
            PocketToast({
                type: "error", 
                message: "Error on fetching astrologers."
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(astrologers.length) {
            setAstrologersToRender(astrologers)
        }
    }, [astrologers])

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        if(query.query){
            setSearchQuery({'query': query.query})
        }
    }, [history.location])

    useEffect(() => {
        if(!isObjectEmpty(searchQuery)){
            const filteredAstrologers = astrologers && astrologers.filter(a => a.name.toLowerCase().includes((searchQuery.query).toLowerCase())) || [];
            setAstrologersToRender(filteredAstrologers);
        }
    }, [searchQuery])

    useEffect(() => {
        const queries = queryString.parse(history.location.search);
        setCurrentPage(queries.page || 1);
    }, [history.location]);

    // console.log('Astrologers', astrologers);
    // console.log('Meta data', metadata);
    // console.log('Query', searchQuery);
    // console.log('Astrologers to Render', astrologersToRender);
    // console.log('Astrologer Selected', selectedAstrologer);
    // console.log('Hours', hours);
    // console.log('Conversation', conversation)

    return (
        <>
            {searchQuery && !isObjectEmpty(searchQuery) && (
                <div className="row justify-content-center mt-2 mt-sm-3 mt-md-0">
                    <div className="col-12 col-lg-11 col-xxl-10">
                        <div className={"search-term" + (isDarkMode && ' dark' || '')}>
                            <h4>Searched Astrologer:<span>&ldquo;{searchQuery.query}&rdquo;</span></h4>
                        </div>
                    </div>
                </div>
            )}

            <div className="row justify-content-center mt-3 mt-md-0">
                <div className="col-12 col-lg-11 col-xxl-10">
                    {!isAuthenticated && (
                        <div className="mb-3">
                            <NotAuthenticatedAlert 
                                path={"/auth/login"}
                                isDarkMode={isDarkMode}
                            />
                        </div>
                    )}

                    {loading && (
                        <LoadingIcon />
                    )}

                    {/* Astrologers */}
                    {!loading && isAuthenticated && astrologersToRender && astrologersToRender.length > 0 && (
                        <>
                            <div className="row gx-2 gx-md-4 gx-lg-2 gx-xl-4">
                                {astrologersToRender.map(item => {
                                    return (
                                        <div className="col-sm-6 col-lg-4 mb-3" key={item.id}>
                                            <AstrologerCard
                                                astrologer={item}
                                                isDarkMode={isDarkMode}
                                                className={"astrologer h-100"}
                                                metaData={metadata}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        count={astrologersToRender && astrologersToRender.length || 0 }
                                        pageSize={50}
                                        isDarkMode={isDarkMode}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {!loading && (!astrologersToRender || !astrologersToRender.length) && (
                        <SimpleAlert classes="alert-danger">
                            <p>Sorry no astrologers are found!!!</p>
                        </SimpleAlert>  
                    )}
                </div>
            </div>
        </>
    )
}

export default AstrologerList;
