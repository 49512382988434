import React from "react";

import QuestionList from "src/core/component/question/QuestionList";
import ViewMoreLink from "./ViewMoreLink";


const OfferedQuestions = ({
    promo, 
    questions, 
    isDarkMode, 
    handleQuestionSelect,
    isSelected,
    profile,
    handleBrowseMore,
    hideViewMore=false, 
    ...rest
}) => {

    if(promo && promo.scope==='question' && promo.questions.length && questions.length){
        questions = questions.filter(item=>item.id!==promo.questions[0].id)
    } else if(promo && promo.scope==='category' && promo.categories.length && questions.length) {
        let categoryQuestions = questions.filter(item=>item.category_id === promo.categories[0].id)
        questions = categoryQuestions && categoryQuestions.length > 4 && categoryQuestions.slice(4);
    }

    return (
        <div className="related-contents__question">
            {questions && questions.length > 0 && (
                <>
                    <h3 className="related-title">You might also like</h3>
                    <QuestionList
                        questions={questions && questions.slice(0,4) || []}
                        handleQuestionSelect={handleQuestionSelect}
                        profile={profile}
                        isDarkMode={isDarkMode}
                    />

                    {(questions && questions?.length > 4) && (
                        <>
                            {!hideViewMore && (
                                <ViewMoreLink 
                                    title={'view more questions'}
                                    onClick={handleBrowseMore}
                                    className={`d-flex justify-content-end`}
                                    isDarkMode={isDarkMode}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default OfferedQuestions
