import React from "react";

import QuestionItem from "./QuestionItem";

const SelectedQuestions = ({
  orderLines,
  order,
  handleRemoval,
  isProcessing,
  deletingLineId,
  isDarkMode,
  ...rest
}) => {
  return (
    <div
      className={`selected-questions mb-3 mb-sm-2 ${
        (isDarkMode && "dark") || ""
      }`}
    >
      {/* <p className="heading d-none d-sm-block">
                Selected Questions:
            </p> */}
      <div className={`questions-list ${(isDarkMode && "dark") || ""}`}>
        {orderLines &&
          orderLines.map((line, index) => {
            return (
              <QuestionItem
                key={line.id}
                line={line}
                handleRemoval={handleRemoval}
                isDarkMode={isDarkMode}
                isProcessing={isProcessing}
                deletingLineId={deletingLineId}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SelectedQuestions;
