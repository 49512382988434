import React from 'react'

import api from 'src/lib/api';
import SimplePayment from 'src/core/component/stripe/SimplePayment';
import { pluralize } from 'src/util/extra';

export default function BuyConversation({ conversation }) {
  const [hours, setHours] = React.useState(1);
  const [clientSecret, setClientSecret] = React.useState(null);

  const handleInputChange = (e) => {
    setHours(+e.target.value);
  }

  const requestClientSecret = () => {
    return Promise.resolve({ client_secret: clientSecret });
  }

  const handlePaymentSuccess = () => {
    setHours(1);

    setTimeout(() => {
      setClientSecret(null);
    }, 200);

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Promise.resolve('randomstring' + hours).then(setClientSecret);

    try {
      const res = await api.post(`v1/conversations/${conversation.id}/buy-hours`, {
        'hours': hours,
      });

      const data = res.data.result.data;

      setClientSecret(data.client_secret);

      if (data.amount == 0) {
        handlePaymentSuccess();
      }
    } catch (error) {
      error = (error.response?.data?.error || error);
      console.log('Error purchasing conversation hours', error);
    }
  }

  if (clientSecret) {
    return <SimplePayment clientSecret={requestClientSecret} onSuccess={handlePaymentSuccess} />
  }

  return (<form onSubmit={handleSubmit}>
    <div className="input-group input-group-sm">
      <input name="hours" type="number" min="1" className="form-control" value={hours} onChange={handleInputChange} />

      <div className="input-group-append">
        <span className="input-group-text">
          {pluralize(hours, 'Hour', 'Hours')}
        </span>
      </div>

      <div className="ml-1 input-group-btn">
        <button type="submit" className="btn btn-success btn-sm small px-3"><strong>BUY</strong></button>
      </div>
    </div>
  </form>)
}
