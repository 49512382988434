import React from "react";
import Moment from 'react-moment';


const MomentCalendarString = ({datetime, ...rest}) => {
    //For all possible format see=>https://momentjs.com/docs/#/displaying/
    const calendarStrings = {
        lastDay : '[Yesterday at] LT',
        sameDay : '[Today at] LT',
        nextDay : '[Tomorrow at] LT',
        sameElse: 'lll'
    };
  
    return (
        <Moment calendar={calendarStrings}>
            {datetime}
        </Moment>
    )
}

export default MomentCalendarString
