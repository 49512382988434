import React from 'react'

import { pluralize } from 'src/util/extra';


const TimeSetupForm = ({isDarkMode, hours, setHours, handleInputChange, invalidHours, ...rest}) => {

    return (
        <form className={"pocket-form" + (isDarkMode && ' dark' || '')}>
            <div className="row">
                <div className="col-12 field">
                    <div className="input-group input-group-sm">
                        <input name="hours" id="hours" type="number" min="1" className={"form-control" + (invalidHours && " is-invalid" || "")} value={hours} onChange={handleInputChange} required />
                        <div className="input-group-append">
                            <span className="input-group-text">
                            {pluralize(hours, 'Hour', 'Hours')}
                            </span>
                        </div>
                        <div className="invalid-feedback text-start">
                            Not a valid time, it should be whole number and should be greater than 0.
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default TimeSetupForm