import { useDispatch, useSelector } from "react-redux";
import { 
    selectAstrologer as astrologerSelectAction, 
    clearSelectedAstrologer as selectedAstrologerClearAction,
} from 'src/connect-with-astrologer/redux';


export default function useCustomerChat() {
  const dispatch = useDispatch();
  const chatInfo = useSelector(state => state.chat);
  const authInfo = useSelector(state => state.auth);

  const _chatAstrologer = () => {
    if(authInfo.accessToken && authInfo.profile && chatInfo.astrologer){
        return chatInfo.astrologer
    }
    return null
  }

  const selectAstrologer = (astrologer) => {
    dispatch(astrologerSelectAction(astrologer));
  }

  const clearSelectedAstrologer = () => {
    dispatch(selectedAstrologerClearAction());
  }

  return {
    get chatAstrologer() {
      return _chatAstrologer();
    },

    selectAstrologer,
    clearSelectedAstrologer
  };
}
