import React from "react";
import { Link } from "react-router-dom";

import { NextArrowSvg } from "src/core/component/assets/svg/CommonSVG";


const ViewMoreLink = ({title, onClick,  isDarkMode, className='', ...rest}) => {

    return (
        <div className={`view-more ${className}`}>
            <Link to="#" className={`pocket-link ${isDarkMode && 'dark' || ''}`} onClick={onClick}>
                {title}
                <NextArrowSvg />
            </Link>
        </div>
    )
}

export default ViewMoreLink
