import React from "react";

import FooterNavLinks from "./FooterNavLinks";
import FooterNavSocials from "./FooterNavSocials";

const FooterNav = ({ page, isDarkMode, ...rest }) => {
  return (
    // <div className="d-flex justify-content-between align-items-center pocket-footer__nav">
    <div className="pocket-footer__nav">
      <FooterNavSocials isDarkMode={isDarkMode} />
      <FooterNavLinks currentPage={page} isDarkMode={isDarkMode} />
    </div>
  );
};

export default FooterNav;
