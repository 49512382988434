import React from "react";

import ListLinkItem from "src/core/component/ListLinkItem";
import facebook from "src/image/social_icons/Facebook@2x.png";
import instagram from "src/image/social_icons/Instagram@2x.png";
import linkedin from "src/image/social_icons/Linkedin@2x.png";
import youtube from "src/image/social_icons/Youtube@2x.png";
import tiktok from "src/image/social_icons/Tiktok@2x.png";

const FooterNavSocials = ({ isDarkMode, ...rest }) => {
  let navSocials = [
    {
      url: "https://www.facebook.com/pocketpandit.astrology/",
      icon: <img src={facebook} className="img-fluid" alt="" />,
    },
    {
      url: "https://www.instagram.com/pocketpandit/",
      icon: <img src={instagram} className="img-fluid" alt="" />,
    },
    /*{
      url: "#",
      icon: <img src={linkedin} className="img-fluid" alt="" />,
    },
    {
      url: "#",
      icon: <img src={youtube} className="img-fluid" alt="" />,
    },*/
    {
      url: "https://www.tiktok.com/@pocketpandit",
      icon: <img src={tiktok} className="img-fluid" alt="" />,
    },
  ];

  return (
    <ul
      /*       className={
        "list-unstyled mb-0 d-flex justifiy-content-start align-items-center pocket-footer__nav-socials" +
        ((isDarkMode && " dark") || "")
      } */
      className={
        "list-unstyled pocket-footer__nav-socials" +
        ((isDarkMode && " dark") || "")
      }
    >
      {navSocials.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.url} target="_blank">
              {item.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default FooterNavSocials;
