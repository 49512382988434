import React from "react";

import {isObjectEmpty} from "src/util/extra";
import PocketSelection from "src/core/component/selection/PocketSelection";
import SwiperCarousel from "../slider/SwiperCarousel";
import ProfileFilterItem from "./ProfileFilterItem";

import All from "src/instant-prediction/assets/all-category@2x.png";

const ProfileFilter = ({
  rtpFilterIds = {},
  filter,
  onChangeFilter,
  handleRelationshipSelect,
  isRelationshipSelected,
  relationships,
  isDarkMode,
  className = "",
  ...rest
}) => {
  onChangeFilter =
    typeof onChangeFilter === "function" ? onChangeFilter : function noop() {};

  const settings = {
    slidesPerView: "auto",
    spaceBetween: 10,
    mouseWheel : true,
  };

  const options =
    relationships &&
    relationships.map((data) => ({
      id: data.id,
      display: data.name,
      name: "relationship_id",
    }));

  const filterOptions = [
    { id: "all", display: "All", name: "relationship_id" },
    ...options,
  ];
  const currentFilter =
    (filter?.p_relationship_id &&
      filterOptions.filter(
        (opt) => opt.id === filter["p_relationship_id"]
      )[0]) ||
    filterOptions.filter((opt) => opt.id === filter["relationship_id"])[0] ||
    "";

  // console.log("relationships", relationships);
  // console.log("filter", rtpFilterIds);

  function showFilterType() {
    if (!isObjectEmpty(rtpFilterIds)) {
      return (
        <div className="d-flex">
          <ProfileFilterItem
            classes={`rtp-filter__item h-auto${
              rtpFilterIds?.relationship_id === "all" ? " selected" : ""
            }`}
            item={{
              id: "all",
              name: "All",
              icon_url: All,
              color: "inherit",
            }}
            handleClick={onChangeFilter}
          />

          {relationships && relationships.length > 0 && (
            <SwiperCarousel
              {...settings}
              items={relationships.map((relationship) => (
                <ProfileFilterItem
                  key={relationship.id}
                  classes={`rtp-filter__item${
                    isRelationshipSelected(relationship) ? " selected" : ""
                  }`}
                  item={relationship}
                  handleClick={onChangeFilter}
                />
              ))}
            />
          )}
        </div>
      );
    } else {
      return (
        <PocketSelection
          options={[...filterOptions]}
          currentValue={currentFilter}
          onChange={onChangeFilter}
          classes={"birth-profile__filter" + ((isDarkMode && " dark") || "")}
        />
      );
    }
  }

  return (
    <div
      className={`filters ${(isDarkMode && " dark") || ""} ${
        (className && className) || ""
      }`}
    >
      {/* relationship option filter */}
      {showFilterType()}
    </div>
  );
};

export default ProfileFilter;
