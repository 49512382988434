import React from 'react'
import { Link } from 'react-router-dom';

import api from 'src/lib/api';
import { pick, prepareFormData, validateEmail } from 'src/util/extra';
import { CalendarSvg } from "src/core/component/assets/svg/CommonSVG";
import CheckboxField from "src/core/component/form/CheckboxField";
import PasswordField from "src/core/component/form/PasswordField";
import FormField from "src/core/component/form/FormField";
import SearchLocation from "src/core/component/form/SearchLocation";
import FileField from 'src/core/component/form/FileField';
import { PocketToast } from "src/core/component/toast/PocketToast";
import { dateAndTimeToDateTime } from "src/util/date_and_time_to_datetime";
import PocketDatePicker from "src/core/component/form/PocketDatePicker";
import useAuthInfo from "src/auth/hook/auth-info";

const initialState = {
  'login_method': 'password',
  'name': '',
  'address': '',
  'dob': '',
  'latitude': '',
  'longitude': '',
  'timezone': '',
  'login': '',
  'password': '',
  'astrologer': true,
  'resume': '',
  'date': new Date().format('yyyy-MM-dd'),
  'time': new Date().format('HH:mm:ss'),
  'agree_tos': false,
};

export default function AstrologerSignupForm({isDarkMode}) {
    const { login: loginUser } = useAuthInfo();
    const [formData, setFormData] = React.useState(initialState);
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [tosError, setTosError] = React.useState(null);
    const [fieldError, setFieldError] = React.useState(null);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setResponse(null);
        setError(null);
        setTosError(null);
        setFieldError(null);

        if(!validateEmail(formData['login'])) {
            setFieldError({'login': 'Email is not valid'});
            return;
        }

        if (!formData["agree_tos"]) {
        setTosError({
            agree_tos: "You must agree to our Terms of Services and Privacy Policy.",
        });

        return;
        }

        const dob = dateAndTimeToDateTime(formData['date'], formData['time']);
        if(dob){
            formData['dob'] = dob
        }

        const payload = pick(
            formData,
            'login',
            'password',
            'astrologer',
            'name',
            'address',
            'dob',
            'latitude',
            'longitude',
            'timezone',
            'resume'
        );

        const formPayload = prepareFormData(payload)

        try {
            const res = await api.post("/v1/auth/signup/astrologer", formPayload, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            const data = res.data?.result?.data;
            if(data) {
                PocketToast({
                type: "success",
                message: "Congratulations, your account has been created successfully",
                });

                loginUser(data["access_token"], data["refresh_token"]);

            } else {
                throw res;
            }

        } catch (error) {
            setResponse({
                'error': error?.response?.data?.error || { message: error?.message || 'Unknown Error.' },
            });
        }
    }

    const onChangeInputValue = (e) => {
        let value;

        if (e.target['type'] == 'checkbox') {
        value = e.target.checked;
        } else {
        value = e.target.value;
        }

        setFormData({
        ...formData,
        [e.target.name]: value,
        });
    }

    const onLocationChange = (data) => {
        setFormData((currentValue) => ({
        ...currentValue,
        'address': data['query'],
        'latitude': data['place']['latitude'],
        'longitude': data['place']['longitude'],
        'timezone': data['timezone']['name'],
        }));
    }

    const onChangeFile = (file) => {
        setFormData((currentValue) => ({
            ...currentValue,
            'resume': file
        }));
    }

    // console.log('Form Data', formData)

    let fields = [
        {
            name: 'name',
            placeholder: 'Enter your name',
            label: 'Name',
            type: 'text',
            hasIcon: false,
            icon: '',
            required: true
        },
        {
            name: 'login',
            placeholder: 'Enter your email',
            label: 'Email',
            type: 'email',
            hasIcon: false,
            icon: '',
            required: true
        },
        {
            name: 'password',
            placeholder: 'Enter your password',
            label: 'Password',
            type: 'password',
            hasIcon: false,
            icon: '',
            required: true
        },
        {
            name: 'address',
            placeholder: 'Enter place of birth',
            label: 'Place of birth',
            type: 'location-input',
            hasIcon: false,
            icon: '',
            required: true
        },
        {
            name: 'date',
            type: 'date',
            placeholder: 'Enter Birth Date',
            label: 'Birth Date (YYYY-MM-DD)',
            required: true
        },
        {
            name: 'time',
            type: 'time',
            placeholder: 'Enter Birth Time',
            label: 'Birth Time (HH:MM:SS AM/PM)',
            step: 1,
            required: true
        },
        {
            name: 'resume',
            placeholder: 'Select resume',
            label: 'Upload your resume',
            type: 'file',
            required: false
        },
    ];

    return (
        <div className={"pocket-auth__signup--wrapper" + (isDarkMode && ' dark' || '')}>
            <form method="POST" onSubmit={handleFormSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')} autoComplete="off">
                {(response?.error || error) && (
                    <div className="alert alert-danger w-100 text-center my-2">
                    <strong> {response.error['message'] || error} </strong>
                    </div>
                )}

                <div className="row gx-2 gx-smsm-3">
                    {
                        fields && fields.map((item, index) =>{
                            return (
                                <div className="col-12 col-sm-6 field" key={index}>
                                    {(item.type === "text" || item.type === "email" || item.type === "time") && (
                                        <FormField
                                            property={item.name}
                                            type={item.type}
                                            hasLabel={true}
                                            hasIcon={item.hasIcon}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            icon={item.icon}
                                            onChange={onChangeInputValue}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                            autoComplete={"off"}
                                            isInvalid={fieldError && Object.keys(fieldError).includes(item.name)}
                                            error={fieldError && Object.keys(fieldError).includes(item.name) && fieldError[item.name]}
                                        />
                                    )}

                                    {item.type === "date" && (
                                        <PocketDatePicker
                                            formData={formData}
                                            setFormData={setFormData}
                                            property={item.name}
                                            required={true}
                                            error={"Please enter a valid date!"}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            disabled={false}
                                            isDarkMode={isDarkMode}
                                        />
                                    )}

                                    {
                                        item.type === "password" &&
                                        <PasswordField
                                            property={item.name}
                                            hasLabel={true}
                                            hasIcon={item.hasIcon}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            icon={item.icon}
                                            onChange={onChangeInputValue}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                        />
                                    }

                                    {item.type == "location-input" && (
                                        <SearchLocation
                                            property={item.name}
                                            hasLabel={true}
                                            hasIcon={item.hasIcon}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            icon={item.icon}
                                            onChange={onLocationChange}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                        />
                                    )}

                                    {item.type == "file" && (
                                        <FileField
                                            property={item.name}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={onChangeFile}
                                            required={item.required}
                                            isDarkMode={isDarkMode}
                                        />
                                    )}

                                </div>
                            )
                        })
                    }
                </div>

                <CheckboxField
                    property={'agree_tos'}
                    onChange={onChangeInputValue}
                    classes={"register-login mb-3"}
                    isInvalid={tosError && tosError?.agree_tos || false}
                    error={tosError?.agree_tos && tosError?.agree_tos || false}
                >
                <label className="form-check-label" htmlFor="agree_tos">
                    I agree with the
                    <Link to="/tos">Terms of Services</Link> and&nbsp;
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </label>
            </CheckboxField>

                <div className="submit-login-register">
                    <button className="pocket-btn" type="submit">Sign Up</button>
                </div>
            </form>

            <div className="account-exists">
                Already have an account? <Link to="/auth/login">Login</Link>
            </div>

        </div>
    )
}
