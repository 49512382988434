import { useDispatch, useSelector } from "react-redux";

import {
    setupPromoData as promoDataSetupAction,
    updatePromoLastCheck as pormoLastCheckUpdateAction,
    updatePromoData as promoDataUpdateAction,
    updatePromoDataBulk as promoDataUpdateBulkAction,
    clearPromoData as promoDataClearAction
} from 'src/promos/redux';


export default function usePromo() {
    const dispatch = useDispatch();
    const promoInfo = useSelector(state => state.promo);

    const _getPromoData = () => {
        const data = promoInfo.promoNotificationData;
        return data;
    }

    const _getPromoLastCheck = () => {
        const lastChecked = promoInfo.lastChecked;
        return lastChecked;
    }

    const setupPromoData = (datetime) => {
        dispatch(promoDataSetupAction(datetime));
    }

    const updatePromoLastCheck = (datetime) => {
        dispatch(pormoLastCheckUpdateAction(datetime));
    }

    const updatePromoData = (data) => {
        dispatch(promoDataUpdateAction(data));
    }

    const updatePromoDataBulk = (data) => {
        dispatch(promoDataUpdateBulkAction(data));
    }

    const clearPromoData = () => {
        dispatch(promoDataClearAction());
    }

    return {
        get promoNotificationData() {
            return _getPromoData()
        },
        get promoLastCheck() {
            return _getPromoLastCheck()
        },
        setupPromoData,
        updatePromoLastCheck,
        updatePromoData,
        updatePromoDataBulk,
        clearPromoData
    };
}
