import React from "react";

import Modal from 'react-bootstrap/Modal';
import { CloseButtonSvg } from "src/core/component/assets/svg/CommonSVG";
import ModalBody from "src/core/component/modal/ModalBody";


const ImagePopup = ({
    showModal,
    handleModalClose,
    showCloseIcon=true, 
    isDarkMode,
    size="xl",
    imageSrc,
    caption="",
    ...rest
}) => {

    return (
        <Modal show={showModal} onHide={handleModalClose} className={`pocket-image__modal}` + (isDarkMode && ' dark' || '')} size={size} backdrop="backdrop">
            {
                showCloseIcon &&
                <div className="modal-custom-close" onClick={handleModalClose}>
                    <CloseButtonSvg />
                </div>
            }

            <ModalBody>
                <div className="image-container d-flex flex-column align-items-center justify-content-center p-2">
                    <img src={imageSrc} className="img-fluid" />
                    {/* style={{maxHeight: '85vh'}} */}

                    {caption && (
                        <div className="caption mt-2" style={{wordBreak:'break-word', display: 'block'}}>
                            {caption}
                        </div>
                    )}
                    
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ImagePopup