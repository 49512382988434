import React from "react";
import Accordion from 'react-bootstrap/Accordion';


const AccordionHeader = ({title, handleSelect, children, icon="", item="", selected=false, classes="",color="", ...rest}) => {

    handleSelect = handleSelect || function noop() { }

    const handleItemClick = (e) => {
        e.preventDefault();
        item && handleSelect(e,item);
    }

    let style = item && selected ? `0 0 0 0.1rem ${item.color}` : '';

    return (
        <Accordion.Header className={classes} onClick={handleItemClick} style={{'boxShadow': style}}>
            {
                children && children
            }

            <div className="item-title d-flex align-items-center justify-content-start">
                {icon && (
                    // <div className="icon d-flex align-items-center justify-content-center" style={{backgroundColor: `${color && color || ''}`}}>
                    <div className="icon d-flex align-items-center justify-content-center">
                        <img src={icon} className="img-fluid" alt="" />
                    </div>
                )}

                {title}
            </div>
            
        </Accordion.Header>
    )
}

export default AccordionHeader