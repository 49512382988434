import { useDispatch, useSelector } from "react-redux";
import {
  login as loginAction,
  logout as logoutAction,
  setupUserInfos as userInfoSetupAction,
  fetchProfile as profileFetchAction,
  fetchBirthProfiles as birthProfilesFetchAction,
  fetchSystemPreferences as systemPreferencesFetchAction,
  fetchNotificationPreferences as notificationPreferencesFetchAction,
  updateBirthProfile as birthProfileUpdateAction,
  deleteBirthProfile as birthProfileDeleteAction,
  updateSystemPreference as systemPreferenceUpdateAction,
  updateProfile as profileUpdateAction,
  updateNotificationPreference as notificationPreferenceUpdateAction,
  updateAccessToken as accessTokenUpdateAction,
} from 'src/auth/redux';

import { clearRTPInfo as userRTPInfoClearAction } from "src/instant-prediction/redux";
import { lightTheme as lightThemeAction } from "src/theme/redux";
import { clearAstroInfo as userAstroInfoClearAction} from "src/freeastrology/redux";
import { clearUserNotification } from "src/notification/redux";

export default function useAuthInfo() {
  const dispatch = useDispatch();
  const authInfo = useSelector(state => state.auth);
  const _isAuthenticated = () => !!(authInfo.accessToken && authInfo.profile);
  const _getPrimaryBirthProfile = () => {
    const [primary = null] = (authInfo.birthProfiles || []).filter(p => p.is_primary == true);
    return primary;
  }

  const login = (accessToken, refreshToken) => {
    dispatch(loginAction(accessToken, refreshToken));
  }

  const logout = () => {
    dispatch(logoutAction());

    // To clear the current user's RTP state.
    dispatch(userRTPInfoClearAction());

    // Clear Free Astrology Active Profile
    dispatch(userAstroInfoClearAction());

    // Clear user notifications
    dispatch(clearUserNotification());
  }

  const setupUserInfos = () => {
    dispatch(userInfoSetupAction());
  }

  const fetchProfile = () => {
    dispatch(profileFetchAction())
  }

  const fetchBirthProfiles = () => {
    dispatch(birthProfilesFetchAction())
  }

  const fetchSystemPreferences = () => {
    dispatch(systemPreferencesFetchAction())
  }

  const fetchNotificationPreferences = () => {
    dispatch(notificationPreferencesFetchAction())
  }

  const updateProfile = (profile) => {
    dispatch(profileUpdateAction(profile));
  }

  const updateBirthProfile = (profile) => {
    dispatch(birthProfileUpdateAction(profile));
  }

  const deleteBirthProfile = (profile) => {
    dispatch(birthProfileDeleteAction(profile));
  }

  const updateSystemPreference = (preference) => {
    dispatch(systemPreferenceUpdateAction(preference));
  }

  const updateNotificationPreference = (preference) => {
    dispatch(notificationPreferenceUpdateAction(preference))
  }

  const updateAccessToken = (token) => {
    dispatch(accessTokenUpdateAction(token));
  }

  return {
    accessToken: authInfo.accessToken,
    refreshToken: authInfo.refreshToken,
    profile: authInfo.profile,
    preference: authInfo.preference,
    notification_preference: authInfo.notification_preference,
    birthProfiles: authInfo.birthProfiles || [],

    get isAuthenticated() {
      return _isAuthenticated();
    },
    get primaryBirthProfile() {
      return _getPrimaryBirthProfile();
    },

    login,
    logout,
    setupUserInfos,
    fetchProfile,
    fetchBirthProfiles,
    fetchSystemPreferences,
    fetchNotificationPreferences,
    updateProfile,
    updateBirthProfile,
    deleteBirthProfile,
    updateSystemPreference,
    updateNotificationPreference,
    updateAccessToken
  };
}
