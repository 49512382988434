export const kundaliLines = (width, height) => ([
  //Horizontal lines
  { move: [0, height / 3], line: [width, height / 3] },
  { move: [0, 2 * height / 3], line: [width, 2 * height / 3] },

  //Vertical lines
  { move: [width / 3, 0], line: [width / 3, height] },
  { move: [2 * width / 3, 0], line: [2 * width / 3, height] },

  //Diagonal lines
  { move: [0, 0], line: [width / 3, height / 3] },
  { move: [width, 0], line: [2 * width / 3, height / 3] },
  { move: [width, height], line: [2 * width / 3, 2 * height / 3] },
  { move: [0, height], line: [width / 3, 2 * height / 3] },


  //Border line
  { move: [0, 0], line: [width, 0] }, // Border top
  { move: [0, 0], line: [0, height] }, // Border left
  { move: [0, height], line: [width, height] }, // Border bottom
  { move: [width, height], line: [width, 0] }, // Border right
]);


export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
});

const codeStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      height: 60 * scale,
      width: 134 * scale,
      top: 20 * scale,  
      left: 133 * scale,
    },
    sthaana: {  
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top:  4 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      left: 195 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      height: 55 * scale,
      width: 52 * scale,
      top: 13 * scale,
      left: 80 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 102 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 72 * scale,
      left: 120 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      height: 59 * scale,
      width: 52 * scale,
      top: 34 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 21 * scale,
      left: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      left: 105 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      height: 74 * scale,
      width: 110 * scale,
      top: 113 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 97 * scale,  
      left: 50 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 133 * scale,
      left: 119 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      height: 55 * scale,
      width: 52 * scale,
      bottom: 24 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 79 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 79 * scale,
      left: 103 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      height: 61 * scale,
      width: 49 * scale,
      bottom: 0 * scale,
      left: 84 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 61 * scale,
      left: 107 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 70 * scale,
      left: 118 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      height: 58 * scale,
      width: 133 * scale,
      bottom: 0 * scale,
      left: 134 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 62 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 78 * scale,
      left: 195 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      height: 61 * scale,
      width: 51 * scale,
      bottom: 0 * scale,
      right: 83 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 61 * scale,
      right: 103 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 72 * scale,
      right: 119 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      height: 57 * scale,
      width: 52 * scale,
      bottom: 23 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 80 * scale,
      right: 21 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      bottom: 79 * scale,
      right: 103 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      height: 74 * scale,
      width: 110 * scale,
      top: 113 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 97 * scale,  
      right: 50 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 133 * scale,
      right: 119 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      height: 60 * scale,
      width: 52 * scale,
      top: 36 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 23 * scale,
      right: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 80 * scale,
      right: 105 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      height: 55 * scale,
      width: 52 * scale,
      top: 13 * scale,
      right: 80 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      right: 102 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 72 * scale,
      right: 120 * scale,
    }
  },
];
