import React from "react";

import LoginForm from "src/auth/component/LoginForm";

const Login = ({setTab, isDarkMode}) => {

  const handleSigupClick = (e) => {
    e.preventDefault();
    setTab("register");
  };
  
  return (
    <div className={"pocket-auth__login--wrapper" + ((isDarkMode && " dark") || "")}>
      <LoginForm
        isDarkMode={isDarkMode}
        onClickSignup={handleSigupClick}
      />
    </div>
  );
};

export default Login;
