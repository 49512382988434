import React, {useCallback,useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ForumDropdown from 'src/core/component/dropdown/ForumDropdown'
import LoadingIcon from 'src/core/component/loader/LoadingIcon'
import ForumPostYourCard from './ForumPostYourCard'
import useAuthInfo from "src/auth/hook/auth-info";

const ForumYourPost = ({
  forumstate,
  handleFilterChange,
  handleAddMore,
  handlePostReplace,
  deleteDraft,
  handleCategoryChange,
  handlePageReset,
  postDraft,
  activeCategory,
  currentFilter,
  isAscending
}) => {
  const { isAuthenticated } = useAuthInfo();
  const observer = useRef(null)
  const history = useHistory();
  const filterOptions = [
    {id:0,name:'All Posts',value:'all'},
    {id:1,name:'Draft',value:'draft'},
    {id:2,name:'Posted',value:'posted'}]

  // TODO reload on forum post

  useEffect(()=>{
    handleCategoryChange(activeCategory?.id || '')
  },[activeCategory])

  useEffect(()=>{
    handlePageReset()
  },[currentFilter,isAscending])

  useEffect(()=>{
    if(isAuthenticated == false){
      history.push('/forum?tab=explore')
    }
  },[isAuthenticated])

  const lastPost = useCallback((node)=>{
    if(!node) return;
    if (forumstate.loading) return;
    if(observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries)=>{
      if(entries[0].isIntersecting){
        handleAddMore()
      }
    });
    observer.current.observe(node);
  })

  return (
    <div className='forum_post_your_post'>
        <div className='header'>
        <p>Your Posts</p>
       
        <ForumDropdown items={filterOptions}
                  selectedItem={forumstate.post_state}
                  itemClick={handleFilterChange}
                  />
        </div>
        <div className='body forum_post_questions'>
        {
          isAuthenticated && forumstate?.posts && forumstate.posts.length > 0 && forumstate.posts.map((post,index)=>{
            return <div key={index} className="question_body"
                ref={index === forumstate.posts.length -1 ? lastPost : null}
                >
                  <ForumPostYourCard 
                      item={post} 
                      postDraft={postDraft}
                      setPosts={handlePostReplace} 
                      isYourPost = {true} 
                      deleteDraft={deleteDraft}/>
                </div>
          })
        }
        {/* {forumstate?.metadata?.next && forumstate.metadata.next > 1 &&
          (
            <div className="col-12">
              <div className="load-more">
                <button
                  className="pocket-btn d-block forum mx-auto mt-3"
                  onClick={handleAddMore}
                >
                  View More Your
                </button>
              </div>
            </div>
          )} */}
          {forumstate.loading && (<div className="col-12"><LoadingIcon /></div>)}

        </div>
  </div>
  )
}

export default ForumYourPost
