import React from "react"
import { Link } from 'react-router-dom';

import { useTheme } from "src/theme/component/ThemeContext";
import Banner from "src/core/component/Banner";
import CenteredContainer from "src/core/component/grid/CenteredContainer";
import CenteredDiv from "src/core/component/CenteredDiv";


const CookieHome = () => {
    const [isDarkMode, toggleTheme] = useTheme();


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submitted');
    }

    const handleChange = (e) => {
        console.log('Changed...');
    }

  return (
        <section className={"pocket-gdpr" + (isDarkMode && ' dark' || '' )}>
            <Banner classes={isDarkMode && 'dark' ||  ''}>
                <CenteredDiv classes={'title'} text={'Cookie Information'} />
            </Banner>

            <div className={"pocket-gdpr__contents"  + (isDarkMode && ' dark' || '' )}>
                <CenteredContainer colClasses={'col-sm-12 col-xl-10 col-xxl-9'}>
                <div className="information mb-0 mb-md-4">
                    <div className="text">
                        <div className="cookiee-item mb-4">
                            <h4 className="heading">
                                1. What are cookies:  
                            </h4>
                            <ol style={{listStyleType: 'none'}}>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">a.</strong> Cookies are little text files that are used to save a small amount of data and information.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">b.</strong> They are uploaded by the website or service provider to your computer’s hard disk through your web browser, 
                                        allowing the website provider's hard disk through your web browser, 
                                        allowing the website provider's system to identify and recognize your browser and store certain 
                                        system to identify and recognize your browser and store certain system to identify and recognize your browser and store certain 
                                        information, such as login id, web pages visited, preferences, and other 
                                        information that is unique to the website. information that is unique to the website.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">c.</strong> With the stored information, website providers personalize their website 
                                    based on the pattern of use and gain statistical understanding of 
                                    how the based on the pattern of use and gain statistical understanding of how the user interacts with the website.  
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">d.</strong> They can also improve the service and adapt the website to your individual interests. 
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">e.</strong> The cookies gather the information in an anonymous manner without revealing the user’s idenitity.
                                </li>
                            </ol>
                        </div>

                        <div className="cookiee-item mb-4">
                            <h4 className="heading">
                                2. Types of Cookies:  
                            </h4>
                            <ol style={{listStyleType: 'none'}}>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">a.</strong> First Party Cookies are created and used exclusively on a single domain, they don’t share your information with any other websites.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">b.</strong> Third Party Cookies are generated on one domain and sent to all third-
                                    party domains that share the same tracking code. Their principal role is to 
                                    monitor all the user behavior and then display advertisements based on 
                                    their actions.   
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">c.</strong> Persistent Cookies are cookies that are stored on the user’s computer to 
                                    remember data, preferences, settings, and sign-on that the user has 
                                    saved even after closing the browser. This makes using the website more 
                                    convenient. However, the website assigns an expiration date to these 
                                    cookies. 
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">d.</strong> Session Cookies  are cookies that are deleted once the user closes the browser.
                                </li>
                            </ol>
                        </div>
                       
                        <div className="cookiee-item mb-4">
                            <h4 className="heading">
                                3. Use of Google Analytics:
                            </h4>
                            <ol style={{listStyleType: 'none'}}>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">a.</strong> <b>POCKET PANDIT&nbsp;</b> uses Google Analytics for better performance of its services to the users.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">b.</strong> Google Analytics is a web analytics service that tracks and reports website traffic.  
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">c.</strong> Google tracks and gathers data and information about how the user interacts with our website and how the service is being used.   
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">d.</strong> Google may use the information gathered to contextualize and personalize the advertisements on its own advertising network.
                                </li>   
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">e.</strong> The cookies gather data in an anonymous manner without revealing the visitor’s identity.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">f.</strong> The cookie is used to distinguish one visitor from another and enhance the website.
                                </li>
                            </ol>
                        </div>

                        <div className="cookiee-item mb-4">
                            <h4 className="heading">
                                4. How to control your cookies: 
                            </h4>
                            <ol style={{listStyleType: 'none'}}>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">a.</strong> You are under no obligation to accept any cookies from the website.
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">b.</strong> The website allows you to reject the cookies before you visit the website.  
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">c.</strong> The user can also configure their browser to notify and allow them to choose whether to accept such cookies or not. 
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">d.</strong> The cookies assist us in making the website function effectively, provide 
                                        better user experience, understanding how the website functions and 
                                        analyzing the works that need to be improved. 
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">e.</strong> It allows us to understand YOUR preferences and interests.  
                                </li>
                                <li className="d-flex align-items-start">
                                    <strong className="me-2">f.</strong> We do not suggest you turn off your cookies while using our website, as 
                                        the features of our website might not function properly.   
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                </CenteredContainer>
            </div>
        </section>
    )
}

export default CookieHome;
