import React from "react";
import { Link, useHistory } from "react-router-dom";


const PreviousNextButton = ({
    hasPrev=true,
    hasNext=true,
    hasLoading=false,
    isLoading=false,
    prevLink="#",
    nextLink="#",
    classes="",
    isDarkMode,
    onClickNext,
    onClickPrev,
    ...rest
}) => {

    // const history = useHistory();

    // onClickPrev = (e) => {
    //     e.preventDefault();
    //     history.goBack();
    // }

  
  return ( 
        <div className={classes}>
            {
                hasPrev &&
                <Link to={prevLink} className={"prev-link" + (isDarkMode && ' dark' || '') + (isLoading && ' disabled' || '')} onClick={onClickPrev}>
                    Back
                </Link>
            }
            {
                hasNext &&
                <Link to={nextLink} className={"next-link position-relative" + (isDarkMode && ' dark' || '') + (isLoading && ' disabled' || '')} onClick={onClickNext}>
                    Next

                    {hasLoading && isLoading && (
                        <span className="spinner-border spinner-border-sm position-absolute" role="status" aria-hidden="true"></span>
                    )}
                </Link>
            }
            
        </div>  
    )
}

PreviousNextButton.defaultProps = {
    onClickNext: () => null,
    onClickPrev: () => null
};

export default PreviousNextButton
