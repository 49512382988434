import React,{useRef,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode,Mousewheel } from "swiper";
import { v4 as uuid } from "uuid";
import "swiper/css";
import 'swiper/scss/grid';
import "swiper/css/navigation";

const SwiperCarousel = ({
  items=[],
  freeMode = true,
  modules = [FreeMode,Mousewheel],
  slidesPerView = 1.1,
  spaceBetween = 10,
  breakpoints = {},
  mouseWheel = false,
  swiperRef = useRef(null),
  ...rest
}) => {

  const settings = {
    freeMode: freeMode,
    modules: modules,
    slidesPerView: slidesPerView,
    spaceBetween: spaceBetween,
    mousewheel: mouseWheel,
    breakpoints: breakpoints,
    ref:swiperRef,
    ...rest
  };

  return (
    <Swiper {...settings}>
        {items.map((item) => (
          <SwiperSlide key={uuid()}>
            {item}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default SwiperCarousel;
