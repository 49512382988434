import { loadJSON } from 'src/lib/json-store';

// Inital state
const initialState = {
  mode: loadJSON('theme.mode') || 'light',
};

// Action types
const LIGHT = 'LIGHT';
const DARK = 'DARK';

// Actions
export const lightTheme = () => {
  return {
    type: LIGHT,
  };
}

export const darkTheme = () => {
  return {
    type: DARK
  };
}


// Reducer
export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case LIGHT: {
      return {
        ...state,
        'mode': 'light',
      };
    }

    case DARK: {
      return {
        ...state,
        'mode': 'dark',
      };
    }

    default:
      return state;
  }
}
