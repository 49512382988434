import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import api from "src/lib/api";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import H4Heading from "src/core/component/grid/H4Heading";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useAlert } from "src/core/hook/Alert";
import { useTheme } from "src/theme/component/ThemeContext";
import useAuthInfo from "src/auth/hook/auth-info";
import DismissableAlert from "src/core/component/DismissableAlert";
import MobilePrevNextButton from "src/core/component/MobilePrevNextButton";
import ConfirmationModalTwo from "src/core/component/modal/ConfirmationModalTwo";
import OrderSummary from "./OrderSummary";
import PaymentForm from "./PaymentForm";

import ExclamationSVG from "src/core/component/assets/svg/ExclamationSVG";

const Payment = () => {
  const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
  const {
    rtpOrder,
    paymentDisclaimer,
    paymentDisclaimerAccept,
    setOrderInfo,
    updateDraftCartLines,
  } = useRTPInfo();
  const [tax, setTax] = useState(null);
  const { isAuthenticated } = useAuthInfo();
  const [isDarkMode] = useTheme();
  const [isProcessing, setIsProcessing] = useState(false);
  const [deletingLineId, setDeletingLineId] = useState(null);
  const [hasUserAgreed, setHasUserAgreed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLineDelete = (line) => {
    if (isProcessing) {
      return;
    }

    setDeletingLineId(line.id);

    const newItems = (rtpOrder?.order_lines || [])
      .filter((l) => l.id != line.id)
      .map((l) => {
        return { profile_id: l.profile_id, question_id: l.question_id };
      });

    updateCart(newItems).then();
  };

  const updateCart = async (lineItems) => {
    setIsProcessing(true);
    try {
      const res = await api.post("v2/question-orders/cart", {
        line_items: lineItems,
      });

      const data = res.data?.result?.data || null;
      setOrderInfo(data);
      const cartLines = (data?.order_lines || []).map((item) => ({
        question_id: item.question_id,
        profile_id: item.profile_id,
      }));
      updateDraftCartLines(cartLines);
      setIsProcessing(false);
      setDeletingLineId(null);
    } catch (error) {
      PocketToast({
        type: "error",
        message: "Sorry! can't delete the item",
      });
      setIsProcessing(false);
    }
  };

  const handleCouponChange = (e) => {};

  const handleCouponSubmit = (e) => {};

  const handleProceed = () => {
    paymentDisclaimerAccept();
    setHasUserAgreed(!hasUserAgreed);
  };

  const handleClose = () => history.push("/");

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/instant-prediction",
          state: {
            from: history.location.pathname,
            search: history.location.search,
          },
        }}
      />
    );
  }

  return (
    <>
      <div className="payment-page">
        <H4Heading classes="rtp-heading mb-4" heading="Payment Method" />

        {showAlert && (
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <DismissableAlert
                text={"Sorry, we were not able to process your cart."}
                handleAlertClose={handleAlertClose}
                classes={"dismissable-alert alert-error"}
              ></DismissableAlert>
            </div>
          </div>
        )}

        <div className={"rtp-layout" + (isDarkMode ? " dark" : "")}>
          <div className="rtp-payment-content">
            <div className="row gx-2 gx-lg-3 gx-xxl-4">
              <PaymentForm
                setShowErrorAlert={setShowAlert}
                order={rtpOrder}
                orderLines={rtpOrder?.order_lines || []}
                isLoading={isProcessing}
                isDarkMode={isDarkMode}
              />

              <OrderSummary
                order={rtpOrder}
                orderLines={rtpOrder?.order_lines || []}
                handleLineDelete={handleLineDelete}
                tax={tax}
                handleCouponChange={handleCouponChange}
                handleCouponSubmit={handleCouponSubmit}
                isProcessing={isProcessing}
                deletingLineId={deletingLineId}
              />
            </div>
          </div>
        </div>

        <div className="back-btn mt-2 d-none d-sm-block d-mdlg-none">
          <Link
            to="/instant-prediction"
            className={"prev-link" + ((isProcessing && " disabled") || "")}
          >
            Back
          </Link>
        </div>

        <MobilePrevNextButton
          classes="d-sm-none"
          hasPrev={true}
          hasNext={false}
          prevLink="/instant-prediction"
          nextLink={"#"}
          hasLoading={true}
          isLoading={isProcessing}
        />
      </div>

      {rtpOrder?.amount > 0 && paymentDisclaimer && (
        <ConfirmationModalTwo
          className={"payment-confirmation-modal" + (isDarkMode ? " dark" : "")}
          show={!hasUserAgreed}
          size="xl"
          hasIcon={true}
          handleProceed={handleProceed}
          handleClose={handleClose}
          icon={<ExclamationSVG />}
          text={`By executing the Payment Order, you declare that you have read and give consent to the all the policies of PocketPandit including, but not limited to, the `}
          textLink={[
            <Link to="/tos" target="_blank" >Terms of Use</Link>,
            ", ",
            <Link to="/privacy-policy" target="_blank" >Privacy Policy</Link>,
            ", and ",
            <Link to="/payment-policy" target="_blank" >Payment and Refund Policy</Link>
          ]}
        />
      )}
    </>
  );
};

export default Payment;
