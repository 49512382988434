import React from 'react'
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";

const BirthProfileModal = ({
  children,
  handleModalShow,
  handleModalClose}) => {
  

  return (
    <div>
      <CommonModal showModal={handleModalShow} classes="forum_birth_profile_modal" backdrop="static"
        showCustomClose={true} size={"lg"} handleModalClose = {handleModalClose} >
          <ModalBody>
            {children}
          </ModalBody>
        </CommonModal>
    </div>
  )
}

export default BirthProfileModal