import React from "react"
import { Link } from 'react-router-dom';

import Dialog from "src/core/component/Dialog";
import DialogContent from "src/core/component/DialogContent";
import Button from "src/core/component/Button";

const CookieSimple = ({ accepted, handleAccept, isDarkMode, classes, ...rest }) => {

    return accepted ? (<></>) : (
            <Dialog accepted={accepted} handleAccept={handleAccept} classes={classes}>
                <DialogContent classes={'d-flex justify-content-between align-items-center'}>
                    <div className="info">
                        <span className="info-text">This website uses cookie to ensure you get the best experience.</span>
                        <Link to="/cookies" className="info-link">More Information</Link>
                    </div>

                    <Button name={'Got It'} classes={'cookie-accept-btn'} onClick={handleAccept} />
                </DialogContent>
            </Dialog>
        )
}

export default CookieSimple
