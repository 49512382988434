import api from 'src/lib/api';

export async function queryBadhakesh(params) {
    if(params){
        return api.get(`v1/badhakesh?${params}`);
    }else {
        return api.get(`v1/badhakesh`);
    }
}

export async function queryVimsopakaBala(params) {
    if(params){
        return api.get(`v1/vimsopaka?${params}`);
    }else {
        return api.get(`v1/vimsopaka`);
    }
}

export async function queryKundaliVimposakaBala(params) {
    if(params){
        return api.get(`v1/kundali-vimsopaka?${params}`);
    }else {
        return api.get(`v1/kundali-vimsopaka`);
    }
}

export async function queryKundaliWeights(params) {
    if(params){
        return api.get(`v1/kundali-weight?${params}`);
    }else {
        return api.get(`v1/kundali-weight`);
    }
}

export async function queryFunctionalBenificMalefic(params) {
    if(params){
        return api.get(`v1/ascendent?${params}`);
    }else {
        return api.get(`v1/ascendent`);
    }
}

export async function queryJaimini(params) {
    if(params){
        return api.get(`v1/planets/jaimini?${params}`);
    }else {
        return api.get(`v1/planets/jaimini`);
    }
}

export async function queryPlanetsFriendship(params) {
    if(params){
        return api.get(`v1/planets-friendship?${params}`);
    }else {
        return api.get(`v1/planets-friendship`);
    }
}

export async function queryDirectionalStrength(params) {
    if(params){
        return api.get(`v1/planets-directional?${params}`);
    }else {
        return api.get(`v1/planets-directional`);
    }
}

export async function queryResidentialStrength(params) {
    if(params){
        return api.get(`v1/residential-strength?${params}`);
    }else {
        return api.get(`v1/residential-strength`);
    }
}

export async function queryAspectStrength(params) {
    if(params){
        return api.get(`v1/planets-aspect?${params}`);
    }else {
        return api.get(`v1/planets-aspect`);
    }
}
