import React,{Fragment, useState,useEffect} from "react";
import { ChangeProfileIcon, SearchSvg } from "src/core/component/assets/svg/CommonSVG"

import LoadingIcon from "src/core/component/loader/LoadingIcon";
import ForumFilter from "./ForumFilter"

const FilterCategory = ({
  categories,
  categoryLoading,
  activeCategory,
  setActiveCategory,
  isDetails = false,
  isDarkMode,
  isAscending,
  setIsAscending,
  currentFilter,
  setCurrentFilter,
  ...rest
}) => {
  const [filterCategories,setFilterCategories] = useState([])
  const [seachCategory,setSearchCategory] = useState("")

  const handleSearchCategoryChange = (e)=>{
    const value = e?.target?.value || "";
      setSearchCategory(value)
      setFilterCategories(categories.filter(c=>c.name.toLowerCase().includes(value.toLowerCase())))
  }

  useEffect(()=>{
    if(categories?.length>0){
      handleSearchCategoryChange(seachCategory)
    }
  },[categories])

  return (
    <div className={"sidebar" + ((isDarkMode && " dark") || "")}>

    <ForumFilter 
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      currentFilter={currentFilter}
      setCurrentFilter={setCurrentFilter}
    />

      {/* <p className="separator d-block"></p> */}

      <div className={"categories" + ((isDarkMode && " dark") || "")}>
        {categoryLoading && <LoadingIcon />}
        {!categoryLoading && categories && categories.length > 0 && (
          <>
            <div className={"category-header" + ((isDarkMode && " dark") || "")}>
              <h4 className="title">Filter Categories</h4>
            </div>
            <div className="category-search">
              <input type="text" value={seachCategory} onChange={handleSearchCategoryChange} placeholder="Search Categories" /><span><SearchSvg/></span>
            </div>
            <div className={"category-items" + ((isDarkMode && " dark") || "")}>
              {categories.length > 0 &&
                [...filterCategories].map((item, index) => 
                    <Fragment key={index}>
                      <div className={"category-item" +
                          ((activeCategory && activeCategory.id === item.id && " active") ||"")}
                          onClick={() => setActiveCategory(item)}>
                        <span>
                          <ChangeProfileIcon/> <p>{item.name}</p></span> <span className="likes">
                          {/* {'10k'}+ Posts */}
                        </span>
                      </div>
                    </Fragment>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FilterCategory;
