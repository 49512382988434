import React from "react";
import { useHistory } from "react-router-dom";
import { ChangeProfileIcon, Like2Svg, LikeGraphSvg, Reply2SVG } from "src/core/component/assets/svg/CommonSVG";
import MomentRelativeTime from "src/core/component/moment-date-time/MomentRelativeTime";
import ShareDropdown from "src/core/component/dropdown/ShareDropdown"
import Moment from "react-moment";
import api from 'src/lib/api';
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useModal } from "src/core/hook/useModal";
import DeleteModal from "src/core/component/modal/DeleteModal";
import { useTheme } from "src/theme/component/ThemeContext";

const ForumPostYourCard = ({
  item,
  setPosts,
  isYourPost,
  postDraft=()=>{},
  deleteDraft=()=>{},
  replyClick=()=>{}
}) => {
  const [showDeleteModal, handleDeleteModalShow, handleDeleteModalClose] = useModal(false);
  const [isDarkMode] = useTheme();
  const history = useHistory();

  const handleReplyClick = (e) => {
    e.preventDefault();
    if(isYourPost){
      history.push(`/forum/${item.id}`);
    }else{
      replyClick();
    }
  };

  const handlePostLikeClick = async (id,target_like_action) => {
    const payload = {'type':"likes",'like':target_like_action }
    
    const result = await api.put(`v1/forum-posts/${id}/metrics`, payload);
    let updatedPost = result.data?.result?.data || null;
    updatedPost = {...updatedPost,is_like:payload.like}
    setPosts(updatedPost)
  };

  const handleDraftDelete = async (id,proceed) => {
    if(proceed){
      const result = await api.delete(`v1/forum-posts/${id}`);
      if(result){
        deleteDraft(result.data?.result?.data.id || null);
        PocketToast({
          type: "success",
          message: "Draft Deleted.",
        });
      }
      handleDeleteModalClose();
    }else{
      handleDeleteModalShow();
    }
  };

  const handleDraftPost = (post)=>{
    postDraft(post);
  }

  return (
      <>
            <div className="image">
              {(item?.created_by?.avatar_url && (
                <img src={item.created_by.avatar_url} />
              )) || <ChangeProfileIcon />}
            </div>
            <div className="question_content">
                <div className="question_content_header">
                    <span className="question_created_by">{item?.created_by?.display_name}</span>
                    {
                      item?.state == "draft" ?
                    <span className="question_category_draft">In Draft</span>:
                    <span style={{backgroundColor:'#99756d'}} className="question_category">{item?.category?.name}</span>
                    }
                </div>
                <div className="question_content_body">
                    <span className="question_title">{item?.title}</span>
                    <span className="question_time">  {item?.created_at && (
                        <MomentRelativeTime datetime={item.created_at} />
                        )}</span>
                </div>
                <div className="question_content_birth_details">
                      <div className="question_content_birth_details_title">Birth Details</div>
                      <div className="question_content_birth_details_body">
                        <span>
                          <div className="label">Date & Time</div>
                          <div className="value"><Moment format="MM/DD/YYYY HH:mm A">{item?.profile?.dob}</Moment></div>
                        </span>
                        <span>
                          <div className="label">Location of Birth</div>
                          <div className="value">{item?.profile?.address.split(',').slice(-2)}</div>
                        </span>
                      </div>
                </div>
                {item?.state == "posted" ?
                <div className="question_content_stats">
                  <div className="like" onClick={()=>(handlePostLikeClick(item.id,!item.is_like))}>
                      <Like2Svg fill={item.is_like == true?"#1D5DC2":"#585858"}/> {item?.metrics?.data?.likes || 0}
                    </div>
                    <div className="reply"  onClick={handleReplyClick}><Reply2SVG/> Reply</div>
                    <div className="views"><LikeGraphSvg/> {item?.views_count}</div>
                    <ShareDropdown post={item}/>
                </div>:
                <div className="question_content_draft">
                      <button className="draft" onClick={()=>{handleDraftDelete(item.id)}}>Discard Draft</button>
                      <button className="post" onClick={()=>{handleDraftPost(item)}} >Post Draft</button>
                </div>
                }
            </div>
              {
        showDeleteModal && (
          <DeleteModal
            show={true}
            className={`delete-modal ${isDarkMode?' dark':''}`}
            handleClose={() => {
              handleDeleteModalClose();
            }}
            handleCloseClass="btn-primary"
            handleProceed={() => handleDraftDelete(item.id, true)}
            handleProceedText={"Delete"}
            handleProceedClass="btn-danger"
          >
            <h5>Delete Draft</h5>
            <p>Are you sure you want to delete this draft?</p>
          </DeleteModal>
        )
      }
        </>

  );
};

export default ForumPostYourCard;
