export const loadJSON = (name) => {
  const data = localStorage.getItem(name);

  if (!data) { return null; }

  return JSON.parse(data);
}

export const saveJSON = (name, data) => {
  if (!data) {
    localStorage.removeItem(name);
    return;
  }

  localStorage.setItem(name, JSON.stringify(data));
}
