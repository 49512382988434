import React,{useEffect, useState} from 'react'
import {BackSvg,SearchSvg,PlusSvg } from 'src/core/component/assets/svg/CommonSVG'
import ForumDropdown from 'src/core/component/dropdown/ForumDropdown'
import useAuthInfo from "src/auth/hook/auth-info";
import api from "src/lib/api";
import LoadingIcon from 'src/core/component/loader/LoadingIcon'
import { useModal } from "src/core/hook/useModal";
import BirthProfileModal from './CreateBirthProfileModal'
import SetupBirthProfileModal from '../birthProfileSetup/SetupBirthProfileModal'


const ForumBirthProfile = ({handleBack,selectedBirthProfile,setSelectedBirthProfile}) => {
    const [selectedRelation,setSelectedRelation] = useState(null)
    const [relationships, setRelationships] = useState([]);
    const [searchBirthProfile,setSearchBirthProfile] = useState('')
    const { birthProfiles } = useAuthInfo();
    const [filteredBirthProfile,setFilteredBirthProfile] = useState([])
    const [selectedProfile,setSelectedProfile] = useState(selectedBirthProfile)
    const [showBirthProfileModal, handleBirthProfileModalShow, handleBirthProfileModalClose] = useModal(false);

    useEffect(()=>{
      fetchRelationships()
    },[])
    
    useEffect(()=>{
      let filteredbp = {};
      birthProfiles.forEach(bp=>{
        let category = bp.relationship.name;
        
        if(selectedRelation){
          if(selectedRelation.name == category){
            if(!filteredbp.hasOwnProperty(category)){filteredbp[category] = [];}
            filteredbp[category].push(bp)
          }
        }else{
          if(!filteredbp.hasOwnProperty(category)){filteredbp[category] = [];}
          if(searchBirthProfile.length>0){
            if(bp.name.toLowerCase().includes(searchBirthProfile.toLowerCase())){
              filteredbp[category].push(bp)
            }
          }else{
          filteredbp[category].push(bp)
        }
      }
      })
      setFilteredBirthProfile(filteredbp)
    },[selectedRelation,searchBirthProfile,birthProfiles])

    const handleSearchBirthProfileChange=(e)=>{setSearchBirthProfile(e.target.value)}

    const fetchRelationships = async () => {
        try {
          const result = await api.get(`v1/relationship`);
          const relationshipData = result?.data?.result?.data || [];
          setRelationships(relationshipData);
          // setSelectedRelation(relationshipData[0])
        } catch (error) {
          console.log("Error on fetching relationships");
        }
      };

    const handleBirthProfileSelect=(profile)=>{
      setSelectedProfile(profile)
    }
    const handleSaveClick=()=>{
      if(selectedProfile!=null){
        setSelectedBirthProfile(selectedProfile)
        handleBack()
    }
  }

  const handleAddBirthProfile = () => {handleBirthProfileModalShow()}
  const handleModalChose_AddProfile = (profile)=>{
    handleBirthProfileModalClose();
    setSelectedBirthProfile(profile);
    handleBack();

  }

  return (
    <div className='question_forum_select_profile'>
      <span onClick={handleBack} className='back'><BackSvg/> Back</span>
      <div className='header'>
        <div className='header_title'>
          <span className='title'>Select Birth Profile</span>
          <span className='sub_title'>Select who you want the prediction for</span>
        </div>
        <ForumDropdown items={relationships}
              selectedItem={selectedRelation}
              itemClick={setSelectedRelation}
              title="Relationship"
              value="Select"
              />
      </div>
      {selectedRelation == null &&
        <div className='search_birth_profile'>
          <input type="text" value={searchBirthProfile} placeholder="Search BirthProfile"
                onChange={(e)=>handleSearchBirthProfileChange(e)} />
          <SearchSvg />
        </div>
      }
      <div className='add_birth_profile' onClick={handleAddBirthProfile}>
          <div className='add_birth_profile_title'>
            <p>Add a birth profile</p>
            <span>Create a birth profile for a relationship that you want the answer for.</span>
          </div>
          <span className='add_birth_profile_action'>
            <PlusSvg fill="#1D5DC2" />
          </span>
      </div>
      {
        birthProfiles?
          <div className='select_birth_profile'>
                {
                  Object.keys(filteredBirthProfile).length>0 && Object.entries(filteredBirthProfile).map(fbp=>
                    <section key={fbp[1]+Math.random()}>
                    {fbp[1].length>0 &&
                    <>
                      <div className='select_birth_profile_relationship'>{fbp[0]} :</div>
                      {fbp[1].map(profile=>
                        <div key={profile?.id} className={`filter_birth_profile ${selectedProfile?.id == profile?.id?'active':''}`} 
                              onClick={()=>handleBirthProfileSelect(profile)}>
                          <div className='profile_image' style={{backgroundColor:profile?.relationship?.color || '#eee'}}>{profile?.relationship?.name.charAt(0)}</div>
                          <div className='info_profile'>
                            <div className='info_profile_name'>{profile?.name}</div>
                            <div className='info_profile_desc'>
                              <span>{new Date(profile?.dob).format('yyyy-MM-dd')}</span>
                              <span>{new Date(profile?.dob).format('hh:mm tt')}</span>
                              <span>{profile?.address.split(' ').slice(-2)}</span>
                            </div>
                          </div>
                        </div>
                      )}
                      </>
                      }
               </section>
             )}
          </div>:
          <div className='w-100'>
            {/* <LoadingIcon/> */}
          </div>
      }
      <div onClick={handleSaveClick} className="button float-end">Save</div>


      <BirthProfileModal 
      handleModalShow={showBirthProfileModal} 
      handleModalClose={handleBirthProfileModalClose}>
          <SetupBirthProfileModal 
              handleModalChose_AddProfile = {handleModalChose_AddProfile}
              relationship_id={selectedRelation?.id}
          />
      </BirthProfileModal>
    </div>
  )
}

export default ForumBirthProfile