import React, {useState, useEffect} from "react";
import { Route, Switch, useRouteMatch, useHistory, useLocation  } from "react-router-dom";

import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import BannerInput from "src/core/component/banner/BannerInput";
import AstrologerChat from "src/connect-with-astrologer/component/astologer-chat/AstrologerChat";
import useWindowSize from "src/core/hook/useWindowSize";
import AstrologerHomeMobile from "src/connect-with-astrologer/component/astologer-chat/AstrologerHomeMobile";


const AstrologerChatHome = () => {
    const history = useHistory();
    const location = useLocation();
    const [isDarkMode] = useTheme();
    const match = useRouteMatch();
    const windowSize = useWindowSize();

    useEffect(() => {
        if(location.pathname === match.path){
            if(windowSize && windowSize?.width > 575) {
                history.push(`${match.path}/chat`);
            }
        }
    }, [location, windowSize])


    return (
        <MainLayout>
            <section className={`pocket-astrologer__chat ${isDarkMode && 'dark' || ''}`}>
                <BannerInput 
                    isDarkMode={isDarkMode} 
                    hasSearch={false} 
                    hasFilter={false} 
                    title={'Astrologer Chat'}
                />
                
                <div className="container-xl overflow-hidden">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className={"pocket-astrologer__chat-contents" + (isDarkMode && ' dark' || '')}>
                                <Switch>
                                    <Route  
                                        exact
                                        path={`${match.path}`}  
                                        render={props => (
                                            <AstrologerHomeMobile
                                                {...props}
                                                isDarkMode={isDarkMode}
                                                match={match}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/chat`}  
                                        render={props => (
                                            <AstrologerChat
                                                {...props}
                                                isDarkMode={isDarkMode}
                                                match={match}
                                            />
                                        )}
                                    />

                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}

export default AstrologerChatHome;
