import React from "react";
import PropTypes from 'prop-types'

import RatingCard from "./RatingCard";
import Carousel from "src/core/component/slider/Carousel";
import NextArrow from "src/core/component/slider/arrow-customized/NextArrow";
import PreviousArrow from "src/core/component/slider/arrow-customized/PreviousArrow";


const RatingSlider = ({ 
    ratings, 
    showNav=false,
    isDarkMode, 
    ...rest 
}) => {

    const settings = {
        infinite: ratings.length > 1,
        autoplay: true,
        dots: false,
        arrows: showNav,
        variableWidth: true,
        centerMode: false,
        speed: 500,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow astro={false} isDarkMode={isDarkMode} />,
        prevArrow: <PreviousArrow astro={false} isDarkMode={isDarkMode} />,
        className: isDarkMode ? 'pocket-slider ratings__slider dark' : 'pocket-slider ratings__slider',
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: ratings.length > 1
              }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: ratings.length > 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: ratings.length > 1,
                }
            }
        ]
    }

  return ( 
        <div className="row">
            <div className="col-12">
                <Carousel
                        {...settings}

                        items={ratings.map((item, index) => (
                            <React.Fragment key={index}>
                                <RatingCard 
                                    item={item} 
                                    isDarkMode={isDarkMode} 
                                />
                            </React.Fragment>
                        ))}
                    />

            </div>

        </div>
    )
}

export default RatingSlider
