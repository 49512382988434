import React from "react";

import PocketSelection from "src/core/component/selection/PocketSelection";

const Gender = ({
  value,
  hasLabel = true,
  hasPlaceholder = true,
  label = "",
  classes = "",
  placeholder = "",
  required = false,
  onChange,
  disabled=false,
  ...res
}) => {
  onChange = typeof onChange === "function" ? onChange : function noop() {};

  const gender = [
    {
      name: "gender",
      display: "Male",
      value: "male",
    },
    {
      name: "gender",
      display: "Female",
      value: "female",
    },
  ];

  return (
    <>
      <PocketSelection
        options={[...gender]}
        currentValue={gender.filter((opt) => opt.value === value)[0] || ""}
        onChange={onChange}
        classes={classes}
        hasLabel={hasLabel}
        label={label}
        placeholder={placeholder || "Select Gender"}
        disabled={disabled}
      />
    </>
  );
};

export default Gender;
