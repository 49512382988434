import React, { useState, useEffect } from "react";
import {
  useHistory,
  Redirect,
  useLocation,
  Route,
  Switch,
  useRouteMatch,
  Link,
} from "react-router-dom";

import { useTheme } from "src/theme/component/ThemeContext";
import AuthBanner from "src/auth/component/banner/AuthBanner";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import LogoDark from "src/core/component/LogoDark";
import LogoLight from "src/core/component/LogoLight";
import useAuthInfo from "src/auth/hook/auth-info";
import ForgotPasswordPage from "./ForgotPasswordPage";
import CreateNewPassword from "./CreateNewPassword";
import AstrologerSignupPage from "./AstrologerSignupPage";


const Auth = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const [isDarkMode] = useTheme();
  const { isAuthenticated } = useAuthInfo();

  useEffect(() => {
    if (location.pathname === match.path) {
      history.push(`${match.path}/login`);
    }
  }, [location]);

  if (isAuthenticated) {
      if(location.state?.from || location.state?.search) {
        return <Redirect 
          to={{
              pathname: (location.state?.from && location.state.from) || "/",
              search: (location.state?.search && location.state.search) || ""
          }} 
        />
      } else {
        // if(profile && profile?.astrologer) {
        //   return <Redirect to="/astrologer" />
        // } else {
        //   return <Redirect to="/" />
        // }

        return <Redirect to="/" />;
      }
  }

  return (
    <main className={"pocket-main auth-main" + ((isDarkMode && " dark") || "")}>
      <section className={"pocket-auth" + ((isDarkMode && " dark") || "")}>
        <div className="auth-container">
          <div className="pocket-auth__logo-sm d-md-none">
            <Link to="/">{isDarkMode ? <LogoDark /> : <LogoLight />}</Link>
          </div>
          <div className="row gx-md-2 gx-lg-4 gx-xl-5">
            <div className="col-md-3 col-lg-4 d-none d-md-block">
              <AuthBanner isDarkMode={isDarkMode} />
            </div>
            <div className="col-sm-12 col-md-9 col-lg-8 col-xl-7">
              <Switch>
                <Route
                  exact
                  path={`${match.path}/login`}
                  render={(props) => (
                    <LoginPage
                      {...props}
                      isDarkMode={isDarkMode}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/signup`}
                  render={(props) => (
                    <SignupPage
                      {...props}
                      isDarkMode={isDarkMode}
                      root_url={match.path}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/astrologer-signup`}
                  render={(props) => (
                    <AstrologerSignupPage
                      {...props}
                      isDarkMode={isDarkMode}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/forgot-password`}
                  render={(props) => (
                    <ForgotPasswordPage {...props} isDarkMode={isDarkMode} />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/reset-password`}
                  render={(props) => (
                    <CreateNewPassword {...props} isDarkMode={isDarkMode} />
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Auth;
