import React from "react";
import { Link, useHistory } from "react-router-dom";


const NotAuthenticatedAlert = ({isDarkMode, title}) => {
    const history = useHistory();
    
    return (
        <div className={"forum-reply-unauthenticated" + (isDarkMode && ' dark' || '')}>
            <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0">{title}</p>
                <div className="login-register-btn">
                    <div className="d-flex align-items-center">
                        <Link 
                            to={{
                                pathname: "/forum/register-login",
                                search: "",
                                state: {
                                  from: history.location.pathname,
                                  search: history.location.search
                                }
                            }}
                            className="pocket-btn register"
                        >
                                Login / Register
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotAuthenticatedAlert
