import React from "react";
import { useLocation } from "react-router-dom";

import * as settingSvgs from 'src/core/component/assets/svg/ProfileSettingSVG';
import NavLink from 'src/core/component/nav/NavLink';
import { AngleDownSvg } from "src/core/component/assets/svg/CommonSVG";


const Sidebar = ({ path, isDarkMode, ...rest}) => {

    const location = useLocation();
    let orderPrefix = location.pathname.split('/')[2]

    let navItems = [
        {
          url: `${path}/birth-profile`,
          page: 'Birth Profile',
          icon: <settingSvgs.ProfileSvg />,
          isLink: true
        },
        {
            url: `${path}/preference`,
            page: 'Preferences',
            icon: <settingSvgs.PreferenceSVG />,
            isLink: true,
            isActive: (location.pathname === `${path}/preference` || orderPrefix === 'preference') ? true : false
        },
        {
            url: `${path}/orders`,
            page: 'Orders',
            hasSeparator: true,
            icon: <settingSvgs.OrderSvg />,
            isLink: true, 
            isActive: (location.pathname === `${path}/orders` || orderPrefix === 'orders' || orderPrefix === 'order' ) ? true : false 
        },
        {
            isSeparator: true,
        },
        {
            url: `${path}/accounts`,
            page: 'Account Information',
            hasSeparator: true,
            icon: <settingSvgs.AccountInfoSvg />,
            isLink: true
        },
        {
            isSeparator: true,
        },
        {
            url: `/faq`,
            page: 'FAQs',
            icon: <settingSvgs.FAQSvg />,
            isLink: true
        },
        {
            url: `/tos`,
            page: 'Terms Of Use',
            icon: <settingSvgs.TOSSvg />,
            isLink: true
        },
        {
            url: `/privacy-policy`,
            page: 'Privacy Policy',
            icon: <settingSvgs.PrivacySvg />,
            isLink: true
        }
    ];

    // console.log('NavItems', navItems)

    let classes = isDarkMode ? "profile-settings__sidebar-menu-item dark" : "profile-settings__sidebar-menu-item";

    return (
        <div className={"profile-settings__sidebar" + (isDarkMode && ' dark' || '')}>
            <ul className={"profile-settings__sidebar-menu list-unstyled mb-0" + (isDarkMode && ' dark' || '')}>
                {navItems.map((item, index) => {
                    return (
                            <React.Fragment key={index}>
                                {item.isLink && (
                                    <NavLink
                                        key={index}
                                        url={item.url}
                                        classes={classes}
                                        isActive={item.isActive}
                                    >
                                        {item.icon}
        
                                        {
                                            item.page
                                        }

                                        <span className="icon-right d-sm-none ms-auto">
                                            <AngleDownSvg />
                                        </span>    
                                    </NavLink>
                                )}

                                {item.isSeparator && (
                                    <p className="separator"></p>
                                )}
                            </React.Fragment>
                        );
                    })}
            </ul>
        </div>
    )
}

export default Sidebar;
