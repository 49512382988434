import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const AboutUs = () => {
  return (
    <section className="gbt-about-us">
      <Container fluid="xl">
        <div className="gbt-about-us__item">
          <h5>About Us</h5>
          <p>At PocketPandit Inc., we are on a mission to take astrology to the next level so as to be able to predict almost anything. We are passionate astrologers, passionate business people, passionate techies, passionate marketers, passionate sales people, passionate designers, passionate writers, with a - you guessed it - passionate cast of customer support. Together, we build various products and services that help our customers answer their questions about almost anything. </p>
          <p>To make predictions, we bring more than 100 years of collective Vedic astrological experience. We firmly believe in the principles of Vedic Astrology and have seen and experienced how accurate predictions can be when it is correctly understood, customized, and applied. To make Vedic Astrology a credible medium of prediction in the modern world, we've innovated on it quite a bit. And the results we've seen are fantastic. </p>
          <p className="gbt-about-us__positions-message mt-4">To learn more about us, email at <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a></p>
        </div>

        {/* <div className="gbt-about-us__item">
          <h5>Our Mission</h5>
          <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
        </div> */}

        {/* <div className="gbt-about-us__item">
          <h5>Our Vision</h5>
          <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
        </div> */}

        {/* <div className="gbt-about-us__item">
          <h5 className="mb-4">Our Team</h5>
          <Row className="w-100 gy-4" xs={1} sm={3} lg={4}>
            <Col>
              <div className="gbt-about-us__item-person">
                <figure className="figure mb-4">
                  <img src="http://thenewcode.com/assets/images/thumbnails/sarah-parmenter.jpeg" className="figure-img img-fluid rounded" alt="" />
                </figure>
                <div className="gbt-about-us__item-person__info px-3">
                  <h6>Jane Cooper</h6>
                  <strong>Sr. Backend Engineer</strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="gbt-about-us__item-person">
                <figure className="figure mb-4">
                  <img src="https://i.pinimg.com/originals/ae/ec/c2/aeecc22a67dac7987a80ac0724658493.jpg" className="figure-img img-fluid rounded" alt="" />
                </figure>
                <div className="gbt-about-us__item-person__info px-4">
                  <h6>Kristin Watson</h6>
                  <strong>Sr. Backend Engineer</strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="gbt-about-us__item-person">
                <figure className="figure mb-4">
                  <img src="https://pbs.twimg.com/profile_images/606196213369720832/lSvWN_q-_400x400.jpg" className="figure-img img-fluid rounded" alt="" />
                </figure>
                <div className="gbt-about-us__item-person__info px-4">
                  <h6>Cody Fisher</h6>
                  <strong>Sr. Backend Engineer</strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="gbt-about-us__item-person">
                <figure className="figure mb-4">
                  <img src="https://minimaltoolkit.com/images/randomdata/male/38.jpg" className="figure-img img-fluid rounded" alt="" />
                </figure>
                <div className="gbt-about-us__item-person__info px-4">
                  <h6>Savannah Nguyen</h6>
                  <strong>Sr. Backend Engineer</strong>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </section>
  )
}

export default AboutUs;
