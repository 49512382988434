import React, {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router-dom';
import queryString from "query-string";

import api from 'src/lib/api';
import ChatForm from 'src/connect-with-astrologer/component/ChatForm';
import ChatMessageList from 'src/connect-with-astrologer/component/ChatMessageList';
import { useModal } from "src/core/hook/useModal";
import BuyTimeModal from 'src/core/component/chat/BuyTimeModal';
import PaymentSetupModal from "src/core/component/chat/PaymentSetupModal";
import AddReviewModal from 'src/core/component/chat/AddReviewModal';
import EndChatConfirm from 'src/core/component/chat/EndChatConfirm';
import PaymentProcessingPopup from "src/core/component/payment/PaymentProcessingPoup";
import CountDownTimer from 'src/connect-with-astrologer/component/CountDownTimer';
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useSocket } from 'src/socket/component/SocketContext';
import useAuthInfo from 'src/auth/hook/auth-info';
import { EndChatSvg } from 'src/core/component/assets/svg/connectastrologer/ConnectAstrologer';
import useCustomerChat from "src/connect-with-astrologer/hook/useCustomerChat";


const CustomerChatContainer = ({astrologer, isDarkMode, ...rest}) => {
    const history = useHistory();
    const socket = useSocket();
    const authInfo = useAuthInfo();
    const {clearSelectedAstrologer} = useCustomerChat();
    const chatContainerScrollToRef = useRef(null);
    const activeConversationRef = useRef(null);
    const [showBuyTimeModal, handleBuyTimeModalShow, handleBuyTimeModalClose] = useModal(false);
    const [showReviewRatingModal, handleReviewRatingModalShow, handleReviewRatingModalClose] = useModal(false);
    const [showPaymentModal, handlePaymentModalShow, handlePaymentModalClose] = useModal(false);
    const [showEndChatModal, handleEndChatModalShow, handleEndChatModalClose] = useModal(false);
    const [showProcessingModal, handleProcessingModalShow, handleProcessingModalClose] = useModal(false);

    const [buyTimeTitle, setBuyTimeTitle] = useState(null);
    const [hours, setHours] = useState(1);
    const [invalidHours, setInvalidHours] = useState(false);
    const [activeConversation , setActiveConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [conversationExpired, setConversationExpired] = useState(false);

    // Payments
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(null);


    //Get Current Conversation from query string
    useEffect(() => {
        const query = queryString.parse(history.location.search);
        const conversationName = query?.c || '';
        if(conversationName) {
            fetchConversation(conversationName);
        } else {
            history.push('/connect-astrologer');
        }
    }, [history.location,hours])

    const fetchConversation = async (name) => {
        try {
            const result = await api.get(`/v1/conversations/${name}`);
            const conversation = result?.data?.result?.data || null;
            setActiveConversation(conversation);
        }
        catch (error) {
            setError(error?.message || 'Error on fetching conversation.');
            PocketToast({
                type: "error", 
                message: "Error on fetching conversation."
            })
        }
    }

    useEffect(() => {
        activeConversation && fetchConversationMessages();
        activeConversation && (activeConversationRef.current = activeConversation.id)
    }, [activeConversation])

    const fetchConversationMessages = async () => {
        try {
            const result = await api.get(`v1/conversations/${activeConversation?.name}/messages`);
            const messageData = result?.data?.result?.data || [];
            setMessages(messageData);
        }
        catch (error) {
            setError(error?.message || 'Error on fetching conversation messages.');
            PocketToast({
                type: "error", 
                message: "Error on fetching conversation messages."
            })
        }
    }

    useEffect(() => {
        if(messages.length) {
            const last = messages.at(-1);
            // console.log('Customer Last Message', last);
            socket.emit('chat_message:customer_last_message',  JSON.stringify({'data': last}))
        }
    }, [messages])

    useEffect(() => {
        const onConversationRenew = async ({ data }) => {
            try {
                const res = await api.get('v1/conversations/' + data.conversation_id);
                const renewedConversation = res.data.result?.data || null;
        
                if (activeConversation?.id === data.conversation_id) {
                    setActiveConversation(renewedConversation);
                }
            } catch (error) {
                PocketToast({
                    type: "error", 
                    message: "Error on fetching conversation."
                })
            }
        }

        const onConversationRemove = ({ data: conversation_id }) => {
            if(activeConversation.id == conversation_id) {
                PocketToast({
                    type: "error", 
                    message: `Your conversation has been removed.`
                })
        
                setActiveConversation(null);
                // Remove selected astrologer from Redux store.
                clearSelectedAstrologer();
                history.push('/connect-astrologer');
            }
        }
    
        socket.on('chat_conversation:renew', onConversationRenew);
        socket.on('chat_conversation:remove', onConversationRemove);
    
        return () => {
          socket.off('chat_conversation:renew', onConversationRenew);
          socket.off('chat_conversation:remove', onConversationRemove);
        }
    
    }, [activeConversation]);

    useEffect(() => {
        const handleNewMessage = ({ data: message, metadata }) => {
            onNewMessage(message, +metadata?.local_id);
        }
    
        socket.on('chat_message:create', handleNewMessage); // listen for msge from other users from same conversation
    
        return () => {
          socket.off('chat_message:create', handleNewMessage);
        }
    
    }, []);

    const onNewMessage = (message, localId) => {
        if(message?.conversation_id){
            if (message?.conversation_id != activeConversationRef.current) { return; }
    
        setMessages(state => {
          const msgIndex = state.findIndex(s => s.id == message.id || s.id == localId);
          if (msgIndex === -1) {
            return [...state, message];
          } else {
            return [
              ...state.slice(0, msgIndex),
              message,
              ...state.slice(msgIndex + 1),
            ];
          }
        });
    }
    }

    const handleErrorSend = (error, localId) => {
        setMessages(state => {
          const msgIndex = state.findIndex(s => s.id == localId);
    
          if (msgIndex === -1) { return state; }
    
          return [
            ...state.slice(0, msgIndex),
            Object.assign(state[msgIndex], { error: error }),
            ...state.slice(msgIndex + 1),
          ];
        });
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages])

    const scrollToBottom = () => {
        if (!chatContainerScrollToRef.current) { return; }

        // scroll to the element position
        chatContainerScrollToRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        if(conversationExpired) {
            setBuyTimeTitle('Your conversation has expired.');
            handleBuyTimeModalShow();
        }
    }, [conversationExpired])

    const handleAddMoreTime = (e) => {
        e.preventDefault();
        setBuyTimeTitle(`${activeConversation?.expired && 'Renew your conversation' || 'Add more time'}`);
        handleBuyTimeModalShow();
    }

    const handleEndChat = (e) => {
        e.preventDefault();
        handleBuyTimeModalClose();
        handleEndChatModalClose();
        handleReviewRatingModalShow();
    }

    const renewFreeConversation = async () => {
        try {
            const res = await api.post(`v1/conversations/${activeConversation.id}/buy-hours`, {
                'hours': hours,
            });

            const renewedConversation = res.data.result.data;
            setHours(1);

            PocketToast({
                type: "success", 
                message: "Congratulations! you have successfully renewed your conversation."
            });

        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Sorry, server can't process your request."
            })
        }
    }

    const handlePayAndContine = (e) => {
        e.preventDefault();
        if(hours <= 0 || parseInt(hours) <= 0) {
            setInvalidHours(true)
            return;
        }

        if(!astrologer) {
            return;
        }

        handleBuyTimeModalClose();

        // if chat rate is 0 donot prompt for payment credentials
        if(!astrologer.chat_rate_per_hour || astrologer.chat_rate_per_hour <= 0) {
            renewFreeConversation();
        } else {
            handlePaymentModalShow();
        }
    }

    const requestClientSecret = async () => {
        try {
            const result = await api.post(`v1/conversations/${activeConversation.id}/buy-hours`, {
                'hours': hours
            });
            // console.log('requestClientSecret',result)
            return result;
        }
        catch (error) {
            return {
                error: `${error?.response && error.response.data.error.message || "Sorry, server can't process your request"}`
            }
        }
    }

    useEffect(() => {
        if(paymentProcessing) {
            // handlePaymentModalClose();
            handleProcessingModalShow();
        }
    }, [paymentProcessing])

    useEffect(() => {
        if(paymentError) {
            handlePaymentModalClose();
            handleProcessingModalClose();
        }
    }, [paymentError])
    
    const handlPaymentSuccess = (data) => {
        setPaymentProcessing(false);
        setPaymentSuccess(true);
        setHours(prev=>prev+data?.amount); // update timer and conversation.

        setTimeout(() => {
            handleProcessingModalClose();
            handlePaymentModalClose();
            setPaymentSuccess(false);
        }, 2000);
    }

    const handleResumeChat = (e) => {
        e.preventDefault();
        handleReviewRatingModalClose();
        if(conversationExpired) {
            setBuyTimeTitle(`Renew your conversation`);
            handleBuyTimeModalShow();
        }
    }

    const handleEndChatClick = (e) => {
        e.preventDefault();
        handleEndChatModalShow();
    }

    // console.log('Conversation', activeConversation);
    // console.log('Messages', messages);
    // console.log('Conversation Expired state...', conversationExpired);
    // console.log('Conversation expired', activeConversation?.expired)

    return (
       <div className={`pocket-astrologer__chat-playground ${isDarkMode && 'dark' || ''}`}>
            {activeConversation && (
                <>
                    <div className={`pocket-astrologer__chat-playground-header customer ${isDarkMode && 'dark' || ''}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            {Date.now() < new Date(activeConversation.date_end).getTime() && (
                                <CountDownTimer
                                    datetime={activeConversation.date_end}
                                    setConversationExpired={setConversationExpired}
                                    isDarkMode={isDarkMode}
                                    title={'Time Left'}
                                    className={'customer'}
                                /> 
                            ) || (<div className="timer-countdown"><span className="timer expired">Expired</span></div>)}
                
                            <div className="controller d-flex align-items-center">
                                <div className="add-time" onClick={handleAddMoreTime}>
                                    {(activeConversation.expired || conversationExpired) && 'Renew Conversation' || 'Add more time'}
                                </div> 

                                {!(activeConversation.expired || conversationExpired) && (
                                    <div className="end-chat" onClick={handleEndChatClick}>
                                        <EndChatSvg />
                                    </div>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className={`pocket-astrologer__chat-playground-content ${isDarkMode && 'dark' || ''}`}>
                        <div className={`pocket-astrologer__chat-conversations ${isDarkMode && 'dark' || ''}`}>
                            <ChatMessageList 
                                isDarkMode={isDarkMode}
                                astrologer={astrologer}
                                conversation={activeConversation}
                                messages={messages}
                            />

                            <div ref={chatContainerScrollToRef} />
                        </div>
                        <div className={`pocket-astrologer__chat-form ${isDarkMode && 'dark' || ''}`}>
                            <ChatForm
                                isDarkMode={isDarkMode}
                                conversation={activeConversation}
                                onSent={onNewMessage}
                                onError={handleErrorSend}
                                expired={conversationExpired}
                            />
                        </div>
                    </div>

                    {/* Buy time modal */}
                    {showBuyTimeModal && (
                        <BuyTimeModal
                            hours={hours}
                            setHours={setHours}
                            invalidHours={invalidHours}
                            isDarkMode={isDarkMode}
                            showModal={showBuyTimeModal}
                            handleModalClose={handleBuyTimeModalClose}
                            handlePayAndContine={handlePayAndContine}
                            handleEndChat={handleEndChat}
                            title={buyTimeTitle}
                            conversation={activeConversation}
                            expired={conversationExpired}
                        />
                    )}

                    {/* Payment Modal */}
                    {showPaymentModal && astrologer && (
                        <PaymentSetupModal
                            isDarkMode={isDarkMode}
                            showModal={showPaymentModal}
                            handleModalClose={handlePaymentModalClose}
                            astrologer={astrologer}
                            hours={hours}
                            amount={astrologer?.chat_rate_per_hour * hours || 0}
                            requestClientSecret={requestClientSecret}
                            handlPaymentSuccess={handlPaymentSuccess}
                            processing={paymentProcessing}
                            setProcessing={setPaymentProcessing}
                            error={paymentError}
                            setError={setPaymentError}
                            buttonLabel={'Confirm Payment'}
                            expired={conversationExpired}
                            showProcessingModal={showProcessingModal}
                        />
                    )}

                    {/* Rating Modal */}
                    {showReviewRatingModal && astrologer && (
                        <AddReviewModal
                            astrologer={astrologer}
                            isDarkMode={isDarkMode}
                            showModal={showReviewRatingModal}
                            handleModalClose={handleReviewRatingModalClose}
                            handleResumeChat={handleResumeChat}
                            conversation={activeConversation}
                            expired={conversationExpired}
                        />
                    )}

                    {/* End Chat modal */}
                    {showEndChatModal && (
                        <EndChatConfirm
                            isDarkMode={isDarkMode}
                            showModal={showEndChatModal}
                            handleModalClose={handleEndChatModalClose}
                            handleEndChat={handleEndChat}
                            conversation={activeConversation}
                        />
                    )}

                    {/* Payment Processing  */}
                    {showProcessingModal && (
                        <PaymentProcessingPopup 
                            showModal={showProcessingModal}
                            handleModalClose={handleProcessingModalClose}
                            success={paymentSuccess}
                            processing={paymentProcessing}
                            showCloseIcon={false}
                            isDarkMode={isDarkMode} 
                            size={"md"}
                        />
                    )}
                </>
            )}

       </div>
    )
}

export default CustomerChatContainer