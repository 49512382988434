export const kundaliLines1 = (w, h) => ([
  // inside // wont work if not *1, 
  {move: [(w*1+(w/10))/3, h/9], line1: [(w*2-(w/10))/3, h/9],line2:[(w*2-(w/10))/3,(h*1+(h/9))/3],line3:[(w*1+(w/10))/3,(h*1+(h/9))/3]},
  {move: [w/10, h/9], line1: [(w*1+(w/10))/3, h/9],line2:[(w*1+(w/10))/3, (h*1+(h/9))/3],line3:[]},
  {move: [w/10, h/9], line1: [(w*1+(w/10))/3, (h*1+(h/9))/3],line2:[w/10, (h*1+(h/9))/3],line3:[]},
  {move: [w/10, (h*1+(h/9))/3], line1: [(w*1+(w/10))/3, (h*1+(h/9))/3],line2:[(w*1+(w/10))/3, (h*2-(h/9))/3],line3:[w/10, (h*2-(h/9))/3]},
  {move: [w/10, (h*2-(h/9))/3], line1: [(w*1+(w/10))/3, (h*2-(h/9))/3],line2:[w/10, h*1-(h/9)],line3:[]},
  {move: [w/10, h*1-(h/9)], line1: [(w*1+(w/10))/3, (h*2-(h/9))/3],line2:[(w*1+(w/10))/3, h*1-(h/9)],line3:[]},
  {move: [(w*1+(w/10))/3, (h*2-(h/9))/3], line1: [(w*2-(w/10))/3, (h*2-(h/9))/3],line2:[(w*2-(w/10))/3, h*1-(h/9)],line3:[(w*1+(w/10))/3, h*1-(h/9)]},
  {move: [(w*2-(w/10))/3, (h*2-(h/9))/3], line1: [w-w/10, h-(h/9)],line2:[(w*2-(w/10))/3, h*1-(h/9)],line3:[]},
  {move: [(w*2-(w/10))/3, (h*2-(h/9))/3], line1: [w-(w/10), (h*2-(h/9))/3],line2:[w-w/10, h-(h/9)],line3:[]},
  {move: [(w*2-(w/10))/3, (h*2-(h/9))/3], line1: [w-(w/10), (h*2-(h/9))/3],line2:[w-(w/10), (h*1+(h/9))/3],line3:[(w*2-(w/10))/3, (h*1+(h/9))/3]},
  {move: [(w*2-(w/10))/3, (h*1+(h/9))/3], line1: [w-(w/10), (h*1+(h/9))/3],line2:[w-(w/10), h/9],line3:[]},
  {move: [(w*2-(w/10))/3, (h*1+(h/9))/3], line1: [w-(w/10), h/9],line2:[(w*2-(w/10))/3, h/9],line3:[]},
  
  
  // level 1
      {move: [w/3, 0], line1: [w/3*2, 0],line2:[w/3*2, h/9],line3:[w/3, h/9]},
      {move: [0, 0], line1: [w/3, 0],line2:[w/3, h/9],line3:[w/10, h/9]},
      {move: [0,0], line1: [w/10, h/9],line2:[w/10, h/3],line3:[0, h/3]},
      {move: [0, h/3*2], line1: [0, h/3],line2:[w/10, h/3],line3:[w/10, h/3*2]},
      {move: [w/10, h-h/9], line1: [0, h],line2:[0, h/3*2],line3:[w/10, h/3*2]},
      {move: [w/3,h-h/9], line1: [w/3,h],line2:[0, h],line3:[w/10, h-h/9]},
      {move: [w/3*2, h-h/9], line1: [w/3*2, h],line2:[w/3,h],line3:[w/3,h-h/9]},
      {move: [w-w/10, h-h/9], line1: [w, h],line2:[w/3*2, h],line3:[w/3*2, h-h/9]},
      {move: [w-w/10, h/3*2], line1: [w, h/3*2],line2:[w, h],line3:[w-w/10, h-h/9]},
      {move: [w-w/10, h/3], line1: [w, h/3],line2:[w, h/3*2],line3:[w-w/10, h/3*2]},
      {move: [w, 0], line1: [w, h/3],line2:[w-w/10, h/3],line3:[w-w/10, h/9]},
      {move: [w/3*2, 0], line1: [w, 0],line2:[w-w/10, h/9],line3:[w/3*2, h/9]},
]);

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
};
export const transitTextStyle = {
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // color:'yellow',
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(5 * scale, 6),
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(6 * scale, 10),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 60 * scale,
      width: 112 * scale,
      top: 44 * scale,  
      left: 143 * scale,
    },
    sthaana: {  
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top:  33 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 92 * scale,
      left: 195 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 44 * scale,
      left: 98 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 33 * scale,
      left: 120 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 85 * scale,
      left: 134 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 59 * scale,
      width: 52 * scale,
      top: 58 * scale,
      left: 34 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 46 * scale,
      left: 44 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 92 * scale,
      left: 122 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 74 * scale,
      width: 110 * scale,
      top: 118 * scale,
      left: 41 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 108 * scale,  
      left: 90 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 133 * scale,
      left: 134 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 189 * scale,
      left: 34 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 93 * scale,
      left: 53 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 93 * scale,
      left: 122 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 61 * scale,
      width: 49 * scale,
      top: 205 * scale,
      left: 102 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 79 * scale,
      left: 127 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 87 * scale,
      left: 134 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 58 * scale,
      width: 133 * scale,
      top: 200 * scale,
      left: 134 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 84 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 93 * scale,
      left: 195 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 61 * scale,
      width: 51 * scale,
      top: 204 * scale,
      right: 104 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 79 * scale,
      right: 130 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 87 * scale,
      right: 136 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 57 * scale,
      width: 52 * scale,
      top: 189 * scale,
      right: 36 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      bottom: 94 * scale,
      right: 56 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 93 * scale,
      right: 124 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 74 * scale,
      width: 110 * scale,
      top: 117 * scale,
      right: 38 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 106 * scale,  
      right: 90 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 133 * scale,
      right: 135 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 60 * scale,
      width: 52 * scale,
      top: 56 * scale,
      right: 33 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 45 * scale,
      right: 48 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 93 * scale,
      right: 126 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 55 * scale,
      width: 52 * scale,
      top: 44 * scale,
      right: 103 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 34 * scale,
      right: 126 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 85 * scale,
      right: 135 * scale,
    }
  },
];
export const gharaStyles2 = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 135 * scale,
    }
  },  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 46 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 37 * scale,
    }
  },  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 25 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 22 * scale,
      left: 2 * scale,
    }
  },  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      left: 2 * scale,
    }
  },  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 203 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 190 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      left: 2 * scale,
    }
  },  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 47 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 37 * scale,
    }
  },  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 135 * scale,
    }
  },  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 265 * scale,
    }
  },  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 200 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 189 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      right: 2 * scale,
    }
  },  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      right:2 * scale,
    }
  },  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 21 * scale,
      right: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 20 * scale,
      right: 2 * scale,
    }
  },  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 265 * scale,
    }
  },
];
