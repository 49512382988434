import React, {useState} from "react";
import {StarRatingSvg} from "../../../core/component/assets/svg/CommonSVG";

const StarRating = ({style= {}, className = ""}) => {
  const [rating, setRating] = useState(4);
  const [hover, setHover] = useState(0);

  return (
    <div className={"gbt-shop__filter-price__rating " + className} style={style}>
      <h5>Rating</h5>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= (hover || rating) ? "on" : "off"}
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="star">
              <StarRatingSvg fill="#F5C037" />
              <StarRatingSvg fill="#FFFFFF" />
            </span>
          </button>
        );
      })}
    </div>
  )
}

export default StarRating;
