import React from "react";
import TimeGroup from "src/core/component/TimeGroup";

import ReplyItem from "./ReplyItem";


const SupportReplies = ({replies=[], isDarkMode, userInfo, ...rest}) => {


    return (
        <div className={`pocket-customer-support__support-item-replies ${isDarkMode && 'dark' || ''}`}>
            {replies.map((reply, i) => {
                const prevItem = replies[i - 1];

                const timeGrouped = !prevItem || new Date(prevItem.created_at).format('yyyy-MM-dd:HH') != new Date(reply.created_at).format('yyyy-MM-dd:HH')

                let timeGroupHeader;

                if (new Date(reply.created_at).format('yyyy') != new Date().format('yyyy')) {
                    timeGroupHeader = new Date(reply.created_at).format('MMM dd yyyy, h tt');
                }
                else if (new Date(reply.created_at).format('yyyy-MM-dd') != new Date().format('yyyy-MM-dd')) {
                    timeGroupHeader = new Date(reply.created_at).format('MMM dd, h tt');
                } else {
                    timeGroupHeader = new Date(reply.created_at).format('h tt');
                }

                return (
                    <React.Fragment key={reply.id}>
                        {timeGrouped && (
                            <TimeGroup
                                index={i}
                                group={timeGroupHeader}
                                isDarkMode={isDarkMode}
                            />
                        )}

                        <ReplyItem
                            item={reply}
                            userInfo={userInfo}
                            isDarkMode={isDarkMode}
                        />
                    </React.Fragment>
                )
            })
            }
        </div>
    )
}

export default SupportReplies;
