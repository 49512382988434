import React, { useState } from "react";

import TOS from "src/gdpr/component/tos/TOS";
import MainLayout from 'src/core/component/MainLayout';


export default function TosPage() {

  return (
    <MainLayout>
      <TOS />
    </MainLayout>
  )
}
