const dataURItoBlob = (dataURI, filename) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = window.atob(dataURI.split(",")[1]);
    else byteString = window.unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    let extension = mimeString.split("/").pop();
    let filenameExt = filename + "." + extension;

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
    }

    return [new Blob([ia], { type: mimeString }), filenameExt];
};

export default dataURItoBlob;
