import React from "react";
import { Link } from "react-router-dom";
import { EditSvg, DeleteIconSvg } from "src/core/component/assets/svg/CommonSVG";
import ProfileCardBody from "src/core/component/card/ProfileCardBody";
import { getFormattedDateTime } from "src/util/extra";


const ProfileCard = ({
    item, 
    relationData, 
    handleProfileClick, 
    onClickEdit,
    onClickDelete,
    isDarkMode, 
    profileIsSelected=false, 
    path="",
    search="",
    canDelete=false,
    canEdit=true,
    handleDelete,
    isRtp=false,
    isSelf=true,
    ...rest
}) => {

    handleProfileClick = handleProfileClick || function noop() { }
    handleDelete = handleDelete || function noop() { }
    onClickEdit = onClickEdit || function noop() { }
    
    // profileIsSelected = profileIsSelected || item.is_primary ? true : false

    const handleProfileSelect = (e) => {
        handleProfileClick(e, item);
    }

    const handleClickEdit = (e) => {
        onClickEdit(e, item);
    }

    const profileFields = [
        {
            name: "name",
            displayText: "Name",
            getValue: (item) => item?.name
        },
        {
            name: "relation",
            displayText: "Relation",
            discardOnCardBody: (isRtp || !isSelf) ? false : true,
            getValue: (item) => item?.relationship?.name
        },
        {
            name: "dob",
            displayText: "Date of Birth",
            getValue: (item) => getFormattedDateTime(item?.dob, "MMM dd, yyyy")
        },
        {
            name: "address",
            displayText: "Location of Birth",
            getValue: (item) => item?.address
        },
        {
            name: "time",
            displayText: "Time of Birth",
            getValue: (item) => getFormattedDateTime(item?.dob, "hh:mm tt")
        }
    ]

    let cardClass = isDarkMode ? 'card dark': 'card';

    return (
        <div className={profileIsSelected ? cardClass + " selected" : cardClass} onClick={handleProfileSelect}>
            <div className="card-body">
                {(isSelf || canDelete) && (
                    <div className="card-title d-flex justify-content-between">
                        {isSelf && (
                            <span>
                                {relationData?.is_self ? 'Your Profile' : `Your ${relationData?.name}'s Profile`}
                            </span>
                        )}
                        
                        {/* {canDelete && (
                            <span className="delete-icon ms-auto" onClick={(e) => handleDelete(e, item.id)}>
                                <DeleteIconSvg />
                            </span>
                        )} */}
                    </div>
                )}
            
                <ProfileCardBody 
                    data={item} 
                    fields={profileFields}
                />
            
                {isRtp && (
                    <>
                        {canEdit && (
                            <Link 
                                to={{
                                    pathname: `${path}`,
                                    search: `${search}`,
                                    state: {
                                        relationship_id: relationData?.id,
                                        name: relationData?.name,
                                        mode: 'edit',
                                        profile_id: item?.id
                                    }
                                }} 
                                className="edit-profile">
                                    Edit Profile
                                    <EditSvg />
                            </Link>
                        )}
                    </>
                )}

                {!isRtp && (
                    <>
                        {canEdit && (
                            <Link to="#" className="edit-profile" onClick={handleClickEdit}>
                                Edit Profile
                                <EditSvg />
                            </Link>
                        )}
                    </>
                )}

            </div>
        </div>
    )
}

export default ProfileCard