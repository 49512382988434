import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { saveJSON } from "src/lib/json-store";

import authReducer from "src/auth/redux";
import themeReducer from "src/theme/redux";
import rtpReducer from "src/realtimeprediction/redux";
import cookieReducer from "src/gdpr/component/cookie/redux/redux";
import customerChatReducer from "src/connect-with-astrologer/redux";
import freeAstroReducer from "src/freeastrology/redux";
import promoNotificationReducer from "src/promos/redux";
import userNotificationReducer from "src/notification/redux";

const store = createStore(
  combineReducers({
    auth: authReducer,
    theme: themeReducer,
    rtp: rtpReducer,
    cookie: cookieReducer,
    chat: customerChatReducer,
    freeAstro: freeAstroReducer,
    promo: promoNotificationReducer,
    notification: userNotificationReducer,
  }),
  applyMiddleware(thunk)
);

store.subscribe(() => {
  const state = store.getState();

  saveJSON("auth.accessToken", state.auth.accessToken);
  saveJSON("auth.refreshToken", state.auth.refreshToken);

  saveJSON("auth.user", state.auth.profile);
  //NOTE: this has to be here, otherwise redux never going to know the state change and redux can't udpate the state.
  saveJSON('auth.user.birthProfiles', state.auth.birthProfiles);
  saveJSON("auth.user.preference", state.auth.preference);
  saveJSON("auth.user.notification_preference", state.auth.notification_preference);
  saveJSON("theme.mode", state.theme.mode);

  // RTP
  saveJSON("rtp.birthProfile", state.rtp.birthProfile);
  saveJSON("rtp.categoryInfo", state.rtp.categoryInfo);
  saveJSON("rtp.orderInfo", state.rtp.orderInfo);
  saveJSON("rtp.datetime", state.rtp.datetime);
  saveJSON("rtp.paymentDisclaimer", state.rtp.paymentDisclaimer);
  saveJSON("rtp.draftCartLines", state.rtp.draftCartLines);

  // Cookie
  saveJSON("cookie.accepted", state.cookie.accepted);

  // Customer Chat
  saveJSON("chat.astrologer", state.chat.astrologer);

  // Free Astrology
  saveJSON("free-astro.birthProfile", state.freeAstro.birthProfile);
  saveJSON("free-astro.datetime", state.freeAstro.datetime);

  // Promo
  saveJSON("promo.lastChecked", state.promo.lastChecked);
  saveJSON("promo.promoNotificationData", state.promo.promoNotificationData);

  // Notifications
  saveJSON("notification.data", state.notification.userNotifications);
});

export default store;
