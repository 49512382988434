import React, {useState} from "react";

import {NextSvg, PrevSvg} from "src/core/component/assets/svg/pagination/PaginationSvg";

const ProductSliderItem = ({item}) => {
  const [itemCount, setItemCount] = useState(0);

  const handleIncrementItemQty = () => {
    setItemCount(itemCount + 1);
  }
  const handleDecrementItemQty = () => {
    if (itemCount < 1) return;
    setItemCount(itemCount - 1);
  }

  return (
    <div className="card-item">
      <figure className="figure">
        <img className="figure-img img-fluid" src={item.imageUrl} alt={item.title}/>
      </figure>
      <div className="card-item__info">
        <h6>{item.title}</h6>
        <p>{item.description}</p>

        <div className="card-item__info-extras">
          <div className="price">
            <span>Price</span>
            {
              item.oldPrice ? (
                <strong>
                  <del>{item.oldPrice}</del>
                  ${item.finalPrice}
                </strong>
              ) : (
                <strong>
                  ${item.finalPrice}
                </strong>
              )
            }
          </div>

          <div className="add-to-cart">
            {
              itemCount === 0 && (
                <button className="btn btn-cart" onClick={handleIncrementItemQty}>Add To Cart</button>
              ) || (
                <div className="count-item">
                  <button className="count-item__btn" onClick={handleDecrementItemQty}>
                    <PrevSvg/>
                  </button>
                  <span>{itemCount}</span>
                  <button className="count-item__btn" onClick={handleIncrementItemQty}>
                    <NextSvg/>
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSliderItem;
