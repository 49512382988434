import React from "react";
import { Link } from "react-router-dom";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";


const EndChatConfirm = ({
    isDarkMode, 
    showModal,
    handleModalClose,
    handleEndChat,
    ...rest
}) => {

    handleEndChat = typeof handleEndChat === 'function' ? handleEndChat : function noop() { }

    const handleCancel = (e) => {
        e.preventDefault();
        handleModalClose();
    }

    return (
        <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={false} centered={false} classes={'chat-setup__modal'  + (isDarkMode && ' dark' || '')}>
            <ModalBody> 
                <div className={`chat-setup__modal-content text-center ${isDarkMode && 'dark' || ''}`}>
                    <h5 className="heading">End Conversation</h5>
                    <div className="description mb-2">Do you want to end the conversation?</div>
                    

                    {/* Buttons */}
                    <button onClick={handleEndChat} className="pocket-btn me-2">
                        End Now
                    </button>

                    <Link to="#" onClick={handleCancel} className="pocket-btn end-chat cancel d-inline-block">
                        Cancel
                    </Link>

                </div>
            </ModalBody>
        </CommonModal>
    )
}

export default EndChatConfirm