import React from "react";

import PocketSelection from "src/core/component/selection/PocketSelection";


const Filter = ({
    filter,
    relationships,
    onChangeFilter,
    isDarkMode, 
    ...rest}) => {

    onChangeFilter = typeof onChangeFilter === 'function' ? onChangeFilter : function noop(){ }

    const options = relationships.map(data => ({
        'id': data.id,
        'display': data.name,
        'name': 'relationship_id'
    }))

    const filterOptions = [{'id': 'all','display': 'All', 'name': 'relationship_id'}, ...options];

    return (
        <div className={"row filters" + (isDarkMode && ' dark' || '')}>
            {/* relationship option filter */}
            <div className="col-12">
                <PocketSelection 
                    options= {[...filterOptions]}
                    currentValue={ filterOptions.filter(opt=>opt.id===filter['relationship_id'])[0] || ''}
                    onChange={ onChangeFilter }
                    classes={"birth-profile__filter" + (isDarkMode && ' dark' || '')}
                />
            </div>
        </div> 
    )
}

export default Filter