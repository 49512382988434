import React from "react";
import { Route, Switch, useRouteMatch, Redirect  } from "react-router-dom";

import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import Sidebar from "src/astrologer/component/sidebar/Sidebar";
import Dashboard from "src/astrologer/component/dashboard/Dashboard";
import Accounts from "src/astrologer/component/accounts/Accounts";
import Reviews from "src/astrologer/component/reviews/Reviews";
import useAuthInfo from "src/auth/hook/auth-info";


const AstrologerHome = () => {

    const { isAuthenticated } = useAuthInfo();
    const match = useRouteMatch();
    const [isDarkMode] = useTheme();

    if (!isAuthenticated) {
        return <Redirect to="/auth/login" />
    }

    return (
        <MainLayout>
            <section className={"profile-settings astrologer-dashboard" + (isDarkMode && ' dark' || '')}>
                <div className="container-xl">
                    <div className="row gx-2">
                        <div className="col-sm-3 col-md-3 mb-4 mb-sm-0">
                            <Sidebar path={match.path} isDarkMode={isDarkMode} />
                        </div>
                        <div className="col-sm-9 col-md-9">
                            <div className={"profile-settings__contents astrologer-dashboard__contents" + (isDarkMode && ' dark' || '')}>
                                <Switch>
                                    <Route  
                                        exact
                                        path={`${match.path}`}  
                                        render={props => (
                                            <Dashboard 
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}
                                                isAuthenticated={isAuthenticated}
                                            />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/profile`}  
                                        render={props => (
                                            <Accounts 
                                                {...props}
                                                path={match.path}
                                                isDarkMode={isDarkMode}{...props}
                                                isAuthenticated={isAuthenticated}
                                            />
                                        )}
                                    />

                                    {/* <Route  
                                        exact
                                        path={`${match.path}/reviews`}  
                                        render={props => (
                                           <Reviews
                                            {...props}
                                            path={match.path}
                                            isDarkMode={isDarkMode}{...props}
                                            isAuthenticated={isAuthenticated}
                                           />
                                        )}
                                    />

                                    <Route  
                                        exact
                                        path={`${match.path}/chat`}  
                                        render={props => (
                                           <div>Chat</div>
                                        )}
                                    /> */}

                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}

export default AstrologerHome;
