import React from "react";

import CookieHome from "src/gdpr/component/cookie/CookieHome";
import MainLayout from 'src/core/component/MainLayout';


export default function CookiePage() {

  return (
    <MainLayout>
        <CookieHome />
    </MainLayout>
  )
}
