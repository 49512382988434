import React, {useState, useEffect} from "react";

import api from 'src/lib/api';
import H4Heading from "src/core/component/grid/H4Heading";
import { FilterSvg } from "src/core/component/assets/svg/CommonSVG";
import OrderList from "./OrderList";
import FilterModal from "./FilterModal";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from 'src/auth/hook/auth-info';
import { PocketToast } from "src/core/component/toast/PocketToast";

const VALID_ORDER_STATES  = ['done', 'cancelled']

const Orders = ({path, isDarkMode, ...rest }) => {
    const { isAuthenticated } = useAuthInfo();
    const [showModal, setShowModal] = useState(false);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [predictionOrderLoading, setPredictionOrderLoading] = useState(false);
    const [chatConverstionLoading, setChatConversationLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [questionOrders, setQuestionOrders] = useState([]);
    const [chatOrders, setChatOrders] = useState([]);
    const [filter, setFilter] = useState({
        'on_progress': '',
        'type': ''
    })

    useEffect(() => {
        fetchQuestionOrders();
    }, [])

    const fetchQuestionOrders = async () => {
        setPredictionOrderLoading(true)

        try {
            const result = await api.get(`v1/question_order`);
            let questionOrderData = (result?.data?.result?.data || []).filter(o=>VALID_ORDER_STATES.includes(o.state)).map(order=>({...order, 'type': 'question'}))
            setQuestionOrders(questionOrderData);
            setPredictionOrderLoading(false);
        }
        catch (error) {
            let errorData = error?.response?.data?.error || error
            PocketToast({
                type: "error",
                message: errorData?.message || "Error on fetching prediction orders"
            })
            setPredictionOrderLoading(false);
        }
    }

    useEffect(() => {
        if (!predictionOrderLoading) {
            let allOrders = [...questionOrders];
            if(allOrders && allOrders.length){
                const sorted = [...allOrders].sort((a, b) => {
                    if(new Date(b.created_at).format('yyyy-MM-dd:HH') > new Date(a.created_at).format('yyyy-MM-dd:HH')) {
                        return 1;
                    } else {
                        return -1;
                    }
                });

                setOrders(sorted)
            }
        }
    }, [questionOrders, predictionOrderLoading])

    const handleModalShow = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    let orderData = orders;
    if(filter){
        if(filter.type) {
            orderData = orderData.filter(item=>item.type === filter.type);
        }
        if(filter.on_progress){
            orderData = orderData.filter(item=>item.state === 'on_progress')
        }
    }

    // console.log('Order Data', orderData)
    // console.log('Filter', filter)
    // console.log('chat orders', chatOrders);
    // console.log('prediction orders', questionOrders);
    // console.log('Orders Loading...', ordersLoading)


    return (
        <div className={"orders" + (isDarkMode && ' dark' || '')}>
            <div className="d-flex align-items-center justify-content-between orders__header">
                <H4Heading
                    classes={"pocket-page-heading mb-0" + (isDarkMode && ' dark' || '')}
                    heading={`Your Orders`}
                />

                {/* For V2, V1=>all orders are question orders */}
                {/* <div className={"orders__filter" + (isDarkMode && ' dark' || '')} onClick={handleModalShow}>
                    <FilterSvg />
                    filter
                </div> */}
            </div>

            {/* Filter Modal */}
            <FilterModal
                handleModalShow={handleModalShow}
                handleModalClose={handleModalClose}
                showModal={showModal}
                filter={filter}
                setFilter={setFilter}
                isDarkMode={isDarkMode}
            />

            <div className={"orders__list" + (isDarkMode && ' dark' || '')}>
                {predictionOrderLoading && (
                    <div className="row">
                        <div className="col-12">
                            <LoadingIcon />
                        </div>
                    </div>
                )}

                {!predictionOrderLoading && orderData && (
                    <>
                        {orderData && orderData.length > 0 &&(
                            <OrderList
                                orderData={orderData}
                                isDarkMode={isDarkMode}
                                path={path}
                            />
                        )}
                    </>
                )}

            </div>
        </div>
    )
}

export default Orders
