import React from "react";
import { Link } from "react-router-dom";

import firebase from "src/lib/firebase";
import api from "src/lib/api";
import { PocketToast } from "src/core/component/toast/PocketToast";
import PasswordField from "src/core/component/form/PasswordField";
import FormField from "src/core/component/form/FormField";
import SocialRegisterLogin from "src/auth/component/social/SocialRegisterLogin";
import useAuthInfo from "src/auth/hook/auth-info";


export default function LoginForm({isDarkMode, onClickSignup}) {
  onClickSignup = typeof onClickSignup === 'function' ? onClickSignup : function noop(){ }

  const { login: loginUser } = useAuthInfo();
  const [formData, setFormData] = React.useState({
    login_method: "password",
    login: "",
    password: "",
  });
  const [error, setError] = React.useState(null);

  const handleLogin = async (credentials) => {
    setError(null);
    const { login, password } = credentials;

    try {
      if (credentials.login_method === "otp") {
        throw new Error("Goto OTP Login");
      }

      const res = await api.post("/v1/auth/token", {
        login: login,
        password: password,
      });

      if (res.data?.error) {
        throw res.data.error;
      }

      const data = res.data?.result?.data;
      loginUser(data["access_token"], data["refresh_token"]);
      PocketToast({
        type: "success",
        message: "Login successful",
      });

    } catch (error) {
      let errorData = error?.response?.data?.error || error
      setFormData({ ...credentials, login: login, password: "" });
      setError(errorData?.message || "Unknown error");
      window.recaptchaVerifier.render().then(function (widgetId) {
        grecaptcha.reset(widgetId);
      });
    }
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleLogin(formData);
  };

  const handleValueChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  let fields = [
    {
      name: "login",
      placeholder: "Enter your email",
      label: "Email",
      type: "email",
      required: true,
    },
    {
      name: "password",
      placeholder: "Enter your password",
      label: "Password",
      type: "password",
      required: true,
    },
  ];

  // console.log('Data', formData)

  return (
    <div
      className={
        "pocket-auth__login--wrapper" + ((isDarkMode && " dark") || "")
      }
    >
      <form
        onSubmit={handleFormSubmit}
        autoComplete="off"
        className={"pocket-form" + ((isDarkMode && " dark") || "")}
      >
        {error && <div className="alert alert-danger">{error}</div>}

        <div className="row gx-2 gx-smsm-3">
          {fields &&
            fields.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 field" key={index}>
                  {(item.type === "text" || item.type === "email") && (
                    <FormField
                      property={item.name}
                      type={item.type}
                      hasLabel={true}
                      label={item.label}
                      placeholder={item.placeholder}
                      onChange={handleValueChange}
                      value={formData[item.name]}
                      required={item.required}
                      autoComplete={"off"}
                    />
                  )}

                  {item.type === "password" && (
                    <PasswordField
                      property={item.name}
                      hasLabel={true}
                      label={item.label}
                      placeholder={item.placeholder}
                      onChange={handleValueChange}
                      value={formData[item.name]}
                      required={item.required}
                    />
                  )}
                </div>
              );
            })}
        </div>

        <div className="row">
          {/* <div className="col-12 col-sm-6">
            <CheckboxField
              property={"accept_tos"}
              onChange={handleChange}
              classes={"register-login"}
            >
              <label className="form-check-label" htmlFor="accept_tos">
                Remember me
              </label>
            </CheckboxField>
          </div> */}
          <div className="col-12 col-sm-6">
            <div className="form-text mt-0">
              <p>
                Forgot your password?{" "}
                <Link to="/auth/forgot-password">Reset Password</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="submit-login-register">
          <button className="pocket-btn" type="submit">
            Login
          </button>
        </div>
      </form>

      <div className="account-exists form-text">
        Don't have an account? <Link to="/auth/signup" onClick={onClickSignup}>Sign Up</Link>
      </div>

      <div className="social-login mb-sm-5">
        <SocialRegisterLogin
          title={"Or login with social accounts"}
          type={"Login"}
        />
      </div>
    </div>
  );
}
