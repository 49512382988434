import React, { Fragment, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import api from 'src/lib/api';
import { useModal } from "src/core/hook/useModal";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { PocketToast } from "src/core/component/toast/PocketToast";
import useAuthInfo from "src/auth/hook/auth-info";
import {
  CloseSvg,
  TickMarkSvg,
} from "src/core/component/assets/svg/CommonSVG";
import { useTheme } from "src/theme/component/ThemeContext";
import { ProfileImgSvg } from "src/core/component/assets/svg/ProfileSettingSVG";
import dataURItoBlob from "src/util/data-uri-to-blob";


const AvatarImageUpload = ({ profile }) => {
    const {updateProfile} = useAuthInfo();
    const [profileImage, setProfileImage] = useState("");
    const [profileCropData, setProfileCropData] = useState("");
    const [profileCropper, setProfileCropper] = useState();
    const [showModal, handleModalShow, handleModalClose] = useModal(false);
    const [isDarkMode] = useTheme();

    useEffect(() => {
        if (!profileCropData) return;
        handleAvatarSubmit();
    }, [profileCropData]);

    const handleAvatarSubmit = async () => {
        try {
            const [avatarImage, avatarImageName] = dataURItoBlob(profileCropData, "image");
            const formData = new FormData();
            formData.append("image", avatarImage, avatarImageName);

            const result = await api.put(`v1/users/me/change-profile`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            const profileData = result.data?.result?.data;
            if(profileData) {
                updateProfile(profileData);

                PocketToast({
                    type: "success", 
                    message: "Image uploaded successfully."
                })
            }
        }
        catch (error) {
            PocketToast({
                type: "error", 
                message: "Image upload failed."
            })  
        }
    }

    const onChangeProfileHandle = (e) => {
        e.preventDefault();

        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
    
        const reader = new FileReader();
        reader.onload = () => {
            setProfileImage(reader.result);
        };
        reader.readAsDataURL(files[0]);

        handleModalShow();
    };

    const getProfileCropData = () => {
        if (typeof profileCropper !== "undefined") {
            setProfileCropData(
                profileCropper.getCroppedCanvas().toDataURL(profileImage.slice(5, 15))
            );
            setProfileImage("");
        }

        handleModalClose();
    };

    return (
        <Fragment>
        {/* Step 1, Profile Image Set */}
        <div
            className={`pocket-profile-setup__profile-avatar ${isDarkMode && 'dark' || ''}`}
            style={{
            backgroundImage:
                "url(" + (profileCropData || profile?.avatar_url) + ")",
            }}
        >
            <Form.Group controlId="profile_avatar">
            <Form.Label>
                <div className="img-icon">
                    <ProfileImgSvg />
                </div>
            </Form.Label>

            <Form.Control
                accept="image/png, image/jpeg"
                type="file"
                className="visually-hidden"
                onChange={onChangeProfileHandle}
            />
            </Form.Group>
        </div>

        {/*  Modal For Profile Image Crop */}
        <CommonModal
            showModal={showModal}
            size="md"
            centered={true}
            backdrop="static"
            showCustomClose={false} 
            classes={`profile-image-set ${isDarkMode && 'dark' || ''}`}
        >
            <ModalBody>
            <Cropper
                cropBoxResizable={false}
                dragMode="move"
                style={{ height: "100%", width: "100%" }}
                initialAspectRatio={1}
                src={profileImage}
                onInitialized={(instance) => {
                    setProfileCropper(instance);
                }}
                toggleDragModeOnDblclick={false}
                viewMode={1}
                zoomTo={0}
            />

            <div className={`pocket-cropper-btns mt-3 d-flex justify-content-center ${isDarkMode && 'dark' || ''}`}>
                <span
                    className="pocket-cropper__tick"
                    onClick={getProfileCropData}
                    style={{ cursor: "pointer", margin: "0 40px 0 0" }}
                >
                    <TickMarkSvg />
                </span>
                <span
                    className="pocket-cropper__close"
                    onClick={() => {
                        handleModalClose();
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <CloseSvg />
                </span>
            </div>
            </ModalBody>
        </CommonModal>
        </Fragment>
    );
};

export default AvatarImageUpload;
