import React from "react";
import Alert from 'react-bootstrap/Alert';

import { CloseButtonSvgSmall } from "src/core/component/assets/svg/CommonSVG";


const DismissableAlert = ({text, handleAlertClose, classes="", ...rest}) => {
  
  return ( 
        <Alert className={"mb-0 " + classes}>
            <div className="d-flex justify-content-center align-items-center">
                <p className="mb-0  alert-text">{text}</p>
                <div className="dismiss-alert" onClick={handleAlertClose}>
                    <CloseButtonSvgSmall />
                </div>
            </div>
        </Alert>
  )
}

export default DismissableAlert
