import React,{useState} from "react";
import TextArea from "src/core/component/form/TextArea";
import { getHtmlWordCount } from 'src/util/getWordCount'

const ForumReplyForm = ({
  onChange,
  handleSubmit,
  value,
  loading,
  isDarkMode,
  rows = "3",
  maxLength = "",
  ...rest
}) => {
  const _reply_limit = 1000;
  let [charactersLimit,setcharactersLimit] = useState(maxLength);

  const replyLimit = (e)=>{
    let textArealength =e.target.value.length
    // let textLength = getHtmlWordCount(e.target.value)
    let limit = _reply_limit - textArealength;

    if(limit>0){  
      setcharactersLimit(limit)
    }else{
      setcharactersLimit(0)
    }
  }

  const onChangeTextArea = (e)=>{
    onChange(e);
    replyLimit(e)
  }

  const handleSubmitTextArea = (e)=>{
    handleSubmit(e);
    setcharactersLimit(maxLength)
  }

  return (
    <>
      <form
        className={"pocket-reply-form w-100" + ((isDarkMode && " dark") || "")} onSubmit={handleSubmitTextArea} >
        <div className="row">
          <div className="col-12 pocket-reply-form-body">
            <TextArea
              hasLabel={false}
              placeholder={"Add a reply."}
              property={"content"}
              value={value}
              onChange={onChangeTextArea}
              required={true}
              rows={rows}
              disabled={loading}
              maxLength={maxLength}
            />
            <span style={{fontSize:'12px',color:charactersLimit<50?'red':'#585858' }}>{charactersLimit+' left'}</span>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
            <button
                type="submit"
                className="pocket-btn forum reply position-relative"
                disabled={loading}
            >
            Reply
            {loading && (
                <span
                className="spinner-border spinner-border-sm position-absolute"
                role="status"
                aria-hidden="true"
                ></span>
            )}
            </button>
        </div>
      </form>
    </>
  );
};

export default ForumReplyForm;
