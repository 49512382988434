import React from "react";
import { useHistory } from "react-router-dom";

import H4Heading from "src/core/component/grid/H4Heading";
import { AngleDownSvg } from "src/core/component/assets/svg/CommonSVG";
import { ChartSettingSvg, NotifcationSettingSvg } from "src/core/component/assets/svg/ProfileSettingSVG";


const Preference = ({path, isDarkMode, ...rest }) => {
    const history = useHistory();

    const handleTabClick = (tab) => {
        history.push(`${path}/preference/${tab}`)
    }

    return ( 
        <div className={"preferences" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Your Preferences`} 
            />

            <div className="row">
                <div className="col-12">
                    <p className="separator mt-0"></p>

                    <ul className={"inner-menu list-unstyled mb-0" + (isDarkMode && ' dark' || '')}>
                        <li className={`inner-menu__item ${isDarkMode && 'dark' || ''}`} onClick={handleTabClick.bind(null, 'chart')}>
                            <div className="d-flex align-items-center">
                                <div className="title d-flex align-items-center">
                                    <ChartSettingSvg />
                                    <span>Chart Settings</span>
                                </div>
                                <span className="icon-right ms-auto">
                                    <AngleDownSvg />
                                </span>
                            </div>
                        </li>

                        <p className="separator"></p>

                        <li className={`inner-menu__item ${isDarkMode && 'dark' || ''}`} onClick={handleTabClick.bind(null, 'notification')}>
                            <div className="d-flex align-items-center">
                                <div className="title d-flex align-items-center">
                                    <NotifcationSettingSvg />
                                    <span>Notification Settings</span>
                                </div>
                                <span className="icon-right ms-auto">
                                    <AngleDownSvg />
                                </span>
                            </div>
                        </li>

                        <p className="separator"></p>

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Preference
