import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import CurrencySvg from "../../images/Collection";
import useWalletTransactions from 'src/wallet/hook/useWalletTransactions'
import PocketSelection from 'src/core/component/selection/PocketSelection';


const WalletTransactions = () => {
  const {walletTransactions,exchangeRate, loading, error} = useWalletTransactions()
  const transactionFilterOptions = [
    {
        label:'All',
        value:'all'
    },
    {
        label:'Other',
        value:'other'
    },
]
const [currentTransactionFilter,setCurrentTransactionFilter] = useState(transactionFilterOptions[0])

  return (
    <div className='transactions'>
        <div className='transactions-title'>
          <div>Your Transactions</div>
          <div className='w-25'>
          <PocketSelection
                options={transactionFilterOptions}
                currentValue={currentTransactionFilter}
                onChange={(item)=>setCurrentTransactionFilter(item)}
                hasLabel={false}
                placeholder={'Select'}
                classes={"pocket-select"}
                />
          </div>
          </div>
        <div className='transactions-table'>
        {(error == null && !loading)?
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Detail</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Title</th>
                <th>Transaction Date</th>
              </tr>
            </thead>
            <tbody>
            {
              walletTransactions.map((t)=>
              <tr key={t.id}>
                <td>{t.detail}</td>
                <td>{t?.credit!=null?<span><CurrencySvg/> {parseFloat(t.credit/100*exchangeRate).toFixed(2)}</span>:null}</td>
                <td>{t?.debit!=null?<span><CurrencySvg/> {parseFloat(t.debit/100*exchangeRate).toFixed(2)}</span>:null}</td>
                <td>{t.title}</td>
                <td>{new Date(t.created_at).format('yyyy-MM-dd')}</td>
              </tr> 
              )}
            </tbody>
      </Table>
        :null}
        </div>
    </div>
  )
}

export default WalletTransactions