import React from 'react'

import logo from 'src/image/navigation_icons/final-logo.png'

const LogoLight = ({width= ""}) => {

  return (
    <img src={logo} className="img-fluid" alt="" width={width} />
  )
}

export default LogoLight
