import React from "react";

import CategorySlider from "src/core/component/category/CategorySlider";
import ViewMoreLink from "./ViewMoreLink";


const OfferedRelationship = ({
    relationships, 
    promo, 
    handleRelationshipSelect, 
    isRelationshipSelected, 
    handleBrowseMore, 
    isDarkMode, 
    ...rest
}) => {

    handleRelationshipSelect = typeof handleRelationshipSelect === 'function' ? handleRelationshipSelect : function noop(){ }
    isRelationshipSelected = typeof isRelationshipSelected === 'function' ? isRelationshipSelected : function noop(){ }

    if(promo && promo.scope==='relationship' && promo.relationships.length && relationships){
        relationships = relationships.filter(item=>item.id!==promo.relationships[0].id)
    }

    return (
        <div className="related-contents__relationship">
            {relationships && relationships.length > 0 && (
                <>
                    <h3 className="related-title">Browse Our Other Relationships:</h3>
                    <div className="">
                        <CategorySlider
                            categories={relationships}
                            handleCategorySelect={handleRelationshipSelect}
                            isCategorySelected={isRelationshipSelected}
                            isDarkMode={isDarkMode}
                        />
                    </div>

                    <ViewMoreLink 
                        title={'View all relationships'}
                        onClick={handleBrowseMore}
                        className={`d-flex justify-content-end`}
                        isDarkMode={isDarkMode}
                    />
                </>
            )}
            
        </div>
    )
}

export default OfferedRelationship
