import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import FormField from "src/core/component/form/FormField";
import Gender from "src/core/component/form/Gender";
import SearchLocation from "src/core/component/form/SearchLocation";
import PreviousNextButton from "src/core/component/PeviousNextButton";
import PocketDatePicker from "src/core/component/form/PocketDatePicker";
import PocketSelection from "src/core/component/selection/PocketSelection";
import BirthProfileAlert from "./BirthProfileAlert";

const BirthProfileSetupForm = ({
  relationTitle,
  isDarkMode,
  handleChange,
  handleSubmit,
  onClickNext,
  onClickPrev,
  form,
  setForm,
  onLocationChange,
  onChangeSelection,
  isSubmitting,
  relationships = [],
  nameTextLength = "",
  prevLink = "/instant-prediction",
  nextLink = "/instant-prediction",
  relationship = {},
  handlePrashnaKundali = () => {},
  ...rest
}) => {
  const location = useLocation();
  const options =
    (relationships &&
      relationships.map((data) => ({
        id: data.id,
        display: data.name,
        name: "relationship_id",
      }))) ||
    [];

  let fields = [
    {
      name: "name",
      type: "text",
      placeholder: "Enter Full Name",
      label: "Full Name",
      autoComplete: false,
      hasInstruction: false,
      required: true,
      hasLink: false,
      hasIcon: false,
      instruction: "",
      icon: "",
      maxLength: nameTextLength,
    },
    {
      name: "address",
      type: "location-input",
      placeholder: "Enter Birth Location",
      label: "Birth Location",
      autoComplete: false,
      hasInstruction: false,
      required: true,
      hasLink: true,
      hasIcon: false,
      instruction: `Don’t know your ${relationTitle}'s birth location?`,
      icon: "",
      disabled: form?.profile_type == 'prashna_kundali_profile'
    },
    {
      name: "date",
      type: "date",
      placeholder: "Enter Birth Date",
      label: "Birth Date (YYYY-MM-DD)",
      required: true,
    },
    {
      name: "time",
      type: "time",
      placeholder: "Enter Birth Time",
      label: "Birth Time (HH:MM:SS AM/PM)",
      step: 1,
      required: true,
      disabled: form?.profile_type == 'prashna_kundali_profile',
      pk_disabled: form?.profile_type == 'prashna_kundali_profile'
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      autoComplete: false,
      required: true,
    },
    {
      name: "relationship_id",
      label: "Relationship",
      type: "select",
      required: true,
      options: options,
    },
  ];

  return (
    <div className={"birth-profile__form" + ((isDarkMode && " dark") || "")}>
      <form
        onSubmit={handleSubmit}
        className={"pocket-form" + ((isDarkMode && " dark") || "")}
        autoComplete="off"
      >
        <div className="row gx-2 gx-md-3">
          {fields &&
            fields.map((item, index) => {
              return (
                <div className="col-12 col-sm-6 field" key={index}>
                  {(item.type == "text" || item.type === "time") && (
                    <>
                      <FormField
                        property={item.name}
                        type={item.type}
                        hasLabel={true}
                        hasIcon={item.hasIcon}
                        hasLink={item.hasLink}
                        label={item.label}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        onChange={handleChange}
                        value={form[item.name] || ""}
                        hasInstruction={false}
                        required={item.required}
                        autoComplete={"off"}
                        step={item.step}
                        maxLength={item?.maxLength}
                        disabled={item?.disabled || false}
                        pk_disabled={item?.pk_disabled || false}
                      />
                      {/* {item.type === "time" && (
                        <BirthProfileAlert
                        className="no-idea-alert bg-transparent m-0 p-0"
                        onClick={handlePrashnaKundali}
                        relationship={relationship}
                        />
                        )}  */}
                    </>
                  )}

                  {item.type === "date" && (
                    <PocketDatePicker
                      formData={form}
                      setFormData={setForm}
                      property={item.name}
                      required={item.required}
                      error={"Please enter a valid date!"}
                      hasLabel={true}
                      label={item.label}
                      placeholder={item.placeholder}
                      disabled={false}
                      isDarkMode={isDarkMode}
                    />
                  )}

                  {item.type == "location-input" && (
                    <SearchLocation
                      property={item.name}
                      hasLink={item.hasLink}
                      hasInstruction={item.hasInstruction}
                      hasLabel={true}
                      hasIcon={item.hasIcon}
                      label={item.label}
                      placeholder={item.placeholder}
                      instruction={item.instruction}
                      icon={item.icon}
                      onChange={onLocationChange}
                      value={form[item.name] || ""}
                      disabled={item?.disabled || false}
                    />
                  )}

                  {item.type === "select" && item.name === "gender" && (
                    <Gender
                      value={form[item.name]}
                      /*value={
                        !(
                          relationship?.name === "Self" ||
                          relationship?.name === "Friend"
                        )
                          ? findGender(relationship.name)
                          : form[item.name]
                      }*/
                      onChange={onChangeSelection}
                      classes={
                        "pocket-select" + ((isDarkMode && " dark") || "")
                      }
                      hasLabel={true}
                      label={item.label}
                      placeholder="Select Gender"
                      disabled={
                        !(
                          relationship?.name === "Self" ||
                          relationship?.name === undefined ||
                          relationship?.name === "Friend" 
                        )
                      }
                    />
                  )}

                  {item.type === "select" &&
                    item.name === "relationship_id" && (
                      <PocketSelection
                        options={[...item.options]}
                        currentValue={
                          item.options.filter(
                            (opt) => opt.id === form[item.name]
                          )[0] || ""
                        }
                        onChange={onChangeSelection}
                        classes={
                          "pocket-select" + ((isDarkMode && " dark") || "")
                        }
                        hasLabel={true}
                        label={item.label}
                        placeholder={"Relationship"}
                        disabled={
                          location?.state?.relationship_id !== "all" && 
                          location?.state?.relationship_id 
                          }
                      />
                      // TODO remove 2nd condition
                    )}
                </div>
              );
            })}
        </div>
      </form>

      <div className="d-none d-sm-block">
        <PreviousNextButton
          classes="d-flex justify-content-between align-items-center"
          hasPrev={true}
          hasNext={true}
          isDarkMode={isDarkMode}
          prevLink={prevLink}
          nextLink={nextLink}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          hasLoading={true}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  );
};

export default BirthProfileSetupForm;
