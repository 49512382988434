import React from "react";

import SearchLocation from "src/core/component/form/SearchLocation";
import FormField from "src/core/component/form/FormField";
import CheckboxField from "src/core/component/form/CheckboxField";
import PocketSelection from "src/core/component/selection/PocketSelection";
import Gender from "src/core/component/form/Gender";
import PocketDatePicker from "src/core/component/form/PocketDatePicker";
import BirthProfileAlert from "src/instant-prediction/components/birthprofilesetup/BirthProfileAlert";
import CircleImage from "src/core/component/birthprofile/CircleImage";

const BirthProfileForm = ({
    relationTitle="",
    onClick,
    isDarkMode,
    handleChange,
    handleSubmit,
    form,
    onLocationChange,
    onChangeSelection,
    setForm,
    profileId,
    relationships=[],
    gapClass,
    showInstruction=true,
    nameTextLength="",
    relationship = {},
    handlePrashnaKundali = () => {},
    ...rest}) => {

    onClick = typeof onClick === 'function' ? onClick : function noop() { }
    handleSubmit = typeof handleSubmit === 'function' ? handleSubmit : function noop() { }
    onChangeSelection = typeof onChangeSelection === 'function' ? onChangeSelection : function noop() { }

    const options = relationships && relationships.map(data => ({
        'id': data.id,
        'display': data.name,
        'name': 'relationship_id'
    })) || [];

    let fields = [
        {
            name: 'name',
            type: 'text',
            placeholder: 'Enter Full Name',
            label: 'Full Name',
            hasInstruction: showInstruction,
            hasLink: false,
            hasIcon: false,
            instruction: '',
            icon: '',
            required: true,
            autoComplete: false,
            maxLength: nameTextLength
        },
        {
            name: 'address',
            type: 'location-input',
            placeholder: 'Enter Birth Location',
            label: 'Birth Location',
            hasInstruction: showInstruction,
            hasLink: true,
            hasIcon: false,
            instruction: `Don’t know your ${relationTitle}'s birth location?`,
            icon: '',
            required: true,
            autoComplete: false,
            disabled: form?.profile_type == 'prashna_kundali_profile'
        },
        {
            name: 'date',
            type: 'date',
            placeholder: 'Enter Birth Date',
            label: 'Birth Date (YYYY-MM-DD)',
            hasInstruction: showInstruction,
            hasLink: true,
            hasIcon: false,
            instruction: `Don’t know your ${relationTitle}'s birth birth date?`,
            icon: '',
            required: true,
            autoComplete: false
        },
        {
            name: 'time',
            type: 'time',
            placeholder: 'Enter Birth Time',
            label: 'Birth Time (HH:MM:SS AM/PM)',
            step: 1,
            hasInstruction: showInstruction,
            hasLink: true,
            hasIcon: false,
            instruction: `Don’t know your ${relationTitle}'s birth birth date?`,
            icon: '',
            required: true,
            autoComplete: false,
            disabled: form?.profile_type == 'prashna_kundali_profile',
            pk_disabled: form?.profile_type == 'prashna_kundali_profile'
        },
        {
            name: 'gender',
            label: 'Gender',
            type: 'select',
            hasInstruction: false,
            hasLink: false,
            hasIcon: false,
            instruction: '',
            icon: '',
            required: true
        },
        {
            name: 'relationship_id',
            label: 'Relationship',
            type: 'select',
            required: true,
            options: options
        }
    ]

    return (

        <form onSubmit={handleSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')} autoComplete="off">
        <div className="text-center birth-profile__alert__container__border">
            {!profileId && (<BirthProfileAlert className={`${isDarkMode?'dark':''}`} onClick={handlePrashnaKundali} relationship={relationship} />)}
            </div>
            <div className={`row ${gapClass}`}>
            {/* <div className={"profile-image" + ((isDarkMode && " dark") || "")}>
                <CircleImage
                    url={form?.avatar_url}
                    showIconOverlay={true}
                    isDarkMode={isDarkMode}
                    onClick={()=>{}}
                    />
            </div> */}
                {
                    fields && fields.map((item, index) =>{
                        return (
                            <div className="col-12 col-sm-6 field" key={index}>
                                {(item.type === "text" || item.type === "email" || item.type === "time") && (
                                    <>
                                        <FormField
                                            property={item.name}
                                            type={item.type}
                                            hasLabel={true}
                                            hasIcon={item.hasIcon}
                                            hasLink={item.hasLink}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            icon={item.icon}
                                            onChange={handleChange}
                                            value={form[item.name] || ""}
                                            hasInstruction={item.hasInstruction}
                                            instruction={item.instruction}
                                            onClick={onClick}
                                            required={item.required}
                                            autoComplete={"off"}
                                            maxLength={item?.maxLength || ""}
                                            disabled={item?.disabled || false}
                                            pk_disabled={item?.pk_disabled || false}
                                        />
                                        {/*item.type === "time" && <BirthProfileAlert className="no-idea-alert bg-transparent m-0 p-0" onClick={handlePrashnaKundali} relationship={relationship} /> */}
                                    </>
                                )}

                                {item.type === "date" && (
                                    <PocketDatePicker
                                        formData={form}
                                        setFormData={setForm}
                                        property={item.name}
                                        required={true}
                                        error={"Please enter a valid date!"}
                                        hasLabel={true}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        disabled={false}
                                        isDarkMode={isDarkMode}
                                    />
                                )}

                                {item.type == "location-input" && (
                                    <SearchLocation
                                        property={item.name}
                                        hasLink={item.hasLink}
                                        hasInstruction={item.hasInstruction}
                                        hasLabel={true}
                                        hasIcon={item.hasIcon}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        instruction={item.instruction}
                                        icon={item.icon}
                                        onChange={onLocationChange}
                                        value={form[item.name] || ""}
                                        onClick={onClick}
                                        required={item.required}
                                        disabled={item?.disabled || false}
                                    />
                                )}

                                {item.type === "select" && item.name === "relationship_id" && (
                                    <PocketSelection
                                        options= {[...item.options]}
                                        currentValue={ item.options.filter(opt=>opt.id===form[item.name])[0] || ''}
                                        onChange={ onChangeSelection }
                                        classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                        hasLabel={true}
                                        label={item.label}
                                        placeholder={'Relationship'}
                                        disabled={!!profileId}
                                    />
                                )}

                                {item.type === "select" && item.name==="gender" && (
                                    <Gender
                                        value={form[item.name]}
                                        onChange={onChangeSelection}
                                        classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                                        hasLabel={true}
                                        label={item.label}
                                        placeholder={'Select Gender'}
                                        disabled={
                                            !(
                                            relationship?.name == "Self" ||
                                            relationship?.name == undefined ||
                                            relationship?.name == "Friend"
                                            )
                                        }
                                    />
                                )}

                            </div>
                        )
                    })
                }

            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between checkbox-and-save">
                    <CheckboxField property="is_primary" value={form["is_primary"]} onChange={handleChange} classes={"birth-profile-check"}>
                        <label className="form-check-label" htmlFor="is_primary">
                            Primary birth profile
                        </label>
                    </CheckboxField>
                    <div className="save-changes">
                        <button type="submit" className="pocket-btn">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default BirthProfileForm
