import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import OtpInput from "react-otp-input";
import useAuthInfo from "src/auth/hook/auth-info";


const OtpInputForm = ({
  processing,
  setOtp,
  handleOtpSubmit,
  onClickResend,
  isDarkMode,
}) => {
  const { profile } = useAuthInfo();
  const [code, setCode] = useState("");
  const [resending, setResending] = useState(false);
  const hasResendOption = (typeof onClickResend === 'function');

  onClickResend = onClickResend || async function noop() { }

  const handleOtpChange = (code) => {
    setCode(code);
  }

  useEffect(() => {
    setOtp(code);
  }, [code])

  const inputStyle = {
    padding: '5px',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '16px',
    color: isDarkMode ? '#cccccc' : '#0a1d40',
    border: isDarkMode ? '2px solid #191919' : '2px solid #f5f5f5',
    backgroundColor: isDarkMode ? '#191919' : '#f5f5f5',
    width: '50px',
    height: '50px',
    borderRadius: '5px'
  }

  const focusStyle = {
    border: '2px solid #1d5dc2',
    boxShadow: 'none',
    outline: 'none'
  }

  const handlClickResend = (e) => {
    e.preventDefault();
    if (resending) { return false; }

    setResending(true);

    onClickResend().finally(() => {
      setResending(false);
    });
  }

  return (
    <>
      <p className="description secondary">
        Please check your email {profile?.email && (<u>({profile.email})</u>)} for the 6 digit verification code and enter it here.

        {hasResendOption && (<> If you haven't yet received the code, you may manually send it using the link below.</>)}
      </p>

      <form onSubmit={handleOtpSubmit} className={"pocket-form" + (isDarkMode && ' dark' || '')} autoComplete="off">
        <p className="otp-label">Enter Verification Code</p>
        <div className="row">
          <div className="col-12 field">
            <OtpInput
              value={code}
              onChange={handleOtpChange}
              numInputs={6}
              separator={<span className="separator" style={{ width: "10px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              containerStyle={'pocket-otp'}
              inputStyle={inputStyle}
              focusStyle={focusStyle}
            />
          </div>
        </div>
        <div className="submit-login-register">
          <button className="pocket-btn" type="submit">
            {processing ? 'Processing...' : 'Continue'}
          </button>

          {hasResendOption && (<small><Link style={{ 'paddingLeft': 15 }} to="/" onClick={handlClickResend}>Send verification code</Link></small>)}
        </div>

      </form>
    </>

  )
}

export default OtpInputForm
