import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";

import { pick } from "src/util/extra";
import api from "src/lib/api";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { dateAndTimeToDateTime } from "src/util/date_and_time_to_datetime";
import { useModal } from "src/core/hook/useModal";
import H4Heading from "src/core/component/grid/H4Heading";
import MobilePrevNextButton from "src/core/component/MobilePrevNextButton";
import PrashnaKundaliModal from "src/core/component/prashnakundali/PrashnaKundaliModal";
import BirthProfileAlert from "./BirthProfileAlert";
import BirthProfileSetupForm from "./BirthProfileSetupForm";
import useFreeAstrology from "src/freeastrology/hook/useFreeAstrology";
import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import "src/instant-prediction/scss/birthprofilesetup/SetupBirthProfile.scss";
import findGender from "src/core/component/findGender";
import { useTheme } from "src/theme/component/ThemeContext";

const SetupBirthProfile = () => {
  const { isAuthenticated, updateBirthProfile } = useAuthInfo();
  const { rtpDatetime } = useRTPInfo();
  const location = useLocation();
  const [isDarkMode] = useTheme();
  const history = useHistory();
  const [profileId, setProfileId] = useState(0);
  const [relationships, setRelationships] = useState([]);
  const [showModal, handleModalShow, handleModalClose] = useModal(false);
  const [showPrasnaKundaliModal, handlePrasnaKundaliModalShow, handlePrasnaKundaliModalClose] = useModal(false);
  const [isPransaKundaliAgreed,setIsPransaKundaliAgreed] = useState(false)
  const [profileLoading, setProfileLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameTextLength, setNameTextLength] = useState(50);
  const { astroBirthProfile, selectProfile } = useFreeAstrology();

  // prashna_kundali_profile
  const [form, setForm] = useState({
    name: "",
    relationship_id: location?.state?.relationship_id || "",
    is_primary: false,
    address: "",
    dob: "",
    latitude: 0.0,
    longitude: 0.0,
    timezone: "",
    country_code: "",
    profile_type: "regular_kundali_profile",
    gender: "",
    date: new Date().format("yyyy-MM-dd"),
    time: new Date().format("HH:mm:ss"),
  });

  useEffect(() => {
    fetchRelationships();
  }, []);

  useEffect(()=>{
    autoSelectGender(form.relationship_id)
  },[form.relationship_id,relationships])

  const autoSelectGender = (rel_id)=>{
    let relationship = relationships.filter((rel) => rel.id == rel_id)[0]
    if( !( relationship?.name === "Self" || relationship?.name === "Friend" || relationship?.name == 'undefined')){
      setForm(prev=>({...prev,gender:findGender(relationship?.name)}))
    }
  }

  const fetchRelationships = async () => {
    try {
      const result = await api.get(`v1/relationship`);
      const relationshipData = result?.data?.result?.data || [];
      setRelationships(relationshipData);
    } catch (error) {
      console.log("Error on fetching relationships");
    }
  };

  useEffect(() => {
    if (location.state && location.state?.relationship_id) {
      setForm((state) => ({
        ...state,
        relationship_id: location?.state?.relationship_id,
      }));
    }
    if (location.state && location.state?.profile_id) {
      setProfileId((prevProfileId) => location.state.profile_id);
    }
  }, [location.state]);

  useEffect(() => {
    if (!profileId) {
      return;
    }
    fetchProfileDetails(profileId);
  }, [profileId]);

  const fetchProfileDetails = async (id) => {
    setProfileLoading(true);
    try {
      const profile = await api.get(`v1/birth-profiles/${id}`);
      const profileData = profile?.data?.result?.data;

      if (profileData && Object.keys(profileData).length > 0) {
        setForm((state) => ({
          ...state,
          name: profileData?.name || "",
          relationship_id: profileData?.relationship_id || location?.state?.relationship_id || "",
          is_primary: profileData?.is_primary || false,
          address: profileData?.address || "",
          dob: profileData?.dob || "",
          latitude: profileData?.latitude || 0.0,
          longitude: profileData?.longitude || 0.0,
          timezone: profileData?.timezone || "",
          country_code: profileData?.country_code || "",
          gender: profileData?.gender || "",
          date: (profileData?.dob && new Date(profileData.dob).format("yyyy-MM-dd")) || new Date().format("yyyy-MM-dd"),
          time: (profileData?.dob && new Date(profileData.dob).format("HH:mm:ss")) || new Date().format("HH:mm:ss"),
          profile_type: profileData?.profile_type || "regular_kundali_profile",
        }));
      }
      setProfileLoading(false);
    } catch (error) {
      PocketToast({
        type: "error",
        message: "Error on fetching profile details",
      });
      setProfileLoading(false);
    }
  };

  const handleProfileFormChange = useCallback((e) => {
    if (e.target.name === "name" && e.target.value.length === nameTextLength) {
      PocketToast({
        type: "warning",
        message: `Name should not be longer than ${nameTextLength} characters`,
      });
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value || e.target.checked || "",
    });
  });

  const handleSelectionChange = (e) => {
    setForm({ ...form, [e.name]: e?.id || e?.value });
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    handleProfileFormSubmit(e);
  };

  const onClickPrev = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleProfileFormSubmit = useCallback((e) => {
    e.preventDefault();
    const dataToSubmit = pick(
      form,
      "name",
      "relationship_id",
      "address",
      "is_primary",
      "gender",
      "latitude",
      "longitude",
      "timezone"
    );
    const dob = dateAndTimeToDateTime(form["date"], form["time"]);
    if (dob) {
      dataToSubmit["dob"] = dob;
    }

    if (Object.values(dataToSubmit).some((val) => val === null || val === "")) {
      PocketToast({
        type: "error",
        message: "All fields are required",
      });
      return;
    }

    dataToSubmit["profile_type"] = form["profile_type"];
    dataToSubmit["country_code"] = form["country_code"];

    setIsSubmitting(true);

    if(profileId) {
      editProfile(dataToSubmit).catch((e) => {
        PocketToast({
          type: "error",
          message: "Failed to update a birth profile",
        });
        setIsSubmitting(false);
      });
    } else {
      createProfile(dataToSubmit).catch((e) => {
        PocketToast({
          type: "error",
          message: "Failed to create a birth profile",
        });
        setIsSubmitting(false);
      });
    }
  });

  const createProfile = async (payload) => {
    if(payload.profile_type == "prashna_kundali_profile" && isPransaKundaliAgreed==false){
      handlePrasnaKundaliModalShow()
      return;
    }
    const result = await api.post("v1/birth-profiles", payload);
    setIsSubmitting(false);
    if (result?.status === 200) {
      updateBirthProfile(result.data?.result?.data);
      PocketToast({
        type: "success",
        message: "Birth profile created successfully",
      });
      if(location.state?.question_id){
        history.push({
          pathname: '/instant-prediction',
          state: { profile:result?.data?.result?.data,question_id:location?.state?.question_id }
        })
      }else{
        history.goBack();
      }
    }
  };

  const editProfile = async (payload) => {
    const result = await api.put(`v1/birth-profiles/${profileId}`, payload);
    setIsSubmitting(false);
    if (result?.status === 200) {
      updateBirthProfile(result.data?.result?.data);
      if(astroBirthProfile.id == profileId){
        selectProfile({...payload,id:profileId});
      }
      PocketToast({
        type: "success",
        message: "Birth profile updated successfully",
      });
      history.goBack();
    }
  };

  const handlePrashnaKundali = (e) => {
    e.preventDefault();
    handleModalShow();
  };

  const setupPrashnaKundali = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      profile_type: "prashna_kundali_profile",
      date:
        (rtpDatetime && new Date(rtpDatetime).format("yyyy-MM-dd")) ||
        new Date().format("yyyy-MM-dd"),
      time:
        (rtpDatetime && new Date(rtpDatetime).format("HH:mm:ss")) ||
        new Date().format("HH:mm:ss"),
    });
    handleModalClose();
  };

  const onLocationChange = (data) => {
    setForm((currentValue) => ({
      ...currentValue,
      address: data["query"] || "",
      latitude: data["place"]["latitude"] || "",
      longitude: data["place"]["longitude"] || "",
      timezone: data["timezone"]["name"] || "",
      country_code: data["country_code"] || "",
    }));
  };

  // console.log("Birth Form Data", form);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/instant-prediction/auth",
          state: {
            from: history.location.pathname,
            search: history.location.search,
          },
        }}
      />
    );
  }

  let relationship = "";
  if (location?.state?.relationship_id === form.relationship_id) {
    relationship = (relationships || []).find((relationship) => relationship.id === form.relationship_id);
  } else {
    relationship = (relationships || []).find((relationship) => relationship.id === form.relationship_id);
  }

  const handleGoBack = ()=>{ 
    setForm(prev=>({...prev,profile_type: "regular_kundali_profile",}))
    handlePrasnaKundaliModalClose();
    setIsSubmitting(false)
    // history.goBack()
  }

  const handlePrashnaKundaliAgreed = (e)=>{
    setIsPransaKundaliAgreed(true)
    handlePrasnaKundaliModalClose()
    handleNextClick(e)
  }

  return (
    <div className="birth-profile">
      <div className="birth-profile__tp mb-2">
        <H4Heading
          classes="rtp-heading"
          heading={`Please enter your ${relationship ? relationship?.is_self ? `birth profile` : `${relationship?.name}'s birth profile` : 'birth profile'}`}
        />
      <div className="text-center birth-profile__alert__container__border">
      {!profileId && (<BirthProfileAlert onClick={handlePrashnaKundali} relationship={relationship} />)}
      </div>

      </div>

      <div className="birth-profile__btm">
        {profileLoading && <LoadingIcon />}

        {!profileLoading && (
          <>
            <BirthProfileSetupForm
              relationTitle={location?.state?.name}
              handleChange={handleProfileFormChange}
              handleSubmit={handleProfileFormSubmit}
              form={form}
              setForm={setForm}
              onLocationChange={onLocationChange}
              onChangeSelection={handleSelectionChange}
              onClickNext={handleNextClick}
              onClickPrev={onClickPrev}
              isSubmitting={isSubmitting}
              nameTextLength={nameTextLength}
              relationships={relationships}
              prevLink="/instant-prediction"
              nextLink="/instant-prediction"
              relationship={relationship}
              handlePrashnaKundali={handlePrashnaKundali}
            />
          </>
        )}
      </div>

      <MobilePrevNextButton
        classes="d-flex justify-content-between align-items-center d-sm-none"
        hasPrev={true}
        hasNext={true}
        prevLink="/instant-prediction"
        nextLink="/instant-prediction"
        onClickNext={handleNextClick}
        onClickPrev={onClickPrev}
        hasLoading={true}
        isLoading={isSubmitting}
      />

      {/*Prashna Kundali Modal */}
      {showModal && (
        <PrashnaKundaliModal
          isDarkMode={isDarkMode}
          showModal={showModal}
          handleModalClose={handleModalClose}
          heading="Looks like you don't know your birth profile"
          description={`We will use the practice of ‘Prasna Chart’ to provide a prediction to your question. Please enter your current location below and we will use that along with the date and time you asked us for prediction to your question(s).`}
          onLocationChange={onLocationChange}
          setupPrashnaKundali={setupPrashnaKundali}
          formData={form}
        />
      )}

      {showPrasnaKundaliModal && (
        <CommonModal showModal={handlePrasnaKundaliModalShow} classes="rtp__birth__profile__prasnakundali" 
        showCustomClose={false} size={"lg"} >
          <ModalBody classes={"rtp__birth__profile__prasnakundali"}>
            <div className="">
                <div className="rtp__birth__profile__prasnakundali__particular__profile">You have created a Prashna Kundali (Question Chart) profile</div>
                  <div className={"rtp_card" }>
                    <div className="rtp_card_text">
                     <span> You can only select and buy one question using a Prashna Kundali (Question Chart) profile.</span> 
                      <span>If you know your Birth Detail please enter it or proceed with <b>Prashna Kindali</b></span>
                    </div>
                    <div className="rtp_card_buttons">
                      <div className="cancel" onClick={handleGoBack}>Cancel</div>
                      <div className="proceed" onClick={(e)=>{handlePrashnaKundaliAgreed(e)}}>Proceed</div>
                    </div>
                  </div>
              </div>
            
            </ModalBody>
    </CommonModal>
      )}
    </div>
  );
};

export default SetupBirthProfile;
