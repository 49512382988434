import React from 'react';
import { Link } from "react-router-dom";

import { DarkModeSvg, LightModeSvg } from 'src/core/component/assets/svg/CommonSVG';


const ThemeToggler = ({isDarkMode, toggleTheme, classes, ...rest}) => {
    
    const handleToggle = (e) => {
        e.preventDefault();
        toggleTheme();
    }

    return (
            <Link to="#" className={classes} onClick={handleToggle}>
                {
                    isDarkMode ?
                        <DarkModeSvg />
                    :
                        <LightModeSvg />
                }
            </Link>
        )
}

export default ThemeToggler
