import React from "react";


const ProductItem = ({item, isDarkMode, ...rest }) => {

    return ( 
        <div className={"order-lines__item product" + (isDarkMode && ' dark' || '')}>
            <div className={"data" + (isDarkMode && ' dark' || '')}>
                <img src={item?.product?.image} className="img-fluid" alt="" />
                <small className="product-name">{item?.product?.name}</small>
            </div>
        </div>
    )
}

export default ProductItem
