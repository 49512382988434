import React from "react";

import CustomerBirthProfiles from "./CustomerBirthProfiles";
import CustomerKundaliChart from "./CustomerKundaliChart";


const CustomerProfilesAndCharts = ({
    user, 
    setChartProfile, 
    chartProfile, 
    handleChangeProfile, 
    showKundali,
    setShowKundali,
    width, 
    isDarkMode, 
    ...rest
}) => {

    return (
        <div className={`profiles-and-charts ${isDarkMode && 'dark' || ''}`}>
            {showKundali && chartProfile && (
                <CustomerKundaliChart
                    user={user}
                    profile={chartProfile}
                    isDarkMode={isDarkMode}
                    handleChangeProfile={handleChangeProfile}
                    width={width}
                />
            )}

            {(!chartProfile || !showKundali) && (
                <CustomerBirthProfiles
                    user={user}
                    isDarkMode={isDarkMode}
                    setSelectedProfile={setChartProfile}
                    selectedProfile={chartProfile}
                    setShowKundali={setShowKundali}
                />
            )}
        </div>
    )
}

export default CustomerProfilesAndCharts;
