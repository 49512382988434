import React from "react";

import AmountItem from "./AmountItem";

const Amounts = ({ orderLines, order, tax}) => {
  return (
    <>
      <p className="separator d-none d-sm-block"></p>

      <div className="amounts">
        <div className="title d-sm-none">Order Summary</div>

        <AmountItem
          title={
            orderLines.length +
            (orderLines.length === 1 ? " Question" : " Questions")
          }
          //   title={`Questions ${orderLines.length}`}
          value={order.sub_total_formatted}
        />
        {tax && <AmountItem title={"Tax and Fees"} value={tax} />}

        {(order.discount_percent || null) && <AmountItem
          title={"Additional Discount"}
          value={`${order.discount_percent}%`}
        />}

        <AmountItem
          title={"Total:"}
          value={order.amount_formatted}
          classes={"all-total"}
        />
      </div>
    </>
  );
};

export default Amounts;
