import React from "react";

import {
  FreeAstrologySvgIcon,
  RealTimePredictionSvgIcon,
  ConnectAstrologerSvgIcon,
  ShopSvgIcon,
  ForumSvgIcon,
  CustomerSupportSvgIcon,
} from "src/core/component/assets/svg/CommonSVG";
import FeatureItem from "./FeatureItem";

const FeaturesCard = ({ handleRTPClick, isDarkMode,appSettings,astro_profile, ...rest }) => {
  let featuresData = [
    {
      title: "Free Astrology",
      description: "Get your free astrological readings here",
      url: "/free-astrology",
      buttonText: "Get Your Reading",
      pricing: "Free",
      icon: <FreeAstrologySvgIcon />,
      class: "free-astrology",
    },
    {
      title: "Instant Prediction",
      description: "Get instant predictions to your questions",
      url: "/instant-prediction",
      buttonText: "Get Predictions",
      pricing: "From $2.99 USD",
      // pricing: "Free for Now",
      icon: <RealTimePredictionSvgIcon />,
      class: "real-time-prediction",
      onClick: handleRTPClick,
    },
    astro_profile?.astrologer === true && {
      'title': 'Astrologer',
      'description': 'Chat Conversations',
      'url': '/astrologer',
      'buttonText': 'Start Here',
      'pricing': ``,
      'icon': <ConnectAstrologerSvgIcon />,
      'class': 'connect-an-astrologer'
  },
    astro_profile?.astrologer === false && {
        'code':1,
        'title': 'Connect to Astrologer',
        'description': 'Connect with our highly qualified team of astrologers',
        'url': '/connect-astrologer',
        'buttonText': 'Start Here',
        'pricing': 'From $1.00 USD',
        'icon': <ConnectAstrologerSvgIcon />,
        'class': 'connect-an-astrologer'
    },
    {
        'code':2,
        'title': 'Shop',
        'description': 'Enjoy the fabulous collection of our products',
        'url': '/shop',
        'buttonText': 'Shop Now',
        'pricing': 'From $11.1 USD',
        'icon': <ShopSvgIcon />,
        'class': 'shop'
    },
    {
      title: "Discussion Forum",
      description: "Get your questions answered along with everyone else's",
      url: "/forum",
      buttonText: "Ask A Question",
      pricing: "Free",
      icon: <ForumSvgIcon />,
      class: "forum",
    },
    {
      title: "Customer Support",
      description:
        "Need support? Just ping us and we will get back to you as soon as possible",
      url: "/support",
      buttonText: "Message Us",
      pricing: "Free",
      icon: <CustomerSupportSvgIcon />,
      class: "customer-support",
    },
  ];

  featuresData = featuresData.map(data=>data?.code?appSettings.includes(data.code) && data:data)
  featuresData = featuresData.filter(data=>data!=false)

  return (
    <div
      className={
        "row row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gx-3 pocket-features__cards" +
        ((isDarkMode && " dark") || "")
      }
    >
      {featuresData.map((item, index) => {
        return (
          <FeatureItem
            key={index}
            title={item.title}
            description={item.description}
            url={item.url}
            pricing={item.pricing}
            buttonText={item.buttonText}
            classes={item.class}
            icon={item.icon}
            isDarkMode={isDarkMode}
            onClick={item.onClick}
          ></FeatureItem>
        );
      })}
    </div>
  );
};

export default FeaturesCard;
