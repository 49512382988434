import React, {useState, useEffect} from "react";
import { useParams } from "react-router";

import H4Heading from "src/core/component/grid/H4Heading";
import OrderSummary from "./OrderSummary";
import SimpleAlert from "src/core/component/SimpleAlert";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import * as orderApi from 'src/lib/http/order';


const OrderDetail = ({path, isDarkMode, ...rest }) => {
    const params = useParams();
    const [orderId, setOrderId] = useState(null)
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(params['OrderId']){
            setOrderId(params.OrderId)
        }
    }, [params])

    useEffect(() => {
        if(orderId){
            fetchOrder(orderId)
        }
    }, [orderId])

    const fetchOrder = async (id) => {
        setLoading(true);
        try {
            const result = await orderApi.getOrderDetails(id);
            setOrder({...result?.data?.result?.data, 'type': 'question'});
        } catch (error) {
            setError('Failed to fetch a order!');
        } finally {
            setLoading(false);
        }
    }

    // console.log('Order Id', orderId)
    // console.log('Order Item', order)

    return ( 
        <div className={"order-detail" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Your Items`} 
            />

            <div className="row">
                <div className="col-12">
                    {loading && (
                        <LoadingIcon />
                    )}

                    {!loading && error && (
                        <SimpleAlert classes="alert-danger">
                            <p>{error}</p>
                        </SimpleAlert>
                    )} 

                    {!loading && order && (
                        <OrderSummary 
                            order={order}
                            isDarkMode={isDarkMode}
                        />
                    )}
                    
                </div>
            </div>

        </div>
    )
}

export default OrderDetail
