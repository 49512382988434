import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";


import api from 'src/lib/api';
import { NotificationSvg } from "src/core/component/assets/svg/CommonSVG";
import { useTheme } from "src/theme/component/ThemeContext";
import NotificationItem from "./NotificationItem";
import useNotification from "src/notification/hook/useNotification";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useSocket } from 'src/socket/component/SocketContext';
import LoadingIcon from "src/core/component/loader/LoadingIcon";


const Notifications = () => {
    const history = useHistory();
    const [isDarkMode] = useTheme();
    const socket = useSocket();
    const {isAuthenticated, profile} = useAuthInfo();
    const {markUserNotificationRead, deleteUserNotification, clearUserNotification} = useNotification();
    const [notificationData, setNotificationData] = useState([]);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [limit, setLimit] = useState(5);
    const [metaData, setMetaData] = useState({
        'has_next': false,
        'next': null,
    });

    useEffect(() => {
        const onNewNotification = ({ data }) => {
            if(data.notification?.recipient_id != profile?.id) {
                return;
            }

            setNotificationData((prevNotifications) => [data.notification, ...prevNotifications]);
        }

        const onNotificationRead = ({data}) => {
            if(data.notification?.recipient_id != profile?.id) {
                return;
            }
            
            setNotificationData(prevState => {
                const index = prevState.findIndex(n => n.id == data.notification.id);
                const notification = prevState[index];
                if (!notification) { return prevState; }
    
                notification.read = true;
                return [
                    ...prevState.slice(0, index),
                    notification,
                    ...prevState.slice(index + 1),
                ];
            });
        }

        // Notification event
        socket.on('notification:create', onNewNotification);
        socket.on('notification:read', onNotificationRead);
        
        return () => {
            socket.off('notification:create', onNewNotification);
            socket.off('notification:read', onNotificationRead);
        }
    }, [])

    useEffect(() => {
        if(isAuthenticated) {
            fetchUserNotifications();
        }
    }, [])

    const fetchUserNotifications = async () => {
        if (loadingNotifications) {
            return;
        }

        setLoadingNotifications(true);
        const query = {
            'page': metaData?.next,
            'limit': limit
        }

        try {
            const notificationResult = await api.get(`v1/notifications?${queryString.stringify(query, {skipEmptyString: true, skipNull: true})}`);
            const fetchedNotifications = notificationResult?.data?.result?.data || [];
            const fetchedMetaData = notificationResult?.data?.result?.metadata || null;
            
            setNotificationData((prevNotifications) => [...prevNotifications, ...fetchedNotifications]);
            setMetaData(fetchedMetaData);
            setLoadingNotifications(false);
        } catch (error) {
            let errorData = error?.response?.data?.error || error
            PocketToast({
                type: "error", 
                message: errorData?.message || "Can not fetch notifications."
            });
            setLoadingNotifications(false);
        }
    }

    const handleNotificationRedirect = (item) => {
        if(item?.data_payload?.redirect_url) {
            window.location.replace(item.data_payload.redirect_url);
        } else {
            if(item?.data_payload?.origin) {
                if(item.data_payload.origin === 'free-astrology') {
                    history.push('/free-astrology');
                } else if (item.data_payload.origin === 'realtime-prediction') {
                    history.push('/realtime-prediction');
                } else if (item.data_payload.origin === 'forum') {
                    if(item.data_payload.id) {
                        history.push(`/forum/${item.data_payload.id}`)
                    } else {
                        history.push('/forum');
                    }
                } else if (item.data_payload.origin === 'shop') {
                    history.push('/shop');
                } else if (item.data_payload.origin === 'support') {
                    history.push('/support');
                } else {
                    console.log('.')
                }
            }
        }
    }

    const handleClearAll = async () => {
        if(!notificationData.length) {
            return;
        }
        
        try {
            const res = await api.delete('v1/notifications/clear-all');
            setNotificationData(() => []);
            clearUserNotification();
        } catch(error) {
            PocketToast({
                type: "error", 
                message: "Can not clear notifications."
            });
        }
    }

    const handleDelete = async (item) => {
        console.log('Delete...', item)
        if(!item) return;

        try {
            const res = await api.delete(`v1/notifications/${item.id}`);
            deleteUserNotification(item.id);
            setNotificationData(prevState => ([
                ...prevState.filter(n=>n.id != item.id)
            ]));
        } catch(error) {
            let errorData = error?.response?.data?.error || error
            PocketToast({
                type: "error", 
                message: errorData?.message || "Can not delete notification."
            });
        }
    }

    const handleClick = async (item) => {
        console.log('Click...', item)
        if(!item) return;

        try {
            const res = await api.put(`v1/notifications/${item.id}/mark-read`);
            markUserNotificationRead(item);
            setNotificationData(prevState => {
                const index = prevState.findIndex(n => n.id == item.id);
                const notification = prevState[index];
                if (!notification) { return prevState; }
    
                notification.read = true;
                return [
                    ...prevState.slice(0, index),
                    notification,
                    ...prevState.slice(index + 1),
                ];
            });

        } catch(error) {
            // PocketToast({
            //     type: "error", 
            //     message: "Failed to mark notification as read."
            // });
            console.log('something went wrong.')
        }

        handleNotificationRedirect(item);
    }


    return (    
        <div className={`pocket-user__notifications ${isDarkMode && 'dark' || ''}`}>
            <div className={`pocket-user__notifications-header ${isDarkMode && 'dark' || ''}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="title">
                        Notifications
                    </div>
                    <div className="icon">
                        <NotificationSvg />
                    </div>
                </div>
            </div>

            <div id="notificationContainer" className={`pocket-user__notifications-main ${isDarkMode && 'dark' || ''}`}>
                {notificationData && metaData && notificationData.length > 0 && (
                        <InfiniteScroll
                            dataLength={notificationData.length}
                            next={fetchUserNotifications}
                            hasMore={metaData?.has_next || metaData?.next || false}
                            scrollableTarget="notificationContainer"
                            scrollThreshold={"5px"}
                            loader={<LoadingIcon />}
                            endMessage={<></>
                                // <p className="notification-exhusted" style={{ textAlign: "center" }}>
                                //     <b>No more notifications!</b>
                                // </p>
                            }
                        >
                            {notificationData.map(item=>{
                                return (
                                    <NotificationItem
                                        key={item.id}
                                        item={item}
                                        handleDelete={handleDelete}
                                        handleClick={handleClick}
                                        isDarkMode={isDarkMode}
                                    />
                                )
                            })}
                    </InfiniteScroll>

                ) || (
                    <>
                        {!loadingNotifications && (
                            <div className={`pocket-user__notifications-not-found ${isDarkMode && 'dark' || ''}`}>
                                <div className="text-center p-4 p-md-5">
                                    <div className="icon">
                                        <NotificationSvg />
                                    </div>
                                    <div className="title">
                                        There are no notifications to display
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {loadingNotifications && (
                    <div className="d-flex align-items-center justify-content-center">
                        <LoadingIcon />
                    </div>
                )}
            </div>

            

            <div className={`pocket-user__notifications-footer ${isDarkMode && 'dark' || ''}`} onClick={handleClearAll}>
                <div className="text-center">
                    Clear All
                </div>
            </div>
        </div>
    )
}

export default Notifications
