import React from 'react'
import CurrencySvg from "../../images/Collection";

const WalletReport = ({profile}) => {
  return (
    <div className='report'>
        <div className='report-title'>Transaction Report</div>
        <div className='report-stats'>
          <div className='report-stats-total'>
              <div className='report-stats-total-title'>Total Pocket Peso</div>
              <div className='report-stats-total-amount'>
              $0 <CurrencySvg/> <span>{parseFloat(profile?.wallet_balance?.dollar*profile?.wallet_balance?.exchange_rate).toFixed(2)} (0 PP Bonus incl. )</span>
              </div>
          </div>
          <div className='report-stats-spend'>
              <div className='report-stats-spend-title'>Total Spend</div>
              <div className='report-stats-spend-amount'>
              $0 <CurrencySvg/> <span>0 (0 PP Bonus incl. )</span>
              </div>
          </div>
        </div>
    </div>
  )
}

export default WalletReport