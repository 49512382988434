import React from "react"

const FooterCopyRight = ({isDarkMode, ...rest}) => {
  
  return (
        <div className={"w-100 text-center pocket-footer__copyright" + (isDarkMode && ' dark' || '' )}>
            <p className="copyright-text mb-0">
                <span>&copy;</span> PocketPandit, INC. All Rights Reserved
            </p>
        </div>
    )
}

export default FooterCopyRight