import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function ConfirmationModalTwo({
  className,
  show,
  onHide,
  text,
  textLink,
  hasIcon,
  icon,
  size,
  handleClose,
  handleProceed,
  isDarkMode,
  ...rest
}) {
  return (
    <Modal
      show={show}
      onHide={handleProceed}
      size={size}
      backdrop="static"
      keyboard={false}
      className={className + ((isDarkMode && " dark") || "")}
      centered
    >
      {hasIcon && (
        <Modal.Header className="justify-content-center">{icon}</Modal.Header>
      )}

      <Modal.Body>
        {text}{" "}
        {(textLink &&
          textLink.length > 0 &&
          textLink.map((link, i) => (
            <React.Fragment key={i}>{link}</React.Fragment>
          ))) ||
          textLink}
      </Modal.Body>
      <Modal.Footer>
        <Button className="prevent" onClick={handleClose}>
          Go Back
        </Button>
        <Button className="gogo" onClick={handleProceed}>
          Agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModalTwo;
