import React from "react";
import QuestionItem from "./QuestionItem";


const QuestionList = ({
    questions,
    handleQuestionSelect,
    profile,
    isDarkMode,
    ...rest}) => {

    handleQuestionSelect = typeof handleQuestionSelect === 'function' ? handleQuestionSelect : function noop() { }

    return (
        <div className="question-list rtp-layoutTwo m-0">
            <ol className={"list-group questions" + (isDarkMode && ' dark' || '')}>
                {
                    questions?.length > 0 && questions.map((item, index) => {

                        return (
                            <QuestionItem
                                key={index}
                                question={item}
                                handleQuestionSelect={handleQuestionSelect}
                                isDarkMode={isDarkMode}
                                profile={profile}
                            />
                        )
                    })
                }
            </ol>
        </div>
    )
}

export default QuestionList
