import React from "react";

import MobileFeaturesGrid from "./MobieFeaturesGrid";

const Features = ({ handleRTPClick, isDarkMode,appSettings,astro_profile, ...rest }) => {
  return (
    <div
      className={
        "container d-sm-none pocket-features__mobile" +
        ((isDarkMode && " dark") || "")
      }
    >
      <div
        className={
          "pocket-features__mobile__content" + ((isDarkMode && " dark") || "")
        }
      >
        <MobileFeaturesGrid handleRTPClick={handleRTPClick} appSettings={appSettings} astro_profile={astro_profile} />
      </div>
    </div>
  );
};

export default Features;
