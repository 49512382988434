import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import api from "src/lib/api";
import queryString from "query-string";
import MainLayout from "src/core/component/MainLayout";
import CenteredContainer from "src/core/component/grid/CenteredContainer";
import { useTheme } from "src/theme/component/ThemeContext";
import ForumList from "src/forumV2/ForumList";
import useFetch from "src/core/hook/useFetch";
import ForumDetailsV2 from "./forumDetail/ForumDetailsV2"
import RegisterLogin from "src/forum/component/registerlogin/RegisterLogin";
import { PocketToast } from "src/core/component/toast/PocketToast";
import useAuthInfo from "src/auth/hook/auth-info";
import "./forum.scss"

const ForumHome = () => {
  const { profile } = useAuthInfo();

  const [isDarkMode] = useTheme();
  const match = useRouteMatch();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isAscending,setIsAscending] = useState(false);
  const [currentFilter,setCurrentFilter] = useState('');
  const [postsLoading, setPostsLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const forumTabs = [{
    'id':1,
    'name':'Explore',
    'value':'explore'
  },
  {
    'id':2,
    'name':'Your Post',
    'value':'your_post'
  }]
  const [forumTab,setForumTab] = useState(forumTabs[0].value)


  const [filter, setFilter] = useState({
    category_id: "",
    query: "",
    state: "posted",
    page: currentPage,
    limit: limit,
    user_id:profile?.id || null
  });
  const { data: categoryResponse, loading: categoryLoading } = useFetch(
    `v1/question-category`,
    true
  );

  const fetchForumPosts = async (filter,isAscending,currentFilter) => {
    if (postsLoading) {return;}
    setPostsLoading(true);

    const apply_filter = {
      ...filter,
      filter_by: currentFilter,
      ascending: isAscending,
    }

    try {
      const result = await api.get(
        `v1/forum-posts-metrics?${queryString.stringify(apply_filter, {
          skipEmptyString: true,
          skipNull: true,
        })}`
      );
      const postsData = result.data?.result?.data || [];
      const metaInfos = result.data?.result?.metadata || null;

      setMetaData(metaInfos);
      if(filter.page == 1){
        setPosts(postsData);
      }else{  
        setPosts((prevPosts) => [...prevPosts, ...postsData]);
      }
      // setPostResponse(result)
    } catch (error) {
      PocketToast({type: "error",message: "Error on fetching forum posts",});
    } finally{ setPostsLoading(false)}
  };


  useEffect(() => {
    fetchForumPosts(filter,isAscending,currentFilter);
  }, [filter,forumTab]);

  useEffect(() => {
    if (!categoryLoading) {
      const categoryData = categoryResponse?.data?.result?.data;
      setCategories(categoryData);
    }
  }, [categoryResponse, categoryLoading]);

  return (
    <MainLayout className="forumV2-layout">
      <section className={"pocket-forumV2" + ((isDarkMode && " dark") || "")}>

        <CenteredContainer colClasses={"col-sm-12"}>
          <div className={"pocket-forumV2__contents" + ((isDarkMode && " dark") || "")}>
            <Switch>
              <Route exact path={`${match.path}`}
                render={(props) => (
                  <ForumList
                    {...props}
                    categories={categories}
                    categoryLoading={categoryLoading}
                    isDarkMode={isDarkMode}
                    setPosts={setPosts}
                    posts={posts}
                    isAscending = {isAscending}
                    setIsAscending = {setIsAscending}
                    currentFilter={currentFilter}
                    setCurrentFilter={setCurrentFilter}
                    filter={filter}
                    setFilter={setFilter}
                    forumTabs={forumTabs}
                    forumTab={forumTab}
                    setForumTab={setForumTab}
                    postsLoading={postsLoading}
                    metaData={metaData}
                    limit={limit}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/register-login`}
                render={(props) => (
                  <RegisterLogin {...props} isDarkMode={isDarkMode} />
                )}
              />
              <Route
                exact
                path={`${match.path}/:postId`}
                // path={`/forum/:postId`}
                render={(props) => (
                  <ForumDetailsV2 
                      props = {props}
                  />
                )}
              />
            </Switch>
          </div>
        </CenteredContainer>
      </section>
    </MainLayout>
  );
};

export default ForumHome;
