import React from "react";


const Dialog = (props) => {
  
  return props.accepted ? (<></>) : 
            (
            <section className={"pocket-dialog " + props.classes}>
                <div className="container-xl">
                        {props.children}
                </div>
            </section>
        )
}

export default Dialog
