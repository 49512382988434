import React from "react";
import ProductOrderSummary from "./ProductOrderSummary";
import QuestionOrderSummary from "./QuestionOrderSumary";


const OrderSummary = ({order, isDarkMode, isTracking=false, ...rest }) => {

    return (
        <div className={"order-summary" + (isDarkMode && ' dark' || '')}>
            <h5 className="title">
                Order Summary
            </h5>

            <p className="separator"></p>

            {order && order.type === 'product' && (
                <ProductOrderSummary
                    order={order}
                    isDarkMode={isDarkMode}
                    isTracking={isTracking}
                />
            )}

            {order && order.type === 'question' && (
                <QuestionOrderSummary
                    order={order}
                    isDarkMode={isDarkMode}
                />
            )}
        </div> 
    )
}

export default OrderSummary
