import React, {useState, useEffect} from "react";
import { useParams } from "react-router";

import api from 'src/lib/api';
import H4Heading from "src/core/component/grid/H4Heading";
import SimpleAlert from "src/core/component/SimpleAlert";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import ChatOrderSummary from "./ChatOrderSummary";
import { useTheme } from "src/theme/component/ThemeContext";
import { PocketToast } from "src/core/component/toast/PocketToast";


const ChatOrderDetails = ({path, ...rest }) => {
    const params = useParams();
    const [isDarkMode] = useTheme();
    const [order, setOrder] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(params['OrderId']){
            fetchOrder(params.OrderId);
            fetchInvoices(params.OrderId);
        }
    }, [params])

    const fetchOrder = async (id) => {
        setLoading(true);
        try {
            const result = await api.get(`/v1/conversations/${id}`);
            setOrder(result?.data?.result?.data || null);
        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching chat order."
            })
        } finally {
            setLoading(false);
        }
    }

    const fetchInvoices = async (id) => {
        setInvoiceLoading(true);
        try {
            const result = await api.get(`/v1/conversations/${id}/invoices`);
            setInvoices(result?.data?.result?.data || []);
        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching chat order invoices."
            })
        } finally {
            setInvoiceLoading(false);
        }
    }

    // console.log('Order Id', orderId)
    // console.log('Order', order);
    // console.log('Invoices', invoices)
    // console.log('Chat Order Details....');

    return ( 
        <div className={"order-detail" + (isDarkMode && ' dark' || '')}>
            <H4Heading 
                classes={"pocket-page-heading" + (isDarkMode && ' dark' || '')} 
                heading={`Conversation History`} 
            />

            <div className="row">
                <div className="col-12">
                    {(loading || invoiceLoading)  && (
                        <LoadingIcon />
                    )}

                    {!loading && !invoiceLoading && error && (
                        <SimpleAlert classes="alert-danger">
                            <p>{error}</p>
                        </SimpleAlert>
                    )} 

                    {!loading && !invoiceLoading && order && invoices && (
                        <ChatOrderSummary
                            conversation={order}
                            invoices={invoices}
                            isDarkMode={isDarkMode}
                        />
                    )}
                    
                </div>
            </div>

        </div>
    )
}

export default ChatOrderDetails
