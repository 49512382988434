import React, { useEffect,useReducer } from 'react'
import queryString from "query-string";
import api from 'src/lib/api';

const INITIAL_STATE = {
    questions:[],
    question_input:'',
    loading:true,
    error:null
}

const useQuestionBank = () => {
    const [questionBank,dispatch] = useReducer(questionBankReducer , INITIAL_STATE)

  const fetchQuestionBank = async (controller)=>{
    try {
        dispatch({type:'FETCH_QUESTION_BANK_REQUEST'})
        const res = await api.get(
            `v1/question_bank?${queryString.stringify({query:questionBank.question_input}, {
          skipEmptyString: true,
          skipNull: true,
        })}`
        ,{signal:controller.signal});
        if(res && res.data){
            dispatch({type:'FETCH_QUESTION_BANK_SUCCESS',payload:res?.data?.result?.data})
        }
        } catch(error){
        dispatch({type:'FETCH_QUESTION_BANK_FAILURE',payload:error})
        }
  }

  useEffect(()=>{
    const controller = new AbortController()
    fetchQuestionBank(controller);

    return () => {
      controller.abort()
    }

  },[questionBank.question_input])

  const questionInputChange = (question_input) => {
    // console.log("🚀 question_input:", question_input)
    dispatch({type:'QUESTION_INPUT_CHANGE',payload:question_input})
  }

  return { questionBank,questionInputChange }
}

const questionBankReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_QUESTION_BANK_REQUEST':
            return {
                ...state,
                loading:true,
            }
        case 'FETCH_QUESTION_BANK_SUCCESS':
            return {
                ...state,
                loading:false,
                questions:action.payload
            }
        case 'FETCH_QUESTION_BANK_FAILURE':
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case 'QUESTION_INPUT_CHANGE':
            return {
                ...state,
                question_input:action.payload
            }
        default:
            return state
    }
}

export default useQuestionBank