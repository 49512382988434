import React, {useState, useEffect} from "react";
import { ChangeProfileIcon, Reply2SVG,VerifiedSVG } from "src/core/component/assets/svg/CommonSVG";
import MomentRelativeTime from "src/core/component/moment-date-time/MomentRelativeTime";
import ForumReplyForm from "./ForumReplyForm";
import useAuthInfo from 'src/auth/hook/auth-info';
import NotAuthenticatedAlert from "./NotAuthenticatedAlert"
import SimpleAlert from "src/core/component/SimpleAlert";
import ForumReplyList from "src/forumV2/forumDetail/ForumReplyList";

const ForumReplyItemCard = ({
    item,
    post,
    replies,
    onClickReply,
    onClickReport,
    showReplyForm,
    onReplySubmit,
    onChange,
    replyData,
    loading,
    error,
    isDarkMode,
    maxLength,
    ...rest
}) => {
    const { isAuthenticated, profile } = useAuthInfo();

    let role = item.created_by?.admin && 'admin' || post.created_by?.id === item.created_by?.id && 'creator' || 'user';

    return (
        <div className='forum-reply-item'>
            <div className={"forum-card-body" + (isDarkMode && ' dark' || '')}>
                    <div className="forum-card-body-header">
                        <div className="image">
                            { item?.created_by?.avatar_url && <img src={item.created_by.avatar_url} /> || <ChangeProfileIcon />}
                        </div>
                        {/* <div  className={"reply-item__content-author" + (isDarkMode && ' dark' || '')}> */}
                        <div  className="title">
                                <div className="author-title">
                                    <div className="name">{item?.created_by?.display_name} <span>{role == 'admin' && <VerifiedSVG/> }</span></div>
                                    <div className="reply-name">
                                        {/* Replying to  */}
                                        <span>&nbsp;</span>
                                    </div>
                                </div>
                                <span className="time">  {item?.created_at && (
                                    <MomentRelativeTime datetime={item.created_at} />)}
                                </span>
                            </div>
                    </div>

                    <div className={"reply-item__content" + (item?.parent_reply_id!= null && ' parent-reply ' || '') + (isDarkMode && ' dark' || '')}>
                        <div className={item?.parent_reply_id== null && ' parent ' || ''}>
                            <div className={"reply-item__content-body" + (isDarkMode && ' dark' || '')}>
                                <p dangerouslySetInnerHTML={{__html: item.content}} />
                            </div>

                            <div className={"reply-item__content-footer" + (isDarkMode && ' dark' || '')}>
                                <div className="d-flex align-items-center links">
                                    <span className="replies d-flex align-items-center gap-2" onClick={onClickReply}>
                                        <Reply2SVG />
                                        <span className="reply-post">Reply</span>
                                    </span>
                                </div>
                            </div>

                            {showReplyForm && (
                                <div className="reply-item__content-reply-form">
                                    <div className={"forum-post-reply" + (isDarkMode && ' dark' || '')}>
                                        {!isAuthenticated && (
                                            <NotAuthenticatedAlert
                                                isDarkMode={isDarkMode}
                                                title={'Login or Sign up to add your reply.'}
                                            />
                                        )}

                                        {isAuthenticated && (
                                            <>
                                                {error && (
                                                    <SimpleAlert classes="alert-danger mb-3 p-2">
                                                        <p className="mb-0">{error}</p>
                                                    </SimpleAlert>
                                                )}

                                                <ForumReplyForm
                                                    onChange={onChange}
                                                    handleSubmit={onReplySubmit}
                                                    value={replyData['content']}
                                                    loading={loading}
                                                    isDarkMode={isDarkMode}
                                                    rows={"5"}
                                                    maxLength={maxLength}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {replies && replies.length > 0 && (
                            <div className="reply-item__content-childs">
                                <ForumReplyList
                                    postReplies={replies}
                                    post={post}
                                />
                            </div>
                        )}

                    </div>
            </div>
        </div>
    )
}

export default ForumReplyItemCard
