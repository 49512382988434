import React from "react"


const Banner = (props) => {
  
  return (
        <div className={"pocket-banner " + props.classes}>
            <div className="container-xl">
                {props.children}
            </div>
        </div>
    )
}

export default Banner
