import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "src/lib/api";
import "src/instant-prediction/scss/Auth.scss";
import SwiperCarousel from "../slider/SwiperCarousel";
import ProfileFilterItem from "../birthprofile/ProfileFilterItem";

const Auth = ({ authRoute, hasTitle = true, title = "", state = {}, hasFilter = true }) => {
  const [relationshipList, setRelationshipList] = useState([]);

  useEffect(() => {
    fetchRelationshipList().then();
  }, []);

  const settings = {
    slidesPerView: "auto",
    spaceBetween: 10,
  };

  const fetchRelationshipList = async () => {
    try {
      const response = await api.get("/v1/relationship");
      setRelationshipList(response?.data.result.data);
    } catch (error) {
      console.log("RTP Filter Error", error);
    }
  };

  // console.log('Authroute', authRoute)

  return (
    <div className="rtp-auth">
      {hasTitle && title && <h5 className="rtp-headingTwo">{title}</h5>}

      {
        hasFilter && (
          <div className="rtp-filter__select" style={{ marginBottom: "20px" }}>
            <SwiperCarousel
              {...settings}
              className="ms-0"
              items={relationshipList.map((relationship) => (
                <ProfileFilterItem
                  key={relationship.id}
                  classes="rtp-filter__item"
                  item={relationship}
                  handleClick={() => {}}
                />
              ))}
            />
          </div>
        )
      }

      <div className="rtp-auth__card no-auth">
        <div className="rtp-auth__text">
          <h4>Please Login / Signup to view your prediction!</h4>
          {/* <p>We need you to login before you can create birth profiles</p> */}
        </div>
        <div className="rtp-auth__buttons">
          <Link
            to={{
              pathname: authRoute,
              state: {
                ...state,
                tab: "register",
              },
            }}
            className="pocket-btn"
          >
            Sign Up
          </Link>
          <Link
            to={{
              pathname: authRoute,
              state: {
                ...state,
                tab: "login",
              },
            }}
            className="pocket-btn btn-two"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Auth;
