import React from "react"
import { Link } from "react-router-dom";
import { OverlayTrigger,Popover } from "react-bootstrap";
import {InfoIcon} from 'src/core/component/assets/svg/CommonSVG'
import SearchLocationInput from "src/core/component/SearchLocationInput";


const SearchLocation = ({
        property,
        value="",
        hasLabel=true,
        isSmall=true,
        hasPlaceholder=true, 
        hasInstruction=false,
        hasLink=false,
        hasIcon=false,
        label="",
        classes="", 
        instruction="",
        placeholder="",
        icon="",
        isInvalid=false,
        error=`*Please enter a valid ${label}`,
        onChange,
        onClick,
        disabled=false,
        ...res
    }) => {

    onClick = typeof onClick === 'function' ? onClick : function noop() { }

    const popover = (
        <Popover id="popover-basic" style={{zIndex:9999999}}>
          <Popover.Body>
            You are currently creating a <strong>Prashna Kundali</strong> (Question Chart)
          </Popover.Body>
        </Popover>
      );
  
    return (
        <>  
            {
                hasLabel && (
                    <>
                    <label className="form-label" htmlFor={property}>{label}</label>
                    {disabled==true?
                    <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                            <span>&nbsp;<InfoIcon/></span>
                    </OverlayTrigger>:null
                    }
                </>)
            }
            
            {
                hasIcon ? 
                <div className="input-group">
                    <SearchLocationInput disabled={disabled} type="text" className={"form-control " + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} id={property} name={property} value={value} placeholder={placeholder} onChange={onChange} required />
                    <span className="input-group-text">{icon}</span>
                    <div className="invalid-feedback">
                        {error}
                    </div>
                </div>
                :
                <>
                    <SearchLocationInput disabled={disabled} type="text" className={"form-control " + (isInvalid && " is-invalid" || "") + (isSmall && " form-control-sm")} id={property} name={property} value={value} placeholder={placeholder} onChange={onChange} required />
                    <div className="invalid-feedback">
                        {error}
                    </div>
                </>
            }

            {
                hasInstruction &&
                <div className="form-text">
                    {instruction}

                    {
                        hasLink &&
                        <Link to="#" onClick={onClick} className="form-text-link">
                            click here
                        </Link>
                    }
                </div>
            }
        </>
    )
}

export default SearchLocation
