import React from 'react'
import { Dropdown } from 'react-bootstrap';
import './forumDropdown.scss'

const ForumDropdown = ({items,selectedItem,itemClick,title="Filter by",value="Select"}) => {
  return (
    <Dropdown className='forum_dropdown'>
        <Dropdown.Toggle id="dropdown-basic">
        <div className='d-flex flex-column align-items-start'>
            <span className='title'>{title}</span>
            <span className='value'>{selectedItem?.name || value}</span>
        </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
        {
           items && items.length>0 && items.map((item)=>
            <Dropdown.Item key={item.id} onClick={()=>itemClick(item)}>{item.name}</Dropdown.Item>
            )
        }
        </Dropdown.Menu>
    </Dropdown>
  )
}

export default ForumDropdown