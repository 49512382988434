import React from 'react';

import PocketRatings from "src/core/component/rating/PocketRatings";


const AverageRatings = ({isDarkMode, average, chatCount, ...rest}) => {
    
  return (
        <>
            <div className={`pocket-average-rating d-flex align-items-center ${isDarkMode && 'dark' || ''}`}>
                <span className="title">Average Rating:</span>
                <div className="rating-star">
                    <PocketRatings
                        ratings={average}
                        isDarkMode={isDarkMode}
                        readOnly={true}
                    />
                </div>
                <div className="rating-value">
                    {`${average}/5`}
                </div>
            </div>
            <div className={`total-chats ${isDarkMode && 'dark' || ''}`}>
                <span>Total Chats: </span>{chatCount}
            </div>
        </>
    )
}

export default AverageRatings
