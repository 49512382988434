import React from 'react'

import api from 'src/lib/api'
import { useSocket } from 'src/socket';
import useAuthInfo from 'src/auth/hook/auth-info';
import useDependentState from 'src/core/hook/useDependentState';

import ChatMessageForm from './ChatMessageForm';
import ScrollView from 'src/core/component/ScrollView';

const Countdown = ({ datetime }) => {
  const [countdown, setCountdown] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  React.useEffect(() => {
    const counter = setInterval(recalculate, 1000);
    recalculate();


    return () => {
      clearInterval(counter);
    }
  }, [datetime]);

  const recalculate = () => {
    const finalDate = new Date(datetime).getTime();
    const diff = finalDate - new Date().getTime();

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const minutes = Math.floor(diff % (1000 * 60 * 60) / (1000 * 60));
    const seconds = Math.floor(diff % (1000 * 60) / 1000);

    setCountdown({
      days: days, hours: hours, minutes: minutes, seconds: seconds,
      expired: diff < 0,
    });
  }

  const { hours: h, minutes: m, seconds: s } = countdown;

  if (countdown.expired) { return null; }

  return (<>
    {countdown.days && `${countdown.days}D, ` || ''}{h > 9 && h || `0${h}`} : {m > 9 && m || `0${m}`} : {s > 9 && s || `0${s}`}
  </>)

}

export default function ChatMessageList({ conversation }) {

  const socket = useSocket();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const authInfo = useAuthInfo();
  const scrollableRef = React.useRef();
  const activeConversation = React.useRef(conversation.id);
  const [otherUser] = useDependentState((c) => {
    const [other] = c.users.filter(u => u.id != authInfo.user_id);
    return other;
  }, [conversation]);

  React.useEffect(() => {

    const handleNewMessage = ({ data: message, metadata }) => {
      onNewMessage(message, +metadata?.local_id);
    }

    socket.on('chat_message:create', handleNewMessage);

    return () => {
      socket.off('chat_message:create', handleNewMessage);
    }

  }, []);

  React.useEffect(() => scrollDown(), [items]);

  React.useEffect(() => {

    activeConversation.current = conversation.id;

    setLoading(true);
    setItems([]);
    api.get(`v1/conversations/${conversation?.name}/messages`)
      .then(res => res.data.result?.data || [])
      .then(setItems)
      .finally(() => setLoading(false));

  }, [conversation]);

  const onNewMessage = (message, localId) => {

    if (message.conversation_id != activeConversation.current) { return; }

    setItems(state => {

      const msgIndex = state.findIndex(s => s.id == message.id || s.id == localId);

      if (msgIndex === -1) {
        return [...state, message];
      } else {
        return [
          ...state.slice(0, msgIndex),
          message,
          ...state.slice(msgIndex + 1),
        ];
      }
    });


    scrollDown();
  }

  const handleErrorSend = (error, localId) => {
    setItems(state => {

      const msgIndex = state.findIndex(s => s.id == localId);

      if (msgIndex === -1) { return state; }

      return [
        ...state.slice(0, msgIndex),
        Object.assign(state[msgIndex], { error: error }),
        ...state.slice(msgIndex + 1),
      ];
    });
  }

  const scrollDown = () => {

    if (!scrollableRef.current) { return; }

    scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
  }

  console.log('Conversation', conversation)
  console.log('Items', items)

  return (
    <>
      <div style={{
        height: 'calc(100% - 65px)',
        position: 'relative',
        width: '100%',
      }}>

        {Date.now() < new Date(conversation.date_end).getTime() && (
          <div className="py-2" style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            background: 'rgba(255, 255, 255, 0.85)',
            zIndex: 1,
          }}>
            <h5 className="text-center text-success">
              <Countdown datetime={conversation.date_end} />
            </h5>
          </div>
        )}

        <ScrollView className="w-100 h-100 d-flex align-items-center justify-content-center" innerRef={scrollableRef}>

          {loading && <>
            <div className="text-center">

              <ul className="list-inline">
                <li className="list-inline-item">
                  <img width="80" src={authInfo?.profile?.avatar_url} className="rounded-circle img-thumbnail" />
                </li>
                <li className="list-inline-item" style={{ marginLeft: -(80 / 2) }}>
                  <img width="80" src={otherUser?.avatar_url} className="rounded-circle img-thumbnail" />
                </li>
              </ul>

              <h5 className="text-muted text-small my-3"> Laoding your conversation with  <strong>{otherUser?.name}</strong> ...</h5>
            </div>
          </>}

          {!loading && !items.length && (<div className="text-center">

            <ul className="list-inline">
              <li className="list-inline-item">
                <img width="80" src={authInfo?.profile?.avatar_url} className="rounded-circle img-thumbnail" />
              </li>
              <li className="list-inline-item" style={{ marginLeft: -(80 / 2) }}>
                <img width="80" src={otherUser?.avatar_url} className="rounded-circle img-thumbnail" />
              </li>
            </ul>

            <h5 className="text-muted text-small my-3"> Start messaging with  <strong>{otherUser?.name}</strong></h5>
          </div>)}


          <ul className="chat-message-list list-unstyled w-100 my-0" style={{
            position: 'absolute',
            bottom: 0,
            // height: '100%',
            maxHeight: '100%',
            overflowY: 'visible',
          }}>

            {(!loading && items.length || null) && (<li className="w-100 p-3" style={{ alignSelf: 'flex-start' }}>
              <div className="text-center">

                <ul className="list-inline">
                  <li className="list-inline-item">
                    <img width="80" src={authInfo?.profile?.avatar_url} className="rounded-circle img-thumbnail" />
                  </li>
                  <li className="list-inline-item" style={{ marginLeft: -(80 / 2) }}>
                    <img width="80" src={otherUser?.avatar_url} className="rounded-circle img-thumbnail" />
                  </li>
                </ul>

                <ul className="list-unstyled text-muted text-small">
                  <li>
                    Your conversation with  <strong>{otherUser?.name}</strong>
                  </li>
                  <li>Started on : {new Date(conversation.created_at).format('dd MMM, yyyy hh:mm tt')}</li>
                </ul>
              </div>
            </li>)}

            {items.map((item, i) => {
              const isReply = item.user_id != authInfo?.profile?.id;
              const prevItem = items[i - 1];

              const timeGrouped = !prevItem || new Date(prevItem.created_at).format('yyyy-MM-dd:HH') != new Date(item.created_at).format('yyyy-MM-dd:HH')

              let timeGroupHeader;

              if (new Date(item.created_at).format('yyyy') != new Date().format('yyyy')) {
                timeGroupHeader = new Date(item.created_at).format('MMM dd yyyy, h tt');
              }
              else if (new Date(item.created_at).format('yyyy-MM-dd') != new Date().format('yyyy-MM-dd')) {
                timeGroupHeader = new Date(item.created_at).format('MMM dd, h tt');
              } else {
                timeGroupHeader = new Date(item.created_at).format('h tt');
              }

              return (
                <React.Fragment key={item.id}>

                  {
                    timeGrouped && (
                      <li className={i === 0 ? 'mb-5 mt-1' : 'my-5'}>
                        <h6 className="mx-auto" style={{
                          width: '85%',
                          textAlign: 'center',
                          borderBottom: '1px solid #eaeaea',
                          lineHeight: '0.1em',
                          margin: '10px 0 20px',
                        }}>
                          <span className="py-1 px-3 text-small" style={{
                            backgroundColor: '#fff',
                            color: '#c0c0c0',
                          }}>{timeGroupHeader}</span>
                        </h6>
                      </li>
                    )}

                  <li className={"clearfix chat-message" + (isReply && ' chat-message-reply' || '')}>
                    <div className={"d-flex px-3 py-1" + (isReply && ' float-start' || ' float-end')}>

                      {(timeGrouped || prevItem?.user_id != item.user_id) && (
                        <div className="flex-shrink-0">
                          <img className="align-self-center rounded-circle" width="28" src={item.user.avatar_url} />
                        </div>
                      ) || (
                          <div className="d-inline-block" style={{ width: 28 }}></div>
                        )}
                      <div className={"ms-3 px-3 py-1 rounded-pill" + (isReply && '' || ' float-end') + (item.error ? ' bg-danger text-white' : (
                        isReply && ' bg-light' || ' bg-primary text-white'
                      ))}>

                        <span title={new Date(item.created_at).format('hh:mm:ss tt')}>
                          {item.content}
                        </span>

                      </div>
                    </div>
                  </li>

                  {item.error && (
                    <li className="clearfix">
                      <p className={'text-small text-danger px-3' + (isReply ? ' float-start' : ' float-end')}>
                        <i className="fa fa-fw fa-exclamation-circle"></i>
                        {item.error}
                      </p>
                    </li>
                  )}

                </React.Fragment>
              )
            })}
          </ul>
        </ScrollView>
      </div>

      <ChatMessageForm
        conversation={conversation}
        onSent={onNewMessage}
        onError={handleErrorSend}
      />
    </>
  )
}
