import React from "react";

import * as orderStateIcon from 'src/core/component/assets/svg/OrderIconSVG';
import OrderStateItem from "./OrderStateItem";


const OrderState = ({order, isDarkMode, ...rest }) => {

    let states = [
        {
            name: 'Order Placed',
            description: 'We have received your order',
            icon: <orderStateIcon.OrderPlacedSvg />,
            isActive: false
        },
        {
            name: 'Order Processed',
            description: 'We are processing your order',
            icon: <orderStateIcon.OrderProcessedSvg />,
            isActive: false
        },
        {
            name: 'Payment Confirmed',
            description: 'Confirming Payment',
            icon: <orderStateIcon.OrderPaymentConfirmedSvg />,
            isActive: false
        },
        {
            name: 'Order Packed',
            description: `Order Id: #${order.id}`,
            icon: <orderStateIcon.OrderPackedSvg />,
            isActive: true
        }
    ];

    return (
        <div className={"order-track__states" + (isDarkMode && ' dark' || '')}>

            <div className="d-flex align-items-center justify-content-between header">
                <div className="order-date">
                    {order?.date && new Date(order.date).format('MMM dd, yyyy hh:mm tt')}
                </div>
                <div className="order-price">
                    {order.price}
                </div>
            </div>


            <div className="d-flex align-items-center justify-content-between">
                {
                    states && states.map((item, index) => {
                        return (
                            <OrderStateItem
                                key={index}
                                item={item}
                                order={order}
                                isDarkMode={isDarkMode}
                            />
                        )
                    })
                }
            </div>

            {/* <div className="row gx-3">
                {
                    states && states.map((item, index) => {
                        return (
                            <div className="col-3" key={index}>
                                <OrderStateItem
                                    item={item}
                                    order={order}
                                    isDarkMode={isDarkMode}
                                />
                            </div>
                        )
                    })
                }
            </div> */}
        </div>
    )
}

export default OrderState
