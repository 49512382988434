import { useDispatch, useSelector } from "react-redux";
import {
    selectProfile as profileSelectionAction,
    setEnterDateTime as setEnterDateTimeAction,
    clearAstroInfo as AstroInfoClearAction
} from 'src/freeastrology/redux';


const DEFAULT_PROFILE = {
  'address': 'Middletown, DE, USA',
  'latitude': '39.449556',
  'longitude': '-75.7163207',
  'timezone': 'America/New_York',
  'is_default': true
}  


export default function useFreeAstrology() {
  const dispatch = useDispatch();
  const astroInfo = useSelector(state => state.freeAstro);
  const authInfo = useSelector(state => state.auth);

  const _astroSelectedBirthProfile = () => {
    if(astroInfo.birthProfile){
      return astroInfo.birthProfile
    }
    return null
  }

  const _astroDatetime = () => {
    return astroInfo.datetime;
  }

  const selectProfile = (profile) => {
    dispatch(profileSelectionAction(profile));
  }

  const setupAstroBirthProfile = () => {
    const primaryProfile = (authInfo?.birthProfiles || []).filter(bp => bp.is_primary == true);
    if(!astroInfo?.birthProfile || astroInfo?.birthProfile?.is_default) {
      if(primaryProfile.length) {
        selectProfile(primaryProfile[0])
      } else if((authInfo?.birthProfiles || []).length) {
          selectProfile(authInfo?.birthProfiles[0])
      } else { 
        selectProfile(DEFAULT_PROFILE)
      }
    }
  }

  const selectBirthProfileById = (id) => {
    const selected = (authInfo?.birthProfiles || []).filter(bp => bp.id == id);

    if (!selected.length) {
      return;
    }

    selectProfile(selected[0]);
  }

  const setEnterDateTime = () => {
    dispatch(setEnterDateTimeAction())
  }

  const clearAstroInfo = () => {
    dispatch(AstroInfoClearAction());
  }

  return {
    birthProfile: astroInfo.birthProfile,

    get astroBirthProfile() {
      return _astroSelectedBirthProfile();
    },

    get astroDatetime() {
      return _astroDatetime();
    },

    selectProfile,
    selectBirthProfileById,
    setupAstroBirthProfile,
    setEnterDateTime,
    clearAstroInfo
  };
}
