import React from "react";

import { ProfileImgSvg } from "src/core/component/assets/svg/ProfileSettingSVG";

const CircleImage = ({
    url, 
    showIconOverlay=false,
    onClick,
    isOnline=false,
    showIsOnline=false,
    isDarkMode, 
    ...rest 
}) => {

    onClick = typeof onClick === 'function' ? onClick : function noop() { }

    return ( 
        <div className={"image-circle" + (isDarkMode && ' dark' || '')} style={{'backgroundImage': `url(${url})`}}>
            {showIconOverlay && (
                <div className="overlay">
                    <div className="icon" onClick={onClick}>
                        <ProfileImgSvg />
                    </div>
                </div>
            )}

            {showIsOnline && (
                <span className={"is-online" + (isOnline && ' yes' || ' no')}>
                </span>
            )}
        </div>
    )
}

export default CircleImage
