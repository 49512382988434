import React, {useState} from "react"
import Modal from 'react-bootstrap/Modal';


const ModalBody = ({classes="", children, ...rest}) => {
  
  return (
        <Modal.Body className={classes}>
            {children}
        </Modal.Body>
    )
}

export default ModalBody
