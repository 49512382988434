import React, { useState, useContext } from "react";
// import { Link } from "react-router-dom";

import { useTheme } from "src/theme/component/ThemeContext";
// import FooterAppLink from "./FooterAppLink";
import FooterCopyRight from "./FooterCopyRight";
import FooterNav from "./FooterNav";
import MobileFooter from "./mobile/MobileFooter";
// import Logo from "src/image/navigation_icons/final-logo.png";

const Footer = () => {
  const [page, setPage] = useState("home");
  const [clicked, setClicked] = useState("home");
  const [isDarkMode, toggleTheme] = useTheme();

  const handleClicked = (clickedLink) => {
    setClicked(clickedLink);
  };

  return (
    <>
      <section className={"pocket-footer" + ((isDarkMode && " dark") || "")}>
        {/* <div className="container-xl d-none d-sm-block"> */}
        <div className="container-xl">
          <div className="pocket-footer__top">
            {/*
                <Link to="/" className="d-inline-block d-sm-none">
                  <img src={Logo} alt="Pocket Pandit" />
                </Link>
              */}

            <FooterNav page={clicked} isDarkMode={isDarkMode} />
          </div>

          <div
            className={
              "w-100 pocket-footer__separator" + ((isDarkMode && " dark") || "")
            }
          ></div>

          <div className="pocket-footer__bottom">
            <FooterCopyRight isDarkMode={isDarkMode} />

            {/* <FooterAppLink isDarkMode={isDarkMode} /> */}
          </div>
        </div>
      </section>

      {/* Mobile View Footer */}
      <MobileFooter
        page={clicked}
        handleClicked={handleClicked}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default Footer;
