import React, {useState, useEffect} from "react";
import { Route, Switch, useRouteMatch, useHistory, useLocation  } from "react-router-dom";

import api from 'src/lib/api';
import MainLayout from 'src/core/component/MainLayout';
import { useTheme } from "src/theme/component/ThemeContext";
import CenteredContainer from "src/core/component/grid/CenteredContainer";
import BannerInput from "src/core/component/banner/BannerInput";
import AstrologerList from "src/connect-with-astrologer/component/astrologer-list/AstrologerList";
import { useModal } from "src/core/hook/useModal";
import FilterModal from "src/connect-with-astrologer/component/setup-modals/FilterModal";
import CustomerChat from "src/connect-with-astrologer/component/customer-chat/CustomerChat";


const ConnectAstrologerHome = () => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const [isDarkMode] = useTheme();
    const [showModal, handleModalShow, handleModalClose] = useModal(false);

    const [filter, setFilter] = useState({
    'rating': 0,
    'status': 'online'
    })
    const [order, setOrder] = useState('high_to_low');

    // console.log('filter', filter);
    // console.log('order', order);

    return (
        <MainLayout>
            <section className={`pocket-astrologer__chat ${isDarkMode && 'dark' || ''}`}>
                <BannerInput 
                    isDarkMode={isDarkMode} 
                    url={match.path} 
                    hasSearch={location.pathname === match.path ? true : false} 
                    hasFilter={location.pathname === match.path ? true : false} 
                    handleFilterClick={handleModalShow}
                    title={location.pathname === match.path ? 'Select an astrologer' : 'Chat with astrologer'} 
                    placeholder={'Search astrologer by name...'} 
                />
                
                <CenteredContainer classes={"overflow-hidden"} colClasses={'col-sm-12'}>
                    <div className={"pocket-astrologer__chat-contents" + (isDarkMode && ' dark' || '')}>
                        <Switch>
                            <Route  
                                exact
                                path={`${match.path}`}  
                                render={props => (
                                    <AstrologerList
                                        {...props}
                                        isDarkMode={isDarkMode}
                                        match={match}
                                        astroFilter={filter}
                                        order={order}
                                    />
                                )}
                            />

                            <Route  
                                exact
                                path={`${match.path}/chat`}  
                                render={props => (
                                    <CustomerChat 
                                        {...props}
                                        match={match}
                                        isDarkMode={isDarkMode}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </CenteredContainer>
            </section>

            {/* Filter Modal */}
            <FilterModal
                handleModalShow={handleModalShow}
                handleModalClose={handleModalClose}
                showModal={showModal}
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
                isDarkMode={isDarkMode}
            />
        </MainLayout>
    )
}

export default ConnectAstrologerHome;
