import React from "react";
import { Link } from "react-router-dom";
import FormCheckboxSwitch from "../form/FormChekboxSwitch";

const CardBody = ({
  classes = "",
  isPromos = false,
  isMobile = false,
  hasLink = false,
  isfeatures = false,
  hasSwitch = false,
  hasLabel = false,
  title = "",
  discount = "",
  description = "",
  url = "#",
  linkText = "",
  subtitle = "",
  occasion = "",
  pricing = "",
  switchLabel = "",
  switchProperty = "",
  checked = false,
  item = null,
  onChange,
  onClick,
  ...rest
}) => {
  return (
    <div className={"card-body" + classes}>
      {title && <div className="card-title">{title}</div>}
      <div className="card-text">
        {subtitle && (
          <p className="subtitle">
            {subtitle}
            <span className="text-lead ms-1">{occasion}</span>
          </p>
        )}
        {discount && isMobile && (
          <span className="text-lead discount">
            {item?.discount_percent}&#x25; off on{" "}
          </span>
        )}
        {description && description}
      </div>
      {hasLink && (
        <Link to={url} className="card-link" onClick={onClick}>
          {linkText}
        </Link>
      )}

      {isfeatures && pricing && <div className="pricing">{pricing}</div>}

      {hasSwitch && (
        <div className="switch">
          <FormCheckboxSwitch
            hasLabel={hasLabel}
            label={switchLabel}
            property={switchProperty}
            checked={checked}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

CardBody.defaultProps = {
  onChange: () => null,
};

export default CardBody;
