import React from "react";

import CategorySlider from "src/core/component/category/CategorySlider";
import RelationshipQuestions from "./RelationshipQuestions.jsx";
import ViewMoreLink from "src/promos/component/promodetails/ViewMoreLink";
import QuestionList from "src/core/component/question/QuestionList";


const RelationshipContents = ({
    promo, 
    categories, 
    questions,
    categorySelected,
    handleQuestionSelect,
    profile,
    handleCategorySelect,
    isCategorySelected,
    handleBrowseMore,
    handleGetPredictionClick, 
    isDarkMode, 
    processing=false,
    isAuthenticated,
    ...rest
}) => {

    let categoryQuestions = questions && categorySelected && questions.filter(q=>categorySelected.map(c=>c.id).includes(q.category_id));

    let questionCategoryIds = questions && questions.map(q=>q.category_id) || [];
    let relationshipCategories = questionCategoryIds && categories && categories.filter(c=>questionCategoryIds.includes(c.id)) || [];

    return (
        <div className="row justify-content-center">
            {relationshipCategories && relationshipCategories.length > 0 && (
                <div className="col-12 col-lg-11 col-xl-10">
                    <CategorySlider
                        categories={relationshipCategories || []}
                        handleCategorySelect={handleCategorySelect}
                        isCategorySelected={isCategorySelected}
                        isDarkMode={isDarkMode}
                    />

                    <div className="category-questions d-none d-sm-block">
                        <RelationshipQuestions
                            categories={categorySelected}
                            questions={questions}
                            handleQuestionSelect={handleQuestionSelect}
                            profile={profile}
                            handleBrowseMore={handleBrowseMore}
                            isDarkMode={isDarkMode}
                        />
                    </div>

                    <div className="category-questions d-sm-none">
                        <QuestionList
                            questions={categoryQuestions && categoryQuestions.slice(0,5) || []}
                            handleQuestionSelect={handleQuestionSelect}
                            profile={profile}
                            isDarkMode={isDarkMode}
                        />
                        
                        {categoryQuestions && categoryQuestions?.length > 5 && (
                            <ViewMoreLink 
                                title={'view more questions'}
                                url={'/realtime-prediction?stage=question'}
                                onClick={handleBrowseMore}
                                className={`d-flex justify-content-end`}
                                isDarkMode={isDarkMode}
                            />
                        )}

                    </div>

                    {isAuthenticated && (
                        <div className="get-prediction d-flex justify-content-end mt-3 mt-sm-0">
                            <button className={`pocket-btn get-prediction`} onClick={handleGetPredictionClick}>
                                {processing && 'Processing...' || 'Get Prediction'}
                            </button>
                        </div>
                    )}

                </div>
            )}
        </div>
    )
}

export default RelationshipContents
