import React, { useState } from "react";

import { PocketToast } from "src/core/component/toast/PocketToast";

import "../scss/Category.scss";

const Category = ({ className, category, handleCategorySelect, style={} }) => {
  const handleCategoryClick = (e) => {
    handleCategorySelect(e, category);
  };

  return (
    <div className={className} role="button" onClick={handleCategoryClick} style={style}>
      <img
        src={category.icon_url}
        alt={category.name.slice(0, 1)}
        style={{ color: category.color }}
      />
      <span>{category.name}</span>
    </div>
  );
};

export default Category;
