import React from "react";
import { Link } from "react-router-dom";

import Card from "src/core/component/card/Card";


const GeneralPromoCard = ({ 
    item,
    isDarkMode, 
    isDragging=false, 
 }) => {
  
  return (
        <Link to={(isDragging && "#") || `promo/${item.id}`} key={item.id}>
            <Card classes={"general-promo"}>
                <img src={item.image_url} alt="" />
            </Card>
        </Link>
    );
};

export default GeneralPromoCard;
