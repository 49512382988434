import React from "react";

import useRTPInfo from "src/realtimeprediction/hook/useRTPInfo";
import useAuthInfo from "src/auth/hook/auth-info";
import {CartSvg} from "../assets/svg/CommonSVG";

import "src/instant-prediction/scss/Question.scss";

const QuestionItem = ({
  question,
  handleQuestionSelect,
  profile,
  isDarkMode = false,
  isSelectable = true,
  ...rest
}) => {
  const { isQuestionInCart } = useRTPInfo();
  const {isAuthenticated} = useAuthInfo();

  handleQuestionSelect =
    typeof handleQuestionSelect === "function"
      ? handleQuestionSelect
      : function noop() {};

  return (
    /*<li className={"list-group-item" + (isQuestionInCart(question.id, profile?.id) && ' selected' || '') + (isDarkMode && ' dark' || '')} onClick={(e) => {handleQuestionSelect(e, question)}}>
        <div className="question-item d-flex justify-content-between align-items-center">
            {question.name}

            <p className="pricing">
                {
                    question.original_price && question.original_price !== question.price &&
                    <span className="display-price">{question.original_price}</span>
                }

                <span className="price">{question.price}</span>
            </p>
        </div>

        {profile && isQuestionInCart(question?.id, profile?.id) && (
          <AlreadyInCart />
        )}

    </li>*/
    <div
      className={`rtp-question ${
        isQuestionInCart(question.id, profile?.id) ? "selected" : ""
      }`}
      role="button"
      onClick={(e) => handleQuestionSelect(e, question)}
      style={{
        pointerEvents: !isSelectable ? "none" : "all",
      }}
    >
      <div className="rtp-question__tp">
        <div className="rtp-question__title">
          <p>{question.name}</p>
        </div>
        <div className="rtp-question__price">
          <span>{[0,0.0].includes(question.price)?'FREE TRIAL':question.price_formatted }</span>
          {question.original_price !== question.price && (
            <del>{question.original_price_formatted}</del>
          )}

        </div>
      </div>

      {isAuthenticated && profile && (
        <div className="rtp-question__btm">
          <span
            className={
              "rtp-question__toggle" +
              (isQuestionInCart(question.id, profile?.id)
                ? " remove"
                : " add")
            }
            role="button"
          >
            {isQuestionInCart(question.id, profile?.id)
              ? " remove "
              : " add "}
            question
          </span>
          <CartSvg />
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
