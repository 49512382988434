import React from "react";

import { useTheme } from "src/theme/component/ThemeContext";
import AccordionBody from "src/core/component/accordion/AccordionBody";
import AccordionCommon from "src/core/component/accordion/AccordionCommon";
import AccordionHeader from "src/core/component/accordion/AccordionHeader";
import AccordionItem from "src/core/component/accordion/AccordionItem";
import Answer from "./Answer";

const Questions = ({
    category,
    order,
    metaData,
    parentWidth,
    orderLines,
    emailHandler,
    emailProcessing,
    setEmailProcessing,
    setKundaliImages,
    ...rest
}) => {
    const categoryWiseLines = orderLines?.length > 0 && orderLines.filter(o => o?.question?.category_id==category?.id)
    const defaultActive = categoryWiseLines.length === 1 ? 0 : '';
    const [isDarkMode] = useTheme();

    return (
        <>
            {categoryWiseLines && categoryWiseLines.length > 0 && (
                <AccordionCommon classes={'answer-accordion' + (isDarkMode && ' dark' || '')} defaultActiveKey={defaultActive}>
                    {
                        categoryWiseLines.map((item, index) => {
                            return (
                                    <React.Fragment key={index}>
                                        <AccordionItem id={index} classes={'answer-accordion__item' + (isDarkMode && ' dark' || '')}>
                                            <AccordionHeader title={item.name} classes={isDarkMode && ' dark' || ''}/>

                                            <AccordionBody classes={isDarkMode && ' dark' || ''}>
                                                <Answer
                                                    line={item}
                                                    order={order}
                                                    metaData={metaData}
                                                    newKundaliDatas = {item}
                                                    parentWidth={parentWidth}
                                                    isDarkMode={isDarkMode}
                                                    emailHandler={emailHandler}
                                                    emailProcessing = {emailProcessing}
                                                    setEmailProcessing = {setEmailProcessing}
                                                    setKundaliImages = {setKundaliImages}
                                                />
                                            </AccordionBody>

                                        </AccordionItem>
                                    </React.Fragment>
                            )
                        })
                    }
                </AccordionCommon>
            )}

            {/* {(!categoryWiseLines || !categoryWiseLines?.length > 0) && (
                <div className="alert alert-danger not-found" role="alert">
                    Sorry! no questions are bought from this category
                </div>
            )} */}
        </>
    )
}

export default Questions
