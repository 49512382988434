const calcSection = (x1, y1, x2, y2, m = 8, n = 5) => [((m * x2 + n * x1) / (m + n)), ((m * y2 + n * y1) / (m + n))];

export const kundaliLines = (width, height) => {
  const [midX, midY] = [width / 2, height / 2];
  const [x1, y1] = calcSection(0, 0, midX, midY);
  const [x2, y2] = calcSection(width, 0, midX, midY);
  const [x3, y3] = calcSection(0, height, midX, midY);
  const [x4, y4] = calcSection(width, height, midX, midY);

  return [
    { move: [midX, 0], curve: [midX, 60, midX - 90, 30, x1, y1] },
    { move: [midX, 0], curve: [midX, 60, midX + 90, 30, x2, y2] },

    { move: [midX, height], curve: [midX, height - 60, midX - 90, height - 30, x3, y3] },
    { move: [midX, height], curve: [midX, height - 60, midX + 90, height - 30, x4, y4] },

    { move: [0, midY], curve: [0 + 80, midY, x1 - 80, y1, x1, y1] },
    { move: [0, midY], curve: [0 + 80, midY, x3 - 80, y3, x3, y3] },

    { move: [width, midY], curve: [width - 80, midY, x2 + 80, y2, x2, y2] },
    { move: [width, midY], curve: [width - 80, midY, x4 + 80, y4, x4, y4] },

    // Border lines
    { move: [0, 0], line: [width, 0] }, // Border top
    { move: [0, 0], line: [0, height] }, // Border left
    { move: [0, height], line: [width, height] }, // Border bottom
    { move: [width, height], line: [width, 0] }, // Border right
    { move: [width, height], line: [0, 0] }, // Diagonal top-left to bottom-right
    { move: [width, 0], line: [0, height] }, // Diagonal top-right to bottom-left
  ];
}

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  fontWeight: 700,
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(8 * scale, 12),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
});

const codeStyle = scale => ({
  fontSize: Math.max(8 * scale, 10),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      height: 66 * scale,
      width: 96 * scale,
      top: 47 * scale,
      left: 152 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 33 * scale,
      left: 194 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 120 * scale,
      left: 192 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      height: 37 * scale,
      width: 100 * scale,
      top: 14 * scale,
      left: 55 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 100 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 62 * scale,
      left: 107 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      height: 95 * scale,
      width: 55 * scale,
      top: 38 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 24 * scale,
      left: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 73 * scale,
      left: 93 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      height: 82 * scale,
      width: 78 * scale,
      top: 104 * scale,
      left: 73 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 90 * scale,
      left: 105 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 132 * scale,
      left: 178 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      height: 88 * scale,
      width: 55 * scale,
      bottom: 32 * scale,
      left: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 122 * scale,
      left: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 72 * scale,
      left: 93 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      height: 44 * scale,
      width: 102 * scale,
      bottom: 0 * scale,
      left: 58 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 46 * scale,
      left: 105 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 62 * scale,
      left: 106 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      height: 64 * scale,
      width: 98 * scale,
      bottom: 44 * scale,
      left: 151 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 110 * scale,
      left: 195 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 120 * scale,
      left: 192 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      height: 44 * scale,
      width: 120 * scale,
      bottom: 0 * scale,
      right: 52 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 46 * scale,
      right: 105 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 61 * scale,
      right: 106 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      height: 90 * scale,
      width: 55 * scale,
      bottom: 31 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      bottom: 122 * scale,
      right: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 73 * scale,
      right: 93 * scale
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      height: 82 * scale,
      width: 78 * scale,
      top: 104 * scale,
      right: 73 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 90 * scale,
      right: 105 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      bottom: 132 * scale,
      right: 178 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      height: 95 * scale,
      width: 55 * scale,
      top: 39 * scale,
      right: 0 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 26 * scale,
      right: 23 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 73 * scale,
      right: 93 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      height: 37 * scale,
      width: 100 * scale,
      top: 14 * scale,
      right: 55 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      right: 100 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 15 * scale,
      width: 15 * scale,
      top: 61 * scale,
      right: 106 * scale,
    }
  }
];
