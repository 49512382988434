import React from "react"

export const OrderPackedSvg = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.70757 2.45831C4.84552 2.2191 5.15127 2.137 5.39049 2.27495L14.3905 7.46495C14.6297 7.6029 14.7118 7.90865 14.5738 8.14787C14.4359 8.38709 14.1301 8.46918 13.8909 8.33123L4.89093 3.14123C4.65171 3.00328 4.56962 2.69753 4.70757 2.45831Z" fill="#1D5DC2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.39162 0.33436C8.77143 0.115313 9.20217 0 9.64062 0C10.0791 0 10.5098 0.115303 10.8896 0.334331C10.8899 0.334532 10.8903 0.334734 10.8906 0.334936L17.8906 4.33493C18.2703 4.55414 18.5856 4.86933 18.805 5.24889C19.0244 5.62845 19.1402 6.05903 19.1406 6.49744V14.4979C19.1402 14.9364 19.0244 15.3674 18.805 15.747C18.5856 16.1266 18.2703 16.4418 17.8906 16.661L17.8887 16.6621L10.8906 20.661C10.8902 20.6612 10.8898 20.6615 10.8893 20.6617C10.5096 20.8806 10.079 20.9959 9.64062 20.9959C9.20226 20.9959 8.7716 20.8806 8.39185 20.6617C8.39144 20.6614 8.39103 20.6612 8.39062 20.661L1.39256 16.6621L1.39063 16.661C1.01095 16.4418 0.6956 16.1266 0.476202 15.747C0.256804 15.3674 0.141075 14.9369 0.140625 14.4985L0.140625 6.49795C0.141075 6.05954 0.256804 5.62845 0.476202 5.24889C0.695601 4.86933 1.01095 4.55414 1.39063 4.33494L1.39255 4.33382L8.39062 0.334936C8.39096 0.334744 8.39129 0.334552 8.39162 0.33436ZM9.64062 1C9.37732 1 9.11865 1.06931 8.89062 1.20096L8.88869 1.20207L1.89062 5.20096C1.89033 5.20113 1.89003 5.2013 1.88974 5.20147C1.66233 5.33297 1.47344 5.52189 1.34197 5.74933C1.21037 5.977 1.14094 6.23526 1.14062 6.49822V14.4977C1.14094 14.7606 1.21037 15.0189 1.34197 15.2466C1.47345 15.474 1.66237 15.663 1.88981 15.7945C1.89008 15.7946 1.88954 15.7943 1.88981 15.7945L8.89063 19.7949C9.11865 19.9266 9.37732 19.9959 9.64062 19.9959C9.90393 19.9959 10.1626 19.9266 10.3906 19.7949L10.3926 19.7938L17.3906 15.7949C17.3909 15.7948 17.3904 15.7951 17.3906 15.7949C17.6181 15.6634 17.8078 15.474 17.9393 15.2466C18.0709 15.0188 18.1404 14.7605 18.1406 14.4974V6.49846C18.1404 6.23542 18.0709 5.97707 17.9393 5.74933C17.8078 5.52189 17.6189 5.33298 17.3915 5.20147C17.3912 5.2013 17.3909 5.20113 17.3906 5.20096L10.3926 1.20207L10.3906 1.20096C10.1626 1.06931 9.90393 1 9.64062 1Z" fill="#1D5DC2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.477436 5.20773C0.615707 4.9687 0.92157 4.88702 1.1606 5.02529L9.64024 9.93046L18.1199 5.02529C18.3589 4.88702 18.6648 4.9687 18.803 5.20773C18.9413 5.44676 18.8596 5.75262 18.6206 5.89089L9.8906 10.9409C9.73572 11.0305 9.54476 11.0305 9.38988 10.9409L0.659878 5.89089C0.420847 5.75262 0.339165 5.44676 0.477436 5.20773Z" fill="#1D5DC2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.64062 9.99805C9.91677 9.99805 10.1406 10.2219 10.1406 10.498V20.578C10.1406 20.8542 9.91677 21.078 9.64062 21.078C9.36448 21.078 9.14062 20.8542 9.14062 20.578V10.498C9.14062 10.2219 9.36448 9.99805 9.64062 9.99805Z" fill="#1D5DC2"/>
        </svg>

    )
}

export const OrderPlacedSvg = () => {
    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.58398 13.8184H11.4931V15.5456H4.58398V13.8184Z" fill="#0A1D40"/>
            <path d="M4.58398 9.5H14.9476V11.2273H4.58398V9.5Z" fill="#0A1D40"/>
            <path d="M4.58398 18.1362H8.90217V19.8635H4.58398V18.1362Z" fill="#0A1D40"/>
            <path d="M17.5384 2.59091H14.9474V1.72727C14.9474 1.26917 14.7655 0.829833 14.4415 0.505906C14.1176 0.18198 13.6783 0 13.2202 0H6.31108C5.85298 0 5.41364 0.18198 5.08971 0.505906C4.76579 0.829833 4.58381 1.26917 4.58381 1.72727V2.59091H1.9929C1.5348 2.59091 1.09546 2.77289 0.771531 3.09682C0.447605 3.42074 0.265625 3.86008 0.265625 4.31818V22.4545C0.265625 22.9126 0.447605 23.352 0.771531 23.6759C1.09546 23.9998 1.5348 24.1818 1.9929 24.1818H17.5384C17.9965 24.1818 18.4358 23.9998 18.7597 23.6759C19.0836 23.352 19.2656 22.9126 19.2656 22.4545V4.31818C19.2656 3.86008 19.0836 3.42074 18.7597 3.09682C18.4358 2.77289 17.9965 2.59091 17.5384 2.59091ZM6.31108 1.72727H13.2202V5.18182H6.31108V1.72727ZM17.5384 22.4545H1.9929V4.31818H4.58381V6.90909H14.9474V4.31818H17.5384V22.4545Z" fill="#0A1D40"/>
        </svg>
    )
}

export const OrderPaymentConfirmedSvg = () => {
    return (
        <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6617 11C15.4533 11 15.2535 11.0828 15.1061 11.2301C14.9588 11.3775 14.876 11.5773 14.876 11.7857C14.876 11.9941 14.9588 12.1939 15.1061 12.3413C15.2535 12.4886 15.4533 12.5714 15.6617 12.5714H18.8045C19.0129 12.5714 19.2128 12.4886 19.3601 12.3413C19.5075 12.1939 19.5903 11.9941 19.5903 11.7857C19.5903 11.5773 19.5075 11.3775 19.3601 11.2301C19.2128 11.0828 19.0129 11 18.8045 11H15.6617Z" fill="#0A1D40"/>
            <path d="M0.733398 3.14286C0.733398 2.30932 1.06452 1.50992 1.65392 0.920521C2.24332 0.331121 3.04272 0 3.87626 0H19.5905C20.4241 0 21.2235 0.331121 21.8129 0.920521C22.4023 1.50992 22.7334 2.30932 22.7334 3.14286V12.5714C22.7334 13.405 22.4023 14.2044 21.8129 14.7938C21.2235 15.3832 20.4241 15.7143 19.5905 15.7143H3.87626C3.04272 15.7143 2.24332 15.3832 1.65392 14.7938C1.06452 14.2044 0.733398 13.405 0.733398 12.5714V3.14286ZM21.162 3.14286C21.162 2.72609 20.9964 2.32639 20.7017 2.03169C20.407 1.73699 20.0073 1.57143 19.5905 1.57143H3.87626C3.45949 1.57143 3.05979 1.73699 2.76509 2.03169C2.47039 2.32639 2.30483 2.72609 2.30483 3.14286V4.71429H21.162V3.14286ZM2.30483 12.5714C2.30483 12.9882 2.47039 13.3879 2.76509 13.6826C3.05979 13.9773 3.45949 14.1429 3.87626 14.1429H19.5905C20.0073 14.1429 20.407 13.9773 20.7017 13.6826C20.9964 13.3879 21.162 12.9882 21.162 12.5714V6.28571H2.30483V12.5714Z" fill="#0A1D40"/>
        </svg>
    )
}

export const OrderProcessedSvg = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5781 0H6.26562V7.125H7.09687L7.33438 7.95625L7.45312 8.075V1.1875H13.3906V5.9375H18.1406V16.625H11.0156L11.3719 17.2188L10.7781 17.8125H19.3281V4.75L14.5781 0ZM14.5781 4.75V1.1875L18.1406 4.75H14.5781Z" fill="#0A1D40"/>
            <path d="M7.20215 13.6562C7.20215 13.9712 7.07704 14.2732 6.85434 14.4959C6.63164 14.7186 6.32959 14.8438 6.01465 14.8438C5.6997 14.8438 5.39766 14.7186 5.17496 14.4959C4.95226 14.2732 4.82715 13.9712 4.82715 13.6562C4.82715 13.3413 4.95226 13.0393 5.17496 12.8166C5.39766 12.5939 5.6997 12.4688 6.01465 12.4688C6.32959 12.4688 6.63164 12.5939 6.85434 12.8166C7.07704 13.0393 7.20215 13.3413 7.20215 13.6562Z" fill="#0A1D40"/>
            <path d="M10.0521 14.725L11.3584 14.25V13.0625L10.0521 12.5875C9.9334 12.2313 9.81465 11.875 9.57715 11.5187L10.1709 10.3312L9.33965 9.5L8.15215 10.0938C7.7959 9.85625 7.43965 9.7375 7.0834 9.61875L6.6084 8.3125H5.4209L4.9459 9.61875C4.58965 9.7375 4.2334 9.85625 3.87715 10.0938L2.68965 9.5L1.8584 10.3312L2.45215 11.6375C2.21465 11.9938 2.0959 12.35 1.97715 12.7062L0.670898 13.0625V14.25L1.97715 14.725C2.0959 15.0812 2.21465 15.4375 2.45215 15.7938L1.8584 16.9812L2.68965 17.8125L3.9959 17.2188C4.35215 17.4563 4.7084 17.575 5.06465 17.6937L5.4209 19H6.6084L7.0834 17.6937C7.43965 17.575 7.7959 17.4563 8.15215 17.2188L9.33965 17.8125L10.1709 16.9812L9.57715 15.675C9.81465 15.4375 9.9334 15.0812 10.0521 14.725ZM6.01465 16.0312C4.7084 16.0312 3.63965 14.9625 3.63965 13.6562C3.63965 12.35 4.7084 11.2812 6.01465 11.2812C7.3209 11.2812 8.38965 12.35 8.38965 13.6562C8.38965 14.9625 7.3209 16.0312 6.01465 16.0312Z" fill="#0A1D40"/>
        </svg>

    )
}