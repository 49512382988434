import React from "react"

import Privacy from "src/gdpr/component/privacypolicy/Privacy";
import MainLayout from 'src/core/component/MainLayout';


export default function PrivacyPage() {

  return (
    <MainLayout>
      <Privacy />
    </MainLayout>
  )
}
