import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import PocketSelection from "src/core/component/selection/PocketSelection";
import Coin from "../Coin/Coin";
import { Col, Row } from "react-bootstrap";
import useSelect from "../../../core/hook/useSelect";
import useExchangeRate from "src/wallet/hook/useExchangeRate"
import "./CurrencyBuy.scss";

// mock coins testing
const pocketCoins = [
  {
    id: 1,
    hasExtra: true,
    extra: 4,
    PP: 50,
    USD:5,
    EUR:5,
    AUD:5,
    INR:50,
    NPR:50,
    GBP:5
  },
  {
    id: 2,
    hasExtra: false,
    extra: 0,
    PP: 100,
    USD:10,
    EUR:10,
    AUD:10,
    INR:100,
    NPR:100,
    GBP:10
  },
  {
    id: 3,
    hasExtra: false,
    extra: 0,
    PP: 200,
    USD:20,
    EUR:20,
    AUD:20,
    INR:200,
    NPR:200,
    GBP:20
  },
  {
    id: 4,
    hasExtra: false,
    extra: 0,
    PP: 500,
    USD:50,
    EUR:50,
    AUD:50,
    INR:500,
    NPR:500,
    GBP:50
  },
  {
    id: 5,
    hasExtra: false,
    extra: 0,
    PP: 1000,
    USD:100,
    EUR:100,
    AUD:100,
    INR:1000,
    NPR:1000,
    GBP:100
  },
  {
    id: 6,
    hasExtra: false,
    extra: 0,
    PP: 2000,
    USD:200,
    EUR:200,
    AUD:200,
    INR:2000,
    NPR:2000,
    GBP:200
  },
  {
    id: 7,
    hasExtra: false,
    extra: 0,
    PP: 3000,
    USD:300,
    EUR:300,
    AUD:300,
    INR:3000,
    NPR:3000,
    GBP:300
  },
  {
    id: 8,
    hasExtra: false,
    extra: 0,
    PP: 5000,
    USD:500,
    EUR:500,
    AUD:500,
    INR:5000,
    NPR:5000,
    GBP:500
  }
];

function CurrencyBuy({currentCurrency,setCurrentCurrency,currencies}) {
  const {exchangeRate, loading, error } = useExchangeRate()
  const history = useHistory();
  const [
    coinSelected,
    handleCoinSelect,
    setCoinSelectionSpace,
    isCoinSelected,
  ] = useSelect([], [], true);

  const handleContinuePayment = ()=>{
    const intentAmount = coinSelected?.map(coin=>coin[currentCurrency.value]).reduce((a,b)=>a+b,0)
    let intentAmountInUsd = 0
    if(!loading && error==null && exchangeRate){
      let excg_rate = 0
      if(currentCurrency.value == 'USD'){
        intentAmountInUsd = intentAmount
      }else{
        excg_rate = exchangeRate.find(rate=>rate.exchange_currency == currentCurrency.value).exchange_rate
        intentAmountInUsd = intentAmount/excg_rate
        intentAmountInUsd = parseFloat(intentAmountInUsd.toFixed(2));
      }      
      
      history.push({
        pathname: `/wallet/checkout`,
        state: {
          intentAmount: intentAmount,
          currentCurrency: currentCurrency.value,
          intentAmountInUsd : intentAmountInUsd,
        }
      });
    }
  }

  return (
    <section className="currency-buy">
      <div className="container">
        <div className="currency-buy__inner">

          <div className="coin-wrapper">
            <div className="currency-buy__heading">
              <h2>Topup Coin</h2>
              <div className="currency-buy__balance">
                <div className="currency-buy__balance-amount">
                <span>Load in: </span>
                    <PocketSelection
                      options={[...currencies]}
                      currentValue={currentCurrency}
                      onChange={(item)=>{setCurrentCurrency(item)}}
                      />
                </div>
              </div>
            </div>
            <div className="pesa-coins">
              <Row xs={2} sm={3} lg={5} className="g-3">
                {pocketCoins &&
                  pocketCoins.length > 0 &&
                  pocketCoins.map((coin) => {
                    return (
                      <Col key={coin.id}>
                        <Coin
                          data={coin}
                          currentCurrency = {currentCurrency}
                          handleCoinSelect={handleCoinSelect}
                          isCoinSelected={isCoinSelected(coin)}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </div>

            <div className="text-lg-end mt-4">
            <div className={`btn btn-primary btn-pesa ${!coinSelected.length ? "disabled" : ""}`} onClick={handleContinuePayment}>
                Continue to payment
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CurrencyBuy;
