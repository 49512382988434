import React from "react"
import { Link } from 'react-router-dom';

import { useTheme } from "src/theme/component/ThemeContext";
import Banner from "src/core/component/Banner";
import CenteredContainer from "src/core/component/grid/CenteredContainer";
import CenteredDiv from "src/core/component/CenteredDiv";


const PaymentPolicy = () => {
    const [isDarkMode, toggleTheme] = useTheme();


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submitted');
    }

    const handleChange = (e) => {
        console.log('Changed...');
    }

  return (
        <section className={"pocket-gdpr" + (isDarkMode && ' dark' || '' )}>
            <Banner classes={isDarkMode && 'dark' ||  ''}>
                <CenteredDiv classes={'title'} text={'Payment and Refund Policy'} />
            </Banner>

            <div className={"pocket-gdpr__contents"  + (isDarkMode && ' dark' || '' )}>
                <CenteredContainer colClasses={'col-sm-12 col-xl-10 col-xxl-9'}>
                <div className="information mb-0 mb-md-4">
                    <div className="text">
                        <p className="mb-4">Last update: July 13, 2022; 5:00 AM | Effective Date: July 13, 2022</p>

                        <p>
                            This "PAYMENT AND REFUND POLICY" is an online agreement, (which is integral part of the 
                            Terms of Use) between YOU and POCKET PANDIT INC, a company based in DELAWARE, 
                            USA and describes the process of payment for the SERVICE we provide, relation with transaction 
                            partners, the cost of the transaction and the services, the amount of refund, and the process and 
                            administration of refund.   YOUR payment request for the purchase of any POCKET PANDIT service 
                            is deemed that YOU read, accept and confirm your service order under the provisions of this 
                            PAYMENT AND REFUND POLICY and abide by the same.
                        </p>
                        <p>
                            This PAYMENT AND REFUND POLICY is an integral part of the <Link to="/tos">TERMS OF USE</Link> of POCKET 
                            PANDIT and subject to the laws of DELAWARE, USA.
                        </p>

                        <h5 className="sub-heading">
                            SERVICE
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) WE provide SERVICE to YOU as defined in the <Link to="/tos">TERMS OF USE</Link> which is the 
                                    principle contract between YOU and the POCKET PANDIT. 
                                </li>  

                                <li>
                                    (b) WE provide levels of services, e.g. free service and premium service. For any 
                                        premium service, YOU require to create a verified account at POCKET PANDIT 
                                        platform available at our website or our mobile application. 
                                </li>
                                <li>
                                    (c) To receive a premium service from POCKET PANDIT, you should make payment in 
                                        advance. Once YOU make the payment, OUR service is made available to you at 
                                        screen or at your electronic address or communication channel provided during the 
                                        opening of an user account. 
                                </li>
                                <li>
                                    (d) Once you open our Website or App, you can choose the categories of services you 
                                        prefer. All the services are available in the form of question and answer.  
                                </li>

                                <li>
                                    (e) YOU can seek service for you or any of your third party. But, you are solely 
                                        responsible for all the information YOU supplied on behalf of any third party.   
                                </li>
                                <li>
                                    (f) Before you choose questions, you need to give consent to POCKET PANDIT for any 
                                    result and hereby confirm the DISCLAIMER provided. YOU are abide by the 
                                    disclaimer you confirmed through this process.    
                                </li>
                                <li>
                                    (g) Once you pay the amount, then WE provide the service either on the screen or in the 
                                        email or any other communication channel YOU provided during opening the user 
                                        Account.    
                                </li>
                            </ol>
                        </div>

                        <h5 className="sub-heading">
                            PAYMENT
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) YOU shall make your payment through a payment channel managed by our payment partner. 
                                </li>  

                                <li>
                                    (b) You can select your desired payment method and proceed with the question. 
                                </li>
                                <li>
                                    (c) Specific amount then shall be deducted from your account before you receive the SERVICE through                         the website or via the communication channel you provided.
                                </li>
                                
                            </ol>
                        </div>


                        <h5 className="sub-heading">
                            PAYMENT CHANNEL
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) STRIPE is the payment partner for POCKET PANDIT. All the payment related laws are applied in the USA jurisdiction. 
                                </li>  

                                <li>
                                    (b) The PAYMENT PARTNER is the beneficiary of the 30% of the paid amount by YOU to the POCKET PANDIT for each transaction.  
                                </li>
                                <li>
                                    (c) For any payment related technical arrangement, our PAYMENT PARTNER is responsible.
                                </li>
                                <li>
                                    (d) YOU can check information about our payment partner, including but not limited to 
                                    the <a href="https://stripe.com/privacy" target="_blank">Terms of Use and Privacy Policy</a>
                                </li>
                                <li>
                                    (e) The PAYMENT PARTNER shall be responsible for any breach or other risk related to 
                                    the execution of the payment actions. 
                                </li>
                            </ol>
                        </div>
                        
                        <h5 className="sub-heading">
                            REFUND
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) POCKET PANDIT offers YOU a refund in certain circumstances, specially on 
                                    erroneous transactions, under this POLICY and the principle TERMS OF USE.
                                </li>  

                                <li>
                                    (b) Asking for refund of any payment, YOU grant POCKET PANDIT to access the 
                                    Services you purchased and evaluate the eligibility of refund YOUR request.
                                </li>
                            </ol>
                        </div>

                        <h5 className="sub-heading">
                            REFUND AMOUNT
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) YOU are aware and agree to receive any of YOUR eligible refund after deducting 
                                        30% of the transaction amount, deducted by the payment channel partner.  
                                </li>  

                                <li>
                                    (b) Any eligible refunds shall be made at the same account or payment 
                                    instrument/channel when the payment was made by YOU. 
                                </li>
                                <li>
                                    (c) YOU are eligible to get the refund if you cancel any service request, within one hour 
                                        of the transaction. 
                                </li>
                                <li>
                                    (d) In real time prediction questions, refund is available only for erroneous delivery of the 
                                    service (i.e. not receiving answers in any way, via website or communication 
                                    channels provided by YOU during creation of the user account) 
                                </li>
                                <li>
                                    (e) One of the payments could be returned if YOU accidentally make two payments for the same order. 
                                </li>
                            </ol>
                        </div>

                        <h5 className="sub-heading">
                            NO REFUND
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) No refund shall be made only because YOU deem the prediction is not true.  
                                </li>  

                                <li>
                                    (b) No refund shall be made for any transaction executed resulting in incorrect due to 
                                    submission of the wrong data provided. 
                                </li>
                                <li>
                                    (c) No refunds shall be provided after YOU receive the report.  
                                </li>
                                <li>
                                    (d) No refund shall be made if predictions made by one astrologer differ from another 
                                        astrologer.  
                                </li>
                                <li>
                                    (e) One of the payments could be returned if YOU accidentally make two payments for the same order. 
                                </li>
                            </ol>
                        </div>
                        
                        <h5 className="sub-heading">
                            HOW TO RAISE A DISPUTE 
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) If YOU request for a refund of any of the payment, YOU need to raise this as a 
                                            dispute.  
                                </li>  

                                <li>
                                    (b) If a dispute is related to the real time prediction or any digital service, the dispute 
                                        shall be raised in 24 hours from the time of the transaction.   
                                </li>
                                <li>
                                    (c) If a dispute is related to the delivery of the goods, the dispute shall be raised within 
                                    Seven (7) days  after receiving delivery   
                                </li>
                                <li>
                                    (d) YOU should send an email to <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a> or through a digital platform for 
                                        the submission of the complaint/dispute, alternatively, including but not limited to  

                                        <ol style={{listStyleType: 'none'}}>
                                            <li>
                                                i) <strong>For e-commerce products:</strong> photographs of any broken, flawed, or damaged 
                                                    products. Along with any bills or labels, all goods must be returned in the 
                                                    same condition they were received,
                                            </li>
                                            <li>
                                                ii) <strong>For real time prediction:</strong> Any email, screenshot or link. 
                                            </li>
                                        </ol>
                                </li>
                                <li>
                                    (e) You will get an electronic communication with directions on how to refund the eligible 
                                    payment. 
                                </li>
                                <li>
                                    (f) Your can Resolve or return your item after we review your request. We might sometimes need 
                                    four to five business days to reply to your request regarding return and to provide you 
                                    with necessary instructions regarding it.
                                </li>
                            </ol>
                        </div>
                        
                        <h5 className="sub-heading">
                            EXPECT TIMELINE 
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) Once we confirm that YOUR payment request is eligible for the refund, WE initiate 
                                        the refund as soon as possible. This process may take Seven (5) to Ten (10) 
                                        business days, subject to technical glitches.
                                </li>  

                                <li>
                                    (b) The refund amount shall be provided to the same payment method you used while 
                                    paying for the product or services.
                                </li>
                                <li>
                                    (c) Banking hours, holidays and laws shall be applied to Delaware, USA jurisdiction. 
                                </li>
                            </ol>
                        </div>
                        
                        <h5 className="sub-heading">
                            UPDATE OF THE POLICY  
                        </h5>
                        <div className="lists">
                            <ol style={{listStyleType: 'none'}}>
                                <li>
                                    (a) This PAYMENT AND REFUND POLICY page is updated and modified from time to 
                                    time. 
                                </li>  

                                <li>
                                    (b) This POLICY is last updated on 13 July, 2022. 
                                </li>
                                <li>
                                    (c) Visiting <Link to="/">www.pocketpandit.com</Link> or using OUR services, it is deemed that you confirm 
                                        and accepted this POLICY. 
                                </li>
                            </ol>
                        </div>

                        <h5 className="sub-heading">
                            CONTACT  
                        </h5>
                        <div className="contact-address mb-3">
                            M/S PocketPandit, INC<br/>
                            600 N Broad Street, Suite 5 # 3519<br/>
                            Middletown, DE 19709<br/> 
                            Email: <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a>  
                        </div>

                    </div>
                </div>
                    
                </CenteredContainer>

            </div>
        </section>
    )
}

export default PaymentPolicy;
