import React, {useState, useEffect} from "react";
import { useHistory, Link  } from "react-router-dom";
import queryString from "query-string";

import { EditSvg } from "src/core/component/assets/svg/CommonSVG";
import useAuthInfo from 'src/auth/hook/auth-info';
import { chartData } from "src/core/component/kundali/prepare-chart-data";
import KundaliSlider from "src/core/component/kundali/KundaliSlider";
import { pick } from "src/util/extra";
import { getHouses } from "src/lib/services/kundali-houses";
import { PocketToast } from "src/core/component/toast/PocketToast";
import LoadingIcon from "src/core/component/loader/LoadingIcon";


const CustomerKundaliChart = ({user, profile, handleChangeProfile, width, isDarkMode, ...rest}) => {
    const {preference} = useAuthInfo();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [kundaliData, setKundaliData] = useState([]);
    const [formData, setFormData] = useState({
        'address': profile?.address || null,
        'datetime': profile?.dob || null,
        'latitude': profile?.latitude || null,
        'longitude': profile?.longitude || null,
        'timezone': profile?.timezone || null,
        'kundali_type': 'D1',
        'house_method': 'E',
        'name': 'rasi',
        'first_house_planet': '0',
        'ascendant_type': 'birth_ascendant',
        'variant': preference?.kundali_variant || 'north',
    });

    const [config, setConfig] = useState({
        'modern_planet': preference?.modern_planet || 'exclude',
        'rahu_ketu_position': preference?.rahu_ketu_position || 'mean_position',
        'kundali_variant': preference?.kundali_variant || 'north',
        'name': 'rasi' //chalit
    });

    useEffect(() => {
        fetchKundaliData(formData);
    }, [profile])

    const fetchKundaliData = async (data) => {
        const params = pick(data, 'datetime', 'latitude', 'longitude', 'timezone', 'variant');
    
        if (data['name'] == 'rasi') {
            params['kundali_type'] = data['kundali_type'];
            params['first_house_planet'] = data['first_house_planet'];
            params['ascendant_type'] = data['ascendant_type'];
        } else if (data['name'] == 'chalit') {
            params['house_method'] = data['house_method'];
        }
        params['include_all'] = '1'

        let queryParams = queryString.stringify(params, {
            skipEmptyString: true,
            skipNull: true,
        })

        setLoading(true);

        try {
            const res = await getHouses(data['name'], queryParams);
            setResponse(res);
    
        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching kundali data."
            })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(response) {
            const houses = response?.data?.result?.data || [];
            setKundaliData(houses);
        }
    }, [response])

    const dataToRender = kundaliData && chartData(kundaliData, config) || [];
    const sliderData = [
        {
            data: dataToRender && dataToRender || [],
            variant: 'north',
            title: 'North-Indian Chart'
        },
        {
            data: dataToRender && dataToRender || [],
            variant: 'south',
            title: 'South-Indian Chart'
        },
        {
            data: dataToRender && dataToRender || [],
            variant: 'east',
            title: 'East-Indian Chart'
        }
    ]

    console.log('Chart Width', width);
    // console.log('Kundali Data', kundaliData);
    // console.log('Data to render', dataToRender);

    return (
        <div className="pocket-astrologer__chat-kundali">
            {loading && (
                <div className="row">
                    <div className="col-12">
                        <LoadingIcon />
                    </div>
                </div>
            )}

            {!loading && (
                <>
                    <div className="kundali-chart-wrapper">
                        {/* Kundali Chart */}
                        {dataToRender && profile && dataToRender.length > 0 && ( 
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="kundali-chart" style={{maxWidth: `${width && width > 600 && '560px' || `${width - 30}px` || '560px'}`}}>
                                    {profile && (
                                        <p className={`profile-title d-flex align-items-center ${isDarkMode && 'dark' || ''}`}>
                                            {profile?.relationship?.name && (
                                                <span style={{'color': `${profile?.relationship?.color}`}}>
                                                    {`${profile.relationship?.name} Profile`}
                                                </span>
                                            )}
                                            <span className="name">
                                                ({profile?.name})
                                            </span>
                                        </p>
                                    )}  
                                    <div className="kundali-slider-wrapper">
                                        <KundaliSlider
                                            kundali_data={sliderData}
                                            config={config}
                                            profile={profile}
                                            isDarkMode={isDarkMode}
                                            scale={width && width > 600 && 550 || width - 40 || 550}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Link to="#" className="change-profile d-flex align-items-center mt-2" onClick={handleChangeProfile}>
                        Change Profile
                        <EditSvg />
                    </Link>
                </>
            )}
        </div>
    )
}

export default CustomerKundaliChart;
