import React, {useState, useEffect} from "react";

import { SimpleCloseIconSvg, AngleDownSvg } from "src/core/component/assets/svg/CommonSVG";
import PricingSummary from "./PricingSummary";


const ProductOrderSummary = ({order, isDarkMode, isTracking=false, ...rest }) => {

    const handleQtyIncrease = (line) => {
        console.log('Qty increase')
    }

    const handleQtyDecrease = (line) => {
        console.log('Qty decrease')
    }

    const handleLineRemove = (line) => {
        console.log('Removing line...', line)
    }

    return (
        <div className={"order-summary__product" + (isDarkMode && ' dark' || '')}>
            <div className={"product-list" + (isDarkMode && ' dark' || '')}>
                <table className={"table table-borderless" + (isDarkMode && ' dark' || '')}>
                    <thead>
                        <tr>
                            <th>Your Products</th>
                            <th>Product Price</th>
                            <th>Quantity</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {(order?.order_lines || []).sort((i1, i2) => i1.id - i2.id).map((item, i) => (
                            <tr key={i}>
                                <td className="align-middle">
                                    <div className="product-image">
                                        <img src={item.product.image} className="img-fluid" alt="" />
                                    </div>
                                </td>

                                <td className="align-middle">
                                    <span className="price">
                                        {item?.price_formatted}
                                    </span>
                                </td>

                                <td className="align-middle" colSpan={1}>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <span className={"change-qty decrease" + (isTracking && ' d-none' || '')} onClick={handleQtyDecrease}>
                                            <span className="icon">
                                                <AngleDownSvg />
                                            </span>
                                        </span>
                                        <span className="qty">
                                            {item?.quantity}
                                        </span>
                                        <span className={"change-qty increase" + (isTracking && ' d-none' || '')} onClick={handleQtyIncrease}>
                                            <span className="icon">
                                                <AngleDownSvg />
                                            </span>
                                        </span>
                                    </div>
                                </td>

                                <td className="align-middle">
                                    <span className={"remove-icon d-flex align-items-center float-end" + (isTracking && ' d-none' || '')} onClick={() => {handleLineRemove(item)}}>
                                        <SimpleCloseIconSvg />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <p className="separator mt-2"></p>

            <PricingSummary
                title={'Products'}
                order={order}
                isDarkMode={isDarkMode}
            />

            <div className={"d-flex justify-content-end re-order-btn" + (isDarkMode && ' dark' || '') + (isTracking && ' d-none' || '')}>
                <button className="pocket-btn">Reorder</button>
            </div>
        </div>
    )
}

export default ProductOrderSummary
