import React from "react";
import { Link } from "react-router-dom";

import SignupForm from "src/auth/component/SignupForm";

export default function SignupPage({
  isDarkMode,
  root_url,
}) {
  return (
    <div className={"pocket-auth__signup" + ((isDarkMode && " dark") || "")}>
      {/* <div className="signup-astrologer mb-4">
        <Link to={`${root_url}/astrologer-signup`} className="pocket-btn">
          Sign up as an astrologer
        </Link>
      </div> */}
      <h4
        className={
          "pocket-auth__signup--heading" + ((isDarkMode && " dark") || "")
        }
      >
        Sign Up with <span>PocketPandit</span>
      </h4>

      <SignupForm
        isDarkMode={isDarkMode}
      />
    </div>
  );
}
