import React, { useState } from "react";

import Carousel from "src/core/component/slider/Carousel";
import CategorySliderItem from "./CategorySliderItem";


const CategorySlider = ({ 
    categories, 
    handleCategorySelect,
    isCategorySelected,
    isDarkMode, 
    ...rest 
}) => {

    const settings = {
        infinite: false,
        autoplay: false,
        dots: false,
        arrows: false,
        speed: 500,
        variableWidth: true,
        centerMode: false,
        autoplaySpeed: 7000,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: isDarkMode ? 'category__slider dark' : 'category__slider',
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    return (
        <div className="categories">
            <div className="row">
                <div className="col-12">
                        <div className={"category__carousel" + (isDarkMode && ' dark' || '')}>
                            <Carousel
                                {...settings}

                                items={categories.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <CategorySliderItem
                                            item={item}
                                            isDarkMode={isDarkMode}
                                            handleCategorySelect={handleCategorySelect}
                                            isSelected={isCategorySelected(item)}
                                        />
                                    </React.Fragment>
                                ))}
                            />
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default CategorySlider
