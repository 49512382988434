import React from 'react'

const ForumPostTab = ({tabs,currentTab,handleForumClickHandle}) => {
  return (
      <div className='forum_post_tabs'>
      {tabs?.map(tab=>
            <div key={tab.id} className={`item ${currentTab==tab.value?' active':''}`} 
                onClick={()=>handleForumClickHandle(tab.value)}>
            <span>{tab.name}</span>
            </div>
      )}      
        </div>
  )
}

export default ForumPostTab