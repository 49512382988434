import React from "react";

import {
  CartCloseSvg,
  SimpleCloseIconSvg,
} from "src/core/component/assets/svg/CommonSVG";

const QuestionItem = ({
  line,
  handleRemoval,
  isProcessing,
  deletingLineId,
  isDarkMode,
  ...rest
}) => {
  return (
    <div
      className={`question-item d-flex align-items-center justify-content-between ${
        (isDarkMode && "dark") || ""
      }`}
    >
      <div className={`question ${(isDarkMode && "dark") || ""}`}>
      {line?.profile?.profile_type == "prashna_kundali_profile"?
        <div style={{fontStyle: "italic",fontWeight: 700,color: "#D49369"}}>Prashna Kundali Profile</div>:null
      }
          {line.name}

        {line?.profile && (
          <p
            className={`profile-info d-flex align-items-center ${
              (isDarkMode && "dark") || ""
            }`}
          >
            {line.profile?.relationship?.name && (
              <span style={{ color: `${line.profile?.relationship?.color}` }}>
                {(line.profile.relationship?.is_self && (
                  <>{`${line.profile.relationship?.name} Profile`}</>
                )) || <>{`${line.profile.relationship?.name}'s Profile`}</>}
              </span>
            )}
            <span className="name">({line.profile.name})</span>
          </p>
        )}

        <div
          className={`remove-icon d-none d-sm-block ${
            (isProcessing && "disabled") || ""
          }`}
          onClick={() => {
            handleRemoval(line);
          }}
        >
          <CartCloseSvg />
          {isProcessing && line.id === deletingLineId && (
            <span
              className="spinner-border spinner-border-sm text-primary position-absolute"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </div>
      </div>

      <div
        className={`remove-icon-mobile d-sm-none ${
          (isProcessing && "disabled") || ""
        }`}
        onClick={() => {
          handleRemoval(line);
        }}
      >
        <SimpleCloseIconSvg />
        {isProcessing && line.id === deletingLineId && (
          <span
            className="spinner-border spinner-border-sm text-primary position-absolute"
            role="status"
            aria-hidden="true"
          ></span>
        )}
      </div>

      <div className="pricing">
        <span>{[0,0.0].includes(line.discounted_price)?'FREE TRIAL':line.discounted_price_formatted }</span>
        {
          line.discounted_price !== line.price ? (
            <del>{line.price_formatted}</del>
          ):<>&nbsp;</>
        }
      </div>
    </div>
  );
};

export default QuestionItem;
