import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Footer from "src/core/component/footer/Footer";
import Header from "./component/Header/Header";
import LoadWallet from "./page/LoadWallet";
import WalletDashboard from "./page/WalletDashboard";
import WalletCheckout from "./page/WalletCheckout";
import useAuthInfo from "src/auth/hook/auth-info";

import "./Wallet.scss";

function Wallet() {
  const match = useRouteMatch();
  const {profile } = useAuthInfo();
  return (
    <>
    <main className="layout-wallet flex-grow-1">
      <Header profile={profile} />
      <Switch>
          <Route exact path={`${match.path}`} render={props => (<LoadWallet profile={profile} />)}/>
          <Route exact path={`${match.path}/transactions`} render={props => (<WalletDashboard profile={profile} />)}/>
          <Route exact path={`${match.path}/checkout`} render={props => (<WalletCheckout profile={profile} />)}/>
      </Switch>
    </main>
    <Footer />
    </>
  )
}

export default Wallet;
