import api from "src/lib/api";

export default async function handleAnswerActionLink(token, cb) {
  if (!token) return;

  try {
    const response = await api.get("v1/action-link", {
      params: { token: token },
    });

    const data = response?.data?.result?.data;

    if (data.action === "instant-prediction:question") {
      const questionResponse = await api.get("/v1/questions/" + data?.payload?.question_id);
      const question = questionResponse?.data?.result?.data;

      cb(question, data?.payload?.birth_profile_id);
    }
  } catch (error) {
    console.log("error", error);
  }
}
