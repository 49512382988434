import React from "react";

const AmountItem = ({ title, value, classes = "", ...rest }) => {
  return (
    <div
      className={
        "amount-item d-flex align-items-center justify-content-between " +
        classes
      }
    >
      <p className="subject">{title}</p>
      <p className="price-total">{value}</p>
    </div>
  );
};

export default AmountItem;
