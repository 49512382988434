import React from "react";
import OrderItem from "./OrderItem";


const OrderList = ({orderData, path, isDarkMode, ...rest }) => {

    return ( 
        <div className="row gx-2 gx-lg-4">
            {
                orderData && orderData.map((order, index) => {
                    return (
                        <div className={"col-md-6 orders__col" + (isDarkMode && ' dark' || '')} key={index}>
                            <OrderItem 
                                order={order}
                                isDarkMode={isDarkMode}
                                path={path}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default OrderList
