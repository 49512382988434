import React from "react";
import { Link } from "react-router-dom";

const ListLinkItem = (props) => {
  let classes = props.classes;

  return (
    <li className={classes}>
      <Link to={props.url}>
        {props.name && props.name}

        {props.children && props.children}
      </Link>
    </li>
  );
};

export default ListLinkItem;
