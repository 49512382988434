import React from "react";

import ImageUploadContainer from "src/core/component/astrologer/ImageUploadContainer";
import FormField from "src/core/component/form/FormField";
import SearchLocation from "src/core/component/form/SearchLocation";
import TextArea from "src/core/component/form/TextArea";
import PocketSelection from "src/core/component/selection/PocketSelection";


const AccountUpdateForm = ({
    handleSubmit,
    handleChange,
    handleSelectionChange,
    formData,
    setFormData,
    specializations,
    defaultSpecializations,
    onLocationChange,
    profile,
    isDarkMode, 
    ...rest 
}) => {
    
    let fields = [
        {
            name: 'name',
            placeholder: 'Full Name',
            label: 'Edit Name',
            type: 'text',
            required: true
        },
        {
            name: 'address',
            placeholder: 'Edit Address',
            label: 'Edit Address',
            type: 'location-input',
            required: true
        },
        {
            name: 'bio',
            placeholder: 'Something about yourself',
            label: 'Add a bio',
            type: 'textarea',
            required: false
        }
    ]   

    return ( 
        <div className={"accounts__form" + (isDarkMode && ' dark' || '')}>
            <ImageUploadContainer
                formData={formData}
                setFormData={setFormData}
                profile={profile}
            />

            <form onSubmit={handleSubmit} className={"pocket-form mb-0" + (isDarkMode && ' dark' || '')} autoComplete="off">
                {/* Cover Image and Profile Image */}
                <div className="field">
                    <PocketSelection 
                        options= {[...specializations]}
                        currentValue={defaultSpecializations}
                        onChange={ handleSelectionChange }
                        classes={"pocket-select" + (isDarkMode && ' dark' || '')}
                        hasLabel={true}
                        label={'Add Skills'}
                        isMulti={true}
                        required={false}
                    />
                </div>

                <div className={`row gx-2 gx-lg-4 gx-xl-5`}>
                    {
                        fields && fields.map((item, index) =>{
                            return (
                                <div className={`col-12 col-sm-6 field ${item.type == "textarea" && 'w-100' || ''}`} key={index}>
                                    {(item.type === "text" || item.type === "email") && (
                                        <FormField 
                                            property={item.name}
                                            type={item.type}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={handleChange}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                            autoComplete={"off"}
                                            disabled={item.disabled || false}
                                        />
                                    )}

                                    {item.type == "location-input" && (
                                        <SearchLocation 
                                            property={item.name}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={onLocationChange}
                                            value={formData[item.name] || ""}
                                        />
                                    )}

                                    {item.type == "textarea" && (
                                        <TextArea 
                                            property={item.name}
                                            hasLabel={true}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            onChange={handleChange}
                                            value={formData[item.name] || ""}
                                            required={item.required}
                                            classes={'w-100'}
                                            rows={5}
                                        />
                                    )}
                                    
                                </div>
                            )
                        })
                    }
                    <div className="col-12">
                        <button className="pocket-btn d-none" type="submit">Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AccountUpdateForm
