import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import handleAnswerActionLink from "src/lib/http/handle-answer-action-link";
import QuestionNotifyModal from "src/core/component/modal/QuestionNotifyModal";
import api from 'src/lib/api';


const HandleQuestionActionLink = ({token, source=""}) => {
    const history = useHistory();
    const {rtpOrder, draftCartLines, updateDraftCartLines, setOrderInfo, paymentDisclaimerShow} = useRTPInfo();
    const [showModal, setShowModal] = React.useState(false);
    const [question, setQuestion] = React.useState()
    const [profileToUse, setprofileToUse] = React.useState()

    const createCart = async (question, profileId) => {
        let cartItems = [...draftCartLines, {question_id: question.id, profile_id: profileId}];
        cartItems = cartItems.filter((line1, index, lineArray) => lineArray.findIndex((line2) => line2.profile_id === line1.profile_id && line2.question_id === line1.question_id) === index);

        if (cartItems.length > rtpOrder?.order_lines?.length) {
            paymentDisclaimerShow();
        }

        try {
            const response = await api.post("v2/question-orders/cart", {
                line_items: cartItems,
            });
            const _order = response.data?.result?.data || null;
            setOrderInfo(_order);

            const cartItemsCount = (_order?.order_lines || []).length;
            if (!cartItemsCount) {
                console.log('Order is empty')
            } else {
                const cartLines = (_order?.order_lines || []).map((item) => ({
                question_id: item.question_id,
                profile_id: item.profile_id,
                }));
                updateDraftCartLines(cartLines);
                
                fbq('track', 'AddToCart'); //NOTE: Facebook Meta Pixel tracking event
                history.push("/instant-prediction/payment");
            }
        } catch (error) {
            console.log('Can not add question to cart')
        }
    }

    const manageQuestionSubscription = (question, profileId) => {
        if(!question) return;
        setQuestion(question);
        setprofileToUse(profileId)

        if(question.published && profileId) {
            createCart(question, profileId);
        } else {
            setShowModal(true);
        }
    }

    const handleQuestionSubscription = async (token) => {
        await handleAnswerActionLink(token, manageQuestionSubscription);
    }

    useEffect(() => {
        handleQuestionSubscription(token).then();
    }, [token])

    return showModal ? <QuestionNotifyModal show={showModal} setShow={setShowModal} question={question} profileId={profileToUse} source={source} classes="question-notify-modal" /> : null;
};

export default HandleQuestionActionLink;
