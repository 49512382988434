import React, {useState, useEffect} from "react";
import api from "src/lib/api";

import ProfileSlider from "./ProfileSlider";


const Profiles = ({
    filter,
    profileData,
    handleProfileClick,
    profileIsSelected,
    onClickEdit,
    isRtp=false,
    canEdit=true,
    isSelf=true,
    path="",
    search="",
    isDarkMode,
    ...rest
}) => {
    const [relationships, setRelationships] = useState([]);

    useEffect(() => {
        fetchRelationships();
    }, [])

    const fetchRelationships = async () => {
        try {
            const result = await api.get(`v1/relationship`);
            const relationshipData = result?.data?.result?.data || [];
            setRelationships(relationshipData);
        }
        catch (error) {
            console.log('Error on fetching relationships.')
        }
    }

    if(filter && (filter?.relationship_id || filter?.p_relationship_id)){
        if(filter?.relationship_id === 'all'){
            profileData=profileData
        } else if(filter?.p_relationship_id === 'all') {
            profileData=profileData
        } else {
            profileData = profileData.filter(item => item.relationship?.id === filter.relationship_id)
        }
    }

    let relationship_name = null;
    const relationshipArray = (!profileData || profileData.length <= 0) && filter && filter?.relationship_id !== 'all' && (relationships || []).filter(r=>r.id === filter?.relationship_id) || [];
    if(relationshipArray.length) {
        relationship_name = relationshipArray[0]?.is_self ? `self` : `${relationshipArray[0]?.name}'s` || null;
    }

    // console.log('Data', profileData)

    return (
        <div className="birth-profile__slider-wrapper">
            {!profileData || profileData.length <= 0 && (
                <p className="alert alert-danger">
                    {`Sorry, you don't have ${relationship_name && relationship_name || 'required'} birth profiles.`}
                </p>
            )}

            {profileData && profileData.length > 0 && (
                <ProfileSlider
                    profiles={profileData}
                    handleProfileClick={handleProfileClick}
                    profileIsSelected={profileIsSelected}
                    onClickEdit={onClickEdit}
                    isDarkMode={isDarkMode}
                    isRtp={isRtp}
                    canEdit={canEdit}
                    isSelf={isSelf}
                    path={path}
                    search={search}
                />
            )}
        </div>
    )
}

export default Profiles
