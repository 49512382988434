import React from "react";

import Carousel from "src/core/component/slider/Carousel";
import QuestionItem from './QuestionItem';


const QuestionLines = ({lines, isDarkMode, ...rest }) => {

    const settings = {
        infinite: lines.length > 1,
        autoplay: true,
        dots: false,
        arrows: false,
        speed: 500,
        variableWidth: true,
        centerMode: false,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: isDarkMode ? 'order-lines__slider dark' : 'order-lines__slider',
    }

    return ( 
        <div className={"order-lines" + (isDarkMode && ' dark' || '')}>
            <div className="row">
                <div className="col-12">
                    <div className={"order-lines__carousel" + (isDarkMode && ' dark' || '')}>
                        <Carousel
                            {...settings}
                            items={lines.map((line, index) => (
                                <React.Fragment key={index}>
                                    <QuestionItem
                                        item={line}
                                        isDarkMode={isDarkMode}
                                    />
                                </React.Fragment>
                            ))}
                        />
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default QuestionLines
