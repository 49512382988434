import React from "react";

import Banner from "src/core/component/Banner";
import SupportContainer from "./SupportContainer";

const Support = ({ isDarkMode, ...rest }) => {
  return (
    <div
      className={`pocket-customer-support__contents ${
        (isDarkMode && "dark") || ""
      }`}
    >
      <Banner classes={(isDarkMode && "dark") || ""}>
        <div className="d-flex justify-content-center align-items-center">
          <h5 className="title">Connect with our Customer Support</h5>
        </div>
      </Banner>

      {/* Support Data */}
      <SupportContainer isDarkMode={isDarkMode} />
    </div>
  );
};

export default Support;
