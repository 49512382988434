import React from "react";

import ConversationItem from "src/core/component/order/ConversationItem";

const ConversationSummary = ({conversation, isDarkMode, ...rest }) => {

    return ( 
        <div className={"conversation-summary" + (isDarkMode && ' dark' || '')}>
            
            <ConversationItem
                conversation={conversation}
                isDarkMode={isDarkMode}
                showRate={true}
            />
        </div>
    )
}

export default ConversationSummary
