import React from 'react';


const TimeGroup = ({group, index, isDarkMode}) => {

    return (
        <div className={index === 0 ? `timing-group first ${isDarkMode && 'dark' || ''}` : `timing-group ${isDarkMode && 'dark' || ''}`}>
            <h6 className="mx-auto">
                <span className="timing">
                    {group}
                </span>
            </h6>
        </div>
    )
}

export default TimeGroup
