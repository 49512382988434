import React from "react";

import PromoSliderSm from "src/core/component/promos/PromoSliderSm"


const GeneralPromos = ({promos, title, isDarkMode, ...rest}) => {
    // console.log('General Promos....', promos)

    return (
        <div className="row">
            <div className="col-12">
                <h3 className="promos-title">{title}</h3>
                <div className={"pocket-promos__general" + (isDarkMode && ' dark' || '')}>
                    <div className="pocket-promos__general-slider-wrapper">
                        <PromoSliderSm
                            promosData={promos}
                            isDarkMode={isDarkMode}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralPromos;
