
export default function useDefaultProfile() {

    const _getDefaultProfile = () => {
        return {
            'address': 'Middletown, DE, USA',
            'latitude': '39.449556',
            'longitude': '-75.7163207',
            'timezone': 'America/New_York',
            'is_default': true
        }
    }

    return {
        get defaultProfile() {
            return _getDefaultProfile();
        }
    };
}
