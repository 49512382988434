import React from 'react'
import { Link } from 'react-router-dom'
import { MobileTopNavIcon } from 'src/core/component/assets/svg/CommonSVG';
import defaultImg from 'src/image/default.jpg';


const MobileNav = ({ isAuthenticated, login, profile, isDarkMode, toggleTheme, ...rest }) => {

    return (
            <>
                <ul className={"list-unstyled mb-0 d-sm-none d-flex align-items-center justify-content-end navigation__mobile-nav" + (isDarkMode && ' dark' || '' )}>
                { isAuthenticated ? (
                    <>
                        <li>
                            <Link to="/settings" className="user-img">
                            {profile.avatar_url ?
                                <img src={profile.avatar_url} className="img-fluid navigation__profile-img" alt="" referrerPolicy="no-referrer" />
                                :
                                <img src={defaultImg} className="img-fluid navigation__profile-img" alt="" referrerPolicy="no-referrer" />
                            }
                            </Link>
                        </li>
                    </>
                ):(
                    <>
                        <li>
                            <Link to="#" className="d-flex align-items-center" onClick={login}>
                                <MobileTopNavIcon />
                                Get Started
                            </Link>
                        </li>
                    </>
                )}
                </ul>
            </>
        )
}

export default MobileNav
