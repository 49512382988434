import { loadJSON } from 'src/lib/json-store';

// Inital state
const initialState = {
    accepted: loadJSON('cookie.accepted') || false,
};

// Action types
const ACCEPTED = 'ACCEPTED';

// Actions
export const acceptCookie = () => {
  return {
    type: ACCEPTED,
  };
}

// Reducer
export default function cookieReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPTED: {
      return {
        ...state,
        'accepted': true,
      };
    }

    default:
      return state;
  }
}
