import React from 'react'
import { LikeSvg, ReplySvg, TreadingSvg } from 'src/core/component/assets/svg/CommonSVG'


const ForumFilterItem = ({currentFilter,setCurrentFilter}) => {

    const handleFilterChange = (filter)=>{
      if (filter == currentFilter){
        setCurrentFilter('')
      }else{
        setCurrentFilter(filter)
      }
    }
  return (
   <>
        <div onClick={()=>{handleFilterChange('Trending')}} className={`filter ${currentFilter == 'Trending'?'active':''}`}><TreadingSvg/> Trending</div>
        <div onClick={()=>{handleFilterChange('Likes')}} className={`filter ${currentFilter == 'Likes'?'active':''}`}><LikeSvg/> Likes</div>
        {/* <div onClick={()=>{handleFilterChange('Replies')}} className={`filter ${currentFilter == 'Replies'?'active':''}`}><ReplySvg/> Replies</div> */}
   </>
  )
}

export default ForumFilterItem