import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";


const FileField = ({
        property,
        type="file",
        hasLabel=true,
        isSmall=true,
        hasPlaceholder=true, 
        label="",
        classes="", 
        placeholder="Select a file",
        isInvalid=false,
        error=`*Please select a valid ${property}`,
        isDarkMode=false,
        onChange,
        ...res
}) => {

    onChange = typeof onChange === 'function' ? onChange : function noop() { }

    const [fileUrl, setFileUrl] = useState(null);
    const inputRef = useRef();
    const [fileName, setFileName] = useState('No file choosen')
    
    useEffect(() => () => (inputRef.current.value = ""), []);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length) {
            let reader = new FileReader();
            let file = e.target.files[0];
            const { name: fileName, size } = file;
            const fileSize = (size / 1000).toFixed(2);
            const fileNameAndSize = `${fileName} - ${fileSize}KB`;
            setFileName(fileNameAndSize);

            reader.onloadend = () => {
                setFileUrl(reader.result);
            };
            reader.readAsDataURL(file);
            onChange(file);
            
        } else {
            setFileUrl(null);
            onChange(null);
            setFileName('No file choosen')
        }
    };

    // console.log('File URL', fileUrl);
    // console.log('Label', label);
    // console.log('File Name', fileName);

    return (
        <>  
            {
                hasLabel &&
                <label className="form-label" htmlFor={property}>{label}</label>
            }

            <div className={`pocket-file-input ${isDarkMode && 'dark' || ''}`}>
                <input type={type} ref={inputRef} id={property} className="pocket-file" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" />
                <label htmlFor={property}>
                    {placeholder}
                    <p className="pocket-file-name">{fileName}</p>
                </label>
            </div>
        </>
    )
}

export default FileField
