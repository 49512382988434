import React from "react"

import { useTheme } from "src/theme/component/ThemeContext";
import Banner from "src/core/component/Banner";
import CenteredDiv from "src/core/component/CenteredDiv";
import CenteredContainer from "src/core/component/grid/CenteredContainer";


const TOS = () => {

    const [isDarkMode, toggleTheme] = useTheme();

    return (
        <section className={"pocket-gdpr" + (isDarkMode && ' dark' || '' )}>
            <Banner classes={isDarkMode && 'dark' ||  ''}>
                <CenteredDiv classes={'title'} text={'Terms of Use'} />
            </Banner>

            <div className={"pocket-gdpr__contents"  + (isDarkMode && ' dark' || '' )}>
                <CenteredContainer colClasses={'col-sm-12 col-xl-10 col-xxl-9'}>
                    <div className="information mb-0 mb-md-4">

                        <div className="text">
                            <p>
                                We here Welcome YOU to POCKET PANDIT.COM, owned and operated by POCKET 
                                PANDIT INC., a private limited liability company registered under the Delaware General 
                                Corporation Law, 1899 having its Reg office at 600 N Broad Street, Suite 5, #3519, 
                                Middletown, DE 19709, USA.  
                            </p>
                            <p>
                                Whenever YOU avail of any of the services provided by POCKET PANDIT.COM, it shall be 
                                implied that YOU agree to the terms and conditions described herein. YOU will be subject to 
                                all compliance (including but not limited to the rules, guidelines, policies, terms, and 
                                conditions) when YOU use any of the services provided in connection with the site. All 
                                applicable compliances are regarded as integrated into and considered an important part of 
                                these Terms of Use. YOU agree to be bound by these terms and conditions by using the 
                                website.  
                            </p>

                            <p>
                                OUR SERVICES AND PRODUCTS ARE PROVIDED SUBJECT TO THE FOLLOWING 
                                TERMS AND CONDITIONS.  PLEASE READ THE TERMS CAREFULLY BEFORE 
                                ACCESSING OR USING OUR SERVICES. USING THE SERVICE IS DEEMED YOU 
                                GAVE CONSENT TO THE TERM OF USE AND OTHER AFFILIATED POLICIES.  
                            </p>

                            <h5 className="sub-heading">
                                PURPOSE AND SCOPE 
                            </h5>

                            <p>
                                The purpose of this Terms of Use is to establish a legal relation between POCKET PANDIT 
                                INC and YOU while using our services. This is a Standard Form of Contract and will be 
                                updated on this site from time to time. YOU shall be abided by the provisions of these Terms 
                                of Use once YOU use our services.  
                            </p>

                            <h5 className="sub-heading">
                                GENERAL TERMS 
                            </h5>

                            <p>
                                For the purpose of these Terms of Use, wherever the context so requires “COMPANY” 
                                “WE”, “US”, and “OUR” shall mean POCKET PANDIT INC., PVT.LTD. 
                            </p>
                            <p>
                                The terms "YOU" “YOUR” or "USER" shall mean any natural or legal person who has 
                                agreed to use POCKET PANDIT INC., Services
                            </p>
                            <p>
                                The terms “WEBSITE, CONTENT, PRODUCTS, GOODS and SERVICES (which together 
                                we refer to as the “Product(s)” or “Services(s)”) are made available worldwide by POCKET 
                                PANDIT INC., and its subsidiaries and affiliates.
                            </p>
                            <p>
                                The term “VENDOR(S)”, means a person or company offering POCKET PANDIT INC., for something to sell. 
                            </p>

                            <h5 className="sub-heading">
                                SERVICE 
                            </h5>

                            <p>
                                POCKET PANDIT INC. offers astrological content, reports, Games, Quizzes, Fortunes,  and 
                                Astrologers Consultations via the World Wide Web. Terms of service shall include GENERIC 
                                USER and PREMIUM USER. (more in details) (also about fees and charges for using the 
                                service). The content of Pocket Pandit shall be viewed by visiting the website, which allows 
                                you to access the ‘Free Services’, however you need to register as a member of Pocket Pandit 
                                to access the astrologers, and receive additional contents and services as a ‘ Premium User”.  
                            </p>

                            <h5 className="sub-heading">
                                AUTHORIZED USE  
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    In order to protect against abuse in order to maintain an informative and valuable service that 
                                    meets the needs objective of the website and to avoid the harm that can result from 
                                    disseminating false, malicious, infringing on others' rights, or otherwise harmful statements 
                                    YOU shall agree on:
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>(a) to abide by all applicable legislation, orders, regulations, rules, and other laws,</li>  
                                    <li>(b) not to use the Company Properties in any manner that is fraudulent or illegal;</li>
                                    <li>(c) not to interfere with or disrupt the Company's networks;</li>
                                    <li>(d) must follow all applicable network regulations, policies, and procedures; </li>
                                    <li>(e) not to upload, share, transmit, distribute, facilitate distribution of, or otherwise
                                    make available to or through the Website any illegal, infringing, harmful, harassing, 
                                    defamatory, threatening, intimidating, fraudulent, tortious, vulgar, or otherwise 
                                    objectionable material of any kind, including unauthorized or unsolicited advertising; </li>
                                    <li>(f) not to breach or attempt to breach the Website's security; </li>
                                    <li>(g) not to aid or permit anyone to breach this Agreement or any applicable legislation, </li>
                                    orders, regulations, rules, or other laws that govern the use of the Website. 
                                    <li>(h) not to modify, translate, merge, disassemble, decompile, any part of the Company
                                    Properties except to the extent the foregoing restrictions are expressly prohibited by 
                                    applicable law;</li>
                                    <li>(i) not to engage in any activity that interferes with the website or another user’s ability 
                                    to use the website; </li>
                                    <li>(j) not to use a robot, AI, or data mining or extraction tool to copy Website Content </li>
                                    <li>(k) YOU may not carry out any activities that may produce viruses, Trojan horses or 
                                    any other contaminating or destructive agent on the website. </li>
                                </ol>
                            </div>
                            
                            <h5 className="sub-heading">
                                ELIGIBILITY 
                            </h5>

                            <p>
                                Only those who can enter into legally binding contracts under relevant law are eligible to use 
                                our services. Without Limiting,  minors are not permitted to use the Services (which means, in most jurisdictions, those under the age of 18). Children under the Guardian's supervision, on the other hand, may be subjected to our services. Furthermore, the Company does not 
                                allow residents of (a) any jurisdiction that may restrict our Services, or (b) any country that is 
                                prohibited from engaging in trade dealings with the United States or its citizens by law, 
                                regulation, treaty, or administrative act from using the Services.  
                            </p>

                            <h5 className="sub-heading">
                                MEMBER REGISTRATION 
                            </h5>

                            <p>
                                Upon registration, YOU will receive a new POCKET PANDIT INC. identifier (ID) and 
                                password.  YOU must submit complete and correct information about YOURself during 
                                registration, including YOUR name, surname, email address,  phone number, age, and 
                                location. YOU are solely responsible for safeguarding the security of YOUR ID and 
                                password. YOU agree that YOUR account, ID, and password will not be sold or transferred 
                                to anyone else. YOU also promise to notify POCKET PANDIT INC. immediately if YOU 
                                become aware of any unauthorized use of YOUR account or any other security violation. 
                            </p>

                            <h5 className="sub-heading">
                                ELECTRONIC COMMUNICATION 
                            </h5>

                            <p>
                                While YOU use our POCKET PANDIT’s services to communicate with us through text 
                                messages through the chat box, you will be communicating with us electronically. You agree 
                                to receive communications from us by electronic means, such as emails, text, push 
                                notification, notifications of anything posted on our website.  
                            </p>

                            <h5 className="sub-heading">
                                INTELLECTUAL PROPERTY RIGHTS   
                            </h5>

                            <p>
                                All the "Content" available through the Website is protected by copyrights, trademarks, or 
                                other proprietary rights and laws. Content Includes images, illustrations, audio clips, video 
                                clips, text, software, music, sound, photographs, video, graphics, or all other Content and 
                                Services made available by the Company, its suppliers, and/or advertisers on or in, or 
                                purchased through, the Company Properties. YOU must not copy, reproduce, republish, 
                                upload, post, transmit, distribute or create derivative work of company properties without 
                                prior approval and YOU must not assist or encourage any other person to do so.   
                            </p>
                            <p>
                                All intellectual property rights in the Site, including all website design, text, graphics, photos, 
                                pictures, video, music, sound, and their selection and arrangement, as well as all software 
                                compilations, underlying source code, and software, will remain Company property and 
                                protected by National and International Copyright laws.  
                            </p>

                            <h5 className="sub-heading">
                                ACCOUNT
                            </h5>
                            <p> 
                                To use some of our premium services you might need your own POCKET PANDIT login 
                                account. YOU might also need to be signed in to the account and have a working payment 
                                method linked to it. YOU agree to bear liability for all the activities that take place under 
                                YOUR account, including preserving your account and password and limiting access to it. 
                            </p>

                            <h5 className="sub-heading">
                                COMPLIANCE WITH LAWS 
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    YOU shall agree on:  
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>(a)To comply with United States laws regarding the transmission of technical data exported from the US through the Service.</li>
                                    <li>(b)To comply with all applicable regulations, policies and procedures of networks connected to the POCKET PANDIT INC. website. </li>  
                                    <li>
                                        (c)To comply with YOUR domestic laws while using our service.
                                    </li>
                                    <li>
                                        (d)To comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding YOUR use of the Services. 
                                    </li>
                                    <li>
                                        (e)To comply not to constitute any criminal or civil liability due to unauthorized access to the site. 
                                    </li>
                                    <li>
                                        (f)To comply not to contribute any illegal, harassing,  vulgar, obscene, libellous, abusing, threatening, harmful, or otherwise objectionable content of any type or nature through our site.
                                    </li>
                                    <li>
                                        (g)To use our service for objective purposes. 
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                LIMITATION OF LIABILITY  
                            </h5>
                            <div className="lists">
                                <div className="text list-info mb-2">
                                    The Materials, Services, Products and Information provided through or in connection with 
                                    our website are provided without warranty of any kind, either express or implied. POCKET 
                                    PANDIT, its service providers, affiliates, associates, subsidiaries, and suppliers shall not 
                                    be liable for any losses incurred as a result of using the website, including but not limited to 
                                    direct, indirect, incidental, punitive, or consequential damages. POCKET PANDIT, shall not 
                                    be liable for indirect damage arising out of or in connection with; 
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>(a)The inability to use the Company properties; </li>
                                    <li>(b)Loss, alteration, or unauthorized access to YOUR transmission or data. </li>  
                                    <li>
                                        (c)Any behaviour of theft or destruction or negligence, failure of performance, error, 
                                        omission, interruption, deletion, defect, computer virus.  
                                    </li>
                                </ol>
                                <p>
                                    POCKET PANDIT INC., shall make no representations or warranties regarding any 
                                    comments or opinions expressed by YOU in comments or chat rooms or other forums 
                                    conducted on this site.  
                                </p>
                            </div>

                            <h5 className="sub-heading">
                                INDEMNIFICATION  
                            </h5>
                            <p>
                                YOU shall agree to indemnify, defend and hold harmless the company and its subsidiaries, 
                                affiliates, directors, employees, suppliers and any other company’s parties from any claim, 
                                liability, losses, or damages including but not limited to, a reasonable attorney’s fee arising 
                                from YOUR use of the company Properties, YOUR violation over privacy policy or these 
                                terms of use, or YOUR violation of any law, rules or regulations or the rights, including 
                                infringement of intellectual property rights or YOUR violation of any third party’s rights.
                            </p>

                            <h5 className="sub-heading">DISCLAIMER</h5>

                            <div className="lists">
                                <div className="text list-info mb-2">
                                    Every product or service purchased or licensed through the website is “as it is” shown and 
                                    described. The use of the company properties is at YOUR sole risk and YOU shall solely be 
                                    responsible for any products or services YOU used through our website. YOU further agree 
                                    on POCKET PANDIT:
                                </div>
                                <ol style={{listStyleType: 'none'}}>
                                    <li>(a)Any product(s) sold through our Services are not manufactured, stored, or inspected 
                                        by the company. We cannot and do not make any promises concerning the quality, 
                                        safety, or legality of the product(s) on Our site because they are produced, listed, and 
                                        sold directly by Vendors. 
                                    </li>
                                    <li>
                                        (b)The company shall not be held liable for the non-availability of products owing to a 
                                        shortage or delayed performance of services or damages or delays as a result of 
                                        products which are out of stock, unavailable or back-ordered deletion, misdelivery, or 
                                        failure to communicate with members due to a network outage.  
                                    </li>  
                                    <li>
                                        (c)The company does not sponsor or endorse the sale or purchase of any products or 
                                        services on the site, either implicitly or explicitly.   
                                    </li>
                                    <li>
                                        (d)The company is not liable for any failure to perform or breach of any contract 
                                        between YOU and the Vendor.  We cannot and do not guarantee that the concerned 
                                        Vendor will perform any transaction concluded on the Platform. We shall not and are 
                                        not required to mediate or resolve any dispute or disagreement between YOU and 
                                        Vendor.
                                    </li>
                                    <li>
                                        (e)YOU release POCKET PANDIT, from any claims related to the product(s) sold 
                                        through our Services, including for defective product(s), misrepresentations by 
                                        Vendors, or product(s) that cause physical injury.
                                    </li>
                                </ol>
                            </div>

                            <h5 className="sub-heading">
                                WARRANTY
                            </h5>
                            <p>
                                POCKET PANDIT DOES NOT EMPLOY OR REPRESENT ASTROLOGY ADVISORS. 
                                POCKET PANDIT INC. HAS NO CONTROL OVER THE QUALITY, RELEVANCE, OR 
                                ACCURACY OF ANY ADVICE OFFERED BY AN ADVISOR BECAUSE OF THEIR 
                                INDEPENDENT CONTRACTORS. ANY OPINION, ANSWER, READING, ADVICE, 
                                FORECAST, RECOMMENDATION, PREDICTION OR OTHER INFORMATION 
                                OFFERED BY ADVISORS IS NOT APPROVED, ADVISED, EVALUATED, 
                                WARRANTED, OR GUARANTEED BY US. WE DO NOT HAVE ANY DIRECT,
                                INDIRECT CONTROL OVER THE CONVERSATION THAT HAPPENS BETWEEN 
                                YOU AND ADVISORS. YOU ACKNOWLEDGE THAT ANY MATERIAL PROVIDED 
                                TO YOU BY ANY ADVISOR IS NOT A SUBSTITUTE FOR PROFESSIONAL ADVICE 
                                AND COUNSEL. POCKET PANDIT INC. WILL NOT BE ACCOUNTABLE IF YOU USE 
                                OUR SERVICES OR ADVICE FROM AN ADVISOR TO MAKE HEALTH, CAREER, 
                                FINANCIAL, OR OTHER DECISIONS. POCKET PANDIT INC., STRONGLY 
                                RECOMMENDS THAT ANYONE SEEKING MEDICAL OR MENTAL HEALTH 
                                COUNSELING SEE A QUALIFIED MEDICAL PROFESSIONAL.  
                            </p>
                            
                            <h5 className="sub-heading">
                                JURISDICTIONAL AREA 
                            </h5>
                            <p>
                                POCKET PANDIT INC., offers its services and products in many parts of the world. Some of 
                                all of the products and services offered on this website may not be available worldwide or 
                                may not be eligible in YOUR jurisdiction. This site is controlled and operated by POCKET 
                                PANDIT INC., from its office in the USA. We make no representation that any of the 
                                materials contained on our website are appropriate or available for use in other locations.  
                                Those who choose to access this site from other locations do so on their own initiative and 
                                are responsible for compliance with local laws, if and to the extent local laws are applicable. 
                                This agreement will be governed by and construed in compliance with the laws of Delaware, 
                                USA, and the parties agree to be subject to the jurisdiction of Delaware's courts. Any 
                                provisions of this agreement that are or may be declared invalid, unenforceable, or illegal will 
                                be ineffective only to the extent of such invalidity, unenforceability, or illegality, without 
                                affecting the validity, enforceability, or legality of the other provisions. 
                            </p>

                            <h5 className="sub-heading">
                                DISPUTE RESOLUTION  
                            </h5>
                            <p>
                                Any dispute, or claim arising out of, attributed to, or in connection with these Terms or the 
                                breach, termination, enforcement, interpretation, or validity, including the scope or 
                                applicability of this agreement, shall be resolved through binding arbitration in accordance 
                                with the Comprehensive Arbitration Rules and Procedures of DRAA (Delaware Rapid 
                                Arbitration Act, 2015). The arbitration shall be conducted by a single arbitrator or more 
                                arbitrators, selected in accordance with the rules of DRAA, and judgment on the arbitration 
                                award may be entered in a court of chancery thereof.  The seat of an arbitration is the State of 
                                Delaware. The arbitration award shall be final and binding upon the parties, and a decree on 
                                such award may be entered in any court having jurisdiction. Notwithstanding the foregoing, 
                                either party has the right to seek any interim or preliminary relief from a court of competent 
                                jurisdiction in Delaware in order to protect the rights of such party pending the completion of 
                                any arbitration hereunder. The fees and expenses of the arbitration shall be in accordance 
                                with DRAA. 
                            </p>

                            <h5 className="sub-heading">
                                INTERNATIONAL USER 
                            </h5>
                            <p>
                                Although our website is accessible from anywhere in the country, the services and products 
                                related to the company's properties may not be available in YOUR country. Our services in 
                                some parts or in full may not be available in all countries. The Company Properties are 
                                controlled and offered by the Company from external hosting facilities located in the United 
                                States of America. The company does not warrant whether the website is appropriate for use 
                                in YOUR particular jurisdiction or not. Those who choose to use our site are responsible for 
                                adhering to all applicable jurisdictions' legislation, orders, regulations, rules, and other laws. 
                            </p>

                            <h5 className="sub-heading">
                                TERMINATION 
                            </h5>
                            <p>
                                The website and this agreement may be terminated at any time by the Company. The 
                                company at any time, including without limitation, may suspend, limit or terminate all or 
                                portion of YOUR access to the site with or without notice and with or without cause if the 
                                company believes that YOU acted in a way that breaches or violates this agreement. The 
                                terms of this Agreement relate to authorized use, compliance with laws, intellectual property 
                                rights, warranty disclaimers, liability limits, and indemnity, as well as any other provisions 
                                that by their nature, shall survive any such termination. Upon termination, YOUR right to use 
                                the Service will immediately cease. YOU may simply stop using the Service if YOU wish to 
                                terminate YOUR account.
                            </p>

                            <h5 className="sub-heading">
                                CONTACTING US  
                            </h5>
                            <p>
                                For any queries regarding the policy, you can contact us at follows: 
                            </p>
                            <div className="contact-address mb-3">
                                M/S PocketPandit, INC<br/>
                                600 N Broad Street, Suite 5 # 3519<br/>
                                Middletown, DE 19709<br/> 
                                Email: <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a>   
                            </div>

                            <h5 className="sub-heading">
                                YOUR CONSENT 
                            </h5>
                            <p>
                                By using our site, you consent to our Terms of Use 
                            </p>

                        </div>
                        {/* <div className={"download" + (isDarkMode && ' dark' || '' )}>
                            <button className={"btn btn-download" + (isDarkMode && ' dark' || '' )}>
                                Download as PDF
                            </button>
                        </div> */}
                    </div>
                </CenteredContainer>

            </div>
        </section>
    )
}

export default TOS
