import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import './SimplePayment.css'

export default function SimplePayment({
  clientSecret: requestClientSecret,
  onSuccess,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);

  onSuccess = typeof onSuccess === 'function' ? onSuccess : function noop() { }

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    try {
      const intent = await requestClientSecret();
      const payload = await stripe.confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });

      if (payload.error) {
        throw new Error(payload.error.message);
      }

      setError(null);
      setProcessing(false);
      setSucceeded(true);

      onSuccess(intent);
    } catch (error) {
      setError(`Payment failed ${error.message}`);
      setProcessing(false);
    }
  };

  return (<form className="simple-payment bg-light" id="payment-form" onSubmit={handleSubmit}>
    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
    <button
      disabled={processing || disabled || succeeded}
      id="submit"
    >
      <span id="button-text">
        {processing ? (
          <div className="spinner" id="spinner"></div>
        ) : (
          "Pay now"
        )}
      </span>
    </button>
    {/* Show any error that happens when processing the payment */}
    {error && (
      <div className="card-error text-danger small" role="alert">
        <i className="fa fa-fw fa-exclamation"></i>
        {error}
      </div>
    )}
    {/* Show a success message upon completion */}
    <p className={succeeded ? "result-message" : "result-message hidden"}>
      Payment succeeded, Thank you for the payment!
    </p>
  </form>)
}
