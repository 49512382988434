import React, {useState, useEffect, useMemo} from 'react'
import { useLocation, useHistory } from 'react-router';
import queryString from "query-string";


import api from 'src/lib/api'
import { truncate } from 'src/util/extra';
import useAuthInfo from 'src/auth/hook/auth-info';
import { useSocket } from 'src/socket/component/SocketContext';
import ConversationListItem from './ConversationListItem';
import { PocketToast } from "src/core/component/toast/PocketToast";
import LoadingIcon from 'src/core/component/loader/LoadingIcon';
import SimpleAlert from "src/core/component/SimpleAlert";


const ConversationList = ({isDarkMode, setActiveItem, match, children, ...rest}) => {
    const location = useLocation();
    const history = useHistory();
    const authInfo = useAuthInfo();
    const socket = useSocket();
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    setActiveItem = typeof setActiveItem === 'function' ? setActiveItem : () => { }

    useEffect(() => {
        loadConversations();
    }, []);

    const loadConversations = async () => {
        if(!refreshing){
            setLoading(true);
        }

        try {
            const res = await api.get('v1/conversations');
            const conversationList = (res.data.result?.data || []);
            setConversations(conversationList);
            setLoading(false);
            setRefreshing(true);
        } catch (error) {
            PocketToast({
                type: "error", 
                message: "Error on fetching conversations."
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(conversations.length) {
            let newConversation;
            const query = queryString.parse(history.location.search);
            const conversationName = query?.c || '';

            if(conversationName) {
                newConversation = conversations.find(c => c['name'] == conversationName); 
            }

            if(!newConversation && match) {
                const first = conversations[0]
                history.push({
                    pathname: `/conversations/chat`,
                    search: '?c=' + first.name,
                });
            }
            
            setActiveConversation(newConversation);
        }
    }, [history.location, conversations]);

    useEffect(() => {
        activeConversation && setActiveItem(activeConversation);
    }, [activeConversation])

    const onNewMessage = (message, count) => {
        setConversations(state => {
            const index = state.findIndex(s => s.id == message.conversation_id);
            const conversation = state[index];
            if (!conversation) { return state; }

            conversation.astrologer_unread_message_count = count;
            return [
                ...state.slice(0, index),
                conversation,
                ...state.slice(index + 1),
            ];
        });
    }

    useEffect(() => {
        const onConversationCreate = ({ data: conversation }) => {
            setConversations(state => ([
                conversation,
                ...state.filter(s => s.id != conversation.id),
            ]));
        }
    
        const onConversationRemove = ({ data: conversation_id }) => {
            setConversations(state => ([
                ...state.filter(s => s.id != conversation_id),
            ]));
        }

        const handleNewMessage = ({ data: message, metadata }) => {
            onNewMessage(message, metadata?.astrologer_unread_count);
        }
    
        socket.on('chat_conversation:create', onConversationCreate);
        socket.on('chat_conversation:remove', onConversationRemove);
        socket.on('chat_message:create', handleNewMessage);
    
        return () => {
            socket.off('chat_conversation:create', onConversationCreate);
            socket.off('chat_conversation:remove', onConversationRemove);
            socket.off('chat_message:create', handleNewMessage);
        }
    
    }, []);
    
    useEffect(() => {
        const onConversationRenew = async ({ data }) => {
            try {
                const res = await api.get('v1/conversations/' + data.conversation_id);
                const renewedConversation = res.data.result?.data || null;
        
                if (activeConversation?.id === data.conversation_id) {
                    setActiveConversation(renewedConversation);
                }
        
                setConversations(state => {
                    const index = state.findIndex(s => s.id == data.conversation_id);
                    const conversation = state[index];
            
                    if (!conversation) { return [renewedConversation, ...state]; }
            
                    return [
                        ...state.slice(0, index),
                        renewedConversation,
                        ...state.slice(index + 1),
                    ];
                });
            } catch (error) {
                console.log('Error fetching conversation', error);
            }
        }
    
        socket.on('chat_conversation:renew', onConversationRenew);
    
        return () => {
            socket.off('chat_conversation:renew', onConversationRenew);
        }
    
    }, [activeConversation]);


    // set active conversation's astrologer unread message count to 0
    useEffect(() => {
        if(activeConversation) {
            setConversations(state => {
                const index = state.findIndex(s => s.id == activeConversation.id);
                const conversation = state[index];
                if (!conversation) { return state; }
    
                conversation.astrologer_unread_message_count = 0;
                return [
                    ...state.slice(0, index),
                    conversation,
                    ...state.slice(index + 1),
                ];
            });
        }
    }, [activeConversation])

    const handleConversationSelect = (conversation) => {
        if(conversation) {
            // loadConversations();

            history.push({
                pathname: `/conversations/chat`,
                search: '?c=' + conversation.name,
            });
        }
    }

    const currentConversations = useMemo(() => {
        return conversations && conversations.filter(item=>!item.expired) || [];
    }, [conversations]);

    const prevConversations = useMemo(() => {
        return conversations && conversations.filter(item=>item.expired) || [];
    }, [conversations]);

    // console.log('Conversations', conversations);
    // console.log('Current', currentConversations);
    // console.log('Prev Con', prevConversations);

    return (    
        <div className={`pocket-astrologer__chat-users-list ${isDarkMode && 'dark' || ''}`}>
            {loading && (
                <LoadingIcon />
            )}

            {!loading && conversations && (
                <>
                    {!conversations.length && (
                        <SimpleAlert classes="alert-danger">
                            <small className="text-sm">Sorry you don't have any conversations!</small>
                        </SimpleAlert>
                    )}

                    {currentConversations && currentConversations.length > 0 && (
                        <>
                            <h5 className={`heading ${isDarkMode && 'dark' || ''}`}>Active Conversations:</h5>
                            {(currentConversations || []).sort((conv1, conv2) => conv2.id - conv1.id).map((item) => {
                                return (
                                        <React.Fragment key={item.id}>
                                            <ConversationListItem
                                                item={item}
                                                activeClass={item.id == activeConversation?.id && 'active' || ''}
                                                handleSelection={handleConversationSelect}
                                                isDarkMode={isDarkMode}
                                                loadConversations={loadConversations}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    )}

                    {prevConversations && prevConversations.length > 0 && (
                        <>
                            <h5 className={`heading mt-3 ${isDarkMode && 'dark' || ''}`}>Previous Conversations:</h5>
                            {(prevConversations|| []).sort((conv1, conv2) => conv2.id - conv1.id).map((item) => {
                                return (
                                        <ConversationListItem
                                            key={item.name}
                                            item={item}
                                            message_type={item?.message_type}
                                            call_type={item?.call_type}
                                            duration={item?.duration}
                                            activeClass={item.id == activeConversation?.id && 'active' || ''}
                                            handleSelection={handleConversationSelect}
                                            isDarkMode={isDarkMode}
                                        />
                                    )
                                })
                            }
                        </>
                    )}
        
                </>
            )}
        </div >
    )
}

export default ConversationList