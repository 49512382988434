import React, {useState, useEffect} from "react";

import Banner from "src/core/component/Banner";
import CenteredDiv from "src/core/component/CenteredDiv";
import CenteredContainer from "src/core/component/grid/CenteredContainer";
import FAQAccordion from "./FAQAccordion";
import { useTheme } from "src/theme/component/ThemeContext";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import SimpleAlert from "src/core/component/SimpleAlert";
import api from "src/lib/api";


const FAQ = () => {

    const [isDarkMode] = useTheme();
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        fetchFaqs()
    }, [])

    const fetchFaqs = async () => {
        setLoading(true);
      
        try {
            const result = await api.get(`v1/faq`);
            setFaqs(result.data?.result?.data || [])
            setLoading(false);
        }
        catch (error) {
            setLoading(false)
            console.log('Error fetching FAQs')
        }
    }

    // console.log('FAQs..', faqs)

    return (
        <section className={"pocket-faq" + (isDarkMode && ' dark' || '' )}>
            <Banner classes={isDarkMode && 'dark' ||  ''}>
                <CenteredDiv classes={'title'} text={'FAQs'} />
            </Banner>

            <div className={"pocket-faq__contents" + (isDarkMode && ' dark' || '')}>
                <CenteredContainer colClasses={'col-sm-12'}>
                    {loading && (
                        <LoadingIcon />
                    )}
                    
                    {!loading && (
                        <>
                            {faqs && faqs.length > 0 && (
                                <FAQAccordion 
                                    faqData={faqs}
                                    isDarkMode={isDarkMode}
                                />
                            )}

                            {(!faqs || !faqs.length) && (
                                <SimpleAlert classes="alert-danger mb-5">
                                    <p>Sorry FAQs not found!!!</p>
                                </SimpleAlert>
                            )}

                        </>
                    )}
                </CenteredContainer>
            </div>
        </section>
    )
}

export default FAQ
