import React, { useRef } from "react";
import PropTypes from 'prop-types'

import ProfileCard from "./ProfileCard";
import Carousel from "src/core/component/slider/Carousel";
import NextArrow from "src/core/component/slider/arrow-customized/NextArrow";
import PreviousArrow from "src/core/component/slider/arrow-customized/PreviousArrow";


const ProfileSlider = ({ 
    profiles, 
    onClickEdit, 
    handleProfileClick, 
    profileIsSelected, 
    isRtp, 
    path, 
    search, 
    canDelete=false,
    canEdit=true,
    isSelf=true,
    handleDelete,
    isDarkMode, 
    ...rest 
}) => {

    handleDelete = typeof handleDelete === 'function' ? handleDelete : function noop(){ }
    const profileSlider = useRef(null);

    const settings = {
        infinite: false,
        autoplay: false,
        dots: false,
        arrows: true,
        variableWidth: true,
        centerMode: false,
        speed: 500,
        autoplaySpeed: 7000,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow astro={false} isDarkMode={isDarkMode} />,
        prevArrow: <PreviousArrow astro={false} isDarkMode={isDarkMode} />,
        className: isDarkMode ? 'pocket-slider birth-profile__slider dark' : 'pocket-slider birth-profile__slider',
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            }
        ]
    }

  return ( 
        <div className="row">
            <div className="col-12">
                <Carousel
                        sliderRef={profileSlider}
                        {...settings}

                        items={profiles.map((item, index) => (
                            <React.Fragment key={index}>
                                <ProfileCard 
                                    item={item} 
                                    isRtp={isRtp}
                                    path={path}
                                    search={search}
                                    relationData={item?.relationship} 
                                    handleProfileClick={handleProfileClick} 
                                    profileIsSelected={profileIsSelected(item)}
                                    isDarkMode={isDarkMode} 
                                    onClickEdit={onClickEdit}
                                    canDelete={canDelete}
                                    canEdit={canEdit}
                                    handleDelete={handleDelete}
                                    isSelf={isSelf}
                                />
                            </React.Fragment>
                        ))}
                    />

            </div>

        </div>
    )
}

ProfileSlider.defaultProps = {
    profiles: [],
    handleProfileClick: () => null,
    profileIsSelected: () => null
};

export default ProfileSlider
