export const kundaliLines1 = (w, h) => ([
  // inside
  {move: [w*3/10, h/9], line1: [w/2, h/9],line2:[w/2, h*3/10],line3:[w*3/10, h*3/10]},
  {move: [w/2, h/9], line1: [w-w*3/10, h/9],line2:[w-w*3/10, h*3/10],line3:[w/2, h*3/10]},
  {move: [w-w*3/10, h/9], line1: [w-w/10, h/9],line2:[w-w/10, h*3/10],line3:[w-w*3/10, h*3/10]},
  {move: [w-w/10, h*3/10], line1: [w-w/10, h/2],line2:[w-w*3/10, h/2],line3:[w-w*3/10, h*3/10]},
  {move: [w-w/10, h/2], line1: [w-w/10, h-h*3/10],line2:[w-w*3/10, h-h*3/10],line3:[w-w*3/10, h/2]},
  {move: [w-w/10, h-h*3/10], line1: [w-w/10, h-h/9],line2:[w-w*3/10, h-h/9],line3:[w-w*3/10, h-h*3/10]},
  {move: [w-w*3/10, h-h/9], line1: [w/2, h-h/9],line2:[w/2, h-h*3/10],line3:[w-w*3/10, h-h*3/10]},
  {move: [w/2, h-h/9], line1: [w*3/10, h-h/9],line2:[w*3/10, h-h*3/10],line3:[w/2, h-h*3/10]},
  {move: [w*3/10, h-h/9], line1: [w/10, h-h/9],line2:[w/10, h-h*3/10],line3:[w*3/10, h-h*3/10]},
  {move: [w/10, h-h*3/10], line1: [w/10, h/2],line2:[w*3/10, h/2],line3:[w*3/10, h-h*3/10]},
  {move: [w/10, h/2], line1: [w/10, h*3/10],line2:[w*3/10, h*3/10],line3:[w*3/10, h/2]},
  {move: [w/10, h/9], line1: [w*3/10, h/9],line2:[w*3/10, h*3/10],line3:[w/10, h*3/10]},
 
     // level 1
     {move: [w/3, 0], line1: [w/3*2, 0],line2:[w/3*2, h/9],line3:[w/3, h/9]},
     {move: [w/3*2, 0], line1: [w, 0],line2:[w-w/10, h/9],line3:[w/3*2, h/9]},
     {move: [w, 0], line1: [w, h/3],line2:[w-w/10, h/3],line3:[w-w/10, h/9]},
     {move: [w-w/10, h/3], line1: [w, h/3],line2:[w, h/3*2],line3:[w-w/10, h/3*2]},
     {move: [w-w/10, h/3*2], line1: [w, h/3*2],line2:[w, h],line3:[w-w/10, h-h/9]},
     {move: [w-w/10, h-h/9], line1: [w, h],line2:[w/3*2, h],line3:[w/3*2, h-h/9]},
     {move: [w/3*2, h-h/9], line1: [w/3*2, h],line2:[w/3,h],line3:[w/3,h-h/9]},
     {move: [w/3,h-h/9], line1: [w/3,h],line2:[0, h],line3:[w/10, h-h/9]},
     {move: [w/10, h-h/9], line1: [0, h],line2:[0, h/3*2],line3:[w/10, h/3*2]},
     {move: [0, h/3*2], line1: [0, h/3],line2:[w/10, h/3],line3:[w/10, h/3*2]},
     {move: [0,0], line1: [w/10, h/9],line2:[w/10, h/3],line3:[0, h/3]},
    {move: [0, 0], line1: [w/3, 0],line2:[w/3, h/9],line3:[w/10, h/9]},
 
]);

export const textStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
};
export const transitTextStyle = {
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  // height: "100%",
  flexWrap: "wrap",
  // color:'yellow',
  // fontFamily: "Montserrat",
};

export const zodiacStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexWrap: "wrap",
  // fontFamily: "Montserrat",
}

export const letterStyle = {
  marginRight: 4,
  marginLeft: 4
};

export const kundaliStyle = (width, height, scale) => ({
  margin: "auto",
  width: width * scale,
  height: height * scale,
  position: "relative",
});

const grahaStyle = scale => ({
  fontSize: Math.max(5 * scale, 6),
  lineHeight: 1.1,
  opacity: 1,
  color: '#808080',
  position: "absolute",
  cursor: "pointer",
});

const sthaanaStyle = scale => ({
  fontSize: Math.max(6 * scale, 10),
  fontWeight: 'normal',
  opacity: 1,
  color: '#333',
  position: "absolute",
  cursor: "pointer",
});

const codeStyle = scale => ({
  fontSize: Math.max(7 * scale, 8),
  fontWeight: 600,
  opacity: 1,
  color: '#929292',
  position: "absolute",
  cursor: "pointer",
})

export const gharaStyles = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 48 * scale,
      width: 80 * scale, 
      top: 44 * scale,
      left: 120 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 33 * scale,
      left: 154 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 32 * scale,
      left: 120 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 44 * scale,
      left: 200 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 33 * scale,
      left: 234 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 32 * scale,
      left: 200 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 44 * scale,
      left: 280 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 33 * scale,
      left: 314 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 32 * scale,
      left: 280 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 80 * scale,
      top: 96 * scale,
      left: 280 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 85 * scale,
      left: 314 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 84 * scale,
      left: 280 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 152 * scale,
      left: 280 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 141 * scale,
      left: 314 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 140 * scale,
      left: 280 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 80 * scale,
      top: 210 * scale,
      left: 280 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 199 * scale,
      left: 314 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 198 * scale,
      left: 280 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 80 * scale,
      top: 210 * scale,
      left: 200 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 199 * scale,
      left: 234 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 198 * scale,
      left: 200 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 49 * scale,
      width: 80 * scale,
      top: 210 * scale,
      left: 120 * scale, 
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 199 * scale,
      left: 154 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 198 * scale,
      left: 120 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 210 * scale,
      left: 40 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 199 * scale,
      left: 73 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 198 * scale,
      left: 40 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 152 * scale,
      left: 40 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 141 * scale,
      left: 73 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 140 * scale,
      left: 40 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 96 * scale,
      left: 40 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 85 * scale,
      left: 73 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 84 * scale,
      left: 40 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 50 * scale,
      width: 80 * scale,
      top: 44 * scale,
      left: 40 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 10 * scale,
      width: 10 * scale,
      top: 33 * scale,
      left: 73 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 13 * scale,
      width: 13 * scale,
      top: 32 * scale,
      left: 40 * scale,
    }
  }
];
export const gharaStyles2 = scale => [
  { //1
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 135 * scale,
    }
  },
  { //2
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 5 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 265 * scale,
    }
  },
  { //3
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 21 * scale,
      right: 20 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 20 * scale,
      right: 2 * scale,
    }
  },
  { //4
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      right:2 * scale,
    }
  },
  { //5
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 200 * scale,    
      left: 360 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 189 * scale,
      right: 30 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      right: 2 * scale,
    }
  },
  { //6
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 96 * scale,
      top: 252 * scale,    
      left: 276 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 268 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 265 * scale,
    }
  },
  { //7
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 100 * scale,
      top: 252 * scale,    
      left: 146 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 136 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 135 * scale,
    }
  },
  { //8
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 96 * scale,
      top: 252 * scale,    
      left: 47 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 251 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 268 * scale,
      left: 37 * scale,
    }
  },
  { //9
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 203 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 190 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 187 * scale,
      left: 2 * scale,
    }
  },
  { //10
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 110 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 97 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 94 * scale,
      left: 2 * scale,
    }
  },
  { //11
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 45 * scale,
      top: 37 * scale,    
      left: 1 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 25 * scale,
      left: 22 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 22 * scale,
      left: 2 * scale,
    }
  },
  { //12
    graha: {
      ...grahaStyle(scale),
      // height: 37 * scale,
      width: 96 * scale,
      top: 5 * scale,    
      left: 46 * scale,
    },
    sthaana: {
      ...sthaanaStyle(scale),
      height: 9 * scale,
      width: 9 * scale,
      top: 18 * scale,
      left: 38 * scale,
    },
    code: {
      ...codeStyle(scale),
      height: 12 * scale,
      width: 12 * scale,
      top: 2 * scale,
      left: 37 * scale,
    }
  },
  
 
 
  
  
  
  
  
 
  
];
