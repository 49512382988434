import React from "react";


const OrderStateItem = ({item, order, isDarkMode, ...rest }) => {
    
    return ( 
        <div className={"order-track__state-item" + (isDarkMode && ' dark' || '') + (item.isActive && ' active' || '')}>
           <div className="d-flex align-items-start">
                <span className="dot"></span>
                <span className="icon">{item.icon}</span>
                <p className="info d-flex flex-column">
                    <span className="name">{item.name}</span>
                    <span className="description">{item.description}</span>
                    <span className="datetime">{order?.date && new Date(order.date).format('hh:mm tt')}</span>
                </p>
           </div>
        </div>
    )
}

export default OrderStateItem
