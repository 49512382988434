import React, {useState, useEffect} from 'react'

import useAuthInfo from 'src/auth/hook/auth-info';
import { ChangeProfileIcon } from "src/core/component/assets/svg/CommonSVG";


const ConversationListItem = ({
    item, 
    activeClass, 
    isDarkMode, 
    handleSelection, 
    loadConversations, 
    ...rest
}) => {

    loadConversations = typeof loadConversations === 'function' ? loadConversations : () => { }

    const authInfo = useAuthInfo();
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expire_warn: false,
        expired: false,
    });

    useEffect(() => {
        let counter;
        if(!item.expired) {
            recalculate();
            counter = setInterval(recalculate, 1000);
        }

        return () => {
          clearInterval(counter);
        }
    }, [item, item.date_end]);
    
    const recalculate = () => {
        const finalDate = new Date(item.date_end).getTime();
        const diff = finalDate - new Date().getTime();

        // console.log('Diff', diff); //total milliseconds
    
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        const minutes = Math.floor(diff % (1000 * 60 * 60) / (1000 * 60));
        const seconds = Math.floor(diff % (1000 * 60) / 1000);

        if(diff <= 0) {
            loadConversations();
        }

        setCountdown({
            days: days, 
            hours: hours, 
            minutes: minutes, 
            seconds: seconds,
            expire_warn: diff <= 300000,
            expired: diff <= 0
        });

    }

    const { hours: h, minutes: m, seconds: s } = countdown;
    const [otherUser] = item.users.filter(u => u.id != authInfo?.profile?.id);

    // console.log('item', item)
    
    return (
        <div className={`pocket-astrologer__chat-users-list-item ${activeClass} ${isDarkMode && 'dark' || ''}`} onClick={handleSelection.bind(null, item)}>
            <div className="d-flex align-items-center">
                <div className="image">
                    { otherUser?.avatar_url && <img src={otherUser.avatar_url} className="img" /> || <ChangeProfileIcon />}
                </div>
                <div className="name">
                    {otherUser.name}
                </div>

                {item?.astrologer_unread_message_count > 0 && (
                    <span className={`badge ms-auto ${activeClass && 'd-none' || ''}`}>
                        {item?.astrologer_unread_message_count}
                    </span>
                )}

            </div>
            {!countdown.expired && countdown.expire_warn && (
                <div className={`message w-100  ${isDarkMode && 'dark' || ''} ${activeClass && 'd-none' || 'd-none d-sm-block'}`}>
                    <div className={`text-center timeout`}>
                        <span>
                            {m && `${m} minutes left` || s && `${s} seconds left`}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ConversationListItem