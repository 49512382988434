import React, {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router-dom';
import queryString from "query-string";

import api from 'src/lib/api';
import ChatForm from 'src/connect-with-astrologer/component/ChatForm';
import ChatMessageList from 'src/connect-with-astrologer/component/ChatMessageList';
import { useModal } from "src/core/hook/useModal";
import TimeAlertModal from 'src/core/component/chat/TimeAlertModal';
import useWindowSize from "src/core/hook/useWindowSize";
import useAuthInfo from 'src/auth/hook/auth-info';
import CustomerProfilesAndCharts from './CustomerProfilesAndCharts';
import { AngleDownSvg } from 'src/core/component/assets/svg/CommonSVG';
import { PocketToast } from "src/core/component/toast/PocketToast";
import { useSocket } from 'src/socket/component/SocketContext';
import CountDownTimer from 'src/connect-with-astrologer/component/CountDownTimer';
import useWidth from "src/core/hook/useWidth";


const AstrologerChatContainer = ({isDarkMode, match, ...rest}) => {
    const history = useHistory();
    const authInfo = useAuthInfo();
    const socket = useSocket();
    const chatContainerScrollToRef = useRef(null);
    const activeConversationRef = useRef(null);
    const [showChart, setShowChart] = useState(false);
    const [showKundali, setShowKundali] = useState(false);
    const [duration, setDuration] = useState(3);
    const [chartProfile, setChartProfile] = useState(null);
    const windowSize = useWindowSize();
    const [showTimeoutModal, handleTimeoutModalShow, handleTimeoutModalClose] = useModal(false);
    const [otherUser, setOtherUser] = useState(null);
    const [activeConversation , setActiveConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [conversationExpired, setConversationExpired] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const chartContainerRef = useRef(null);
    const [chartContainerWidth] = useWidth(chartContainerRef);

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        const conversationName = query?.c || '';
        if(conversationName) {
            fetchConversation(conversationName);
        } 
    }, [history.location])

    const fetchConversation = async (name) => {
        try {
            const result = await api.get(`/v1/conversations/${name}`);
            const conversation = result?.data?.result?.data || null;
            setActiveConversation(conversation);
        }
        catch (error) {
            setError(error?.message || 'Error on fetching conversation.');
            PocketToast({
                type: "error", 
                message: "Error on fetching conversation."
            })
        }
    }

    useEffect(() => {
        activeConversation && fetchConversationMessages();
        activeConversation && (activeConversationRef.current = activeConversation.id)
    }, [activeConversation])

    const fetchConversationMessages = async () => {
        try {
            const result = await api.get(`v1/conversations/${activeConversation?.name}/messages`);
            const messageData = result?.data?.result?.data || [];
            setMessages(messageData);
        }
        catch (error) {
            setError(error?.message || 'Error on fetching conversation messages.');
            PocketToast({
                type: "error", 
                message: "Error on fetching conversation messages."
            })
        }
    }

    useEffect(() => {
        if(messages.length) {
            const last = messages.at(-1);
            // console.log('Astrologer Last Message', last);
            socket.emit('chat_message:astro_last_message',  JSON.stringify({'data': last}))
        }
    }, [messages])

    useEffect(() => {
        if(activeConversation) {
            const customer = activeConversation.users.filter(u => u.id != authInfo.profile?.id) || [];
            if(customer && customer.length){
                setOtherUser(customer[0])
            }
        }
    }, [activeConversation])

    useEffect(() => {
        const onConversationRenew = async ({ data }) => {
            try {
                const res = await api.get('v1/conversations/' + data.conversation_id);
                const renewedConversation = res.data.result?.data || null;
        
                if (activeConversation?.id === data.conversation_id) {
                    setActiveConversation(renewedConversation);
                }
            } catch (error) {
                PocketToast({
                    type: "error", 
                    message: "Error on fetching conversation."
                })
            }
        }

        const onConversationRemove = ({ data: conversation_id }) => {
            if(activeConversation.id == conversation_id) {
                PocketToast({
                    type: "error", 
                    message: `Your conversation with ${activeConversation.user.name} has been removed.`
                })

                setActiveConversation(null);
            }
        }
    
        socket.on('chat_conversation:renew', onConversationRenew);
        socket.on('chat_conversation:remove', onConversationRemove);
    
        return () => {
          socket.off('chat_conversation:renew', onConversationRenew);
          socket.off('chat_conversation:remove', onConversationRemove);
        }
    
    }, [activeConversation]);

    useEffect(() => {
        const handleNewMessage = ({ data: message, metadata }) => {
          onNewMessage(message, +metadata?.local_id);
        }
    
        socket.on('chat_message:create', handleNewMessage);
    
        return () => {
          socket.off('chat_message:create', handleNewMessage);
        }
    
    }, []);

    const onNewMessage = (message, localId) => {
        if(message?.conversation_id){
        if (message.conversation_id != activeConversationRef.current) { return; }
    
        setMessages(state => {
            const msgIndex = state.findIndex(s => s.id == message.id || s.id == localId);
            if (msgIndex === -1) {
                return [...state, message];
            } else {
                return [
                    ...state.slice(0, msgIndex),
                    message,
                    ...state.slice(msgIndex + 1),
                ];
            }
        });
    }
    }

    const handleErrorSend = (error, localId) => {
        setMessages(state => {
          const msgIndex = state.findIndex(s => s.id == localId);
    
          if (msgIndex === -1) { return state; }
    
          return [
            ...state.slice(0, msgIndex),
            Object.assign(state[msgIndex], { error: error }),
            ...state.slice(msgIndex + 1),
          ];
        });
    }

    useEffect(() => {
        if(duration && duration <= 2) {
            if(windowSize && windowSize.width <= 575 && windowSize.width !== null) {
                handleTimeoutModalShow()
            }
        }
    }, [duration, windowSize])

    useEffect(() => {
        scrollToBottom();
    }, [messages])

    // reset chart profile when active conversation changed
    useEffect(() => {
        setChartProfile(null)
    }, [activeConversation])

    const scrollToBottom = () => {
        if (!chatContainerScrollToRef.current) { return; }
        // scroll to the element position
        chatContainerScrollToRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const handleChartToggle = () => {
        setShowChart((prevState) => !prevState);
    }

    const handleChangeProfile = (e) => {
        e.preventDefault();
        setShowKundali(false);
    }

    
    return (
       <div className={`pocket-astrologer__chat-playground ${isDarkMode && 'dark' || ''}`} ref={chartContainerRef}>
            {activeConversation && (
                <>
                    <div className={`pocket-astrologer__chat-playground-header ${isDarkMode && 'dark' || ''}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            {Date.now() < new Date(activeConversation.date_end).getTime() && (
                                <CountDownTimer
                                    datetime={activeConversation.date_end}
                                    setConversationExpired={setConversationExpired}
                                    isDarkMode={isDarkMode}
                                    title={'Time Left'}
                                    className={'astrologer'}
                                /> 
                            ) || (<div className="timer-countdown"><span className="timer expired">Expired</span></div>)}
                        </div>
                    </div>
                    <div className={`pocket-astrologer__chat-playground-content ${isDarkMode && 'dark' || ''}`}>
                        <div className={`pocket-astrologer__chat-conversations astrologer ${isDarkMode && 'dark' || ''}`}>
                            <ChatMessageList 
                                isDarkMode={isDarkMode}
                                conversation={activeConversation}
                                messages={messages}
                            />

                            <div ref={chatContainerScrollToRef} className="chat-content-scroll-ref">
                            </div>
                        </div>
                        <div className={`pocket-astrologer__chat-form position-relative ${isDarkMode && 'dark' || ''}`}>
                            {/* birth profiles and kundali */}
                            {showChart && (
                                <CustomerProfilesAndCharts
                                    user={otherUser}
                                    chartProfile={chartProfile}
                                    setChartProfile={setChartProfile}
                                    handleChangeProfile={handleChangeProfile}
                                    showKundali={showKundali}
                                    setShowKundali={setShowKundali}
                                    width={chartContainerWidth}
                                    isDarkMode={isDarkMode}
                                />
                            )}

                            {/* Chart toggler */}
                            <div className="chart-toggler d-flex align-items-center justify-content-end">
                                <div className="toggler d-flex align-items-center" onClick={handleChartToggle}>
                                    View Kundali
                                    <AngleDownSvg />
                                </div>
                            </div>

                            <ChatForm
                                isDarkMode={isDarkMode}
                                conversation={activeConversation}
                                onSent={onNewMessage}
                                onError={handleErrorSend}
                                expired={conversationExpired}
                            />
                        </div>
                    </div>

                    {/* Time Remaining Alert Modal */}
                    {/* {showTimeoutModal && (
                        <TimeAlertModal
                            showModal={showTimeoutModal}
                            handleModalClose={handleTimeoutModalClose}
                            isDarkMode={isDarkMode}
                            duration={2}
                            user={user}
                        />
                    )} */}
                </>
            )}

        </div>
    )
}

export default AstrologerChatContainer