import React from "react";

import Modal from 'react-bootstrap/Modal';
import { CloseButtonSvg, ProcessingSuccessSvg } from "src/core/component/assets/svg/CommonSVG";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import ModalBody from "src/core/component/modal/ModalBody";


const PaymentProcessingPopup = ({
    showModal,
    handleModalClose,
    success,
    processing,
    showCloseIcon, 
    isDarkMode,
    size="lg",
    ...rest
}) => {

    return (
            <Modal show={showModal} onHide={handleModalClose} className={`payment-processing__modal ${size==='md' && 'size-medium' || ''}` + (isDarkMode && ' dark' || '')} size={size} backdrop="static">
                {
                    showCloseIcon &&
                    <div className="modal-custom-close" onClick={handleModalClose}>
                        <CloseButtonSvg />
                    </div>
                }

                <ModalBody>
                    <div className={"payment-status" + (isDarkMode && ' dark' || '')}>
                        <div className="text-center">
                            {processing && (
                                <>
                                    <LoadingIcon />
                                    <h5 className="text processing">Processing...</h5>
                                </>
                            )}

                            {success && (
                                <div className="success">
                                    <ProcessingSuccessSvg />
                                    <h5 className="text done">Payment Done</h5>
                                </div>
                            )}

                        </div>
                        
                    </div>
                </ModalBody>

            </Modal>
    )
}

export default PaymentProcessingPopup