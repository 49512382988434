import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger,Popover } from "react-bootstrap";
import {InfoIcon} from 'src/core/component/assets/svg/CommonSVG'

const FormField = ({
  property,
  value,
  type = "text",
  hasLabel = true,
  isSmall = true,
  hasPlaceholder = true,
  hasInstruction = false,
  hasLink = false,
  hasIcon = false,
  label = "",
  classes = "",
  instruction = "",
  placeholder = "",
  icon = "",
  isInvalid = false,
  required = false,
  disabled = false,
  pk_disabled = false,
  autoComplete = "off",
  error = `*Please enter a valid ${property}`,
  min = "0",
  step = 1,
  onChange,
  onClick,
  maxLength = "",
  ...res
}) => {
  onClick = typeof onClick === "function" ? onClick : function noop() {};

  const popover = (
    <Popover id="popover-basic" style={{zIndex:9999999}}>
      <Popover.Body>
        You are currently creating a <strong>Prashna Kundali</strong> (Question Chart)
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {hasLabel && (
        <label className="form-label" htmlFor={property}>
          {label}
          {disabled==true && pk_disabled ?
            <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                    <span>&nbsp;<InfoIcon/></span>
            </OverlayTrigger>:null
            }
        </label>
      )}

      {hasIcon ? (
        <div className="input-group">
          <input
            type={type}
            className={
              "form-control" +
              ((isInvalid && " is-invalid") || "") +
              (isSmall && " form-control-sm")
            }
            placeholder={placeholder}
            name={property}
            value={value}
            id={property}
            onChange={onChange}
            required={required}
            autoComplete={autoComplete}
            disabled={disabled}
            maxLength={maxLength}
            min={type === "number" ? min : null}
            step={type === "time" ? step : null}
          />
          <span className="input-group-text">{icon}</span>
          <div className="invalid-feedback">{error}</div>
        </div>
      ) : (
        <>
          <input
            type={type}
            className={
              "form-control" +
              ((isInvalid && " is-invalid") || "") +
              (isSmall && " form-control-sm")
            }
            placeholder={placeholder}
            name={property}
            id={property}
            value={value}
            onChange={onChange}
            required={required}
            autoComplete={autoComplete}
            disabled={disabled}
            maxLength={maxLength}
            min={type === "number" ? min : null}
            step={type === "time" ? step : null}
          />
          <div className="invalid-feedback">{error}</div>
        </>
      )}

      {hasInstruction && (
        <div className="form-text">
          {instruction}

          {hasLink && (
            <Link to="#" onClick={onClick} className="form-text-link">
              click here
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default FormField;
