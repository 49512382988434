import React, { useRef } from "react";
import PropTypes from 'prop-types'


import Carousel from "src/core/component/slider/Carousel";
import NextArrow from "src/core/component/slider/arrow-customized/NextArrow";
import PreviousArrow from "src/core/component/slider/arrow-customized/PreviousArrow";
import KundaliSliderItem from "./KundaliSliderItem";


const KundaliSlider = ({
    kundali_data,
    config,
    profile,
    scale,
    isDarkMode,
    ...rest
}) => {

    const settings = {
        infinite: false,
        autoplay: false,
        dots: false,
        arrows: true,
        variableWidth: false,
        centerMode: false,
        speed: 500,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow astro={false} isDarkMode={isDarkMode} />,
        prevArrow: <PreviousArrow astro={false} isDarkMode={isDarkMode} />,
        className: isDarkMode ? 'pocket-kundali__slider dark' : 'pocket-kundali__slider',
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12">
                <Carousel
                        {...settings}
                        items={kundali_data.map((item, index) => (
                            <React.Fragment key={index}>
                                <KundaliSliderItem
                                    data={item.data}
                                    variant={item.variant}
                                    title={item.title}
                                    config={config}
                                    profile={profile}
                                    scale={scale}
                                    isDarkMode={isDarkMode}
                                />
                            </React.Fragment>
                        ))}
                    />

            </div>

        </div>
    )
}

export default KundaliSlider
