import React from "react";
import MarkdownParse from "src/core/component/MarkdownParse";

import AccordionCommon from "src/core/component/accordion/AccordionCommon";
import AccordionHeader from "src/core/component/accordion/AccordionHeader";
import AccordionItem from "src/core/component/accordion/AccordionItem";
import AccordionBody from "src/core/component/accordion/AccordionBody";

const FAQAccordion = ({ faqData, isDarkMode, ...rest }) => {
  return (
    <AccordionCommon
      classes={"faq-accordion" + ((isDarkMode && " dark") || "")}
    >
      {faqData &&
        faqData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <AccordionItem
                id={item.id}
                classes={
                  "faq-accordion__item" + ((isDarkMode && " dark") || "")
                }
              >
                <AccordionHeader title={item.title}></AccordionHeader>

                <AccordionBody classes={(isDarkMode && " dark") || ""}>
                  <div className="faq-questions">
                   {item?.description && (
                  <MarkdownParse markdownText={item.description}/>
               )} 
                 </div>
               
                </AccordionBody>
              </AccordionItem>
            </React.Fragment>
          );
        })}
    </AccordionCommon>
  );
};

export default FAQAccordion;
