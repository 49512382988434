import React from "react";
import { Link } from 'react-router-dom';

import LogoDark from "src/core/component/LogoDark";
import banner from 'src/image/login-signup/auth-banner.jpg'

const AuthBanner = () => {

  return (
        <div className="pocket-auth__banner" style={{'backgroundImage': `url(${banner})`}}>
            <div className="pocket-auth__logo mx-auto">
                <Link to="/">
                    <LogoDark />
                </Link>
            </div>
        </div>
    )
}

export default AuthBanner;
