import React from "react";
import { Link } from "react-router-dom";

import MobilePrevNextButton from "src/core/component/MobilePrevNextButton";
import Login from "./Login";
import Register from "./Register";

const TabManager = ({
  tab,
  setTab,
  location,
  isDarkMode,
  prevLink = "/instant-prediction",
  ...rest
}) => {

  const handleTabClicked = (clickedTab) => {
    setTab(clickedTab);
  };

  return (
    <div className={"register-login__tabs" + ((isDarkMode && " dark") || "")}>

      <div
        className={
          "d-flex justify-content-start align-items-center tab-menu" +
          ((isDarkMode && " dark") || "")
        }
      >
        <div
          className={
            "tab-item" +
            ((tab === "register" && " active") || "") +
            ((isDarkMode && " dark") || "")
          }
          onClick={() => {
            handleTabClicked("register");
          }}
        >
          Signup
        </div>
        <div
          className={
            "tab-item" +
            ((tab === "login" && " active") || "") +
            ((isDarkMode && " dark") || "")
          }
          onClick={() => {
            handleTabClicked("login");
          }}
        >
          Login
        </div>
      </div>

      {tab === "register" && (
        <Register
          setTab={setTab}
          isDarkMode={isDarkMode}
        />
      )}

      {tab === "login" && (
        <Login
          setTab={setTab}
          isDarkMode={isDarkMode}
        />
      )}

      <div className="back-btn my-3 d-none d-sm-block">
        <Link
          to={prevLink}
          className={"prev-link" + ((isDarkMode && " dark") || "")}
        >
          Back
        </Link>
      </div>

      <MobilePrevNextButton
        classes={"my-3 d-sm-none"}
        hasPrev={true}
        hasNext={false}
        isDarkMode={isDarkMode}
        prevLink={prevLink}
        nextLink={"#"}
      />
    </div>
  );
};

export default TabManager;
