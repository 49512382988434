import React from 'react'
import { getZodiacIcon } from "src/core/component/GetZodiacIcon";

export const dms_str = (dms, depth = 3) => {
    const signs = ['°', "'", '"'];
    return dms.filter((__, i) => i < depth).map((v, i) => `${v}${signs[i]}`).join('');
}

export const pada_ordinal = (num) => {
    return ["1st", "2nd", "3rd", "4th"][+num - 1];
}

export const get_benefic_malefic = (arr, ascendantZodiacCode) => {
    let classes = {};
    arr.filter((item) => item.zodiac_sign.code === ascendantZodiacCode)?.[0]?.functional_benefic_malefics?.map((item) => {
        classes = {
            ...classes,
            [item.planet.name]: item.state
        }
    });

    return classes;
}

export const chartData = (data, preference) => {
    let houses = [];

    if (preference['kundali_variant'].toLowerCase() !== "north" && preference['name'] === "rasi") {
        houses = data.sort((a, b) => a?.zodiac?.code - b?.zodiac?.code);
    } else {
        houses = data;
    }

    houses = data && data.map((house) => {
        let housePlanets = house['planets'];
        let code = preference['name'] === 'rasi' ? house?.zodiac?.code + 1 : house['code'];
        let label;

        if (preference['modern_planet'] === 'exclude') {
            housePlanets = housePlanets.filter(p => [8, 9, 10].includes(p['code']) === false);
        }

        if (preference['rahu_ketu_position'] === 'mean_position') {
            housePlanets = housePlanets.filter(p => [12, 14].includes(p['code']) === false);
        } else if (preference['rahu_ketu_position'] === 'true_position') {
            housePlanets = housePlanets.filter(p => [11, 13].includes(p['code']) === false);
        }

        // console.log('HousPlanets....', housePlanets)

        let benefic_malefic = get_benefic_malefic(preference.benefic_malefic_data, preference.ascendant.zodiac.code);

        housePlanets = housePlanets.map(
            p => {
                let dignity = [
                    preference.retrograde && p.motion < 0 ? "R" : false,
                    preference.combustion && p.is_combustion ? "C" : false
                ].filter(Boolean)
                    .join("");

                const benefic_malefic_class = ((preference.benefic_malefic && benefic_malefic && benefic_malefic[p.name]) ? (" " + benefic_malefic[p.name]) : "");

                return (
                    <>
                        <span className={"graha" + benefic_malefic_class}> {p['name'].substring(0, 2)} </span>
                        {
                            dignity ? <small>{dignity ? "(" + dignity + ")" : ""}</small> : ""
                        }
                        {
                            preference?.degrees ? <span className="dms"> {dms_str(p['dms'], 2)} </span> : " "
                        }
                        {
                            preference?.nakshatra ? <span className="nakshatra"> {p['nakshatra']['name'].substring(0, 2)} </span> : " "
                        }
                        {
                            preference?.nakshatra_pada ? <small>({pada_ordinal(p['pada']['code'])})</small> : " "
                        }
                    </>
                )
            }
        );

        if (preference['name'] === 'rasi') {
            if (preference['zodiac_names']) {
                label = <span style={{ color: "#D49369", fontWeight: 700, fontSize: 11 }}>{house['zodiac']['name'].substring(0, 3).toUpperCase()}</span>;
            } else {
                label = getZodiacIcon(house?.zodiac?.code);
            }
        } else {
            label = '';
        }

        return [label, housePlanets, code];
    });

    return houses
}

export const prepareKundaliData = (responseData, config, formData, chartToggleData, beneficMaleficData) => {
    if (!responseData?.result) {
        return {
            houses: [],
            planetsInfo: [],
            planets: [],
        }
    };

    const metadata = responseData['result']['metadata'];
    let configData = {
        'modern_planet': config.modern_planets,
        'rahu_ketu_position': config.rahu_ketu,
        'name': formData.name,
        'kundali_variant': chartToggleData.variant,
        'retrograde': chartToggleData.retrograde,
        'combustion': chartToggleData.combustion,
        'degrees': chartToggleData.degrees,
        'nakshatra': chartToggleData.nakshatra,
        'zodiac_names': chartToggleData.zodiac_names,
        'nakshatra_pada': chartToggleData.nakshatra_pada,
        'benefic_malefic': chartToggleData.benefic_malefic,
        'benefic_malefic_data': beneficMaleficData,
        'ascendant': metadata.ascendant
    }
    const resData = [...responseData.result.data]
    const houses = chartData(resData, configData)

    const planetsInfo = responseData.result.data.map(
        (h) => h['planets'].map(p => Object.assign(p, {
            'house': {
                'code': h['code'],
                'name': h['name'],
            }
        }))
    )
        .flat()
        .filter((p) => {
            if (config['modern_planets'] === 'exclude') {
                return [8, 9, 10].includes(p['code']) === false;
            }

            return true;
        })
        .filter((p) => {
            if (config['rahu_ketu'] === 'mean_position') {
                return [12, 14].includes(p['code']) === false;
            } else if (config['rahu_ketu'] === 'true_position') {
                return [11, 13].includes(p['code']) === false;
            }

            return true;
        })
        .filter((p) => {
            if (Number(formData['first_house_planet']) != 0) {
                return p['code'] != 0;
            }

            return true;
        });

    let filteredPlanets = [];

    if (config['rahu_ketu'] === 'mean_position') {
        filteredPlanets = planetsInfo.filter(p => [12, 14].includes(p['code']) === false);
    } else if (config['rahu_ketu'] === 'true_position') {
        filteredPlanets = planetsInfo.filter(p => [11, 13].includes(p['code']) === false);
    }

    return {
        houses: houses,
        planetsInfo: planetsInfo,
        planets: filteredPlanets,
    }
}