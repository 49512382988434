import React from "react";

import AccordionCommon from "src/core/component/accordion/AccordionCommon";
import AccordionHeader from "src/core/component/accordion/AccordionHeader";
import AccordionItem from "src/core/component/accordion/AccordionItem";
import AccordionBody from "src/core/component/accordion/AccordionBody";
import QuestionList from "src/core/component/question/QuestionList";
import ViewMoreLink from "src/promos/component/promodetails/ViewMoreLink";


const RelationshipQuestions = ({
    categories,
    questions,
    handleQuestionSelect, 
    profile, 
    handleBrowseMore,
    isDarkMode, 
    ...rest}) => {


    return (
        <AccordionCommon classes={'question-accordion' + (isDarkMode && ' dark' || '')}>
            {
                categories && categories.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <AccordionItem id={item.id} classes={'question-accordion__item' + (isDarkMode && ' dark' || '')}>
                                <AccordionHeader title={`Here are the ${item.name.toLowerCase()} question you can ask`}>
                                </AccordionHeader>

                                <AccordionBody classes={isDarkMode && ' dark' || ''}>
                                    <QuestionList 
                                        questions={(questions?.length && questions.filter(q => q.category_id==item.id) || []).slice(0,4)}
                                        handleQuestionSelect={handleQuestionSelect}
                                        profile={profile}
                                        isDarkMode={isDarkMode}
                                    />
                                    
                                    {(questions?.length && questions.filter(q => q.category_id==item.id) || []).length > 4 && (
                                        <div>
                                            <ViewMoreLink 
                                                title={'view more questions'}
                                                url={'/realtime-prediction?stage=question'}
                                                onClick={handleBrowseMore}
                                                className={`d-flex justify-content-end`}
                                                isDarkMode={isDarkMode}
                                            />
                                        </div>
                                    )}

                                </AccordionBody>
                            </AccordionItem>
                        </React.Fragment>
                    )
                })
            }
        </AccordionCommon>
    )
}

export default RelationshipQuestions
