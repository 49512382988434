import React, {useState} from "react"
import { useHistory } from "react-router";

import CommonModal from "src/core/component/modal/CommonModal";
import ModalBody from "src/core/component/modal/ModalBody";
import { SuccessMessageSvg } from "src/core/component/assets/svg/CommonSVG";
import api from 'src/lib/api';
import { useAlert } from "src/core/hook/Alert";
import DismissableAlert from "src/core/component/DismissableAlert";
import OtpInputForm from "src/auth/component/forms/OtpInputForm";
import NewPasswordForm from "src/auth/component/forms/NewPasswordForm";


export default function CreateNewPassword({isDarkMode}) {
    const history = useHistory();
    const [showAlert, setShowAlert, handleAlertClose] = useAlert(false);
    const [showModal, setShowModal] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [otp, setOtp] = useState(null)

    const [passwordResetData, setPasswordResetData] = useState({
       'password': '',
       'confirm_password': ''
    });

    const onChangePasswordInput = (e) => {
        setPasswordResetData({
            ...passwordResetData,
            [e.target.name]: e.target.value,
        });
    }

    const handleResetFormSubmit = (e) => {
        if(processing) return;
        e.preventDefault();
        if(passwordResetData['password'] != passwordResetData['confirm_password']) {
            setError('Password mismatched!');
            setShowAlert(true);
            return;
        }
        if(!otp) {
            setError('Invalid OTP!');
            setShowAlert(true);
            return;
        }

        const payload = {
            "otp_code": otp,
            "password": passwordResetData['password']
        }
        setProcessing(true);
        resetPassword(payload).catch(e=> {
            setError(e?.response?.data?.error?.message || e?.message);
            setShowAlert(true);
            setProcessing(false);
        })
    }

    const resetPassword = async (payload) => {
        const result = await api.post('v1/password-reset', payload)
        setProcessing(false)
        if(result?.status === 200) {
            setShowModal(true)
        }
    }

    const handleOtpSubmit = (e) => {
        if(processing) return;
        e.preventDefault();
        if(!otp){
            setError('OTP required!');
            setShowAlert(true);
            return;
        }
        if(otp.length > 6){
            setError('OTP cannot be greater than 6 digits!');
            setShowAlert(true);
            return;
        }
        const payload = {"otp_code": otp};
        setProcessing(true);
        verifyOtp(payload).catch(e=> {
            setError(e?.response?.data?.error?.message || e?.message)
            setShowAlert(true)
            setProcessing(false)
        })
    }

    const verifyOtp = async (payload) => {
        const result = await api.post('v1/verify-password-reset', payload)
        setProcessing(false)
        if(result?.status === 200) {
            setOtpVerified(true)
        }
    }

    const handleModalClose = () => {
        setShowModal(false);

        setPasswordResetData({
            ...passwordResetData,
            'password': '',
            'confirm_password': ''
        });

        history.push('/auth/login');
    }

    // console.log('Password Reset Data', passwordResetData)
    // console.log('OTP', otp)

    return (
        <div className={"pocket-auth__new-password" + (isDarkMode && ' dark' || '')}>
            <div className="row justify-content-center">
                <div className={ otpVerified ? "col-sm-12" : 'col-sm-9 col-md-9 col-lg-9 col-xl-10' }>
                    <div className={"card new-password" + (isDarkMode && ' dark' || '')}>
                        
                        {showAlert && (
                            <DismissableAlert text={error} handleAlertClose={handleAlertClose} classes={'dismissable-alert alert-error'}>
                            </DismissableAlert>
                        )}

                        <h4 className="heading secondary">Create New Password</h4>
                        {!otpVerified && (
                            <OtpInputForm 
                                processing={processing}
                                setOtp={setOtp}
                                handleOtpSubmit={handleOtpSubmit}
                                isDarkMode={isDarkMode}
                            />
                        )}

                        {otpVerified && (
                            <NewPasswordForm 
                                formData={passwordResetData}
                                onChangePasswordInput={onChangePasswordInput}
                                handleResetFormSubmit={handleResetFormSubmit}
                                processing={processing}
                                isDarkMode={isDarkMode}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Email/Code sent modal */}
            <CommonModal showModal={showModal} handleModalClose={handleModalClose} size={"md"} showCustomClose={true} centered={false} classes={'pocket-auth__modal'  + (isDarkMode && ' dark' || '')} backdrop="static">
                <ModalBody>
                    <div className="card">
                        <div className="icon change-successful mx-auto">
                            <SuccessMessageSvg />
                        </div>
                        <p className="card-text">Your password has been successfully changed</p>
                    </div>
                </ModalBody>
            </CommonModal>
        </div>
    )
}
