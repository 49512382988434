import React from "react";
import { Link } from "react-router-dom";

import SimpleAlert from "src/core/component/SimpleAlert";

const NotAuthenticatedAlert = ({ path, isDarkMode, ...rest }) => {
  return (
    <SimpleAlert
      classes={`pocket-alert common ${(isDarkMode && "dark") || ""}`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0  alert-text">Seems like you are not authenticated</p>
        <Link to={path} className="go-to">
          Authenticate
        </Link>
      </div>
    </SimpleAlert>
  );
};

export default NotAuthenticatedAlert;
