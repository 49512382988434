import React from "react"

export const DoneSvg = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.21429 10.0839H9.78571C10.1786 10.0839 10.5 10.4053 10.5 10.7982C10.5 11.1911 10.1786 11.5125 9.78571 11.5125H1.21429C0.821429 11.5125 0.5 11.1911 0.5 10.7982C0.5 10.4053 0.821429 10.0839 1.21429 10.0839ZM4.79286 7.15534C4.52425 7.42047 4.16165 7.56853 3.78424 7.5672C3.40683 7.56586 3.04529 7.41523 2.77857 7.1482L1.21429 5.58392C0.821429 5.19106 0.828572 4.55535 1.23571 4.17677C1.62143 3.80535 2.23571 3.81963 2.60714 4.19106L3.78571 5.36963L8.37857 0.776774C8.76429 0.391059 9.38571 0.391059 9.77143 0.776774L9.8 0.805345C10.1857 1.19106 10.1857 1.81963 9.79286 2.20534L4.79286 7.15534Z" fill="#061C99"/>
        </svg>
    )
}

export const AddImageSvg = () => {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5157 0H2.16841C0.972531 0 0 0.897 0 2V14C0 15.103 0.972531 16 2.16841 16H19.5157C20.7115 16 21.6841 15.103 21.6841 14V2C21.6841 0.897 20.7115 0 19.5157 0ZM4.87892 3C5.31024 3 5.7239 3.15804 6.02889 3.43934C6.33388 3.72064 6.50522 4.10218 6.50522 4.5C6.50522 4.89782 6.33388 5.27936 6.02889 5.56066C5.7239 5.84196 5.31024 6 4.87892 6C4.4476 6 4.03394 5.84196 3.72895 5.56066C3.42395 5.27936 3.25261 4.89782 3.25261 4.5C3.25261 4.10218 3.42395 3.72064 3.72895 3.43934C4.03394 3.15804 4.4476 3 4.87892 3ZM10.842 13H3.25261L7.58943 8L9.21573 10L12.4683 6L18.4315 13H10.842Z" fill="#929292"/>
        </svg>

    )
}

export const SendMessageSvg = () => {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16.5" r="16" fill="#061C99"/>
            <path d="M10.6207 11.8963C10.5399 11.8823 10.4568 11.8912 10.3808 11.9221C10.3048 11.953 10.239 12.0047 10.1909 12.0711C10.1428 12.1375 10.1143 12.2161 10.1087 12.298C10.1031 12.3798 10.1205 12.4616 10.159 12.534L12.5612 16.2505C12.6059 16.3197 12.6693 16.3749 12.7441 16.4098C12.8189 16.4446 12.9019 16.4576 12.9838 16.4473L18.0001 15.8106C18.2384 15.7874 18.3354 16.1097 18.1238 16.2219L13.5887 18.4584C13.5148 18.4949 13.4527 18.5516 13.4096 18.6219C13.3664 18.6922 13.344 18.7733 13.3449 18.8557L13.3918 23.2807C13.3997 23.3623 13.4302 23.4401 13.4801 23.5053C13.5299 23.5704 13.597 23.6203 13.6738 23.6491C13.7506 23.678 13.8339 23.6848 13.9144 23.6686C13.9948 23.6525 14.069 23.6141 14.1287 23.5578L23.3988 14.8199C23.4579 14.7641 23.5003 14.6929 23.5213 14.6144C23.5423 14.5358 23.541 14.453 23.5175 14.3751C23.4941 14.2972 23.4495 14.2274 23.3886 14.1735C23.3278 14.1195 23.2532 14.0835 23.1731 14.0696L10.6207 11.8963Z" fill="#F5F5F5"/>
        </svg>
    )
}

export const BriefCaseSvgDark = () => {
    return (
        <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={64} height={63} rx="31.5" fill="#1D5DC2" fillOpacity="0.2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.8787 19.8787C27.4413 19.3161 28.2044 19 29 19H35C35.7957 19 36.5587 19.3161 37.1213 19.8787C37.6839 20.4413 38 21.2044 38 22V23H45C45.7956 23 46.5587 23.3161 47.1213 23.8787C47.6839 24.4413 48 25.2044 48 26V28.768L32.772 32.828C32.2661 32.9627 31.7339 32.9627 31.228 32.828L16 28.768V26C16 25.2044 16.3161 24.4413 16.8787 23.8787C17.4413 23.3161 18.2044 23 19 23H26V22C26 21.2044 26.3161 20.4413 26.8787 19.8787ZM35 21H29C28.7348 21 28.4804 21.1054 28.2929 21.2929C28.1054 21.4804 28 21.7348 28 22V23H36V22C36 21.7348 35.8946 21.4804 35.7071 21.2929C35.5196 21.1054 35.2652 21 35 21Z" fill="#1D5DC2" />
            <path d="M16.8787 44.1213C16.3161 43.5587 16 42.7957 16 42V30.7L31.742 34.894C31.911 34.9391 32.089 34.9391 32.258 34.894L48 30.7V42C48 42.7957 47.6839 43.5587 47.1213 44.1213C46.5587 44.6839 45.7956 45 45 45H19C18.2044 45 17.4413 44.6839 16.8787 44.1213Z" fill="#1D5DC2" />
        </svg>
    )
}

export const BriefCaseSvgLight = () => {
    return (
        <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width={64} height={63} rx="31.5" fill="#4A7DCE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.8787 19.8787C27.4413 19.3161 28.2044 19 29 19H35C35.7957 19 36.5587 19.3161 37.1213 19.8787C37.6839 20.4413 38 21.2044 38 22V23H45C45.7956 23 46.5587 23.3161 47.1213 23.8787C47.6839 24.4413 48 25.2044 48 26V28.768L32.772 32.828C32.2661 32.9627 31.7339 32.9627 31.228 32.828L16 28.768V26C16 25.2044 16.3161 24.4413 16.8787 23.8787C17.4413 23.3161 18.2044 23 19 23H26V22C26 21.2044 26.3161 20.4413 26.8787 19.8787ZM35 21H29C28.7348 21 28.4804 21.1054 28.2929 21.2929C28.1054 21.4804 28 21.7348 28 22V23H36V22C36 21.7348 35.8946 21.4804 35.7071 21.2929C35.5196 21.1054 35.2652 21 35 21Z" fill="#CCCCCC" />
            <path d="M16.8787 44.1213C16.3161 43.5587 16 42.7957 16 42V30.7L31.742 34.894C31.911 34.9391 32.089 34.9391 32.258 34.894L48 30.7V42C48 42.7957 47.6839 43.5587 47.1213 44.1213C46.5587 44.6839 45.7956 45 45 45H19C18.2044 45 17.4413 44.6839 16.8787 44.1213Z" fill="#CCCCCC" />
        </svg>
    )
}
