import React from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'

import Badge from "src/core/component/nav/Badge";
import { NotificationSvg } from "src/core/component/assets/svg/CommonSVG";
import Notifications from "src/core/component/notification/Notifications";
import useNotification from "src/notification/hook/useNotification";


const NotificationDropdown = ({ logout, isDarkMode, onToggle, profile, ...rest }) => {
    const {userNotifications} = useNotification();
    let classes = isDarkMode ? 'nav-item navigation__item dark' : 'nav-item navigation__item';


    return (    
        <>
            <Dropdown className={"navigation__dropdown notification" + (isDarkMode && ' dark' || '' )} drop="down" align={"end"} autoClose={true} onToggle={onToggle}>
                <Dropdown.Toggle  id="dropdown-basic-notification" className={"navigation__dropdown__toggler" + (isDarkMode && ' dark' || '' )}>
                    <li className={ classes }>
                        <Link to="#" className="nav-link d-flex align-items-center justify-content-center" onClick={(e) => e.preventDefault()}>
                            <NotificationSvg />
                            {(userNotifications || []).filter(n=>!n.read).length > 0 && (<Badge value={(userNotifications || []).filter(n=>!n.read).length} />) || (<></>)}
                        </Link>
                    </li>
                </Dropdown.Toggle>
                <Dropdown.Menu className={"navigation__dropdown__menu notification" + (isDarkMode && ' dark' || '' )}>
                    <Notifications />
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default NotificationDropdown
