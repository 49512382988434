import React from "react";
import { Container } from "react-bootstrap";

import {useTheme} from "src/theme/component/ThemeContext";

import {BriefCaseSvgDark, BriefCaseSvgLight} from "src/core/component/assets/svg/SupportSVG";

const Career = () => {
  const [isDarkMode] = useTheme();

  return (
    <section className="gbt-career">
      <Container fluid="xl">
        <div className="gbt-career__item">
          <h5>Why choose us?</h5>
          <p>Work from anywhere. Make your own schedule. Build long-lasting bonds across diverse cultures. Learn about Vedic Astrology: a traditional science, and apply it along with our own astrological innovations and use them to make predictions. And yes, we are always looking for help in the "astrological innovations" part. Work to solve interesting and, needless to say, unique problems.</p>
          <p>We are on a mission to take Astrology to the next level so as to be able to predict almost anything. Join us in this special endeavour.</p>
        </div>

        <div className="gbt-career__positions text-center">
          <div className="gbt-career__positions-empty">
            {
              isDarkMode ? <BriefCaseSvgLight /> : <BriefCaseSvgDark />
            }
            <h5 className="mt-3">There are no vacant positions at the moment.</h5>
          </div>

          <p className="gbt-career__positions-message mt-4">If you are interested in working with us, please send us your resume or CV on this email: <a href="mailto:info@pocketpandit.com">info@pocketpandit.com</a></p>
        </div>
      </Container>
    </section>
  )
}

export default Career;
