import React from "react";
import Select from 'react-select';


const PocketSelection = ({
        options = [],
        currentValue,
        onChange,
        isMulti=false,
        hasLabel=false,
        label="",
        isClearable=false,
        placeholder="Select",
        isSearchable=false,
        required=false,
        classes = "",
  disabled=false,
    }) => {

    let className = classes ? `pocket-selection ${classes}` : "pocket-selection"
    let classNamePrefix = "pocket-selection"

    // console.log("currentValue", currentValue)

    return (
        <>
            {hasLabel && (
                <label className="form-label">{label}</label>
            )}
            <Select
                isSearchable={isSearchable}
                getOptionValue={ option => option.value || option.code || option.id}
                getOptionLabel={ option => option.display || option.name || option.label }
                options= {options}
                // components={{
                //     DropdownIndicator: false,
                // }}
                isMulti={isMulti}
                onChange={ onChange }
                value={ currentValue || ''}
                isClearable={isClearable}
                key={ option => option.value || option.code || option.id }
                placeholder={placeholder}
                className={className}
                classNamePrefix={classNamePrefix}
                required={required}
                isDisabled={disabled}
            />
        </>
    )
}

export default PocketSelection
