import React from "react";

import SignupForm from "src/auth/component/SignupForm";

const Register = ({setTab, isDarkMode}) => {

  const handleLoginClick = (e) => {
    e.preventDefault();
    setTab("login");
  };

  return (
    <div className={"pocket-auth__signup--wrapper" + ((isDarkMode && " dark") || "")}>
        <SignupForm
          isDarkMode={isDarkMode}
          onClickLogin={handleLoginClick}
        />
    </div>
  );
};

export default Register;
