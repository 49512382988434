import React from "react";
import Accordion from 'react-bootstrap/Accordion';


const AccordionItem = ({id, children, classes="", ...rest}) => {

    return (
        <Accordion.Item className={classes} eventKey={id}>
            {children}
        </Accordion.Item>
    )
}

export default AccordionItem