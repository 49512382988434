import React, { useState, useEffect, useCallback } from "react";
import {useHistory, Redirect } from "react-router-dom";
import { pick } from "src/util/extra";
import api from "src/lib/api";
import useRTPInfo from "src/instant-prediction/hook/useRTPInfo";
import LoadingIcon from "src/core/component/loader/LoadingIcon";
import useAuthInfo from "src/auth/hook/auth-info";
import { PocketToast } from "src/core/component/toast/PocketToast";
import { dateAndTimeToDateTime } from "src/util/date_and_time_to_datetime";
import useFreeAstrology from "src/freeastrology/hook/useFreeAstrology";
import findGender from "src/core/component/findGender";
import { useTheme } from "src/theme/component/ThemeContext";
import BirthProfileSetupModal from "./BirthProfileSetupModal"
import "./birthProfileSetupModal.scss";
import BirthProfileAlert from "src/instant-prediction/components/birthprofilesetup/BirthProfileAlert"
import AlignEnd from "src/core/component/AlignEnd";
import SearchLocation from "src/core/component/form/SearchLocation";
import { BackSvg } from "src/core/component/assets/svg/CommonSVG"

const SetupBirthProfileModal = ({
  handleModalChose_AddProfile,
  relationship_id='',
  profileId=null,
}) => {
  const { updateBirthProfile } = useAuthInfo();
  const { rtpDatetime } = useRTPInfo();
  const [isDarkMode] = useTheme();
  const history = useHistory();
  const [relationships, setRelationships] = useState([]);
  const [isPransaKundaliAgreed,setIsPransaKundaliAgreed] = useState(false)
  const [profileLoading, setProfileLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameTextLength, setNameTextLength] = useState(50);
  const { astroBirthProfile, selectProfile } = useFreeAstrology();
  const [prashnaKundaliView, setPrashnaKundaliView] = useState(false)
  const [prashnaKundaliConfirm, setPrashnaKundaliConfirm] = useState(false)

  // prashna_kundali_profile
  const [form, setForm] = useState({
    name: "",
    relationship_id:relationship_id,
    is_primary: false,
    address: "",
    dob: "",
    latitude: 0.0,
    longitude: 0.0,
    timezone: "",
    country_code: "",
    profile_type: "regular_kundali_profile",
    gender: "",
    date: new Date().format("yyyy-MM-dd"),
    time: new Date().format("HH:mm:ss"),
  });

  useEffect(() => {
    fetchRelationships();
  }, []);

  useEffect(()=>{
    autoSelectGender(form.relationship_id)
  },[form.relationship_id,relationships])

  const autoSelectGender = (rel_id)=>{
    let relationship = relationships.filter((rel) => rel.id == rel_id)[0]
    if( !( relationship?.name === "Self" || relationship?.name === "Friend" || relationship?.name == 'undefined')){
      setForm(prev=>({...prev,gender:findGender(relationship?.name)}))
    }
  }

  const fetchRelationships = async () => {
    try {
      const result = await api.get(`v1/relationship`);
      const relationshipData = result?.data?.result?.data || [];
      setRelationships(relationshipData);
    } catch (error) {
      console.log("Error on fetching relationships");
    }
  };

  useEffect(() => {
    if(profileId){fetchProfileDetails(profileId);}
  }, []);

  const fetchProfileDetails = async (id) => {
    setProfileLoading(true);
    try {
      const profile = await api.get(`v1/birth-profiles/${id}`);
      const profileData = profile?.data?.result?.data;

      if (profileData && Object.keys(profileData).length > 0) {
        setForm((state) => ({
          ...state,
          name: profileData?.name || "",
          relationship_id: profileData?.relationship_id || location?.state?.relationship_id || "",
          is_primary: profileData?.is_primary || false,
          address: profileData?.address || "",
          dob: profileData?.dob || "",
          latitude: profileData?.latitude || 0.0,
          longitude: profileData?.longitude || 0.0,
          timezone: profileData?.timezone || "",
          country_code: profileData?.country_code || "",
          gender: profileData?.gender || "",
          date: (profileData?.dob && new Date(profileData.dob).format("yyyy-MM-dd")) || new Date().format("yyyy-MM-dd"),
          time: (profileData?.dob && new Date(profileData.dob).format("HH:mm:ss")) || new Date().format("HH:mm:ss"),
          profile_type: profileData?.profile_type || "regular_kundali_profile",
        }));
      }
      setProfileLoading(false);
    } catch (error) {
      PocketToast({
        type: "error",
        message: "Error on fetching profile details",
      });
      setProfileLoading(false);
    }
  };

  const handleProfileFormChange = useCallback((e) => {
    if (e.target.name === "name" && e.target.value.length === nameTextLength) {
      PocketToast({
        type: "warning",
        message: `Name should not be longer than ${nameTextLength} characters`,
      });
    }

    setForm({...form,[e.target.name]: e.target.value || e.target.checked || "",});
  });

  const handleSelectionChange = (e) => {setForm({ ...form, [e.name]: e?.id || e?.value });};

  const handleProfileFormSubmit = useCallback((e) => {
    e.preventDefault();
    const dataToSubmit = pick(
      form,
      "name",
      "relationship_id",
      "address",
      "is_primary",
      "gender",
      "latitude",
      "longitude",
      "timezone"
    );
    const dob = dateAndTimeToDateTime(form["date"], form["time"]);
    if (dob) {
      dataToSubmit["dob"] = dob;
    }

    if (Object.values(dataToSubmit).some((val) => val === null || val === "")) {
      PocketToast({
        type: "error",
        message: "All fields are required",
      });
      return;
    }

    dataToSubmit["profile_type"] = form["profile_type"];
    dataToSubmit["country_code"] = form["country_code"];

    setIsSubmitting(true);

    if(profileId) {
      editProfile(dataToSubmit).catch((e) => {
        PocketToast({
          type: "error",
          message: "Failed to update a birth profile",
        });
        setIsSubmitting(false);
      });
    } else {
      createProfile(dataToSubmit).catch((e) => {
        PocketToast({
          type: "error",
          message: "Failed to create a birth profile",
        });
        setIsSubmitting(false);
      });
    }
  });

  const createProfile = async (payload) => {
    if(payload.profile_type == "prashna_kundali_profile" && isPransaKundaliAgreed==false){
      setPrashnaKundaliConfirm(true);
      return;
    }
    const result = await api.post("v1/birth-profiles", payload);
    setIsSubmitting(false);
    if (result?.status === 200) {
      updateBirthProfile(result.data?.result?.data);
      PocketToast({type: "success",message: "Birth profile created successfully",});
      handleModalChose_AddProfile(result.data?.result?.data);
    }
  };

  const editProfile = async (payload) => {
    const result = await api.put(`v1/birth-profiles/${profileId}`, payload);
    setIsSubmitting(false);
    if (result?.status === 200) {
      updateBirthProfile(result.data?.result?.data);
      if(astroBirthProfile.id == profileId){
        selectProfile({...payload,id:profileId});
      }
      PocketToast({
        type: "success",
        message: "Birth profile updated successfully",
      });
      history.goBack();
    }
  };


  const setupPrashnaKundali = (e) => {
    e.preventDefault();
    if(form['address']==''){return;}
    handleBack();
    setForm({
      ...form,
      profile_type: "prashna_kundali_profile",
      date:
        (rtpDatetime && new Date(rtpDatetime).format("yyyy-MM-dd")) ||
        new Date().format("yyyy-MM-dd"),
      time:
        (rtpDatetime && new Date(rtpDatetime).format("HH:mm:ss")) ||
        new Date().format("HH:mm:ss"),
    });
  };

  const onLocationChange = (data) => {
    setForm((currentValue) => ({
      ...currentValue,
      address: data["query"] || "",
      latitude: data["place"]["latitude"] || "",
      longitude: data["place"]["longitude"] || "",
      timezone: data["timezone"]["name"] || "",
      country_code: data["country_code"] || "",
    }));
  };

  // console.log("Birth Form Data", form);

  let relationship = "";
  if (location?.state?.relationship_id === form.relationship_id) {
    relationship = (relationships || []).find((relationship) => relationship.id === form.relationship_id);
  } else {
    relationship = (relationships || []).find((relationship) => relationship.id === form.relationship_id);
  }

  const handlePrashnaKundali = ()=>{setPrashnaKundaliView(true);}
  const handleBack = ()=>{setPrashnaKundaliView(false);}
  const handleCancel = ()=>{
      setForm(prev=>({...prev,profile_type: "regular_kundali_profile",}))
      setIsSubmitting(false)
      setPrashnaKundaliConfirm(false)
  }
  const handlePrashnaKundaliAgreed = (e)=>{
    setIsPransaKundaliAgreed(true);
    handleProfileFormSubmit(e);
  }

  return (
    <div className="modal-birth-profile">

      { !prashnaKundaliView && !prashnaKundaliConfirm ? (
      <div>
        <BirthProfileAlert onClick={handlePrashnaKundali} relationship={relationship} className="modal-birth-profile-alert" />
        <div className="modal-birth-profile-title">
          <div className="label">Add Birth Profile</div>
          {/* <div className="value">You do not have any profile for this relationship.</div> */}
        </div>
        
        <div className="birth-profile__btm">
          {profileLoading ? <LoadingIcon />:
            <BirthProfileSetupModal
              relationTitle={location?.state?.name}
              handleChange={handleProfileFormChange}
              handleSubmit={handleProfileFormSubmit}
              form={form}
              setForm={setForm}
              onLocationChange={onLocationChange}
              onChangeSelection={handleSelectionChange}
              isSubmitting={isSubmitting}
              nameTextLength={nameTextLength}
              relationships={relationships}
              relationship={relationship}
            />
          }
        </div>
      </div>
      ): prashnaKundaliView ?(
        <div className="modal-birth-profile-prashna-kundali">
          <span onClick={handleBack} className='back'><BackSvg/> Back</span>
          <h4 className="heading">Looks like you don't know your birth profile</h4>
          <div className="description">
              We will use the practice of ‘Prasna Chart’ to provide a prediction to your question. Please enter your current location below and we will use that along with the date and time you asked us for prediction to your question(s).
          </div>
          <form className={"pocket-form" + (isDarkMode && ' dark' || '')}>
              <SearchLocation 
                  property={'address'}
                  hasLabel={true}
                  isSmall={false}
                  label={'Please enter your current location'}
                  placeholder={'Enter Location'}
                  onChange={onLocationChange}
                  value={form['address'] || ""}
              />
              <AlignEnd><button className={`location-submit`} disabled={form['address']== ''} onClick={setupPrashnaKundali}>Submit</button></AlignEnd>
          </form>
        </div>
      ):(
        <div className="modal-birth-profile-prashna-kundali-confirm">
          <div className="label">You have created a Prashna Kundali (Question Chart) profile</div>
            <div className="content">
              <div className="value">
                <span> You can only select and buy one question using a Prashna Kundali (Question Chart) profile.</span> 
                <span>If you know your Birth Detail please enter it or proceed with <b>Prashna Kindali</b></span>
              </div>
              <div className="body_buttons">
                <div className="cancel" onClick={handleCancel}>Cancel</div>
                <div className="proceed" onClick={(e)=>{handlePrashnaKundaliAgreed(e)}}>Proceed</div>
              </div>
            </div>
        </div>
      )}

    </div>
  );
};

export default SetupBirthProfileModal;
