import React from "react"


const TextArea = ({
        property,
        value,
        hasLabel=true,
        hasPlaceholder=true, 
        label="",
        classes="", 
        placeholder="",
        isInvalid=false,
        required=false,
        autoComplete="off",
        error=`*Please enter a valid ${property}`,
        rows="3",
        resizable=false,
        disabled=false,
        onChange,
        maxLength="",
        ...rest
    }) => {

    onChange = typeof onChange === 'function' ? onChange : function noop() { }

  return (
        <>  
            {
                hasLabel &&
                <label className="form-label" htmlFor={property}>{label}</label>
            }
            
            {
                <>
                    <textarea 
                        className={"form-control" + (isInvalid && " is-invalid" || "") + (resizable && ' resizable' || ' none')} 
                        placeholder={placeholder} 
                        name={property} 
                        id={property} 
                        value={value} 
                        onChange={onChange} 
                        rows={rows}
                        required={required}
                        disabled={disabled}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                    >

                    </textarea>

                    <div className="invalid-feedback">
                        {error}
                    </div>
                </>
            }
        </>
    )
}

export default TextArea
