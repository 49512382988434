import React from "react";
import { Link } from "react-router-dom";

import { ShoppingBagIconSvg } from "src/core/component/assets/svg/CommonSVG";

const CartEmpty = () => {
  return (
    <div className="cart-empty text-center">
      <div className="cart-icon">
        <ShoppingBagIconSvg />
      </div>
      <h5 className="title">Your cart is empty</h5>

      <Link to="/instant-prediction" className="pocket-btn">
        View Questions Now
      </Link>
    </div>
  );
};

export default CartEmpty;
