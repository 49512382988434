import React from 'react'

import api from 'src/lib/api';
import useAuthInfo from 'src/auth/hook/auth-info';

import BuyConversation from './BuyConversation';

export default function ConversationDetail({ conversation }) {
  const [invoices, setInvoices] = React.useState([]);

  const authInfo = useAuthInfo();
  const isBuyer = conversation.user_id == authInfo.profile?.id;
  const [otherUser] = conversation.users.filter(u => u.id != authInfo.profile?.id);

  React.useEffect(() => {

    queryInvoices(conversation.id);

  }, [conversation]);

  const queryInvoices = async (conversationId) => {

    try {
      setInvoices([]);
      const res = await api.get('v1/conversations/' + conversationId + '/invoices');

      setInvoices(res.data.result?.data);
    } catch (error) {
      error = (error.response?.data?.error || error);
      console.log('Error fetching invoices', error);
    }
  }

  return (<>

    <div className="card border-0">
      <div className="card-body">
        <div className="text-center">
          <img className="rounded-circle" width="80px" src={otherUser.avatar_url} />

          <h5 className="text-center">{otherUser.name}</h5>
          <h6 className="text-muted text-center text-small"> {otherUser.login} </h6>

          <p className="text-muted">
            <i className="fa fa-fw fa-dollar"></i>
            {Number(otherUser.chat_rate_per_hour || 0).toFixed(2)} / Hour
          </p>

        </div>

        {isBuyer && (
          <BuyConversation conversation={conversation} />
        )}
      </div>
    </div>


    {(invoices.length || null) && (<div className="row">
      <div className="col-sm-12">
        <h6 className="mx-auto text-small" style={{
          width: '85%',
          textAlign: 'center',
          borderBottom: '1px solid #eaeaea',
          lineHeight: '0.1em',
          margin: '10px 0 20px',
        }}>
          <strong className="py-1 px-3" style={{
            backgroundColor: '#fff',
            color: '#c0c0c0',
          }}> PAYMENT HISTORY </strong>
        </h6>
      </div>

      <div className="col-sm-12">
        <table className="table table-sm table-striped table-borderless text-small">
          <thead>
            <tr>
              <th> Date </th>
              <th> Hours </th>
              <th> Rate per hour</th>
              <th> Amount </th>
            </tr>
          </thead>

          <tbody>
            {[].concat(...invoices.map(invoice => invoice.items || [])).map((line) => (
              <tr key={line.id}>
                <td> {new Date(line.created_at).format('MMM dd, yyyy')} </td>
                <td> {line.quantity}</td>
                <td>Rs. {line.rate}</td>
                <td>Rs.{line.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>)}

  </>)
}
